.modal-popup {
    z-index: 10000;
    overflow-y: visible;
    .modal-inner-wrap {
        max-width: 1000px;
        max-height: 90%;
        .modal-title {
            border-bottom: none;

        }
        .modal-content{
            overflow-y: auto;
            padding-bottom: 14px;
            ul{
                li{
                    width: 50%;
                    display: inline-block;
                }
            }
            .pharmacy-picker-button-group{
                margin-bottom: 1.5rem;
                .pharmacy-picker-cancel{
                    margin-right: 0.5rem;
                }
            }
        }

    }
}

/* normal pharmacy picker */
#pharmacy-list{
    /* Remove default list styling */
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: grid;
    display: -ms-flexbox;
    -ms-flex-wrap: wrap;
    grid-gap: 0.5rem;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(auto-fit, minmax(1rem,1fr));
    li{
        width: stretch;
        margin: 0;
        span,
        a {
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            margin-top: -1px; /* Prevent double borders */
            background-color: #f6f6f6; /* Grey background color */
            padding: 12px; /* Add some padding */
            padding-bottom: 0px;
            text-decoration: none; /* Remove default text underline */
            font-size: 18px; /* Increase the font-size */
            color: black; /* Add a black text color */
            cursor: pointer;
            &:hover:not(.header) {
                background-color: #eee; /* Add a hover effect to all links, except for headers */
            }
        }
    }
}

/* Search bar */
.pharmacy-search{
    position: relative;
    i{
        position: absolute;
        top: 0.7rem;
        left: 0.5rem;
        color: #bbb;
        font-size: 28px;
    }
}

// Small mobile
@include max-screen($screen__xs){
    #pharmacy-list{
        grid-template-columns: 1fr;
        -ms-grid-columns: 1fr;
    }
}

/* MOBILE */
@include max-screen($screen__m){
    .modal-popup{
        .modal-inner-wrap{
            width: 100%;
            .modal-header,
            .modal-content{
                padding-left: 24px;
                padding-right: 24px;
            }
        }
    }
}

#myInput {
    width: 99%;
    font-size: 18px;
    padding: 12px 20px 12px 40px;
    border: 1px solid #ddd;
    margin-bottom: 5px;
	height: 45px;
	&:before{
		content: "search";
		display: inline-block;
		font-family: "Material Icons";
        width: 20px;
        height: 20px;
	}
}
