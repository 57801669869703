$banda-green: #118b2d;
$banda-green__hover-color: #00812b;
$banda-cod-grey__color: #1a1a1a;
$banda-font__color: $banda-cod-grey__color;
$banda-link__color: #1979c3;
$banda-headline__color: #333333;
$banda-orange__color: #f96e33;
$banda-orange__hover-color: #f8520b;
$banda-light-grey__color: #f6f6f6;
$banda-border__color: #c8c8c8;
$banda-border__secondary-color: #c2c2c2;
$banda-button__background-color: $banda-orange__color;
$banda-border_color: #ededed;
$banda-white: #fff;

/* Product Page */
$product-tab__background-color: #fff;
$banda-showmore-button__background-color: #e8e8e8;
$banda-cart-button__background-color: $banda-button__background-color;

/* Footer */
$banda-footer__background-color: #e8e8e8;

/* Tena */
$tena-light-blue__color: #9dc2fc;
$tena-dark-blue__color: #1c569e;
$tena-font__color: $banda-cod-grey__color;
