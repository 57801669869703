.trollweb-pharmacypicker-index-index {
    .column.main{
        .pharmacy-picker {
            margin: 1.5rem 0 0 0;
            .description-text {
                font-size: 1.5rem;
                font-weight: 600;
                margin-bottom: 0.75rem;
            }
            ul {
                list-style: none;
                margin-top: 1rem;
            }
        }
    }
}

/* cart */
.cart{
    #ps-pharma-suite{
        .cart-list{
            .product-item{
                td:first-of-type{
                    width: 57%;
                }
                .thumb{
                    display: inline-block;
                    margin-right: 0.5rem;
                    img{
                        max-width: 120px;
                        width: 120px;
                    }
                }
                .info{
                    display: inline-block;
                    vertical-align: top;
                    h4{
                        margin-top: 0.65rem;
                        display: table-caption;
                        width: 240px;
                    }
                }
                .qty-info{
                    text-align: center;
                }
                .price-info{
                    text-align: center;
                    .remove-item{
                        display: none;
                    }
                }
                .ps-cart-remove-button{
                    text-align: center;
                }
            }
        }
        .ps-cart-container-mobile{
            display: none;
        }
        .center-cell{
            text-align: center;
            padding-top: 1.2rem;
            font-weight: 600;
            font-size: 14px;
        }
    }
}

/* Booking Manual */
.ps-booking{
    h1{
        font-size: 30pt !important;
        font-weight: 700;
        line-height: 45pt;
        margin-bottom: 0rem !important;
        color: #474445;
        padding-left: 2rem;
        margin-top: 1rem !important;
    }
    #medicineList{
        thead{
            th:nth-child(2){
                display: none;
            }
        }
        tbody{
            tr{
                td:nth-child(2){
                    display: none;
                }
                td{
                    .btn.btn-secondary{
                        width: 100% !important;
                    }
                }
            }
        }
    }
    form[name="bookingForm"]{
        .bb{
            margin-top: 1rem;
            legend{
                font-weight: 700;
                padding-bottom: 0.25rem !important;
            }
            .form-horizontal{
                margin-bottom: 1rem;
                label[for="medicine"]{
                    text-align: left !important;
                    padding-left: 0 !important;
                    padding-top: 24px !important;
                    font-size: 13px;
                    span{
                        &:after{
                            content: ":";
                            display: inline-block;
                        }
                    }
                }
                .form-group{
                    padding: 0.5rem 0;
                    border-bottom: none !important;
                }
            }
            .btn{
                width: 105px !important;
                font-weight: 700 !important;
            }
            .h4{
                font-size: 16px;
                font-weight: 700;
                margin-bottom: 0.5rem;
            }
            .field-group{
                .pb-2{
                    display: block;
                    margin-bottom: 0.5rem;
                }
            }
            .row.ps-bottom-padding{
                margin-top: 1rem;
            }
            #psBookingBtnSendOrder{
                width: 475px !important;
                padding: 0.75rem 0;
                font-weight: 700 !important;
            }
        }
    }
}

/* Desktop */
@include min-screen($screen__m){
    /* Login */
    .trollweb-pharmacypicker-index-index{
        .pharmacy-picker{
            width: 79%;
            float: right;
        }
    }
}

/* Tablet */
@include screen($screen__m, $screen__l){
    .ps-booking{
        .container-fluid{
            form[name="bookingForm"]{
                .bb{
                    .form-group{
                        label{
                            text-align: left !important;
                            padding-left: 0 !important;
                        }
                    }
                }
            }
        }
    }
}

@include max-screen($screen__m){
    .ps-booking{
        .container-fluid{
            padding-left: 0 !important;
            padding-right: 0 !important;
            h1{
                font-size: 26px !important;
                line-height: 32px;
            }
        }
        form[name="bookingForm"]{
            .bb{
                .form-group{
                    label[for="medicine"]{
                        padding-top: 0 !important;
                    }
                    label{
                        padding-left: 0 !important;
                    }
                }
                #psBookingBtnSendOrder{
                    width: 280px !important;
                    font-size: 13px;
                }
            }
        }
    }
}

.trollweb-pharmacystorefront-prescriptions-index {
    // Pso makes some funky padding
    padding: 0;

    //modal
    .modal-popup {
        .modal-inner-wrap {
            max-width: 950px;
        }
        .modal-header {
            border: none;
        }
        .modal-content {
             box-shadow: none;
             border: none;
             outline: none;
             padding: 0 48px 48px 48px;
             ul {
                list-style: none;
                padding: 0;
             }
        }
    }

    // content
    #ps-pharma-suite {
        > * a {
            color: #1979c3 !important;
        }
        position: relative;
        .container {
            padding-top: 0;
        }
        .overlay-panel-body {
            overflow: initial;
            .ps-prescription-content {
                padding-top: 1.25rem;
            }
        }
        .ps-prescription-content-page {
            .ps-consumable-header {
                .ps-search {
                    padding: 20px 30px;
                }
                .select-style {
                    min-width: 235px !important;
                }
            }
        }
        .ps-main-content {
            background-color: transparent;
        }
        .ps-sort-form {
            position: absolute;
            top: -6rem;
            right: 1.25rem;
        }
        .ps-prescription-list-container {
            padding-top: 0;
        }
        .ps-prescription-list-content-content {
            background-color: transparent;
            border: none;
            padding: 0;
        }
        .btn-primary {
            background: #f96e33 !important;
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.35);
            border: none !important;
        }
        .ps-prescription-name {
            color: #000;
            &:hover {
                color: #000;
            }
        }
        .ps-back-to-prescriptions {
            text-decoration: none;
            margin: 0 0 1rem 0;
            background: transparent;
            &:before {
                left: 30px;
            }
            &:hover {
                text-decoration: none;
                &:before {
                    left: 25px;
                }
            }
        }
        .ps-back-to-prescriptions .text,
        .ps-back-to-prescriptions .text > span,
        .prescription-list-new a.color-black.active .ps-prescription-title .text {
            color: #1979c3;
            font-family: "Raleway";
            font-weight: 600;
        }
             .ps-prescription-list-content-article-box button.choose {
                 width: 187px;
             }
             .prescription-list-content-article-name {
                 overflow-wrap: break-word;
                 white-space: pre-wrap;
             }
             .ps-prescription-name {
                word-break: break-word;
             }
    }
}

/* MOBILE */
@include max-screen ($screen__m){
    #ps-pharma-suite{
        .ps-sort-form{
            position: unset !important;
        }
        .ps-prescription-list-container{
            .ps-prescription-list-content-article-header{
                height: 150px;
            }
        }
    }
    .cart{
        #ps-pharma-suite{
            .product-item{
                td:first-of-type{
                    max-width: 110px;
                }
            }
        }
    }
}
