//  Common
.filter {
    &-title {
        strong {
            position: unset;
            &:before {
                display: none;
            }
        }
    }
    &-current {
        .items {
            display: flex;
            flex-wrap: wrap;
        }
        .item {
            padding-left: unset;
            z-index: unset;
            a {
                display: flex;
                border: 1px solid #474747;
                border-radius: 15px;
                padding: 0.19rem 0.25rem 0.25rem 0.35rem;
                align-items: center;
                position: relative;
                z-index: 1;
                background: #fff;
                box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.75);
                &:before {
                    margin-left: 1rem;
                    order: 1;
                }
                .remove-label {
                    padding: 0 30px 0 10px;
                }
            }
        }
        .action.remove {
            margin-right: 0.5rem;
            @extend .abs-remove-button-for-blocks;
            left: unset;
            position: unset;
            top: unset;
            &:hover:before {
                color: #fff;
            }
            &:before {
                content: '\E5CD';
                position: absolute;
                right: 4px;
                top: 3px;
                font-size: 12px;
                line-height: 12px;
                margin: 0;
                text-align: center;
                background-color: #474747;
                border-radius: 50%;
                padding: 0.25rem;
                color: white;
                font-weight: 900;
            }
        }
    }
    &-actions {
        display: none;
    }
    &-label {
        display: none;
    }
    &-options {
        &-item {
            background: #ededed;
            border: none;
            padding-bottom: unset;
            &.active {
                .filter-options-content {
                    display: block;
                }
            }
        }
        &[data-value="stormberg_size"] {
            background: red;
        }
        &-title {
            align-items: center;
            font-size: 16px;
            padding: $indent__s;
            text-transform: unset;
            background: grey;
            color: #ffffff;
            border: 1px solid grey;
            border-bottom: 0;
            @include lib-icon-font(
                $_icon-font-content: '\E145',
                $_icon-font-size: 24px,
                $_icon-font-position: after,
                $_icon-font-display: flex
            );
            &:after {
                position: unset;
                right: unset;
                top: unset;
                margin-left: auto;
                line-height: 1;
                color: #ffffff;
            }
            &:hover {
                color: darken(#ffffff, 5%);
            }
            .active > & {
                @include lib-icon-font-symbol(
                    $_icon-font-content: '\E15B',
                    $_icon-font-position: after
                );
            }
        }
        &-content {
            display: none;
            a {
                margin-left: unset;
                margin-right: unset;
                padding-left: 10px;
            }
            .am_shopby_slider_container {
                margin: 1.5rem 2rem 0 1rem;
                .ui-slider-horizontal {
                    height: 4px;
                    background: #0072a0;
                    .ui-slider-handle {
                        background: #0072a0;
                        height: 20px;
                        width: 20px;
                        margin-top: -8px;
                    }
                }
                .am_shopby_slider_display {
                    margin: .5rem 0 0 0;
                    text-align: center;
                }
            }
            .swatch-option.color,
            .swatch-option.image {
                width: 100%;
                max-width: 100%;
                height: 100%;
                min-height: 75px;
                max-height: 100%;
                background-size: cover !important;
                align-items: flex-end;
                border-radius: 0;
                padding: 0;
                margin: 0 2px;
                width: calc(100% - 4px);
                .label {
                    width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    text-align: center;
                }
            }
        }
    }
}
//  Mobile
@include max-screen($screen__m) {
    body {
        &.filter-active {
            .page-header {
                display: unset;
            }
            .page-wrapper {
                margin-top: unset;
                visibility: unset;
            }
            .filter-title {
                strong {
                    font-weight: bold;
                }
            }
        }
    }
    .filter {
        &-title {
            strong {
                height: 40px;
                background-color: #ffffff;
                border: 1px solid #474747;
                border-bottom: 2px solid;
                border-radius: 4px;
            }
        }
        &.active {
            .filter {
                &-options {
                    &-item {
                        border: 0;
                        margin-bottom: 0;
                    }
                    &-title {
                        padding: 12px 10px;
                        border-bottom: 1px solid #fff;
                        background: grey;
                    }
                    &-content {
                        border-top: 1px solid #0072a0;
                        border-bottom: 1px solid #0072a0;
                        background: rgba(6, 57, 80, 0.2);
                    }
                }
                &-title {
                    display: flex;
                    align-items: center;
                    border-bottom: 1px solid #8a8a8a;
                    width: 85%;
                    background-color: #fff;
                    height: 50px;
                    z-index: 15;
                    strong {
                        position: static;
                        width: 100%;
                        left: unset;
                        right: unset;
                        top: unset;
                        @include lib-icon-font(
                                $icon-remove,
                            $_icon-font-color: $text__color__muted,
                            $_icon-font-color-active: $text__color__muted,
                            $_icon-font-color-hover: $text__color__muted,
                            $_icon-font-size: 16px,
                            $_icon-font-display: flex,
                            $_icon-font-position: after
                        );
                        &:after {
                            margin: 0px 0 0 auto;
                            font-size: 28px;
                            line-height: 28px;
                        }
                        &:before {
                            display: none;
                        }
                    }
                }
                &-subtitle {
                    display: none;
                }
                &-options {
                    background: #f7f7f7;
                    width: 85%;
                    padding: 0;
                    box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.5);
                    &-title {
                        padding: 10px;
                        color: #ffffff;
                        border: none;
                        &:after {
                            color: #ffffff;
                        }
                    }
                }
            }
        }
    }
    .filter .filter-current {
        border: none;
        margin: unset;
        .items {
            display: unset;
            padding: 1rem 0;
        }
        &-subtitle {
            @include lib-icon-font(
                $_icon-font-content: $icon-down,
                $_icon-font-size: 13px,
                $_icon-font-position: before,
                $_icon-font-display: none
            );
        }
        &.active {
            .items {
                display: flex;
            }
        }
    }
    .filter-no-options {
        .filter-title {
            display: none;
        }
    }

    // Desktop
    @include min-screen($screen__m) {
        &-content {
            .item {
                margin: $indent__xs 0;
                padding: 0 3px;
            }
        }
        &-subtitle {
            display: unset;
            position: static;
        }
    }
    .page-layout-1column {
        .filter {
            &-options {
                &-item {
                    margin: 5px;
                }
                &-title {
                    padding: $indent__s;
                }
                &-content {
                    left: 0;
                }
            }
        }
    }
}

/**
*
* DESKTOP STORMBERG SIDEBAR
*
*/

@include min-screen($screen__l) {

    .page-layout-2columns-left .column.main {
        width: 75%;
        float: right;
        order: 2;
    }

    .page-layout-2columns-left .sidebar-main {
        width: 25%;
        float: left;
        order: 1;
    }

    .filter-options-content {
        padding: 1rem 0;
        .swatch-option-link-layered {
            width: 20%;
            padding: 0;
        }
        .swatch-option {
            box-shadow: inset 0 0 0 1px #474747;
            margin: 0 2px;
            padding: 0;
            &.text {
                min-height: 50px;
                align-items: center;
            }
            &.selected {
                .label {
                    margin: 2px;
                }
            }
            .label {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 12px;
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                margin: 1px;
                text-align: center;
                padding: 0;
                display: flex;
                justify-content: center;
                span {
                    background: #fff;
                    padding: 0 4px;
                    width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
            .swatch-option.color, .swatch-option.image {
                width: 100%;
                max-width: 100%;
                height: 100%;
                min-height: 75px;
                max-height: 100%;
                background-size: cover !important;
                align-items: flex-end;
                border-radius: 0;
                padding: 0;
                margin: 0;
                .label {
                    width: 100%;
                    background: #ffffff;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    text-align: center;
                }
            }
        }
    }
}
