/* General Label Styling */
.tw-productlabels{
    .tw-productlabels__label{
        margin: 0rem 0.5rem 0.6rem 0rem;
        opacity: 0.85;
        .tw-productlabels__text{
            padding: 0.6rem 1.5rem;
        }
        &--discount{
            .tw-productlabels__text{
                background-color: #d60606;
            }
        }
        &--new{
            .tw-productlabels__text{
               background-color: #000;
               color: #fff;
            }
        }
    }
}
/* Remove on Product Page */
.product.media{
    .tw-productlabels{
        display: none;
    }
}
