/* Variables */
$star-font-size: 26px;
$star-height: 26px;

.review-container{
    display: block;
    clear: both;
    float: left;
    #product-review-container{
        .block-title{
            text-align: center;
            strong{
                font-size: 24px;
                font-weight: 600;
            }
		}
        .review-item{
            .review-image{
                display: inline-block;
                float: left;
                margin-top: -0.9rem;
                margin-right: 1.5rem;
            }
            .review-ratings{
                float: right;
                .rating-summary{
                    overflow: unset;
                    .rating-result{
                        width: 128px;
                        &:before{
                            height: $star-height;
                            font-size: $star-font-size;
                            letter-spacing: 0;
                        }
                        span{
                            &:before{
                                font-size: $star-font-size;
                                height: $star-height;
                                letter-spacing: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
/* Desktop */
@include min-screen( $screen__m ){
    .review-container{
        width: 48%;
        margin-top: 1rem;
    }
    .review-image{
        i{
            font-size: 5rem;
        }
    }
}
@include max-screen( $screen__m ){
    .review-container{
        width: 100%;
        margin-top: 0.5rem;
    }
    .review-image{
        i{
            font-size: 4rem;
            margin-top: 0.5rem;
        }
    }
}
