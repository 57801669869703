///
//  Theme variables
//  _____________________________________________

//  Messages
$message-global-note__color: $text__color !default;
$message-global-note__background: $color-yellow-light2 !default;
$message-global-note-link__color: $link__color !default;
$message-global-note-link__color-hover: $link__hover__color !default;
$message-global-note-link__color-active: $link__active__color !default;
$message-global-note__border-color: $color-yellow-light3 !default;

$message-global-caution__color: $color-white !default;
$message-global-caution__background: $color-red9 !default;
$message-global-caution-link__color: $link__color !default;
$message-global-caution-link__color-hover: $link__hover__color !default;
$message-global-caution-link__color-active: $link__active__color !default;
$message-global-caution__border-color: none !default;

//  Header

$header__background-color: false !default;
$header-panel__background-color: #f8f8f8 !default;
$header-panel__border-color: #e0e0e0 !default;
$header-panel__border-width: 0px !default;
$header-panel__text-color: $color-white !default;
$header-icons-color: $color-gray56 !default;
$header-icons-color-hover: $color-gray20 !default;
$customer-welcome__z-index: $dropdown-list__z-index + 1 !default;

$addto-color: $color-gray60 !default;
$addto-hover-color: $primary__color !default;
$minicart-icons-color: $header-icons-color !default;
$minicart-icons-color-hover: $header-icons-color-hover !default;

$price-color: $color-gray34 !default;
$price-size: 22px !default;
$price-size-desktop: 36px !default;

$button__shadow: inset 0 1px 0 0 rgba(255, 255, 255, 1), inset 0 -1px 0 0 fade($border-color__base, 30) !default; // Used for secondary button and catalog toolbar controls
$button__shadow-active: inset 0 1px 0 0 fade($border-color__base, 80), inset 0 -1px 0 0 fade($border-color__base, 30) !default; // Used for secondary button and catalog toolbar controls

$h1__margin-bottom__desktop: $indent__xl !default;

//  Footer
$footer__background-color: $color-gray-light01 !default;
$footer-links-separator-border-color: $color-gray-light4 !default;
$footer-links-color: $color-gray34 !default;
$footer-links-color-hover: $color-gray20 !default;
$footer-links-color-current: $primary__color__light !default;

//  Layered navigation
$filter-title-background-color: $color-gray94 !default;
$filter-link: $color-gray-darken4 !default;
$filter-link-hover: darken($filter-link, 30%) !default;
$filter-quantity: $color-gray52 !default;

$page-main__background-color: #ffffff !default;

$polarcore-header-content-desktop__padding: 0 !default;
