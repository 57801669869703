.polarcore-header-links {
    grid-column: 5;
    -ms-grid-column: 5;
    -ms-grid-row: 2;
    display: grid;
    display: -ms-grid;
    grid-template-columns: 1fr;
    -ms-grid-columns: 1fr;
    .wishlist-link,
    .account-link {
        display: none;
    }
    .desktop-price-widget{
        display: none;
        grid-column: 2;
        order: 2;
        -ms-grid-column: 2;
        line-height: 1.5em;
        .price-subtotal{
            .label {
                display: none !important;
            }
            &, * {
                font-size: 95%;
            }
        }
    }
}
.prescription-link a,
.bandagist-picker button {
    display: inline-block;
    line-height: 1.1;
    font-weight: 600;
    background: none;
    box-shadow: none;
    border-radius: 0;
    border-bottom: 1px solid;
    padding: 0 0 0.3rem 0;
    color: $link__color;
    &,
    &:focus,
    &:active,
    &:visited {
        text-decoration: none;
        color: $link__hover__color;
    }
}
.prescription-link,
.bandagist-text {
    display: none;
}
.prescription-link{
    grid-column: 2;
    -ms-grid-column: 2;
    text-align: center;
}
.bandagist-picker {
    grid-column: 4;
    -ms-grid-column: 4;
    text-align: center;
}
.prescription-link,
.bandagist-picker {
    -ms-grid-row: 2;
}
.bandagist-text {
    order: -1;
    grid-column: 1 / span 5;
    -ms-grid-column: 1;
    -ms-grid-column-span: 5;
    -ms-grid-row: 1;
    width: 100%;
    margin: 0 auto;
    padding: 0.5rem 1rem;
    line-height: 1;
    font-weight: 700;
    text-align: center;
    background-color: #dedede;
    border: 10px solid white;
    border-top: 0;
    border-bottom: 1px solid rgba(0,0,0,0.3);
    box-shadow: inset 0 -5px 5px -5px rgba(0,0,0,0.3);
}

span.bandagist-picker-address-target {
    display: block;
    font-size: 12px;
    margin-top: 5px;
}

//  Tablet
@include screen($screen__s, $screen__l - 1px) {
    .prescription-link {
        grid-column: 3;
        -ms-grid-column: 3;
    }
    .bandagist-text,
    .bandagist-picker {
        grid-column: 5;
        -ms-grid-column: 5;
    }
    .prescription-link,
    .bandagist-picker {
        grid-row: 2;
        -ms-grid-row: 2;
        margin-bottom: 1rem;
    }
    .bandagist-text {
        padding: 0 1rem;
        grid-row: 1;
        -ms-grid-row: 1;
    }
    .polarcore-header-links {
        grid-column: 6;
        -ms-grid-column: 6;
        grid-row: 1 / span 2;
        -ms-grid-row: 1;
        -ms-grid-row-span: 2;
    }
}
// Tablet + Desktop
@include min-screen($screen__s) {
    .header {
        &.content {
            .prescription-link {
                margin-left: auto;
                margin-right: auto;
            }
            .bandagist-text {
                background: none;
                box-shadow: none;
                border: none;
            }
        }
    }
}

// Desktop
@include min-screen($screen__l) {
    .prescription-link {
        grid-column: 2;
        -ms-grid-column: 2;
        -ms-grid-row: 2;
    }
    .bandagist-text,
    .bandagist-picker {
        grid-column: 4;
        -ms-grid-column: 4;
    }
    .bandagist-text {
        -ms-grid-row: 1;
    }
    .bandagist-picker {
        -ms-grid-row: 2;
    }
    .prescription-link,
    .bandagist-picker {
        margin-bottom: 1.5rem;
    }
    .polarcore-header-links {
        grid-column: 5;
        grid-row: 1 / span 2;
        -ms-grid-column: 5;
        -ms-grid-row: 1;
        -ms-grid-row-span: 2;
        grid-template-columns: 3rem 2fr;
        -ms-grid-columns: 3rem 2fr;
        .desktop-price-widget{
            display: block;
            cursor: pointer;
        }
    }

}

@include max-screen($screen__l) {
    .block-search {
        .form.minisearch {
            display: flex;
        }

        .field.search {
            width: 100%;
            height: 38px;
        }
        .actions button {
            background: transparent;
            border: 1px solid $banda-border__secondary-color;
            border-left: 0;
            opacity: 1;

            span {
                display: none;
            }
        }
    }
}

@include max-screen($screen__s) {

    .header.content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .bandagist-text {
            order: 0;
            width: 100%;
            background: #00904a;
            color: #fff;

            span:empty{
                display:none !important;
             }
        }

        .block-search {
            order: 6;
            width: 100%;
            margin-left: 1.75rem;
        }

        .nav-toggle {
            order: 2;
            min-width: 33.3%;
        }

        .logo {
            order: 3;
        }

        .bandagist-picker {
            order: 4;
        }

        .polarcore-header-links {
            order: 5;
            min-width: 25px;
        }

        .block-search {
            .form.minisearch {
                width: 100%;
            }

            .field.search {
                width: inherit;
            }
        }
    }

}


@media only screen and (max-width: 767px){
    .logo {
       top: 0;
       img {
        max-width: 40%;
        margin: 4px;
      }
    }
    .header.content .logo {
        width: 100%;
        max-width: 30%;
    }
}
@media only screen and (min-width: 768px) and (max-width: 1199px){
    .logo {
        top: 0;
    }
}

