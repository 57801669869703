.cart-summary {
    .block {
        .title > strong {
            display: block;
            padding: 1rem 0;
            cursor: pointer;
            &:hover {
                cursor: pointer;
                opacity: 0.9;
            }
            @include lib-heading(h3);
            @include lib-icon-font(
                $icon-down,
                $_icon-font-size: 26px,
                $_icon-font-position: after
            );
        }
        &.active {
            .title > strong {
                @include lib-icon-font(
                    $icon-up,
                    $_icon-font-size: 26px,
                    $_icon-font-position: after
                );
            }
        }
        &.shipping {
            .title {
            }
        }
    }
    .block.giftcard {
        display: none;
    }
}

.summary {
    @extend .abs-adjustment-incl-excl-tax;
    background: $sidebar__background-color;
    margin-bottom: $indent__m;
    padding: 1rem;

    &.title {
        display: block;
        @include lib-heading(h3);
    }

    .block {
        @extend .abs-discount-block;
        margin-bottom: 0;

        .item-options {
            margin-left: 0;
        }

        .fieldset {
            display: flex;
            flex-direction: column;
            padding: 0.5rem;

            .field {
                margin: 0 0 $indent__s;

                &.note {
                    font-size: $font-size__s;
                }
            }

            .methods {
                .field {
                    & > .label {
                        display: inline;
                    }
                }
            }
        }

        .fieldset.estimate {
            & > .legend,
            & > .legend + br {
                @extend .abs-no-display;
            }
        }
    }
    .actions-toolbar {
        & > .primary {
            button {
                @extend .abs-revert-secondary-color;
            }
        }
    }
}

//  Totals block
.cart-totals {
    @extend .abs-sidebar-totals;
    border-top: 1px solid $border-color__base;
    padding-top: $indent__s;

    .table-wrapper {
        margin-bottom: 0;
        overflow: inherit;
    }
}

//  Products table
.cart.table-wrapper {
    .items {
        thead + .item {
            border-top: $border-width__base solid $border-color__base;
        }

        & > .item {
            border-bottom: $border-width__base solid $border-color__base;
            position: relative;
        }
    }

    .col {
        padding-top: $indent__base;

        &.qty {
            .input-text {
                @extend .abs-input-qty;
                margin-top: -5px;
            }

            .label {
                @extend .abs-visually-hidden;
            }
        }
    }

    .item {
        &-actions td {
            padding-bottom: $indent__s;
            text-align: center;
            white-space: normal;
        }

        .col {
            &.item {
                display: block;
                min-height: 75px;
                padding: $indent__m 0 $indent__s 75px;
                position: relative;
            }
        }
    }

    .actions-toolbar {
        @extend .abs-add-clearfix;

        & > .action {
            @extend button;
            @include lib-link-as-button();
            margin-bottom: $indent__s;
            margin-right: $indent__s;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .action {
        &.help.map {
            @extend .abs-action-button-as-link;
            font-weight: $font-weight__regular;
        }
    }

    .product {
        &-item-photo {
            display: block;
            left: 0;
            max-width: 60px;
            padding: 0;
            position: absolute;
            top: 15px;
            width: 100%;
        }

        &-item-details {
            white-space: normal;
        }

        &-item-name {
            display: inline-block;
            font-weight: $font-weight__regular;
            margin-top: -6px;
        }
    }

    .gift-registry-name-label {
        &:after {
            content: ':';
        }
    }

    //  Product options
    .item-options {
        @extend .abs-product-options-list;
        @extend .abs-add-clearfix;
        font-size: $font-size__s;
        margin-bottom: $indent__s;
    }

    .product-item-name + .item-options {
        margin-top: $indent__s;
    }

    .product-image-wrapper {
        @extend .abs-reset-image-wrapper;
    }

    .action.configure {
        display: inline-block;
        margin: 0 0 $indent__base;
    }
}

.cart-container {
    display: grid;
	display: -ms-grid;
    grid-template-columns: 1fr;
    -ms-grid-columns: 1fr;
    -ms-grid-rows: 1fr 3rem 1fr 3rem 1fr;
    grid-gap: 3rem;
    .form-cart {
        @extend .abs-shopping-cart-items;
		grid-column: 1;
		-ms-grid-column: 1;
		grid-row: 2;
		-ms-grid-row: 3;
		width: 100%;
		float: none;
		padding: 0;
        .cart.actions {
            .action {
                background: none;
            }
            .action.update:before {
                font-size: 24px;
            }
            .action.clear {
                display: none;
            }
        }
    }

    .checkout-methods-items {
        @extend .abs-reset-list;
        margin-top: $indent__base;
        text-align: center;

        .action.primary {
            @extend .abs-button-l;
            width: 100%;
        }

        .item {
            & + .item {
                margin-top: $indent__base;
            }
        }
    }
	.block.crosssell {
		grid-column: 1;
		-ms-grid-column: 1;
		grid-row: 3;
		-ms-grid-row: 5;
		margin: 0;
	}
}

.cart-tax-info,
.cart .cart-tax-info {
    & + .cart-tax-total {
        display: block;
    }
}

.cart.table-wrapper,
.order-items.table-wrapper {
    .col.price,
    .col.qty,
    .col.subtotal,
    .col.msrp {
        text-align: right;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .cart {
        &.table-wrapper {
            overflow: inherit;

            thead {
                .col {
                    &:not(.item) {
                        display: none;
                    }
                }
            }

            .col {
                &.qty,
                &.price,
                &.subtotal,
                &.msrp {
                    box-sizing: border-box;
                    display: block;
                    float: left;
                    text-align: center;
                    white-space: nowrap;
                    width: 33%;
                    &:before {
                        content: attr(data-th) ':';
                        display: block;
                        font-weight: $font-weight__bold;
                        padding-bottom: $indent__s;
                    }
                }

                &.msrp {
                    white-space: normal;
                }
            }

            .item {
                .col.item {
                    padding-bottom: 0;
                }
            }
        }
    }

    .cart-container {
        .form-cart {
            @extend .abs-shopping-cart-items-mobile;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .cart-container {
        @extend .abs-add-clearfix-desktop;
        grid-template-columns: 3fr 1fr;
        -ms-grid-columns: 3fr 3rem 1fr;
        &.no-products {
            .prescription-form-cart {
                grid-row: 1;
                -ms-grid-row: 1;
            }
            .form-cart {
                grid-row: 2;
                -ms-grid-row: 4;
            }
        }
        .form-cart {
            @extend .abs-shopping-cart-items-desktop;
        	grid-row: 1;
        	-ms-grid-row: 1;
        }

		.cart-summary {
			grid-column: 2;
			-ms-grid-column: 3;
            grid-row: 1;
            -ms-grid-row: 1;
		}

        .widget {
            float: left;
        }

		.block.crosssell {
            grid-column: 1 / span 2;
            -ms-grid-column: 1;
            -ms-grid-column-span: 5;
			grid-row: 2;
			-ms-grid-row: 3;
		}
    }

    .cart-summary {
        .actions-toolbar {
            .column.main & {
                @extend .abs-reset-left-margin-desktop;
                & > .secondary {
                    float: none;
                }
            }
        }

        .block {
            .fieldset {
                .field {
                    @include lib-form-field-type-revert($_type: block);
                    margin: 0 0 $indent__s;
                }
            }
        }
    }

    .cart {
        &.table-wrapper {
            .items { // Google Chrome version 44.0.2403.107 m fix
                min-width: 100%;
                width: auto;
            }

            .item {
                .col {
                    &.item {
                        padding: 27px 8px $indent__s;
                    }
                }

                &-actions {
                    td {
                        text-align: right;
                    }
                }
            }

            .product {
                &-item-photo {
                    display: table-cell;
                    max-width: 100%;
                    padding-right: $indent__base;
                    position: static;
                    vertical-align: top;
                    width: 1%;
                }

                &-item-details {
                    display: table-cell;
                    vertical-align: top;
                    white-space: normal;
                    width: 99%;
                }
            }

            .item-actions {
                .actions-toolbar {
                    @extend .abs-reset-left-margin-desktop;
                    text-align: left;
                }
            }
        }
    }
}
