body {
    .p-full{
        width: 100%;
    }
    p.half-width{
        width: 50%;
    }
    /* overwrites the weird padding from pharmasuite.css */
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    /* hide tena stuff on banda */
    .tena-only{
        display: none;
    }

    .cookie-status-message {
        display: none;
    }
}

/* Mine Resepter */
.resepter-headline, .back-to-frontpage{
    display: block;
    width: auto;
}
.back-to-frontpage{
    font-size: 16px;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
}

/* Bandagist + org. nr., bottom of page */
.trollweb-pharmacy-picker-footer {
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	box-sizing: border-box;
	text-align: center;
    background: #00904a;
    color: #fff;

    @include max-screen($screen__s) {
        border-top: 0.8px solid #43434394;
    }

	&-contents {
		padding: 0.75rem 4rem;
		display: inline-block;
		.pharmacy-name{
			font-weight: 700;
			font-size: 18px;
			&-chosen {
				font-weight: 500;
				font-size: 18px;
			}
		}
		.pharmacy-org-number {
			font-weight: 700;
			font-size: 18px;
			&-chosen{
				font-weight: 500;
				font-size: 18px;
			}
		}
	}
}

/* breadcrumbs */
.columns{
    .breadcrumbs{
        ul{
            li{
                strong{
                    font-size: 12px !important;
                }
                a{
                    font-size: 12px !important;
                }
                &:after{
                    font-size: 15px !important;
                    line-height: 15px !important;
                }
            }
        }
    }
}

.wpcloud-banner2.wpcloud-banner2--small.banda-small {
    border: none;
}
a.wpcloud-banner2__button.button {
    line-height: 2em;
    background: none;
    border-top: 1px solid $link__color;
    width: auto;
    &,
    &:visited,
    &:hover {
        color: $link__hover__color;
        text-decoration: none;
    }
}

.cms-home {
    .quick-links {
        .wpb_wrapper {
            display: grid;
            .wpcloud-card {
                padding: 5vw 0;
                border-bottom: 1px solid #118b2d;
                .mdl-card {
                    padding: 0;
                    a {
                        display: grid;
                        grid-template-columns: 2fr 1fr;
                        align-items: center;
                        .wpcloud-image {
                            grid-column: 2;
                            grid-row: 1 / span 2;
                            padding: 0;
                            width: 100%;
                            height: 100%;
                            min-height: 15vw;
                            background-size: contain;
                            background-repeat: no-repeat;
                            background-position: center;
                        }
                        .mdl-card__actions {
                            position: relative;
                            grid-row: 2;
                            button {
                                background-color: #118b2d;
                                color: white;
                                border-radius: 5rem;
                                padding: 0.8rem;
                                font-family: inherit;
                                font-weight: 500;
                                font-size: 1.2em;
                                min-width: 50%;
                                &:hover {
                                    background-color: #047021;
                                }
                            }
                        }
                        .content {
                            padding: 0;
                            color: $text__color;
                            line-height: 1.5;
                            font-size: 1.2em;
                        }
                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }
}

// Mobile Only
@include max-screen($screen__s) {
    .cms-home {
        .quick-links {
            .wpb_wrapper {
                .wpcloud-card {
                    &:nth-of-type(even) {
                        .mdl-card {
                            a {
                                grid-template-columns: 1fr 2fr;
                                text-align: right;
                                .wpcloud-image {
                                    grid-column: 1;
                                }
                            }
                        }
                    }
                }
                &:last-of-type {
                    border-bottom: none;
                }
            }
        }
    }
}
// Tablet Only
@include screen($screen__s, $screen__l) {
    .cms-home {
        .quick-links {
            .wpb_wrapper {
                grid-template-columns: 1fr 1fr;
                .mdl-card {
                    a {
                        height: 100%;
                        .content {
                            align-self: flex-start;
                        }
                        .mdl-card__actions {
                            align-self: flex-end;
                        }
                    }
                }
                &:nth-of-type(odd) {
                    padding-right: 1rem;
                }
                &:nth-of-type(even) {
                    padding-left: 1rem;
                }
            }
        }
    }
}

// Desktop
@include max-screen($screen__l) {
    .show-only-desktop {display: none !important;}
}
// Desktop
@include min-screen($screen__l) {
    .hide-only-desktop {display: none !important;}
}

.smile-es-range-slider {
    width: 95%;
    margin: 30px auto auto;
    text-align: center;

    [data-role="from-label"] {
        float: left;
        margin-top: -25px;
    }
    [data-role="to-label"] {
        float: right;
        margin-top: -25px;
    }
    .ui-slider-handle {
        background: #118b2d;
    }
}
