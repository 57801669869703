.toolbar{
    select.sorter-options{
        background-color: $banda-light-grey__color;
        box-shadow: none;
        border-radius: 0px;
        padding: 0 2rem;
        margin: 0;
        border-bottom: 3px solid #b5b5b5;
        -webkit-appearance: menulist !important;
        font-size: 14px;
        font-weight: 400;
        color: #000;
        width: 150px;
        height: 40px;
    }
    .action.sorter-action{
        display: none;
    }
}

/**
*
* Mobile
*
*/
@include max-screen($screen__m) {

    .filter {
        &-title {
            strong {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 50px;
            }
        }
    }

    .toolbar select.sorter-options {
        height: 40px;
        width: calc(100% - 3rem);
        text-align: center;
        font-size: 14px;
        background-color: #ffffff;
        border: 1px solid #474747;
        border-bottom: 2px solid;
        border-radius: 4px;
    }

}
