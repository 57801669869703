.polarcore {
    //
    //  Variables
    //  ---------------------------------------------
    $polarcore__special-price-color: #dc2b2b !default;

    //
    //  Product Lists
    //  _____________________________________________

    .products {
        margin: 0.5rem -0.5rem;
    }

    a.product-item-link {
        white-space: normal;
        width: 100%;
        text-overflow: unset;
    }

    .product-item .special-price .price,
    .product-item .minimal-price .price {
        color: #dc2b2b;
    }
    .products-grid {
        .product {
            &-item {
                position: relative;
                padding: 2%;
                width: calc(50% - 10px);
                border: none;
                box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.2);
                margin: 5px;

                &-info {
                    padding: 1rem;
                    border-radius: 0.2rem;
                    margin: 0 0.5rem 0.5rem 0;
                    .regular-price {
                        text-decoration: line-through;
                    }
                    .special-price{
                        color: #dc2b2b;
                    }

                    .product-item-details{
                        .product-item-inner{
                            width: 100%;
                        }
                    }
                }

                .price {
                    font-size: 1.5em;
                    font-weight: 700;


                    &-box {
                        position: relative;
                        width: 100%;
                        justify-content: unset;
                        margin-top: 1rem;
                        text-align: left;
                    }

                    .old-price {
                        top: -1.5rem;
                        right: 0;
                        z-index: 1;
                        text-decoration: line-through;

                        & {
                            margin-left: 1rem;
                            color: #474747;
                            font-size: 18px;
                        }
                    }
                }

                &-details {
                    flex-grow: 1;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: flex-end;
                    justify-content: center;
                    padding-top: 0.5rem;

                    .products-list & {
                        text-align: left;
                    }

                    &__top {
                        display: flex;
                        flex-wrap: wrap;
                        width: 100%;
                    }

                    span.new-price-label {
                        font-size: 12px;
                        height: fit-content;
                        margin-top: 10px;
                    }
                }

                &-actions {
                    width: 100%;
                    .actions-primary {
                        width: 100%;
                        button.action.tocart {
                            width: 100%;
                        }
                    }
                }
                &-name {
                    width: 100%;
                    height: 5rem;
                    margin: 0;
                    padding-right: 0.5rem;
                    font-size: 18px;
                    font-weight: 400;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    line-clamp: 3;
                    box-orient: vertical;
                }

                &-actions {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .actions {
                        &-secondary {
                            display: none;
                        }
                    }

                    .products-list {
                        .actions-primary + .actions-secondary {
                            width: auto;
                            margin-left: 1rem;
                            margin-right: auto;
                        }
                    }
                }

                .button-link {
                    width: 70%;
                    margin: 1rem auto 0 auto;

                    .action.tocart.primary {
                        width: 100%;
                        background: #00904a none;
                        border: #00904a;
                        color: #ffffff;
                        cursor: pointer;
                        display: inline-block;
                        margin: 0;
                        font-size: 14px;
                        line-height: 16px;
                        box-sizing: border-box;
                        vertical-align: middle;
                        padding: 0.7rem;
                        text-transform: uppercase;
                        font-weight: 400;
                        border-radius: 3px;
                        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.35);

                        &:before {
                            display: none;
                        }

                        span {
                            position: relative;
                            width: auto;
                            font-size: 15px;
                            text-transform: none;
                        }
                    }
                }
            }

            &-items {
                display: flex;
                flex-wrap: wrap;
                padding: 0;
            }
        }
    }

    .toolbar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 0;
        width: 100%;
        &:before,
        &:after {
            display: none;
        }
        .sorter {
            position: unset;
            width: auto;
            border-radius: 3px;
            background-color: #e8e8e8;
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.45);
            &-options {
                width: 100%;
                padding: 0 0.5rem;
                border: none;
                box-shadow: none;
                background-color: transparent;
                font-family: "Raleway";
                font-size: 16px;
                height: 35px;
            }
            .sorter-label {
                display: none;
            }
        }
    }
    .products-grid.products-crosssell {
        .product-item {
            padding: 1rem;
            width: 100%;
            min-width: auto;
            @include min-screen($screen__xs) {
                width: calc(100% / 2);
            }
            @include min-screen($screen__s) {
                width: calc(100% / 3);
            }
            @include min-screen($screen__m) {
                width: calc(100% / 4);
            }
            @include min-screen($screen__l) {
                width: calc(100% / 5);
            }
        }
    }
}


//
//  Tablet
//  _____________________________________________
@include min-screen($screen__l) {
    .polarcore .products-grid {
        .product-item {
            padding: 1rem;
            width: calc(33.3333% - 10px);
            margin-left: 5px;
            margin-right: 5px;
        }
    }
}

//
//  Desktop
//  _____________________________________________
@include min-screen($screen__xl) {
    .polarcore .products-grid {
        .product-item {
            width: calc(25% - 10px);
            margin-left: 5px;
            margin-right: 5px;
        }
    }
}

//
//  Mobile
//  _____________________________________________
@include max-screen($screen__s) {
    .polarcore .products-grid {
        .product-item {
            margin-left: 5px;
            margin-right: 5px;
            &-info {
                margin: 0;
                height: 350px;

                .product-image-container {
                    .product-image-wrapper {
                        height: 150px;
                    }
                }
            }
            .price {
                font-size: 1.5rem;
            }
            &-actions {
                width: 90%;
            }
            .button-link {
                width: 70%;
            }
        }
    }
}

.product-item-actions .actions-primary .stock.unavailable {
    width: 100%;
    text-align: center;
    background-color: #f3f3f3;
    color: #9e9e9e;
    display: block;
    height: 32px;
    line-height: 33px;
    text-decoration: none;
    text-align: center;
    max-width: 100%;
    pointer-events: none;
}

.polarcore .products-grid .product-item-details span.new-price-label {
    font-size: 12px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-top: 10px;
}

.product-item .special-price {
    display: inline-block;
}




.block-static-block {
    .products {
        .product-item-info{
            .price-box{
                .price-wrapper{
                    .special-price {
                        margin-left: 10px;
                    }
                    .regular-price{
                        font-weight: 400;
                    }
                }

            }
        }

    }
}

