//
//  Contained row appearance styles
//  _____________________________________________

[data-content-type='row'][data-appearance='contained'] {
    box-sizing: border-box;
    margin-left: auto !important; // sass-lint:disable-line no-important
    margin-right: auto !important; // sass-lint:disable-line no-important
    max-width: 100%;

    [data-element='inner'] {
        box-sizing: border-box;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m - 1) {
    [data-content-type='row'][data-appearance='contained'] {
        [data-element='inner'] {
            background-attachment: scroll !important; // sass-lint:disable-line no-important
        }
    }
}
