input[type="radio"]:checked {
    background: $banda-green;
}
input[type="radio"] {
    background: #ffffff;
    border: 2px solid #3b3f49;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
}

.footer-logo {
    .nav-toggle {
        display: none !important;
    }
    .logo {
        display: block;
        margin: 2rem 0;
    }
}

/**
*
* Newsletter block/componenet
*
*/
.block .field.newsletter div.control:before {
    line-height: 44px;
}
#newsletter {
    height: 44px;
}

/*
 see Snowdog/blank/styles/mixins/_media-queries.scss
 screen range for tablets/halfscreen
*/
@include screen($screen__m, ($layout__max-width)) {
    .page-wrapper .breadcrumbs {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}
