/* Top USP */
.usp-top-bar{
    width: 100%;
    .widget.block.block-static-block{
        margin: 0;
        ul{
            width: 100%;
            display: grid;
            display: -ms-grid;
            justify-content: space-between;
            align-items: center;
            margin: 0 auto;
            padding: 0;
            max-width: 1440px;
            li{
                grid-row: 1;
                grid-column: 1;
                opacity: 0;
                list-style: none;
                margin: 0;
                white-space: nowrap;
                i{
                    margin-right: 0.5rem;
                    vertical-align:middle;
                    line-height: 1.5em;
                    color: #118b2d;
                }
                &:nth-of-type(1) {
                    animation:fade 20s 5s infinite;

                }
                &:nth-of-type(2) {
                    animation:fade2 20s 5s infinite;

                }
                &:nth-of-type(3) {
                    animation:fade3 20s 5s infinite;

                }
                &:nth-of-type(4) {
                    animation:fade4 20s 5s infinite;

                }
                &:nth-of-type(5) {
                    animation:fade5 20s 5s infinite;

                }
            }
        }
    }
}

@keyframes fade
{
  0%   {opacity:1}
  20% { opacity: 0}
  40% { opacity: 0}
  60% { opacity: 0}
  80% { opacity: 0}
  100% { opacity: 0}
}
@keyframes fade2
{
  0%   {opacity:0}
  20% { opacity: 1}
  40% { opacity: 0}
  60% { opacity: 0}
  80% { opacity: 0}
  100% { opacity: 0}
}
@keyframes fade3
{
  0%   {opacity:0}
  20% { opacity: 0}
  40% { opacity: 1}
  60% { opacity: 0}
  80% { opacity: 0}
  100% { opacity: 0}
}
@keyframes fade4
{
  0%   {opacity:0}
  20% { opacity: 0}
  40% { opacity: 0}
  60% { opacity: 1}
  80% { opacity: 0}
  100% { opacity: 0}
}
@keyframes fade5
{
  0%   {opacity:0}
  20% { opacity: 0}
  40% { opacity: 0}
  60% { opacity: 0}
  80% { opacity: 1}
  100% { opacity: 0}
}

// Tablet + Desktop
@include min-screen($screen__l) {
    .usp-top-bar{
        .widget.block.block-static-block{
            ul{
                display: flex;
                display: -ms-flexbox;
                li{
                    visibility: visible;
                    opacity: 1;
                    grid-column: unset;
                    &:nth-of-type(1),
                    &:nth-of-type(2),
                    &:nth-of-type(3),
                    &:nth-of-type(4),
                    &:nth-of-type(5) {
                        animation: none;
                    }
                }
            }
        }
    }
}

// Mobile
@include max-screen($screen__l){
    .usp-top-bar{
        .widget.block.block-static-block{
            ul{
                grid-template-columns: 100%;
                -ms-grid-columns: 100%;
                justify-items: center;
            }
        }
    }
}
