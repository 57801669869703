$primary__red: red;

.columns {
    .main  {
        .app-container {
            width: 100% !important;
        }
    }
    .mapboxgl-ctrl-geocoder.mapboxgl-ctrl,
    .location-button-container,
    .list-card,
    .store-list-item-button {
        border-radius: 0;
    }
    .app-content {
        .location-button-container {
            background-color: $banda-green;
            text-transform: uppercase;

            &:hover {
                background-color: $banda-green__hover-color;
            }
        }
        ul {
            .store-list-item-name {
                color: $banda-font__color;
            }
            .list-card {
                button {
                    display: none;
                }
            }
            .store-list-item-details {
                font-size: 1.5rem;
            }
        }
        .store-list-item-button {
            color: $banda-font__color;
            border: 1px solid $banda-cod-grey__color;
        }
    }
}
