.block.related{
    display: inline-block;
    .block-title.title{
        margin-bottom: 1rem;
        text-align: center;
        #block-related-heading{
            font-size: 26px;
            font-weight: 600;
        }
    }
    .products-related{
        margin: 0;
    }
    .block-actions{
        display: none;
    }
    .product-item {
        .product-item-info {
            max-height: 280px;
        }
        .product-image-container {
            height: 12rem;
            .product-image-wrapper {
                height: inherit;
            }
        }
    }
    .product-item-details{
        flex: 1;
        .price-box{
            margin-bottom: 0.7rem;
            .old-price{
                .price{
                    margin-left: 0;
                }
            }
        }
        .field.related{
            display: none;
        }
        .product-item-actions{
            display: none !important;
        }
    }
}

/* Desktop */
@include min-screen ($screen__m){
    .block.related{
        width: 48%;
        float: left;
        clear: both;
        margin-right: 4%;
        margin-bottom: 0;
        .block-title.title{
            #block-related-heading{
                font-size: 24px;
            }
        }
        .products-grid{
            .product-item{
                width: 30%;
                &:nth-child(3){
                    margin-right: 0;
                }
            }
        }
    }
}

/* Mobile */
@include max-screen ($screen__m){
    .block.related{
        margin-top: 3rem;
        width: 100%;
        .block-title.title{
            #block-related-heading{
                font-size: 22px;
            }
        }
        .block-content.content{
            .block-actions{
                .action.select{
                    margin: 0.75rem auto;
                }
            }
        }
    }
    .products-related{
        .products{
            .product-item{
                width: 48%;
            }
        }
    }
}
