.wishlist-link {
    margin-left: 1rem;
    .wishlist-button {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: inherit;
    }
    .wishlist-icon {
        line-height: 10px;
        &:before {
            @include material-icon($icon-wishlist);
            font-size: 26px;
       }
   }
   .wishlist-text {
       font-size: 10px;
       font-weight: 500;
   }
}

.products-grid.wishlist {

    .product-item .price-box {
        justify-content: center;
    }

}

.sidebar .block-wishlist {

    .product-items {
        padding: 0;
        
        .product-item {
            width: 100%;
            
            .price-box {
                text-align: right;
            }

            &-name {
                text-align: left;
            }

        }
    
    }

    .actions-primary {
        .action.primary.tocart {
            margin-left: auto ;
        }
    }

}
