//  Common
.columns {
    .column.main {
        padding: 0 1rem;
    }
}
//  Desktop
@include min-screen($screen__m) {
    .page-layout-2columns-left {
        .column.main {
            padding-right: 1rem;
        }
        .sidebar-additional {
            width: 25%;
        }
    }
}
