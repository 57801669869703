.polarcore__full-width-top-container {
    
    .widget {
        &.block-static-block {
            padding: 0;
            margin: 0;
            margin-bottom: 0;
        }
    }

    img {
        width: 100%;
    }

}
