//  Navigation CUSTOM
//  ---------------------------------------------

$shadow-drop: rgba(0,0,0,0.2) !default;
$border-curve: 5px !default;

.nav-toggle {
    order: 0;
    @include lib-icon-font(
        $icon-menu,
        $_icon-font-size       : 28px,
        $_icon-font-color      : $polarcore-nav-toggle__color,
        $_icon-font-color-hover: $polarcore-nav-toggle-hover__color
    );
    @include lib-icon-text-hide();
    cursor: pointer;
}

.nav-sections {
    background-color: #00904a;
    &-item {
        &-title {
            display: none;
        }

        &-content {

            .navigation {
                box-sizing: border-box;
                padding: 0;

                ul { // All menus
                    display: flex;
                    justify-content: space-around;
                    flex-direction: column;
                    align-items: flex-start;
                    padding: 0.5rem 0;
                    margin: 0;
                    border: none;
                    box-shadow: none;
                }

                li { // All items
                    margin: 0;
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    box-shadow: none;

                    ul { // All submenus
                        visibility: collapse;
                        border: none;
                        padding-left: 1rem;

                        &[aria-expanded="false"] {
                            visibility: collapse;
                        }

                        &[aria-expanded="true"] {
                            visibility: visible;
                        }

                        li {
                            flex-wrap: wrap;
                            margin-left: -3rem;
                            img {
                                flex: 0 0 25px;
                            }
                            a {
                                flex: 0 0 85%;
                            }
                        }
                    }

                    &[aria-haspopup="true"] > a {
                        &:after {
                            content: '+';
                            display: inline-block;
                            right: 0;
                            line-height: 0;
                            font-size: 3rem;
                            color: #cecece;
                            margin-left: auto;
                            margin-right: 0;
                            font-weight: 100;
                        }
                    }

                    &[aria-haspopup="true"] > a.clicked,
                    &[aria-haspopup="true"]:hover > a {
                        &:after {
                            content: '-';
                        }
                    }
                }

                a { // All links
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: calc(100% - 2.5rem);
                    padding: 1rem;
                    line-height: 1;
                    font-weight: 500;
                    color: #fff;
                    &:after {
                        content: none;
                        display: none;
                    }
                }
                a,
                a:hover {
                    color: #fff;
                    text-decoration: none;
                }

                .menu-toggler {
                    -webkit-appearance: none;
                    width: 3rem;
                    flex-grow: 1;
                    border: none;
                    background: none;
                    font-family: monospace;
                    font-size: 1.5em;
                    font-weight: 100;
                    color: $shadow-drop;
                    text-align: right;
                    cursor: pointer;

                }


                > ul { // Level0 menu (Row)
                    > li { // Level0 items
                        justify-content: space-between;
                        > ul { // Level1 menu (Column and slide down)
                            > li { // Level1-only items
                                img {
                                    -ms-grid-column: 1;
                                    width: 2.75rem;
                                    height: 3rem; //For IE only
                                    height: unset;
                                    object-fit: cover;
                                    object-position: center;
                                    margin-left: 0.5rem;
                                    & + a {
                                        -ms-grid-column: 2;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.sidebar-main {
    .filter-title strong {
        font-weight: 400;
    }

    .filter-options-content {
        display: block !important;
    }
    .filter-current {
        &-subtitle {
            font-weight: 500;
        }
        .items {
            margin-top: 2rem;
        }

        .action.remove {
            position: relative;
        }
    }
    .block-subtitle {
        display: none;
    }
    .filter-options {
        &-title {
            background: $banda-white;
            color: $banda-font__color;
            text-transform: uppercase;
            border: 0;
            border-bottom: 1px solid $banda-cod-grey__color;
            font-weight: 400;
            font-size: 14px;

            &:hover {
                color: $banda-font__color;
            }
        }
        &-item {
            background: $banda-white;
        }
    }
}

// Mobile + Tablet
@include max-screen($screen__l) {
    .nav-sections {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 90vw;
        height: 100vh;
        z-index: 9999;
        background-color: #f5f5f5;
        visibility: collapse;
        transform: translateX(-100%);
        transition: visibility .5s ease-in-out;
        &-item {
            &-title {
                padding: 1rem;
                width: 100%;
                box-shadow: none;
                text-align: center;
            }
            &-content {
                padding: 0;
                .navigation {
                    li { // All items
                        ul { // All submenus
                            display: none;
                            width: 100%;
                            flex-wrap: wrap;
                            &[aria-expanded="true"] {
                                display: flex;
                            }
                            li {
                                margin-left: 0;
                                ul {
                                    padding-left: 1.5rem;
                                    li {
                                        &:before {
                                            content: '';
                                            width: 1px;
                                            height: calc(2.5em + 1px);
                                            display: block;
                                            background-color: #bfbbbb;
                                            margin-top: -1.5em;
                                        }
                                        &:first-of-type:before {
                                            content: '';
                                            height: 2em;
                                            margin-top: 1px;
                                        }
                                        & a {
                                            padding: 1rem 1rem 0 0;
                                            text-align: left;
                                            justify-content: flex-start;
                                            line-height: 1.5;
                                            &:before {
                                                content: '-';
                                                display: inline-block;
                                                transform: scaleX(3);
                                                color: #bfbbbb;
                                                font-weight: 300;
                                                margin-right: calc(1.5rem - 3px);
                                                margin-left: 3px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                //sidebar navigation expanded and submenu expanded
                .navigation ul li > ul li {
                    &[aria-haspopup="true"] {
                        ul {
                            flex: 0 0 100%;
                        }
                    }
                }
            }
        }
    }
    .page-wrapper {
        transform: translateX(0);
        transition: transform .5s ease-in-out;
    }
    &.nav-open {
        overflow-y: hidden;
        .nav-sections {
            visibility: visible;
            overflow-y: scroll;
            display: block;
        }
        .page-wrapper {
            transform: translateX(90vw);
        }
        .navigation {
            margin-bottom: 100px;
        }
    }
    &.nav-before-open {
        width: 100vw;
        overflow-x: hidden;
        .page-wrapper {
            position: relative;
            &:before {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: rgba(0,0,0,0.5);
                z-index: 10;
            }
        }
        .nav-toggle {
            position: fixed;
            top: 0;
            z-index: 20;
            &:before {
                content: '\e5cd';
                color: #ffffff;
            }
        }
    }

    .filter-content {
        margin-top: 2rem;
    }

    .filter-options {
        margin: 0 1rem 0;
    }

     .nav-sections-item-content .navigation a {
        color: #000;
    }
}

// Desktop
@include min-screen($screen__l) {
    .nav-toggle {
        display: none;
    }
    .nav-sections {
        display: block;
        border-top: 1px solid #e9e9e9;
        border-bottom: 1px solid #e9e9e9;
        &-item {
            &-content {
                > .navigation {
                    margin: 0 auto;
                    width: 100%;
                    max-width: 1440px;
                }
                .navigation {
                    > ul { // Level0 menu (Row)
                        flex-direction: row;
                        align-items: center;
                        margin-left: 15rem;

                        > li > ul[aria-expanded="true"] {
                            margin-left: -15rem;
                        }

                        > li { // Level0 items
                            width: auto;
                            text-align: center;

                            > ul { // Level1 menu (Column and slide down)
                                position: absolute;
                                z-index: 1;
                                top: 3vh;
                                left: 0;
                                border-radius: 0 0 #{$border-curve} #{$border-curve};
                                margin-top: 0.5rem;
                                &[aria-expanded="true"] {
                                    z-index: 9999;
                                    animation-name: slideDown;
                                    animation-duration: .4s;
                                    animation-timing-function: ease;
                                    animation-delay: inherit;
                                    animation-iteration-count: 1;
                                    animation-direction: normal;
                                    animation-fill-mode: forwards;
                                    animation-play-state: running;
                                }

                                li { // Level1+ items
                                    display: -ms-grid;
                                    -ms-grid-columns: 3rem 1fr;
                                    background-color: #f5f5f5;

                                    a {
                                        display: flex;
                                        width: 100%;
                                    }

                                    &:hover {
                                        background-color: #e9e9e9;
                                    }
                                }

                                > li { // Level1-only items

                                    &:first-child {
                                        box-shadow: inset 0 5px 5px -5px #{$shadow-drop};
                                    }

                                    &:last-child {
                                        border-radius: 0 0 #{$border-curve} #{$border-curve};
                                    }

                                    &:hover {
                                        > ul {
                                            visibility: visible;
                                            left: 100%;
                                            transition: left .4s ease;
                                        }
                                    }

                                    > ul {
                                        position: absolute;
                                        z-index: -1;
                                        left: -100%;
                                    }

                                    ul { // Level2+ menus (Column and slide right)
                                        top: 0;
                                        left: 0;
                                        width: auto;

                                        li { // Level2+ items
                                            box-shadow: inset 5px 0 5px -5px #{$shadow-drop};
                                            margin-left: 0px;

                                            &:first-child {
                                                border-radius: 0 #{$border-curve} 0 0;
                                            }

                                            &:last-child {
                                                border-radius: 0 0 #{$border-curve} 0;
                                            }
                                        }
                                    }
                                }
                            }
                            ul { // Level1+ menus
                                display: unset;
                                padding: 0;
                                min-width: 280px;
                                width: inherit;
                                max-width: 300px;
                                justify-content: flex-start;
                                flex-grow: 1;
                                flex-shrink: 0;
                            }
                        }
                    }

                    li { // All items
                        position: relative;
                        height: 3rem;
                        flex-wrap: unset;
                    }

                    a { // All links
                        display: inline-block;
                        width: 100%;
                        text-align: left;
                        &[href*="/pharmacy/prescriptions#/prescriptions"] {
                            display: none;
                        }
                        &[href$="/produkter"] {
                            + button {
                                pointer-events: none;
                            }
                            ~ ul {
                                animation-name: slideDown;
                                animation-duration: .4s;
                                animation-timing-function: ease;
                                animation-delay: inherit;
                                animation-iteration-count: 1;
                                animation-direction: normal;
                                animation-fill-mode: forwards;
                                animation-play-state: running;
                            }
                        }
                    }
                }
            }
        }
    }
}


// Desktop
@include min-screen($screen__xl) {
    .nav-sections {
        &-item {
            &-content {
                .navigation {
                    a {
                        white-space: nowrap;
                    }
                    ul > li > ul > li {
                        height: 4rem;

                        ul > li {
                            padding-left: 0.5rem;
                        }
                    }
                }
            }
        }
    }
    .sidebar-main {
        margin-top: 0;
        padding: 1rem 0 0 0;
        transition: margin-top .2s ease;
    }
}
.nav-sections-item-content .navigation li ul li a , .nav-sections-item-content .navigation li ul li a:hover {
    flex: 0 0 85%;
    color: #000;
}

// Laptop sizes
@media only screen and (min-width: 1200px) and (max-width: 1390px){
    .nav-sections{
        .nav-sections-item-content{
            .navigation{
                > ul{
                    > li{
                        ul[aria-expanded="true"]{
                            min-width: 20vw;
                            li{
                                background-color: #f3f5f9;
                                a{
                                    font-size: 12px;
                                }
                            }
                        }
                    }
                }

            }
        }
    }
}

@keyframes slideDown {
    0% {top: -200vh;z-index: -1;}
    10% {top: -90vh;z-index: -1;}
    20% {top: -80vh;z-index: -1;}
    30% {top: -70vh;z-index: -1;}
    40% {top: -60vh;z-index: -1;}
    50% {top: -50vh;z-index: -1;}
    60% {top: -40vh;z-index: -1;}
    70% {top: -30vh;z-index: -1;}
    80% {top: -20vh;z-index: -1;}
    90% {top: -10vh;z-index: -1;}
    91% {top: -9vh;z-index: -1;}
    92% {top: -8vh;z-index: -1;}
    93% {top: -7vh;z-index: -1;}
    94% {top: -6vh;z-index: -1;}
    95% {top: -5vh;z-index: -1;}
    96% {top: -4vh;z-index: -1;}
    97% {top: -3vh;z-index: -1;}
    98% {top: -2vh;z-index: -1;}
    99% {top: -1vh;z-index: -1;}
    100% {top: 100%;z-index: 9999;}
}
