/*
*
* Common Footer
*
*/

.footer.content {
    display: flex;
    flex-wrap: wrap;

    > * {
        flex-grow: 1;
        padding: 1rem 2rem;

        .block-static-block {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            justify-content: center;

            > div {
                width: 100%;
            }
            .footer-social {

                li {
                    padding-bottom: 1rem;
                }

                a {

                    img {
                      width: 20px;
                      vertical-align: middle;
                      margin-right: 1rem;
                    }

                    &:before {
                        display: none;
                    }

                }

            }

        }

        h2 {
            width: 100%;
            color: #ffffff;
            padding-bottom: 2rem;
            font-size: 14px;
        }

        li {
            padding-bottom: 0.5rem;
            font-size: 12px;
        }

        a {
            font-weight: 300;
            color: #f0f0f0;

            &.footer-store-link {
                color: $banda-link__color;
                text-decoration: underline;
            }

            &:before {
                @include material-icon($icon-arrow-right-thin);
                vertical-align: middle;
                font-size: 20px;
                margin-left: -5px;
            }

        }
    }

    .full-width {
        width: 100%;
    }

    .customer-service {

        .block-static-block {

            ul {
                width: 50%;
            }

        }

    }
}

.page-footer {
    color: #f0f0f0;

    .copyright {
        padding: 20px;
        border-top: 1px solid $polarcore-footer__border-color;
    }

}

/*
*
* Footer Mobile up
*
*/

@include min-screen($screen__m) {

    .page-footer .editorial {
        border-left: 1px solid $polarcore-footer__border-color;
        border-right: 1px solid $polarcore-footer__border-color;
    }

}
