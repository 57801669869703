//  Category view
.p2-category-top {
    body[class*="catalog"] & {
        .page-title {
            font-size: 35px;
            color: #000;
            margin-bottom: 1rem;
            font-weight: 500;
            padding-left: 1rem;
            margin-top: 4rem;
        }
        .category-description {
            color: $banda-font__color;
            font-size: 16px;
            border-bottom: unset;
        }
    }
}

span.old-price.sly-old-price span.price:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background: #434343;
    left: 0;
    top: 54%;
}



span.old-price.sly-old-price span.price {
    position: relative;
}
span.old-price.sly-old-price {
    display: block !important;
    margin-top: 0;
}

span.old-price span.price:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background: #434343;
    left: 0;
    top: 54%;
}

span.old-price span.price {
    position: relative;
}

.product-info-main .special-price, .product-options-bottom .special-price{
    margin: 0;
}


.products-grid, .widget-product-carousel {
    .old-price,
    .old.price {
        float: left;
        margin-right: 1.25rem;
        .price{
            font-weight: 400 !important;
        }
    }
    //  Product images general container
    .product-image {
        &-container {
            width: 100%;
        }
        &-wrapper {
            height: 220px;
            width: 100%;
        }
        &-photo {
            height: 100%;
            width: 100%;
            max-width: unset;
            position: relative;
            left: unset;
            right: unset;
            top: unset;
            object-fit: contain;
            object-position: top;
            .block.crosssell & {
                width: auto;
                height: 10rem;
            }
        }
    }
}
//  Product view

.product-info-main,
.product-options-bottom {
    .price-box {
        .price-wrapper .price {
            font-size: 32px;
            font-weight: unset;
        }
    }
    .special-price {
        display: inline-block;
        .price-container {
            .price {
                font-size: 32px;
            }
        }
    }
    .box-tocart {
        .action.tocart {
            @extend .abs-button-l;
            max-height: unset;
        }
    }
}

//  Mobile
@include max-screen($screen__m) {
    .p2-category-top {
        body[class*="catalog"] & {
            .page-title {
                margin-top: 1rem;
            }
        }
    }
}
