@import "../mixins/util";

.swatch-option.text, .swatch-attribute.size .swatch-option {
    @include center;
    padding: 10px 20px;
    transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    border: none;
    min-width: 20px;
    max-width: fit-content;
    color: #3d3d3d;
    background: #f9f9f9;
    font-size: 15px;
    font-weight: 500;
    margin: 12px 12px 0 0;

    @media (max-width: 600px) {
        font-size: 12px;
        min-width: 14px;
    }

    &:hover {
        background-color: lighten(#00904a, 1%);
        color: white;
        cursor: pointer;
        box-shadow: none;
        border: none;
    }

    &.selected {
        background-color: #00904a;
        color: white;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
        border: none;
    }
}

.swatch-attribute {
    margin-top: 8px;

    .size .swatch-option.selected {
        background-color: #00904a;
        color: white;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
        border: none;
    }
}
