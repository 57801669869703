.footer.content {
    > * {
        li {
            font-size: 15px;
            color: var(--neutrals-50, #fff);//$banda-font__color;
            list-style-type: none;
        }
        a {
            font-weight: 400;
            font-size: 14px;
            color: var(--neutrals-50, #fff);//$banda-font__color;
        }
    }
    background-color: #00904a;
    display: none;
}
.block.newsletter .field .control:before{
    content: none;
}

.page-footer {
    padding: 15px 15px 25px 15px;
    color: #fff;
    background-color: #00904a;
    .page-footer {
        &-column {
            width: 25%;
            max-width: 25%;
        }
    }

    .page-footer-content {
        max-width: 1280px;
        margin: auto;
        display: flex;
    }
    ul>li, footer.page-footer ol>li {  
        list-style-type: none;   
        color: var(--neutrals-50, #fff);
    }
}


.copyright {
    background-color: #007F41;
    color: var(--neutrals-50, #FFF);
    display: block;
    padding: 10px;
    text-align: center;
}

.block.newsletter .form.subscribe>.field, .block.newsletter .form.subscribe>.actions {
    float: left;
}

.block.newsletter {
    max-width: 100%;
    input[type=email] {
        display: flex;
        // padding: 8px 12px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        border-radius: 4px;
        border: 1px solid var(--neutrals-300, #b8c0ca);
        background: var(--neutrals-50, #fff);
        width: 270px;
        color: #000;
    }

    input[type=checkbox] {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        border-radius: 4px;
        border: 1px solid var(--neutrals-300, #b8c0ca);
        background: var(--neutrals-50, #fff);
        color: #000;
        margin: 0;
    }
    .form.subscribe {
        display: table;
        width: 100%;
    }
    label.indented-checkbox-text {
        margin-left: 3em;
        display: block;
        position: relative;
        margin-top: -1.4em;
        line-height: 1.4em;
    }

    .actions {
        display: table-cell;
        vertical-align: top;
        width: 100%;
        margin-top: 5px;

        button.subscribe {
            border: none;
            display: flex;
            padding: 20px 28px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            align-self: stretch;
            border-radius: 12px;
            background: var(--primary-600, #f96e33);
            margin-top: inherit;
            float: left;
            width: 270px;
            height: 48px;
            margin-bottom: 10px;
            box-shadow: none;
        }
    }
}

.terms-condition-checkbox {
    display: inline-flex;
    align-items: center;
}

.block.newsletter label.indented-checkbox-text {
    margin-left: 5px;
    display: block;
    position: relative;
    margin-top: auto;
    line-height: normal;
}

.block.newsletter {
    width: 100%; 
}


.page-footer-column.footer-links li a:visited , .page-footer-column.footer-links ul>li a ,.page-footer-column.footer-links a:visited, .alink:visited ,.page-footer-column.footer-links a{
    color: var(--neutrals-50, #fff);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;   
    list-style-type: none; 
}

.footer-links ul li {
    list-style-type: none;
    margin-bottom: 1rem;
} 


footer.page-footer ul>li, footer.page-footer ol>li ,footer.page-footer ul>li a{
    color: var(--neutrals-50, #fff);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;   
    list-style-type: none; 
    
}
footer.page-footer ul{
    padding-left: 0;
}

.social-media-wrapper {
    display: flex;
    align-items: center; /* Align items vertically */
    .social-media {
        list-style: none;
        padding: 0;
        margin: 15px 0 0 0;
        display: flex;
        align-items: center;
        li {
            margin-right: 10px;
            a {
                width: 40px;
                height: 40px;
                background-color: #fff;
                border-radius: 25px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px;
                img {
                    width: 40px;
                    height: 40px;
                }
            }
        }
    }
}

.footer-content {
    padding: 0rem 0.5rem;
    .widget.block-static-block {
        h2 {
            font-weight: 400;
            font-family: "Oswald";
            font-size: 20px;
        }
        a {
            font-size: 15px;
            letter-spacing: 0.02rem;
            &:before {
                display: none;
            }
        }
        ul {
            li {
                padding: 0.3rem 0rem;
            }
        }
        ul.social-media {
            li {
                display: inline;
                img {
                    max-width: 60px;
                    margin-right: 2rem;
                }
            }
        }
        .trustpilot-widget {
            width: 160px;
        }
    }
    /* mobile */
    @include max-screen( $screen__m ) {
        .page-footer{
            li{
                font-size: 14px !important;
            }
            a{
                font-size: 14px !important;
            }
        }
        .p-full {
            display: flex;
            flex-direction: column;
            flex-flow: column;

            > div {
                margin-left: 0;
                max-width: 100%;
                flex-basis: auto;
            }
        }
        .footer-grid {
            ul.social-media {
                text-align: center;
                li {
                    img {
                        max-width: 45px !important;
                        margin-right: 1.5rem !important;
                    }
                }
            }
        }

    }
}


.page-bottom {
    background: var(--primary-50, #f3f5f9);
    .usp-bar-wrapper {
        max-width: 1280px;
        flex-shrink: 0;        
        margin: 0 auto;
        width: 100%;
        padding: 6px 0;
        display: inline-block;
        justify-content: flex-start;
        align-items: center;
        text-align: left;
        .usp-bar-content {
            display: flex;
            justify-content: flex-start;
            padding: 0 8px;
            .usp-bar-row {
                display: flex;   
                .usp-bar-item {
                    margin-left: 0;
                    margin-right: 16px;
                }
            }
        }
    }
}




@media screen and (max-width: 980px) {
    .page-footer .page-footer-content {     
        flex-wrap: wrap;
    }

    .page-footer .page-footer-column {
        width: auto;
        max-width: 50%;
        flex: 0 0 50%;
    }

    .terms-condition-checkbox {
        float: inline-start;
    }
    .page-footer {
        margin-top: 0; 
    }
}


@media screen and (max-width: 600px) { 
    .page-footer .page-footer-column {
        width: auto;
        max-width: 100%;
        flex: 0 0 100%;
    }
}
