$layout-column-main__sidebar-offset: 2%;
$layout-column__additional-sidebar-offset: $layout-column-main__sidebar-offset;

//
//  Common
//  _____________________________________________

.columns {
    @include lib-layout-columns();

    .column.main {
        @extend .abs-add-box-sizing;
        padding-bottom: $indent__xl;
        flex-basis: 100%;
        max-width: 100%;
        flex-grow: 1;
        order: 1;
    }

    .sidebar-main {
        @extend .abs-add-box-sizing;
        flex-grow: 1;
        flex-basis: 100%;
        order: -1;
    }

    .sidebar-additional {
        display: none;
    }
}

.catalogsearch-result-index .sidebar-main {
    display: none;
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .navigation,
    .breadcrumbs,
    .page-header .header.panel,
    .header.content,
    .footer.content,
    .page-main,
    .page-wrapper > .widget,
    .page-wrapper > .page-bottom,
    .block.category.event,
    .top-container {
        padding-left: $layout__width-xs-indent;
        padding-right: $layout__width-xs-indent;
    }
    .breadcrumbs {
        order: -10;
    }
    .page-main {
        .account &,
        .cms-privacy-policy & {
            padding-top: 41px;
            position: relative;
        }
    }
    .columns {
        .column.main {
            display: flex;
            flex-direction: column;
            .toolbar.toolbar-products {
                flex-direction: column;
                order: 999;
                .pages {
                    display: block;
                }
            }
        }
    }
    body.cms-index-index {
        .columns {
            .column.main {
                display: block;
            }
        }
    }
}

@mixin lib-layout-columns__left() {
    .#{$layout-class-3columns} & {
        @include lib-layout-column(3, 1, $layout-column__left-width);
    }
    .#{$layout-class-2columns__left} & {
        @include lib-layout-column(2, 1, $layout-column__left-width);
    }
    .#{$layout-class-2columns__right} & {
        @include lib-layout-column(2, 1, $layout-column__left-width);
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .navigation,
    .breadcrumbs,
    .page-header .header.panel,
    .header.content,
    .footer.content,
    .page-wrapper > .widget,
    .page-wrapper > .page-bottom,
    .block.category.event,
    .top-container,
    .page-main {
        box-sizing: border-box;
        margin-left: auto;
        margin-right: auto;
        max-width: $layout__max-width;
        padding-left: $layout-indent__width;
        padding-right: $layout-indent__width;
        width: auto;
    }

    .page-main {
        width: 100%;
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: auto;
        .ie9 & {
            width: auto;
        }
    }

    .page-bottom {
        min-width: 100%;
    }

    .columns {
        display: block;
    }

    .column.main {
        @extend .abs-add-box-sizing-desktop;
        @include lib-layout-columns__main();
        width: 80%;
        min-height: 300px;
    }

    .sidebar-main {
        @extend .abs-add-box-sizing-desktop;
        @include lib-layout-columns__left();
        padding-right: $layout-column-main__sidebar-offset;
        width: 20% !important;
    }

    .page-layout-2columns-right .sidebar-main {
        padding-left: $layout-column-main__sidebar-offset;
        padding-right: 0;
    }

    .sidebar-additional {
        @extend .abs-add-box-sizing-desktop;
        @include lib-layout-columns__right();
        clear: right;
        padding-left: $layout-column__additional-sidebar-offset;
    }

    .page-layout-2columns-left {
        .sidebar-additional {
            clear: left;
            float: left;
            padding-left: 0;
            padding-right: $layout-column__additional-sidebar-offset;
        }
    }

    .panel.header {
        padding: 10px 20px;
    }
}
