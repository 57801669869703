//  Common
.rating-summary {
    .rating-result {
        margin-top: -4px;
    }
}

.availability.only.configurable-variation-qty {
    display: none;
}
//  Add review block
.product-reviews-summary {
    display: flex;
    margin: 0 auto 0 0;
    margin-top: .5rem;
    .products.wrapper.list & {
        margin: 0 auto 0 0;
    }
    .reviews-actions {
        display: flex;
        width: 70%;
        flex-direction: row;
        a {
            display: flex;
            &.view {
                margin-right: .5rem;
            }
            &.add {
                display: none;
            }
        }
    }
}
//  Desktop
@include min-screen($screen__m) {
    .product-reviews-summary {
        align-items: center;
        .reviews-actions {
            a {
                width: 40%;
                white-space: nowrap;
            }
            a.add {
                display: none;
            }
        }
    }
}
//  Common
.review-field-ratings {
    .review-field-rating {
        .label,
        .control {
            &:before{
                height: 26px;
                font-size: 26px;
            }
            label{
                &:before{
                    height: 26px;
                    font-size: 26px;
                }
            }
        }
        .label {
            display: none;
        }
        .control {
            padding-top: 2px;
        }
    }
}
//  Review product page
.review {
    legend.label {
        white-space: nowrap;
    }
    &-form {
        .action.submit.primary {
            // display: none; ???
        }
    }
    .fieldset &-legend.legend {
        span {
            float: left;
        }
        strong {
            margin-left: 8px;
        }
    }
    &-list {
        .block-title strong {
            // @include lib-heading(h2);
			color: inherit;
			font-family: inherit;
			font-style: inherit;
			font-weight: 500;
			line-height: 1.1;
			font-size: 20px;
			margin-top: 7px;
			margin-bottom: 7px;
        }
    }
    &-item {
        padding: 20px 0 0px 0;
    }
    &-ratings {
        margin: -2px 0px 4px 0;
    }
    &-title {
        @include lib-heading(h3);
		margin: 0 0.5rem 0 0;
		font-size: 16px;
		font-weight: 900;
    }
    &-content {
        margin-top: 10px;
    }
}
//  Desktop
@include min-screen($screen__s) {
    .review {
        &-form {
            fieldset {
                margin: 0 0 15px;
                legend.label {
                    white-space: nowrap;
                }
            }

        }
        &-ratings {
            float: right;
			margin-top: -.15rem;
        }
        &-ratings ~ &-content,
        &-ratings ~ &-details {
            margin-left: 0;
			width: 100%;
            float: left;
            position: relative;
            padding-right: 1.5rem;
            margin-top: 0;
            &:before {
                display: none; // quote on reviews, kinda tight alignment
                margin-right: 1rem;
                position: absolute;
                left: -2rem;
                top: -.6rem;
            }
        }
        .fieldset &-legend.legend {
            margin-bottom: 25px;
        }
        &-item {
            padding: $reviews-indent-desktop 0 0 0;
        }
        &-title {
		    margin: 0rem 1rem .5rem 0;
        }
    }
}
