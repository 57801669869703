/**
 * Adding namespacing for Gridlex
 */

$gl-gridName: p-grid;
$gl-colName: p-col;

$gl-mq-list: (
    lg: $screen__l,
    md: $screen__m,
    sm: $screen__s,
    xs: $screen__xs 
) !default;