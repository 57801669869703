.block.upsell{
    .title{
        margin-bottom: 1rem;
        text-align: center;
        #block-upsell-heading{
            font-weight: 600;
        }
    }
    .products-upsell{
        margin: 0;
        .product-item {
            .product-item-info {
                max-height: 280px;
            }
            .product-image-container {
                height: 12rem;
                .product-image-wrapper {
                    height: inherit;
                }
            }
        }
    }
}

/* Desktop */
@include min-screen ($screen__m){
    .block.upsell{
        width: 48%;
        float: left;
        margin-bottom: 0;
        .title{
            #block-upsell-heading{
                font-size: 24px;
            }
        }
        .products-upsell{
            .product-items{
                .product-item{
                    width: 31.33%;
                    padding-bottom: 1.32rem;
                }
            }
        }
    }
}

/* Mobile */
@include max-screen ($screen__m){
    .block.upsell{
        margin-bottom: 20px;
        .title{
            #block-upsell-heading{
                font-size: 22px;
            }
        }
        .products-upsell{
            .product-items{
                .product-item{
                    width: 48%;
                }
            }
        }
    }
}
