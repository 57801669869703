a.action.primary,
button {
    border-radius: 0px;
}

.action,
button {
    &:active {
        box-shadow: $button__shadow;
    }
}

a.action.primary {
    @include lib-link-as-button();
}

.action.primary {
    @include lib-button-primary(
        $_button-border: $polarcore-primary-button__border,
        $_button-border-hover: $polarcore-primary-button__border,
        $_button-border-active: $polarcore-primary-button__border,
        $_button-color: $polarcore-primary-button__color,
        $_button-background: $polarcore-primary-button__background,
        $_button-background-hover: darken($polarcore-primary-button__background, 8%),
        $_button-background-active: darken($polarcore-primary-button__background, 8%)
    );
    padding: 1rem;  
    font-weight: 400;
    border-radius: 0;
    box-shadow: 0 1px 0 0 darken($polarcore-primary-button__background, 25%);
}

.action.primary.checkout,
.action.primary.tocart {
    @include lib-button-primary(
        $_button-border: $polarcore-primary-cart-button__border,
        $_button-border-hover: $polarcore-primary-cart-button__border,
        $_button-border-active: $polarcore-primary-cart-button__border,
        $_button-color: $polarcore-primary-cart-button__color,
        $_button-background: $polarcore-primary-cart-button__background,
        $_button-background-hover: darken($polarcore-primary-cart-button__background, 8%),
        $_button-background-active: darken($polarcore-primary-cart-button__background, 8%)
    );
    padding: 1rem;
    text-transform: none;
    font-weight: 400;
    border-radius: 3px;
    box-shadow: 0 1px 1px rgba(0,0,0,0.15);
    background-color: $polarcore-primary-cart-button__background;
}

.actions-secondary {
    display: none;
}

.wpcloud-cta-btn-wrapper {
    a.wpcloud-cta-button.login-portal-button,
    a.wpcloud-cta-button.button
    {
        background: $banda-green;
        color: white;
        line-height: 15px;

        &:hover {
            background: $banda-green__hover-color;
        }
    }
}
.wpcloud-simple-gform{
    .wpcloud-cta-btn-wrapper{
        a.mdl-button{
            background-color: $banda-green;
            &:hover{
                background: $banda-green__hover-color;
            }
        }
    }
}


.products.list.product-items .product-item-info button.sold-out-button {
    width: 100%;
    margin-top: 11px;
    background-color: #e8e8e8;
    color: #333333;
    text-align: center;
}