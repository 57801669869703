$polarcore-primary__color: #2196f3 !default;
$polarcore-secondary__color: #3c3c3c !default;

$primary__color: $polarcore-secondary__color !default;
$secondary__color: #999 !default;
$collapsible-nav-current-border-color: $polarcore-primary__color !default;
$color-orange-red1: $polarcore-primary__color !default;
$active__color: $polarcore-primary__color !default;

$addto-color: #5e5e5e !default;
$addto-hover-color: lighten(#5e5e5e, 5%) !default;

// Body text color
$text__color: #434343 !default;