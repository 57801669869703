@charset "UTF-8";
/* Product Page */
/* Footer */
/* Tena */
/**
*
* Layout
*
*/
/**
*
* PolarCore Tabs mode
*
*/
html {
  font-family: sans-serif;
  text-size-adjust: 100%; }

body {
  margin: 0; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block; }

audio,
canvas,
progress,
video {
  display: inline-block; }

audio:not([controls]) {
  display: none;
  height: 0; }

progress {
  vertical-align: baseline; }

[hidden],
template {
  display: none; }

a {
  background-color: transparent; }

a:active,
a:hover {
  outline-width: 0; }

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted; }

b,
strong {
  font-weight: inherit; }

b,
strong {
  font-weight: bolder; }

dfn {
  font-style: italic; }

h1 {
  font-size: 2em;
  margin: 0.67em 0; }

mark {
  background-color: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

img {
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

code,
kbd,
pre,
samp {
  /* stylelint-disable */
  font-family: monospace, monospace;
  /* stylelint-enable */
  font-size: 1em; }

figure {
  margin: 1em 40px; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

button, .cart.table-wrapper .actions-toolbar > .action, .action-gift,
input,
select,
textarea {
  font: inherit; }

optgroup {
  font-weight: bold; }

button, .cart.table-wrapper .actions-toolbar > .action, .action-gift,
input,
select {
  overflow: visible; }

button, .cart.table-wrapper .actions-toolbar > .action, .action-gift,
input,
select,
textarea {
  margin: 0; }

button, .cart.table-wrapper .actions-toolbar > .action, .action-gift,
select {
  text-transform: none; }

[type="button"],
[type="reset"],
[type="submit"], button, .cart.table-wrapper .actions-toolbar > .action, .action-gift {
  cursor: pointer; }

[disabled] {
  cursor: default; }

[type="reset"],
[type="submit"], button, .cart.table-wrapper .actions-toolbar > .action, .action-gift,
html [type="button"] {
  appearance: button; }

button::-moz-focus-inner, .cart.table-wrapper .actions-toolbar > .action::-moz-focus-inner, .action-gift::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

button:-moz-focusring, .cart.table-wrapper .actions-toolbar > .action:-moz-focusring, .action-gift:-moz-focusring,
input:-moz-focusring {
  outline: 1px dotted ButtonText; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal; }

textarea {
  overflow: auto; }

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  appearance: field; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  appearance: none; }

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/MaterialIcons-Regular.eot);
  /* For IE6-8 */
  src: local("Material Icons"), local("MaterialIcons-Regular"), url(../fonts/MaterialIcons-Regular.woff2) format("woff2"), url(../fonts/MaterialIcons-Regular.woff) format("woff"), url(../fonts/MaterialIcons-Regular.ttf) format("truetype"); }

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }

/**
*
* Colors
*
*/
/**
*
*  Globals
*
*/
/**
*
* Form Element
*
*/
/**
*
* Navigation
*
*/
/**
*
* Modal
*
*/
/**
*
* Page
*
*/
/**
*
* Pager
*
*/
/**
*
* Filter
*
*/
/**
*
* Checkout
*
*/
/**
*
* Header
*
*/
/**
*
* Dropdown list
*
*/
/**
*
* Magento_Swatches module
*
*/
/**
*
* Footer
*
*/
/**
*
* Footer
*
*/
/**
*
*Toolbar
*
*/
body {
  margin: 0;
  padding: 0; }

article,
aside,
details,
figcaption,
figure,
main,
footer,
header,
nav,
section,
summary {
  display: block; }

audio,
canvas,
video {
  display: inline-block; }

audio:not([controls]) {
  display: none;
  height: 0; }

nav ul,
nav ol {
  list-style: none none; }

img {
  border: 0; }

img,
object,
video,
embed {
  height: auto;
  max-width: 100%; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 0; }

html {
  font-size: 62.5%;
  font-size-adjust: 100%; }

body {
  color: #434343;
  font-family: "Roboto", Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42857;
  font-size: 1.4rem; }

p {
  margin-top: 0; }

p {
  margin-bottom: 1rem; }

abbr[title] {
  border-bottom: 1px dotted #E0E6EE;
  cursor: help; }

b,
strong {
  font-weight: 700; }

em,
i {
  font-style: italic; }

mark {
  background: #f0f0f0;
  color: black; }

small,
.small {
  font-size: 12px; }

hr {
  border: 0;
  border-top: 1px solid #E0E6EE;
  margin-bottom: 20px;
  margin-top: 20px; }

sub,
sup {
  font-size: 71.42857%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

dfn {
  font-style: italic; }

h1 {
  font-weight: 300;
  line-height: 1.1;
  font-size: 2.6rem;
  margin-top: 0;
  margin-bottom: 2rem; }

h2 {
  font-weight: 300;
  line-height: 1.1;
  font-size: 2.6rem;
  margin-top: 2.5rem;
  margin-bottom: 2rem; }

h3 {
  font-weight: 300;
  line-height: 1.1;
  font-size: 1.8rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem; }

h4 {
  font-weight: 700;
  line-height: 1.1;
  font-size: 1.4rem;
  margin-top: 2rem;
  margin-bottom: 2rem; }

h5 {
  font-weight: 700;
  line-height: 1.1;
  font-size: 1.2rem;
  margin-top: 2rem;
  margin-bottom: 2rem; }

h6 {
  font-weight: 700;
  line-height: 1.1;
  font-size: 1rem;
  margin-top: 2rem;
  margin-bottom: 2rem; }

h1 small,
h1 .small,
h2 small,
h2 .small,
h3 small,
h3 .small,
h4 small,
h4 .small,
h5 small,
h5 .small,
h6 small,
h6 .small {
  color: #118b2d;
  font-family: "Roboto", Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1; }

a,
.alink {
  color: #118b2d;
  text-decoration: none; }
  a:visited,
  .alink:visited {
    color: #118b2d;
    text-decoration: none; }
  a:hover,
  .alink:hover {
    color: #0e7426;
    text-decoration: underline; }
  a:active,
  .alink:active {
    color: #2196f3;
    text-decoration: underline; }

ul,
ol {
  margin-top: 0;
  margin-bottom: 2.5rem; }
  ul > li,
  ol > li {
    margin-top: 0;
    margin-bottom: 1rem; }
  ul ul,
  ul ol,
  ol ul,
  ol ol {
    margin-bottom: 0; }

dl {
  margin-bottom: 20px;
  margin-top: 0; }

dt {
  font-weight: 700;
  margin-bottom: 5px;
  margin-top: 0; }

dd {
  margin-bottom: 10px;
  margin-top: 0;
  margin-left: 0; }

code,
kbd,
pre,
samp {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace; }

code {
  background: #f0f0f0;
  color: #094e19;
  padding: 2px 4px;
  font-size: 1.2rem;
  white-space: nowrap; }

kbd {
  background: #f0f0f0;
  color: #094e19;
  padding: 2px 4px;
  font-size: 1.2rem; }

pre {
  background: #f0f0f0;
  border: 1px solid #E0E6EE;
  color: #094e19;
  line-height: 1.42857;
  margin: 0 0 10px;
  padding: 10px;
  font-size: 1.2rem;
  display: block;
  word-wrap: break-word; }
  pre code {
    background-color: transparent;
    border-radius: 0;
    color: inherit;
    font-size: inherit;
    padding: 0;
    white-space: pre-wrap; }

blockquote {
  border-left: 0 solid #E0E6EE;
  margin: 0 0 20px 40px;
  padding: 0;
  color: #434343;
  font-family: "Roboto", Helvetica, sans-serif;
  font-style: italic;
  font-weight: 400;
  line-height: 1.42857;
  font-size: 1.4rem; }
  blockquote p:last-child,
  blockquote ul:last-child,
  blockquote ol:last-child {
    margin-bottom: 0; }
  blockquote footer,
  blockquote small,
  blockquote .small {
    color: #118b2d;
    line-height: 1.42857;
    font-size: 1rem;
    display: block; }
    blockquote footer:before,
    blockquote small:before,
    blockquote .small:before {
      content: "— "; }
  blockquote cite {
    font-style: normal; }

blockquote:before, blockquote:after {
  content: ''; }

q {
  quotes: none; }
  q:before, q:after {
    content: '';
    content: none; }

cite {
  font-style: normal; }

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  max-width: 100%; }
  table th {
    text-align: left; }
  table > tbody > tr > th,
  table > tbody > tr > td,
  table > tfoot > tr > th,
  table > tfoot > tr > td {
    vertical-align: top; }
  table > thead > tr > th,
  table > thead > tr > td {
    vertical-align: bottom; }
  table > thead > tr > th,
  table > thead > tr > td,
  table > tbody > tr > th,
  table > tbody > tr > td,
  table > tfoot > tr > th,
  table > tfoot > tr > td {
    padding: 8px 10px; }

button, .cart.table-wrapper .actions-toolbar > .action, .action-gift {
  background-image: none;
  background: #e0e6ee;
  border: 0;
  color: #118b2d;
  cursor: pointer;
  display: inline-block;
  font-family: "Roboto", Helvetica, sans-serif;
  font-weight: 400;
  margin: 0;
  padding: 7px 15px;
  font-size: 1.4rem;
  line-height: 1.6rem;
  box-sizing: border-box;
  vertical-align: middle; }
  button:focus, .cart.table-wrapper .actions-toolbar > .action:focus, .action-gift:focus, button:active, .cart.table-wrapper .actions-toolbar > .action:active, .action-gift:active {
    background: #d0d9e5;
    border: 0;
    color: #118b2d; }
  button:hover, .cart.table-wrapper .actions-toolbar > .action:hover, .action-gift:hover {
    background: #d0d9e5;
    border: 0;
    color: #555; }
  button.disabled, .cart.table-wrapper .actions-toolbar > .disabled.action, .disabled.action-gift, button[disabled], .cart.table-wrapper .actions-toolbar > .action[disabled], .action-gift[disabled],
  fieldset[disabled] button,
  fieldset[disabled] .cart.table-wrapper .actions-toolbar > .action,
  .cart.table-wrapper fieldset[disabled] .actions-toolbar > .action,
  fieldset[disabled] .action-gift {
    opacity: 0.5;
    cursor: default;
    pointer-events: none; }
  button::-moz-focus-inner, .cart.table-wrapper .actions-toolbar > .action::-moz-focus-inner, .action-gift::-moz-focus-inner {
    border: 0;
    padding: 0; }

input[type="text"],
input[type="password"],
input[type="url"],
input[type="tel"],
input[type="search"],
input[type="number"],
input[type="datetime"],
input[type="email"] {
  background: #fff;
  background-clip: padding-box;
  border: 1px solid #c2c2c2;
  border-radius: 1px;
  font-family: "Roboto", Helvetica, sans-serif;
  font-size: 14px;
  height: 32px;
  line-height: 1.42857;
  padding: 0 9px;
  vertical-align: baseline;
  width: 100%;
  box-sizing: border-box; }
  input[type="text"]:disabled,
  input[type="password"]:disabled,
  input[type="url"]:disabled,
  input[type="tel"]:disabled,
  input[type="search"]:disabled,
  input[type="number"]:disabled,
  input[type="datetime"]:disabled,
  input[type="email"]:disabled {
    opacity: 0.5; }
  input[type="text"]::-moz-placeholder,
  input[type="password"]::-moz-placeholder,
  input[type="url"]::-moz-placeholder,
  input[type="tel"]::-moz-placeholder,
  input[type="search"]::-moz-placeholder,
  input[type="number"]::-moz-placeholder,
  input[type="datetime"]::-moz-placeholder,
  input[type="email"]::-moz-placeholder {
    color: #c2c2c2; }
  input[type="text"]::-webkit-input-placeholder,
  input[type="password"]::-webkit-input-placeholder,
  input[type="url"]::-webkit-input-placeholder,
  input[type="tel"]::-webkit-input-placeholder,
  input[type="search"]::-webkit-input-placeholder,
  input[type="number"]::-webkit-input-placeholder,
  input[type="datetime"]::-webkit-input-placeholder,
  input[type="email"]::-webkit-input-placeholder {
    color: #c2c2c2; }
  input[type="text"]:-ms-input-placeholder,
  input[type="password"]:-ms-input-placeholder,
  input[type="url"]:-ms-input-placeholder,
  input[type="tel"]:-ms-input-placeholder,
  input[type="search"]:-ms-input-placeholder,
  input[type="number"]:-ms-input-placeholder,
  input[type="datetime"]:-ms-input-placeholder,
  input[type="email"]:-ms-input-placeholder {
    color: #c2c2c2; }

input[type="number"] {
  -moz-appearance: textfield; }
  input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0; }

input[type="search"] {
  -webkit-appearance: none; }
  input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration, input[type="search"]::-webkit-search-results-button, input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none; }

select {
  background: #fff;
  background-clip: padding-box;
  border: 1px solid #c2c2c2;
  border-radius: 1px;
  font-family: "Roboto", Helvetica, sans-serif;
  font-size: 14px;
  height: 32px;
  line-height: 1.42857;
  padding: 5px 10px 4px;
  vertical-align: baseline;
  width: 100%;
  box-sizing: border-box; }
  select:disabled {
    opacity: 0.5; }

select[multiple="multiple"] {
  height: auto;
  background-image: none; }

textarea {
  background: #fff;
  background-clip: padding-box;
  border: 1px solid #c2c2c2;
  border-radius: 1px;
  font-family: "Roboto", Helvetica, sans-serif;
  font-size: 14px;
  height: auto;
  line-height: 1.42857;
  margin: 0;
  padding: 10px;
  vertical-align: baseline;
  width: 100%;
  box-sizing: border-box;
  resize: vertical; }
  textarea:disabled {
    opacity: 0.5; }
  textarea::-moz-placeholder {
    color: #c2c2c2; }
  textarea::-webkit-input-placeholder {
    color: #c2c2c2; }
  textarea:-ms-input-placeholder {
    color: #c2c2c2; }

input.mage-error,
select.mage-error,
textarea.mage-error {
  border-color: #ed8380; }

div.mage-error[generated] {
  color: #e02b27;
  font-size: 1.2rem; }

input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button; }

input::-moz-focus-inner {
  border: 0;
  padding: 0; }

form {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

address {
  font-style: normal; }

*:focus {
  box-shadow: none;
  outline: 0; }

._keyfocus *:focus,
input:not([disabled]):focus,
textarea:not([disabled]):focus,
select:not([disabled]):focus {
  box-shadow: 0 0 3px 1px #68a8e0; }

/* Gridlex */
/**
 * Adding namespacing for Gridlex
 */
[class~="p-grid"],
[class*="p-grid-"],
[class*="p-grid_"] {
  box-sizing: border-box;
  display: flex;
  flex-flow: row wrap;
  margin: 0 -0.5rem; }

[class~="p-col"],
[class*="p-col-"],
[class*="p-col_"] {
  box-sizing: border-box;
  padding: 0 0.5rem 1rem;
  max-width: 100%; }

[class~="p-col"],
[class*="p-col_"] {
  flex: 1 1 0%; }

[class*="p-col-"] {
  flex: none; }

[class~="p-grid"][class~="p-col"],
[class~="p-grid"][class*="p-col-"],
[class~="p-grid"][class*="p-col_"],
[class*="p-grid-"][class~="p-col"],
[class*="p-grid-"][class*="p-col-"],
[class*="p-grid-"][class*="p-col_"],
[class*="p-grid_"][class~="p-col"],
[class*="p-grid_"][class*="p-col-"],
[class*="p-grid_"][class*="p-col_"] {
  margin: 0;
  padding: 0; }

/************************
    HELPERS SUFFIXES
*************************/
[class*="p-grid-"][class*="-noGutter"] {
  margin: 0; }
  [class*="p-grid-"][class*="-noGutter"] > [class~="p-col"],
  [class*="p-grid-"][class*="-noGutter"] > [class*="p-col-"] {
    padding: 0; }

[class*="p-grid-"][class*="-noWrap"] {
  flex-wrap: nowrap; }

[class*="p-grid-"][class*="-center"] {
  justify-content: center; }

[class*="p-grid-"][class*="-right"] {
  justify-content: flex-end;
  align-self: flex-end;
  margin-left: auto; }

[class*="p-grid-"][class*="-top"] {
  align-items: flex-start; }

[class*="p-grid-"][class*="-middle"] {
  align-items: center; }

[class*="p-grid-"][class*="-bottom"] {
  align-items: flex-end; }

[class*="p-grid-"][class*="-reverse"] {
  flex-direction: row-reverse; }

[class*="p-grid-"][class*="-column"] {
  flex-direction: column; }
  [class*="p-grid-"][class*="-column"] > [class*="p-col-"] {
    flex-basis: auto; }

[class*="p-grid-"][class*="-spaceBetween"] {
  justify-content: space-between; }

[class*="p-grid-"][class*="-spaceAround"] {
  justify-content: space-around; }

[class*="p-grid-"][class*="-equalHeight"] > [class~="p-col"],
[class*="p-grid-"][class*="-equalHeight"] > [class*="p-col-"],
[class*="p-grid-"][class*="-equalHeight"] > [class*="p-col_"] {
  align-self: stretch; }
  [class*="p-grid-"][class*="-equalHeight"] > [class~="p-col"] > *,
  [class*="p-grid-"][class*="-equalHeight"] > [class*="p-col-"] > *,
  [class*="p-grid-"][class*="-equalHeight"] > [class*="p-col_"] > * {
    height: 100%; }

[class*="p-grid-"][class*="-noBottom"] > [class~="p-col"],
[class*="p-grid-"][class*="-noBottom"] > [class*="p-col-"],
[class*="p-grid-"][class*="-noBottom"] > [class*="p-col_"] {
  padding-bottom: 0; }

[class*="p-col-"][class*="-top"] {
  align-self: flex-start; }

[class*="p-col-"][class*="-middle"] {
  align-self: center; }

[class*="p-col-"][class*="-bottom"] {
  align-self: flex-end; }

[class*="p-col-"][class*="-first"] {
  order: -1; }

[class*="p-col-"][class*="-last"] {
  order: 1; }

/************************
    GRID BY NUMBER
*************************/
[class*="p-grid-1"] > [class~="p-col"],
[class*="p-grid-1"] > [class*="p-col-"],
[class*="p-grid-1"] > [class*="p-col_"] {
  flex-basis: 100%;
  max-width: 100%; }

[class*="p-grid-2"] > [class~="p-col"],
[class*="p-grid-2"] > [class*="p-col-"],
[class*="p-grid-2"] > [class*="p-col_"] {
  flex-basis: 50%;
  max-width: 50%; }

[class*="p-grid-3"] > [class~="p-col"],
[class*="p-grid-3"] > [class*="p-col-"],
[class*="p-grid-3"] > [class*="p-col_"] {
  flex-basis: 33.33333%;
  max-width: 33.33333%; }

[class*="p-grid-4"] > [class~="p-col"],
[class*="p-grid-4"] > [class*="p-col-"],
[class*="p-grid-4"] > [class*="p-col_"] {
  flex-basis: 25%;
  max-width: 25%; }

[class*="p-grid-5"] > [class~="p-col"],
[class*="p-grid-5"] > [class*="p-col-"],
[class*="p-grid-5"] > [class*="p-col_"] {
  flex-basis: 20%;
  max-width: 20%; }

[class*="p-grid-6"] > [class~="p-col"],
[class*="p-grid-6"] > [class*="p-col-"],
[class*="p-grid-6"] > [class*="p-col_"] {
  flex-basis: 16.66667%;
  max-width: 16.66667%; }

[class*="p-grid-7"] > [class~="p-col"],
[class*="p-grid-7"] > [class*="p-col-"],
[class*="p-grid-7"] > [class*="p-col_"] {
  flex-basis: 14.28571%;
  max-width: 14.28571%; }

[class*="p-grid-8"] > [class~="p-col"],
[class*="p-grid-8"] > [class*="p-col-"],
[class*="p-grid-8"] > [class*="p-col_"] {
  flex-basis: 12.5%;
  max-width: 12.5%; }

[class*="p-grid-9"] > [class~="p-col"],
[class*="p-grid-9"] > [class*="p-col-"],
[class*="p-grid-9"] > [class*="p-col_"] {
  flex-basis: 11.11111%;
  max-width: 11.11111%; }

[class*="p-grid-10"] > [class~="p-col"],
[class*="p-grid-10"] > [class*="p-col-"],
[class*="p-grid-10"] > [class*="p-col_"] {
  flex-basis: 10%;
  max-width: 10%; }

[class*="p-grid-11"] > [class~="p-col"],
[class*="p-grid-11"] > [class*="p-col-"],
[class*="p-grid-11"] > [class*="p-col_"] {
  flex-basis: 9.09091%;
  max-width: 9.09091%; }

[class*="p-grid-12"] > [class~="p-col"],
[class*="p-grid-12"] > [class*="p-col-"],
[class*="p-grid-12"] > [class*="p-col_"] {
  flex-basis: 8.33333%;
  max-width: 8.33333%; }

@media (max-width: 1200px) {
  [class*="_lg-1"] > [class~="p-col"],
  [class*="_lg-1"] > [class*="p-col-"],
  [class*="_lg-1"] > [class*="p-col_"] {
    flex-basis: 100%;
    max-width: 100%; }
  [class*="_lg-2"] > [class~="p-col"],
  [class*="_lg-2"] > [class*="p-col-"],
  [class*="_lg-2"] > [class*="p-col_"] {
    flex-basis: 50%;
    max-width: 50%; }
  [class*="_lg-3"] > [class~="p-col"],
  [class*="_lg-3"] > [class*="p-col-"],
  [class*="_lg-3"] > [class*="p-col_"] {
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  [class*="_lg-4"] > [class~="p-col"],
  [class*="_lg-4"] > [class*="p-col-"],
  [class*="_lg-4"] > [class*="p-col_"] {
    flex-basis: 25%;
    max-width: 25%; }
  [class*="_lg-5"] > [class~="p-col"],
  [class*="_lg-5"] > [class*="p-col-"],
  [class*="_lg-5"] > [class*="p-col_"] {
    flex-basis: 20%;
    max-width: 20%; }
  [class*="_lg-6"] > [class~="p-col"],
  [class*="_lg-6"] > [class*="p-col-"],
  [class*="_lg-6"] > [class*="p-col_"] {
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  [class*="_lg-7"] > [class~="p-col"],
  [class*="_lg-7"] > [class*="p-col-"],
  [class*="_lg-7"] > [class*="p-col_"] {
    flex-basis: 14.28571%;
    max-width: 14.28571%; }
  [class*="_lg-8"] > [class~="p-col"],
  [class*="_lg-8"] > [class*="p-col-"],
  [class*="_lg-8"] > [class*="p-col_"] {
    flex-basis: 12.5%;
    max-width: 12.5%; }
  [class*="_lg-9"] > [class~="p-col"],
  [class*="_lg-9"] > [class*="p-col-"],
  [class*="_lg-9"] > [class*="p-col_"] {
    flex-basis: 11.11111%;
    max-width: 11.11111%; }
  [class*="_lg-10"] > [class~="p-col"],
  [class*="_lg-10"] > [class*="p-col-"],
  [class*="_lg-10"] > [class*="p-col_"] {
    flex-basis: 10%;
    max-width: 10%; }
  [class*="_lg-11"] > [class~="p-col"],
  [class*="_lg-11"] > [class*="p-col-"],
  [class*="_lg-11"] > [class*="p-col_"] {
    flex-basis: 9.09091%;
    max-width: 9.09091%; }
  [class*="_lg-12"] > [class~="p-col"],
  [class*="_lg-12"] > [class*="p-col-"],
  [class*="_lg-12"] > [class*="p-col_"] {
    flex-basis: 8.33333%;
    max-width: 8.33333%; } }

@media (max-width: 1024px) {
  [class*="_md-1"] > [class~="p-col"],
  [class*="_md-1"] > [class*="p-col-"],
  [class*="_md-1"] > [class*="p-col_"] {
    flex-basis: 100%;
    max-width: 100%; }
  [class*="_md-2"] > [class~="p-col"],
  [class*="_md-2"] > [class*="p-col-"],
  [class*="_md-2"] > [class*="p-col_"] {
    flex-basis: 50%;
    max-width: 50%; }
  [class*="_md-3"] > [class~="p-col"],
  [class*="_md-3"] > [class*="p-col-"],
  [class*="_md-3"] > [class*="p-col_"] {
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  [class*="_md-4"] > [class~="p-col"],
  [class*="_md-4"] > [class*="p-col-"],
  [class*="_md-4"] > [class*="p-col_"] {
    flex-basis: 25%;
    max-width: 25%; }
  [class*="_md-5"] > [class~="p-col"],
  [class*="_md-5"] > [class*="p-col-"],
  [class*="_md-5"] > [class*="p-col_"] {
    flex-basis: 20%;
    max-width: 20%; }
  [class*="_md-6"] > [class~="p-col"],
  [class*="_md-6"] > [class*="p-col-"],
  [class*="_md-6"] > [class*="p-col_"] {
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  [class*="_md-7"] > [class~="p-col"],
  [class*="_md-7"] > [class*="p-col-"],
  [class*="_md-7"] > [class*="p-col_"] {
    flex-basis: 14.28571%;
    max-width: 14.28571%; }
  [class*="_md-8"] > [class~="p-col"],
  [class*="_md-8"] > [class*="p-col-"],
  [class*="_md-8"] > [class*="p-col_"] {
    flex-basis: 12.5%;
    max-width: 12.5%; }
  [class*="_md-9"] > [class~="p-col"],
  [class*="_md-9"] > [class*="p-col-"],
  [class*="_md-9"] > [class*="p-col_"] {
    flex-basis: 11.11111%;
    max-width: 11.11111%; }
  [class*="_md-10"] > [class~="p-col"],
  [class*="_md-10"] > [class*="p-col-"],
  [class*="_md-10"] > [class*="p-col_"] {
    flex-basis: 10%;
    max-width: 10%; }
  [class*="_md-11"] > [class~="p-col"],
  [class*="_md-11"] > [class*="p-col-"],
  [class*="_md-11"] > [class*="p-col_"] {
    flex-basis: 9.09091%;
    max-width: 9.09091%; }
  [class*="_md-12"] > [class~="p-col"],
  [class*="_md-12"] > [class*="p-col-"],
  [class*="_md-12"] > [class*="p-col_"] {
    flex-basis: 8.33333%;
    max-width: 8.33333%; } }

@media (max-width: 768px) {
  [class*="_sm-1"] > [class~="p-col"],
  [class*="_sm-1"] > [class*="p-col-"],
  [class*="_sm-1"] > [class*="p-col_"] {
    flex-basis: 100%;
    max-width: 100%; }
  [class*="_sm-2"] > [class~="p-col"],
  [class*="_sm-2"] > [class*="p-col-"],
  [class*="_sm-2"] > [class*="p-col_"] {
    flex-basis: 50%;
    max-width: 50%; }
  [class*="_sm-3"] > [class~="p-col"],
  [class*="_sm-3"] > [class*="p-col-"],
  [class*="_sm-3"] > [class*="p-col_"] {
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  [class*="_sm-4"] > [class~="p-col"],
  [class*="_sm-4"] > [class*="p-col-"],
  [class*="_sm-4"] > [class*="p-col_"] {
    flex-basis: 25%;
    max-width: 25%; }
  [class*="_sm-5"] > [class~="p-col"],
  [class*="_sm-5"] > [class*="p-col-"],
  [class*="_sm-5"] > [class*="p-col_"] {
    flex-basis: 20%;
    max-width: 20%; }
  [class*="_sm-6"] > [class~="p-col"],
  [class*="_sm-6"] > [class*="p-col-"],
  [class*="_sm-6"] > [class*="p-col_"] {
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  [class*="_sm-7"] > [class~="p-col"],
  [class*="_sm-7"] > [class*="p-col-"],
  [class*="_sm-7"] > [class*="p-col_"] {
    flex-basis: 14.28571%;
    max-width: 14.28571%; }
  [class*="_sm-8"] > [class~="p-col"],
  [class*="_sm-8"] > [class*="p-col-"],
  [class*="_sm-8"] > [class*="p-col_"] {
    flex-basis: 12.5%;
    max-width: 12.5%; }
  [class*="_sm-9"] > [class~="p-col"],
  [class*="_sm-9"] > [class*="p-col-"],
  [class*="_sm-9"] > [class*="p-col_"] {
    flex-basis: 11.11111%;
    max-width: 11.11111%; }
  [class*="_sm-10"] > [class~="p-col"],
  [class*="_sm-10"] > [class*="p-col-"],
  [class*="_sm-10"] > [class*="p-col_"] {
    flex-basis: 10%;
    max-width: 10%; }
  [class*="_sm-11"] > [class~="p-col"],
  [class*="_sm-11"] > [class*="p-col-"],
  [class*="_sm-11"] > [class*="p-col_"] {
    flex-basis: 9.09091%;
    max-width: 9.09091%; }
  [class*="_sm-12"] > [class~="p-col"],
  [class*="_sm-12"] > [class*="p-col-"],
  [class*="_sm-12"] > [class*="p-col_"] {
    flex-basis: 8.33333%;
    max-width: 8.33333%; } }

@media (max-width: 480px) {
  [class*="_xs-1"] > [class~="p-col"],
  [class*="_xs-1"] > [class*="p-col-"],
  [class*="_xs-1"] > [class*="p-col_"] {
    flex-basis: 100%;
    max-width: 100%; }
  [class*="_xs-2"] > [class~="p-col"],
  [class*="_xs-2"] > [class*="p-col-"],
  [class*="_xs-2"] > [class*="p-col_"] {
    flex-basis: 50%;
    max-width: 50%; }
  [class*="_xs-3"] > [class~="p-col"],
  [class*="_xs-3"] > [class*="p-col-"],
  [class*="_xs-3"] > [class*="p-col_"] {
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  [class*="_xs-4"] > [class~="p-col"],
  [class*="_xs-4"] > [class*="p-col-"],
  [class*="_xs-4"] > [class*="p-col_"] {
    flex-basis: 25%;
    max-width: 25%; }
  [class*="_xs-5"] > [class~="p-col"],
  [class*="_xs-5"] > [class*="p-col-"],
  [class*="_xs-5"] > [class*="p-col_"] {
    flex-basis: 20%;
    max-width: 20%; }
  [class*="_xs-6"] > [class~="p-col"],
  [class*="_xs-6"] > [class*="p-col-"],
  [class*="_xs-6"] > [class*="p-col_"] {
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  [class*="_xs-7"] > [class~="p-col"],
  [class*="_xs-7"] > [class*="p-col-"],
  [class*="_xs-7"] > [class*="p-col_"] {
    flex-basis: 14.28571%;
    max-width: 14.28571%; }
  [class*="_xs-8"] > [class~="p-col"],
  [class*="_xs-8"] > [class*="p-col-"],
  [class*="_xs-8"] > [class*="p-col_"] {
    flex-basis: 12.5%;
    max-width: 12.5%; }
  [class*="_xs-9"] > [class~="p-col"],
  [class*="_xs-9"] > [class*="p-col-"],
  [class*="_xs-9"] > [class*="p-col_"] {
    flex-basis: 11.11111%;
    max-width: 11.11111%; }
  [class*="_xs-10"] > [class~="p-col"],
  [class*="_xs-10"] > [class*="p-col-"],
  [class*="_xs-10"] > [class*="p-col_"] {
    flex-basis: 10%;
    max-width: 10%; }
  [class*="_xs-11"] > [class~="p-col"],
  [class*="_xs-11"] > [class*="p-col-"],
  [class*="_xs-11"] > [class*="p-col_"] {
    flex-basis: 9.09091%;
    max-width: 9.09091%; }
  [class*="_xs-12"] > [class~="p-col"],
  [class*="_xs-12"] > [class*="p-col-"],
  [class*="_xs-12"] > [class*="p-col_"] {
    flex-basis: 8.33333%;
    max-width: 8.33333%; } }

/************************
    COLS SIZES
*************************/
[class~="p-grid"] > [class*="p-col-1"],
[class*="p-grid-"] > [class*="p-col-1"],
[class*="p-grid_"] > [class*="p-col-1"] {
  flex-basis: 8.33333%;
  max-width: 8.33333%; }

[class~="p-grid"] > [class*="p-col-2"],
[class*="p-grid-"] > [class*="p-col-2"],
[class*="p-grid_"] > [class*="p-col-2"] {
  flex-basis: 16.66667%;
  max-width: 16.66667%; }

[class~="p-grid"] > [class*="p-col-3"],
[class*="p-grid-"] > [class*="p-col-3"],
[class*="p-grid_"] > [class*="p-col-3"] {
  flex-basis: 25%;
  max-width: 25%; }

[class~="p-grid"] > [class*="p-col-4"],
[class*="p-grid-"] > [class*="p-col-4"],
[class*="p-grid_"] > [class*="p-col-4"] {
  flex-basis: 33.33333%;
  max-width: 33.33333%; }

[class~="p-grid"] > [class*="p-col-5"],
[class*="p-grid-"] > [class*="p-col-5"],
[class*="p-grid_"] > [class*="p-col-5"] {
  flex-basis: 41.66667%;
  max-width: 41.66667%; }

[class~="p-grid"] > [class*="p-col-6"],
[class*="p-grid-"] > [class*="p-col-6"],
[class*="p-grid_"] > [class*="p-col-6"] {
  flex-basis: 50%;
  max-width: 50%; }

[class~="p-grid"] > [class*="p-col-7"],
[class*="p-grid-"] > [class*="p-col-7"],
[class*="p-grid_"] > [class*="p-col-7"] {
  flex-basis: 58.33333%;
  max-width: 58.33333%; }

[class~="p-grid"] > [class*="p-col-8"],
[class*="p-grid-"] > [class*="p-col-8"],
[class*="p-grid_"] > [class*="p-col-8"] {
  flex-basis: 66.66667%;
  max-width: 66.66667%; }

[class~="p-grid"] > [class*="p-col-9"],
[class*="p-grid-"] > [class*="p-col-9"],
[class*="p-grid_"] > [class*="p-col-9"] {
  flex-basis: 75%;
  max-width: 75%; }

[class~="p-grid"] > [class*="p-col-10"],
[class*="p-grid-"] > [class*="p-col-10"],
[class*="p-grid_"] > [class*="p-col-10"] {
  flex-basis: 83.33333%;
  max-width: 83.33333%; }

[class~="p-grid"] > [class*="p-col-11"],
[class*="p-grid-"] > [class*="p-col-11"],
[class*="p-grid_"] > [class*="p-col-11"] {
  flex-basis: 91.66667%;
  max-width: 91.66667%; }

[class~="p-grid"] > [class*="p-col-12"],
[class*="p-grid-"] > [class*="p-col-12"],
[class*="p-grid_"] > [class*="p-col-12"] {
  flex-basis: 100%;
  max-width: 100%; }

[class~="p-grid"] > [data-push-left*="off-0"],
[class*="p-grid-"] > [data-push-left*="off-0"],
[class*="p-grid_"] > [data-push-left*="off-0"] {
  margin-left: 0; }

[class~="p-grid"] > [data-push-left*="off-1"],
[class*="p-grid-"] > [data-push-left*="off-1"],
[class*="p-grid_"] > [data-push-left*="off-1"] {
  margin-left: 8.33333%; }

[class~="p-grid"] > [data-push-left*="off-2"],
[class*="p-grid-"] > [data-push-left*="off-2"],
[class*="p-grid_"] > [data-push-left*="off-2"] {
  margin-left: 16.66667%; }

[class~="p-grid"] > [data-push-left*="off-3"],
[class*="p-grid-"] > [data-push-left*="off-3"],
[class*="p-grid_"] > [data-push-left*="off-3"] {
  margin-left: 25%; }

[class~="p-grid"] > [data-push-left*="off-4"],
[class*="p-grid-"] > [data-push-left*="off-4"],
[class*="p-grid_"] > [data-push-left*="off-4"] {
  margin-left: 33.33333%; }

[class~="p-grid"] > [data-push-left*="off-5"],
[class*="p-grid-"] > [data-push-left*="off-5"],
[class*="p-grid_"] > [data-push-left*="off-5"] {
  margin-left: 41.66667%; }

[class~="p-grid"] > [data-push-left*="off-6"],
[class*="p-grid-"] > [data-push-left*="off-6"],
[class*="p-grid_"] > [data-push-left*="off-6"] {
  margin-left: 50%; }

[class~="p-grid"] > [data-push-left*="off-7"],
[class*="p-grid-"] > [data-push-left*="off-7"],
[class*="p-grid_"] > [data-push-left*="off-7"] {
  margin-left: 58.33333%; }

[class~="p-grid"] > [data-push-left*="off-8"],
[class*="p-grid-"] > [data-push-left*="off-8"],
[class*="p-grid_"] > [data-push-left*="off-8"] {
  margin-left: 66.66667%; }

[class~="p-grid"] > [data-push-left*="off-9"],
[class*="p-grid-"] > [data-push-left*="off-9"],
[class*="p-grid_"] > [data-push-left*="off-9"] {
  margin-left: 75%; }

[class~="p-grid"] > [data-push-left*="off-10"],
[class*="p-grid-"] > [data-push-left*="off-10"],
[class*="p-grid_"] > [data-push-left*="off-10"] {
  margin-left: 83.33333%; }

[class~="p-grid"] > [data-push-left*="off-11"],
[class*="p-grid-"] > [data-push-left*="off-11"],
[class*="p-grid_"] > [data-push-left*="off-11"] {
  margin-left: 91.66667%; }

[class~="p-grid"] > [data-push-right*="off-0"],
[class*="p-grid-"] > [data-push-right*="off-0"],
[class*="p-grid_"] > [data-push-right*="off-0"] {
  margin-right: 0; }

[class~="p-grid"] > [data-push-right*="off-1"],
[class*="p-grid-"] > [data-push-right*="off-1"],
[class*="p-grid_"] > [data-push-right*="off-1"] {
  margin-right: 8.33333%; }

[class~="p-grid"] > [data-push-right*="off-2"],
[class*="p-grid-"] > [data-push-right*="off-2"],
[class*="p-grid_"] > [data-push-right*="off-2"] {
  margin-right: 16.66667%; }

[class~="p-grid"] > [data-push-right*="off-3"],
[class*="p-grid-"] > [data-push-right*="off-3"],
[class*="p-grid_"] > [data-push-right*="off-3"] {
  margin-right: 25%; }

[class~="p-grid"] > [data-push-right*="off-4"],
[class*="p-grid-"] > [data-push-right*="off-4"],
[class*="p-grid_"] > [data-push-right*="off-4"] {
  margin-right: 33.33333%; }

[class~="p-grid"] > [data-push-right*="off-5"],
[class*="p-grid-"] > [data-push-right*="off-5"],
[class*="p-grid_"] > [data-push-right*="off-5"] {
  margin-right: 41.66667%; }

[class~="p-grid"] > [data-push-right*="off-6"],
[class*="p-grid-"] > [data-push-right*="off-6"],
[class*="p-grid_"] > [data-push-right*="off-6"] {
  margin-right: 50%; }

[class~="p-grid"] > [data-push-right*="off-7"],
[class*="p-grid-"] > [data-push-right*="off-7"],
[class*="p-grid_"] > [data-push-right*="off-7"] {
  margin-right: 58.33333%; }

[class~="p-grid"] > [data-push-right*="off-8"],
[class*="p-grid-"] > [data-push-right*="off-8"],
[class*="p-grid_"] > [data-push-right*="off-8"] {
  margin-right: 66.66667%; }

[class~="p-grid"] > [data-push-right*="off-9"],
[class*="p-grid-"] > [data-push-right*="off-9"],
[class*="p-grid_"] > [data-push-right*="off-9"] {
  margin-right: 75%; }

[class~="p-grid"] > [data-push-right*="off-10"],
[class*="p-grid-"] > [data-push-right*="off-10"],
[class*="p-grid_"] > [data-push-right*="off-10"] {
  margin-right: 83.33333%; }

[class~="p-grid"] > [data-push-right*="off-11"],
[class*="p-grid-"] > [data-push-right*="off-11"],
[class*="p-grid_"] > [data-push-right*="off-11"] {
  margin-right: 91.66667%; }

@media (max-width: 1200px) {
  [class~="p-grid"] > [class*="_lg-1"],
  [class*="p-grid-"] > [class*="_lg-1"],
  [class*="p-grid_"] > [class*="_lg-1"] {
    flex-basis: 8.33333%;
    max-width: 8.33333%; }
  [class~="p-grid"] > [class*="_lg-2"],
  [class*="p-grid-"] > [class*="_lg-2"],
  [class*="p-grid_"] > [class*="_lg-2"] {
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  [class~="p-grid"] > [class*="_lg-3"],
  [class*="p-grid-"] > [class*="_lg-3"],
  [class*="p-grid_"] > [class*="_lg-3"] {
    flex-basis: 25%;
    max-width: 25%; }
  [class~="p-grid"] > [class*="_lg-4"],
  [class*="p-grid-"] > [class*="_lg-4"],
  [class*="p-grid_"] > [class*="_lg-4"] {
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  [class~="p-grid"] > [class*="_lg-5"],
  [class*="p-grid-"] > [class*="_lg-5"],
  [class*="p-grid_"] > [class*="_lg-5"] {
    flex-basis: 41.66667%;
    max-width: 41.66667%; }
  [class~="p-grid"] > [class*="_lg-6"],
  [class*="p-grid-"] > [class*="_lg-6"],
  [class*="p-grid_"] > [class*="_lg-6"] {
    flex-basis: 50%;
    max-width: 50%; }
  [class~="p-grid"] > [class*="_lg-7"],
  [class*="p-grid-"] > [class*="_lg-7"],
  [class*="p-grid_"] > [class*="_lg-7"] {
    flex-basis: 58.33333%;
    max-width: 58.33333%; }
  [class~="p-grid"] > [class*="_lg-8"],
  [class*="p-grid-"] > [class*="_lg-8"],
  [class*="p-grid_"] > [class*="_lg-8"] {
    flex-basis: 66.66667%;
    max-width: 66.66667%; }
  [class~="p-grid"] > [class*="_lg-9"],
  [class*="p-grid-"] > [class*="_lg-9"],
  [class*="p-grid_"] > [class*="_lg-9"] {
    flex-basis: 75%;
    max-width: 75%; }
  [class~="p-grid"] > [class*="_lg-10"],
  [class*="p-grid-"] > [class*="_lg-10"],
  [class*="p-grid_"] > [class*="_lg-10"] {
    flex-basis: 83.33333%;
    max-width: 83.33333%; }
  [class~="p-grid"] > [class*="_lg-11"],
  [class*="p-grid-"] > [class*="_lg-11"],
  [class*="p-grid_"] > [class*="_lg-11"] {
    flex-basis: 91.66667%;
    max-width: 91.66667%; }
  [class~="p-grid"] > [class*="_lg-12"],
  [class*="p-grid-"] > [class*="_lg-12"],
  [class*="p-grid_"] > [class*="_lg-12"] {
    flex-basis: 100%;
    max-width: 100%; }
  [class~="p-grid"] > [data-push-left*="_lg-0"],
  [class*="p-grid-"] > [data-push-left*="_lg-0"],
  [class*="p-grid_"] > [data-push-left*="_lg-0"] {
    margin-left: 0; }
  [class~="p-grid"] > [data-push-left*="_lg-1"],
  [class*="p-grid-"] > [data-push-left*="_lg-1"],
  [class*="p-grid_"] > [data-push-left*="_lg-1"] {
    margin-left: 8.33333%; }
  [class~="p-grid"] > [data-push-left*="_lg-2"],
  [class*="p-grid-"] > [data-push-left*="_lg-2"],
  [class*="p-grid_"] > [data-push-left*="_lg-2"] {
    margin-left: 16.66667%; }
  [class~="p-grid"] > [data-push-left*="_lg-3"],
  [class*="p-grid-"] > [data-push-left*="_lg-3"],
  [class*="p-grid_"] > [data-push-left*="_lg-3"] {
    margin-left: 25%; }
  [class~="p-grid"] > [data-push-left*="_lg-4"],
  [class*="p-grid-"] > [data-push-left*="_lg-4"],
  [class*="p-grid_"] > [data-push-left*="_lg-4"] {
    margin-left: 33.33333%; }
  [class~="p-grid"] > [data-push-left*="_lg-5"],
  [class*="p-grid-"] > [data-push-left*="_lg-5"],
  [class*="p-grid_"] > [data-push-left*="_lg-5"] {
    margin-left: 41.66667%; }
  [class~="p-grid"] > [data-push-left*="_lg-6"],
  [class*="p-grid-"] > [data-push-left*="_lg-6"],
  [class*="p-grid_"] > [data-push-left*="_lg-6"] {
    margin-left: 50%; }
  [class~="p-grid"] > [data-push-left*="_lg-7"],
  [class*="p-grid-"] > [data-push-left*="_lg-7"],
  [class*="p-grid_"] > [data-push-left*="_lg-7"] {
    margin-left: 58.33333%; }
  [class~="p-grid"] > [data-push-left*="_lg-8"],
  [class*="p-grid-"] > [data-push-left*="_lg-8"],
  [class*="p-grid_"] > [data-push-left*="_lg-8"] {
    margin-left: 66.66667%; }
  [class~="p-grid"] > [data-push-left*="_lg-9"],
  [class*="p-grid-"] > [data-push-left*="_lg-9"],
  [class*="p-grid_"] > [data-push-left*="_lg-9"] {
    margin-left: 75%; }
  [class~="p-grid"] > [data-push-left*="_lg-10"],
  [class*="p-grid-"] > [data-push-left*="_lg-10"],
  [class*="p-grid_"] > [data-push-left*="_lg-10"] {
    margin-left: 83.33333%; }
  [class~="p-grid"] > [data-push-left*="_lg-11"],
  [class*="p-grid-"] > [data-push-left*="_lg-11"],
  [class*="p-grid_"] > [data-push-left*="_lg-11"] {
    margin-left: 91.66667%; }
  [class~="p-grid"] > [data-push-right*="_lg-0"],
  [class*="p-grid-"] > [data-push-right*="_lg-0"],
  [class*="p-grid_"] > [data-push-right*="_lg-0"] {
    margin-right: 0; }
  [class~="p-grid"] > [data-push-right*="_lg-1"],
  [class*="p-grid-"] > [data-push-right*="_lg-1"],
  [class*="p-grid_"] > [data-push-right*="_lg-1"] {
    margin-right: 8.33333%; }
  [class~="p-grid"] > [data-push-right*="_lg-2"],
  [class*="p-grid-"] > [data-push-right*="_lg-2"],
  [class*="p-grid_"] > [data-push-right*="_lg-2"] {
    margin-right: 16.66667%; }
  [class~="p-grid"] > [data-push-right*="_lg-3"],
  [class*="p-grid-"] > [data-push-right*="_lg-3"],
  [class*="p-grid_"] > [data-push-right*="_lg-3"] {
    margin-right: 25%; }
  [class~="p-grid"] > [data-push-right*="_lg-4"],
  [class*="p-grid-"] > [data-push-right*="_lg-4"],
  [class*="p-grid_"] > [data-push-right*="_lg-4"] {
    margin-right: 33.33333%; }
  [class~="p-grid"] > [data-push-right*="_lg-5"],
  [class*="p-grid-"] > [data-push-right*="_lg-5"],
  [class*="p-grid_"] > [data-push-right*="_lg-5"] {
    margin-right: 41.66667%; }
  [class~="p-grid"] > [data-push-right*="_lg-6"],
  [class*="p-grid-"] > [data-push-right*="_lg-6"],
  [class*="p-grid_"] > [data-push-right*="_lg-6"] {
    margin-right: 50%; }
  [class~="p-grid"] > [data-push-right*="_lg-7"],
  [class*="p-grid-"] > [data-push-right*="_lg-7"],
  [class*="p-grid_"] > [data-push-right*="_lg-7"] {
    margin-right: 58.33333%; }
  [class~="p-grid"] > [data-push-right*="_lg-8"],
  [class*="p-grid-"] > [data-push-right*="_lg-8"],
  [class*="p-grid_"] > [data-push-right*="_lg-8"] {
    margin-right: 66.66667%; }
  [class~="p-grid"] > [data-push-right*="_lg-9"],
  [class*="p-grid-"] > [data-push-right*="_lg-9"],
  [class*="p-grid_"] > [data-push-right*="_lg-9"] {
    margin-right: 75%; }
  [class~="p-grid"] > [data-push-right*="_lg-10"],
  [class*="p-grid-"] > [data-push-right*="_lg-10"],
  [class*="p-grid_"] > [data-push-right*="_lg-10"] {
    margin-right: 83.33333%; }
  [class~="p-grid"] > [data-push-right*="_lg-11"],
  [class*="p-grid-"] > [data-push-right*="_lg-11"],
  [class*="p-grid_"] > [data-push-right*="_lg-11"] {
    margin-right: 91.66667%; }
  [class~="p-grid"] [class*="_lg-first"],
  [class*="p-grid-"] [class*="_lg-first"],
  [class*="p-grid_"] [class*="_lg-first"] {
    order: -1; }
  [class~="p-grid"] [class*="_lg-last"],
  [class*="p-grid-"] [class*="_lg-last"],
  [class*="p-grid_"] [class*="_lg-last"] {
    order: 1; } }

@media (max-width: 1024px) {
  [class~="p-grid"] > [class*="_md-1"],
  [class*="p-grid-"] > [class*="_md-1"],
  [class*="p-grid_"] > [class*="_md-1"] {
    flex-basis: 8.33333%;
    max-width: 8.33333%; }
  [class~="p-grid"] > [class*="_md-2"],
  [class*="p-grid-"] > [class*="_md-2"],
  [class*="p-grid_"] > [class*="_md-2"] {
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  [class~="p-grid"] > [class*="_md-3"],
  [class*="p-grid-"] > [class*="_md-3"],
  [class*="p-grid_"] > [class*="_md-3"] {
    flex-basis: 25%;
    max-width: 25%; }
  [class~="p-grid"] > [class*="_md-4"],
  [class*="p-grid-"] > [class*="_md-4"],
  [class*="p-grid_"] > [class*="_md-4"] {
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  [class~="p-grid"] > [class*="_md-5"],
  [class*="p-grid-"] > [class*="_md-5"],
  [class*="p-grid_"] > [class*="_md-5"] {
    flex-basis: 41.66667%;
    max-width: 41.66667%; }
  [class~="p-grid"] > [class*="_md-6"],
  [class*="p-grid-"] > [class*="_md-6"],
  [class*="p-grid_"] > [class*="_md-6"] {
    flex-basis: 50%;
    max-width: 50%; }
  [class~="p-grid"] > [class*="_md-7"],
  [class*="p-grid-"] > [class*="_md-7"],
  [class*="p-grid_"] > [class*="_md-7"] {
    flex-basis: 58.33333%;
    max-width: 58.33333%; }
  [class~="p-grid"] > [class*="_md-8"],
  [class*="p-grid-"] > [class*="_md-8"],
  [class*="p-grid_"] > [class*="_md-8"] {
    flex-basis: 66.66667%;
    max-width: 66.66667%; }
  [class~="p-grid"] > [class*="_md-9"],
  [class*="p-grid-"] > [class*="_md-9"],
  [class*="p-grid_"] > [class*="_md-9"] {
    flex-basis: 75%;
    max-width: 75%; }
  [class~="p-grid"] > [class*="_md-10"],
  [class*="p-grid-"] > [class*="_md-10"],
  [class*="p-grid_"] > [class*="_md-10"] {
    flex-basis: 83.33333%;
    max-width: 83.33333%; }
  [class~="p-grid"] > [class*="_md-11"],
  [class*="p-grid-"] > [class*="_md-11"],
  [class*="p-grid_"] > [class*="_md-11"] {
    flex-basis: 91.66667%;
    max-width: 91.66667%; }
  [class~="p-grid"] > [class*="_md-12"],
  [class*="p-grid-"] > [class*="_md-12"],
  [class*="p-grid_"] > [class*="_md-12"] {
    flex-basis: 100%;
    max-width: 100%; }
  [class~="p-grid"] > [data-push-left*="_md-0"],
  [class*="p-grid-"] > [data-push-left*="_md-0"],
  [class*="p-grid_"] > [data-push-left*="_md-0"] {
    margin-left: 0; }
  [class~="p-grid"] > [data-push-left*="_md-1"],
  [class*="p-grid-"] > [data-push-left*="_md-1"],
  [class*="p-grid_"] > [data-push-left*="_md-1"] {
    margin-left: 8.33333%; }
  [class~="p-grid"] > [data-push-left*="_md-2"],
  [class*="p-grid-"] > [data-push-left*="_md-2"],
  [class*="p-grid_"] > [data-push-left*="_md-2"] {
    margin-left: 16.66667%; }
  [class~="p-grid"] > [data-push-left*="_md-3"],
  [class*="p-grid-"] > [data-push-left*="_md-3"],
  [class*="p-grid_"] > [data-push-left*="_md-3"] {
    margin-left: 25%; }
  [class~="p-grid"] > [data-push-left*="_md-4"],
  [class*="p-grid-"] > [data-push-left*="_md-4"],
  [class*="p-grid_"] > [data-push-left*="_md-4"] {
    margin-left: 33.33333%; }
  [class~="p-grid"] > [data-push-left*="_md-5"],
  [class*="p-grid-"] > [data-push-left*="_md-5"],
  [class*="p-grid_"] > [data-push-left*="_md-5"] {
    margin-left: 41.66667%; }
  [class~="p-grid"] > [data-push-left*="_md-6"],
  [class*="p-grid-"] > [data-push-left*="_md-6"],
  [class*="p-grid_"] > [data-push-left*="_md-6"] {
    margin-left: 50%; }
  [class~="p-grid"] > [data-push-left*="_md-7"],
  [class*="p-grid-"] > [data-push-left*="_md-7"],
  [class*="p-grid_"] > [data-push-left*="_md-7"] {
    margin-left: 58.33333%; }
  [class~="p-grid"] > [data-push-left*="_md-8"],
  [class*="p-grid-"] > [data-push-left*="_md-8"],
  [class*="p-grid_"] > [data-push-left*="_md-8"] {
    margin-left: 66.66667%; }
  [class~="p-grid"] > [data-push-left*="_md-9"],
  [class*="p-grid-"] > [data-push-left*="_md-9"],
  [class*="p-grid_"] > [data-push-left*="_md-9"] {
    margin-left: 75%; }
  [class~="p-grid"] > [data-push-left*="_md-10"],
  [class*="p-grid-"] > [data-push-left*="_md-10"],
  [class*="p-grid_"] > [data-push-left*="_md-10"] {
    margin-left: 83.33333%; }
  [class~="p-grid"] > [data-push-left*="_md-11"],
  [class*="p-grid-"] > [data-push-left*="_md-11"],
  [class*="p-grid_"] > [data-push-left*="_md-11"] {
    margin-left: 91.66667%; }
  [class~="p-grid"] > [data-push-right*="_md-0"],
  [class*="p-grid-"] > [data-push-right*="_md-0"],
  [class*="p-grid_"] > [data-push-right*="_md-0"] {
    margin-right: 0; }
  [class~="p-grid"] > [data-push-right*="_md-1"],
  [class*="p-grid-"] > [data-push-right*="_md-1"],
  [class*="p-grid_"] > [data-push-right*="_md-1"] {
    margin-right: 8.33333%; }
  [class~="p-grid"] > [data-push-right*="_md-2"],
  [class*="p-grid-"] > [data-push-right*="_md-2"],
  [class*="p-grid_"] > [data-push-right*="_md-2"] {
    margin-right: 16.66667%; }
  [class~="p-grid"] > [data-push-right*="_md-3"],
  [class*="p-grid-"] > [data-push-right*="_md-3"],
  [class*="p-grid_"] > [data-push-right*="_md-3"] {
    margin-right: 25%; }
  [class~="p-grid"] > [data-push-right*="_md-4"],
  [class*="p-grid-"] > [data-push-right*="_md-4"],
  [class*="p-grid_"] > [data-push-right*="_md-4"] {
    margin-right: 33.33333%; }
  [class~="p-grid"] > [data-push-right*="_md-5"],
  [class*="p-grid-"] > [data-push-right*="_md-5"],
  [class*="p-grid_"] > [data-push-right*="_md-5"] {
    margin-right: 41.66667%; }
  [class~="p-grid"] > [data-push-right*="_md-6"],
  [class*="p-grid-"] > [data-push-right*="_md-6"],
  [class*="p-grid_"] > [data-push-right*="_md-6"] {
    margin-right: 50%; }
  [class~="p-grid"] > [data-push-right*="_md-7"],
  [class*="p-grid-"] > [data-push-right*="_md-7"],
  [class*="p-grid_"] > [data-push-right*="_md-7"] {
    margin-right: 58.33333%; }
  [class~="p-grid"] > [data-push-right*="_md-8"],
  [class*="p-grid-"] > [data-push-right*="_md-8"],
  [class*="p-grid_"] > [data-push-right*="_md-8"] {
    margin-right: 66.66667%; }
  [class~="p-grid"] > [data-push-right*="_md-9"],
  [class*="p-grid-"] > [data-push-right*="_md-9"],
  [class*="p-grid_"] > [data-push-right*="_md-9"] {
    margin-right: 75%; }
  [class~="p-grid"] > [data-push-right*="_md-10"],
  [class*="p-grid-"] > [data-push-right*="_md-10"],
  [class*="p-grid_"] > [data-push-right*="_md-10"] {
    margin-right: 83.33333%; }
  [class~="p-grid"] > [data-push-right*="_md-11"],
  [class*="p-grid-"] > [data-push-right*="_md-11"],
  [class*="p-grid_"] > [data-push-right*="_md-11"] {
    margin-right: 91.66667%; }
  [class~="p-grid"] [class*="_md-first"],
  [class*="p-grid-"] [class*="_md-first"],
  [class*="p-grid_"] [class*="_md-first"] {
    order: -1; }
  [class~="p-grid"] [class*="_md-last"],
  [class*="p-grid-"] [class*="_md-last"],
  [class*="p-grid_"] [class*="_md-last"] {
    order: 1; } }

@media (max-width: 768px) {
  [class~="p-grid"] > [class*="_sm-1"],
  [class*="p-grid-"] > [class*="_sm-1"],
  [class*="p-grid_"] > [class*="_sm-1"] {
    flex-basis: 8.33333%;
    max-width: 8.33333%; }
  [class~="p-grid"] > [class*="_sm-2"],
  [class*="p-grid-"] > [class*="_sm-2"],
  [class*="p-grid_"] > [class*="_sm-2"] {
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  [class~="p-grid"] > [class*="_sm-3"],
  [class*="p-grid-"] > [class*="_sm-3"],
  [class*="p-grid_"] > [class*="_sm-3"] {
    flex-basis: 25%;
    max-width: 25%; }
  [class~="p-grid"] > [class*="_sm-4"],
  [class*="p-grid-"] > [class*="_sm-4"],
  [class*="p-grid_"] > [class*="_sm-4"] {
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  [class~="p-grid"] > [class*="_sm-5"],
  [class*="p-grid-"] > [class*="_sm-5"],
  [class*="p-grid_"] > [class*="_sm-5"] {
    flex-basis: 41.66667%;
    max-width: 41.66667%; }
  [class~="p-grid"] > [class*="_sm-6"],
  [class*="p-grid-"] > [class*="_sm-6"],
  [class*="p-grid_"] > [class*="_sm-6"] {
    flex-basis: 50%;
    max-width: 50%; }
  [class~="p-grid"] > [class*="_sm-7"],
  [class*="p-grid-"] > [class*="_sm-7"],
  [class*="p-grid_"] > [class*="_sm-7"] {
    flex-basis: 58.33333%;
    max-width: 58.33333%; }
  [class~="p-grid"] > [class*="_sm-8"],
  [class*="p-grid-"] > [class*="_sm-8"],
  [class*="p-grid_"] > [class*="_sm-8"] {
    flex-basis: 66.66667%;
    max-width: 66.66667%; }
  [class~="p-grid"] > [class*="_sm-9"],
  [class*="p-grid-"] > [class*="_sm-9"],
  [class*="p-grid_"] > [class*="_sm-9"] {
    flex-basis: 75%;
    max-width: 75%; }
  [class~="p-grid"] > [class*="_sm-10"],
  [class*="p-grid-"] > [class*="_sm-10"],
  [class*="p-grid_"] > [class*="_sm-10"] {
    flex-basis: 83.33333%;
    max-width: 83.33333%; }
  [class~="p-grid"] > [class*="_sm-11"],
  [class*="p-grid-"] > [class*="_sm-11"],
  [class*="p-grid_"] > [class*="_sm-11"] {
    flex-basis: 91.66667%;
    max-width: 91.66667%; }
  [class~="p-grid"] > [class*="_sm-12"],
  [class*="p-grid-"] > [class*="_sm-12"],
  [class*="p-grid_"] > [class*="_sm-12"] {
    flex-basis: 100%;
    max-width: 100%; }
  [class~="p-grid"] > [data-push-left*="_sm-0"],
  [class*="p-grid-"] > [data-push-left*="_sm-0"],
  [class*="p-grid_"] > [data-push-left*="_sm-0"] {
    margin-left: 0; }
  [class~="p-grid"] > [data-push-left*="_sm-1"],
  [class*="p-grid-"] > [data-push-left*="_sm-1"],
  [class*="p-grid_"] > [data-push-left*="_sm-1"] {
    margin-left: 8.33333%; }
  [class~="p-grid"] > [data-push-left*="_sm-2"],
  [class*="p-grid-"] > [data-push-left*="_sm-2"],
  [class*="p-grid_"] > [data-push-left*="_sm-2"] {
    margin-left: 16.66667%; }
  [class~="p-grid"] > [data-push-left*="_sm-3"],
  [class*="p-grid-"] > [data-push-left*="_sm-3"],
  [class*="p-grid_"] > [data-push-left*="_sm-3"] {
    margin-left: 25%; }
  [class~="p-grid"] > [data-push-left*="_sm-4"],
  [class*="p-grid-"] > [data-push-left*="_sm-4"],
  [class*="p-grid_"] > [data-push-left*="_sm-4"] {
    margin-left: 33.33333%; }
  [class~="p-grid"] > [data-push-left*="_sm-5"],
  [class*="p-grid-"] > [data-push-left*="_sm-5"],
  [class*="p-grid_"] > [data-push-left*="_sm-5"] {
    margin-left: 41.66667%; }
  [class~="p-grid"] > [data-push-left*="_sm-6"],
  [class*="p-grid-"] > [data-push-left*="_sm-6"],
  [class*="p-grid_"] > [data-push-left*="_sm-6"] {
    margin-left: 50%; }
  [class~="p-grid"] > [data-push-left*="_sm-7"],
  [class*="p-grid-"] > [data-push-left*="_sm-7"],
  [class*="p-grid_"] > [data-push-left*="_sm-7"] {
    margin-left: 58.33333%; }
  [class~="p-grid"] > [data-push-left*="_sm-8"],
  [class*="p-grid-"] > [data-push-left*="_sm-8"],
  [class*="p-grid_"] > [data-push-left*="_sm-8"] {
    margin-left: 66.66667%; }
  [class~="p-grid"] > [data-push-left*="_sm-9"],
  [class*="p-grid-"] > [data-push-left*="_sm-9"],
  [class*="p-grid_"] > [data-push-left*="_sm-9"] {
    margin-left: 75%; }
  [class~="p-grid"] > [data-push-left*="_sm-10"],
  [class*="p-grid-"] > [data-push-left*="_sm-10"],
  [class*="p-grid_"] > [data-push-left*="_sm-10"] {
    margin-left: 83.33333%; }
  [class~="p-grid"] > [data-push-left*="_sm-11"],
  [class*="p-grid-"] > [data-push-left*="_sm-11"],
  [class*="p-grid_"] > [data-push-left*="_sm-11"] {
    margin-left: 91.66667%; }
  [class~="p-grid"] > [data-push-right*="_sm-0"],
  [class*="p-grid-"] > [data-push-right*="_sm-0"],
  [class*="p-grid_"] > [data-push-right*="_sm-0"] {
    margin-right: 0; }
  [class~="p-grid"] > [data-push-right*="_sm-1"],
  [class*="p-grid-"] > [data-push-right*="_sm-1"],
  [class*="p-grid_"] > [data-push-right*="_sm-1"] {
    margin-right: 8.33333%; }
  [class~="p-grid"] > [data-push-right*="_sm-2"],
  [class*="p-grid-"] > [data-push-right*="_sm-2"],
  [class*="p-grid_"] > [data-push-right*="_sm-2"] {
    margin-right: 16.66667%; }
  [class~="p-grid"] > [data-push-right*="_sm-3"],
  [class*="p-grid-"] > [data-push-right*="_sm-3"],
  [class*="p-grid_"] > [data-push-right*="_sm-3"] {
    margin-right: 25%; }
  [class~="p-grid"] > [data-push-right*="_sm-4"],
  [class*="p-grid-"] > [data-push-right*="_sm-4"],
  [class*="p-grid_"] > [data-push-right*="_sm-4"] {
    margin-right: 33.33333%; }
  [class~="p-grid"] > [data-push-right*="_sm-5"],
  [class*="p-grid-"] > [data-push-right*="_sm-5"],
  [class*="p-grid_"] > [data-push-right*="_sm-5"] {
    margin-right: 41.66667%; }
  [class~="p-grid"] > [data-push-right*="_sm-6"],
  [class*="p-grid-"] > [data-push-right*="_sm-6"],
  [class*="p-grid_"] > [data-push-right*="_sm-6"] {
    margin-right: 50%; }
  [class~="p-grid"] > [data-push-right*="_sm-7"],
  [class*="p-grid-"] > [data-push-right*="_sm-7"],
  [class*="p-grid_"] > [data-push-right*="_sm-7"] {
    margin-right: 58.33333%; }
  [class~="p-grid"] > [data-push-right*="_sm-8"],
  [class*="p-grid-"] > [data-push-right*="_sm-8"],
  [class*="p-grid_"] > [data-push-right*="_sm-8"] {
    margin-right: 66.66667%; }
  [class~="p-grid"] > [data-push-right*="_sm-9"],
  [class*="p-grid-"] > [data-push-right*="_sm-9"],
  [class*="p-grid_"] > [data-push-right*="_sm-9"] {
    margin-right: 75%; }
  [class~="p-grid"] > [data-push-right*="_sm-10"],
  [class*="p-grid-"] > [data-push-right*="_sm-10"],
  [class*="p-grid_"] > [data-push-right*="_sm-10"] {
    margin-right: 83.33333%; }
  [class~="p-grid"] > [data-push-right*="_sm-11"],
  [class*="p-grid-"] > [data-push-right*="_sm-11"],
  [class*="p-grid_"] > [data-push-right*="_sm-11"] {
    margin-right: 91.66667%; }
  [class~="p-grid"] [class*="_sm-first"],
  [class*="p-grid-"] [class*="_sm-first"],
  [class*="p-grid_"] [class*="_sm-first"] {
    order: -1; }
  [class~="p-grid"] [class*="_sm-last"],
  [class*="p-grid-"] [class*="_sm-last"],
  [class*="p-grid_"] [class*="_sm-last"] {
    order: 1; } }

@media (max-width: 480px) {
  [class~="p-grid"] > [class*="_xs-1"],
  [class*="p-grid-"] > [class*="_xs-1"],
  [class*="p-grid_"] > [class*="_xs-1"] {
    flex-basis: 8.33333%;
    max-width: 8.33333%; }
  [class~="p-grid"] > [class*="_xs-2"],
  [class*="p-grid-"] > [class*="_xs-2"],
  [class*="p-grid_"] > [class*="_xs-2"] {
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  [class~="p-grid"] > [class*="_xs-3"],
  [class*="p-grid-"] > [class*="_xs-3"],
  [class*="p-grid_"] > [class*="_xs-3"] {
    flex-basis: 25%;
    max-width: 25%; }
  [class~="p-grid"] > [class*="_xs-4"],
  [class*="p-grid-"] > [class*="_xs-4"],
  [class*="p-grid_"] > [class*="_xs-4"] {
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  [class~="p-grid"] > [class*="_xs-5"],
  [class*="p-grid-"] > [class*="_xs-5"],
  [class*="p-grid_"] > [class*="_xs-5"] {
    flex-basis: 41.66667%;
    max-width: 41.66667%; }
  [class~="p-grid"] > [class*="_xs-6"],
  [class*="p-grid-"] > [class*="_xs-6"],
  [class*="p-grid_"] > [class*="_xs-6"] {
    flex-basis: 50%;
    max-width: 50%; }
  [class~="p-grid"] > [class*="_xs-7"],
  [class*="p-grid-"] > [class*="_xs-7"],
  [class*="p-grid_"] > [class*="_xs-7"] {
    flex-basis: 58.33333%;
    max-width: 58.33333%; }
  [class~="p-grid"] > [class*="_xs-8"],
  [class*="p-grid-"] > [class*="_xs-8"],
  [class*="p-grid_"] > [class*="_xs-8"] {
    flex-basis: 66.66667%;
    max-width: 66.66667%; }
  [class~="p-grid"] > [class*="_xs-9"],
  [class*="p-grid-"] > [class*="_xs-9"],
  [class*="p-grid_"] > [class*="_xs-9"] {
    flex-basis: 75%;
    max-width: 75%; }
  [class~="p-grid"] > [class*="_xs-10"],
  [class*="p-grid-"] > [class*="_xs-10"],
  [class*="p-grid_"] > [class*="_xs-10"] {
    flex-basis: 83.33333%;
    max-width: 83.33333%; }
  [class~="p-grid"] > [class*="_xs-11"],
  [class*="p-grid-"] > [class*="_xs-11"],
  [class*="p-grid_"] > [class*="_xs-11"] {
    flex-basis: 91.66667%;
    max-width: 91.66667%; }
  [class~="p-grid"] > [class*="_xs-12"],
  [class*="p-grid-"] > [class*="_xs-12"],
  [class*="p-grid_"] > [class*="_xs-12"] {
    flex-basis: 100%;
    max-width: 100%; }
  [class~="p-grid"] > [data-push-left*="_xs-0"],
  [class*="p-grid-"] > [data-push-left*="_xs-0"],
  [class*="p-grid_"] > [data-push-left*="_xs-0"] {
    margin-left: 0; }
  [class~="p-grid"] > [data-push-left*="_xs-1"],
  [class*="p-grid-"] > [data-push-left*="_xs-1"],
  [class*="p-grid_"] > [data-push-left*="_xs-1"] {
    margin-left: 8.33333%; }
  [class~="p-grid"] > [data-push-left*="_xs-2"],
  [class*="p-grid-"] > [data-push-left*="_xs-2"],
  [class*="p-grid_"] > [data-push-left*="_xs-2"] {
    margin-left: 16.66667%; }
  [class~="p-grid"] > [data-push-left*="_xs-3"],
  [class*="p-grid-"] > [data-push-left*="_xs-3"],
  [class*="p-grid_"] > [data-push-left*="_xs-3"] {
    margin-left: 25%; }
  [class~="p-grid"] > [data-push-left*="_xs-4"],
  [class*="p-grid-"] > [data-push-left*="_xs-4"],
  [class*="p-grid_"] > [data-push-left*="_xs-4"] {
    margin-left: 33.33333%; }
  [class~="p-grid"] > [data-push-left*="_xs-5"],
  [class*="p-grid-"] > [data-push-left*="_xs-5"],
  [class*="p-grid_"] > [data-push-left*="_xs-5"] {
    margin-left: 41.66667%; }
  [class~="p-grid"] > [data-push-left*="_xs-6"],
  [class*="p-grid-"] > [data-push-left*="_xs-6"],
  [class*="p-grid_"] > [data-push-left*="_xs-6"] {
    margin-left: 50%; }
  [class~="p-grid"] > [data-push-left*="_xs-7"],
  [class*="p-grid-"] > [data-push-left*="_xs-7"],
  [class*="p-grid_"] > [data-push-left*="_xs-7"] {
    margin-left: 58.33333%; }
  [class~="p-grid"] > [data-push-left*="_xs-8"],
  [class*="p-grid-"] > [data-push-left*="_xs-8"],
  [class*="p-grid_"] > [data-push-left*="_xs-8"] {
    margin-left: 66.66667%; }
  [class~="p-grid"] > [data-push-left*="_xs-9"],
  [class*="p-grid-"] > [data-push-left*="_xs-9"],
  [class*="p-grid_"] > [data-push-left*="_xs-9"] {
    margin-left: 75%; }
  [class~="p-grid"] > [data-push-left*="_xs-10"],
  [class*="p-grid-"] > [data-push-left*="_xs-10"],
  [class*="p-grid_"] > [data-push-left*="_xs-10"] {
    margin-left: 83.33333%; }
  [class~="p-grid"] > [data-push-left*="_xs-11"],
  [class*="p-grid-"] > [data-push-left*="_xs-11"],
  [class*="p-grid_"] > [data-push-left*="_xs-11"] {
    margin-left: 91.66667%; }
  [class~="p-grid"] > [data-push-right*="_xs-0"],
  [class*="p-grid-"] > [data-push-right*="_xs-0"],
  [class*="p-grid_"] > [data-push-right*="_xs-0"] {
    margin-right: 0; }
  [class~="p-grid"] > [data-push-right*="_xs-1"],
  [class*="p-grid-"] > [data-push-right*="_xs-1"],
  [class*="p-grid_"] > [data-push-right*="_xs-1"] {
    margin-right: 8.33333%; }
  [class~="p-grid"] > [data-push-right*="_xs-2"],
  [class*="p-grid-"] > [data-push-right*="_xs-2"],
  [class*="p-grid_"] > [data-push-right*="_xs-2"] {
    margin-right: 16.66667%; }
  [class~="p-grid"] > [data-push-right*="_xs-3"],
  [class*="p-grid-"] > [data-push-right*="_xs-3"],
  [class*="p-grid_"] > [data-push-right*="_xs-3"] {
    margin-right: 25%; }
  [class~="p-grid"] > [data-push-right*="_xs-4"],
  [class*="p-grid-"] > [data-push-right*="_xs-4"],
  [class*="p-grid_"] > [data-push-right*="_xs-4"] {
    margin-right: 33.33333%; }
  [class~="p-grid"] > [data-push-right*="_xs-5"],
  [class*="p-grid-"] > [data-push-right*="_xs-5"],
  [class*="p-grid_"] > [data-push-right*="_xs-5"] {
    margin-right: 41.66667%; }
  [class~="p-grid"] > [data-push-right*="_xs-6"],
  [class*="p-grid-"] > [data-push-right*="_xs-6"],
  [class*="p-grid_"] > [data-push-right*="_xs-6"] {
    margin-right: 50%; }
  [class~="p-grid"] > [data-push-right*="_xs-7"],
  [class*="p-grid-"] > [data-push-right*="_xs-7"],
  [class*="p-grid_"] > [data-push-right*="_xs-7"] {
    margin-right: 58.33333%; }
  [class~="p-grid"] > [data-push-right*="_xs-8"],
  [class*="p-grid-"] > [data-push-right*="_xs-8"],
  [class*="p-grid_"] > [data-push-right*="_xs-8"] {
    margin-right: 66.66667%; }
  [class~="p-grid"] > [data-push-right*="_xs-9"],
  [class*="p-grid-"] > [data-push-right*="_xs-9"],
  [class*="p-grid_"] > [data-push-right*="_xs-9"] {
    margin-right: 75%; }
  [class~="p-grid"] > [data-push-right*="_xs-10"],
  [class*="p-grid-"] > [data-push-right*="_xs-10"],
  [class*="p-grid_"] > [data-push-right*="_xs-10"] {
    margin-right: 83.33333%; }
  [class~="p-grid"] > [data-push-right*="_xs-11"],
  [class*="p-grid-"] > [data-push-right*="_xs-11"],
  [class*="p-grid_"] > [data-push-right*="_xs-11"] {
    margin-right: 91.66667%; }
  [class~="p-grid"] [class*="_xs-first"],
  [class*="p-grid-"] [class*="_xs-first"],
  [class*="p-grid_"] [class*="_xs-first"] {
    order: -1; }
  [class~="p-grid"] [class*="_xs-last"],
  [class*="p-grid-"] [class*="_xs-last"],
  [class*="p-grid_"] [class*="_xs-last"] {
    order: 1; } }

/************************
    HIDING COLS
*************************/
@media (max-width: 1200px) {
  [class*="lg-hidden"] {
    display: none; } }

@media (max-width: 1024px) {
  [class*="md-hidden"] {
    display: none; } }

@media (max-width: 768px) {
  [class*="sm-hidden"] {
    display: none; } }

@media (max-width: 480px) {
  [class*="xs-hidden"] {
    display: none; } }

.actions-toolbar > .primary,
.actions-toolbar > .secondary {
  text-align: center;
  margin-bottom: 10px; }
  .actions-toolbar > .primary .action,
  .actions-toolbar > .secondary .action {
    margin-bottom: 10px; }
    .actions-toolbar > .primary .action:last-child,
    .actions-toolbar > .secondary .action:last-child {
      margin-bottom: 0; }
  .actions-toolbar > .primary:last-child,
  .actions-toolbar > .secondary:last-child {
    margin-bottom: 0; }

@media only screen and (min-width: 1024px) {
  .actions-toolbar:before, .actions-toolbar:after {
    content: '';
    display: table; }
  .actions-toolbar:after {
    clear: both; }
  .actions-toolbar .primary {
    float: left; }
  .actions-toolbar .secondary {
    float: right; }
  .actions-toolbar .primary,
  .actions-toolbar .secondary {
    display: inline-block; }
    .actions-toolbar .primary a.action,
    .actions-toolbar .secondary a.action {
      display: inline-block; }
  .actions-toolbar .primary .action {
    margin: 0 5px 0 0; }
  .actions-toolbar .secondary a.action {
    margin-top: 6px; }
  .actions-toolbar > .secondary,
  .actions-toolbar > .primary {
    margin-bottom: 0; }
    .actions-toolbar > .secondary .action,
    .actions-toolbar > .primary .action {
      margin-bottom: 0;
      width: auto; } }

.breadcrumbs {
  margin: 0 0 20px; }
  .breadcrumbs .items {
    font-size: 1.2rem;
    color: #a3a3a3;
    margin: 0;
    padding: 0;
    list-style: none none; }
    .breadcrumbs .items > li {
      display: inline-block;
      vertical-align: top; }
  .breadcrumbs .item {
    margin: 0; }
  .breadcrumbs a {
    color: #118b2d;
    text-decoration: none; }
    .breadcrumbs a:visited {
      color: #118b2d;
      text-decoration: none; }
    .breadcrumbs a:hover {
      color: #118b2d;
      text-decoration: underline; }
    .breadcrumbs a:active {
      color: #118b2d;
      text-decoration: none; }
  .breadcrumbs strong {
    font-weight: 400; }
  .breadcrumbs .item:not(:last-child) {
    display: inline-block;
    text-decoration: none; }
    .breadcrumbs .item:not(:last-child):after {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 24px;
      line-height: 18px;
      content: "";
      font-family: "Material Icons";
      margin: 0;
      vertical-align: top;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; }

a.action.primary, button, .cart.table-wrapper .actions-toolbar > .action, .action-gift {
  border-radius: 0px; }

.action:active, button:active, .cart.table-wrapper .actions-toolbar > .action:active, .action-gift:active {
  box-shadow: inset 0 2px 1px rgba(0, 0, 0, 0.12); }

a.action.primary {
  display: inline-block;
  text-decoration: none; }
  a.action.primary:hover, a.action.primary:active, a.action.primary:focus {
    text-decoration: none; }

.action.primary {
  background-image: none;
  background: #118b2d;
  border: 0px solid #e5e5e5;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-family: "Roboto", Helvetica, sans-serif;
  font-weight: 400;
  padding: 7px 15px;
  font-size: 1.4rem;
  box-sizing: border-box;
  vertical-align: middle;
  padding: 1rem;
  font-weight: 400;
  border-radius: 0;
  box-shadow: 0 1px 0 0 #031908; }
  .action.primary:focus, .action.primary:active {
    background: #0d6721;
    border: 0px solid #e5e5e5;
    color: #fff; }
  .action.primary:hover {
    background: #0d6721;
    border: 0px solid #e5e5e5;
    color: #fff; }
  .action.primary.disabled, .action.primary[disabled],
  fieldset[disabled] .action.primary {
    opacity: 0.5;
    cursor: default;
    pointer-events: none; }

.action.primary.checkout,
.action.primary.tocart {
  background-image: none;
  background: #00904a;
  border: #004423;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-family: "Roboto", Helvetica, sans-serif;
  font-weight: 400;
  padding: 7px 15px;
  font-size: 1.4rem;
  box-sizing: border-box;
  vertical-align: middle;
  padding: 1rem;
  text-transform: none;
  font-weight: 400;
  border-radius: 3px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  background-color: #00904a; }
  .action.primary.checkout:focus, .action.primary.checkout:active,
  .action.primary.tocart:focus,
  .action.primary.tocart:active {
    background: #006735;
    border: #004423;
    color: #fff; }
  .action.primary.checkout:hover,
  .action.primary.tocart:hover {
    background: #006735;
    border: #004423;
    color: #fff; }
  .action.primary.checkout.disabled, .action.primary.checkout[disabled],
  fieldset[disabled] .action.primary.checkout,
  .action.primary.tocart.disabled,
  .action.primary.tocart[disabled],
  fieldset[disabled]
  .action.primary.tocart {
    opacity: 0.5;
    cursor: default;
    pointer-events: none; }

.actions-secondary {
  display: none; }

.wpcloud-cta-btn-wrapper a.wpcloud-cta-button.login-portal-button,
.wpcloud-cta-btn-wrapper a.wpcloud-cta-button.button {
  background: #118b2d;
  color: white;
  line-height: 15px; }
  .wpcloud-cta-btn-wrapper a.wpcloud-cta-button.login-portal-button:hover,
  .wpcloud-cta-btn-wrapper a.wpcloud-cta-button.button:hover {
    background: #00812b; }

.wpcloud-simple-gform .wpcloud-cta-btn-wrapper a.mdl-button {
  background-color: #118b2d; }
  .wpcloud-simple-gform .wpcloud-cta-btn-wrapper a.mdl-button:hover {
    background: #00812b; }

.products.list.product-items .product-item-info button.sold-out-button, .products.list.product-items .product-item-info .cart.table-wrapper .actions-toolbar > .sold-out-button.action, .cart.table-wrapper .products.list.product-items .product-item-info .actions-toolbar > .sold-out-button.action, .products.list.product-items .product-item-info .sold-out-button.action-gift {
  width: 100%;
  margin-top: 11px;
  background-color: #e8e8e8;
  color: #333333;
  text-align: center; }

.abs-reset-list, .bundle-options-container .block-bundle-summary .bundle.items, .product-items, .prices-tier, .cart-container .checkout-methods-items, .opc-progress-bar, .checkout-agreements-items, .block-wishlist-management .wishlist-select-items, .paypal-review .items-qty, .order-details-items .items-qty, .footer.content ul, .block-banners .banner-items,
.block-banners-inline .banner-items, .block-event .slider-panel .slider {
  margin: 0;
  padding: 0;
  list-style: none none; }
  .abs-reset-list > li, .bundle-options-container .block-bundle-summary .bundle.items > li, .product-items > li, .prices-tier > li, .cart-container .checkout-methods-items > li, .opc-progress-bar > li, .checkout-agreements-items > li, .block-wishlist-management .wishlist-select-items > li, .paypal-review .items-qty > li, .order-details-items .items-qty > li, .footer.content ul > li, .block-banners .banner-items > li, .block-banners-inline .banner-items > li, .block-event .slider-panel .slider > li {
    margin: 0; }

.abs-action-link-button, .abs-action-addto-product, .bundle-options-container .block-bundle-summary .product-addto-links > .action, .product-info-main .action.tocompare,
.product-options-bottom .action.tocompare, .product-info-main .action.towishlist,
.product-options-bottom .action.towishlist,
.block-bundle-summary .action.towishlist, .block-event .slider-panel .slider .item .action.event {
  background-image: none;
  background: #e0e6ee;
  border: 0;
  color: #118b2d;
  cursor: pointer;
  display: inline-block;
  font-family: "Roboto", Helvetica, sans-serif;
  font-weight: 400;
  margin: 0;
  padding: 7px 15px;
  font-size: 1.4rem;
  line-height: 1.6rem;
  box-sizing: border-box;
  vertical-align: middle;
  display: inline-block;
  text-decoration: none;
  border-radius: 0; }
  .abs-action-link-button:focus, .abs-action-addto-product:focus, .bundle-options-container .block-bundle-summary .product-addto-links > .action:focus, .product-info-main .action.tocompare:focus,
  .product-options-bottom .action.tocompare:focus, .product-info-main .action.towishlist:focus,
  .product-options-bottom .action.towishlist:focus,
  .block-bundle-summary .action.towishlist:focus, .block-event .slider-panel .slider .item .action.event:focus, .abs-action-link-button:active, .abs-action-addto-product:active, .bundle-options-container .block-bundle-summary .product-addto-links > .action:active, .product-info-main .action.tocompare:active,
  .product-options-bottom .action.tocompare:active, .product-info-main .action.towishlist:active,
  .product-options-bottom .action.towishlist:active,
  .block-bundle-summary .action.towishlist:active, .block-event .slider-panel .slider .item .action.event:active {
    background: #d0d9e5;
    border: 0;
    color: #118b2d; }
  .abs-action-link-button:hover, .abs-action-addto-product:hover, .bundle-options-container .block-bundle-summary .product-addto-links > .action:hover, .product-info-main .action.tocompare:hover,
  .product-options-bottom .action.tocompare:hover, .product-info-main .action.towishlist:hover,
  .product-options-bottom .action.towishlist:hover,
  .block-bundle-summary .action.towishlist:hover, .block-event .slider-panel .slider .item .action.event:hover {
    background: #d0d9e5;
    border: 0;
    color: #555; }
  .abs-action-link-button.disabled, .disabled.abs-action-addto-product, .bundle-options-container .block-bundle-summary .product-addto-links > .disabled.action, .product-info-main .disabled.action.tocompare,
  .product-options-bottom .disabled.action.tocompare, .product-info-main .disabled.action.towishlist,
  .product-options-bottom .disabled.action.towishlist,
  .block-bundle-summary .disabled.action.towishlist, .block-event .slider-panel .slider .item .disabled.action.event, .abs-action-link-button[disabled], .abs-action-addto-product[disabled], .bundle-options-container .block-bundle-summary .product-addto-links > .action[disabled], .product-info-main .action.tocompare[disabled],
  .product-options-bottom .action.tocompare[disabled], .product-info-main .action.towishlist[disabled],
  .product-options-bottom .action.towishlist[disabled],
  .block-bundle-summary .action.towishlist[disabled], .block-event .slider-panel .slider .item .action.event[disabled],
  fieldset[disabled] .abs-action-link-button,
  fieldset[disabled] .abs-action-addto-product,
  fieldset[disabled] .bundle-options-container .block-bundle-summary .product-addto-links > .action,
  .bundle-options-container .block-bundle-summary fieldset[disabled] .product-addto-links > .action,
  fieldset[disabled] .product-info-main .action.tocompare,
  .product-info-main fieldset[disabled] .action.tocompare,
  fieldset[disabled] .product-options-bottom .action.tocompare,
  .product-options-bottom fieldset[disabled] .action.tocompare,
  fieldset[disabled] .product-info-main .action.towishlist,
  .product-info-main fieldset[disabled] .action.towishlist,
  fieldset[disabled] .product-options-bottom .action.towishlist,
  .product-options-bottom fieldset[disabled] .action.towishlist,
  fieldset[disabled] .block-bundle-summary .action.towishlist,
  .block-bundle-summary fieldset[disabled] .action.towishlist,
  fieldset[disabled] .block-event .slider-panel .slider .item .action.event,
  .block-event .slider-panel .slider .item fieldset[disabled] .action.event {
    opacity: 0.5;
    cursor: default;
    pointer-events: none; }
  .abs-action-link-button:hover, .abs-action-addto-product:hover, .bundle-options-container .block-bundle-summary .product-addto-links > .action:hover, .product-info-main .action.tocompare:hover,
  .product-options-bottom .action.tocompare:hover, .product-info-main .action.towishlist:hover,
  .product-options-bottom .action.towishlist:hover,
  .block-bundle-summary .action.towishlist:hover, .block-event .slider-panel .slider .item .action.event:hover, .abs-action-link-button:active, .abs-action-addto-product:active, .bundle-options-container .block-bundle-summary .product-addto-links > .action:active, .product-info-main .action.tocompare:active,
  .product-options-bottom .action.tocompare:active, .product-info-main .action.towishlist:active,
  .product-options-bottom .action.towishlist:active,
  .block-bundle-summary .action.towishlist:active, .block-event .slider-panel .slider .item .action.event:active, .abs-action-link-button:focus, .abs-action-addto-product:focus, .bundle-options-container .block-bundle-summary .product-addto-links > .action:focus, .product-info-main .action.tocompare:focus,
  .product-options-bottom .action.tocompare:focus, .product-info-main .action.towishlist:focus,
  .product-options-bottom .action.towishlist:focus,
  .block-bundle-summary .action.towishlist:focus, .block-event .slider-panel .slider .item .action.event:focus {
    text-decoration: none; }

.abs-product-options-list dt, .cart.table-wrapper .item-options dt, .block-giftregistry-shared-items .product .item-options dt, .multicheckout .item-options dt {
  clear: left;
  float: left;
  margin: 0 10px 5px 0; }
  .abs-product-options-list dt:after, .cart.table-wrapper .item-options dt:after, .block-giftregistry-shared-items .product .item-options dt:after, .multicheckout .item-options dt:after {
    content: ': '; }

.abs-product-options-list dd, .cart.table-wrapper .item-options dd, .block-giftregistry-shared-items .product .item-options dd, .multicheckout .item-options dd {
  display: inline-block;
  float: left;
  margin: 0 0 5px; }

@media only screen and (min-width: 1024px) {
  .abs-product-options-list-desktop dt, .block-giftregistry-shared .item-options dt {
    clear: left;
    float: left;
    margin: 0 10px 5px 0; }
    .abs-product-options-list-desktop dt:after, .block-giftregistry-shared .item-options dt:after {
      content: ': '; }
  .abs-product-options-list-desktop dd, .block-giftregistry-shared .item-options dd {
    display: inline-block;
    float: left;
    margin: 0 0 5px; } }

.abs-button-responsive, .actions-toolbar > .primary .action,
.actions-toolbar > .secondary .action {
  width: 100%; }

@media only screen and (min-width: 1024px) {
  .abs-button-desktop {
    width: auto; } }

@media only screen and (max-width: 1023px) {
  .abs-button-responsive-smaller, .product-info-main .box-tocart .actions .action.tocart {
    width: 100%; } }

@media only screen and (min-width: 1024px) {
  .abs-blocks-2columns, .column .block-addbysku .block-content .box, .login-container .block, .account .column.main .block:not(.widget) .block-content .box, .block-addresses-list .items.addresses > .item, .form-address-edit .fieldset, .form-edit-account .fieldset, .magento-rma-guest-returns .column.main .block:not(.widget) .block-content .box, [class^='sales-guest-'] .column.main .block:not(.widget) .block-content .box,
  .sales-guest-view .column.main .block:not(.widget) .block-content .box {
    width: 48.8%; }
    .abs-blocks-2columns:nth-child(odd), .column .block-addbysku .block-content .box:nth-child(odd), .login-container .block:nth-child(odd), .account .column.main .block:not(.widget) .block-content .box:nth-child(odd), .block-addresses-list .items.addresses > .item:nth-child(odd), .form-address-edit .fieldset:nth-child(odd), .form-edit-account .fieldset:nth-child(odd), .magento-rma-guest-returns .column.main .block:not(.widget) .block-content .box:nth-child(odd), [class^='sales-guest-'] .column.main .block:not(.widget) .block-content .box:nth-child(odd),
    .sales-guest-view .column.main .block:not(.widget) .block-content .box:nth-child(odd) {
      clear: left;
      float: left; }
    .abs-blocks-2columns:nth-child(even), .column .block-addbysku .block-content .box:nth-child(even), .login-container .block:nth-child(even), .account .column.main .block:not(.widget) .block-content .box:nth-child(even), .block-addresses-list .items.addresses > .item:nth-child(even), .form-address-edit .fieldset:nth-child(even), .form-edit-account .fieldset:nth-child(even), .magento-rma-guest-returns .column.main .block:not(.widget) .block-content .box:nth-child(even), [class^='sales-guest-'] .column.main .block:not(.widget) .block-content .box:nth-child(even),
    .sales-guest-view .column.main .block:not(.widget) .block-content .box:nth-child(even) {
      float: right; } }

@media only screen and (min-width: 768px) {
  .abs-blocks-2columns-s {
    width: 48.8%; }
    .abs-blocks-2columns-s:nth-child(odd) {
      clear: left;
      float: left; }
    .abs-blocks-2columns-s:nth-child(even) {
      float: right; } }

.abs-reset-image-wrapper, .products-list .product-image-wrapper, .cart.table-wrapper .product-image-wrapper, .minicart-items .product-image-wrapper, .block-giftregistry-shared-items .product-image-wrapper {
  height: auto;
  padding: 0 !important; }
  .abs-reset-image-wrapper .product-image-photo, .products-list .product-image-wrapper .product-image-photo, .cart.table-wrapper .product-image-wrapper .product-image-photo, .minicart-items .product-image-wrapper .product-image-photo, .block-giftregistry-shared-items .product-image-wrapper .product-image-photo {
    position: static; }

.abs-adaptive-images {
  display: block;
  height: auto;
  max-width: 100%; }

.abs-adaptive-images-centered, .product.media .product.photo .photo.image {
  display: block;
  height: auto;
  margin: 0 auto;
  max-width: 100%; }

.abs-login-block-title, .login-container .block .block-title {
  font-size: 1.8rem;
  border-bottom: 1px solid #999;
  margin-bottom: 15px;
  padding-bottom: 12px; }
  .abs-login-block-title strong, .login-container .block .block-title strong {
    font-weight: 500; }

.abs-block-title, .abs-account-blocks .block-title, .account .column.main .block:not(.widget) .block-title, .multicheckout .block-title, .paypal-review .block .block-title, .magento-rma-guest-returns .column.main .block:not(.widget) .block-title, [class^='sales-guest-'] .column.main .block:not(.widget) .block-title, .sales-guest-view .column.main .block:not(.widget) .block-title, .block-compare .block-title, .block-giftregistry-results .block-title, .block-giftregistry-shared-items .block-title, .block-wishlist-search-results .block-title, .block-reorder .block-title, .widget .block-title, .block-wishlist .block-title {
  margin-bottom: 15px; }
  .abs-block-title > strong, .abs-account-blocks .block-title > strong, .account .column.main .block:not(.widget) .block-title > strong, .multicheckout .block-title > strong, .paypal-review .block .block-title > strong, .magento-rma-guest-returns .column.main .block:not(.widget) .block-title > strong, [class^='sales-guest-'] .column.main .block:not(.widget) .block-title > strong, .sales-guest-view .column.main .block:not(.widget) .block-title > strong, .block-compare .block-title > strong, .block-giftregistry-results .block-title > strong, .block-giftregistry-shared-items .block-title > strong, .block-wishlist-search-results .block-title > strong, .block-reorder .block-title > strong, .widget .block-title > strong, .block-wishlist .block-title > strong {
    font-weight: 300;
    line-height: 1.1;
    font-size: 1.8rem;
    margin-top: 1.5rem;
    margin-bottom: 1rem; }

.abs-account-blocks .block-title > .action, .account .column.main .block:not(.widget) .block-title > .action, .multicheckout .block-title > .action, .paypal-review .block .block-title > .action, .magento-rma-guest-returns .column.main .block:not(.widget) .block-title > .action, [class^='sales-guest-'] .column.main .block:not(.widget) .block-title > .action, .sales-guest-view .column.main .block:not(.widget) .block-title > .action {
  margin-left: 15px; }

.abs-account-blocks .box-title, .account .column.main .block:not(.widget) .box-title, .multicheckout .box-title, .paypal-review .block .box-title, .magento-rma-guest-returns .column.main .block:not(.widget) .box-title, [class^='sales-guest-'] .column.main .block:not(.widget) .box-title, .sales-guest-view .column.main .block:not(.widget) .box-title {
  display: inline-block;
  margin: 0 0 5px; }
  .abs-account-blocks .box-title > span, .account .column.main .block:not(.widget) .box-title > span, .multicheckout .box-title > span, .paypal-review .block .box-title > span, .magento-rma-guest-returns .column.main .block:not(.widget) .box-title > span, [class^='sales-guest-'] .column.main .block:not(.widget) .box-title > span, .sales-guest-view .column.main .block:not(.widget) .box-title > span {
    font-weight: 700;
    line-height: 1.1;
    font-size: 1.4rem;
    margin-top: 2rem;
    margin-bottom: 2rem; }
  .abs-account-blocks .box-title > .action, .account .column.main .block:not(.widget) .box-title > .action, .multicheckout .box-title > .action, .paypal-review .block .box-title > .action, .magento-rma-guest-returns .column.main .block:not(.widget) .box-title > .action, [class^='sales-guest-'] .column.main .block:not(.widget) .box-title > .action, .sales-guest-view .column.main .block:not(.widget) .box-title > .action {
    font-weight: 400;
    margin-left: 10px; }

.abs-account-blocks .block-content p:last-child, .account .column.main .block:not(.widget) .block-content p:last-child, .multicheckout .block-content p:last-child, .paypal-review .block .block-content p:last-child, .magento-rma-guest-returns .column.main .block:not(.widget) .block-content p:last-child, [class^='sales-guest-'] .column.main .block:not(.widget) .block-content p:last-child, .sales-guest-view .column.main .block:not(.widget) .block-content p:last-child {
  margin-bottom: 0; }

.abs-account-blocks .block-content .box, .account .column.main .block:not(.widget) .block-content .box, .multicheckout .block-content .box, .paypal-review .block .block-content .box, .magento-rma-guest-returns .column.main .block:not(.widget) .block-content .box, [class^='sales-guest-'] .column.main .block:not(.widget) .block-content .box, .sales-guest-view .column.main .block:not(.widget) .block-content .box {
  margin-bottom: 20px; }

.abs-dropdown-simple, .giftregisty-dropdown, .wishlist-dropdown {
  display: inline-block;
  position: relative; }
  .abs-dropdown-simple:before, .giftregisty-dropdown:before, .wishlist-dropdown:before, .abs-dropdown-simple:after, .giftregisty-dropdown:after, .wishlist-dropdown:after {
    content: '';
    display: table; }
  .abs-dropdown-simple:after, .giftregisty-dropdown:after, .wishlist-dropdown:after {
    clear: both; }
  .abs-dropdown-simple .action.toggle, .giftregisty-dropdown .action.toggle, .wishlist-dropdown .action.toggle {
    cursor: pointer;
    display: inline-block;
    text-decoration: none; }
    .abs-dropdown-simple .action.toggle:after, .giftregisty-dropdown .action.toggle:after, .wishlist-dropdown .action.toggle:after {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 22px;
      line-height: 22px;
      color: inherit;
      content: "";
      font-family: "Material Icons";
      margin: 0 0 0 5px;
      vertical-align: middle;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; }
    .abs-dropdown-simple .action.toggle:hover:after, .giftregisty-dropdown .action.toggle:hover:after, .wishlist-dropdown .action.toggle:hover:after {
      color: inherit; }
    .abs-dropdown-simple .action.toggle:active:after, .giftregisty-dropdown .action.toggle:active:after, .wishlist-dropdown .action.toggle:active:after {
      color: inherit; }
    .abs-dropdown-simple .action.toggle.active, .giftregisty-dropdown .action.toggle.active, .wishlist-dropdown .action.toggle.active {
      display: inline-block;
      text-decoration: none; }
      .abs-dropdown-simple .action.toggle.active:after, .giftregisty-dropdown .action.toggle.active:after, .wishlist-dropdown .action.toggle.active:after {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 22px;
        line-height: 22px;
        color: inherit;
        content: "";
        font-family: "Material Icons";
        margin: 0 0 0 5px;
        vertical-align: middle;
        display: inline-block;
        font-weight: normal;
        overflow: hidden;
        speak: none;
        text-align: center; }
      .abs-dropdown-simple .action.toggle.active:hover:after, .giftregisty-dropdown .action.toggle.active:hover:after, .wishlist-dropdown .action.toggle.active:hover:after {
        color: inherit; }
      .abs-dropdown-simple .action.toggle.active:active:after, .giftregisty-dropdown .action.toggle.active:active:after, .wishlist-dropdown .action.toggle.active:active:after {
        color: inherit; }
  .abs-dropdown-simple ul.dropdown, .giftregisty-dropdown ul.dropdown, .wishlist-dropdown ul.dropdown {
    margin: 0;
    padding: 0;
    list-style: none none;
    background: #fff;
    border: 1px solid #bbb;
    margin-top: 4px;
    min-width: 200px;
    z-index: 100;
    box-sizing: border-box;
    display: none;
    position: absolute;
    top: 100%;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15); }
    .abs-dropdown-simple ul.dropdown li, .giftregisty-dropdown ul.dropdown li, .wishlist-dropdown ul.dropdown li {
      margin: 0;
      padding: 5px 5px 5px 23px; }
      .abs-dropdown-simple ul.dropdown li:hover, .giftregisty-dropdown ul.dropdown li:hover, .wishlist-dropdown ul.dropdown li:hover {
        background: #e8e8e8;
        cursor: pointer; }
    .abs-dropdown-simple ul.dropdown:before, .giftregisty-dropdown ul.dropdown:before, .wishlist-dropdown ul.dropdown:before, .abs-dropdown-simple ul.dropdown:after, .giftregisty-dropdown ul.dropdown:after, .wishlist-dropdown ul.dropdown:after {
      border-bottom-style: solid;
      content: '';
      display: block;
      height: 0;
      position: absolute;
      width: 0; }
    .abs-dropdown-simple ul.dropdown:before, .giftregisty-dropdown ul.dropdown:before, .wishlist-dropdown ul.dropdown:before {
      border: 6px solid;
      border-color: transparent transparent #fff transparent;
      z-index: 99; }
    .abs-dropdown-simple ul.dropdown:after, .giftregisty-dropdown ul.dropdown:after, .wishlist-dropdown ul.dropdown:after {
      border: 7px solid;
      border-color: transparent transparent #bbb transparent;
      z-index: 98; }
    .abs-dropdown-simple ul.dropdown:before, .giftregisty-dropdown ul.dropdown:before, .wishlist-dropdown ul.dropdown:before {
      left: 10px;
      top: -12px; }
    .abs-dropdown-simple ul.dropdown:after, .giftregisty-dropdown ul.dropdown:after, .wishlist-dropdown ul.dropdown:after {
      left: 9px;
      top: -14px; }
  .abs-dropdown-simple.active, .active.giftregisty-dropdown, .active.wishlist-dropdown {
    overflow: visible; }
    .abs-dropdown-simple.active ul.dropdown, .active.giftregisty-dropdown ul.dropdown, .active.wishlist-dropdown ul.dropdown {
      display: block; }

.abs-input-qty, .column .block-addbysku .qty .qty, .bundle-options-container .input-text.qty, .cart.table-wrapper .col.qty .input-text, .account .data.table-return-items .qty .input-text, .table-giftregistry-items .field.qty .qty, .block-giftregistry-shared-items .qty .qty, .table.grouped .control .qty, .block-wishlist-info-items .input-text.qty, .multicheckout .table-wrapper .col .qty .input-text, .account .table-wrapper .data.table.wishlist .box-tocart .qty, .products-grid.wishlist .product-item .box-tocart input.qty, .sidebar .block-addbysku .fieldset .fields .field.qty .qty {
  text-align: center;
  width: 47px; }

.abs-margin-for-blocks-and-widgets, .block, .block-banners,
.block-banners-inline {
  margin-bottom: 40px; }

.abs-remove-button-for-blocks, .sidebar .action.delete, .table-comparison .cell.remove .action.delete, .block-compare .action.delete, .filter-current .action.remove {
  display: inline-block;
  text-decoration: none; }
  .abs-remove-button-for-blocks > span, .sidebar .action.delete > span, .table-comparison .cell.remove .action.delete > span, .block-compare .action.delete > span, .filter-current .action.remove > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
  .abs-remove-button-for-blocks:before, .sidebar .action.delete:before, .table-comparison .cell.remove .action.delete:before, .block-compare .action.delete:before, .filter-current .action.remove:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 26px;
    line-height: 15px;
    color: #303030;
    content: "";
    font-family: "Material Icons";
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center; }
  .abs-remove-button-for-blocks:hover:before, .sidebar .action.delete:hover:before, .table-comparison .cell.remove .action.delete:hover:before, .block-compare .action.delete:hover:before, .filter-current .action.remove:hover:before {
    color: #303030; }
  .abs-remove-button-for-blocks:active:before, .sidebar .action.delete:active:before, .table-comparison .cell.remove .action.delete:active:before, .block-compare .action.delete:active:before, .filter-current .action.remove:active:before {
    color: #303030; }

.abs-product-link, .product-item-name, .product.name a {
  font-weight: 400; }
  .abs-product-link > a, .product-item-name > a, .product.name a > a {
    color: #2C313E;
    text-decoration: none; }
    .abs-product-link > a:visited, .product-item-name > a:visited, .product.name a > a:visited {
      color: #2C313E;
      text-decoration: underline; }
    .abs-product-link > a:hover, .product-item-name > a:hover, .product.name a > a:hover {
      color: #2C313E;
      text-decoration: underline; }
    .abs-product-link > a:active, .product-item-name > a:active, .product.name a > a:active {
      color: #2C313E;
      text-decoration: underline; }

.abs-reset-left-margin, .form.giftregistry.items .actions-toolbar, .form-new-agreement .actions-toolbar, .fieldset .review-legend.legend, .form.wishlist.items .actions-toolbar {
  margin-left: 0; }

@media only screen and (min-width: 1024px) {
  .abs-reset-left-margin-desktop, .bundle-options-container .legend.title, .column.main .cart-summary .actions-toolbar, .cart.table-wrapper .item-actions .actions-toolbar, .gift-summary .actions-toolbar,
  .cart.table-wrapper .gift-summary .actions-toolbar, .column.main .block-giftregistry-shared-items .actions-toolbar, .form-new-agreement .fieldset .legend, .form-new-agreement .actions-toolbar, .column.main .paypal-review .actions-toolbar, .wishlist-index-index .main .form-wishlist-items .actions-toolbar {
    margin-left: 0; } }

@media only screen and (min-width: 768px) {
  .abs-reset-left-margin-desktop-s, .column:not(.sidebar-main) .multicheckout .actions-toolbar, .multicheckout .block-shipping .box-shipping-method .fieldset .legend {
    margin-left: 0; } }

.abs-action-remove, .abs-add-fields .action.remove, .form-giftregistry-share .action.remove, .form-giftregistry-edit .action.remove, .form-add-invitations .action.remove, .form-create-return .action.remove, .form.send.friend .action.remove {
  left: 10px;
  margin-left: 70%;
  position: absolute;
  top: 31px;
  width: auto; }

@media only screen and (min-width: 1024px) {
  .abs-action-remove-desktop, .abs-add-fields-desktop .fieldset .additional .action.remove, .form-giftregistry-share .fieldset .additional .action.remove, .form-giftregistry-edit .fieldset .additional .action.remove, .form-add-invitations .fieldset .additional .action.remove, .form-create-return .fieldset .additional .action.remove, .form.send.friend .fieldset .additional .action.remove {
    margin-left: 75.8%;
    top: 6px; } }

.abs-add-fields .fieldset, .form-giftregistry-share .fieldset, .form-giftregistry-edit .fieldset, .form-add-invitations .fieldset, .form-create-return .fieldset, .form.send.friend .fieldset {
  margin-bottom: 50px; }
  .abs-add-fields .fieldset .field:not(.choice) .control, .form-giftregistry-share .fieldset .field:not(.choice) .control, .form-giftregistry-edit .fieldset .field:not(.choice) .control, .form-add-invitations .fieldset .field:not(.choice) .control, .form-create-return .fieldset .field:not(.choice) .control, .form.send.friend .fieldset .field:not(.choice) .control {
    width: 70%; }
  .abs-add-fields .fieldset .actions-toolbar:not(:first-child) > .secondary, .form-giftregistry-share .fieldset .actions-toolbar:not(:first-child) > .secondary, .form-giftregistry-edit .fieldset .actions-toolbar:not(:first-child) > .secondary, .form-add-invitations .fieldset .actions-toolbar:not(:first-child) > .secondary, .form-create-return .fieldset .actions-toolbar:not(:first-child) > .secondary, .form.send.friend .fieldset .actions-toolbar:not(:first-child) > .secondary {
    float: left; }
    .abs-add-fields .fieldset .actions-toolbar:not(:first-child) > .secondary .action.add, .form-giftregistry-share .fieldset .actions-toolbar:not(:first-child) > .secondary .action.add, .form-giftregistry-edit .fieldset .actions-toolbar:not(:first-child) > .secondary .action.add, .form-add-invitations .fieldset .actions-toolbar:not(:first-child) > .secondary .action.add, .form-create-return .fieldset .actions-toolbar:not(:first-child) > .secondary .action.add, .form.send.friend .fieldset .actions-toolbar:not(:first-child) > .secondary .action.add {
      margin-top: 30px; }
  .abs-add-fields .fieldset .fields .actions-toolbar, .form-giftregistry-share .fieldset .fields .actions-toolbar, .form-giftregistry-edit .fieldset .fields .actions-toolbar, .form-add-invitations .fieldset .fields .actions-toolbar, .form-create-return .fieldset .fields .actions-toolbar, .form.send.friend .fieldset .fields .actions-toolbar {
    margin: 0; }

.abs-add-fields .message.notice, .form-giftregistry-share .message.notice, .form-giftregistry-edit .message.notice, .form-add-invitations .message.notice, .form-create-return .message.notice, .form.send.friend .message.notice {
  margin: 30px 0 0; }

.abs-add-fields .additional, .form-giftregistry-share .additional, .form-giftregistry-edit .additional, .form-add-invitations .additional, .form-create-return .additional, .form.send.friend .additional {
  margin-top: 55px;
  position: relative; }

@media only screen and (min-width: 1024px) {
  .abs-add-fields-desktop .fieldset .field:not(.choice) .control, .form-giftregistry-share .fieldset .field:not(.choice) .control, .form-giftregistry-edit .fieldset .field:not(.choice) .control, .form-add-invitations .fieldset .field:not(.choice) .control, .form-create-return .fieldset .field:not(.choice) .control, .form.send.friend .fieldset .field:not(.choice) .control {
    width: 50%; } }

@media only screen and (min-width: 1024px) {
  .abs-margin-for-forms-desktop, .column:not(.sidebar-additional) form .actions-toolbar,
  .column:not(.sidebar-main) form .actions-toolbar, .fieldset .legend {
    margin-left: 25.8%; } }

@media only screen and (min-width: 768px) {
  .abs-margin-for-forms-desktop-s {
    margin-left: 25.8%; } }

.abs-hidden, .order-details-items .order-items .order-gift-message:not(.expanded-content) {
  height: 0;
  visibility: hidden; }

.abs-visually-hidden, .fieldset > .field.no-label > .label,
.fieldset > .fields > .field.no-label > .label, .product-item-actions .actions-secondary > .action span, .table-comparison .cell.label.remove span,
.table-comparison .cell.label.product span, .cart.table-wrapper .col.qty .label, .minicart-wrapper .action.showcart .counter-label, .checkout-payment-method .field-select-billing > .label, .checkout-payment-method .payments .legend, .checkout-payment-method .ccard .legend, .field.street .field.additional .label, .page-product-downloadable .product-options-wrapper .fieldset .legend.links-title, .page-product-giftcard .fieldset.giftcard > .legend, .opc-wrapper .form-giftcard-account .field .label, .block-wishlist-search-form .form-wishlist-search .fieldset > .legend, .page-multiple-wishlist .page-title-wrapper .page-title, .multicheckout .table-wrapper .col .label, .multicheckout .block .methods-shipping .item-content .fieldset > .legend, .block.newsletter .label, .block-reorder .product-item .label, .opc-wrapper .form-discount .field .label, .action.skip:not(:focus), .page-header .switcher .label,
.page-footer .switcher .label, .products-grid.wishlist .product-item .comment-box .label {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

@media only screen and (max-width: 767px) {
  .abs-visually-hidden-mobile, .table-giftregistry-items .col.product:before {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; } }

@media only screen and (max-width: 1023px) {
  .abs-visually-hidden-mobile-m {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; } }

@media only screen and (min-width: 768px) {
  .abs-visually-hidden-desktop-s {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; } }

@media only screen and (min-width: 1024px) {
  .abs-visually-hidden-desktop, .modes-label, .block-search .label, .block-collapsible-nav .title {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; } }

.abs-visually-hidden-reset, .shipping-policy-block.field-tooltip .field-tooltip-action span {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto; }

.abs-add-clearfix:before, .abs-add-fields .fieldset .actions-toolbar:not(:first-child):before, .form-giftregistry-share .fieldset .actions-toolbar:not(:first-child):before, .form-giftregistry-edit .fieldset .actions-toolbar:not(:first-child):before, .form-add-invitations .fieldset .actions-toolbar:not(:first-child):before, .form-create-return .fieldset .actions-toolbar:not(:first-child):before, .form.send.friend .fieldset .actions-toolbar:not(:first-child):before, .payment-method-braintree .hosted-date-wrap:before, .toolbar:before, .toolbar-products:before, .sidebar .product-items-images:before, .cart.table-wrapper .actions-toolbar:before, .cart.table-wrapper .item-options:before, .minicart-items .product-item > .product:before, .opc-estimated-wrapper:before, .opc-wrapper .field.addresses:before, .page-product-downloadable .product-add-form:before, .gift-options:before, .gift-item-block .content:before, .order-details-items .order-gift-message:before,
.order-details-items .block-order-details-gift-message:before, .block-giftregistry-shared-items .product .item-options:before, .gift-wrapping-list:before, .opc-wrapper .data.table .gift-wrapping:before,
.table-order-items .gift-wrapping:before,
.table-order-review .gift-wrapping:before, .order-options .product:before, .map-popup .map-info-price:before, .multicheckout .item-options:before, .multicheckout .block:before, .multicheckout .block-content:before, .customer-review .product-details:before, .abs-add-clearfix:after, .abs-add-fields .fieldset .actions-toolbar:not(:first-child):after, .form-giftregistry-share .fieldset .actions-toolbar:not(:first-child):after, .form-giftregistry-edit .fieldset .actions-toolbar:not(:first-child):after, .form-add-invitations .fieldset .actions-toolbar:not(:first-child):after, .form-create-return .fieldset .actions-toolbar:not(:first-child):after, .form.send.friend .fieldset .actions-toolbar:not(:first-child):after, .payment-method-braintree .hosted-date-wrap:after, .toolbar:after, .toolbar-products:after, .sidebar .product-items-images:after, .cart.table-wrapper .actions-toolbar:after, .cart.table-wrapper .item-options:after, .minicart-items .product-item > .product:after, .opc-estimated-wrapper:after, .opc-wrapper .field.addresses:after, .page-product-downloadable .product-add-form:after, .gift-options:after, .gift-item-block .content:after, .order-details-items .order-gift-message:after,
.order-details-items .block-order-details-gift-message:after, .block-giftregistry-shared-items .product .item-options:after, .gift-wrapping-list:after, .opc-wrapper .data.table .gift-wrapping:after,
.table-order-items .gift-wrapping:after,
.table-order-review .gift-wrapping:after, .order-options .product:after, .map-popup .map-info-price:after, .multicheckout .item-options:after, .multicheckout .block:after, .multicheckout .block-content:after, .customer-review .product-details:after {
  content: '';
  display: table; }

.abs-add-clearfix:after, .abs-add-fields .fieldset .actions-toolbar:not(:first-child):after, .form-giftregistry-share .fieldset .actions-toolbar:not(:first-child):after, .form-giftregistry-edit .fieldset .actions-toolbar:not(:first-child):after, .form-add-invitations .fieldset .actions-toolbar:not(:first-child):after, .form-create-return .fieldset .actions-toolbar:not(:first-child):after, .form.send.friend .fieldset .actions-toolbar:not(:first-child):after, .payment-method-braintree .hosted-date-wrap:after, .toolbar:after, .toolbar-products:after, .sidebar .product-items-images:after, .cart.table-wrapper .actions-toolbar:after, .cart.table-wrapper .item-options:after, .minicart-items .product-item > .product:after, .opc-estimated-wrapper:after, .opc-wrapper .field.addresses:after, .page-product-downloadable .product-add-form:after, .gift-options:after, .gift-item-block .content:after, .order-details-items .order-gift-message:after,
.order-details-items .block-order-details-gift-message:after, .block-giftregistry-shared-items .product .item-options:after, .gift-wrapping-list:after, .opc-wrapper .data.table .gift-wrapping:after,
.table-order-items .gift-wrapping:after,
.table-order-review .gift-wrapping:after, .order-options .product:after, .map-popup .map-info-price:after, .multicheckout .item-options:after, .multicheckout .block:after, .multicheckout .block-content:after, .customer-review .product-details:after {
  clear: both; }

@media only screen and (min-width: 1024px) {
  .abs-add-clearfix-desktop:before, .abs-pager-toolbar:before, .account .toolbar:before, .toolbar-giftregistry-results:before, .toolbar-wishlist-results:before, .column .block-addbysku .block-content:before, .block-cart-failed .block-content:before, .cart-container:before, .login-container:before, .account .page-title-wrapper:before, .account .column.main .block:not(.widget) .block-content:before, .block-addresses-list .items.addresses:before, .block-giftregistry-shared .item-options:before, .gift-wrapping .nested:before, .table .gift-wrapping .content:before, .block-wishlist-management:before, .paypal-review .block-content:before, .magento-rma-guest-returns .column.main .block:not(.widget) .block-content:before, [class^='sales-guest-'] .column.main .block:not(.widget) .block-content:before,
  .sales-guest-view .column.main .block:not(.widget) .block-content:before, .abs-add-clearfix-desktop:after, .abs-pager-toolbar:after, .account .toolbar:after, .toolbar-giftregistry-results:after, .toolbar-wishlist-results:after, .column .block-addbysku .block-content:after, .block-cart-failed .block-content:after, .cart-container:after, .login-container:after, .account .page-title-wrapper:after, .account .column.main .block:not(.widget) .block-content:after, .block-addresses-list .items.addresses:after, .block-giftregistry-shared .item-options:after, .gift-wrapping .nested:after, .table .gift-wrapping .content:after, .block-wishlist-management:after, .paypal-review .block-content:after, .magento-rma-guest-returns .column.main .block:not(.widget) .block-content:after, [class^='sales-guest-'] .column.main .block:not(.widget) .block-content:after,
  .sales-guest-view .column.main .block:not(.widget) .block-content:after {
    content: '';
    display: table; }
  .abs-add-clearfix-desktop:after, .abs-pager-toolbar:after, .account .toolbar:after, .toolbar-giftregistry-results:after, .toolbar-wishlist-results:after, .column .block-addbysku .block-content:after, .block-cart-failed .block-content:after, .cart-container:after, .login-container:after, .account .page-title-wrapper:after, .account .column.main .block:not(.widget) .block-content:after, .block-addresses-list .items.addresses:after, .block-giftregistry-shared .item-options:after, .gift-wrapping .nested:after, .table .gift-wrapping .content:after, .block-wishlist-management:after, .paypal-review .block-content:after, .magento-rma-guest-returns .column.main .block:not(.widget) .block-content:after, [class^='sales-guest-'] .column.main .block:not(.widget) .block-content:after,
  .sales-guest-view .column.main .block:not(.widget) .block-content:after {
    clear: both; } }

@media only screen and (min-width: 768px) {
  .abs-add-clearfix-desktop-s:before, .multicheckout .block-billing:before, .abs-add-clearfix-desktop-s:after, .multicheckout .block-billing:after {
    content: '';
    display: table; }
  .abs-add-clearfix-desktop-s:after, .multicheckout .block-billing:after {
    clear: both; } }

@media only screen and (max-width: 767px) {
  .abs-add-clearfix-mobile:before, .abs-checkout-order-review tbody tr:before, .multicheckout.order-review .data.table tbody tr:before, .table-wrapper .table:not(.totals):not(.table-comparison) tfoot tr:before, .table-giftregistry-items .col.product:before, .account .toolbar:before, .abs-add-clearfix-mobile:after, .abs-checkout-order-review tbody tr:after, .multicheckout.order-review .data.table tbody tr:after, .table-wrapper .table:not(.totals):not(.table-comparison) tfoot tr:after, .table-giftregistry-items .col.product:after, .account .toolbar:after {
    content: '';
    display: table; }
  .abs-add-clearfix-mobile:after, .abs-checkout-order-review tbody tr:after, .multicheckout.order-review .data.table tbody tr:after, .table-wrapper .table:not(.totals):not(.table-comparison) tfoot tr:after, .table-giftregistry-items .col.product:after, .account .toolbar:after {
    clear: both; } }

@media only screen and (max-width: 1023px) {
  .abs-add-clearfix-mobile-m:before, .account .column.main .block:not(.widget) .block-title:before, .form-create-return .fieldset > .actions-toolbar:before, .abs-add-clearfix-mobile-m:after, .account .column.main .block:not(.widget) .block-title:after, .form-create-return .fieldset > .actions-toolbar:after {
    content: '';
    display: table; }
  .abs-add-clearfix-mobile-m:after, .account .column.main .block:not(.widget) .block-title:after, .form-create-return .fieldset > .actions-toolbar:after {
    clear: both; } }

.abs-add-box-sizing, .abs-field-date .control, .field.date .control, .abs-field-tooltip, .field .control._with-tooltip, .columns .column.main, .columns .sidebar-main, .column .block-addbysku .sku, .bundle-options-container .block-bundle-summary, .product-item, .sidebar .product-items-images .product-item, .search-autocomplete, .opc-wrapper .shipping-address-item, .shipping-policy-block.field-tooltip .field-tooltip-content, .opc-block-summary, .sidebar .block-addbysku .fieldset .fields .field.sku, .block-event .slider-panel .slider .item .action.show {
  box-sizing: border-box; }

@media only screen and (min-width: 1024px) {
  .abs-add-box-sizing-desktop, .column.main, .sidebar-main, .sidebar-additional {
    box-sizing: border-box; } }

@media only screen and (min-width: 768px) {
  .abs-add-box-sizing-desktop-s, .multicheckout .block-shipping .box, .multicheckout .block-billing .box-billing-address, .multicheckout .block-billing .box-billing-method {
    box-sizing: border-box; } }

@media only screen and (min-width: 1024px) {
  .abs-add-box-sizing-desktop-m {
    box-sizing: border-box; } }

@media only screen and (min-width: 1024px) {
  .abs-revert-field-type-desktop .fieldset > .field, .product-add-form .fieldset > .field,
  .abs-revert-field-type-desktop .fieldset .fields > .field,
  .product-add-form .fieldset .fields > .field {
    margin: 0 0 20px; }
    .abs-revert-field-type-desktop .fieldset > .field:not(.choice) > .label, .product-add-form .fieldset > .field:not(.choice) > .label,
    .abs-revert-field-type-desktop .fieldset .fields > .field:not(.choice) > .label,
    .product-add-form .fieldset .fields > .field:not(.choice) > .label {
      box-sizing: content-box;
      float: none;
      width: auto;
      text-align: left;
      padding: 0; }
    .abs-revert-field-type-desktop .fieldset > .field:not(.choice) > .control, .product-add-form .fieldset > .field:not(.choice) > .control,
    .abs-revert-field-type-desktop .fieldset .fields > .field:not(.choice) > .control,
    .product-add-form .fieldset .fields > .field:not(.choice) > .control {
      float: none;
      width: auto; }
    .abs-revert-field-type-desktop .fieldset > .field > .label, .product-add-form .fieldset > .field > .label,
    .abs-revert-field-type-desktop .fieldset .fields > .field > .label,
    .product-add-form .fieldset .fields > .field > .label {
      margin: 0 0 5px;
      display: inline-block; }
    .abs-revert-field-type-desktop .fieldset > .field.choice:before, .product-add-form .fieldset > .field.choice:before, .abs-revert-field-type-desktop .fieldset > .field.no-label:before, .product-add-form .fieldset > .field.no-label:before,
    .abs-revert-field-type-desktop .fieldset .fields > .field.choice:before,
    .product-add-form .fieldset .fields > .field.choice:before,
    .abs-revert-field-type-desktop .fieldset .fields > .field.no-label:before,
    .product-add-form .fieldset .fields > .field.no-label:before {
      display: none; }
    .abs-revert-field-type-desktop .fieldset > .field:not(:first-child):last-of-type, .product-add-form .fieldset > .field:not(:first-child):last-of-type,
    .abs-revert-field-type-desktop .fieldset .fields > .field:not(:first-child):last-of-type,
    .product-add-form .fieldset .fields > .field:not(:first-child):last-of-type {
      margin-bottom: 0; } }

.abs-navigation-icon {
  display: block;
  text-decoration: none; }
  .abs-navigation-icon:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 34px;
    line-height: 1.2;
    color: #a2a2a2;
    content: "";
    font-family: "Material Icons";
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center; }
  .abs-navigation-icon:after {
    position: absolute;
    right: 5px;
    top: 0; }

.abs-split-button {
  display: inline-block;
  position: relative;
  vertical-align: middle; }
  .abs-split-button:before, .abs-split-button:after {
    content: '';
    display: table; }
  .abs-split-button:after {
    clear: both; }
  .abs-split-button .action.split {
    float: left;
    margin: 0; }
  .abs-split-button .action.toggle {
    float: right;
    margin: 0; }
  .abs-split-button button.action.split, .abs-split-button .cart.table-wrapper .actions-toolbar > .action.split, .cart.table-wrapper .abs-split-button .actions-toolbar > .action.split, .abs-split-button .action.split.action-gift {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0; }
  .abs-split-button button + .action.toggle, .abs-split-button .cart.table-wrapper .actions-toolbar > .action + .action.toggle, .cart.table-wrapper .abs-split-button .actions-toolbar > .action + .action.toggle, .abs-split-button .action-gift + .action.toggle {
    border-bottom-left-radius: 0;
    border-left: 0;
    border-top-left-radius: 0; }
  .abs-split-button .action.toggle {
    padding: 4px 5px;
    display: inline-block;
    text-decoration: none; }
    .abs-split-button .action.toggle > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .abs-split-button .action.toggle:after {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 22px;
      line-height: 22px;
      color: inherit;
      content: "";
      font-family: "Material Icons";
      margin: 0;
      vertical-align: top;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; }
    .abs-split-button .action.toggle:hover:after {
      color: inherit; }
    .abs-split-button .action.toggle:active:after {
      color: inherit; }
    .abs-split-button .action.toggle.active {
      display: inline-block;
      text-decoration: none; }
      .abs-split-button .action.toggle.active > span {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px; }
      .abs-split-button .action.toggle.active:after {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 22px;
        line-height: 22px;
        color: inherit;
        content: "";
        font-family: "Material Icons";
        margin: 0;
        vertical-align: top;
        display: inline-block;
        font-weight: normal;
        overflow: hidden;
        speak: none;
        text-align: center; }
      .abs-split-button .action.toggle.active:hover:after {
        color: inherit; }
      .abs-split-button .action.toggle.active:active:after {
        color: inherit; }
  .abs-split-button .items {
    margin: 0;
    padding: 0;
    list-style: none none;
    background: #fff;
    border: 1px solid #bbb;
    margin-top: 4px;
    min-width: 100%;
    z-index: 100;
    box-sizing: border-box;
    display: none;
    position: absolute;
    top: 100%;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15); }
    .abs-split-button .items li {
      margin: 0;
      padding: 3px 5px; }
      .abs-split-button .items li:hover {
        background: #e8e8e8;
        cursor: pointer; }
    .abs-split-button .items:before, .abs-split-button .items:after {
      border-bottom-style: solid;
      content: '';
      display: block;
      height: 0;
      position: absolute;
      width: 0; }
    .abs-split-button .items:before {
      border: 6px solid;
      border-color: transparent transparent #fff transparent;
      z-index: 99; }
    .abs-split-button .items:after {
      border: 7px solid;
      border-color: transparent transparent #bbb transparent;
      z-index: 98; }
    .abs-split-button .items:before {
      right: 10px;
      top: -12px; }
    .abs-split-button .items:after {
      right: 9px;
      top: -14px; }
  .abs-split-button.active {
    overflow: visible; }
    .abs-split-button.active .items {
      display: block; }

.abs-action-addto-product, .bundle-options-container .block-bundle-summary .product-addto-links > .action, .product-info-main .action.tocompare,
.product-options-bottom .action.tocompare, .product-info-main .action.towishlist,
.product-options-bottom .action.towishlist,
.block-bundle-summary .action.towishlist {
  line-height: 1.2rem;
  padding: 5px 8px;
  font-size: 1.1rem; }

.abs-actions-addto-gridlist, .product-item-actions .actions-secondary > .action, .table-comparison .wishlist.split.button > .action.split,
.product-items .wishlist.split.button > .action.split, .product-items .action.towishlist,
.products.list.items .action.towishlist,
.table-comparison .action.towishlist {
  display: inline-block;
  text-decoration: none; }
  .abs-actions-addto-gridlist > span, .product-item-actions .actions-secondary > .action > span, .table-comparison .wishlist.split.button > .action.split > span, .product-items .wishlist.split.button > .action.split > span, .product-items .action.towishlist > span, .products.list.items .action.towishlist > span, .table-comparison .action.towishlist > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
  .abs-actions-addto-gridlist:before, .product-item-actions .actions-secondary > .action:before, .table-comparison .wishlist.split.button > .action.split:before,
  .product-items .wishlist.split.button > .action.split:before, .product-items .action.towishlist:before,
  .products.list.items .action.towishlist:before,
  .table-comparison .action.towishlist:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 29px;
    line-height: 24px;
    color: #5e5e5e;
    font-family: "Material Icons";
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center; }
  .abs-actions-addto-gridlist:hover:before, .product-item-actions .actions-secondary > .action:hover:before, .table-comparison .wishlist.split.button > .action.split:hover:before,
  .product-items .wishlist.split.button > .action.split:hover:before, .product-items .action.towishlist:hover:before,
  .products.list.items .action.towishlist:hover:before,
  .table-comparison .action.towishlist:hover:before {
    color: #6b6b6b; }

.abs-button-l, .bundle-actions .action.primary, .bundle-options-container .block-bundle-summary .box-tocart .action.primary, .product-info-main .box-tocart .action.tocart,
.product-options-bottom .box-tocart .action.tocart, .cart-container .checkout-methods-items .action.primary, .block-minicart .block-content > .actions > .primary .action.primary, .methods-shipping .actions-toolbar .action.primary, .block-authentication .action.action-register,
.block-authentication .action.action-login, .checkout-payment-method .payment-method-content > .actions-toolbar > .primary .action.primary, .form-address-edit .actions-toolbar .action.primary, .multicheckout .action.primary {
  line-height: 2.2rem;
  padding: 14px 17px;
  font-size: 1.8rem; }

.abs-action-button-as-link, .abs-action-remove, .abs-add-fields .action.remove, .form-giftregistry-share .action.remove, .form-giftregistry-edit .action.remove, .form-add-invitations .action.remove, .form-create-return .action.remove, .form.send.friend .action.remove, .cart.table-wrapper .action.help.map, .opc-wrapper .edit-address-link, .opc-block-shipping-information .shipping-information-title .action-edit, .action-auth-toggle, .checkout-payment-method .checkout-billing-address .action-cancel, .checkout-agreements-block .action-show, .cart-summary .block.giftcard .action.check, .gift-options .actions-toolbar .action-cancel, .table-comparison .wishlist.split.button > .action,
.product-items .wishlist.split.button > .action, .paypal-button-widget .paypal-button {
  line-height: 1.42857;
  padding: 0;
  color: #118b2d;
  text-decoration: none;
  background: none;
  border: 0;
  display: inline;
  border-radius: 0;
  font-size: inherit;
  font-weight: 400; }
  .abs-action-button-as-link:visited, .abs-action-remove:visited, .abs-add-fields .action.remove:visited, .form-giftregistry-share .action.remove:visited, .form-giftregistry-edit .action.remove:visited, .form-add-invitations .action.remove:visited, .form-create-return .action.remove:visited, .form.send.friend .action.remove:visited, .cart.table-wrapper .action.help.map:visited, .opc-wrapper .edit-address-link:visited, .opc-block-shipping-information .shipping-information-title .action-edit:visited, .action-auth-toggle:visited, .checkout-payment-method .checkout-billing-address .action-cancel:visited, .checkout-agreements-block .action-show:visited, .cart-summary .block.giftcard .action.check:visited, .gift-options .actions-toolbar .action-cancel:visited, .table-comparison .wishlist.split.button > .action:visited,
  .product-items .wishlist.split.button > .action:visited, .paypal-button-widget .paypal-button:visited {
    color: #118b2d;
    text-decoration: none; }
  .abs-action-button-as-link:hover, .abs-action-remove:hover, .abs-add-fields .action.remove:hover, .form-giftregistry-share .action.remove:hover, .form-giftregistry-edit .action.remove:hover, .form-add-invitations .action.remove:hover, .form-create-return .action.remove:hover, .form.send.friend .action.remove:hover, .cart.table-wrapper .action.help.map:hover, .opc-wrapper .edit-address-link:hover, .opc-block-shipping-information .shipping-information-title .action-edit:hover, .action-auth-toggle:hover, .checkout-payment-method .checkout-billing-address .action-cancel:hover, .checkout-agreements-block .action-show:hover, .cart-summary .block.giftcard .action.check:hover, .gift-options .actions-toolbar .action-cancel:hover, .table-comparison .wishlist.split.button > .action:hover,
  .product-items .wishlist.split.button > .action:hover, .paypal-button-widget .paypal-button:hover {
    color: #0e7426;
    text-decoration: underline; }
  .abs-action-button-as-link:active, .abs-action-remove:active, .abs-add-fields .action.remove:active, .form-giftregistry-share .action.remove:active, .form-giftregistry-edit .action.remove:active, .form-add-invitations .action.remove:active, .form-create-return .action.remove:active, .form.send.friend .action.remove:active, .cart.table-wrapper .action.help.map:active, .opc-wrapper .edit-address-link:active, .opc-block-shipping-information .shipping-information-title .action-edit:active, .action-auth-toggle:active, .checkout-payment-method .checkout-billing-address .action-cancel:active, .checkout-agreements-block .action-show:active, .cart-summary .block.giftcard .action.check:active, .gift-options .actions-toolbar .action-cancel:active, .table-comparison .wishlist.split.button > .action:active,
  .product-items .wishlist.split.button > .action:active, .paypal-button-widget .paypal-button:active {
    color: #2196f3;
    text-decoration: underline; }
  .abs-action-button-as-link:hover, .abs-action-remove:hover, .abs-add-fields .action.remove:hover, .form-giftregistry-share .action.remove:hover, .form-giftregistry-edit .action.remove:hover, .form-add-invitations .action.remove:hover, .form-create-return .action.remove:hover, .form.send.friend .action.remove:hover, .cart.table-wrapper .action.help.map:hover, .opc-wrapper .edit-address-link:hover, .opc-block-shipping-information .shipping-information-title .action-edit:hover, .action-auth-toggle:hover, .checkout-payment-method .checkout-billing-address .action-cancel:hover, .checkout-agreements-block .action-show:hover, .cart-summary .block.giftcard .action.check:hover, .gift-options .actions-toolbar .action-cancel:hover, .table-comparison .wishlist.split.button > .action:hover,
  .product-items .wishlist.split.button > .action:hover, .paypal-button-widget .paypal-button:hover {
    color: #0e7426; }
  .abs-action-button-as-link:hover, .abs-action-remove:hover, .abs-add-fields .action.remove:hover, .form-giftregistry-share .action.remove:hover, .form-giftregistry-edit .action.remove:hover, .form-add-invitations .action.remove:hover, .form-create-return .action.remove:hover, .form.send.friend .action.remove:hover, .cart.table-wrapper .action.help.map:hover, .opc-wrapper .edit-address-link:hover, .opc-block-shipping-information .shipping-information-title .action-edit:hover, .action-auth-toggle:hover, .checkout-payment-method .checkout-billing-address .action-cancel:hover, .checkout-agreements-block .action-show:hover, .cart-summary .block.giftcard .action.check:hover, .gift-options .actions-toolbar .action-cancel:hover, .table-comparison .wishlist.split.button > .action:hover,
  .product-items .wishlist.split.button > .action:hover, .paypal-button-widget .paypal-button:hover, .abs-action-button-as-link:active, .abs-action-remove:active, .abs-add-fields .action.remove:active, .form-giftregistry-share .action.remove:active, .form-giftregistry-edit .action.remove:active, .form-add-invitations .action.remove:active, .form-create-return .action.remove:active, .form.send.friend .action.remove:active, .cart.table-wrapper .action.help.map:active, .opc-wrapper .edit-address-link:active, .opc-block-shipping-information .shipping-information-title .action-edit:active, .action-auth-toggle:active, .checkout-payment-method .checkout-billing-address .action-cancel:active, .checkout-agreements-block .action-show:active, .cart-summary .block.giftcard .action.check:active, .gift-options .actions-toolbar .action-cancel:active, .table-comparison .wishlist.split.button > .action:active,
  .product-items .wishlist.split.button > .action:active, .paypal-button-widget .paypal-button:active, .abs-action-button-as-link:focus, .abs-action-remove:focus, .abs-add-fields .action.remove:focus, .form-giftregistry-share .action.remove:focus, .form-giftregistry-edit .action.remove:focus, .form-add-invitations .action.remove:focus, .form-create-return .action.remove:focus, .form.send.friend .action.remove:focus, .cart.table-wrapper .action.help.map:focus, .opc-wrapper .edit-address-link:focus, .opc-block-shipping-information .shipping-information-title .action-edit:focus, .action-auth-toggle:focus, .checkout-payment-method .checkout-billing-address .action-cancel:focus, .checkout-agreements-block .action-show:focus, .cart-summary .block.giftcard .action.check:focus, .gift-options .actions-toolbar .action-cancel:focus, .table-comparison .wishlist.split.button > .action:focus,
  .product-items .wishlist.split.button > .action:focus, .paypal-button-widget .paypal-button:focus {
    background: none;
    border: 0; }
  .abs-action-button-as-link.disabled, .disabled.abs-action-remove, .abs-add-fields .disabled.action.remove, .form-giftregistry-share .disabled.action.remove, .form-giftregistry-edit .disabled.action.remove, .form-add-invitations .disabled.action.remove, .form-create-return .disabled.action.remove, .form.send.friend .disabled.action.remove, .cart.table-wrapper .disabled.action.help.map, .opc-wrapper .disabled.edit-address-link, .opc-block-shipping-information .shipping-information-title .disabled.action-edit, .disabled.action-auth-toggle, .checkout-payment-method .checkout-billing-address .disabled.action-cancel, .checkout-agreements-block .disabled.action-show, .cart-summary .block.giftcard .disabled.action.check, .gift-options .actions-toolbar .disabled.action-cancel, .table-comparison .wishlist.split.button > .disabled.action,
  .product-items .wishlist.split.button > .disabled.action, .paypal-button-widget .disabled.paypal-button, .abs-action-button-as-link[disabled], .abs-action-remove[disabled], .abs-add-fields .action.remove[disabled], .form-giftregistry-share .action.remove[disabled], .form-giftregistry-edit .action.remove[disabled], .form-add-invitations .action.remove[disabled], .form-create-return .action.remove[disabled], .form.send.friend .action.remove[disabled], .cart.table-wrapper .action.help.map[disabled], .opc-wrapper .edit-address-link[disabled], .opc-block-shipping-information .shipping-information-title .action-edit[disabled], .action-auth-toggle[disabled], .checkout-payment-method .checkout-billing-address .action-cancel[disabled], .checkout-agreements-block .action-show[disabled], .cart-summary .block.giftcard .action.check[disabled], .gift-options .actions-toolbar .action-cancel[disabled], .table-comparison .wishlist.split.button > .action[disabled],
  .product-items .wishlist.split.button > .action[disabled], .paypal-button-widget .paypal-button[disabled],
  fieldset[disabled] .abs-action-button-as-link,
  fieldset[disabled] .abs-action-remove,
  fieldset[disabled] .abs-add-fields .action.remove,
  .abs-add-fields fieldset[disabled] .action.remove,
  fieldset[disabled] .form-giftregistry-share .action.remove,
  .form-giftregistry-share fieldset[disabled] .action.remove,
  fieldset[disabled] .form-giftregistry-edit .action.remove,
  .form-giftregistry-edit fieldset[disabled] .action.remove,
  fieldset[disabled] .form-add-invitations .action.remove,
  .form-add-invitations fieldset[disabled] .action.remove,
  fieldset[disabled] .form-create-return .action.remove,
  .form-create-return fieldset[disabled] .action.remove,
  fieldset[disabled] .form.send.friend .action.remove,
  .form.send.friend fieldset[disabled] .action.remove,
  fieldset[disabled] .cart.table-wrapper .action.help.map,
  .cart.table-wrapper fieldset[disabled] .action.help.map,
  fieldset[disabled] .opc-wrapper .edit-address-link,
  .opc-wrapper fieldset[disabled] .edit-address-link,
  fieldset[disabled] .opc-block-shipping-information .shipping-information-title .action-edit,
  .opc-block-shipping-information .shipping-information-title fieldset[disabled] .action-edit,
  fieldset[disabled] .action-auth-toggle,
  fieldset[disabled] .checkout-payment-method .checkout-billing-address .action-cancel,
  .checkout-payment-method .checkout-billing-address fieldset[disabled] .action-cancel,
  fieldset[disabled] .checkout-agreements-block .action-show,
  .checkout-agreements-block fieldset[disabled] .action-show,
  fieldset[disabled] .cart-summary .block.giftcard .action.check,
  .cart-summary .block.giftcard fieldset[disabled] .action.check,
  fieldset[disabled] .gift-options .actions-toolbar .action-cancel,
  .gift-options .actions-toolbar fieldset[disabled] .action-cancel,
  fieldset[disabled] .table-comparison .wishlist.split.button > .action,
  .table-comparison fieldset[disabled] .wishlist.split.button > .action,
  fieldset[disabled] .product-items .wishlist.split.button > .action,
  .product-items fieldset[disabled] .wishlist.split.button > .action,
  fieldset[disabled] .paypal-button-widget .paypal-button,
  .paypal-button-widget fieldset[disabled] .paypal-button {
    color: #118b2d;
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
    text-decoration: underline; }
  .abs-action-button-as-link:active, .abs-action-remove:active, .abs-add-fields .action.remove:active, .form-giftregistry-share .action.remove:active, .form-giftregistry-edit .action.remove:active, .form-add-invitations .action.remove:active, .form-create-return .action.remove:active, .form.send.friend .action.remove:active, .cart.table-wrapper .action.help.map:active, .opc-wrapper .edit-address-link:active, .opc-block-shipping-information .shipping-information-title .action-edit:active, .action-auth-toggle:active, .checkout-payment-method .checkout-billing-address .action-cancel:active, .checkout-agreements-block .action-show:active, .cart-summary .block.giftcard .action.check:active, .gift-options .actions-toolbar .action-cancel:active, .table-comparison .wishlist.split.button > .action:active,
  .product-items .wishlist.split.button > .action:active, .paypal-button-widget .paypal-button:active, .abs-action-button-as-link:not(:focus), .abs-action-remove:not(:focus), .abs-add-fields .action.remove:not(:focus), .form-giftregistry-share .action.remove:not(:focus), .form-giftregistry-edit .action.remove:not(:focus), .form-add-invitations .action.remove:not(:focus), .form-create-return .action.remove:not(:focus), .form.send.friend .action.remove:not(:focus), .cart.table-wrapper .action.help.map:not(:focus), .opc-wrapper .edit-address-link:not(:focus), .opc-block-shipping-information .shipping-information-title .action-edit:not(:focus), .action-auth-toggle:not(:focus), .checkout-payment-method .checkout-billing-address .action-cancel:not(:focus), .checkout-agreements-block .action-show:not(:focus), .cart-summary .block.giftcard .action.check:not(:focus), .gift-options .actions-toolbar .action-cancel:not(:focus), .table-comparison .wishlist.split.button > .action:not(:focus),
  .product-items .wishlist.split.button > .action:not(:focus), .paypal-button-widget .paypal-button:not(:focus) {
    box-shadow: none; }

.abs-revert-secondary-color, .block-compare .action.compare, .summary .actions-toolbar > .primary button, .summary .actions-toolbar > .primary .cart.table-wrapper .actions-toolbar > .action, .cart.table-wrapper .summary .actions-toolbar > .primary .actions-toolbar > .action, .summary .actions-toolbar > .primary .action-gift, .cart-summary .form.giftregistry .fieldset .action.primary, .paypal-review .block .actions-toolbar .action.primary {
  background: #e0e6ee;
  border: 0;
  color: #118b2d; }
  .abs-revert-secondary-color:focus, .block-compare .action.compare:focus, .summary .actions-toolbar > .primary button:focus, .summary .actions-toolbar > .primary .cart.table-wrapper .actions-toolbar > .action:focus, .cart.table-wrapper .summary .actions-toolbar > .primary .actions-toolbar > .action:focus, .summary .actions-toolbar > .primary .action-gift:focus, .cart-summary .form.giftregistry .fieldset .action.primary:focus, .paypal-review .block .actions-toolbar .action.primary:focus, .abs-revert-secondary-color:active, .block-compare .action.compare:active, .summary .actions-toolbar > .primary button:active, .summary .actions-toolbar > .primary .cart.table-wrapper .actions-toolbar > .action:active, .cart.table-wrapper .summary .actions-toolbar > .primary .actions-toolbar > .action:active, .summary .actions-toolbar > .primary .action-gift:active, .cart-summary .form.giftregistry .fieldset .action.primary:active, .paypal-review .block .actions-toolbar .action.primary:active {
    background: #d0d9e5;
    border: 0;
    color: #118b2d; }
  .abs-revert-secondary-color:hover, .block-compare .action.compare:hover, .summary .actions-toolbar > .primary button:hover, .summary .actions-toolbar > .primary .cart.table-wrapper .actions-toolbar > .action:hover, .cart.table-wrapper .summary .actions-toolbar > .primary .actions-toolbar > .action:hover, .summary .actions-toolbar > .primary .action-gift:hover, .cart-summary .form.giftregistry .fieldset .action.primary:hover, .paypal-review .block .actions-toolbar .action.primary:hover {
    background: #d0d9e5;
    border: 0;
    color: #555; }

.abs-revert-secondary-size, .cart-summary .form.giftregistry .fieldset .action.primary {
  font-size: 1.4rem;
  line-height: 1.6rem;
  padding: 7px 15px; }

.abs-box-tocart, .bundle-actions {
  margin: 10px 0; }

.abs-adjustment-incl-excl-tax .price-including-tax, .bundle-options-container .price-notice .price-including-tax, .product-options-wrapper .field .price-notice .price-including-tax, .summary .price-including-tax, .gift-summary .price-including-tax, .gift-options .price-including-tax,
.abs-adjustment-incl-excl-tax .price-excluding-tax,
.bundle-options-container .price-notice .price-excluding-tax,
.product-options-wrapper .field .price-notice .price-excluding-tax,
.summary .price-excluding-tax,
.gift-summary .price-excluding-tax,
.gift-options .price-excluding-tax,
.abs-adjustment-incl-excl-tax .weee,
.bundle-options-container .price-notice .weee,
.product-options-wrapper .field .price-notice .weee,
.summary .weee,
.gift-summary .weee,
.gift-options .weee {
  font-size: 1.4rem;
  display: inline-block;
  white-space: nowrap; }

.abs-adjustment-incl-excl-tax .price-including-tax + .price-excluding-tax, .bundle-options-container .price-notice .price-including-tax + .price-excluding-tax, .product-options-wrapper .field .price-notice .price-including-tax + .price-excluding-tax, .summary .price-including-tax + .price-excluding-tax, .gift-summary .price-including-tax + .price-excluding-tax, .gift-options .price-including-tax + .price-excluding-tax {
  display: inline-block;
  font-size: 1.1rem; }
  .abs-adjustment-incl-excl-tax .price-including-tax + .price-excluding-tax:before, .bundle-options-container .price-notice .price-including-tax + .price-excluding-tax:before, .product-options-wrapper .field .price-notice .price-including-tax + .price-excluding-tax:before, .summary .price-including-tax + .price-excluding-tax:before, .gift-summary .price-including-tax + .price-excluding-tax:before, .gift-options .price-including-tax + .price-excluding-tax:before {
    content: "(" attr(data-label) ": "; }
  .abs-adjustment-incl-excl-tax .price-including-tax + .price-excluding-tax:after, .bundle-options-container .price-notice .price-including-tax + .price-excluding-tax:after, .product-options-wrapper .field .price-notice .price-including-tax + .price-excluding-tax:after, .summary .price-including-tax + .price-excluding-tax:after, .gift-summary .price-including-tax + .price-excluding-tax:after, .gift-options .price-including-tax + .price-excluding-tax:after {
    content: ')'; }

.abs-tax-total, .price-including-tax .cart-tax-total,
.price-excluding-tax .cart-tax-total {
  cursor: pointer;
  padding-right: 12px;
  position: relative;
  display: inline-block;
  text-decoration: none; }
  .abs-tax-total:after, .price-including-tax .cart-tax-total:after,
  .price-excluding-tax .cart-tax-total:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 26px;
    line-height: 10px;
    color: #a2a2a2;
    content: "";
    font-family: "Material Icons";
    margin: 3px 0 0 0;
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center; }
  .abs-tax-total:after, .price-including-tax .cart-tax-total:after,
  .price-excluding-tax .cart-tax-total:after {
    position: absolute;
    right: -10px;
    top: 3px; }
  .abs-tax-total-expanded:after, .price-including-tax .cart-tax-total-expanded:after,
  .price-excluding-tax .cart-tax-total-expanded:after {
    content: ""; }

.abs-tax-total-expanded:after, .price-including-tax .cart-tax-total-expanded:after,
.price-excluding-tax .cart-tax-total-expanded:after {
  content: ""; }

.abs-methods-shipping-title {
  font-size: 1.4rem;
  font-weight: 700;
  margin: 0 0 15px; }

@media only screen and (max-width: 767px) {
  .abs-checkout-order-review tbody tr:not(:last-child), .multicheckout.order-review .data.table tbody tr:not(:last-child) {
    border-bottom: 1px solid #E0E6EE; }
  .abs-checkout-order-review tbody tr .col.item:before, .multicheckout.order-review .data.table tbody tr .col.item:before {
    display: none; }
  .abs-checkout-order-review tbody tr .col.qty, .multicheckout.order-review .data.table tbody tr .col.qty, .abs-checkout-order-review tbody tr .col.price, .multicheckout.order-review .data.table tbody tr .col.price, .abs-checkout-order-review tbody tr .col.subtotal, .multicheckout.order-review .data.table tbody tr .col.subtotal {
    box-sizing: border-box;
    float: left;
    text-align: center;
    white-space: nowrap;
    width: 33%; }
    .abs-checkout-order-review tbody tr .col.qty:before, .multicheckout.order-review .data.table tbody tr .col.qty:before, .abs-checkout-order-review tbody tr .col.price:before, .multicheckout.order-review .data.table tbody tr .col.price:before, .abs-checkout-order-review tbody tr .col.subtotal:before, .multicheckout.order-review .data.table tbody tr .col.subtotal:before {
      content: attr(data-th) ":";
      display: block;
      font-weight: 700;
      padding-bottom: 10px; }
  .abs-checkout-order-review tbody tr .product-item-name, .multicheckout.order-review .data.table tbody tr .product-item-name {
    margin: 0; } }

.abs-colon:after, .minicart-items .details-qty .label:after,
.minicart-items .price-minicart .label:after, .order-details-items .order-gift-message .label:after,
.order-details-items .block-order-details-gift-message .label:after, .block-wishlist-management .wishlist-select .wishlist-name-label:after {
  content: ': '; }

.abs-icon-add, .abs-dropdown-items-new .action.new, .wishlist.split.button .action.new, .wishlist-dropdown .action.new {
  display: inline-block;
  text-decoration: none; }
  .abs-icon-add:before, .abs-dropdown-items-new .action.new:before, .wishlist.split.button .action.new:before, .wishlist-dropdown .action.new:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 10px;
    line-height: 10px;
    color: #a2a2a2;
    content: "";
    font-family: "Material Icons";
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center; }

@media only screen and (max-width: 1023px) {
  .abs-icon-add-mobile, .block-wishlist-management .wishlist-add.item .add {
    display: block;
    text-decoration: none; }
    .abs-icon-add-mobile:before, .block-wishlist-management .wishlist-add.item .add:before {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 10px;
      line-height: 10px;
      color: #a2a2a2;
      content: "";
      font-family: "Material Icons";
      margin: 0 5px 0 0;
      vertical-align: middle;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; } }

.abs-dropdown-items-new .items .item:last-child:hover, .wishlist.split.button .items .item:last-child:hover, .wishlist-dropdown .items .item:last-child:hover {
  background: #e8e8e8; }

.abs-dropdown-items-new .action.new:before, .wishlist.split.button .action.new:before, .wishlist-dropdown .action.new:before {
  margin-left: -17px;
  margin-right: 5px; }

@media only screen and (max-width: 1023px) {
  .abs-toggling-title-mobile, .block-wishlist-management .wishlist-select .wishlist-name, .block-collapsible-nav .title {
    border-bottom: 1px solid #E0E6EE;
    border-top: 1px solid #E0E6EE;
    cursor: pointer;
    margin-bottom: 0;
    padding: 10px 40px 10px 15px;
    position: relative;
    display: block;
    text-decoration: none; }
    .abs-toggling-title-mobile:after, .block-wishlist-management .wishlist-select .wishlist-name:after, .block-collapsible-nav .title:after {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 28px;
      line-height: inherit;
      color: #a2a2a2;
      content: "";
      font-family: "Material Icons";
      vertical-align: middle;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; }
    .abs-toggling-title-mobile:after, .block-wishlist-management .wishlist-select .wishlist-name:after, .block-collapsible-nav .title:after {
      position: absolute;
      right: 10px;
      top: 0; }
    .abs-toggling-title-mobile.active:after, .block-wishlist-management .wishlist-select .active.wishlist-name:after, .block-collapsible-nav .active.title:after {
      content: ""; } }

.abs-no-display, .abs-sidebar-totals .table-caption, .cart-totals .table-caption, .opc-block-summary .table-totals .table-caption, .sidebar .subtitle, .summary .block .fieldset.estimate > .legend,
.summary .block .fieldset.estimate > .legend + br, .minicart-items .product-item-details .weee[data-label] .label, .page-product-giftcard .fieldset.giftcard > .legend + br, .order-details-items .order-items .order-gift-message .action.close, .multicheckout .block .methods-shipping .item-content .fieldset > .legend + br, .multicheckout .hidden, [class^='multishipping-'] .nav-sections,
[class^='multishipping-'] .nav-toggle, .sidebar-main .block-reorder .subtitle,
.sidebar-additional .block-reorder .subtitle, .no-display {
  display: none; }

@media only screen and (max-width: 767px) {
  .abs-no-display-s, .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison).additional-attributes tbody th, .opc-wrapper .shipping-address-item.selected-item .action-select-shipping-item {
    display: none; } }

@media only screen and (min-width: 1024px) {
  .abs-no-display-desktop, .opc-estimated-wrapper, .sidebar .block.widget .pager .item:not(.pages-item-next):not(.pages-item-previous) {
    display: none; } }

.abs-status, .return-status, .order-status {
  display: inline-block;
  margin-bottom: 20px; }

@media only screen and (max-width: 1023px) {
  .abs-pager-toolbar-mobile .toolbar-amount, .toolbar-wishlist-results .toolbar-amount,
  .abs-pager-toolbar-mobile .limiter,
  .toolbar-wishlist-results .limiter,
  .abs-pager-toolbar-mobile .pages,
  .toolbar-wishlist-results .pages {
    float: none;
    margin-bottom: 25px; } }

@media only screen and (max-width: 767px) {
  .abs-pager-toolbar-mobile-s .toolbar-amount, .toolbar-giftregistry-results .toolbar-amount,
  .abs-pager-toolbar-mobile-s .limiter,
  .toolbar-giftregistry-results .limiter,
  .abs-pager-toolbar-mobile-s .pages,
  .toolbar-giftregistry-results .pages {
    margin-bottom: 25px; } }

@media only screen and (min-width: 1024px) {
  .abs-pager-toolbar, .account .toolbar, .toolbar-giftregistry-results, .toolbar-wishlist-results {
    margin-bottom: 20px;
    position: relative; }
    .abs-pager-toolbar .limiter, .account .toolbar .limiter, .toolbar-giftregistry-results .limiter, .toolbar-wishlist-results .limiter {
      float: right;
      position: relative;
      z-index: 1; }
    .abs-pager-toolbar .toolbar-amount, .account .toolbar .toolbar-amount, .toolbar-giftregistry-results .toolbar-amount, .toolbar-wishlist-results .toolbar-amount {
      float: left;
      line-height: normal;
      padding: 7px 0 0;
      position: relative;
      z-index: 1; }
    .abs-pager-toolbar .pages, .account .toolbar .pages, .toolbar-giftregistry-results .pages, .toolbar-wishlist-results .pages {
      position: absolute;
      width: 100%;
      z-index: 0; } }

.abs-block-items-counter, .block-compare .counter, .block-wishlist .counter {
  color: #47e96c;
  font-size: 1.2rem;
  white-space: nowrap; }

.abs-shopping-cart-items .action.continue, .block-cart-failed .action.continue, .cart-container .form-cart .action.continue {
  border-radius: 3px;
  font-weight: 700;
  display: inline-block;
  text-decoration: none;
  background-image: none;
  background: #e0e6ee;
  border: 0;
  color: #118b2d;
  cursor: pointer;
  display: inline-block;
  font-family: "Roboto", Helvetica, sans-serif;
  font-weight: 400;
  margin: 0;
  padding: 7px 15px 7px 0;
  font-size: 1.4rem;
  line-height: 1.6rem;
  box-sizing: border-box;
  vertical-align: middle;
  display: inline-block;
  text-decoration: none; }
  .abs-shopping-cart-items .action.continue:hover, .block-cart-failed .action.continue:hover, .cart-container .form-cart .action.continue:hover, .abs-shopping-cart-items .action.continue:active, .block-cart-failed .action.continue:active, .cart-container .form-cart .action.continue:active, .abs-shopping-cart-items .action.continue:focus, .block-cart-failed .action.continue:focus, .cart-container .form-cart .action.continue:focus {
    text-decoration: none; }
  .abs-shopping-cart-items .action.continue:before, .block-cart-failed .action.continue:before, .cart-container .form-cart .action.continue:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 32px;
    line-height: 16px;
    color: inherit;
    content: "";
    font-family: "Material Icons";
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center; }
  .abs-shopping-cart-items .action.continue:hover:before, .block-cart-failed .action.continue:hover:before, .cart-container .form-cart .action.continue:hover:before {
    color: inherit; }
  .abs-shopping-cart-items .action.continue:active:before, .block-cart-failed .action.continue:active:before, .cart-container .form-cart .action.continue:active:before {
    color: inherit; }
  .abs-shopping-cart-items .action.continue:focus, .block-cart-failed .action.continue:focus, .cart-container .form-cart .action.continue:focus, .abs-shopping-cart-items .action.continue:active, .block-cart-failed .action.continue:active, .cart-container .form-cart .action.continue:active {
    background: #d0d9e5;
    border: 0;
    color: #118b2d; }
  .abs-shopping-cart-items .action.continue:hover, .block-cart-failed .action.continue:hover, .cart-container .form-cart .action.continue:hover {
    background: #d0d9e5;
    border: 0;
    color: #555; }
  .abs-shopping-cart-items .action.continue.disabled, .block-cart-failed .action.continue.disabled, .cart-container .form-cart .action.continue.disabled, .abs-shopping-cart-items .action.continue[disabled], .block-cart-failed .action.continue[disabled], .cart-container .form-cart .action.continue[disabled],
  fieldset[disabled] .abs-shopping-cart-items .action.continue,
  fieldset[disabled] .block-cart-failed .action.continue,
  fieldset[disabled] .cart-container .form-cart .action.continue,
  .cart-container fieldset[disabled] .form-cart .action.continue {
    opacity: 0.5;
    cursor: default;
    pointer-events: none; }
  .abs-shopping-cart-items .action.continue:active, .block-cart-failed .action.continue:active, .cart-container .form-cart .action.continue:active {
    box-shadow: inset 0 2px 1px rgba(0, 0, 0, 0.12); }

.abs-shopping-cart-items .action.update, .block-cart-failed .action.update, .cart-container .form-cart .action.update {
  display: inline-block;
  text-decoration: none;
  padding-left: 5px; }
  .abs-shopping-cart-items .action.update:before, .block-cart-failed .action.update:before, .cart-container .form-cart .action.update:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 32px;
    line-height: 16px;
    color: inherit;
    content: "";
    font-family: "Material Icons";
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center; }
  .abs-shopping-cart-items .action.update:hover:before, .block-cart-failed .action.update:hover:before, .cart-container .form-cart .action.update:hover:before {
    color: inherit; }
  .abs-shopping-cart-items .action.update:active:before, .block-cart-failed .action.update:active:before, .cart-container .form-cart .action.update:active:before {
    color: inherit; }

@media only screen and (max-width: 1023px) {
  .abs-shopping-cart-items-mobile .actions, .cart-container .form-cart .actions {
    text-align: center; }
  .abs-shopping-cart-items-mobile .action.update, .cart-container .form-cart .action.update, .abs-shopping-cart-items-mobile .action.continue, .cart-container .form-cart .action.continue, .abs-shopping-cart-items-mobile .action.clear, .cart-container .form-cart .action.clear {
    margin: 0 auto 10px; }
  .abs-shopping-cart-items-mobile .action.update, .cart-container .form-cart .action.update, .abs-shopping-cart-items-mobile .action.clear, .cart-container .form-cart .action.clear {
    display: block; } }

@media only screen and (min-width: 1024px) {
  .abs-shopping-cart-items-desktop, .block-cart-failed, .cart-container .form-cart, .cart-container .cart-gift-item {
    float: left;
    position: relative;
    width: 73%; }
    .abs-shopping-cart-items-desktop .actions, .block-cart-failed .actions, .cart-container .form-cart .actions, .cart-container .cart-gift-item .actions {
      text-align: right; }
    .abs-shopping-cart-items-desktop .action.clear, .block-cart-failed .action.clear, .cart-container .form-cart .action.clear, .cart-container .cart-gift-item .action.clear, .abs-shopping-cart-items-desktop .action.update, .block-cart-failed .action.update, .cart-container .form-cart .action.update, .cart-container .cart-gift-item .action.update {
      margin-left: 10px; }
    .abs-shopping-cart-items-desktop .action.continue, .block-cart-failed .action.continue, .cart-container .form-cart .action.continue, .cart-container .cart-gift-item .action.continue {
      float: left; } }

.abs-field-date .control, .field.date .control {
  position: relative; }

.abs-field-date-input, .abs-field-date input, .field.date input, .form-giftregistry-search .fields-specific-options .datetime-picker {
  margin-right: 10px;
  width: calc(100% - 50px); }

.abs-field-tooltip, .field .control._with-tooltip {
  position: relative; }
  .abs-field-tooltip input, .field .control._with-tooltip input {
    margin-right: 10px;
    width: calc(100% - 36px); }

.abs-checkout-tooltip-content-position-top, .checkout-index-index .modal-popup .field-tooltip .field-tooltip-content, .shipping-policy-block.field-tooltip .field-tooltip-content {
  right: -10px;
  top: 40px;
  left: auto; }
  .abs-checkout-tooltip-content-position-top:before, .checkout-index-index .modal-popup .field-tooltip .field-tooltip-content:before, .shipping-policy-block.field-tooltip .field-tooltip-content:before, .abs-checkout-tooltip-content-position-top:after, .checkout-index-index .modal-popup .field-tooltip .field-tooltip-content:after, .shipping-policy-block.field-tooltip .field-tooltip-content:after {
    border: 10px solid transparent;
    height: 0;
    width: 0;
    margin-top: -21px;
    right: 10px;
    left: auto;
    top: 0; }
  .abs-checkout-tooltip-content-position-top:before, .checkout-index-index .modal-popup .field-tooltip .field-tooltip-content:before, .shipping-policy-block.field-tooltip .field-tooltip-content:before {
    border-bottom-color: #999; }
  .abs-checkout-tooltip-content-position-top:after, .checkout-index-index .modal-popup .field-tooltip .field-tooltip-content:after, .shipping-policy-block.field-tooltip .field-tooltip-content:after {
    border-bottom-color: #f4f4f4;
    top: 1px; }

@media only screen and (max-width: 1023px) {
  .abs-checkout-tooltip-content-position-top-mobile, .field-tooltip .field-tooltip-content {
    right: -10px;
    top: 40px;
    left: auto; }
    .abs-checkout-tooltip-content-position-top-mobile:before, .field-tooltip .field-tooltip-content:before, .abs-checkout-tooltip-content-position-top-mobile:after, .field-tooltip .field-tooltip-content:after {
      border: 10px solid transparent;
      height: 0;
      width: 0;
      margin-top: -21px;
      right: 10px;
      left: auto;
      top: 0; }
    .abs-checkout-tooltip-content-position-top-mobile:before, .field-tooltip .field-tooltip-content:before {
      border-bottom-color: #999; }
    .abs-checkout-tooltip-content-position-top-mobile:after, .field-tooltip .field-tooltip-content:after {
      border-bottom-color: #f4f4f4;
      top: 1px; } }

.abs-checkout-title, .opc-block-shipping-information .shipping-information-title, .opc-block-summary > .title, .checkout-agreements-items .checkout-agreements-item-title {
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  color: #434343;
  font-weight: 300;
  font-size: 2.6rem; }

.abs-sidebar-totals .mark, .cart-totals .mark, .opc-block-summary .table-totals .mark {
  font-weight: 400;
  padding-left: 4px; }
  .abs-sidebar-totals .mark strong, .cart-totals .mark strong, .opc-block-summary .table-totals .mark strong {
    font-weight: 400; }

.abs-sidebar-totals .amount, .cart-totals .amount, .opc-block-summary .table-totals .amount {
  padding-right: 4px;
  text-align: right;
  white-space: nowrap; }
  .abs-sidebar-totals .amount strong, .cart-totals .amount strong, .opc-block-summary .table-totals .amount strong {
    font-weight: 400; }

.abs-sidebar-totals .grand .mark, .cart-totals .grand .mark, .opc-block-summary .table-totals .grand .mark,
.abs-sidebar-totals .grand .amount,
.cart-totals .grand .amount,
.opc-block-summary .table-totals .grand .amount {
  padding-top: 20px; }


.abs-sidebar-totals .grand .amount,
.cart-totals .grand .amount,
.opc-block-summary .table-totals .grand .amount {
  padding-right: 4px;
  text-align: right; }
  .abs-sidebar-totals .grand .amount strong, .cart-totals .grand .amount strong, .opc-block-summary .table-totals .grand .amount strong {
    font-weight: 700; }

.abs-sidebar-totals .msrp, .cart-totals .msrp, .opc-block-summary .table-totals .msrp {
  margin-bottom: 10px; }

.abs-sidebar-totals .totals-tax-summary .mark, .cart-totals .totals-tax-summary .mark, .opc-block-summary .table-totals .totals-tax-summary .mark,
.abs-sidebar-totals .totals-tax-summary .amount,
.cart-totals .totals-tax-summary .amount,
.opc-block-summary .table-totals .totals-tax-summary .amount {
  border-top: 1px solid #E0E6EE;
  border-bottom: 1px solid #E0E6EE;
  cursor: pointer; }

.abs-sidebar-totals .totals-tax-summary .amount .price, .cart-totals .totals-tax-summary .amount .price, .opc-block-summary .table-totals .totals-tax-summary .amount .price {
  display: block;
  text-decoration: none;
  padding-right: 25px;
  position: relative; }
  .abs-sidebar-totals .totals-tax-summary .amount .price > span, .cart-totals .totals-tax-summary .amount .price > span, .opc-block-summary .table-totals .totals-tax-summary .amount .price > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
  .abs-sidebar-totals .totals-tax-summary .amount .price:after, .cart-totals .totals-tax-summary .amount .price:after, .opc-block-summary .table-totals .totals-tax-summary .amount .price:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 30px;
    line-height: inherit;
    color: #a2a2a2;
    content: "";
    font-family: "Material Icons";
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center; }
  .abs-sidebar-totals .totals-tax-summary .amount .price:after, .cart-totals .totals-tax-summary .amount .price:after, .opc-block-summary .table-totals .totals-tax-summary .amount .price:after {
    position: absolute;
    right: -5px;
    top: -12px; }

.abs-sidebar-totals .totals-tax-summary.expanded .mark, .cart-totals .totals-tax-summary.expanded .mark, .opc-block-summary .table-totals .totals-tax-summary.expanded .mark,
.abs-sidebar-totals .totals-tax-summary.expanded .amount,
.cart-totals .totals-tax-summary.expanded .amount,
.opc-block-summary .table-totals .totals-tax-summary.expanded .amount {
  border-bottom: 0; }

.abs-sidebar-totals .totals-tax-summary.expanded .amount .price:after, .cart-totals .totals-tax-summary.expanded .amount .price:after, .opc-block-summary .table-totals .totals-tax-summary.expanded .amount .price:after {
  content: ""; }

.abs-sidebar-totals .totals-tax-details, .cart-totals .totals-tax-details, .opc-block-summary .table-totals .totals-tax-details {
  display: none;
  border-bottom: 1px solid #E0E6EE; }
  .abs-sidebar-totals .totals-tax-details.shown, .cart-totals .totals-tax-details.shown, .opc-block-summary .table-totals .totals-tax-details.shown {
    display: table-row; }

.abs-discount-block > .title, .summary .block > .title, .paypal-review-discount .block > .title {
  border-top: 1px solid #E0E6EE;
  cursor: pointer;
  font-weight: 600;
  display: block;
  text-decoration: none;
  margin-bottom: 0;
  overflow: hidden;
  padding: 7px 30px 7px 5px;
  position: relative; }
  .abs-discount-block > .title:after, .summary .block > .title:after, .paypal-review-discount .block > .title:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 30px;
    line-height: inherit;
    color: #a2a2a2;
    content: "";
    font-family: "Material Icons";
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center; }
  .abs-discount-block > .title:after, .summary .block > .title:after, .paypal-review-discount .block > .title:after {
    position: absolute;
    right: -5px;
    top: -5px; }
  .column.main .abs-discount-block > .title strong, .column.main .summary .block > .title strong, .summary .column.main .block > .title strong, .column.main .paypal-review-discount .block > .title strong, .paypal-review-discount .column.main .block > .title strong {
    font-size: 1.8rem;
    font-weight: 400; }

.abs-discount-block > .content, .summary .block > .content, .paypal-review-discount .block > .content {
  display: none; }

.abs-discount-block.active > .title:after, .summary .active.block > .title:after, .paypal-review-discount .active.block > .title:after {
  content: ""; }

.abs-discount-block.active > .content, .summary .active.block > .content, .paypal-review-discount .active.block > .content {
  display: block; }

.fieldset {
  border: 0;
  margin: 0 0 40px;
  padding: 0;
  letter-spacing: -0.31em; }
  .fieldset > * {
    letter-spacing: normal; }
  .fieldset > .legend {
    margin: 0 0 25px;
    padding: 0;
    box-sizing: border-box;
    float: left;
    line-height: 1.2;
    font-size: 2rem; }
    .fieldset > .legend + br {
      clear: both;
      display: block;
      height: 0;
      overflow: hidden;
      visibility: hidden; }
  .fieldset:last-child {
    margin-bottom: 0; }
  .fieldset > .field,
  .fieldset > .fields > .field {
    margin: 0 0 20px; }
    .fieldset > .field > .label,
    .fieldset > .fields > .field > .label {
      margin: 0 0 5px;
      display: inline-block; }
    .fieldset > .field:last-child,
    .fieldset > .fields > .field:last-child {
      margin-bottom: 0; }
    .fieldset > .field > .label,
    .fieldset > .fields > .field > .label {
      font-weight: 700; }
      .fieldset > .field > .label + br,
      .fieldset > .fields > .field > .label + br {
        display: none; }
    .fieldset > .field .choice input,
    .fieldset > .fields > .field .choice input {
      vertical-align: top; }
    .fieldset > .field .fields.group:before, .fieldset > .field .fields.group:after,
    .fieldset > .fields > .field .fields.group:before,
    .fieldset > .fields > .field .fields.group:after {
      content: '';
      display: table; }
    .fieldset > .field .fields.group:after,
    .fieldset > .fields > .field .fields.group:after {
      clear: both; }
    .fieldset > .field .fields.group .field,
    .fieldset > .fields > .field .fields.group .field {
      box-sizing: border-box;
      float: left; }
    .fieldset > .field .fields.group.group-2 .field,
    .fieldset > .fields > .field .fields.group.group-2 .field {
      width: 50% !important; }
    .fieldset > .field .fields.group.group-3 .field,
    .fieldset > .fields > .field .fields.group.group-3 .field {
      width: 33.3% !important; }
    .fieldset > .field .fields.group.group-4 .field,
    .fieldset > .fields > .field .fields.group.group-4 .field {
      width: 25% !important; }
    .fieldset > .field .fields.group.group-5 .field,
    .fieldset > .fields > .field .fields.group.group-5 .field {
      width: 20% !important; }
    .fieldset > .field .addon,
    .fieldset > .fields > .field .addon {
      display: inline-flex;
      flex-wrap: nowrap;
      padding: 0;
      width: 100%; }
      .fieldset > .field .addon textarea,
      .fieldset > .field .addon select,
      .fieldset > .field .addon input,
      .fieldset > .fields > .field .addon textarea,
      .fieldset > .fields > .field .addon select,
      .fieldset > .fields > .field .addon input {
        order: 2;
        flex-basis: 100%;
        display: inline-block;
        margin: 0;
        width: auto; }
      .fieldset > .field .addon .addbefore,
      .fieldset > .field .addon .addafter,
      .fieldset > .fields > .field .addon .addbefore,
      .fieldset > .fields > .field .addon .addafter {
        background: #fff;
        background-clip: padding-box;
        border: 1px solid #c2c2c2;
        border-radius: 1px;
        font-family: "Roboto", Helvetica, sans-serif;
        font-size: 14px;
        height: 32px;
        line-height: 1.42857;
        padding: 0 9px;
        vertical-align: baseline;
        width: 100%;
        box-sizing: border-box;
        order: 3;
        display: inline-block;
        vertical-align: middle;
        white-space: nowrap;
        width: auto; }
        .fieldset > .field .addon .addbefore:disabled,
        .fieldset > .field .addon .addafter:disabled,
        .fieldset > .fields > .field .addon .addbefore:disabled,
        .fieldset > .fields > .field .addon .addafter:disabled {
          opacity: 0.5; }
        .fieldset > .field .addon .addbefore::-moz-placeholder,
        .fieldset > .field .addon .addafter::-moz-placeholder,
        .fieldset > .fields > .field .addon .addbefore::-moz-placeholder,
        .fieldset > .fields > .field .addon .addafter::-moz-placeholder {
          color: #c2c2c2; }
        .fieldset > .field .addon .addbefore::-webkit-input-placeholder,
        .fieldset > .field .addon .addafter::-webkit-input-placeholder,
        .fieldset > .fields > .field .addon .addbefore::-webkit-input-placeholder,
        .fieldset > .fields > .field .addon .addafter::-webkit-input-placeholder {
          color: #c2c2c2; }
        .fieldset > .field .addon .addbefore:-ms-input-placeholder,
        .fieldset > .field .addon .addafter:-ms-input-placeholder,
        .fieldset > .fields > .field .addon .addbefore:-ms-input-placeholder,
        .fieldset > .fields > .field .addon .addafter:-ms-input-placeholder {
          color: #c2c2c2; }
      .fieldset > .field .addon .addbefore,
      .fieldset > .fields > .field .addon .addbefore {
        float: left;
        order: 1; }
    .fieldset > .field .additional,
    .fieldset > .fields > .field .additional {
      margin-top: 10px; }
    .fieldset > .field.required > .label:after,
    .fieldset > .field._required > .label:after,
    .fieldset > .fields > .field.required > .label:after,
    .fieldset > .fields > .field._required > .label:after {
      content: '*';
      color: #e02b27;
      font-size: 1.2rem;
      margin: 0 0 0 5px; }
    .fieldset > .field .note,
    .fieldset > .fields > .field .note {
      font-size: 1.2rem;
      margin: 3px 0 0;
      padding: 0;
      display: inline-block;
      text-decoration: none; }
      .fieldset > .field .note:before,
      .fieldset > .fields > .field .note:before {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 24px;
        line-height: 12px;
        content: "";
        font-family: "Material Icons";
        vertical-align: middle;
        display: inline-block;
        font-weight: normal;
        overflow: hidden;
        speak: none;
        text-align: center; }
    .fieldset > .field.choice .label,
    .fieldset > .fields > .field.choice .label {
      display: inline;
      font-weight: normal; }
    .column:not(.main) .fieldset > .field .label, .column:not(.main)
    .fieldset > .fields > .field .label {
      font-weight: normal; }
    .fieldset > .field .field.choice,
    .fieldset > .fields > .field .field.choice {
      margin-bottom: 10px; }
      .fieldset > .field .field.choice:last-child,
      .fieldset > .fields > .field .field.choice:last-child {
        margin-bottom: 0; }
    .fieldset > .field input[type=file],
    .fieldset > .fields > .field input[type=file] {
      margin: 5px 0; }

.legend + .fieldset,
.legend + div {
  clear: both; }

.legend strong {
  margin-left: 5px; }

fieldset.field {
  border: 0;
  padding: 0; }

.field.date .time-picker {
  display: inline-block;
  margin-top: 10px;
  white-space: nowrap; }

.field .message.warning {
  margin-top: 10px; }

.field-error,
div.mage-error[generated] {
  margin-top: 7px; }

.field-error {
  color: #e02b27;
  font-size: 1.2rem; }

.field .tooltip {
  position: relative; }
  .field .tooltip .tooltip-content {
    background: #fff;
    max-width: 360px;
    min-width: 210px;
    padding: 12px 16px;
    z-index: 100;
    display: none;
    position: absolute;
    text-align: left;
    color: #118b2d;
    line-height: 1.4;
    border: 1px solid #bbb;
    margin-left: 5px;
    left: 100%;
    top: 0; }
    .field .tooltip .tooltip-content:after, .field .tooltip .tooltip-content:before {
      border: solid transparent;
      content: '';
      height: 0;
      position: absolute;
      width: 0; }
    .field .tooltip .tooltip-content:after {
      border-width: 5px;
      border-color: transparent; }
    .field .tooltip .tooltip-content:before {
      border-width: 6px;
      border-color: transparent; }
    .field .tooltip .tooltip-content:after, .field .tooltip .tooltip-content:before {
      right: 100%; }
    .field .tooltip .tooltip-content:after {
      border-right-color: #fff;
      margin-top: -5px;
      top: 15px; }
    .field .tooltip .tooltip-content:before {
      border-right-color: #bbb;
      margin-top: -6px;
      top: 15px; }
  .field .tooltip .tooltip-toggle {
    cursor: help; }
  .field .tooltip .tooltip-toggle:hover + .tooltip-content,
  .field .tooltip .tooltip-toggle:focus + .tooltip-content,
  .field .tooltip:hover .tooltip-content {
    display: block; }
  .field .tooltip .tooltip-content {
    min-width: 200px;
    white-space: normal; }

input:focus ~ .tooltip .tooltip-content,
select:focus ~ .tooltip .tooltip-content {
  display: block; }

._has-datepicker ~ .ui-datepicker-trigger {
  background-image: none;
  background: none;
  -moz-box-sizing: content-box;
  border: 0;
  box-shadow: none;
  line-height: inherit;
  margin: 0;
  padding: 0;
  text-decoration: none;
  text-shadow: none;
  font-weight: 400;
  display: block;
  text-decoration: none;
  display: inline-block;
  vertical-align: middle; }
  ._has-datepicker ~ .ui-datepicker-trigger:focus, ._has-datepicker ~ .ui-datepicker-trigger:active {
    background: none;
    border: none; }
  ._has-datepicker ~ .ui-datepicker-trigger:hover {
    background: none;
    border: none; }
  ._has-datepicker ~ .ui-datepicker-trigger.disabled, ._has-datepicker ~ .ui-datepicker-trigger[disabled],
  fieldset[disabled] ._has-datepicker ~ .ui-datepicker-trigger {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5; }
  ._has-datepicker ~ .ui-datepicker-trigger > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
  ._has-datepicker ~ .ui-datepicker-trigger:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 40px;
    line-height: 40px;
    color: #47e96c;
    content: "";
    font-family: "Material Icons";
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center; }
  ._has-datepicker ~ .ui-datepicker-trigger:focus {
    box-shadow: none;
    outline: 0; }

.sidebar .fieldset {
  margin: 0; }
  .sidebar .fieldset > .field:not(.choice):not(:last-child),
  .sidebar .fieldset .fields > .field:not(:last-child) {
    margin: 0 0 20px; }
  .sidebar .fieldset > .field:not(.choice) .label,
  .sidebar .fieldset .fields > .field .label {
    margin: 0 0 4px;
    padding: 0 0 5px;
    text-align: left;
    width: 100%; }
  .sidebar .fieldset > .field:not(.choice) .control,
  .sidebar .fieldset .fields > .field .control {
    width: 100%; }

@media only screen and (min-width: 1024px) {
  .fieldset > .field {
    margin: 0 0 20px;
    box-sizing: border-box;
    margin: 0 0 29px; }
    .fieldset > .field > .label {
      margin: 0; }
    .fieldset > .field:before, .fieldset > .field:after {
      content: '';
      display: table; }
    .fieldset > .field:after {
      clear: both; }
    .fieldset > .field.choice:before, .fieldset > .field.no-label:before {
      padding: 6px 15px 0 0;
      width: 25.8%;
      box-sizing: border-box;
      content: ' ';
      float: left;
      height: 1px; }
    .fieldset > .field .description {
      padding: 6px 15px 0 0;
      text-align: right;
      width: 25.8%;
      box-sizing: border-box;
      float: left; }
    .fieldset > .field:not(.choice) > .label {
      padding: 6px 15px 0 0;
      text-align: right;
      width: 25.8%;
      box-sizing: border-box;
      float: left; }
    .fieldset > .field:not(.choice) > .control {
      width: 74.2%;
      float: left; } }

@font-face {
  font-family: "Material Icons";
  src: url("../fonts/MaterialIcons-Regular.eot");
  src: url("../fonts/MaterialIcons-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/MaterialIcons-Regular.woff2") format("woff2"), url("../fonts/MaterialIcons-Regular.woff") format("woff"), url("../fonts/MaterialIcons-Regular.ttf") format("truetype"), url("../fonts/MaterialIcons-Regular.svg#Material Icons") format("svg");
  font-weight: normal;
  font-style: normal; }

.columns {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box; }
  .columns:after {
    clear: both;
    content: ' ';
    display: block;
    height: 0;
    overflow: hidden;
    visibility: hidden; }
  .columns .column.main {
    padding-bottom: 40px;
    flex-basis: 100%;
    max-width: 100%;
    flex-grow: 1;
    order: 1; }
  .columns .sidebar-main {
    flex-grow: 1;
    flex-basis: 100%;
    order: -1; }
  .columns .sidebar-additional {
    display: none; }

.catalogsearch-result-index .sidebar-main {
  display: none; }

@media only screen and (max-width: 1023px) {
  .navigation,
  .breadcrumbs,
  .page-header .header.panel,
  .header.content,
  .footer.content,
  .page-main,
  .page-wrapper > .widget,
  .page-wrapper > .page-bottom,
  .block.category.event,
  .top-container {
    padding-left: 15px;
    padding-right: 15px; }
  .breadcrumbs {
    order: -10; }
  .account .page-main,
  .cms-privacy-policy .page-main {
    padding-top: 41px;
    position: relative; }
  .columns .column.main {
    display: flex;
    flex-direction: column; }
    .columns .column.main .toolbar.toolbar-products {
      flex-direction: column;
      order: 999; }
      .columns .column.main .toolbar.toolbar-products .pages {
        display: block; }
  body.cms-index-index .columns .column.main {
    display: block; } }

@media only screen and (min-width: 1024px) {
  .navigation,
  .breadcrumbs,
  .page-header .header.panel,
  .header.content,
  .footer.content,
  .page-wrapper > .widget,
  .page-wrapper > .page-bottom,
  .block.category.event,
  .top-container,
  .page-main {
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    max-width: 1440px;
    padding-left: 1rem;
    padding-right: 1rem;
    width: auto; }
  .page-main {
    width: 100%;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: auto; }
    .ie9 .page-main {
      width: auto; }
  .page-bottom {
    min-width: 100%; }
  .columns {
    display: block; }
  .column.main {
    width: 80%;
    min-height: 300px; }
    .page-layout-1column .column.main {
      width: 100%;
      order: 2; }
    .page-layout-3columns .column.main {
      width: 58.33333%;
      display: inline-block;
      order: 2; }
    .page-layout-2columns-left .column.main {
      width: 79.16667%;
      float: right;
      order: 2; }
    .page-layout-2columns-right .column.main {
      width: 79.16667%;
      float: left;
      order: 1; }
  .sidebar-main {
    padding-right: 2%;
    width: 20% !important; }
    .page-layout-3columns .sidebar-main {
      width: 20.83333%;
      float: left;
      order: 1; }
    .page-layout-2columns-left .sidebar-main {
      width: 20.83333%;
      float: left;
      order: 1; }
    .page-layout-2columns-right .sidebar-main {
      width: 20.83333%;
      float: left;
      order: 1; }
  .page-layout-2columns-right .sidebar-main {
    padding-left: 2%;
    padding-right: 0; }
  .sidebar-additional {
    clear: right;
    padding-left: 2%; }
    .page-layout-3columns .sidebar-additional {
      width: 20.83333%;
      float: right;
      order: 3; }
    .page-layout-2columns-left .sidebar-additional {
      width: 20.83333%;
      float: right;
      order: 2; }
    .page-layout-2columns-right .sidebar-additional {
      width: 20.83333%;
      float: right;
      order: 2; }
  .page-layout-2columns-left .sidebar-additional {
    clear: left;
    float: left;
    padding-left: 0;
    padding-right: 2%; }
  .panel.header {
    padding: 10px 20px; } }

.load.indicator {
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 9999;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  position: absolute; }
  .load.indicator:before {
    background: transparent url("../images/loader-2.gif") no-repeat 50% 50%;
    border-radius: 5px;
    height: 160px;
    width: 160px;
    bottom: 0;
    box-sizing: border-box;
    content: '';
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0; }
  .load.indicator > span {
    display: none; }

.loading-mask {
  bottom: 0;
  left: 0;
  margin: auto;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
  background: rgba(255, 255, 255, 0.5); }
  .loading-mask .loader > img {
    bottom: 0;
    left: 0;
    margin: auto;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 100; }
  .loading-mask .loader > p {
    display: none; }

body > .loading-mask {
  z-index: 9999; }

._block-content-loading {
  position: relative; }

.message.info {
  margin: 0 0 10px;
  padding: 10px 20px;
  display: block;
  line-height: 1.2em;
  font-size: 1.3rem;
  background: #fdf0d5;
  color: #6f4400;
  padding-left: 40px;
  position: relative; }
  .message.info a {
    color: #118b2d; }
    .message.info a:hover {
      color: #0e7426; }
    .message.info a:active {
      color: #0e7426; }
  .message.info > *:first-child:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 28px;
    line-height: 28px;
    color: #c07600;
    content: "";
    font-family: "Material Icons";
    margin: -14px 0 0;
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
    left: 0;
    top: 18px;
    width: 40px;
    position: absolute;
    text-align: center; }

.message.error {
  margin: 0 0 10px;
  padding: 10px 20px;
  display: block;
  line-height: 1.2em;
  font-size: 1.3rem;
  background: #fae5e5;
  color: #e02b27;
  padding-left: 40px;
  position: relative; }
  .message.error a {
    color: #118b2d; }
    .message.error a:hover {
      color: #0e7426; }
    .message.error a:active {
      color: #0e7426; }
  .message.error > *:first-child:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 28px;
    line-height: 28px;
    color: #b30000;
    content: "";
    font-family: "Material Icons";
    margin: -14px 0 0;
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
    left: 0;
    top: 18px;
    width: 40px;
    position: absolute;
    text-align: center; }

.message.warning {
  margin: 0 0 10px;
  padding: 10px 20px;
  display: block;
  line-height: 1.2em;
  font-size: 1.3rem;
  background: #fdf0d5;
  color: #6f4400;
  padding-left: 40px;
  position: relative; }
  .message.warning a {
    color: #118b2d; }
    .message.warning a:hover {
      color: #0e7426; }
    .message.warning a:active {
      color: #0e7426; }
  .message.warning > *:first-child:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 28px;
    line-height: 28px;
    color: #c07600;
    content: "";
    font-family: "Material Icons";
    margin: -14px 0 0;
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
    left: 0;
    top: 18px;
    width: 40px;
    position: absolute;
    text-align: center; }

.message.notice {
  margin: 0 0 10px;
  padding: 10px 20px;
  display: block;
  line-height: 1.2em;
  font-size: 1.3rem;
  background: #fdf0d5;
  color: #6f4400;
  padding-left: 40px;
  position: relative; }
  .message.notice a {
    color: #118b2d; }
    .message.notice a:hover {
      color: #0e7426; }
    .message.notice a:active {
      color: #0e7426; }
  .message.notice > *:first-child:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 28px;
    line-height: 28px;
    color: #c07600;
    content: "";
    font-family: "Material Icons";
    margin: -14px 0 0;
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
    left: 0;
    top: 18px;
    width: 40px;
    position: absolute;
    text-align: center; }

.message.success {
  margin: 0 0 10px;
  padding: 10px 20px;
  display: block;
  line-height: 1.2em;
  font-size: 1.3rem;
  background: #e5efe5;
  color: #006400;
  padding-left: 40px;
  position: relative; }
  .message.success a {
    color: #118b2d; }
    .message.success a:hover {
      color: #0e7426; }
    .message.success a:active {
      color: #0e7426; }
  .message.success > *:first-child:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 28px;
    line-height: 28px;
    color: #006400;
    content: "";
    font-family: "Material Icons";
    margin: -14px 0 0;
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
    left: 0;
    top: 18px;
    width: 40px;
    position: absolute;
    text-align: center; }

.nav-toggle {
  order: 0;
  display: inline-block;
  text-decoration: none;
  cursor: pointer; }
  .nav-toggle:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 28px;
    line-height: inherit;
    color: #69707d;
    content: "";
    font-family: "Material Icons";
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center; }
  .nav-toggle:hover:before {
    color: #5d646f; }
  .nav-toggle > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }

.nav-sections {
  background-color: #00904a; }
  .nav-sections-item-title {
    display: none; }
  .nav-sections-item-content .navigation {
    box-sizing: border-box;
    padding: 0; }
    .nav-sections-item-content .navigation ul {
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      align-items: flex-start;
      padding: 0.5rem 0;
      margin: 0;
      border: none;
      box-shadow: none; }
    .nav-sections-item-content .navigation li {
      margin: 0;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      box-shadow: none; }
      .nav-sections-item-content .navigation li ul {
        visibility: collapse;
        border: none;
        padding-left: 1rem; }
        .nav-sections-item-content .navigation li ul[aria-expanded="false"] {
          visibility: collapse; }
        .nav-sections-item-content .navigation li ul[aria-expanded="true"] {
          visibility: visible; }
        .nav-sections-item-content .navigation li ul li {
          flex-wrap: wrap;
          margin-left: -3rem; }
          .nav-sections-item-content .navigation li ul li img {
            flex: 0 0 25px; }
          .nav-sections-item-content .navigation li ul li a {
            flex: 0 0 85%; }
      .nav-sections-item-content .navigation li[aria-haspopup="true"] > a:after {
        content: '+';
        display: inline-block;
        right: 0;
        line-height: 0;
        font-size: 3rem;
        color: #cecece;
        margin-left: auto;
        margin-right: 0;
        font-weight: 100; }
      .nav-sections-item-content .navigation li[aria-haspopup="true"] > a.clicked:after,
      .nav-sections-item-content .navigation li[aria-haspopup="true"]:hover > a:after {
        content: '-'; }
    .nav-sections-item-content .navigation a {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: calc(100% - 2.5rem);
      padding: 1rem;
      line-height: 1;
      font-weight: 500;
      color: #fff; }
      .nav-sections-item-content .navigation a:after {
        content: none;
        display: none; }
    .nav-sections-item-content .navigation a,
    .nav-sections-item-content .navigation a:hover {
      color: #fff;
      text-decoration: none; }
    .nav-sections-item-content .navigation .menu-toggler {
      -webkit-appearance: none;
      width: 3rem;
      flex-grow: 1;
      border: none;
      background: none;
      font-family: monospace;
      font-size: 1.5em;
      font-weight: 100;
      color: rgba(0, 0, 0, 0.2);
      text-align: right;
      cursor: pointer; }
    .nav-sections-item-content .navigation > ul > li {
      justify-content: space-between; }
      .nav-sections-item-content .navigation > ul > li > ul > li img {
        -ms-grid-column: 1;
        width: 2.75rem;
        height: 3rem;
        height: unset;
        object-fit: cover;
        object-position: center;
        margin-left: 0.5rem; }
        .nav-sections-item-content .navigation > ul > li > ul > li img + a {
          -ms-grid-column: 2; }

.sidebar-main .filter-title strong {
  font-weight: 400; }

.sidebar-main .filter-options-content {
  display: block !important; }

.sidebar-main .filter-current-subtitle {
  font-weight: 500; }

.sidebar-main .filter-current .items {
  margin-top: 2rem; }

.sidebar-main .filter-current .action.remove {
  position: relative; }

.sidebar-main .block-subtitle {
  display: none; }

.sidebar-main .filter-options-title {
  background: #fff;
  color: #1a1a1a;
  text-transform: uppercase;
  border: 0;
  border-bottom: 1px solid #1a1a1a;
  font-weight: 400;
  font-size: 14px; }
  .sidebar-main .filter-options-title:hover {
    color: #1a1a1a; }

.sidebar-main .filter-options-item {
  background: #fff; }

@media only screen and (max-width: 1199px) {
  .nav-sections {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 90vw;
    height: 100vh;
    z-index: 9999;
    background-color: #f5f5f5;
    visibility: collapse;
    transform: translateX(-100%);
    transition: visibility .5s ease-in-out; }
    .nav-sections-item-title {
      padding: 1rem;
      width: 100%;
      box-shadow: none;
      text-align: center; }
    .nav-sections-item-content {
      padding: 0; }
      .nav-sections-item-content .navigation li ul {
        display: none;
        width: 100%;
        flex-wrap: wrap; }
        .nav-sections-item-content .navigation li ul[aria-expanded="true"] {
          display: flex; }
        .nav-sections-item-content .navigation li ul li {
          margin-left: 0; }
          .nav-sections-item-content .navigation li ul li ul {
            padding-left: 1.5rem; }
            .nav-sections-item-content .navigation li ul li ul li:before {
              content: '';
              width: 1px;
              height: calc(2.5em + 1px);
              display: block;
              background-color: #bfbbbb;
              margin-top: -1.5em; }
            .nav-sections-item-content .navigation li ul li ul li:first-of-type:before {
              content: '';
              height: 2em;
              margin-top: 1px; }
            .nav-sections-item-content .navigation li ul li ul li a {
              padding: 1rem 1rem 0 0;
              text-align: left;
              justify-content: flex-start;
              line-height: 1.5; }
              .nav-sections-item-content .navigation li ul li ul li a:before {
                content: '-';
                display: inline-block;
                transform: scaleX(3);
                color: #bfbbbb;
                font-weight: 300;
                margin-right: calc(1.5rem - 3px);
                margin-left: 3px; }
      .nav-sections-item-content .navigation ul li > ul li[aria-haspopup="true"] ul {
        flex: 0 0 100%; }
  .page-wrapper {
    transform: translateX(0);
    transition: transform .5s ease-in-out; }
  .nav-open {
    overflow-y: hidden; }
    .nav-open .nav-sections {
      visibility: visible;
      overflow-y: scroll;
      display: block; }
    .nav-open .page-wrapper {
      transform: translateX(90vw); }
    .nav-open .navigation {
      margin-bottom: 100px; }
  .nav-before-open {
    width: 100vw;
    overflow-x: hidden; }
    .nav-before-open .page-wrapper {
      position: relative; }
      .nav-before-open .page-wrapper:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 10; }
    .nav-before-open .nav-toggle {
      position: fixed;
      top: 0;
      z-index: 20; }
      .nav-before-open .nav-toggle:before {
        content: '\e5cd';
        color: #ffffff; }
  .filter-content {
    margin-top: 2rem; }
  .filter-options {
    margin: 0 1rem 0; }
  .nav-sections-item-content .navigation a {
    color: #000; } }

@media only screen and (min-width: 1200px) {
  .nav-toggle {
    display: none; }
  .nav-sections {
    display: block;
    border-top: 1px solid #e9e9e9;
    border-bottom: 1px solid #e9e9e9; }
    .nav-sections-item-content > .navigation {
      margin: 0 auto;
      width: 100%;
      max-width: 1440px; }
    .nav-sections-item-content .navigation > ul {
      flex-direction: row;
      align-items: center;
      margin-left: 15rem; }
      .nav-sections-item-content .navigation > ul > li > ul[aria-expanded="true"] {
        margin-left: -15rem; }
      .nav-sections-item-content .navigation > ul > li {
        width: auto;
        text-align: center; }
        .nav-sections-item-content .navigation > ul > li > ul {
          position: absolute;
          z-index: 1;
          top: 3vh;
          left: 0;
          border-radius: 0 0 5px 5px;
          margin-top: 0.5rem; }
          .nav-sections-item-content .navigation > ul > li > ul[aria-expanded="true"] {
            z-index: 9999;
            animation-name: slideDown;
            animation-duration: .4s;
            animation-timing-function: ease;
            animation-delay: inherit;
            animation-iteration-count: 1;
            animation-direction: normal;
            animation-fill-mode: forwards;
            animation-play-state: running; }
          .nav-sections-item-content .navigation > ul > li > ul li {
            display: -ms-grid;
            -ms-grid-columns: 3rem 1fr;
            background-color: #f5f5f5; }
            .nav-sections-item-content .navigation > ul > li > ul li a {
              display: flex;
              width: 100%; }
            .nav-sections-item-content .navigation > ul > li > ul li:hover {
              background-color: #e9e9e9; }
          .nav-sections-item-content .navigation > ul > li > ul > li:first-child {
            box-shadow: inset 0 5px 5px -5px rgba(0, 0, 0, 0.2); }
          .nav-sections-item-content .navigation > ul > li > ul > li:last-child {
            border-radius: 0 0 5px 5px; }
          .nav-sections-item-content .navigation > ul > li > ul > li:hover > ul {
            visibility: visible;
            left: 100%;
            transition: left .4s ease; }
          .nav-sections-item-content .navigation > ul > li > ul > li > ul {
            position: absolute;
            z-index: -1;
            left: -100%; }
          .nav-sections-item-content .navigation > ul > li > ul > li ul {
            top: 0;
            left: 0;
            width: auto; }
            .nav-sections-item-content .navigation > ul > li > ul > li ul li {
              box-shadow: inset 5px 0 5px -5px rgba(0, 0, 0, 0.2);
              margin-left: 0px; }
              .nav-sections-item-content .navigation > ul > li > ul > li ul li:first-child {
                border-radius: 0 5px 0 0; }
              .nav-sections-item-content .navigation > ul > li > ul > li ul li:last-child {
                border-radius: 0 0 5px 0; }
        .nav-sections-item-content .navigation > ul > li ul {
          display: unset;
          padding: 0;
          min-width: 280px;
          width: inherit;
          max-width: 300px;
          justify-content: flex-start;
          flex-grow: 1;
          flex-shrink: 0; }
    .nav-sections-item-content .navigation li {
      position: relative;
      height: 3rem;
      flex-wrap: unset; }
    .nav-sections-item-content .navigation a {
      display: inline-block;
      width: 100%;
      text-align: left; }
      .nav-sections-item-content .navigation a[href*="/pharmacy/prescriptions#/prescriptions"] {
        display: none; }
      .nav-sections-item-content .navigation a[href$="/produkter"] + button, .nav-sections-item-content .navigation .cart.table-wrapper .actions-toolbar > a[href$="/produkter"] + .action, .cart.table-wrapper .nav-sections-item-content .navigation .actions-toolbar > a[href$="/produkter"] + .action, .nav-sections-item-content .navigation a[href$="/produkter"] + .action-gift {
        pointer-events: none; }
      .nav-sections-item-content .navigation a[href$="/produkter"] ~ ul {
        animation-name: slideDown;
        animation-duration: .4s;
        animation-timing-function: ease;
        animation-delay: inherit;
        animation-iteration-count: 1;
        animation-direction: normal;
        animation-fill-mode: forwards;
        animation-play-state: running; } }

@media only screen and (min-width: 1440px) {
  .nav-sections-item-content .navigation a {
    white-space: nowrap; }
  .nav-sections-item-content .navigation ul > li > ul > li {
    height: 4rem; }
    .nav-sections-item-content .navigation ul > li > ul > li ul > li {
      padding-left: 0.5rem; }
  .sidebar-main {
    margin-top: 0;
    padding: 1rem 0 0 0;
    transition: margin-top .2s ease; } }

.nav-sections-item-content .navigation li ul li a, .nav-sections-item-content .navigation li ul li a:hover {
  flex: 0 0 85%;
  color: #000; }

@media only screen and (min-width: 1200px) and (max-width: 1390px) {
  .nav-sections .nav-sections-item-content .navigation > ul > li ul[aria-expanded="true"] {
    min-width: 20vw; }
    .nav-sections .nav-sections-item-content .navigation > ul > li ul[aria-expanded="true"] li {
      background-color: #f3f5f9; }
      .nav-sections .nav-sections-item-content .navigation > ul > li ul[aria-expanded="true"] li a {
        font-size: 12px; } }

@keyframes slideDown {
  0% {
    top: -200vh;
    z-index: -1; }
  10% {
    top: -90vh;
    z-index: -1; }
  20% {
    top: -80vh;
    z-index: -1; }
  30% {
    top: -70vh;
    z-index: -1; }
  40% {
    top: -60vh;
    z-index: -1; }
  50% {
    top: -50vh;
    z-index: -1; }
  60% {
    top: -40vh;
    z-index: -1; }
  70% {
    top: -30vh;
    z-index: -1; }
  80% {
    top: -20vh;
    z-index: -1; }
  90% {
    top: -10vh;
    z-index: -1; }
  91% {
    top: -9vh;
    z-index: -1; }
  92% {
    top: -8vh;
    z-index: -1; }
  93% {
    top: -7vh;
    z-index: -1; }
  94% {
    top: -6vh;
    z-index: -1; }
  95% {
    top: -5vh;
    z-index: -1; }
  96% {
    top: -4vh;
    z-index: -1; }
  97% {
    top: -3vh;
    z-index: -1; }
  98% {
    top: -2vh;
    z-index: -1; }
  99% {
    top: -1vh;
    z-index: -1; }
  100% {
    top: 100%;
    z-index: 9999; } }

.pages > .label {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.pages .items {
  font-size: 0;
  letter-spacing: -1px;
  line-height: 0;
  white-space: nowrap;
  margin: 0;
  padding: 0;
  list-style: none none;
  display: inline-block;
  font-weight: 700;
  border: 1px solid #E0E6EE; }

.pages .item {
  font-size: 1.2rem;
  font-size: 12px;
  letter-spacing: normal;
  line-height: 32px;
  margin: 0 2px 0 0;
  margin-right: 0;
  display: inline-block;
  border-right: 1px solid #E0E6EE;
  width: 42px;
  height: 39px; }
  .pages .item .label {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
  .pages .item span {
    font-size: 14px; }
  .pages .item.pages-item-next {
    border: 0; }

.pages a.page {
  color: #118b2d;
  display: inline-block;
  padding: 0 4px;
  text-decoration: none;
  text-decoration: none;
  margin-top: -2px; }
  .pages a.page:visited {
    color: #118b2d; }
  .pages a.page:hover {
    color: #0e7426;
    text-decoration: none; }

.pages strong.page {
  font-size: 1.2rem;
  font-size: 12px;
  letter-spacing: normal;
  line-height: 32px;
  color: #118b2d;
  display: inline-block;
  font-weight: 700;
  padding: 0 4px;
  margin-top: -2px; }

.pages .action {
  color: #47e96c;
  display: inline-block;
  padding: 0;
  text-decoration: none; }
  .pages .action:visited {
    color: #47e96c; }
  .pages .action:hover {
    color: #47e96c;
    text-decoration: none; }
  .pages .action:active {
    color: #47e96c; }
  .pages .action.next {
    display: inline-block;
    text-decoration: none;
    height: 39px;
    padding: 0;
    margin-left: 0 !important; }
    .pages .action.next:visited:before {
      color: #47e96c; }
    .pages .action.next:active:before {
      color: #47e96c; }
    .pages .action.next > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .pages .action.next:before {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 46px;
      line-height: inherit;
      color: #47e96c;
      content: "";
      font-family: "Material Icons";
      margin: 0 0 0 -6px;
      vertical-align: top;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; }
    .pages .action.next:hover:before {
      color: #47e96c; }
    .pages .action.next:active:before {
      color: #47e96c; }
    .pages .action.next:before {
      margin: 3px 0 0 -6px;
      font-size: 46px; }
  .pages .action.previous {
    display: inline-block;
    text-decoration: none;
    height: 39px;
    padding: 0;
    margin-right: 0 !important; }
    .pages .action.previous:visited:before {
      color: #47e96c; }
    .pages .action.previous:active:before {
      color: #47e96c; }
    .pages .action.previous > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .pages .action.previous:before {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 46px;
      line-height: inherit;
      color: #47e96c;
      content: "";
      font-family: "Material Icons";
      margin: 0 0 0 -6px;
      vertical-align: top;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; }
    .pages .action.previous:hover:before {
      color: #47e96c; }
    .pages .action.previous:active:before {
      color: #47e96c; }
    .pages .action.previous:before {
      margin: 3px 0 0 -6px; }

.pages .action {
  width: 34px; }
  .pages .action.previous {
    margin-right: 12px; }
  .pages .action.next {
    margin-left: 12px; }

.ui-dialog.popup .action.close {
  position: absolute;
  height: 40px;
  right: 0;
  top: 0;
  width: 40px;
  z-index: 1001;
  display: inline-block;
  text-decoration: none;
  background-image: none;
  background: none;
  -moz-box-sizing: content-box;
  border: 0;
  box-shadow: none;
  line-height: inherit;
  margin: 0;
  padding: 0;
  text-decoration: none;
  text-shadow: none;
  font-weight: 400; }
  .ui-dialog.popup .action.close > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
  .ui-dialog.popup .action.close:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 22px;
    line-height: 22px;
    color: inherit;
    content: "";
    font-family: "Material Icons";
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center; }
  .ui-dialog.popup .action.close:hover:before {
    color: inherit; }
  .ui-dialog.popup .action.close:active:before {
    color: inherit; }
  .ui-dialog.popup .action.close:focus, .ui-dialog.popup .action.close:active {
    background: none;
    border: none; }
  .ui-dialog.popup .action.close:hover {
    background: none;
    border: none; }
  .ui-dialog.popup .action.close.disabled, .ui-dialog.popup .action.close[disabled],
  fieldset[disabled] .ui-dialog.popup .action.close {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5; }

.popup-pointer {
  margin-left: -14px;
  position: absolute;
  top: -14px; }
  .popup-pointer:before, .popup-pointer:after {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-bottom-style: solid; }
  .popup-pointer:before {
    left: 2px;
    top: 2px;
    border: solid 6px;
    border-color: transparent transparent #fff transparent;
    z-index: 99; }
  .popup-pointer:after {
    left: 1px;
    top: 0;
    border: solid 7px;
    border-color: transparent transparent #aeaeae transparent;
    z-index: 98; }

@media only screen and (min-width: 768px) {
  .ui-dialog.popup {
    width: 420px; } }

.price-tier_price .price-excluding-tax,
.price-tier_price .price-including-tax {
  display: inline; }

.price-including-tax,
.price-excluding-tax {
  display: block;
  font-size: 1.8rem;
  line-height: 1; }
  .price-including-tax .price,
  .price-excluding-tax .price {
    font-weight: 700; }

.price-including-tax + .price-excluding-tax,
.weee[data-label] {
  display: block;
  font-size: 1.8rem; }
  .price-including-tax + .price-excluding-tax:before,
  .weee[data-label]:before {
    content: attr(data-label) ": ";
    font-size: 1.1rem; }
  .price-including-tax + .price-excluding-tax .price,
  .weee[data-label] .price {
    font-size: 1.1rem; }

.product.data.items {
  margin: 0;
  padding: 0;
  margin-bottom: 20px; }
  .product.data.items > .item.title {
    box-sizing: border-box;
    float: none;
    width: 100%; }
    .product.data.items > .item.title > .switch {
      display: block; }
  .product.data.items > .item.content {
    box-sizing: border-box;
    display: block;
    float: none;
    margin: 0; }
    .product.data.items > .item.content:before, .product.data.items > .item.content:after {
      content: '';
      display: table; }
    .product.data.items > .item.content:after {
      clear: both; }
    .product.data.items > .item.content.active {
      display: block; }
  .product.data.items > .item.title {
    margin: 0 0 5px; }
  .product.data.items > .item.title > .switch {
    background: #f0f0f0;
    border-bottom: 1px solid #E0E6EE;
    border-left: 1px solid #E0E6EE;
    border-right: 1px solid #E0E6EE;
    border-top: 1px solid #E0E6EE;
    height: 40px;
    padding: 5px 20px 5px 20px;
    color: #47e96c;
    font-weight: 600;
    line-height: 40px;
    font-size: 1.8rem;
    color: #47e96c;
    text-decoration: none; }
    .product.data.items > .item.title > .switch:visited {
      color: #47e96c;
      text-decoration: none; }
    .product.data.items > .item.title > .switch:hover {
      color: #47e96c;
      text-decoration: none; }
    .product.data.items > .item.title > .switch:active {
      color: #434343;
      text-decoration: none; }
  .product.data.items > .item.title:not(.disabled) > .switch:focus,
  .product.data.items > .item.title:not(.disabled) > .switch:hover {
    background: #fcfcfc; }
  .product.data.items > .item.title:not(.disabled) > .switch:active,
  .product.data.items > .item.title.active > .switch,
  .product.data.items > .item.title.active > .switch:focus,
  .product.data.items > .item.title.active > .switch:hover {
    background: white;
    padding-bottom: 5px; }
  .product.data.items > .item.content {
    background: white;
    border: 1px solid #E0E6EE;
    margin: 0 0 5px;
    padding: 20px 20px 20px 20px; }

.table-wrapper {
  margin-bottom: 20px; }

table > caption {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.table:not(.cart):not(.totals) {
  border: none; }
  .table:not(.cart):not(.totals) > thead > tr > th,
  .table:not(.cart):not(.totals) > thead > tr > td,
  .table:not(.cart):not(.totals) > tbody > tr > th,
  .table:not(.cart):not(.totals) > tbody > tr > td,
  .table:not(.cart):not(.totals) > tfoot > tr > th,
  .table:not(.cart):not(.totals) > tfoot > tr > td {
    border: none; }
  .table:not(.cart):not(.totals) > thead > tr > th,
  .table:not(.cart):not(.totals) > thead > tr > td {
    border-bottom: 1px solid #E0E6EE; }
  .table:not(.cart):not(.totals) tfoot > tr:first-child th,
  .table:not(.cart):not(.totals) tfoot > tr:first-child td {
    border-top: 1px solid #E0E6EE;
    padding-top: 20px; }
  .table:not(.cart):not(.totals) tfoot .mark {
    font-weight: 400;
    text-align: right; }

@media only screen and (max-width: 767px) {
  .table-wrapper {
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;
    position: relative; }
    .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) {
      border: none;
      display: block; }
      .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) > thead > tr > th {
        display: none; }
      .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) > tbody {
        display: block; }
        .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) > tbody > tr {
          display: block; }
          .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) > tbody > tr td,
          .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) > tbody > tr th {
            border-bottom: none;
            display: block;
            padding: 5px 0; }
            .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) > tbody > tr td:before,
            .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) > tbody > tr th:before {
              padding-right: 10px;
              content: attr(data-th) ": ";
              display: inline-block;
              color: #094e19;
              font-weight: 700; }
      .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) tbody > tr > td:first-child {
        padding-top: 20px; }
      .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) tbody > tr > td:last-child {
        padding-bottom: 20px; }
      .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison).additional-attributes tbody td:last-child {
        border: none;
        padding: 0 0 5px; }
    .table-wrapper .table:not(.totals):not(.table-comparison) tfoot {
      display: block; }
      .table-wrapper .table:not(.totals):not(.table-comparison) tfoot tr {
        display: block; }
        .table-wrapper .table:not(.totals):not(.table-comparison) tfoot tr:first-child th,
        .table-wrapper .table:not(.totals):not(.table-comparison) tfoot tr:first-child td {
          padding-top: 20px; }
      .table-wrapper .table:not(.totals):not(.table-comparison) tfoot th {
        box-sizing: border-box;
        float: left;
        padding-left: 0;
        padding-right: 0;
        text-align: left;
        width: 70%; }
      .table-wrapper .table:not(.totals):not(.table-comparison) tfoot td {
        box-sizing: border-box;
        float: left;
        padding-left: 0;
        padding-right: 0;
        text-align: right;
        width: 30%; }
  .data-table-definition-list thead {
    display: none; }
  .data-table-definition-list tbody th {
    padding-bottom: 0; }
  .data-table-definition-list tbody th,
  .data-table-definition-list tbody td {
    display: block;
    padding-left: 0;
    padding-right: 0; } }

.tooltip.wrapper {
  position: relative; }
  .tooltip.wrapper .tooltip.content {
    background: #fff;
    max-width: 360px;
    min-width: 210px;
    padding: 12px 16px;
    z-index: 100;
    display: none;
    position: absolute;
    text-align: left;
    color: #118b2d;
    line-height: 1.4;
    border: 1px solid #bbb;
    margin-top: 5px;
    left: 0;
    top: 100%; }
    .tooltip.wrapper .tooltip.content:after, .tooltip.wrapper .tooltip.content:before {
      border: solid transparent;
      content: '';
      height: 0;
      position: absolute;
      width: 0; }
    .tooltip.wrapper .tooltip.content:after {
      border-width: 5px;
      border-color: transparent; }
    .tooltip.wrapper .tooltip.content:before {
      border-width: 6px;
      border-color: transparent; }
    .tooltip.wrapper .tooltip.content:after, .tooltip.wrapper .tooltip.content:before {
      bottom: 100%; }
    .tooltip.wrapper .tooltip.content:after {
      border-bottom-color: #fff;
      left: 15px;
      margin-left: -5px; }
    .tooltip.wrapper .tooltip.content:before {
      border-bottom-color: #bbb;
      left: 15px;
      margin-left: -6px; }
  .tooltip.wrapper .tooltip.toggle {
    cursor: help; }
  .tooltip.wrapper .tooltip.toggle:hover + .tooltip.content,
  .tooltip.wrapper .tooltip.toggle:focus + .tooltip.content,
  .tooltip.wrapper:hover .tooltip.content {
    display: block; }
  .tooltip.wrapper .tooltip.content dl {
    margin-bottom: 0; }
  .tooltip.wrapper .tooltip.content dd {
    white-space: normal; }
  .tooltip.wrapper .tooltip.content .subtitle {
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 15px; }
  .tooltip.wrapper .tooltip.content .label {
    margin-top: 10px; }
    .tooltip.wrapper .tooltip.content .label:first-child {
      margin-top: 0; }
  .tooltip.wrapper .tooltip.content .values {
    margin: 0; }

.ui-tooltip {
  position: absolute;
  z-index: 9999; }

@media only screen and (max-width: 479px) {
  .tooltip.wrapper .tooltip.content {
    min-width: 10rem; } }

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/opensans/light/opensans-300.eot");
  src: url("../fonts/opensans/light/opensans-300.eot?#iefix") format("embedded-opentype"), url("../fonts/opensans/light/opensans-300.woff2") format("woff2"), url("../fonts/opensans/light/opensans-300.woff") format("woff"), url("../fonts/opensans/light/opensans-300.ttf") format("truetype"), url("../fonts/opensans/light/opensans-300.svg#Open Sans") format("svg");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/opensans/regular/opensans-400.eot");
  src: url("../fonts/opensans/regular/opensans-400.eot?#iefix") format("embedded-opentype"), url("../fonts/opensans/regular/opensans-400.woff2") format("woff2"), url("../fonts/opensans/regular/opensans-400.woff") format("woff"), url("../fonts/opensans/regular/opensans-400.ttf") format("truetype"), url("../fonts/opensans/regular/opensans-400.svg#Open Sans") format("svg");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/opensans/semibold/opensans-600.eot");
  src: url("../fonts/opensans/semibold/opensans-600.eot?#iefix") format("embedded-opentype"), url("../fonts/opensans/semibold/opensans-600.woff2") format("woff2"), url("../fonts/opensans/semibold/opensans-600.woff") format("woff"), url("../fonts/opensans/semibold/opensans-600.ttf") format("truetype"), url("../fonts/opensans/semibold/opensans-600.svg#Open Sans") format("svg");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/opensans/bold/opensans-700.eot");
  src: url("../fonts/opensans/bold/opensans-700.eot?#iefix") format("embedded-opentype"), url("../fonts/opensans/bold/opensans-700.woff2") format("woff2"), url("../fonts/opensans/bold/opensans-700.woff") format("woff"), url("../fonts/opensans/bold/opensans-700.ttf") format("truetype"), url("../fonts/opensans/bold/opensans-700.svg#Open Sans") format("svg");
  font-weight: 700;
  font-style: normal; }

@media only screen and (min-width: 1024px) {
  h1 {
    font-size: 40px;
    margin-bottom: 40px; } }

.items {
  margin: 0;
  padding: 0;
  list-style: none none; }

body._has-modal {
  height: 100%;
  overflow: hidden;
  width: 100%; }

.modals-overlay {
  z-index: 1000; }

.modal-slide,
.modal-popup {
  bottom: 0;
  left: 0;
  min-width: 0;
  position: fixed;
  right: 0;
  top: 0;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.3s, opacity 0.3s ease; }
  .modal-slide._show,
  .modal-popup._show {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.3s ease; }
    .modal-slide._show .modal-inner-wrap,
    .modal-popup._show .modal-inner-wrap {
      transform: translate(0, 0); }
  .modal-slide .modal-inner-wrap,
  .modal-popup .modal-inner-wrap {
    background-color: #fff;
    box-shadow: none;
    opacity: 1;
    pointer-events: auto; }

.modal-slide {
  left: 14.8rem;
  z-index: 900; }
  .modal-slide._show .modal-inner-wrap {
    transform: translateX(0); }
  .modal-slide .modal-inner-wrap {
    height: 100%;
    overflow-y: auto;
    position: static;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
    width: auto; }
  .modal-slide._inner-scroll .modal-inner-wrap {
    overflow-y: visible;
    display: flex;
    flex-direction: column; }
  .modal-slide._inner-scroll .modal-header,
  .modal-slide._inner-scroll .modal-footer {
    flex-grow: 0;
    flex-shrink: 0; }
  .modal-slide._inner-scroll .modal-content {
    overflow-y: auto; }
  .modal-slide._inner-scroll .modal-footer {
    margin-top: auto; }
  .modal-slide .modal-header,
  .modal-slide .modal-content,
  .modal-slide .modal-footer {
    padding: 0 2.6rem 2.6rem; }
  .modal-slide .modal-header {
    padding-bottom: 2.1rem;
    padding-top: 2.1rem; }

.modal-popup {
  z-index: 900;
  left: 0;
  overflow-y: auto; }
  .modal-popup._show .modal-inner-wrap {
    transform: translateY(0); }
  .modal-popup .modal-inner-wrap {
    margin: 5rem auto;
    width: 75%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    transform: translateY(-200%);
    transition: transform 0.2s ease; }
  .modal-popup._inner-scroll {
    overflow-y: visible; }
    .ie11 .modal-popup._inner-scroll,
    .ie10 .modal-popup._inner-scroll,
    .ie9 .modal-popup._inner-scroll {
      overflow-y: auto; }
    .modal-popup._inner-scroll .modal-inner-wrap {
      max-height: 90%; }
      .ie11 .modal-popup._inner-scroll .modal-inner-wrap,
      .ie10 .modal-popup._inner-scroll .modal-inner-wrap,
      .ie9 .modal-popup._inner-scroll .modal-inner-wrap {
        max-height: none; }
    .modal-popup._inner-scroll .modal-content {
      overflow-y: auto; }
  .modal-popup .modal-header,
  .modal-popup .modal-content,
  .modal-popup .modal-footer {
    padding-left: 3rem;
    padding-right: 3rem; }
  .modal-popup .modal-header,
  .modal-popup .modal-footer {
    flex-grow: 0;
    flex-shrink: 0; }
  .modal-popup .modal-header {
    padding-bottom: 1.2rem;
    padding-top: 3rem; }
  .modal-popup .modal-footer {
    margin-top: auto;
    padding-bottom: 3rem;
    padding-top: 3rem; }
  .modal-popup .modal-footer-actions {
    text-align: right; }

@media only screen and (max-width: 1023px) {
  .modal-popup.modal-slide {
    left: 14.8rem;
    z-index: 900; }
    .modal-popup.modal-slide._show .modal-inner-wrap {
      transform: translateX(0); }
    .modal-popup.modal-slide .modal-inner-wrap {
      height: 100%;
      overflow-y: auto;
      position: static;
      transform: translateX(100%);
      transition: transform 0.3s ease-in-out;
      width: auto; }
    .modal-popup.modal-slide .modal-inner-wrap {
      margin: 0;
      max-height: none; } }

.modal-custom .action-close,
.modal-popup .action-close,
.modal-slide .action-close {
  background-image: none;
  background: none;
  -moz-box-sizing: content-box;
  border: 0;
  box-shadow: none;
  line-height: inherit;
  margin: 0;
  padding: 0;
  text-decoration: none;
  text-shadow: none;
  font-weight: 400;
  display: inline-block;
  text-decoration: none;
  position: absolute;
  right: 0;
  top: 0; }
  .modal-custom .action-close:focus, .modal-custom .action-close:active,
  .modal-popup .action-close:focus,
  .modal-popup .action-close:active,
  .modal-slide .action-close:focus,
  .modal-slide .action-close:active {
    background: none;
    border: none; }
  .modal-custom .action-close:hover,
  .modal-popup .action-close:hover,
  .modal-slide .action-close:hover {
    background: none;
    border: none; }
  .modal-custom .action-close.disabled, .modal-custom .action-close[disabled],
  fieldset[disabled] .modal-custom .action-close,
  .modal-popup .action-close.disabled,
  .modal-popup .action-close[disabled],
  fieldset[disabled]
  .modal-popup .action-close,
  .modal-slide .action-close.disabled,
  .modal-slide .action-close[disabled],
  fieldset[disabled]
  .modal-slide .action-close {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5; }
  .modal-custom .action-close > span,
  .modal-popup .action-close > span,
  .modal-slide .action-close > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
  .modal-custom .action-close:before,
  .modal-popup .action-close:before,
  .modal-slide .action-close:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 32px;
    line-height: 32px;
    color: #3c3c3c;
    content: "";
    font-family: "Material Icons";
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center; }
  .modal-custom .action-close:hover:before,
  .modal-popup .action-close:hover:before,
  .modal-slide .action-close:hover:before {
    color: inherit; }
  .modal-custom .action-close:active:before,
  .modal-popup .action-close:active:before,
  .modal-slide .action-close:active:before {
    color: inherit; }
  .modal-custom .action-close:hover:before,
  .modal-popup .action-close:hover:before,
  .modal-slide .action-close:hover:before {
    color: #0b5e1e; }

.modal-custom .action-close {
  margin: 25px; }

.modal-popup {
  pointer-events: none; }
  .modal-popup .modal-title {
    border-bottom: 1px solid #c1c1c1;
    font-weight: 300;
    padding-bottom: 10px;
    font-size: 26px;
    margin-bottom: 0;
    min-height: 1em;
    word-wrap: break-word; }
  .modal-popup .action-close {
    padding: 10px; }

.modal-slide .action-close {
  padding: 1.1rem 2rem; }

.modal-slide .page-main-actions {
  margin-bottom: calc(2.1rem - 15px);
  margin-top: 2.1rem; }

.modals-overlay {
  background-color: rgba(51, 51, 51, 0.55);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0; }

body._has-modal-custom .modal-custom-overlay {
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1000; }

@media only screen and (max-width: 1023px) {
  .custom-slide {
    bottom: 0;
    left: 0;
    min-width: 0;
    position: fixed;
    right: 0;
    top: 0;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 0.3s, opacity 0.3s ease;
    left: 14.8rem;
    z-index: 900; }
    .custom-slide._show {
      visibility: visible;
      opacity: 1;
      transition: opacity 0.3s ease; }
      .custom-slide._show .modal-inner-wrap {
        transform: translate(0, 0); }
    .custom-slide .modal-inner-wrap {
      background-color: #fff;
      box-shadow: none;
      opacity: 1;
      pointer-events: auto; }
    .custom-slide._show .modal-inner-wrap {
      transform: translateX(0); }
    .custom-slide .modal-inner-wrap {
      height: 100%;
      overflow-y: auto;
      position: static;
      transform: translateX(100%);
      transition: transform 0.3s ease-in-out;
      width: auto; }
    .custom-slide._show {
      -webkit-overflow-scrolling: touch;
      overflow-x: hidden;
      overflow-y: auto; }
    .custom-slide .modal-inner-wrap {
      background-color: #f4f4f4;
      box-sizing: border-box;
      height: auto;
      min-height: 100%; }
  body._has-modal-custom {
    height: 100vh;
    overflow: hidden;
    width: 100vw; }
    body._has-modal-custom .modal-custom-overlay {
      background-color: rgba(51, 51, 51, 0.55); } }

@media only screen and (max-width: 1023px) {
  .modal-popup.modal-slide .modal-inner-wrap[class] {
    background-color: #f4f4f4; }
  .modal-popup.modal-slide._inner-scroll._show {
    -webkit-overflow-scrolling: touch;
    overflow-y: auto; }
  .modal-popup.modal-slide._inner-scroll .modal-inner-wrap {
    height: auto;
    min-height: 100%; }
  .modal-popup .modal-title {
    font-size: 14px;
    font-weight: 700; } }

@media only screen and (min-width: 1024px) {
  .modal-popup.modal-slide .modal-footer {
    border-top: 1px solid #c1c1c1;
    text-align: right; } }

.column .block-addbysku .fieldset {
  margin: 0; }
  .column .block-addbysku .fieldset .fields {
    position: relative; }
    .column .block-addbysku .fieldset .fields .field {
      display: inline-block;
      margin-bottom: 10px;
      vertical-align: top; }
    .column .block-addbysku .fieldset .fields .actions-toolbar {
      position: absolute;
      right: 0;
      top: 4px; }

.column .block-addbysku .sku {
  margin-right: -155px;
  padding-right: 160px;
  width: 100%; }

.column .block-addbysku .skus > .label {
  margin-top: 10px; }

.column .block-addbysku .qty .qty + .mage-error {
  width: 80px; }

.column .block-addbysku .block-content .actions-toolbar {
  clear: both;
  margin: 0 0 20px;
  text-align: left; }
  .column .block-addbysku .block-content .actions-toolbar:before, .column .block-addbysku .block-content .actions-toolbar:after {
    content: '';
    display: table; }
  .column .block-addbysku .block-content .actions-toolbar:after {
    clear: both; }
  .column .block-addbysku .block-content .actions-toolbar .primary {
    float: left; }
  .column .block-addbysku .block-content .actions-toolbar .primary,
  .column .block-addbysku .block-content .actions-toolbar .secondary {
    display: inline-block; }
    .column .block-addbysku .block-content .actions-toolbar .primary a.action,
    .column .block-addbysku .block-content .actions-toolbar .secondary a.action {
      display: inline-block; }
  .column .block-addbysku .block-content .actions-toolbar .primary .action {
    margin: 0 5px 0 0; }
  .column .block-addbysku .block-content .actions-toolbar .secondary a.action {
    margin-top: 6px; }
  .column .block-addbysku .block-content .actions-toolbar .primary,
  .column .block-addbysku .block-content .actions-toolbar .secondary {
    display: block;
    float: none; }
  .column .block-addbysku .block-content .actions-toolbar > .secondary {
    text-align: left; }

.column .block-addbysku .reset {
  margin: 10px 0; }

.column .block-addbysku .note {
  display: block;
  margin: 0 0 20px; }

.block-cart-failed {
  margin-top: 40px; }
  .block-cart-failed .block-title {
    font-size: 2.6rem; }
    .block-cart-failed .block-title strong {
      font-weight: 300; }
  .block-cart-failed .actions {
    text-align: center; }
    .block-cart-failed .actions .action {
      margin-bottom: 10px; }

@media only screen and (max-width: 767px) {
  .column .block-addbysku .sku {
    margin-right: -85px;
    padding-right: 90px; }
  .column .block-addbysku .action.remove {
    display: inline-block;
    text-decoration: none;
    background-image: none;
    background: none;
    -moz-box-sizing: content-box;
    border: 0;
    box-shadow: none;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-decoration: none;
    text-shadow: none;
    font-weight: 400; }
    .column .block-addbysku .action.remove > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .column .block-addbysku .action.remove:before {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 28px;
      line-height: 22px;
      color: inherit;
      content: "";
      font-family: "Material Icons";
      margin: 1px 0 0;
      vertical-align: top;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; }
    .column .block-addbysku .action.remove:hover:before {
      color: inherit; }
    .column .block-addbysku .action.remove:active:before {
      color: inherit; }
    .column .block-addbysku .action.remove:focus, .column .block-addbysku .action.remove:active {
      background: none;
      border: none; }
    .column .block-addbysku .action.remove:hover {
      background: none;
      border: none; }
    .column .block-addbysku .action.remove.disabled, .column .block-addbysku .action.remove[disabled],
    fieldset[disabled] .column .block-addbysku .action.remove {
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.5; } }

@media only screen and (min-width: 768px) {
  .column .block-addbysku .action.remove {
    background-image: none;
    background: none;
    -moz-box-sizing: content-box;
    border: 0;
    box-shadow: none;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-decoration: none;
    text-shadow: none;
    font-weight: 400;
    line-height: 1.42857;
    margin: 0;
    padding: 0;
    color: #118b2d;
    text-decoration: none;
    background: none;
    border: 0;
    display: inline;
    margin-top: 2px; }
    .column .block-addbysku .action.remove:focus, .column .block-addbysku .action.remove:active {
      background: none;
      border: none; }
    .column .block-addbysku .action.remove:hover {
      background: none;
      border: none; }
    .column .block-addbysku .action.remove.disabled, .column .block-addbysku .action.remove[disabled],
    fieldset[disabled] .column .block-addbysku .action.remove {
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.5; }
    .column .block-addbysku .action.remove:visited {
      color: #118b2d;
      text-decoration: none; }
    .column .block-addbysku .action.remove:hover {
      color: #0e7426;
      text-decoration: underline; }
    .column .block-addbysku .action.remove:active {
      color: #2196f3;
      text-decoration: underline; }
    .column .block-addbysku .action.remove:hover {
      color: #0e7426; }
    .column .block-addbysku .action.remove:hover, .column .block-addbysku .action.remove:active, .column .block-addbysku .action.remove:focus {
      background: none;
      border: 0; }
    .column .block-addbysku .action.remove.disabled, .column .block-addbysku .action.remove[disabled],
    fieldset[disabled] .column .block-addbysku .action.remove {
      color: #118b2d;
      opacity: 0.5;
      cursor: default;
      pointer-events: none;
      text-decoration: underline; } }

@media only screen and (min-width: 1024px) {
  .block-cart-failed .actions {
    text-align: left; }
  .block-cart-failed .actions.primary {
    float: right; } }

.payment-method-braintree .hosted-control {
  background: #fff;
  background-clip: padding-box;
  border: 1px solid #c2c2c2;
  border-radius: 1px;
  font-family: "Roboto", Helvetica, sans-serif;
  font-size: 14px;
  height: 32px;
  line-height: 1.42857;
  padding: 0 9px;
  vertical-align: baseline;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  width: 225px; }
  .payment-method-braintree .hosted-control:disabled {
    opacity: 0.5; }
  .payment-method-braintree .hosted-control::-moz-placeholder {
    color: #c2c2c2; }
  .payment-method-braintree .hosted-control::-webkit-input-placeholder {
    color: #c2c2c2; }
  .payment-method-braintree .hosted-control:-ms-input-placeholder {
    color: #c2c2c2; }
  .payment-method-braintree .hosted-control.braintree-hosted-fields-focused {
    border-color: #006bb4; }
  .payment-method-braintree .hosted-control.braintree-hosted-fields-invalid:not(.braintree-hosted-fields-focused) {
    border-color: #e02b27; }
    .payment-method-braintree .hosted-control.braintree-hosted-fields-invalid:not(.braintree-hosted-fields-focused) ~ .hosted-error {
      height: auto;
      opacity: 1; }
  .payment-method-braintree .hosted-control.braintree-hosted-fields-valid {
    border-color: #006400; }
  .payment-method-braintree .hosted-control.hosted-cid {
    width: 5rem; }
  .payment-method-braintree .hosted-control.hosted-date {
    float: left;
    margin-bottom: 7px;
    width: 6rem; }
    .payment-method-braintree .hosted-control.hosted-date:first-of-type {
      margin-right: 50px; }
      .payment-method-braintree .hosted-control.hosted-date:first-of-type:after {
        content: '/';
        display: inline;
        padding-bottom: inherit;
        padding-top: inherit;
        position: absolute;
        right: -25px;
        top: 5px; }

.payment-method-braintree .field-tooltip {
  right: 0; }

.payment-method-braintree .hosted-error {
  clear: both;
  color: #e02b27;
  font-size: 1.2rem;
  height: 0;
  margin-top: 7px;
  opacity: 0; }

.braintree-paypal-logo {
  margin-top: 10px;
  text-align: center; }

.braintree-paypal-account {
  padding: 15px 0 15px 50px;
  position: relative;
  display: inline-block; }
  .braintree-paypal-account:before {
    background-image: url("../Magento_Braintree/images/paypal-small.png");
    background-position-x: 0;
    background-position-y: 0;
    line-height: 16px;
    vertical-align: middle;
    height: 16px;
    width: 16px;
    background-repeat: no-repeat;
    content: '';
    display: inline-block;
    margin: ""; }
  .braintree-paypal-account:before {
    left: 17px;
    margin-top: -8px;
    position: absolute;
    top: 50%; }
  .braintree-paypal-account .payment-method-type {
    font-weight: 700; }
  .braintree-paypal-account .payment-method-description {
    color: #575757; }

.braintree-paypal-fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

.action-braintree-paypal-logo {
  background-image: none;
  background: none;
  -moz-box-sizing: content-box;
  border: 0;
  box-shadow: none;
  line-height: inherit;
  margin: 0;
  padding: 0;
  text-decoration: none;
  text-shadow: none;
  font-weight: 400; }
  .action-braintree-paypal-logo:focus, .action-braintree-paypal-logo:active {
    background: none;
    border: none; }
  .action-braintree-paypal-logo:hover {
    background: none;
    border: none; }
  .action-braintree-paypal-logo.disabled, .action-braintree-paypal-logo[disabled],
  fieldset[disabled] .action-braintree-paypal-logo {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5; }
  .action-braintree-paypal-logo img {
    margin: 0;
    width: 114px; }

.payment-method .payment-method-content .actions-toolbar.braintree-paypal-actions {
  margin-left: 0; }

.account .table .col.paypal-account img {
  vertical-align: middle; }

.account .table .col.paypal-account + .actions {
  vertical-align: middle; }

@media only screen and (min-width: 1024px) {
  .payment-method-braintree .cvv .field-tooltip {
    left: 6rem; }
  .braintree-paypal-account {
    border-color: #ccc;
    border-style: solid;
    border-width: 1px 0;
    display: inline-block;
    width: 50%; }
  .account .table-credit-cards .col.actions {
    width: 100px; } }

.bundle-options-container {
  clear: both;
  margin-bottom: 40px; }
  .bundle-options-container .legend.title {
    font-weight: 300;
    line-height: 1.1;
    font-size: 2.6rem;
    margin-top: 2.5rem;
    margin-bottom: 2rem; }
  .bundle-options-container .product-add-form {
    display: none; }
  .bundle-options-container .product-options-wrapper {
    margin-bottom: 30px; }
  .bundle-options-container .action.back {
    margin-bottom: 30px; }
  .bundle-options-container .price-box .price {
    font-size: 18px;
    font-weight: 700; }
  .bundle-options-container .block-bundle-summary {
    background: #999;
    padding: 10px 20px; }
    .bundle-options-container .block-bundle-summary > .title > strong {
      font-weight: 300;
      line-height: 1.1;
      font-size: 2.6rem;
      margin-top: 2.5rem;
      margin-bottom: 2rem; }
    .bundle-options-container .block-bundle-summary .bundle-summary {
      margin-top: 30px; }
      .bundle-options-container .block-bundle-summary .bundle-summary.empty {
        display: none; }
      .bundle-options-container .block-bundle-summary .bundle-summary > .subtitle {
        font-weight: 300;
        line-height: 1.1;
        font-size: 1.8rem;
        margin-top: 1.5rem;
        margin-bottom: 1rem;
        display: block; }
    .bundle-options-container .block-bundle-summary .bundle.items > li {
      margin-bottom: 10px; }
    .bundle-options-container .block-bundle-summary .box-tocart .actions {
      display: inline-block;
      margin-bottom: 10px; }
    .bundle-options-container .block-bundle-summary .product-addto-links > .action {
      vertical-align: top; }
  .bundle-options-container .nested .field.qty {
    margin-top: 20px; }
    .bundle-options-container .nested .field.qty .label {
      font-weight: 700;
      margin: 0 0 5px;
      display: inline-block; }
  .bundle-options-container p.required {
    color: #e02b27; }
  .bundle-options-container .product-options-bottom {
    clear: left; }

@media only screen and (min-width: 1024px) {
  .bundle-options-container .bundle-options-wrapper,
  .bundle-options-container .product-options-wrapper {
    float: left;
    width: 57%; }
  .bundle-options-container .block-bundle-summary {
    float: right;
    position: relative;
    width: 40%; }
  .page-layout-2columns-left .bundle-options-container .bundle-options-wrapper,
  .page-layout-2columns-right .bundle-options-container .bundle-options-wrapper,
  .page-layout-3columns .bundle-options-container .bundle-options-wrapper, .page-layout-2columns-left
  .bundle-options-container .block-bundle-summary,
  .page-layout-2columns-right
  .bundle-options-container .block-bundle-summary,
  .page-layout-3columns
  .bundle-options-container .block-bundle-summary {
    width: 48%; } }

.products {
  margin: 30px 0; }

.product-item {
  vertical-align: top; }
  .products-grid .product-item {
    display: inline-block;
    width: 50%; }
  .product-item-name {
    display: block;
    margin: 5px 0;
    word-wrap: break-word;
    hyphens: auto; }
  .product-item-info {
    max-width: 100%;
    height: 420px; }
  .product-item-actions {
    display: none; }
    .product-item-actions .actions-secondary > .action:before {
      margin: 0; }
  .product-item-description {
    margin: 25px 0; }
  .product-item .product-reviews-summary .rating-summary {
    margin: 0 4px 0 0; }
  .product-item .product-reviews-summary .reviews-actions {
    font-size: 12px;
    margin-top: 5px;
    text-transform: lowercase; }
  .product-item .price-box {
    margin: 10px 0 0;
    height: 60px; }
    .product-item .price-box .price {
      font-size: 14px;
      font-weight: 700;
      white-space: nowrap; }
    .product-item .price-box .price-label {
      font-size: 12px; }
      .product-item .price-box .price-label:after {
        content: ":"; }
  .product-item .special-price .price,
  .product-item .minimal-price .price {
    font-size: 14px;
    font-weight: 700; }
  .product-item .special-price .price-wrapper,
  .product-item .minimal-price .price-wrapper {
    display: inline-block; }
  .product-item .special-price .price-including-tax + .price-excluding-tax,
  .product-item .minimal-price .price-including-tax + .price-excluding-tax {
    display: block; }
  .product-item .special-price {
    display: inline-block; }
  .product-item .old-price .price {
    font-weight: 400; }
  .product-item .minimal-price .price-container {
    display: block; }
  .product-item .minimal-price-link {
    margin-top: 5px; }
    .product-item .minimal-price-link .price-label {
      color: #118b2d;
      font-size: 14px; }
    .product-item .minimal-price-link .price {
      font-weight: 400; }
  .product-item .minimal-price-link,
  .product-item .price-excluding-tax,
  .product-item .price-including-tax {
    display: block;
    white-space: nowrap; }
  .product-item .price-from,
  .product-item .price-to {
    margin: 0; }
  .product-item .action.tocompare:before {
    content: ""; }
  .product-item .tocart {
    white-space: nowrap; }

.column.main .products-crosssell .product-items {
  margin-left: 0; }

.column.main .products-crosssell .product-item {
  width: calc(100% / 5);
  padding: 1rem; }
  .column.main .products-crosssell .product-item a,
  .column.main .products-crosssell .product-item .actions .action.primary.tocart span,
  .column.main .products-crosssell .product-item .price {
    font-size: 1.5rem; }
  .column.main .products-crosssell .product-item .price-box {
    margin: 10px 0 5px; }

.column.main .products-crosssell .product-item-info {
  width: 100%;
  height: 320px; }

.checkout-cart-index .products-crosssell .product-item-info {
  max-height: 320px; }
  .checkout-cart-index .products-crosssell .product-item-info .price-box {
    padding: 3rem 0 2rem;
    height: 30px; }
  .checkout-cart-index .products-crosssell .product-item-info .product-image-container {
    min-height: 130px;
    max-height: 150px; }
    .checkout-cart-index .products-crosssell .product-item-info .product-image-container .product-image-wrapper {
      height: inherit; }

.price-container .price {
  font-size: 14px; }

.price-container .price-including-tax + .price-excluding-tax,
.price-container .weee {
  margin-top: 5px; }

.price-container .price-including-tax + .price-excluding-tax,
.price-container .weee,
.price-container .price-including-tax + .price-excluding-tax .price,
.price-container .weee .price,
.price-container .weee + .price-excluding-tax:before,
.price-container .weee + .price-excluding-tax .price {
  font-size: 11px; }

.price-container .weee:before {
  content: "(" attr(data-label) ": "; }

.price-container .weee:after {
  content: ")"; }

.price-container .weee + .price-excluding-tax:before {
  content: attr(data-label) ": "; }

.products-list .product-item {
  display: table;
  width: 100%; }
  .products-list .product-item-info {
    display: table-row; }
  .products-list .product-item-photo {
    display: table-cell;
    padding: 0 30px 30px 0;
    vertical-align: top;
    width: 1%; }
  .products-list .product-item-details {
    display: table-cell;
    vertical-align: top; }

@media only screen and (max-width: 767px) {
  .products-list .product-item {
    table-layout: fixed;
    padding-left: 10px; }
    .products-list .product-item-photo {
      padding: 0 10px 10px 0;
      width: 30%; }
  .column.main .product-items {
    margin-left: -0.5rem; } }

@media only screen and (min-width: 768px) {
  .products-grid .product-item {
    margin-bottom: 30px; }
  .product-item-actions {
    display: block; }
    .products-grid .product-item-actions {
      margin: 10px 0; }
    .product-item-actions .actions-primary + .actions-secondary {
      display: table-cell;
      padding-left: 5px;
      white-space: nowrap;
      width: 50%; }
      .product-item-actions .actions-primary + .actions-secondary > * {
        white-space: normal; }
    .product-item-actions .actions-primary {
      display: table-cell; }
  .products-grid .product-item {
    width: 33.3333%; }
  .page-products .products-grid .product-item,
  .page-layout-1column .products-grid .product-item,
  .page-layout-3columns .products-grid .product-item,
  .page-products.page-layout-1column .products-grid .product-item,
  .page-products.page-layout-3columns .products-grid .product-item {
    width: 33.3333%; } }

@media only screen and (min-width: 1024px) {
  .page-products .products-grid .product-item {
    width: 33.3333%; }
  .page-products.page-layout-1column .products-grid .product-item {
    width: 25%; }
  .page-products.page-layout-3columns .products-grid .product-item {
    width: 50%; } }

@media only screen and (min-width: 1200px) {
  .products-grid .product-item {
    width: 20%; }
  .page-layout-1column .products-grid .product-item {
    width: 16.6666%; }
  .page-layout-3columns .products-grid .product-item {
    width: 25%; }
  .page-products .products-grid .product-items {
    margin: 0; }
  .page-products.page-layout-1column .products-grid .product-item {
    margin-left: 0;
    width: 20%; }
  .page-products.page-layout-3columns .products-grid .product-item {
    margin-left: 1%;
    width: 32.667%; }
    .page-products.page-layout-3columns .products-grid .product-item:nth-child(3n) {
      margin-left: 1%; }
    .page-products.page-layout-3columns .products-grid .product-item:nth-child(3n + 1) {
      margin-left: 0; } }

.page-products .columns {
  position: relative;
  z-index: 1; }

.toolbar-amount {
  display: block;
  line-height: 24px;
  margin: 0;
  padding: 8px 0 0; }
  .products.wrapper ~ .toolbar .toolbar-amount {
    display: none; }

.toolbar-products {
  margin-bottom: 40px;
  padding: 0 1rem 0 0.5rem;
  text-align: center; }
  .products.wrapper ~ .toolbar-products .pages {
    display: block; }
  .toolbar-products .limiter {
    display: none; }
    .toolbar-products .limiter .control {
      display: inline-block; }

.sorter {
  padding: 4px 0 0; }
  .products.wrapper ~ .toolbar .sorter {
    display: none; }

.sorter-options {
  margin: 0 0 0 7px;
  width: auto; }

.sorter-action {
  vertical-align: top;
  display: inline-block;
  text-decoration: none; }
  .sorter-action > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
  .sorter-action:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 28px;
    line-height: 32px;
    color: #3c3c3c;
    content: "";
    font-family: "Material Icons";
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center; }
  .sorter-action:hover:before {
    color: #414141; }

.sorter .sort-desc:before {
  content: ""; }

.modes {
  display: none; }

.limiter-options {
  margin: 0 5px 0 7px;
  width: auto; }

.limiter-label {
  font-weight: 400; }

.page-products .toolbar .limiter {
  display: none; }

@media only screen and (min-width: 1024px) {
  .page-products .columns {
    padding-top: 0;
    position: relative;
    z-index: 1; }
  .products.wrapper ~ .toolbar .pages {
    width: 100%; }
  .toolbar-amount {
    float: left; }
  .sorter {
    float: right; }
  .modes {
    display: block;
    float: left;
    margin-right: 20px; }
    .products.wrapper ~ .toolbar .modes {
      display: none; }
  .modes-mode {
    color: #47e96c;
    border: 1px solid #E0E6EE;
    border-right: 0;
    float: left;
    font-weight: 400;
    line-height: 1;
    padding: 7px 10px;
    text-align: center;
    display: block;
    text-decoration: none; }
    .modes-mode > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .modes-mode:before {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 22px;
      line-height: inherit;
      color: #47e96c;
      content: "";
      font-family: "Material Icons";
      vertical-align: middle;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; }
    .modes-mode:hover:before {
      color: #47e96c; }
    .modes-mode:not(.active):hover {
      color: #47e96c;
      background: #ededed; }
    .modes-mode:last-child {
      border-right: 1px solid #E0E6EE; }
    .modes-mode.active {
      color: #90f1a6; }
  .mode-list:before {
    content: ""; }
  .limiter {
    float: right;
    display: none; }
    .products.wrapper ~ .toolbar .limiter {
      display: none; } }

.fotorama-stretch, .fotorama-focus-overlay:after, .fotorama__nav__frame:focus:after, .fotorama__html, .fotorama__stage__frame, .fotorama__stage__shaft, .fotorama__video iframe {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%; }

.fotorama-grab-cursor, .fotorama__wrap .fotorama__grab {
  cursor: move;
  cursor: grab; }

.fotorama-grabbing-cursor, .fotorama__grabbing * {
  cursor: move;
  cursor: grabbing; }

.fotorama-gpu, .fotorama__caption, .fotorama--fullscreen, .fotorama__wrap--css3 .fotorama__html, .fotorama--fullscreen .fotorama__stage__frame .fotorama__img--full, .fotorama__wrap--css3 .fotorama__nav, .fotorama__nav:before, .fotorama__nav:after, .fotorama__wrap--css3 .fotorama__spinner, .fotorama__wrap--css3 .fotorama__stage, .fotorama__stage:before, .fotorama__stage:after, .fotorama__wrap--css3 .fotorama__stage__frame {
  transform: translateZ(0); }

.fotorama-focus, .fotorama__fullscreen-icon:focus, .fotorama__nav__frame, .fotorama__zoom-in:focus,
.fotorama__zoom-out:focus {
  outline: 0; }

.fotorama-focus-overlay:after, .fotorama__nav__frame:focus:after {
  background-color: #006bb4;
  border-radius: inherit;
  content: ''; }

.fotorama-transform-disabled, .fotorama__wrap--video .fotorama__stage__frame--video .fotorama__html, .fotorama__wrap--video .fotorama__stage__frame--video .fotorama__img, .fotorama__wrap--video .fotorama__stage, .fotorama__wrap--video .fotorama__stage__frame--video, .fotorama__wrap--video .fotorama__stage__shaft {
  transform: none !important; }

.fotorama-transition-for-slide, .fotorama__wrap--css3 .fotorama__nav__shaft, .fotorama__wrap--css3 .fotorama__stage__shaft, .fotorama__wrap--css3 .fotorama__thumb-border {
  transition-duration: unset;
  transition-property: transform, width;
  transition-timing-function: cubic-bezier(0.1, 0, 0.25, 1); }

.fotorama-no-select, .fotorama__arr, .fotorama__fullscreen-icon, .fotorama__no-select, .fotorama__video-close, .fotorama__wrap {
  user-select: none; }

.fotorama-select, .fotorama__select {
  user-select: text; }

.fotorama-empty-bg {
  background: url(data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=); }

.fotorama-auto-margin, .fotorama__nav, .fotorama__nav__frame {
  margin: auto;
  padding: 0; }

.fotorama-inline-block, .fotorama__caption__wrap, .fotorama__nav__frame, .fotorama__nav__shaft {
  display: inline;
  display: inline-block;
  vertical-align: middle;
  zoom: 1; }

.fotorama-hidden, .fotorama--hidden, .fotorama__load {
  position: absolute;
  top: -99999px;
  left: -99999px;
  z-index: -1000; }

.fotorama-visible {
  position: relative;
  left: auto;
  top: auto;
  opacity: 1;
  z-index: auto; }

.fotorama-no-tap, .fotorama__arr, .fotorama__fullscreen-icon, .fotorama__nav, .fotorama__nav__frame, .fotorama__nav__shaft, .fotorama__stage__frame, .fotorama__stage__shaft, .fotorama__video-close {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.transitionDuration {
  transition-duration: 333ms; }

.transitionDurationZero {
  transition-duration: unset; }

.fotorama-sprite, .fotorama__arr .fotorama__arr__arr, .fotorama__fullscreen-icon, .fotorama__thumb--icon, .fotorama__video-close, .fotorama__video-play, .fotorama__fullscreen .fotorama__zoom-in, .fotorama__fullscreen .fotorama__zoom-out {
  background-image: url("../images/gallery.png");
  background-repeat: no-repeat; }

@media print {
  .fotorama-print-background, .fotorama-sprite, .fotorama__arr .fotorama__arr__arr, .fotorama__fullscreen-icon, .fotorama__thumb--icon, .fotorama__video-close, .fotorama__video-play, .fotorama__fullscreen .fotorama__zoom-in, .fotorama__fullscreen .fotorama__zoom-out, .fotorama__thumb-border {
    background: none !important; } }

.fotorama__active {
  box-shadow: 0 0 0 transparent; }

.fotorama__stage__frame.fotorama__active {
  z-index: 8; }

.fotorama__wrap--fade .fotorama__stage__frame.fotorama__active {
  display: block;
  left: 0;
  top: 0; }

.fotorama__wrap--fade .fotorama__fade-rear.fotorama__active {
  z-index: 9; }

.fotorama__wrap--only-active .fotorama__stage__frame.fotorama__active {
  visibility: visible; }

.fotorama__arr {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 80px;
  box-sizing: border-box;
  z-index: 900;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.3);
  transition: background-color 0.3s ease-in-out; }
  .fotorama__arr:hover {
    background-color: rgba(255, 255, 255, 0.5); }
  .fotorama__arr:active {
    background-color: rgba(213, 213, 213, 0.5); }
  .fotorama__arr:focus {
    box-shadow: none; }
    .fotorama__arr:focus:after {
      border-radius: inherit;
      bottom: 3px;
      box-shadow: 0 0 3px 1px #68a8e0;
      content: '';
      left: 3px;
      position: absolute;
      right: 3px;
      top: 3px;
      z-index: 1000; }
  .fotorama__arr--prev {
    left: 0; }
    .fotorama__wrap--css3.fotorama__wrap--no-controls.fotorama__wrap--slide.fotorama__wrap--toggle-arrows .fotorama__arr--prev:not(:focus) {
      transform: translate3d(-120px, 0, 0); }
    .fotorama__wrap--css3.fotorama__wrap--video .fotorama__arr--prev {
      transform: translate3d(-120px, 0, 0); }
  .fotorama__arr--next {
    right: 0; }
    .fotorama__wrap--css3.fotorama__wrap--no-controls.fotorama__wrap--slide.fotorama__wrap--toggle-arrows .fotorama__arr--next:not(:focus) {
      transform: translate3d(120px, 0, 0); }
    .fotorama__wrap--css3.fotorama__wrap--video .fotorama__arr--next {
      transform: translate3d(120px, 0, 0); }
  .fotorama__arr--disabled {
    display: none;
    opacity: 0.1;
    pointer-events: none;
    cursor: default; }
  .fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__arr {
    opacity: 0; }
    .fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__arr:focus {
      opacity: 1; }
  .fotorama__wrap--video .fotorama__arr {
    opacity: 0 !important; }
  .fotorama__wrap--css2.fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__arr {
    display: none; }
    .fotorama__wrap--css2.fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__arr:focus {
      display: block; }
  .fotorama__wrap--css2.fotorama__wrap--video .fotorama__arr {
    display: none !important; }
  .fotorama__wrap--css3 .fotorama__arr:not(:focus) {
    transition-duration: 0.3s;
    transition-property: transform, opacity, background-color; }
  .fotorama__stage .fotorama__arr--shown {
    display: block !important; }
  .fotorama__stage .fotorama__arr--hidden {
    display: none !important; }

.fotorama__arr .fotorama__arr__arr {
  width: 80px;
  height: 80px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0); }
  .ie9 .fotorama__arr .fotorama__arr__arr {
    margin: -40px 0 0 -40px; }

.fotorama__arr--prev .fotorama__arr__arr {
  background-position: -80px -80px; }

.fotorama__arr--next .fotorama__arr__arr {
  background-position: -160px -80px; }

.fotorama__caption {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  color: #000;
  font-size: 14px;
  line-height: 1.5;
  z-index: 12; }
  .fotorama__caption a {
    color: #000;
    text-decoration: none;
    border-bottom: 1px solid;
    border-color: fade(#000, 0.5); }
    .fotorama__caption a:hover {
      color: #000;
      border-color: fade(#000, 0.5); }
  .fotorama__wrap--rtl .fotorama__caption {
    left: auto;
    right: 0; }
  .fotorama__wrap--video .fotorama__caption,
  .fotorama__wrap--no-captions .fotorama__caption {
    display: none; }

.fotorama__caption__wrap {
  padding: 5px 10px;
  box-sizing: border-box;
  background-color: #fff; }

.fotorama__dot {
  display: block;
  position: relative;
  top: 12px;
  left: 6px;
  width: 4px;
  height: 4px;
  border: 1px solid #858585;
  border-radius: 6px; }
  .fotorama__nav__frame--dot:focus .fotorama__dot {
    box-shadow: none; }
    .fotorama__nav__frame--dot:focus .fotorama__dot:after {
      border-radius: inherit;
      bottom: 3px;
      box-shadow: 0 0 3px 1px #68a8e0;
      content: '';
      left: 3px;
      position: absolute;
      right: 3px;
      top: 3px;
      z-index: 1000; }
  .fotorama__nav__frame--thumb:focus .fotorama__dot:after,
  .fotorama__nav__frame--dot:focus .fotorama__dot:after {
    bottom: 0;
    left: 0;
    right: 0;
    top: 0; }
  .fotorama__active .fotorama__dot {
    background-color: #2196f3;
    border-color: #2196f3; }

.fotorama__wrap--fade .fotorama__fade-front,
.fotorama__wrap--fade .fotorama__fade-rear {
  display: block;
  left: 0;
  top: 0; }

.fotorama__wrap--fade .fotorama__fade-front {
  z-index: 8; }

.fotorama__wrap--fade .fotorama__fade-rear {
  z-index: 7; }

.fotorama {
  min-width: 1px;
  overflow: hidden; }
  .fotorama--fullscreen {
    position: fixed !important;
    top: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    width: 100% !important;
    margin: 0 !important;
    background: #fff;
    z-index: 10000 !important; }

.fotorama__fullscreen {
  overflow: hidden;
  position: relative; }

.fotorama__fullscreen-icon {
  position: absolute;
  display: none;
  top: 0;
  right: 0;
  width: 80px;
  height: 80px;
  background-position: 0 0;
  cursor: pointer;
  z-index: 1000; }
  .fotorama__fullscreen-icon:focus {
    box-shadow: none; }
    .fotorama__fullscreen-icon:focus:after {
      border-radius: inherit;
      bottom: 3px;
      box-shadow: 0 0 3px 1px #68a8e0;
      content: '';
      left: 3px;
      position: absolute;
      right: 3px;
      top: 3px;
      z-index: 1000; }
  .fotorama--fullscreen-icons .fotorama__fullscreen-icon {
    display: none; }
  .fotorama--fullscreen .fotorama__fullscreen-icon {
    display: inline-block;
    background-position: -80px 0; }
  .fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__fullscreen-icon {
    opacity: 0; }
    .fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__fullscreen-icon:focus {
      opacity: 1; }
  .fotorama__wrap--video .fotorama__fullscreen-icon {
    opacity: 0 !important; }
  .fotorama__wrap--css2.fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__fullscreen-icon {
    display: none; }
    .fotorama__wrap--css2.fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__fullscreen-icon:focus {
      display: block; }
  .fotorama__wrap--css2.fotorama__wrap--video .fotorama__fullscreen-icon {
    display: none !important; }
  .fotorama__wrap--css3.fotorama__wrap--no-controls.fotorama__wrap--slide.fotorama__wrap--toggle-arrows .fotorama__fullscreen-icon:not(:focus) {
    transform: translate3d(80px, -80px, 0); }
  .fotorama__wrap--css3.fotorama__wrap--video .fotorama__fullscreen-icon {
    transform: translate3d(80px, -80px, 0) !important; }
  .fotorama__wrap--css3 .fotorama__fullscreen-icon:not(:focus) {
    transition-duration: 0.3s;
    transition-property: transform, opacity, background-color; }
  .fotorama__wrap:not(.fotorama__wrap--toggle-arrows) .fotorama__fullscreen-icon {
    background-color: rgba(255, 255, 255, 0.3);
    transition: background-color 0.3s ease-in-out; }
    .fotorama__wrap:not(.fotorama__wrap--toggle-arrows) .fotorama__fullscreen-icon:hover {
      background-color: rgba(255, 255, 255, 0.5); }
    .fotorama__wrap:not(.fotorama__wrap--toggle-arrows) .fotorama__fullscreen-icon:active {
      background-color: rgba(213, 213, 213, 0.5); }

.gallery-placeholder .loading-mask {
  position: static;
  padding: 0 0 50%; }

.gallery-placeholder .loader img {
  position: absolute; }

.fotorama__wrap--css3 .fotorama__html {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: linear; }

.fotorama__wrap--video .fotorama__stage__frame--video .fotorama__html {
  opacity: 0; }

.fotorama__img {
  max-width: inherit;
  opacity: 0;
  border: none !important; }
  .fotorama__loaded .fotorama__img,
  .fotorama__error .fotorama__img {
    opacity: 1; }
  .fotorama__img--full {
    display: none; }
  .fotorama__wrap--video .fotorama__stage__frame--video .fotorama__img {
    opacity: 0; }
  .fotorama__nav-wrap .fotorama_vertical_ratio .fotorama__img {
    position: absolute;
    top: 50%;
    width: 100%;
    height: auto;
    transform: translateY(-50%); }
  .fotorama__nav-wrap .fotorama_horizontal_ratio .fotorama__img {
    position: absolute;
    left: 50%;
    width: auto;
    height: 100%;
    transform: translateX(-50%); }
  .fotorama__stage__frame .fotorama__img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    transform: translate3d(-50%, -50%, 0);
    transition-duration: 0.3s;
    transition-property: width, height, top, left; }
  .fotorama--fullscreen .fotorama__stage__frame .fotorama__img {
    display: none; }
  .fotorama--fullscreen .fotorama__stage__frame .fotorama__img,
  .fotorama--fullscreen .fotorama__stage__frame .fotorama__img--full {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    max-width: inherit;
    max-height: inherit; }
  .fotorama--fullscreen .fotorama__loaded--full .fotorama__img {
    display: none; }
    .fotorama--fullscreen .fotorama__loaded--full .fotorama__img--full {
      display: block; }
  .fotorama--fullscreen .fotorama__stage__frame .fotorama__img--full {
    display: block;
    top: 0;
    left: 0;
    width: auto;
    height: auto;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
    cursor: default;
    transition: 0.3s linear; }
    .fotorama--fullscreen .fotorama__stage__frame .fotorama__img--full.fotorama__img--zoommable {
      max-width: none;
      max-height: none;
      cursor: pointer;
      transition-property: width, height, bottom, right, top, left; }
    .fotorama--fullscreen .fotorama__stage__frame .fotorama__img--full.fotorama__img--draggable {
      cursor: move;
      transition-property: none; }
  .fotorama__product-video--loaded .fotorama__img,
  .fotorama__product-video--loaded .fotorama__img--full {
    display: none !important; }

.magnifier-thumb-wrapper {
  position: relative;
  display: block;
  top: 0;
  left: 0; }

.magnifier-lens {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  border: solid 1px #bbb;
  z-index: 800; }

.magnifier-loader-text {
  margin-top: 10px; }

.magnifier-large {
  position: absolute;
  width: 32%;
  z-index: 100; }

.magnifier-preview {
  position: absolute;
  top: 215px;
  bottom: 0;
  left: 58%;
  overflow: hidden;
  padding: 0; }
  .magnifier-preview:not(.hidden) {
    background-color: #fff; }
  .magnifier-preview img {
    position: absolute;
    top: 0;
    left: 0;
    max-width: inherit; }

.magnify-lens {
  position: absolute;
  top: 0;
  left: 0;
  color: #fff;
  text-align: center;
  background: rgba(255, 255, 255, 0.5);
  border: solid 1px #bbb;
  z-index: 1000; }

.magnify-opaque {
  opacity: 0.5; }

.magnify-hidden {
  display: none; }

.magnify-fullimage {
  display: none; }
  .fotorama__fullscreen .magnify-fullimage {
    display: inline-block; }

.fotorama__nav {
  position: relative;
  display: none;
  max-width: 100%;
  overflow: hidden;
  font-size: 0;
  line-height: 0;
  white-space: nowrap;
  z-index: 5; }
  .fotorama__nav--dots {
    display: block;
    text-align: center; }
  .fotorama__nav--thumbs {
    display: flex;
    justify-content: center; }
  .fotorama__nav:before, .fotorama__nav:after {
    position: absolute;
    display: block;
    content: '';
    text-decoration: none;
    background-repeat: no-repeat;
    z-index: 10;
    pointer-events: none; }
  .fotorama__nav:before {
    left: -10px;
    top: -10px; }
  .fotorama__nav:after {
    right: -10px;
    bottom: -10px; }
  .fotorama--fullscreen .fotorama__nav:before, .fotorama--fullscreen .fotorama__nav:after,
  .fotorama__wrap--no-shadows .fotorama__nav:before,
  .fotorama__wrap--no-shadows .fotorama__nav:after {
    display: none; }
  .fotorama__wrap--only-active .fotorama__nav {
    max-width: 99999px !important; }

.fotorama__nav-wrap--vertical.fotorama__nav-wrap {
  position: absolute;
  display: inline-block;
  top: 0;
  left: 0; }

.fotorama__nav__frame {
  position: relative;
  box-sizing: content-box; }
  .fotorama__nav--dots .fotorama__nav__frame {
    width: 18px;
    height: 30px; }
    .fotorama__nav--dots .fotorama__nav__frame--thumb {
      display: none; }
  .fotorama__nav--thumbs .fotorama__nav__frame {
    padding-left: 0 !important; }
    .fotorama__nav--thumbs .fotorama__nav__frame:last-child {
      padding-right: 0 !important; }
    .fotorama__nav--thumbs .fotorama__nav__frame--dot {
      display: none; }
  .fotorama__nav__frame.fotorama__active .fotorama__nav__frame {
    width: 0;
    height: 0;
    border-width: 3px; }
    .fotorama__nav__frame.fotorama__active .fotorama__nav__frame:after {
      top: -3px;
      left: -3px;
      padding: 3px; }
  .fotorama__nav__frame:focus {
    box-shadow: none; }
    .fotorama__nav__frame:focus :after {
      top: -1px;
      left: -1px;
      padding: 1px; }
  .fotorama__nav-wrap--vertical .fotorama__nav__shaft .fotorama__nav__frame--thumb {
    display: block;
    padding-bottom: inherit !important; }

.fotorama__nav__shaft {
  position: relative;
  top: 0;
  left: 0;
  text-align: left; }
  .fotorama__nav-wrap--vertical .fotorama__nav__shaft {
    width: 100%;
    background-color: white; }

.fotorama__wrap .fotorama__pointer {
  cursor: pointer; }

.fotorama__select {
  cursor: auto; }

.fotorama__wrap--fade .fotorama__stage .fotorama__shadow {
  display: none; }

.fotorama__nav.fotorama__shadows--left:before, .fotorama__nav.fotorama__shadows--right:after,
.fotorama__stage.fotorama__shadows--left:before,
.fotorama__stage.fotorama__shadows--right:after {
  top: 0;
  bottom: 0;
  width: 10px;
  height: auto;
  background-size: 1px 100%, 5px 100%; }

.fotorama__nav.fotorama__shadows--top:before, .fotorama__nav.fotorama__shadows--bottom:after,
.fotorama__stage.fotorama__shadows--top:before,
.fotorama__stage.fotorama__shadows--bottom:after {
  left: 0;
  right: 0;
  width: auto;
  height: 10px;
  background-size: 100% 1px, 100% 5px; }

.fotorama__nav.fotorama__shadows--left:before,
.fotorama__stage.fotorama__shadows--left:before {
  left: 0;
  background-position: 0 0, 0 0;
  background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.2) 25%, rgba(0, 0, 0, 0.3) 75%, transparent), radial-gradient(farthest-side at 0 50%, rgba(0, 0, 0, 0.4), transparent); }

.fotorama__nav.fotorama__shadows--right:after,
.fotorama__stage.fotorama__shadows--right:after {
  right: 0;
  background-position: 100% 0, 100% 0;
  background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.2) 25%, rgba(0, 0, 0, 0.3) 75%, transparent), radial-gradient(farthest-side at 100% 50%, rgba(0, 0, 0, 0.4), transparent); }

.fotorama__nav.fotorama__shadows--top:before,
.fotorama__stage.fotorama__shadows--top:before {
  top: 0;
  background-position: 0 0, 0 0;
  background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.2) 25%, rgba(0, 0, 0, 0.3) 75%, transparent), radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.4), transparent); }

.fotorama__nav.fotorama__shadows--bottom:after,
.fotorama__stage.fotorama__shadows--bottom:after {
  bottom: 0;
  background-position: 0 100%, 0 100%;
  background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.2) 25%, rgba(0, 0, 0, 0.3) 75%, transparent), radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.4), transparent); }

.fotorama__video-close.fotorama-show-control {
  opacity: 1;
  transform: translate3d(0, -10px, 0); }

.fotorama__spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  position: absolute;
  display: none;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  height: 64px;
  width: 64px;
  margin: auto;
  background-image: url("../images/loader-1.gif");
  z-index: 100; }
  .fotorama__spinner--show {
    display: block; }
  .fotorama__wrap--css3 .fotorama__spinner {
    animation: spinner 24s infinite linear; }

@keyframes spinner {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.fotorama__stage {
  position: relative;
  max-width: 100%;
  overflow: hidden; }
  .fotorama__stage:before, .fotorama__stage:after {
    position: absolute;
    display: block;
    content: '';
    background-repeat: no-repeat;
    pointer-events: none;
    text-decoration: none;
    z-index: 10; }
  .fotorama__stage:before {
    left: -10px;
    top: -10px; }
  .fotorama__stage:after {
    right: -10px;
    bottom: -10px; }
  .fotorama--fullscreen .fotorama__stage:before, .fotorama--fullscreen .fotorama__stage:after,
  .fotorama__wrap--no-shadows .fotorama__stage:before,
  .fotorama__wrap--no-shadows .fotorama__stage:after,
  .fotorama__wrap--fade .fotorama__stage:before,
  .fotorama__wrap--fade .fotorama__stage:after {
    display: none; }
  .fotorama__wrap--only-active .fotorama__stage {
    max-width: 99999px !important; }

.fotorama__stage__frame {
  overflow: hidden;
  text-align: center; }
  .fotorama__wrap--rtl .fotorama__stage__frame {
    direction: rtl; }
  .fotorama__wrap--slide .fotorama__stage__frame {
    opacity: 1 !important; }
  .fotorama__wrap--fade .fotorama__stage__frame {
    display: none; }
  .fotorama--fullscreen .fotorama__stage__frame iframe {
    left: 80px;
    width: calc(100% - 80px * 2); }
  .fotorama__wrap--only-active .fotorama__stage__frame {
    visibility: hidden; }

.fotorama__stage__shaft {
  position: relative; }
  .fotorama__stage__shaft:focus {
    box-shadow: none; }
    .fotorama__stage__shaft:focus:after {
      border-radius: inherit;
      bottom: 3px;
      box-shadow: 0 0 3px 1px #68a8e0;
      content: '';
      left: 3px;
      position: absolute;
      right: 3px;
      top: 3px;
      z-index: 1000; }

.fotorama__thumb {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: #ebebeb; }
  .fotorama__nav__frame:focus .fotorama__thumb {
    box-shadow: none;
    z-index: 2; }
    .fotorama__nav__frame:focus .fotorama__thumb:after {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      box-shadow: 0 0 3px 1px #68a8e0;
      border-radius: inherit;
      content: '';
      z-index: 1000; }
  .fotorama__nav__frame--thumb:focus .fotorama__thumb,
  .fotorama__nav__frame--dot:focus .fotorama__thumb {
    overflow: inherit; }
  .fotorama__nav__frame:nth-child(2):focus .fotorama__thumb:after {
    left: 1px; }
  .fotorama__nav__frame:last-child:focus .fotorama__thumb:after {
    right: 1px; }
  .fotorama__thumb--icon {
    padding-bottom: 30px;
    font-size: 0.001px; }
    .fotorama__nav-wrap--horizontal .fotorama__thumb__arr--left .fotorama__thumb--icon,
    .fotorama__nav-wrap--horizontal .fotorama__thumb__arr--right .fotorama__thumb--icon {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0); }
      .ie9 .fotorama__nav-wrap--horizontal .fotorama__thumb__arr--left .fotorama__thumb--icon, .ie9
      .fotorama__nav-wrap--horizontal .fotorama__thumb__arr--right .fotorama__thumb--icon {
        margin: -15px 0 0 -15px; }
    .fotorama__nav-wrap--vertical .fotorama__thumb__arr--left .fotorama__thumb--icon,
    .fotorama__nav-wrap--vertical .fotorama__thumb__arr--right .fotorama__thumb--icon {
      margin: auto;
      width: 30px;
      transform: rotate(90deg); }
    .fotorama__thumb__arr--left .fotorama__thumb--icon {
      background-position: -25px -265px; }
    .fotorama__thumb__arr--right .fotorama__thumb--icon {
      background-position: -25px -350px; }

.fotorama__thumb-border {
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  border: 1px solid #2196f3;
  background-image: linear-gradient(to bottom right, rgba(255, 255, 255, 0.25), rgba(64, 64, 64, 0.1));
  backface-visibility: hidden;
  z-index: 9; }
  .fotorama__nav--dots .fotorama__thumb-border {
    display: none; }

.fotorama__thumb__arr {
  box-shadow: none;
  background-color: rgba(255, 255, 255, 0.3);
  transition: background-color 0.3s ease-in-out; }
  .fotorama__thumb__arr:hover {
    background-color: rgba(255, 255, 255, 0.5); }
  .fotorama__thumb__arr:active {
    background-color: rgba(213, 213, 213, 0.5); }
  .fotorama__nav-wrap--horizontal .fotorama__thumb__arr--left,
  .fotorama__nav-wrap--horizontal .fotorama__thumb__arr--right {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 30px;
    z-index: 1000;
    cursor: pointer; }
  .fotorama__nav-wrap--horizontal .fotorama__thumb__arr--left {
    left: 0; }
  .fotorama__nav-wrap--horizontal .fotorama__thumb__arr--right {
    right: 0; }
  .fotorama__nav-wrap--vertical .fotorama__thumb__arr--left,
  .fotorama__nav-wrap--vertical .fotorama__thumb__arr--right {
    position: absolute;
    left: 0;
    right: 0;
    height: 30px;
    cursor: pointer;
    z-index: 1000; }
  .fotorama__nav-wrap--vertical .fotorama__thumb__arr--left {
    top: 0; }
  .fotorama__nav-wrap--vertical .fotorama__thumb__arr--right {
    bottom: 0; }

.fotorama__nav__frame--thumb:focus .fotorama__thumb.fotorama_vertical_ratio:after,
.fotorama__nav__frame--dot:focus .fotorama__thumb.fotorama_vertical_ratio:after {
  left: 2px;
  right: 2px; }

.fotorama__nav__frame:nth-child(2):focus .fotorama__thumb.fotorama_vertical_ratio:after {
  top: 1px; }

.fotorama__nav__frame:last-child:focus .fotorama__thumb.fotorama_vertical_ratio:after {
  bottom: 1px; }

.fotorama__video {
  position: absolute;
  top: 32px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10; }

.fotorama__video-close {
  position: absolute;
  top: 0;
  right: 0;
  width: 80px;
  height: 80px;
  opacity: 0;
  z-index: 1000;
  cursor: pointer;
  background-position: -80px 0;
  transform: translate3d(80px, -80px, 0);
  transition: opacity 0.3s ease-in-out; }
  .fotorama__fullscreen .fotorama__video-close {
    display: none; }
  .fotorama__wrap--toggle-arrows:not(.fotorama__wrap--video) .fotorama__video-close {
    display: none; }
  .fotorama__wrap--toggle-arrows.fotorama__wrap--video .fotorama__video-close {
    top: 97px;
    right: 93px;
    opacity: 1; }
  .fotorama__wrap--css3 .fotorama__video-close:not(:focus) {
    transition-duration: 0.3s;
    transition-property: transform, opacity, background-color; }
  @media all and (max-width: 768px) {
    .fotorama__video-close {
      top: 10px;
      width: 40px;
      height: 40px;
      background-position: -100px -20px; } }

.fotorama__video-play {
  width: 160px;
  height: 160px;
  background-position: -80px -160px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0); }
  .fotorama__wrap--css2 .fotorama__video-play,
  .fotorama__wrap--video .fotorama__stage .fotorama__video-play {
    display: none; }
  .fotorama__loaded .fotorama__video-play,
  .fotorama__error .fotorama__video-play,
  .fotorama__nav__frame .fotorama__video-play {
    display: block;
    opacity: 1; }
  .fotorama__nav__frame .fotorama__video-play {
    width: 80px;
    height: 80px;
    background-position: 0 -240px; }
  .fotorama__wrap--css3 .fotorama__video-play:not(:focus) {
    transition-duration: 0.3s;
    transition-property: transform, opacity, background-color; }

.fotorama__wrap {
  position: relative;
  direction: ltr;
  text-size-adjust: 100%;
  z-index: 0; }
  .fotorama--fullscreen .fotorama__wrap {
    max-width: 100% !important; }
  .fotorama__wrap--pan-y {
    touch-action: pan-y; }

.fotorama__zoom-in,
.fotorama__zoom-out {
  display: none;
  z-index: 1000; }
  .fotorama__zoom-in:focus,
  .fotorama__zoom-out:focus {
    box-shadow: none; }
    .fotorama__zoom-in:focus:after,
    .fotorama__zoom-out:focus:after {
      border-radius: inherit;
      bottom: 3px;
      box-shadow: 0 0 3px 1px #68a8e0;
      content: '';
      left: 3px;
      position: absolute;
      right: 3px;
      top: 3px;
      z-index: 1000; }
  .fotorama__wrap:not(.fotorama__wrap--toggle-arrows) .fotorama__zoom-in, .fotorama__wrap:not(.fotorama__wrap--toggle-arrows)
  .fotorama__zoom-out {
    background-color: rgba(255, 255, 255, 0.3);
    transition: background-color 0.3s ease-in-out; }
    .fotorama__wrap:not(.fotorama__wrap--toggle-arrows) .fotorama__zoom-in:hover, .fotorama__wrap:not(.fotorama__wrap--toggle-arrows)
    .fotorama__zoom-out:hover {
      background-color: rgba(255, 255, 255, 0.5); }
    .fotorama__wrap:not(.fotorama__wrap--toggle-arrows) .fotorama__zoom-in:active, .fotorama__wrap:not(.fotorama__wrap--toggle-arrows)
    .fotorama__zoom-out:active {
      background-color: rgba(213, 213, 213, 0.5); }
  .fotorama__fullscreen .fotorama__zoom-in, .fotorama__fullscreen
  .fotorama__zoom-out {
    position: absolute;
    display: block;
    width: 80px;
    height: 80px;
    overflow: hidden;
    cursor: pointer; }
    .fotorama__fullscreen .fotorama__zoom-in--disabled, .fotorama__fullscreen
    .fotorama__zoom-out--disabled {
      display: none; }

.fotorama__fullscreen .fotorama__zoom-out {
  top: 80px;
  background-position: 0 -80px !important; }

.fotorama__fullscreen .fotorama__zoom-in {
  background-position: 0 0 !important;
  top: 0; }

.old-price,
.old.price {
  text-decoration: line-through; }

.prices-tier .price-container .price-including-tax + .price-excluding-tax:before {
  content: "(" attr(data-label) ": "; }

.prices-tier .price-container .price-including-tax + .price-excluding-tax:last-child:after {
  content: ')'; }

.prices-tier .price-container .weee[data-label] {
  display: inline; }
  .prices-tier .price-container .weee[data-label] .price {
    font-size: 11px; }
  .prices-tier .price-container .weee[data-label]:before {
    content: " +" attr(data-label) ": "; }

.actual-price {
  font-weight: 700; }

.category-image .image {
  display: block;
  height: auto;
  max-width: 100%; }

.category-image, .category-description {
  margin-bottom: 20px; }

.product-image-container {
  display: inline-block;
  max-width: 100%; }

.product-image-wrapper {
  display: block;
  height: 0;
  overflow: hidden;
  position: relative;
  z-index: 1; }

.product-image-photo {
  bottom: 0;
  display: block;
  height: auto;
  left: 0;
  margin: auto;
  max-width: 100%;
  position: absolute;
  right: 0;
  top: 0; }

.product.media .placeholder .photo.container {
  max-width: 100%; }

.product.media .notice {
  color: #47e96c;
  font-size: 12px;
  margin: 10px 0; }

.product.media .product.thumbs {
  margin: 20px 0 30px; }

.product.media .items.thumbs {
  margin: 0;
  padding: 0;
  list-style: none none; }
  .product.media .items.thumbs > li {
    display: inline-block;
    vertical-align: top; }
  .product.media .items.thumbs .active {
    display: block;
    line-height: 1; }

.product.info.detailed {
  clear: both;
  margin-bottom: 30px; }
  .product.info.detailed .additional-attributes {
    width: auto; }
    .product.info.detailed .additional-attributes > thead > tr > td,
    .product.info.detailed .additional-attributes > tbody > tr > td,
    .product.info.detailed .additional-attributes > tfoot > tr > td {
      padding: 4px 5px 10px 5px; }
    .product.info.detailed .additional-attributes > thead > tr > th,
    .product.info.detailed .additional-attributes > tbody > tr > th,
    .product.info.detailed .additional-attributes > tfoot > tr > th {
      padding: 4px 30px 10px 0; }

.product-info-main .page-title-wrapper .page-title {
  line-height: 1.42857;
  margin-bottom: 10px; }

.product-info-main .stock.available, .product-info-main .stock.unavailable {
  display: inline-block;
  font-weight: 700;
  margin-right: 20px;
  text-transform: uppercase;
  vertical-align: top; }

.product-info-main .product.attribute.sku {
  display: inline-block;
  vertical-align: top;
  color: #47e96c; }
  .product-info-main .product.attribute.sku > .value {
    display: inline-block;
    vertical-align: top;
    word-break: break-all; }
  .product-info-main .product.attribute.sku .type {
    margin-right: 5px; }

.product-info-main .product.attribute.overview {
  margin: 20px 0; }

.product-info-main .product.alert {
  margin: 10px 0; }

.product-info-main .price-box {
  margin-top: 10px; }

.product-info-main .product-reviews-summary .reviews-actions {
  font-size: 14px; }

.product-options-wrapper .fieldset-product-options-inner .legend {
  font-weight: 700;
  margin: 0 0 5px;
  font-size: 14px;
  border: none;
  display: inline-block;
  padding: 0; }

.product-options-wrapper .fieldset-product-options-inner.required .legend:after, .product-options-wrapper .fieldset-product-options-inner._required .legend:after {
  content: '*';
  color: #e02b27;
  font-size: 1.2rem;
  margin: 0 0 0 5px; }

.product-options-wrapper .field .note {
  display: block; }

.product-info-main .price-box .price-including-tax + .price-excluding-tax,
.product-info-main .price-box .weee + .price-excluding-tax,
.product-info-main .price-box .weee,
.product-options-bottom .price-box .price-including-tax + .price-excluding-tax,
.product-options-bottom .price-box .weee + .price-excluding-tax,
.product-options-bottom .price-box .weee {
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 5px; }
  .product-info-main .price-box .price-including-tax + .price-excluding-tax .price,
  .product-info-main .price-box .weee + .price-excluding-tax .price,
  .product-info-main .price-box .weee .price,
  .product-options-bottom .price-box .price-including-tax + .price-excluding-tax .price,
  .product-options-bottom .price-box .weee + .price-excluding-tax .price,
  .product-options-bottom .price-box .weee .price {
    font-size: 12px;
    font-weight: 700; }

.product-info-main .price-box .price-wrapper .price,
.product-options-bottom .price-box .price-wrapper .price {
  font-size: 18px;
  font-weight: 700; }

.product-info-main .price-box .price,
.product-options-bottom .price-box .price {
  white-space: nowrap; }

.product-info-main .special-price,
.product-options-bottom .special-price {
  display: block;
  margin: 10px 0; }
  .product-info-main .special-price .price-container,
  .product-options-bottom .special-price .price-container {
    font-size: 14px; }
  .product-info-main .special-price .price-label + .price-wrapper,
  .product-options-bottom .special-price .price-label + .price-wrapper {
    display: inline-block; }

.product-info-main .old-price .price-label:after,
.product-info-main .special-price .price-label:after,
.product-options-bottom .old-price .price-label:after,
.product-options-bottom .special-price .price-label:after {
  content: ': '; }

.product-info-main .box-tocart,
.product-options-bottom .box-tocart {
  margin: 20px 0; }
  .product-info-main .box-tocart .field.qty,
  .product-options-bottom .box-tocart .field.qty {
    padding-right: 15px; }
  .product-info-main .box-tocart .input-text.qty,
  .product-options-bottom .box-tocart .input-text.qty {
    height: 52px;
    text-align: center;
    width: 52px; }
  .product-info-main .box-tocart .actions,
  .product-options-bottom .box-tocart .actions {
    text-align: center; }

.product-info-main .product-addto-links,
.product-options-bottom .product-addto-links {
  margin: 20px 0; }

.product-info-main .action.tocompare,
.product-options-bottom .action.tocompare {
  vertical-align: top; }

.prices-tier {
  background: #f5f5f5;
  margin: 10px 0;
  padding: 10px 15px; }
  .prices-tier .price-container {
    display: inline-block; }
  .prices-tier .price-including-tax,
  .prices-tier .price-excluding-tax,
  .prices-tier .weee {
    display: inline-block; }
    .prices-tier .price-including-tax .price,
    .prices-tier .price-excluding-tax .price,
    .prices-tier .weee .price {
      font-size: 14px;
      font-weight: 700; }

.ui-dialog-titlebar-close {
  line-height: 1.42857;
  margin: 0;
  padding: 0;
  color: #118b2d;
  text-decoration: none;
  background: none;
  border: 0;
  display: inline; }
  .ui-dialog-titlebar-close:visited {
    color: #118b2d;
    text-decoration: none; }
  .ui-dialog-titlebar-close:hover {
    color: #0e7426;
    text-decoration: underline; }
  .ui-dialog-titlebar-close:active {
    color: #2196f3;
    text-decoration: underline; }
  .ui-dialog-titlebar-close:hover {
    color: #0e7426; }
  .ui-dialog-titlebar-close:hover, .ui-dialog-titlebar-close:active, .ui-dialog-titlebar-close:focus {
    background: none;
    border: 0; }
  .ui-dialog-titlebar-close.disabled, .ui-dialog-titlebar-close[disabled],
  fieldset[disabled] .ui-dialog-titlebar-close {
    color: #118b2d;
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
    text-decoration: underline; }

.block.related .action.select {
  margin: 0 5px; }

.sidebar .product-items .product-item {
  margin-bottom: 20px;
  position: relative; }
  .sidebar .product-items .product-item-info {
    position: relative;
    width: auto; }
    .sidebar .product-items .product-item-info .product-item-photo {
      left: 0;
      position: absolute;
      top: 0; }
  .sidebar .product-items .product-item-name {
    margin-top: 0; }
  .sidebar .product-items .product-item-details {
    margin: 0 0 0 85px; }
  .sidebar .product-items .product-item-actions {
    display: block;
    margin-top: 10px; }

.sidebar .product-items .price-box {
  display: block;
  margin: 7px 0; }

.sidebar .product-items .text {
  margin-right: 8px; }

.sidebar .product-items .counter {
  color: #47e96c;
  font-size: 12px;
  white-space: nowrap; }

.sidebar .product-items .minilist .price {
  display: inline;
  padding: 0; }

.sidebar .product-items .minilist .weee:before {
  display: inline-block; }

.sidebar .action.delete {
  position: absolute;
  right: 0;
  top: 0; }

.sidebar .product-items-images {
  margin-left: -5px; }
  .sidebar .product-items-images .product-item {
    float: left;
    padding-left: 5px; }

.sidebar .product-items-names .product-item {
  margin-bottom: 10px; }

.sidebar .product-items-names .product-item-name {
  margin: 0; }

@media only screen and (max-width: 1023px) {
  .catalog-product-view .column.main {
    display: flex;
    flex-direction: column; }
  .catalog-product-view .product.media {
    order: -1; }
  .block.related .action.select {
    display: block;
    margin: 5px 0; }
  .compare,
  .product-addto-links .action.tocompare,
  .product-item-actions .actions-secondary > .action.tocompare,
  [class*='block-compare'] {
    display: none; } }

@media only screen and (min-width: 1024px) {
  .product-info-main .box-tocart,
  .product-options-bottom .box-tocart {
    display: table; }
    .product-info-main .box-tocart .field.qty,
    .product-options-bottom .box-tocart .field.qty {
      display: table-cell; }
    .product-info-main .box-tocart .actions,
    .product-options-bottom .box-tocart .actions {
      display: table-cell;
      text-align: center;
      vertical-align: bottom; }
  .product-info-main .page-title-wrapper .page-title {
    margin-top: -13px; }
  .sidebar .product-items .product-item-info .product-item-photo {
    float: left;
    left: auto;
    margin: 0 10px 10px 0;
    position: relative;
    top: auto; }
  .sidebar .product-items .product-item-details {
    margin: 0; }
  .sidebar .product-items .product-item-actions {
    clear: left; } }

@media only screen and (min-width: 1440px) {
  .sidebar .product-items .product-item-info .product-item-photo {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0; }
  .sidebar .product-items .product-item-details {
    margin-left: 85px; } }

@media only screen and (min-width: 1024px) {
  .product-info-main {
    float: right; }
  .product.media {
    float: left;
    margin-bottom: 25px; }
  .page-layout-1column .product-info-main {
    width: 40%; }
  .page-layout-1column .product.media {
    width: 57%; }
  .page-layout-2columns-left .product-info-main,
  .page-layout-2columns-right .product-info-main,
  .page-layout-3columns .product-info-main {
    width: 48%; }
  .page-layout-2columns-left .product.media,
  .page-layout-2columns-right .product.media,
  .page-layout-3columns .product.media {
    width: 50%; } }

body.catalog-product-compare-index .action.print {
  float: right;
  margin: 15px 0; }

.table-wrapper.comparison {
  clear: both;
  max-width: 100%;
  overflow-x: auto; }

.table-comparison {
  table-layout: fixed; }
  .table-comparison .cell.label,
  .table-comparison td:last-child {
    border-right: 1px solid #E0E6EE; }
  .table-comparison .cell {
    padding: 15px;
    width: 140px; }
    .table-comparison .cell .attribute.value {
      overflow: hidden;
      width: 100%; }
    .table-comparison .cell.product.info, .table-comparison .cell.product.label {
      border-bottom: 1px solid #E0E6EE; }
    .table-comparison .cell.label .attribute.label {
      display: block;
      width: 100%;
      word-wrap: break-word; }
    .table-comparison .cell.attribute {
      font-size: 13px; }
      .table-comparison .cell.attribute img {
        height: auto;
        max-width: 100%; }
  .table-comparison .product-item-photo {
    display: block;
    margin: 0 auto 15px; }
  .table-comparison .product-image-photo {
    margin-left: 0; }
  .table-comparison .product-item-actions,
  .table-comparison .price-box,
  .table-comparison .product.rating,
  .table-comparison .product-item-name {
    display: block;
    margin: 15px 0; }
  .table-comparison .product-addto-links {
    margin-top: 15px; }
    .table-comparison .product-addto-links .action.split,
    .table-comparison .product-addto-links .action.toggle {
      line-height: 1.2rem;
      padding: 5px 8px;
      font-size: 1.1rem; }
    .table-comparison .product-addto-links .action.toggle {
      padding: 0; }
  .table-comparison .cell.remove {
    padding-bottom: 0;
    padding-top: 0;
    text-align: right; }
  .table-comparison .product-item-actions > .actions-primary + .actions-secondary {
    margin-top: 10px; }
  .table-comparison .action.tocart {
    white-space: nowrap; }

.comparison.headings {
  background: #fff;
  left: 0;
  position: absolute;
  top: 0;
  width: auto;
  z-index: 2; }

.block-compare .product-item .product-item-name {
  margin-left: 22px; }

.block-compare .action.delete {
  left: -6px;
  position: absolute;
  top: 0; }

.block-compare .actions-toolbar {
  margin: 17px 0 0; }

.old-price,
.old.price {
  text-decoration: line-through; }

.prices-tier .price-container .price-including-tax + .price-excluding-tax:before {
  content: "(" attr(data-label) ": "; }

.prices-tier .price-container .price-including-tax + .price-excluding-tax:last-child:after {
  content: ')'; }

.prices-tier .price-container .weee[data-label] {
  display: inline; }
  .prices-tier .price-container .weee[data-label] .price {
    font-size: 1.1rem; }
  .prices-tier .price-container .weee[data-label]:before {
    content: " +" attr(data-label) ": "; }

.actual-price {
  font-weight: 700; }

.category-image .image {
  display: block;
  height: auto;
  max-width: 100%; }

.category-image, .category-description {
  margin-bottom: 20px; }

.product-image-container {
  display: inline-block;
  max-width: 100%; }

.product-image-wrapper {
  display: block;
  height: 0;
  overflow: hidden;
  position: relative;
  z-index: 1; }

.product-image-photo {
  bottom: 0;
  display: block;
  height: auto;
  left: 0;
  margin: auto;
  max-width: 100%;
  position: absolute;
  right: 0;
  top: 0; }

.product.media .placeholder .photo.container {
  max-width: 100%; }

.product.media .notice {
  color: #47e96c;
  font-size: 1.2rem;
  margin: 10px 0; }

.product.media .product.thumbs {
  margin: 20px 0 30px; }

.product.media .items.thumbs {
  margin: 0;
  padding: 0;
  list-style: none none; }
  .product.media .items.thumbs > li {
    display: inline-block;
    vertical-align: top; }
  .product.media .items.thumbs .active {
    display: block;
    line-height: 1; }

.product.info.detailed {
  clear: both;
  margin-bottom: 30px; }
  .product.info.detailed .additional-attributes {
    width: auto; }
    .product.info.detailed .additional-attributes > thead > tr > td,
    .product.info.detailed .additional-attributes > tbody > tr > td,
    .product.info.detailed .additional-attributes > tfoot > tr > td {
      padding: 4px 5px 10px 5px; }
    .product.info.detailed .additional-attributes > thead > tr > th,
    .product.info.detailed .additional-attributes > tbody > tr > th,
    .product.info.detailed .additional-attributes > tfoot > tr > th {
      padding: 4px 30px 10px 0; }

.product-info-main .page-title-wrapper .page-title {
  line-height: 1.42857;
  margin-bottom: 10px; }

.product-info-main .stock.available, .product-info-main .stock.unavailable {
  display: inline-block;
  font-weight: 700;
  margin-right: 20px;
  text-transform: uppercase;
  vertical-align: top; }

.product-info-main .product.attribute.sku {
  display: inline-block;
  vertical-align: top;
  color: #47e96c; }
  .product-info-main .product.attribute.sku > .value {
    display: inline-block;
    vertical-align: top;
    word-break: break-all; }
  .product-info-main .product.attribute.sku .type {
    margin-right: 5px; }

.product-info-main .product.attribute.overview {
  margin: 20px 0; }

.product-info-main .product.alert {
  margin: 10px 0; }

.product-info-main .price-box {
  margin-top: 10px; }

.product-info-main .product-reviews-summary .reviews-actions {
  font-size: 1.4rem; }

.product-options-wrapper .fieldset-product-options-inner .legend {
  font-weight: 700;
  margin: 0 0 5px;
  font-size: 1.4rem;
  border: none;
  display: inline-block;
  float: none;
  padding: 0; }

.product-options-wrapper .fieldset-product-options-inner input.datetime-picker ~ select.datetime-picker {
  margin-top: 10px; }

.product-options-wrapper .fieldset-product-options-inner.required .legend:after, .product-options-wrapper .fieldset-product-options-inner._required .legend:after {
  content: '*';
  color: #e02b27;
  font-size: 1.2rem;
  margin: 0 0 0 5px; }

.product-options-wrapper .field .note {
  display: block; }

.product-info-main .price-box .price-including-tax + .price-excluding-tax,
.product-info-main .price-box .weee + .price-excluding-tax,
.product-info-main .price-box .weee,
.product-options-bottom .price-box .price-including-tax + .price-excluding-tax,
.product-options-bottom .price-box .weee + .price-excluding-tax,
.product-options-bottom .price-box .weee {
  font-size: 1.2rem;
  line-height: 14px;
  margin-bottom: 5px; }
  .product-info-main .price-box .price-including-tax + .price-excluding-tax .price,
  .product-info-main .price-box .weee + .price-excluding-tax .price,
  .product-info-main .price-box .weee .price,
  .product-options-bottom .price-box .price-including-tax + .price-excluding-tax .price,
  .product-options-bottom .price-box .weee + .price-excluding-tax .price,
  .product-options-bottom .price-box .weee .price {
    font-size: 1.2rem;
    font-weight: 700; }

.product-info-main .price-box .price-wrapper .price,
.product-options-bottom .price-box .price-wrapper .price {
  font-size: 1.8rem;
  font-weight: 700; }

.product-info-main .price-box .price,
.product-options-bottom .price-box .price {
  white-space: nowrap; }

.product-info-main .special-price,
.product-options-bottom .special-price {
  display: block;
  margin: 10px 0; }
  .product-info-main .special-price .price-container,
  .product-options-bottom .special-price .price-container {
    font-size: 1.4rem; }
  .product-info-main .special-price .price-label + .price-wrapper,
  .product-options-bottom .special-price .price-label + .price-wrapper {
    display: inline-block; }

.product-info-main .old-price .price-label:after,
.product-info-main .special-price .price-label:after,
.product-options-bottom .old-price .price-label:after,
.product-options-bottom .special-price .price-label:after {
  content: ': '; }

.product-info-main .box-tocart,
.product-options-bottom .box-tocart {
  margin: 20px 0; }
  .product-info-main .box-tocart .field.qty,
  .product-options-bottom .box-tocart .field.qty {
    padding-right: 15px; }
  .product-info-main .box-tocart .input-text.qty,
  .product-options-bottom .box-tocart .input-text.qty {
    height: 52px;
    text-align: center;
    width: 52px; }
  .product-info-main .box-tocart .actions,
  .product-options-bottom .box-tocart .actions {
    text-align: center; }

.product-info-main .product-addto-links,
.product-options-bottom .product-addto-links {
  margin: 20px 0; }

.product-info-main .action.tocompare,
.product-options-bottom .action.tocompare {
  vertical-align: top; }

.prices-tier {
  background: #f5f5f5;
  margin: 10px 0;
  padding: 10px 15px; }
  .prices-tier .price-container {
    display: inline-block; }
  .prices-tier .price-including-tax,
  .prices-tier .price-excluding-tax,
  .prices-tier .weee {
    display: inline-block; }
    .prices-tier .price-including-tax .price,
    .prices-tier .price-excluding-tax .price,
    .prices-tier .weee .price {
      font-size: 1.4rem;
      font-weight: 700; }

.ui-dialog-titlebar-close {
  line-height: 1.42857;
  margin: 0;
  padding: 0;
  color: #118b2d;
  text-decoration: none;
  background: none;
  border: 0;
  display: inline; }
  .ui-dialog-titlebar-close:visited {
    color: #118b2d;
    text-decoration: none; }
  .ui-dialog-titlebar-close:hover {
    color: #0e7426;
    text-decoration: underline; }
  .ui-dialog-titlebar-close:active {
    color: #2196f3;
    text-decoration: underline; }
  .ui-dialog-titlebar-close:hover {
    color: #0e7426; }
  .ui-dialog-titlebar-close:hover, .ui-dialog-titlebar-close:active, .ui-dialog-titlebar-close:focus {
    background: none;
    border: 0; }
  .ui-dialog-titlebar-close.disabled, .ui-dialog-titlebar-close[disabled],
  fieldset[disabled] .ui-dialog-titlebar-close {
    color: #118b2d;
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
    text-decoration: underline; }

.block.related .action.select {
  margin: 0 5px; }

.sidebar .product-items .product-item {
  margin-bottom: 20px;
  position: relative; }

.sidebar .product-items .product-item-info {
  position: relative;
  width: auto; }
  .sidebar .product-items .product-item-info .product-item-photo {
    left: 0;
    position: absolute;
    top: 0; }

.sidebar .product-items .product-item-name {
  margin-top: 0; }

.sidebar .product-items .product-item-details {
  margin: 0 0 0 85px; }

.sidebar .product-items .product-item-actions {
  display: block;
  margin-top: 10px; }

.sidebar .product-items .price-box {
  display: block;
  margin: 7px 0; }

.sidebar .product-items .text {
  margin-right: 8px; }

.sidebar .product-items .counter {
  color: #47e96c;
  font-size: 1.2rem;
  white-space: nowrap; }

.sidebar .product-items .minilist .price {
  display: inline;
  padding: 0; }

.sidebar .product-items .minilist .weee:before {
  display: inline-block; }

.sidebar .action.delete {
  position: absolute;
  right: 0;
  top: 0; }

.sidebar .product-items-images {
  margin-left: -5px; }
  .sidebar .product-items-images .product-item {
    float: left;
    padding-left: 5px; }

.sidebar .product-items-names .product-item {
  margin-bottom: 10px; }

.sidebar .product-items-names .product-item-name {
  margin: 0; }

@media only screen and (max-width: 1023px) {
  .catalog-product-view .column.main {
    display: flex;
    flex-direction: column; }
  .catalog-product-view .product.media {
    order: -1; }
  .block.related .action.select {
    display: block;
    margin: 5px 0; }
  .compare,
  .product-addto-links .action.tocompare,
  .product-item-actions .actions-secondary > .action.tocompare,
  [class*='block-compare'] {
    display: none; } }

@media only screen and (min-width: 1024px) {
  .product-info-main .box-tocart,
  .product-options-bottom .box-tocart {
    display: table; }
    .product-info-main .box-tocart .field.qty,
    .product-options-bottom .box-tocart .field.qty {
      display: table-cell; }
    .product-info-main .box-tocart .actions,
    .product-options-bottom .box-tocart .actions {
      display: table-cell;
      padding-top: 25px;
      text-align: center;
      vertical-align: bottom; }
  .product-info-main .page-title-wrapper .page-title {
    margin-top: -13px; }
  .sidebar .product-items .product-item-info .product-item-photo {
    float: left;
    left: auto;
    margin: 0 10px 10px 0;
    position: relative;
    top: auto; }
  .sidebar .product-items .product-item-details {
    margin: 0; }
  .sidebar .product-items .product-item-actions {
    clear: left; } }

@media only screen and (min-width: 1440px) {
  .sidebar .product-items .product-item-info .product-item-photo {
    float: none;
    left: 0;
    margin: 0;
    position: absolute;
    top: 0; }
  .sidebar .product-items .product-item-details {
    margin-left: 85px; } }

@media only screen and (min-width: 1024px) {
  .product-info-main {
    float: right; }
  .product.media {
    float: left;
    margin-bottom: 25px; }
  .page-layout-1column .product-info-main {
    width: 40%; }
  .page-layout-1column .product.media {
    width: 60%;
    padding-right: 20px; }
  .page-layout-2columns-left .product-info-main,
  .page-layout-2columns-right .product-info-main,
  .page-layout-3columns .product-info-main {
    width: 48%; }
  .page-layout-2columns-left .product.media,
  .page-layout-2columns-right .product.media,
  .page-layout-3columns .product.media {
    width: 50%; } }

body.catalog-product-compare-index .action.print {
  float: right;
  margin: 15px 0; }

.table-wrapper.comparison {
  clear: both;
  max-width: 100%;
  overflow-x: auto; }

.table-comparison {
  table-layout: fixed; }
  .table-comparison .cell.label,
  .table-comparison td:last-child {
    border-right: 1px solid #E0E6EE; }
  .table-comparison .cell {
    padding: 15px;
    width: 140px; }
    .table-comparison .cell .attribute.value {
      overflow: hidden;
      width: 100%; }
    .table-comparison .cell.product.info, .table-comparison .cell.product.label {
      border-bottom: 1px solid #E0E6EE; }
    .table-comparison .cell.label .attribute.label {
      display: block;
      width: 100%;
      word-wrap: break-word; }
    .table-comparison .cell.attribute {
      font-size: 1.3rem; }
      .table-comparison .cell.attribute img {
        height: auto;
        max-width: 100%; }
  .table-comparison .product-item-photo {
    display: block;
    margin: 0 auto 15px; }
  .table-comparison .product-image-photo {
    margin-left: 0; }
  .table-comparison .product-item-actions,
  .table-comparison .price-box,
  .table-comparison .product.rating,
  .table-comparison .product-item-name {
    display: block;
    margin: 15px 0; }
  .table-comparison .product-addto-links {
    margin-top: 15px; }
    .table-comparison .product-addto-links .action.split,
    .table-comparison .product-addto-links .action.toggle {
      line-height: 1.2rem;
      padding: 5px 8px;
      font-size: 1.1rem; }
    .table-comparison .product-addto-links .action.toggle {
      padding: 0; }
  .table-comparison .cell.remove {
    padding-bottom: 0;
    padding-top: 0;
    text-align: right; }
  .table-comparison .product-item-actions > .actions-primary + .actions-secondary {
    margin-top: 10px; }
  .table-comparison .action.tocart {
    white-space: nowrap; }

.comparison.headings {
  background: #fff;
  left: 0;
  position: absolute;
  top: 0;
  width: auto;
  z-index: 2; }

.block-compare .product-item .product-item-name {
  margin-left: 22px; }

.block-compare .action.delete {
  left: -6px;
  position: absolute;
  top: 0; }

.block-compare .actions-toolbar {
  margin: 17px 0 0; }

.block-category-event {
  background: #f0f0f0;
  color: #118b2d;
  padding: 10px 0 15px;
  text-align: center; }
  .block-category-event.block:last-child {
    margin-bottom: 10px; }
  .block-category-event .block-title {
    margin: 0 0 10px; }
    .block-category-event .block-title strong {
      font-size: 1.5rem;
      font-weight: 300; }
  .block-category-event .ticker {
    margin: 0;
    padding: 0;
    list-style: none none; }
    .block-category-event .ticker li {
      display: none;
      margin: 0 15px; }
    .block-category-event .ticker .value {
      font-size: 1.8rem;
      font-weight: 300; }
    .block-category-event .ticker .label {
      font-size: 1rem;
      display: block;
      text-transform: lowercase; }
  .block-category-event .dates {
    display: table;
    margin: 0 auto; }
    .block-category-event .dates .start,
    .block-category-event .dates .end {
      display: table-cell;
      vertical-align: top; }
    .block-category-event .dates .date {
      font-size: 1.8rem; }
    .block-category-event .dates .time {
      display: block; }
    .block-category-event .dates .start {
      padding-right: 40px;
      position: relative; }
      .block-category-event .dates .start:after {
        font-size: 1.8rem;
        content: '\2013';
        display: block;
        position: absolute;
        right: 15px;
        top: 0; }

@media only screen and (min-width: 1024px) {
  .block-category-event.block:last-child {
    margin-bottom: 30px;
    padding: 10px 0 30px; }
  .block-category-event .block-title {
    margin: 0; }
    .block-category-event .block-title strong {
      font-size: 2.4rem; }
  .block-category-event .ticker li {
    display: none;
    margin: 0 50px; }
  .block-category-event .ticker .value {
    font-size: 6rem; }
  .block-category-event .ticker .label {
    font-size: 1.4rem;
    text-transform: none; }
  .block-category-event .dates .date {
    font-size: 5rem; }
  .block-category-event .dates .start {
    padding-right: 50px; }
    .block-category-event .dates .start:after {
      font-size: 5rem;
      right: 10px; } }

.block-search {
  margin-bottom: 0; }
  .block-search .block-title {
    display: none; }
  .block-search .block-content {
    margin-bottom: 0; }
  .block-search .label {
    display: inline-block;
    text-decoration: none;
    display: inline-block;
    float: right; }
    .block-search .label > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .block-search .label:before {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 35px;
      line-height: 33px;
      color: #3c3c3c;
      content: "";
      font-family: "Material Icons";
      vertical-align: middle;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; }
    .block-search .label:hover:before {
      color: #414141; }
    .block-search .label:active:before {
      color: #414141; }
    .block-search .label.active + .control input {
      position: static; }
  .block-search .action.search:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 28px;
    line-height: 22px;
    color: #b5b5b5;
    content: "";
    font-family: "Material Icons";
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
    margin-right: -15px; }
  .block-search .control {
    clear: both;
    margin: 0 -15px -1px;
    padding: 0 15px;
    height: 38px; }
  .block-search input {
    margin: 0;
    position: absolute;
    height: inherit; }
  .block-search .nested {
    display: none; }

.search-autocomplete {
  display: none;
  margin-top: -15px;
  overflow: hidden;
  position: absolute;
  z-index: 3;
  background: #fff;
  border: 1px solid #ededed;
  border-top: 2px solid #118b2d;
  padding: 1rem;
  box-shadow: 5px 5px 15px 0px rgba(0, 0, 0, 0.14); }
  .search-autocomplete .autocomplete-list-title {
    text-transform: uppercase;
    font-size: 10px;
    color: rgba(0, 0, 0, 0.3);
    font-weight: 800;
    margin-bottom: 1rem; }
  .search-autocomplete dd {
    display: flex;
    width: 100%; }
    .search-autocomplete dd:hover {
      cursor: pointer; }
    .search-autocomplete dd .product-item .price {
      font-size: 14px; }
    .search-autocomplete dd .price-box,
    .search-autocomplete dd .price-final_price {
      margin-top: -0.5rem !important;
      margin-bottom: 0; }
    .search-autocomplete dd .category-mini-crumb,
    .search-autocomplete dd .qs-option-name {
      cursor: pointer; }
    .search-autocomplete dd .product-image-box {
      margin-right: 1rem; }
    .search-autocomplete dd .qs-option-name {
      margin-right: 1rem; }
  .search-autocomplete ul {
    margin: 0;
    padding: 0;
    list-style: none none; }
    .search-autocomplete ul li {
      border-top: 1px solid #e5e5e5;
      cursor: pointer;
      margin: 0;
      padding: 5px 40px 5px 10px;
      position: relative;
      text-align: left;
      white-space: normal; }
      .search-autocomplete ul li:not(:empty) {
        border-top: 0;
        border: 1px solid #c2c2c2;
        background: #fff; }
      .search-autocomplete ul li:first-child {
        border-top: none; }
      .search-autocomplete ul li:hover, .search-autocomplete ul li.selected {
        background: #e8e8e8; }
      .search-autocomplete ul li .amount {
        color: #999;
        position: absolute;
        right: 7px;
        top: 5px; }

.form.search.advanced .fields.range .field:first-child {
  position: relative; }
  .form.search.advanced .fields.range .field:first-child .control {
    padding-right: 25px; }
    .form.search.advanced .fields.range .field:first-child .control:after {
      content: ' \2013 ';
      display: inline-block;
      position: absolute;
      right: 0;
      text-align: center;
      top: 6px;
      width: 25px; }

.form.search.advanced .fields.range .field:last-child {
  position: relative; }
  .form.search.advanced .fields.range .field:last-child div.mage-error[generated] {
    left: 0;
    position: absolute;
    top: 32px; }

.form.search.advanced .fields.range .field.with-addon .control {
  padding-right: 45px; }

.form.search.advanced .group.price .addon .addafter {
  background: none;
  border: 0;
  padding-top: 6px;
  position: absolute;
  right: 0;
  top: 0; }

.search.summary {
  margin-bottom: 10px; }

@media only screen and (max-width: 1023px) {
  .block-search .block-content {
    margin-bottom: 0; }
  .block-search .field.search label {
    display: none; }
  .block-search .control {
    padding: 0; }
  .block-search .control input.input-text {
    position: relative;
    border-right: 0; } }

@media only screen and (max-width: 767px) {
  .block-search {
    margin-top: 10px; }
  .field.search label {
    display: none; }
  .control input.input-text {
    position: relative;
    width: 100%; } }

@media only screen and (min-width: 1024px) {
  .block-search {
    float: right;
    padding-left: 15px;
    position: relative;
    width: 250px;
    z-index: 4; }
    .block-search .control {
      border-top: 0;
      margin: 0;
      padding: 0 0 25px; }
    .block-search .nested {
      display: block;
      padding-top: 5px;
      position: absolute; }
    .block-search input {
      margin: 0;
      padding-right: 35px;
      position: static; }
      .block-search input::-webkit-input-placeholder {
        color: #c2c2c2; }
      .block-search input:-moz-placeholder {
        color: #c2c2c2; }
      .block-search input::-moz-placeholder {
        color: #c2c2c2; }
      .block-search input:-ms-input-placeholder {
        color: #c2c2c2; }
    .block-search .action.search {
      display: inline-block;
      display: inline-block;
      text-decoration: none;
      background-image: none;
      background: none;
      -moz-box-sizing: content-box;
      border: 0;
      box-shadow: none;
      line-height: inherit;
      margin: 0;
      padding: 0;
      text-decoration: none;
      text-shadow: none;
      font-weight: 400;
      padding: 5px 0;
      position: absolute;
      right: 10px;
      top: 3px;
      z-index: 1; }
      .block-search .action.search > span {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px; }
      .block-search .action.search:before {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 22px;
        line-height: 22px;
        color: #3c3c3c;
        content: "";
        font-family: "Material Icons";
        margin: 0;
        vertical-align: top;
        display: inline-block;
        font-weight: normal;
        overflow: hidden;
        speak: none;
        text-align: center; }
      .block-search .action.search:hover:before {
        color: inherit; }
      .block-search .action.search:active:before {
        color: inherit; }
      .block-search .action.search:focus, .block-search .action.search:active {
        background: none;
        border: none; }
      .block-search .action.search:hover {
        background: none;
        border: none; }
      .block-search .action.search.disabled, .block-search .action.search[disabled],
      fieldset[disabled] .block-search .action.search {
        cursor: not-allowed;
        pointer-events: none;
        opacity: 0.5; }
      .block-search .action.search:focus:before {
        color: #333; }
  .search-autocomplete {
    margin-top: 0; } }

.cart-summary .block .title > strong {
  display: block;
  padding: 1rem 0;
  cursor: pointer;
  font-weight: 300;
  line-height: 1.1;
  font-size: 1.8rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  display: inline-block;
  text-decoration: none; }
  .cart-summary .block .title > strong:hover {
    cursor: pointer;
    opacity: 0.9; }
  .cart-summary .block .title > strong:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 26px;
    line-height: inherit;
    color: #a2a2a2;
    content: "";
    font-family: "Material Icons";
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center; }

.cart-summary .block.active .title > strong {
  display: inline-block;
  text-decoration: none; }
  .cart-summary .block.active .title > strong:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 26px;
    line-height: inherit;
    color: #a2a2a2;
    content: "";
    font-family: "Material Icons";
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center; }

.cart-summary .block.giftcard {
  display: none; }

.summary {
  background: #f5f5f5;
  margin-bottom: 25px;
  padding: 1rem; }
  .summary.title {
    display: block;
    font-weight: 300;
    line-height: 1.1;
    font-size: 1.8rem;
    margin-top: 1.5rem;
    margin-bottom: 1rem; }
  .summary .block {
    margin-bottom: 0; }
    .summary .block .item-options {
      margin-left: 0; }
    .summary .block .fieldset {
      display: flex;
      flex-direction: column;
      padding: 0.5rem; }
      .summary .block .fieldset .field {
        margin: 0 0 10px; }
        .summary .block .fieldset .field.note {
          font-size: 12px; }
      .summary .block .fieldset .methods .field > .label {
        display: inline; }

.cart-totals {
  border-top: 1px solid #E0E6EE;
  padding-top: 10px; }
  .cart-totals .table-wrapper {
    margin-bottom: 0;
    overflow: inherit; }

.cart.table-wrapper .items thead + .item {
  border-top: 1px solid #E0E6EE; }

.cart.table-wrapper .items > .item {
  border-bottom: 1px solid #E0E6EE;
  position: relative; }

.cart.table-wrapper .col {
  padding-top: 20px; }
  .cart.table-wrapper .col.qty .input-text {
    margin-top: -5px; }

.cart.table-wrapper .item-actions td {
  padding-bottom: 10px;
  text-align: center;
  white-space: normal; }

.cart.table-wrapper .item .col.item {
  display: block;
  min-height: 75px;
  padding: 25px 0 10px 75px;
  position: relative; }

.cart.table-wrapper .actions-toolbar > .action {
  display: inline-block;
  text-decoration: none;
  margin-bottom: 10px;
  margin-right: 10px; }
  .cart.table-wrapper .actions-toolbar > .action:hover, .cart.table-wrapper .actions-toolbar > .action:active, .cart.table-wrapper .actions-toolbar > .action:focus {
    text-decoration: none; }
  .cart.table-wrapper .actions-toolbar > .action:last-child {
    margin-right: 0; }

.cart.table-wrapper .action.help.map {
  font-weight: 400; }

.cart.table-wrapper .product-item-photo {
  display: block;
  left: 0;
  max-width: 60px;
  padding: 0;
  position: absolute;
  top: 15px;
  width: 100%; }

.cart.table-wrapper .product-item-details {
  white-space: normal; }

.cart.table-wrapper .product-item-name {
  display: inline-block;
  font-weight: 400;
  margin-top: -6px; }

.cart.table-wrapper .gift-registry-name-label:after {
  content: ':'; }

.cart.table-wrapper .item-options {
  font-size: 12px;
  margin-bottom: 10px; }

.cart.table-wrapper .product-item-name + .item-options {
  margin-top: 10px; }

.cart.table-wrapper .action.configure {
  display: inline-block;
  margin: 0 0 20px; }

.cart-container {
  display: grid;
  display: -ms-grid;
  grid-template-columns: 1fr;
  -ms-grid-columns: 1fr;
  -ms-grid-rows: 1fr 3rem 1fr 3rem 1fr;
  grid-gap: 3rem; }
  .cart-container .form-cart {
    grid-column: 1;
    -ms-grid-column: 1;
    grid-row: 2;
    -ms-grid-row: 3;
    width: 100%;
    float: none;
    padding: 0; }
    .cart-container .form-cart .cart.actions .action {
      background: none; }
    .cart-container .form-cart .cart.actions .action.update:before {
      font-size: 24px; }
    .cart-container .form-cart .cart.actions .action.clear {
      display: none; }
  .cart-container .checkout-methods-items {
    margin-top: 20px;
    text-align: center; }
    .cart-container .checkout-methods-items .action.primary {
      width: 100%; }
    .cart-container .checkout-methods-items .item + .item {
      margin-top: 20px; }
  .cart-container .block.crosssell {
    grid-column: 1;
    -ms-grid-column: 1;
    grid-row: 3;
    -ms-grid-row: 5;
    margin: 0; }

.cart-tax-info + .cart-tax-total,
.cart .cart-tax-info + .cart-tax-total {
  display: block; }

.cart.table-wrapper .col.price,
.cart.table-wrapper .col.qty,
.cart.table-wrapper .col.subtotal,
.cart.table-wrapper .col.msrp,
.order-items.table-wrapper .col.price,
.order-items.table-wrapper .col.qty,
.order-items.table-wrapper .col.subtotal,
.order-items.table-wrapper .col.msrp {
  text-align: right; }

@media only screen and (max-width: 1023px) {
  .cart.table-wrapper {
    overflow: inherit; }
    .cart.table-wrapper thead .col:not(.item) {
      display: none; }
    .cart.table-wrapper .col.qty, .cart.table-wrapper .col.price, .cart.table-wrapper .col.subtotal, .cart.table-wrapper .col.msrp {
      box-sizing: border-box;
      display: block;
      float: left;
      text-align: center;
      white-space: nowrap;
      width: 33%; }
      .cart.table-wrapper .col.qty:before, .cart.table-wrapper .col.price:before, .cart.table-wrapper .col.subtotal:before, .cart.table-wrapper .col.msrp:before {
        content: attr(data-th) ":";
        display: block;
        font-weight: 700;
        padding-bottom: 10px; }
    .cart.table-wrapper .col.msrp {
      white-space: normal; }
    .cart.table-wrapper .item .col.item {
      padding-bottom: 0; } }

@media only screen and (min-width: 1024px) {
  .cart-container {
    grid-template-columns: 3fr 1fr;
    -ms-grid-columns: 3fr 3rem 1fr; }
    .cart-container.no-products .prescription-form-cart {
      grid-row: 1;
      -ms-grid-row: 1; }
    .cart-container.no-products .form-cart {
      grid-row: 2;
      -ms-grid-row: 4; }
    .cart-container .form-cart {
      grid-row: 1;
      -ms-grid-row: 1; }
    .cart-container .cart-summary {
      grid-column: 2;
      -ms-grid-column: 3;
      grid-row: 1;
      -ms-grid-row: 1; }
    .cart-container .widget {
      float: left; }
    .cart-container .block.crosssell {
      grid-column: 1 / span 2;
      -ms-grid-column: 1;
      -ms-grid-column-span: 5;
      grid-row: 2;
      -ms-grid-row: 3; }
  .column.main .cart-summary .actions-toolbar > .secondary {
    float: none; }
  .cart-summary .block .fieldset .field {
    margin: 0 0 20px;
    margin: 0 0 10px; }
    .cart-summary .block .fieldset .field:not(.choice) > .label {
      box-sizing: content-box;
      float: none;
      width: auto;
      text-align: left;
      padding: 0; }
    .cart-summary .block .fieldset .field:not(.choice) > .control {
      float: none;
      width: auto; }
    .cart-summary .block .fieldset .field > .label {
      margin: 0 0 5px;
      display: inline-block; }
    .cart-summary .block .fieldset .field.choice:before, .cart-summary .block .fieldset .field.no-label:before {
      display: none; }
  .cart.table-wrapper .items {
    min-width: 100%;
    width: auto; }
  .cart.table-wrapper .item .col.item {
    padding: 27px 8px 10px; }
  .cart.table-wrapper .item-actions td {
    text-align: right; }
  .cart.table-wrapper .product-item-photo {
    display: table-cell;
    max-width: 100%;
    padding-right: 20px;
    position: static;
    vertical-align: top;
    width: 1%; }
  .cart.table-wrapper .product-item-details {
    display: table-cell;
    vertical-align: top;
    white-space: normal;
    width: 99%; }
  .cart.table-wrapper .item-actions .actions-toolbar {
    text-align: left; } }

.block-minicart .items-total {
  display: none;
  float: left;
  margin: 0 10px;
  margin-top: 0.25rem; }
  .block-minicart .items-total .count {
    font-weight: 700; }

.block-minicart .subtotal {
  margin: 0 10px;
  text-align: left; }
  .block-minicart .subtotal .label {
    display: none; }

.block-minicart .amount .price-wrapper:first-child .price {
  font-size: 18px;
  font-weight: 700; }

.block-minicart .subtitle {
  display: none; }
  .block-minicart .subtitle.empty {
    display: block;
    font-size: 14px;
    padding: 30px 0 20px;
    text-align: center; }

.block-minicart .text.empty {
  text-align: center; }

.block-minicart .block-content > .actions {
  margin-top: 15px;
  text-align: center; }
  .block-minicart .block-content > .actions > .primary {
    margin: 0 10px 15px; }
    .block-minicart .block-content > .actions > .primary .action.primary {
      display: block;
      margin-bottom: 15px;
      width: calc(100% + 4rem + 6px);
      margin-left: calc(-2rem - 3px);
      margin-bottom: -1rem; }
      .block-minicart .block-content > .actions > .primary .action.primary:last-child {
        margin-bottom: 0; }

.block-minicart .action.close,
.block-minicart .action.delete,
.block-minicart .action.edit,
.block-minicart .action.remove {
  transform: scale(0.6); }

.block-minicart .block-category-link,
.block-minicart .block-product-link,
.block-minicart .block-cms-link,
.block-minicart .block-banners {
  margin: 15px 0 0;
  text-align: center; }

.minicart-wrapper {
  grid-column: 1;
  order: 1;
  -ms-grid-column: 1;
  grid-row: 1;
  -ms-grid-row: 1;
  width: 100%;
  display: inline-block;
  position: relative; }
  .minicart-wrapper:before, .minicart-wrapper:after {
    content: '';
    display: table; }
  .minicart-wrapper:after {
    clear: both; }
  .minicart-wrapper .action.showcart {
    cursor: pointer;
    display: inline-block;
    text-decoration: none; }
    .minicart-wrapper .action.showcart:before {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 26px;
      line-height: 26px;
      color: #3c3c3c;
      font-family: "Material Icons";
      margin: 0;
      vertical-align: top;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; }
    .minicart-wrapper .action.showcart:hover:before {
      color: #414141; }
    .minicart-wrapper .action.showcart:active:before {
      color: #3c3c3c; }
    .minicart-wrapper .action.showcart.active {
      display: inline-block;
      text-decoration: none; }
      .minicart-wrapper .action.showcart.active:before {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 26px;
        line-height: 26px;
        color: #3c3c3c;
        font-family: "Material Icons";
        margin: 0;
        vertical-align: top;
        display: inline-block;
        font-weight: normal;
        overflow: hidden;
        speak: none;
        text-align: center; }
      .minicart-wrapper .action.showcart.active:hover:before {
        color: #414141; }
      .minicart-wrapper .action.showcart.active:active:before {
        color: #3c3c3c; }
  .minicart-wrapper .block-minicart {
    margin: 0;
    padding: 0;
    list-style: none none;
    background: #fff;
    border: 1px solid #E0E6EE;
    margin-top: 4px;
    min-width: 100%;
    z-index: 100;
    box-sizing: border-box;
    display: none;
    position: absolute;
    top: 100%;
    box-shadow: none; }
    .minicart-wrapper .block-minicart li {
      margin: 0; }
      .minicart-wrapper .block-minicart li:hover {
        cursor: pointer; }
    .minicart-wrapper .block-minicart:before, .minicart-wrapper .block-minicart:after {
      border-bottom-style: solid;
      content: '';
      display: block;
      height: 0;
      position: absolute;
      width: 0; }
    .minicart-wrapper .block-minicart:before {
      border: 6px solid;
      border-color: transparent transparent #fff transparent;
      z-index: 99; }
    .minicart-wrapper .block-minicart:after {
      border: 7px solid;
      border-color: transparent transparent #bbb transparent;
      z-index: 98; }
    .minicart-wrapper .block-minicart:before {
      left: 10px;
      top: -12px; }
    .minicart-wrapper .block-minicart:after {
      left: 9px;
      top: -14px; }
  .minicart-wrapper.active {
    overflow: visible; }
    .minicart-wrapper.active .block-minicart {
      display: block; }
  .minicart-wrapper:before {
    position: relative;
    display: block;
    width: 100%;
    min-height: 4rem;
    background-image: url("../images/shopping-bag-empty.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat; }
  .minicart-wrapper .block-minicart {
    padding: 25px 20px;
    padding-bottom: 1rem;
    right: 0;
    width: 320px; }
    .minicart-wrapper .block-minicart .block-title {
      display: none; }
    .minicart-wrapper .block-minicart:after {
      left: auto;
      right: 25px; }
    .minicart-wrapper .block-minicart:before {
      left: auto;
      right: 26px; }
  .minicart-wrapper .product .actions {
    float: right;
    margin: -24px 0 0;
    text-align: right; }
    .minicart-wrapper .product .actions > .primary,
    .minicart-wrapper .product .actions > .secondary {
      display: inline; }
  .minicart-wrapper .action.close {
    display: inline-block;
    text-decoration: none;
    background-image: none;
    background: none;
    -moz-box-sizing: content-box;
    border: 0;
    box-shadow: none;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-decoration: none;
    text-shadow: none;
    font-weight: 400;
    height: 40px;
    position: absolute;
    right: 0;
    top: 0;
    width: 40px; }
    .minicart-wrapper .action.close > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .minicart-wrapper .action.close:before {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 32px;
      line-height: 32px;
      color: inherit;
      content: "";
      font-family: "Material Icons";
      margin: 0;
      vertical-align: top;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; }
    .minicart-wrapper .action.close:hover:before {
      color: inherit; }
    .minicart-wrapper .action.close:active:before {
      color: inherit; }
    .minicart-wrapper .action.close:focus, .minicart-wrapper .action.close:active {
      background: none;
      border: none; }
    .minicart-wrapper .action.close:hover {
      background: none;
      border: none; }
    .minicart-wrapper .action.close.disabled, .minicart-wrapper .action.close[disabled],
    fieldset[disabled] .minicart-wrapper .action.close {
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.5; }
  .minicart-wrapper .action.showcart {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    white-space: nowrap;
    background: none;
    border: 0;
    color: inherit; }
    .minicart-wrapper .action.showcart .text {
      display: none; }
    .minicart-wrapper .action.showcart .counter.qty {
      position: absolute;
      color: #00904a;
      font-size: 125%;
      font-weight: 600;
      border-radius: 50%;
      text-align: center; }
      .minicart-wrapper .action.showcart .counter.qty.empty {
        display: none; }
      .minicart-wrapper .action.showcart .counter.qty .loader > img {
        max-width: 24px; }
    .minicart-wrapper .action.showcart .counter-number {
      display: block;
      width: 100%;
      height: 0;
      margin-top: -4.5rem;
      text-align: center; }

.minicart-items-wrapper {
  border: 1px solid #ccc;
  margin: 0 -20px;
  border-left: 0;
  border-right: 0;
  overflow-x: auto;
  padding: 15px; }

.minicart-items {
  margin: 0;
  padding: 0;
  list-style: none none; }
  .minicart-items .product-item {
    padding: 20px 0; }
    .minicart-items .product-item:not(:first-child) {
      border-top: 1px solid #ccc; }
    .minicart-items .product-item:first-child {
      padding-top: 0; }
    .minicart-items .product-item:last-child {
      border-width: 0; }
  .minicart-items .product-item-pricing .label {
    display: inline-block;
    opacity: 0;
    width: 4.5rem; }
  .minicart-items .price-minicart {
    margin-bottom: 5px; }
  .minicart-items .product > .product-item-photo,
  .minicart-items .product > .product-image-container {
    float: left; }
  .minicart-items .product .toggle {
    display: block;
    text-decoration: none;
    cursor: pointer;
    position: relative; }
    .minicart-items .product .toggle:after {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 28px;
      line-height: 16px;
      color: #a2a2a2;
      content: "";
      font-family: "Material Icons";
      vertical-align: middle;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; }
    .minicart-items .product .toggle:after {
      position: static; }
  .minicart-items .product.active > .toggle:after {
    content: ""; }
  .minicart-items .product-item-name {
    font-weight: 400;
    margin: 0 0 10px; }
    .minicart-items .product-item-name a {
      color: #118b2d; }
  .minicart-items .product-item-details {
    padding-left: 88px; }
    .minicart-items .product-item-details .price {
      font-weight: 700; }
    .minicart-items .product-item-details .price-including-tax,
    .minicart-items .product-item-details .price-excluding-tax {
      margin: 5px 0 0; }
    .minicart-items .product-item-details .weee[data-label] {
      font-size: 11px; }
    .minicart-items .product-item-details .details-qty {
      margin-top: 10px; }
  .minicart-items .product.options .tooltip.toggle {
    display: inline-block;
    text-decoration: none; }
    .minicart-items .product.options .tooltip.toggle > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .minicart-items .product.options .tooltip.toggle:after {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 28px;
      line-height: 28px;
      color: #a2a2a2;
      content: "";
      font-family: "Material Icons";
      margin: -3px 0 0 7px;
      vertical-align: middle;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; }
    .minicart-items .product.options .tooltip.toggle .details {
      display: none; }
  .minicart-items .item-qty {
    margin-right: 10px;
    text-align: center;
    width: 40px; }
  .minicart-items .update-cart-item {
    font-size: 11px;
    vertical-align: top; }
  .minicart-items .subtitle {
    display: none; }
  .minicart-items .action.edit, .minicart-items .action.delete {
    display: inline-block;
    text-decoration: none; }
    .minicart-items .action.edit > span, .minicart-items .action.delete > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .minicart-items .action.edit:before, .minicart-items .action.delete:before {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 28px;
      line-height: 28px;
      color: #303030;
      content: "";
      font-family: "Material Icons";
      vertical-align: middle;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; }
    .minicart-items .action.edit:hover:before, .minicart-items .action.delete:hover:before {
      color: #303030; }
    .minicart-items .action.edit:active:before, .minicart-items .action.delete:active:before {
      color: #303030; }
  .minicart-items .action.delete:before {
    content: ""; }

@media only screen and (min-width: 1200px) {
  .minicart-wrapper .action.showcart {
    display: grid;
    display: -ms-grid;
    grid-template-columns: 1fr;
    -ms-grid-columns: 1fr; }
    .minicart-wrapper .action.showcart:before {
      grid-column: 1;
      -ms-grid-column: 1;
      grid-row: 1;
      -ms-grid-row: 1; }
    .minicart-wrapper .action.showcart .counter.qty {
      position: relative;
      grid-column: 1;
      -ms-grid-column: 1;
      grid-row: 1;
      -ms-grid-row: 1;
      width: 100%;
      padding-top: 1.75rem; } }

@media only screen and (max-width: 1199px) {
  .minicart-wrapper .action.showcart .counter-number {
    margin-top: -2.75rem; } }

.field-tooltip {
  cursor: pointer;
  position: absolute;
  right: -45px;
  top: 1px; }
  @media only screen and (max-width: 767px) {
    .field-tooltip {
      right: 0; } }
  .field-tooltip._active {
    z-index: 100; }
    .field-tooltip._active .field-tooltip-content {
      display: block; }
    .field-tooltip._active .field-tooltip-action:before {
      color: #118b2d; }
  .field-tooltip .field-tooltip-action {
    display: inline-block;
    text-decoration: none; }
    .field-tooltip .field-tooltip-action > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .field-tooltip .field-tooltip-action:before {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 21px;
      line-height: inherit;
      color: #bbb;
      content: "";
      font-family: "Material Icons";
      vertical-align: middle;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; }
    .field-tooltip .field-tooltip-action:hover:before {
      color: #118b2d; }
    ._keyfocus .field-tooltip .field-tooltip-action:focus {
      z-index: 100; }
      ._keyfocus .field-tooltip .field-tooltip-action:focus + .field-tooltip-content {
        display: block; }
      ._keyfocus .field-tooltip .field-tooltip-action:focus:before {
        color: #118b2d; }
  .field-tooltip .field-tooltip-content {
    background: #f4f4f4;
    border: 1px solid #999;
    border-radius: 1px;
    font-size: 14px;
    padding: 12px;
    width: 270px;
    display: none;
    left: 38px;
    position: absolute;
    text-transform: none;
    top: -9px;
    word-wrap: break-word;
    z-index: 2; }
    .field-tooltip .field-tooltip-content:before, .field-tooltip .field-tooltip-content:after {
      border: 10px solid transparent;
      height: 0;
      width: 0;
      border-right-color: #f4f4f4;
      left: -21px;
      top: 12px;
      content: '';
      display: block;
      position: absolute;
      z-index: 3; }
    .field-tooltip .field-tooltip-content:before {
      border-right-color: #666666; }
    .field-tooltip .field-tooltip-content:after {
      border-right-color: #f4f4f4;
      width: 1px;
      z-index: 4; }

@media only screen and (max-width: 479px) {
  .modal-popup .field-tooltip .field-tooltip-content {
    width: 200px; } }

.totals-tax-summary th:before {
  content: "Herav";
  display: inline-block;
  margin-right: 0.2rem; }

.totals-tax-summary th, .totals-tax-summary td {
  border-top: none; }

.checkout-klarna-index {
  overflow-x: hidden; }
  .checkout-klarna-index .nav-sections,
  .checkout-klarna-index .nav-toggle {
    display: none; }
  .checkout-klarna-index .page-header {
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
    z-index: 1; }
  .checkout-klarna-index .header.content {
    display: -ms-flexbox;
    grid-template-columns: 1fr 1fr 1fr;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    -ms-grid-rows: 1fr; }
    .checkout-klarna-index .header.content .logo {
      grid-column: 2;
      -ms-grid-column: 2;
      grid-row: 1;
      -ms-grid-row: 1; }
    .checkout-klarna-index .header.content .nav-toggle {
      display: none; }
  .checkout-klarna-index .discount-code {
    display: none; }
  .checkout-klarna-index .page-main {
    background-color: #f9f9f9;
    margin: 0;
    max-width: none;
    padding: 1rem 0; }
    .checkout-klarna-index .page-main .columns {
      max-width: 1440px;
      margin: 0 auto; }
  .checkout-klarna-index .page-title {
    text-align: center;
    font-weight: 700;
    margin: 1vw auto 2vw auto;
    width: 100%; }
  .checkout-klarna-index .step-title,
  .checkout-klarna-index .title {
    margin: 0;
    font-family: inherit;
    font-size: 2em;
    font-weight: 600;
    border: none; }
  .checkout-klarna-index .trollweb-pharmacy-picker-footer {
    max-width: 1440px;
    margin-bottom: 2rem;
    background: transparent; }
    .checkout-klarna-index .trollweb-pharmacy-picker-footer-contents {
      padding: 0rem 4rem;
      margin-bottom: 1rem; }
  .checkout-klarna-index #checkout {
    display: grid;
    display: -ms-grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr;
    -ms-grid-columns: 1fr;
    -ms-grid-rows: auto 1rem auto 1rem auto; }
    .checkout-klarna-index #checkout .opc-estimated-wrapper {
      display: none; }
    .checkout-klarna-index #checkout .messages {
      width: 100%; }
    .checkout-klarna-index #checkout #opc-shipping_method {
      grid-row: 1;
      -ms-grid-row: 1; }
    .checkout-klarna-index #checkout .opc-block-summary {
      display: none; }
    .checkout-klarna-index #checkout .opc-wrapper {
      grid-row: 2;
      -ms-grid-row: 3; }
      .checkout-klarna-index #checkout .opc-wrapper .opc {
        padding: 0;
        list-style: none; }
        .checkout-klarna-index #checkout .opc-wrapper .opc .cui__main-header h1 {
          color: #ffffff !important; }
    .checkout-klarna-index #checkout .opc-wrapper,
    .checkout-klarna-index #checkout #opc-shipping_method,
    .checkout-klarna-index #checkout .opc-block-summary {
      background-color: #ffffff;
      border-radius: 0.5rem;
      box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);
      padding: 1rem 2rem; }
    .checkout-klarna-index #checkout .table-checkout-shipping-method {
      width: 100%; }
      .checkout-klarna-index #checkout .table-checkout-shipping-method tbody {
        width: 100%;
        display: flex;
        flex-direction: column; }
        .checkout-klarna-index #checkout .table-checkout-shipping-method tbody tr.row {
          cursor: row;
          border: 1px solid #dcdcdc;
          border-radius: 0.5rem;
          padding: 1rem;
          margin: 0.5rem 0;
          display: grid;
          display: -ms-flexbox;
          align-items: center;
          grid-template-columns: 2rem auto auto auto; }
          .checkout-klarna-index #checkout .table-checkout-shipping-method tbody tr.row * {
            padding: 0;
            border: none; }
          .checkout-klarna-index #checkout .table-checkout-shipping-method tbody tr.row td.col-carrier {
            font-weight: 600;
            font-size: 1.1em;
            text-align: right; }
          .checkout-klarna-index #checkout .table-checkout-shipping-method tbody tr.row td[data-bind*="method_title"] {
            text-align: center;
            margin: 0 auto; }

.payment-option.giftcardaccount {
  display: none; }

/* Success page */
.checkout-success p {
  text-align: center;
  font-size: 18px; }

.checkout-success p:first-of-type {
  font-size: 22px;
  font-weight: 500; }

.checkout-success .ng-scope {
  max-width: 700px;
  margin: 0.5rem auto; }
  .checkout-success .ng-scope .product-item.ng-scope .qty-info {
    text-align: center; }

.checkout-success .ps-article-unauthorized {
  padding: 1.25rem; }

.checkout-success .actions-toolbar {
  max-width: 705px;
  margin: 1rem auto; }

.checkout-klarna-success .page-title {
  text-align: center;
  font-weight: 500; }

.checkout-klarna-success #registration {
  max-width: 700px;
  margin: 0 auto; }
  .checkout-klarna-success #registration p {
    font-size: 16px; }
  .checkout-klarna-success #registration .action.primary {
    margin-top: 0.5rem; }

.continue-shopping-link {
  width: auto; }
  .continue-shopping-link a {
    font-size: 1.2em;
    color: #118b2d;
    display: flex;
    align-items: center; }
    .continue-shopping-link a:hover, .continue-shopping-link a:focus {
      text-decoration: none; }
    .continue-shopping-link a:before {
      font-family: 'Material Icons';
      font-weight: normal;
      font-style: normal;
      font-size: 24px;
      /* Preferred icon size */
      display: inline-block;
      line-height: 1;
      text-transform: none;
      letter-spacing: normal;
      word-wrap: normal;
      white-space: nowrap;
      direction: ltr;
      /* Support for all WebKit browsers. */
      -webkit-font-smoothing: antialiased;
      /* Support for Safari and Chrome. */
      text-rendering: optimizeLegibility;
      /* Support for Firefox. */
      -moz-osx-font-smoothing: grayscale;
      /* Support for IE. */
      font-feature-settings: 'liga';
      content: "";
      font-size: 1.2em;
      color: #118b2d;
      margin-right: 1rem;
      vertical-align: middle; }

.authentication-link {
  grid-column: 3;
  -ms-grid-column: 3;
  order: 3; }
  .authentication-link button.action, .authentication-link .cart.table-wrapper .actions-toolbar > .action, .cart.table-wrapper .authentication-link .actions-toolbar > .action, .authentication-link .action.action-gift {
    border: 1px solid #118b2d;
    color: #118b2d;
    font-size: 1.2em;
    padding: 0.5em 2rem;
    border-radius: 5rem;
    white-space: nowrap; }
    .authentication-link button.action:hover, .authentication-link .cart.table-wrapper .actions-toolbar > .action:hover, .cart.table-wrapper .authentication-link .actions-toolbar > .action:hover, .authentication-link .action.action-gift:hover {
      text-decoration: none; }
  .authentication-link .authentication-wrapper {
    width: 100%;
    float: none;
    margin: 0 1rem 0 auto;
    text-align: right; }
  .authentication-link .authentication-dropdown button.action, .authentication-link .authentication-dropdown .cart.table-wrapper .actions-toolbar > .action, .cart.table-wrapper .authentication-link .authentication-dropdown .actions-toolbar > .action, .authentication-link .authentication-dropdown .action.action-gift {
    background-color: #118b2d;
    color: #ffffff; }
  .authentication-link .authentication-dropdown .modal-inner-wrap {
    background-color: #ffffff; }
  .authentication-link .authentication-dropdown._show {
    min-width: 70vw;
    max-width: 500px;
    width: auto;
    right: unset;
    left: 50vw !important;
    top: calc(5rem + 2vw);
    bottom: unset;
    position: fixed;
    transform: translateX(-50%); }
    .authentication-link .authentication-dropdown._show + .dropdown-overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.2); }

/* Desktop/Tablet */
@media only screen and (min-width: 1024px) {
  .checkout-klarna-index #checkout {
    max-width: 1024px;
    margin: 0 auto; }
    .checkout-klarna-index #checkout .messages {
      grid-column: 1;
      -ms-grid-column: 1; }
    .checkout-klarna-index #checkout #opc-shipping_method {
      grid-column: 1;
      -ms-grid-column: 1;
      grid-row: 1;
      -ms-grid-row: 1; }
    .checkout-klarna-index #checkout .opc-wrapper {
      grid-column: 1;
      -ms-grid-column: 1;
      grid-row: 2 / span 6;
      -ms-grid-row: 2;
      -ms-grid-row-span: 6;
      width: 100%; } }

/*Mobile */
@media only screen and (max-width: 1023px) {
  .block-authentication .block-customer-login .block-title {
    display: flex;
    justify-content: flex-start; } }

/* Small Mobile */
@media only screen and (max-width: 767px) {
  .checkout-klarna-index .authentication-link button.action, .checkout-klarna-index .authentication-link .cart.table-wrapper .actions-toolbar > .action, .cart.table-wrapper .checkout-klarna-index .authentication-link .actions-toolbar > .action, .checkout-klarna-index .authentication-link .action.action-gift {
    border: none;
    padding: 0; }
  .checkout-klarna-index .columns .column.main {
    padding: 0; }
  .checkout-klarna-index #checkout .opc-wrapper,
  .checkout-klarna-index #checkout #opc-shipping_method,
  .checkout-klarna-index #checkout .opc-block-summary {
    padding: 1rem; }
  .checkout-klarna-index #checkout .table-checkout-shipping-method tbody tr.row td[data-bind*="method_title"] {
    grid-row: 2;
    grid-column: 1 / span 4;
    text-align: center; }
  .checkout-klarna-index #checkout .table-checkout-shipping-method tbody tr.row td[data-bind*="carrier_title"] {
    grid-column: 3 / span 2; }
  .checkout-klarna-index #checkout .step-title, .checkout-klarna-index #checkout .title {
    margin: 0; } }

.opc-estimated-wrapper {
  border-bottom: 1px solid #ccc;
  margin: 0 0 15px;
  padding: 18px 15px; }
  .opc-estimated-wrapper .estimated-block {
    font-size: 18px;
    font-weight: 700;
    float: left; }
    .opc-estimated-wrapper .estimated-block .estimated-label {
      display: block;
      margin: 0 0 5px; }
  .opc-estimated-wrapper .minicart-wrapper .action.showcart:before {
    color: #118b2d; }

@media only screen and (min-width: 1024px) {
  .opc-estimated-wrapper {
    display: none; } }

.opc-progress-bar {
  margin: 0 0 20px;
  counter-reset: i;
  font-size: 0; }

.opc-progress-bar-item {
  margin: 0 0 10px;
  display: inline-block;
  position: relative;
  text-align: center;
  vertical-align: top;
  width: 50%; }
  .opc-progress-bar-item:before {
    background: #e4e4e4;
    top: 19px;
    content: '';
    height: 7px;
    left: 0;
    position: absolute;
    width: 100%; }
  .opc-progress-bar-item:first-child:before {
    border-radius: 6px 0 0 6px; }
  .opc-progress-bar-item:last-child:before {
    border-radius: 0 6px 6px 0; }
  .opc-progress-bar-item > span {
    display: inline-block;
    padding-top: 45px;
    width: 100%;
    word-wrap: break-word;
    color: #e4e4e4;
    font-weight: 300;
    font-size: 1.8rem; }
    .opc-progress-bar-item > span:before, .opc-progress-bar-item > span:after {
      background: #e4e4e4;
      height: 38px;
      margin-left: -19px;
      width: 38px;
      border-radius: 50%;
      content: '';
      left: 50%;
      position: absolute;
      top: 0; }
    .opc-progress-bar-item > span:after {
      background: #fff;
      height: 26px;
      margin-left: -13px;
      top: 6px;
      width: 26px;
      content: counter(i);
      counter-increment: i;
      color: #118b2d;
      font-weight: 600;
      font-size: 1.8rem; }
  .opc-progress-bar-item._active:before {
    background: #2196f3; }
  .opc-progress-bar-item._active > span {
    color: #118b2d; }
    .opc-progress-bar-item._active > span:before {
      background: #2196f3; }
    .opc-progress-bar-item._active > span:after {
      content: "";
      font-family: "Material Icons";
      line-height: 1;
      font-size: 2.8rem; }
  .opc-progress-bar-item._complete {
    cursor: pointer; }
    .opc-progress-bar-item._complete > span {
      color: #118b2d; }
      .opc-progress-bar-item._complete > span:after {
        content: "";
        font-family: "Material Icons";
        line-height: 1;
        font-size: 2.8rem; }

@media only screen and (min-width: 1024px) {
  .opc-progress-bar-item {
    width: 185px; } }

.field.choice .field-tooltip {
  display: inline-block;
  margin-left: 10px;
  position: relative;
  top: -3px; }

.field.choice .field-tooltip-action {
  line-height: 21px; }

.field._error .control input,
.field._error .control select,
.field._error .control textarea {
  border-color: #ed8380; }

.opc-wrapper .fieldset > .field > .label {
  font-weight: 400; }

@media only screen and (min-width: 1024px) {
  .opc-wrapper .fieldset > .field.required, .opc-wrapper .fieldset > .field._required {
    position: relative; }
    .opc-wrapper .fieldset > .field.required > label, .opc-wrapper .fieldset > .field._required > label {
      padding-right: 25px; }
      .opc-wrapper .fieldset > .field.required > label:after, .opc-wrapper .fieldset > .field._required > label:after {
        margin-left: 10px;
        position: absolute;
        top: 9px; } }

@media only screen and (min-width: 1024px) {
  .checkout-index-index .modal-popup .form-shipping-address {
    max-width: 500px; }
  .checkout-index-index .modal-popup .modal-footer .action-save-address {
    float: right;
    margin: 0 0 0 10px; } }

@media only screen and (min-width: 1200px) {
  .checkout-index-index .modal-popup .modal-inner-wrap {
    margin-left: -400px;
    width: 800px;
    left: 50%; } }

.field-tooltip {
  cursor: pointer;
  position: absolute;
  right: -45px;
  top: 1px; }
  @media only screen and (max-width: 767px) {
    .field-tooltip {
      right: 0; } }
  .field-tooltip._active {
    z-index: 100; }
    .field-tooltip._active .field-tooltip-content {
      display: block; }
    .field-tooltip._active .field-tooltip-action:before {
      color: #118b2d; }
  .field-tooltip .field-tooltip-action {
    display: inline-block;
    text-decoration: none; }
    .field-tooltip .field-tooltip-action > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .field-tooltip .field-tooltip-action:before {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 21px;
      line-height: inherit;
      color: #bbb;
      content: "";
      font-family: "Material Icons";
      vertical-align: middle;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; }
    .field-tooltip .field-tooltip-action:hover:before {
      color: #118b2d; }
    ._keyfocus .field-tooltip .field-tooltip-action:focus {
      z-index: 100; }
      ._keyfocus .field-tooltip .field-tooltip-action:focus + .field-tooltip-content {
        display: block; }
      ._keyfocus .field-tooltip .field-tooltip-action:focus:before {
        color: #118b2d; }
  .field-tooltip .field-tooltip-content {
    background: #f4f4f4;
    border: 1px solid #999;
    border-radius: 1px;
    font-size: 14px;
    padding: 12px;
    width: 270px;
    display: none;
    left: 38px;
    position: absolute;
    text-transform: none;
    top: -9px;
    word-wrap: break-word;
    z-index: 2; }
    .field-tooltip .field-tooltip-content:before, .field-tooltip .field-tooltip-content:after {
      border: 10px solid transparent;
      height: 0;
      width: 0;
      border-right-color: #f4f4f4;
      left: -21px;
      top: 12px;
      content: '';
      display: block;
      position: absolute;
      z-index: 3; }
    .field-tooltip .field-tooltip-content:before {
      border-right-color: #666666; }
    .field-tooltip .field-tooltip-content:after {
      border-right-color: #f4f4f4;
      width: 1px;
      z-index: 4; }

@media only screen and (max-width: 479px) {
  .modal-popup .field-tooltip .field-tooltip-content {
    width: 200px; } }

.opc-wrapper .form-login,
.opc-wrapper .form-shipping-address {
  margin-top: 28px;
  margin-bottom: 20px; }
  .opc-wrapper .form-login .fieldset .note,
  .opc-wrapper .form-shipping-address .fieldset .note {
    font-size: 14px;
    margin-top: 10px; }

.opc-wrapper .shipping-address-items {
  font-size: 0; }

.opc-wrapper .shipping-address-item {
  border: 2px solid transparent;
  line-height: 30px;
  margin: 0 0 20px;
  padding: 20px 35px 20px 20px;
  transition: 0.3s border-color;
  width: 50%;
  display: inline-block;
  font-size: 14px;
  position: relative;
  vertical-align: top;
  word-wrap: break-word; }
  .opc-wrapper .shipping-address-item.selected-item {
    border-color: #2196f3; }
    .opc-wrapper .shipping-address-item.selected-item:after {
      background: #2196f3;
      color: #fff;
      content: "";
      font-family: "Material Icons";
      height: 27px;
      width: 29px;
      font-size: 27px;
      line-height: 21px;
      padding-top: 2px;
      position: absolute;
      right: 0;
      text-align: center;
      top: 0; }
    .opc-wrapper .shipping-address-item.selected-item .action-select-shipping-item {
      visibility: hidden; }

.opc-wrapper .action-show-popup {
  margin: 0 0 20px; }
  .opc-wrapper .action-show-popup > span:before {
    content: '+';
    padding-right: 5px; }

.opc-wrapper .action-select-shipping-item {
  float: right;
  margin: 20px 0 0; }

.opc-wrapper .edit-address-link {
  display: block;
  float: left;
  margin: 26px 5px 0 0; }

.checkout-shipping-method .step-title {
  margin-bottom: 0; }

.checkout-shipping-method .no-quotes-block {
  margin: 20px 0; }

.methods-shipping .actions-toolbar .action.primary {
  margin: 20px 0 0; }

.table-checkout-shipping-method thead th {
  display: none; }

.table-checkout-shipping-method tbody td {
  border-top: 1px solid #ccc;
  padding-bottom: 20px;
  padding-top: 20px; }
  .table-checkout-shipping-method tbody td:first-child {
    padding-left: 0;
    padding-right: 0;
    width: 20px; }

.table-checkout-shipping-method tbody tr:first-child td {
  border-top: none; }

.table-checkout-shipping-method tbody .row-error td {
  border-top: none;
  padding-bottom: 10px;
  padding-top: 0; }

@media only screen and (max-width: 767px) {
  .opc-wrapper .form-login {
    border-bottom: 1px solid #ccc;
    margin: 0 0 15px;
    padding: 0 0 15px; }
  .opc-wrapper .shipping-address-item {
    border-bottom: 1px solid #ccc;
    margin: 0 0 15px;
    padding: 0 0 15px;
    width: 100%; }
    .opc-wrapper .shipping-address-item.selected-item {
      padding: 15px 35px 15px 18px;
      border-bottom-width: 2px; }
      .opc-wrapper .shipping-address-item.selected-item .edit-address-link {
        right: 39px; }
  .opc-wrapper .form-login,
  .opc-wrapper .form-shipping-address {
    margin-top: 28px; }
  .opc-wrapper .action-select-shipping-item {
    float: none;
    margin-top: 10px;
    width: 100%; }
  .opc-wrapper .action-show-popup {
    width: 100%; }
  .opc-wrapper .edit-address-link {
    display: inline-block;
    text-decoration: none;
    margin: 0;
    position: absolute;
    right: 0;
    top: 1px; }
    .opc-wrapper .edit-address-link > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .opc-wrapper .edit-address-link:before {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 28px;
      line-height: 28px;
      color: #303030;
      content: "";
      font-family: "Material Icons";
      vertical-align: middle;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; }
    .opc-wrapper .edit-address-link:hover:before {
      color: #303030; }
    .opc-wrapper .edit-address-link:active:before {
      color: #303030; } }

@media only screen and (min-width: 1024px) {
  .checkout-shipping-method .actions-toolbar > .primary {
    float: right; }
  .checkout-shipping-method .actions-toolbar .action.primary {
    margin: 0; }
  .opc-wrapper .form-login,
  .opc-wrapper .form-shipping-address {
    max-width: 500px; }
  .table-checkout-shipping-method {
    width: auto; } }

@media only screen and (min-width: 1200px) {
  .opc-wrapper .shipping-address-item {
    width: 33.33333%; }
    .opc-wrapper .shipping-address-item:before {
      background: #ccc;
      height: calc(100% - 20px);
      content: '';
      left: 0;
      position: absolute;
      top: 0;
      width: 1px; }
    .opc-wrapper .shipping-address-item:nth-child(3n + 1):before {
      display: none; }
    .opc-wrapper .shipping-address-item.selected-item:before {
      display: none; }
    .opc-wrapper .shipping-address-item.selected-item + .shipping-address-item:before {
      display: none; }
  .table-checkout-shipping-method {
    min-width: 500px; } }

.checkout-shipping-method {
  position: relative; }

.shipping-policy-block.field-tooltip {
  top: 12px; }
  .shipping-policy-block.field-tooltip .field-tooltip-action {
    color: #118b2d;
    cursor: pointer; }
    .shipping-policy-block.field-tooltip .field-tooltip-action:before {
      display: none; }
  .shipping-policy-block.field-tooltip .field-tooltip-content {
    width: 420px;
    top: 30px; }

@media only screen and (max-width: 767px) {
  .shipping-policy-block.field-tooltip {
    margin-bottom: 20px;
    position: relative;
    right: auto;
    top: auto; }
    .shipping-policy-block.field-tooltip .field-tooltip-content {
      width: 300px;
      right: auto; }
      .shipping-policy-block.field-tooltip .field-tooltip-content:before, .shipping-policy-block.field-tooltip .field-tooltip-content:after {
        right: auto; } }

@media only screen and (min-width: 1024px) {
  .opc-sidebar {
    margin: 46px 0 20px;
    width: 33.33333%;
    float: right;
    order: 2; } }

.opc-block-shipping-information {
  padding: 0 30px; }
  .opc-block-shipping-information .shipping-information-title {
    border-bottom: 1px solid #ccc;
    margin: 0 0 20px;
    position: relative; }
    .opc-block-shipping-information .shipping-information-title .action-edit {
      top: 2px;
      display: inline-block;
      text-decoration: none;
      margin: 0;
      position: absolute;
      right: 0; }
      .opc-block-shipping-information .shipping-information-title .action-edit > span {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px; }
      .opc-block-shipping-information .shipping-information-title .action-edit:before {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 28px;
        line-height: 28px;
        color: #3c3c3c;
        content: "";
        font-family: "Material Icons";
        vertical-align: middle;
        display: inline-block;
        font-weight: normal;
        overflow: hidden;
        speak: none;
        text-align: center; }
      .opc-block-shipping-information .shipping-information-title .action-edit:hover:before {
        color: #118b2d; }
      .opc-block-shipping-information .shipping-information-title .action-edit:active:before {
        color: #3c3c3c; }
  .opc-block-shipping-information .shipping-information-content {
    line-height: 27px; }
    .opc-block-shipping-information .shipping-information-content .actions-toolbar {
      margin-left: 0; }
  .opc-block-shipping-information .ship-to,
  .opc-block-shipping-information .ship-via {
    margin: 0 0 20px; }

.opc-block-summary {
  background: #f5f5f5;
  padding: 22px 30px;
  margin: 0 0 20px; }
  .opc-block-summary > .title {
    display: block; }
  .opc-block-summary .mark .value {
    color: #999;
    display: block; }
  .opc-block-summary .grand.incl + .grand.excl .mark,
  .opc-block-summary .grand.incl + .grand.excl .amount {
    border-top: 0;
    font-size: 1.4rem;
    padding-top: 0; }
    .opc-block-summary .grand.incl + .grand.excl .mark strong,
    .opc-block-summary .grand.incl + .grand.excl .amount strong {
      font-weight: 400; }
  .opc-block-summary .not-calculated {
    font-style: italic; }
  .opc-block-summary .items-in-cart > .title {
    border-bottom: 1px solid #E0E6EE;
    padding: 10px 40px 10px 0;
    cursor: pointer;
    display: block;
    text-decoration: none;
    margin-bottom: 0;
    position: relative; }
    .opc-block-summary .items-in-cart > .title > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .opc-block-summary .items-in-cart > .title:after {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 30px;
      line-height: 12px;
      color: #a2a2a2;
      content: "";
      font-family: "Material Icons";
      margin: 3px 0 0;
      vertical-align: middle;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; }
    .opc-block-summary .items-in-cart > .title:after {
      position: absolute;
      right: 0;
      top: 10px; }
    .opc-block-summary .items-in-cart > .title strong {
      font-size: 1.8rem;
      font-weight: 300;
      margin: 0; }
  .opc-block-summary .items-in-cart.active > .title:after {
    content: ""; }
  .opc-block-summary .items-in-cart .product {
    position: relative; }
  .opc-block-summary .minicart-items-wrapper {
    margin: -15px 0 0;
    max-height: 370px;
    padding: 15px 15px 0 0;
    border: 0; }
  .column.main .opc-block-summary .product-item {
    margin: 0;
    padding-left: 0; }
  .opc-block-summary .product-item .product-item-inner {
    display: table;
    margin: 0 0 10px;
    width: 100%; }
  .opc-block-summary .product-item .product-item-name-block {
    display: table-cell;
    padding-right: 5px;
    text-align: left; }
  .opc-block-summary .product-item .subtotal {
    display: table-cell;
    text-align: right; }
  .opc-block-summary .product-item .price {
    font-size: 1.6rem;
    font-weight: 400; }
  .opc-block-summary .product-item .price-including-tax + .price-excluding-tax {
    margin: 0; }
    .opc-block-summary .product-item .price-including-tax + .price-excluding-tax .price {
      font-size: 1rem; }
  .opc-block-summary .actions-toolbar {
    position: relative;
    z-index: 1; }
    .opc-block-summary .actions-toolbar .secondary {
      border-top: 1px solid #E0E6EE;
      display: block;
      float: none;
      margin: -1px 0 0;
      padding: 15px 0 0;
      text-align: left; }
      .opc-block-summary .actions-toolbar .secondary .action {
        margin: 0; }

@media only screen and (min-width: 1024px) {
  .opc-summary-wrapper .modal-header .action-close {
    display: none; } }

.authentication-dropdown {
  box-sizing: border-box; }
  .authentication-dropdown .modal-inner-wrap {
    padding: 25px; }

.authentication-wrapper {
  float: right;
  margin-top: -60px;
  max-width: 50%;
  position: relative;
  z-index: 1; }
  ._has-auth-shown .authentication-wrapper {
    z-index: 900; }

.block-authentication .block-title {
  font-size: 1.8rem;
  border-bottom: 0;
  margin-bottom: 25px; }
  .block-authentication .block-title strong {
    font-weight: 300; }

.block-authentication .field .label {
  font-weight: 400; }

.block-authentication .actions-toolbar {
  margin-bottom: 5px; }
  .block-authentication .actions-toolbar > .secondary {
    padding-top: 25px;
    text-align: left; }

.block-authentication .block[class] {
  margin: 0; }
  .block-authentication .block[class] ul {
    list-style: none;
    padding-left: 10px; }
  .block-authentication .block[class] .field .control,
  .block-authentication .block[class] .field .label {
    float: none;
    width: auto; }
  .block-authentication .block[class] + .block {
    border-top: 1px solid #c1c1c1;
    margin-top: 40px;
    padding-top: 40px;
    position: relative; }
    .block-authentication .block[class] + .block::before {
      height: 36px;
      line-height: 34px;
      margin: -19px 0 -18px;
      min-width: 36px;
      background: #fff;
      border: 1px solid #c1c1c1;
      border-radius: 50%;
      box-sizing: border-box;
      color: #c1c1c1;
      content: attr(data-label);
      display: inline-block;
      left: 50%;
      letter-spacing: normal;
      padding: 0 0.2rem;
      position: absolute;
      text-align: center;
      text-transform: uppercase;
      top: 0; }

@media only screen and (min-width: 1024px) {
  .authentication-dropdown {
    background-color: #fff;
    border: 1px solid #aeaeae;
    position: absolute;
    text-align: left;
    top: 100%;
    transform: scale(1, 0);
    transform-origin: 0 0;
    transition: transform linear 0.1s, visibility 0s linear 0.1s;
    visibility: hidden;
    width: 100%; }
    .authentication-dropdown._show {
      z-index: 100;
      transform: scale(1, 1);
      transition: transform linear 0.1s, visibility 0s linear 0s;
      visibility: visible; }
  .authentication-wrapper {
    width: 33.33333%;
    text-align: right; }
  .block-authentication .block-title {
    font-size: 2.6rem;
    border-bottom: 0;
    margin-bottom: 25px; }
  .block-authentication .actions-toolbar > .primary {
    display: inline;
    float: right;
    margin-right: 0; }
    .block-authentication .actions-toolbar > .primary .action {
      margin-right: 0; }
  .block-authentication .actions-toolbar > .secondary {
    float: left;
    margin-right: 2rem;
    padding-top: 1rem; }
  .popup-authentication .modal-inner-wrap {
    min-width: 1024px;
    width: 60%; }
  .popup-authentication .block-authentication {
    display: flex;
    flex-direction: row;
    border-top: 1px solid #c1c1c1; }
  .popup-authentication .block[class],
  .popup-authentication .form-login,
  .popup-authentication .fieldset,
  .popup-authentication .block-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1; }
  .popup-authentication .block[class] {
    box-sizing: border-box;
    float: left;
    padding: 10px 30px 0 0;
    width: 50%; }
    .popup-authentication .block[class] + .block {
      border-left: 1px solid #c1c1c1;
      border-top: 0;
      margin: 0;
      padding: 10px 0 0 40px; }
      .popup-authentication .block[class] + .block::before {
        left: 0;
        top: 50%; }
  .popup-authentication .actions-toolbar {
    margin-bottom: 0;
    margin-top: auto; } }

.checkout-payment-method .step-title {
  border-bottom: 0;
  margin-bottom: 0; }

.checkout-payment-method .payment-method:first-child .payment-method-title {
  border-top: 0; }

.checkout-payment-method .payment-method._active .payment-method-content {
  display: block; }

.checkout-payment-method .payment-method + .payment-method .payment-method-title {
  border-top: 1px solid #ccc; }

.checkout-payment-method .payment-method-title {
  padding: 20px 0;
  margin: 0; }
  .checkout-payment-method .payment-method-title .payment-icon {
    display: inline-block;
    margin-right: 5px;
    vertical-align: middle; }
  .checkout-payment-method .payment-method-title .action-help {
    display: inline-block;
    margin-left: 5px; }

.checkout-payment-method .payment-method-content {
  display: none;
  padding: 0 0 20px 22px; }
  .checkout-payment-method .payment-method-content .fieldset:not(:last-child) {
    margin: 0 0 20px; }

.checkout-payment-method .payment-group + .payment-group .step-title {
  margin: 20px 0 0; }

.checkout-payment-method .field-select-billing,
.checkout-payment-method .billing-address-form {
  max-width: 500px; }

.checkout-payment-method .billing-address-same-as-shipping-block {
  margin: 0 0 10px; }

.checkout-payment-method .checkout-billing-address {
  margin: 0 0 20px; }
  .checkout-payment-method .checkout-billing-address .primary .action-update {
    margin-right: 0; }
  .checkout-payment-method .checkout-billing-address .billing-address-details {
    line-height: 27px;
    padding: 0 0 0 23px; }

.checkout-payment-method .payment-method-note + .checkout-billing-address {
  margin-top: 20px; }

.checkout-payment-method .payment-method-iframe {
  background-color: transparent;
  display: none;
  width: 100%; }

.checkout-payment-method .no-payments-block {
  margin: 20px 0; }

@media only screen and (max-width: 1023px) {
  .checkout-payment-method .payment-methods {
    margin: -15px; }
  .checkout-payment-method .payment-method-title {
    padding: 15px; }
  .checkout-payment-method .payment-method-content {
    padding: 0 15px 20px; }
  .checkout-payment-method .checkout-billing-address .action-cancel {
    margin-top: 10px; } }

@media only screen and (min-width: 1024px) {
  .checkout-payment-method .actions-toolbar .primary {
    float: right;
    margin: 0; }
  .checkout-payment-method .fieldset > .field-select-billing > .control {
    float: none;
    width: 100%; }
  .checkout-payment-method .payment-method-content .fieldset > .field {
    margin: 0 0 20px; }
    .checkout-payment-method .payment-method-content .fieldset > .field.type .control {
      margin-left: 25.8%; }
    .checkout-payment-method .payment-method-content .fieldset > .field.type.no-detection .control {
      margin-left: 0; }
  .checkout-billing-address .action-update {
    float: right; }
  .checkout-billing-address .actions-toolbar .action-cancel {
    margin: 6px 20px 0 0; } }

.checkout-payment-method .payment-option._active .payment-option-title .action-toggle:after {
  content: ""; }

.checkout-payment-method .payment-option._collapsible .payment-option-title {
  cursor: pointer; }

.checkout-payment-method .payment-option._collapsible .payment-option-content {
  display: none; }

.checkout-payment-method .payment-option-title {
  border-top: 1px solid #ccc;
  padding: 20px 0; }
  .checkout-payment-method .payment-option-title .action-toggle {
    color: #118b2d;
    display: inline-block;
    text-decoration: none; }
    .checkout-payment-method .payment-option-title .action-toggle:after {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 32px;
      line-height: 16px;
      color: #3c3c3c;
      content: "";
      font-family: "Material Icons";
      margin: 0;
      vertical-align: middle;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; }
    .checkout-payment-method .payment-option-title .action-toggle:hover:after {
      color: #118b2d; }
    .checkout-payment-method .payment-option-title .action-toggle:active:after {
      color: #3c3c3c; }

.checkout-payment-method .payment-option-content {
  padding: 0 0 20px 22px; }

.checkout-payment-method .payment-option-inner {
  margin: 0 0 20px; }

.checkout-payment-method .credit-card-types {
  padding: 0; }
  .checkout-payment-method .credit-card-types .item {
    display: inline-block;
    list-style: none;
    margin: 0 5px 0 0;
    vertical-align: top; }
    .checkout-payment-method .credit-card-types .item._active {
      font-weight: 700; }
      .checkout-payment-method .credit-card-types .item._active img {
        filter: grayscale(0%); }
    .checkout-payment-method .credit-card-types .item._inactive {
      opacity: 0.4; }
    .checkout-payment-method .credit-card-types .item span {
      display: inline-block;
      padding-top: 6px;
      vertical-align: top; }
  .checkout-payment-method .credit-card-types img {
    filter: grayscale(100%);
    transition: all 0.6s ease; }

.checkout-payment-method .ccard .fields > .year {
  padding-left: 5px; }

.checkout-payment-method .ccard .fields .select {
  padding-left: 5px;
  padding-right: 5px; }

.checkout-payment-method .ccard .month .select {
  width: 140px; }

.checkout-payment-method .ccard .year .select {
  width: 80px; }

.checkout-payment-method .ccard .number .input-text {
  width: 225px; }

.checkout-payment-method .ccard > .field.cvv > .control {
  padding-right: 20px;
  width: auto; }

.checkout-payment-method .ccard .cvv .input-text {
  width: 55px; }

.checkout-payment-method .ccard.fieldset > .field .fields.group.group-2 .field {
  width: auto !important; }

@media only screen and (max-width: 1023px) {
  .checkout-payment-method .payment-option {
    margin: -15px; }
    .checkout-payment-method .payment-option .payment-option-title {
      padding: 15px; }
    .checkout-payment-method .payment-option .payment-option-content {
      padding: 0 15px 20px; } }

@media only screen and (min-width: 1024px) {
  .checkout-payment-method .payment-option-title {
    padding-left: 22px; }
  .checkout-payment-method .payment-option-content .payment-option-inner + .actions-toolbar {
    margin-left: 0; } }

.checkout-agreements-block .checkout-agreements {
  margin-bottom: 20px; }

.checkout-agreements-block .action-show {
  vertical-align: baseline; }

.checkout-agreements-items {
  padding-bottom: 30px; }
  .checkout-agreements-items .checkout-agreements-item {
    margin-bottom: 20px; }
  .checkout-agreements-items .checkout-agreements-item-title {
    border-bottom: 0; }

.checkout-agreement-item-content {
  overflow: auto; }

.email-credentials {
  width: 100%; }
  .email-credentials th {
    text-align: left; }
  .email-credentials tr + tr th,
  .email-credentials tr + tr td {
    padding-bottom: 10px; }

.email-features {
  width: 100%; }
  .email-features td {
    width: 50%; }
    .email-features td table {
      width: 100%; }
      .email-features td table td {
        padding-right: 10px;
        width: 30px; }
        .email-features td table td h3 {
          margin-bottom: 0;
          margin-top: 5px; }
        .email-features td table td p {
          margin-bottom: 0; }
      .email-features td table td + td {
        padding-right: 25px;
        width: auto; }
  .email-features tr + tr > td {
    padding-top: 20px; }

@media only screen and (max-width: 479px) {
  .email-features > tbody > tr > td {
    clear: both;
    display: block;
    padding-top: 20px;
    width: auto !important; } }

.login-container .block-new-customer .actions-toolbar {
  margin-top: 25px; }

.login-container .block .block-title {
  font-size: 18px; }

.login-container .fieldset:after {
  margin: 10px 0 0;
  content: attr(data-hasrequired);
  display: block;
  letter-spacing: normal;
  word-spacing: normal;
  color: #e02b27;
  font-size: 1.2rem; }

.login-container .fieldset:after {
  margin-top: 35px; }

.block-addresses-list .items.addresses > .item {
  margin-bottom: 20px; }
  .block-addresses-list .items.addresses > .item:last-child {
    margin-bottom: 0; }

.block-addresses-list .items.addresses .item.actions {
  margin-top: 5px; }
  .block-addresses-list .items.addresses .item.actions .action:after {
    border-left: 1px solid #90f1a6;
    content: '';
    display: inline-block;
    height: 12px;
    margin: 0 10px;
    vertical-align: -1px; }
  .block-addresses-list .items.addresses .item.actions .action:last-child:after {
    display: none; }

.block-addresses-list address {
  line-height: 24px; }

.form-address-edit #region_id {
  display: none; }

.form-edit-account .fieldset.password {
  display: none; }

.form-edit-account [data-bind="scope: 'showPassword'"] {
  display: none; }

.form-create-account .fieldset-fullname .fields .field {
  float: none; }

.box-billing-address .box-content,
.box-shipping-address .box-content,
.box-information .box-content,
.box-newsletter .box-content {
  line-height: 24px; }

.account .page-title-wrapper .page-title {
  display: inline-block; }

.account .messages {
  margin-bottom: 20px; }

.account .column.main {
  margin-bottom: 30px; }
  .account .column.main h2 {
    margin-top: 0; }

.account .sidebar-additional {
  margin-top: 40px; }

.account .table-wrapper {
  margin-bottom: 20px;
  border-bottom: 1px solid #e4e4e4; }
  .account .table-wrapper:last-child {
    margin-bottom: 0; }

.account .legend {
  border-bottom: 1px solid #c6c6c6;
  margin-bottom: 25px;
  padding-bottom: 10px; }
  .account .legend > strong,
  .account .legend > span {
    font-size: 22px;
    font-weight: 300; }

.account .actions-toolbar {
  margin-left: 0 !important;
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  bottom: 0; }
  .account .actions-toolbar:before,
  .account .actions-toolbar :after {
    display: none; }
  .account .actions-toolbar .primary {
    order: 1; }
  .account .actions-toolbar .secondary {
    order: 0; }

.account label[for="street_1"] {
  display: none; }

.account .create .actions-toolbar {
  display: block; }

.account .column.main .order-details-items .table-wrapper .data.table,
[class^='sales-guest-'] .column.main .order-details-items .table-wrapper .data.table,
.sales-guest-view .column.main .order-details-items .table-wrapper .data.table {
  border: none; }
  .account .column.main .order-details-items .table-wrapper .data.table > tbody > tr:nth-child(even) > td,
  .account .column.main .order-details-items .table-wrapper .data.table > tbody > tr:nth-child(even) > th,
  [class^='sales-guest-'] .column.main .order-details-items .table-wrapper .data.table > tbody > tr:nth-child(even) > td,
  [class^='sales-guest-'] .column.main .order-details-items .table-wrapper .data.table > tbody > tr:nth-child(even) > th,
  .sales-guest-view .column.main .order-details-items .table-wrapper .data.table > tbody > tr:nth-child(even) > td,
  .sales-guest-view .column.main .order-details-items .table-wrapper .data.table > tbody > tr:nth-child(even) > th {
    background: white; }
  .account .column.main .order-details-items .table-wrapper .data.table > thead > tr > th,
  .account .column.main .order-details-items .table-wrapper .data.table > thead > tr > td,
  .account .column.main .order-details-items .table-wrapper .data.table > tbody > tr > th,
  .account .column.main .order-details-items .table-wrapper .data.table > tbody > tr > td,
  .account .column.main .order-details-items .table-wrapper .data.table > tfoot > tr > th,
  .account .column.main .order-details-items .table-wrapper .data.table > tfoot > tr > td,
  [class^='sales-guest-'] .column.main .order-details-items .table-wrapper .data.table > thead > tr > th,
  [class^='sales-guest-'] .column.main .order-details-items .table-wrapper .data.table > thead > tr > td,
  [class^='sales-guest-'] .column.main .order-details-items .table-wrapper .data.table > tbody > tr > th,
  [class^='sales-guest-'] .column.main .order-details-items .table-wrapper .data.table > tbody > tr > td,
  [class^='sales-guest-'] .column.main .order-details-items .table-wrapper .data.table > tfoot > tr > th,
  [class^='sales-guest-'] .column.main .order-details-items .table-wrapper .data.table > tfoot > tr > td,
  .sales-guest-view .column.main .order-details-items .table-wrapper .data.table > thead > tr > th,
  .sales-guest-view .column.main .order-details-items .table-wrapper .data.table > thead > tr > td,
  .sales-guest-view .column.main .order-details-items .table-wrapper .data.table > tbody > tr > th,
  .sales-guest-view .column.main .order-details-items .table-wrapper .data.table > tbody > tr > td,
  .sales-guest-view .column.main .order-details-items .table-wrapper .data.table > tfoot > tr > th,
  .sales-guest-view .column.main .order-details-items .table-wrapper .data.table > tfoot > tr > td {
    border: none; }
  .account .column.main .order-details-items .table-wrapper .data.table > thead > tr > th,
  .account .column.main .order-details-items .table-wrapper .data.table > thead > tr > td,
  [class^='sales-guest-'] .column.main .order-details-items .table-wrapper .data.table > thead > tr > th,
  [class^='sales-guest-'] .column.main .order-details-items .table-wrapper .data.table > thead > tr > td,
  .sales-guest-view .column.main .order-details-items .table-wrapper .data.table > thead > tr > th,
  .sales-guest-view .column.main .order-details-items .table-wrapper .data.table > thead > tr > td {
    border-bottom: 1px solid #E0E6EE; }

.account .data.table .col.actions .action:after,
[class^='sales-guest-'] .data.table .col.actions .action:after,
.sales-guest-view .data.table .col.actions .action:after {
  border-left: 1px solid #90f1a6;
  content: '';
  display: inline-block;
  height: 12px;
  margin: 0 10px;
  vertical-align: -1px; }

.account .data.table .col.actions .action:last-child:after,
[class^='sales-guest-'] .data.table .col.actions .action:last-child:after,
.sales-guest-view .data.table .col.actions .action:last-child:after {
  display: none; }

.account .data.table .col.actions .action.delete,
[class^='sales-guest-'] .data.table .col.actions .action.delete,
.sales-guest-view .data.table .col.actions .action.delete {
  color: #d10029; }

.column.main .block:last-child {
  margin-bottom: 0; }

.block .title strong {
  font-weight: 700;
  line-height: 1.1;
  font-size: 1.4rem;
  margin-top: 2rem;
  margin-bottom: 2rem; }

.block p:last-child {
  margin: 0; }

.block .box-actions {
  margin-top: 5px; }
  .block .box-actions .action:after {
    border-left: 1px solid #90f1a6;
    content: '';
    display: inline-block;
    height: 12px;
    margin: 0 10px;
    vertical-align: -1px; }
  .block .box-actions .action:last-child:after {
    display: none; }

.field.password .control {
  display: flex;
  flex-direction: column; }
  .field.password .control .mage-error {
    order: 2; }
  .field.password .control .input-text {
    order: 0; }

.password-strength-meter {
  background-color: #f4f4f4;
  height: 32px;
  line-height: 32px;
  padding: 0 9px;
  position: relative;
  z-index: 1; }
  .password-strength-meter:before {
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    z-index: -1; }
  .password-strength-meter-0 .password-strength-meter:before {
    background-color: #f4f4f4;
    width: 100%; }
  .password-strength-meter-1 .password-strength-meter:before {
    background-color: #ffafae;
    width: 25%; }
  .password-strength-meter-2 .password-strength-meter:before {
    background-color: #ffd6b3;
    width: 50%; }
  .password-strength-meter-3 .password-strength-meter:before {
    background-color: #c5eeac;
    width: 75%; }
  .password-strength-meter-4 .password-strength-meter:before {
    background-color: #81b562;
    width: 100%; }

.control.captcha-image {
  margin-top: 10px; }
  .control.captcha-image .captcha-img {
    vertical-align: middle; }

.order-products-toolbar {
  position: relative; }

.block-dashboard-info .block-title,
.block-dashboard-addresses .block-title {
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2); }
  .block-dashboard-info .block-title strong,
  .block-dashboard-addresses .block-title strong {
    font-size: 22px !important; }

@media only screen and (min-width: 1024px) {
  .login-container .block.login .actions-toolbar > .primary {
    margin-bottom: 0;
    margin-right: 30px; }
  .login-container .block.login .actions-toolbar > .secondary {
    float: left; }
  .login-container .fieldset > .field > .control {
    width: 55%; }
  .form-create-account .fieldset-fullname .fields .field {
    float: left;
    margin: 0 10px 10px 0; }
  .form-create-account .fieldset-fullname .field-name-prefix,
  .form-create-account .fieldset-fullname .field-name-suffix {
    width: 50px; }
  .form.password.reset,
  .form.send.confirmation,
  .form.password.forget,
  .form.create.account {
    min-width: 600px;
    width: 50%; }
  .account.page-layout-2columns-left .sidebar-main,
  .account.page-layout-2columns-left .sidebar-additional {
    width: 280px; }
  .account.page-layout-2columns-left .column.main {
    width: calc(100% - 280px - 1rem); }
  .account .data.table {
    margin-bottom: 0; }
    .account .data.table .col.actions {
      white-space: nowrap; }
  .block-addresses-list .items.addresses > .item {
    margin-bottom: 20px; }
    .block-addresses-list .items.addresses > .item:nth-last-child(1), .block-addresses-list .items.addresses > .item:nth-last-child(2) {
      margin-bottom: 0; }
  .block-addresses-list address {
    font-size: 16px; }
  .page-main .block {
    margin-bottom: 0; }
  .form-edit-account .fieldset .fieldset {
    margin-bottom: 20px;
    width: 100%; }
  .box-billing-address .box-content,
  .box-shipping-address .box-content,
  .box-information .box-content {
    font-size: 16px; }
  .control.captcha-image .captcha-img {
    margin: 0 10px 10px 0; } }

@media only screen and (max-width: 767px) {
  .account .column.main,
  .account .sidebar-additional {
    margin: 0; }
  .account .data.table > tbody > tr > td:last-child {
    border: 0; } }

@media only screen and (max-width: 1023px) {
  .login-container .fieldset:after {
    text-align: center; }
  .account .page.messages {
    margin-bottom: 40px; }
  .account .column.main .block:not(.widget) .block-title > .action {
    float: right;
    margin-top: 5px; }
  .account .column.main .block:not(.widget) .block-content .box {
    margin-bottom: 20px; }
    .account .column.main .block:not(.widget) .block-content .box:last-child {
      margin-bottom: 0; }
  .control.captcha-image .captcha-img {
    display: block;
    margin-bottom: 10px; }
  .customer-account-index .page-title-wrapper {
    position: relative; } }

.downloadable.samples {
  margin-bottom: 20px; }
  .downloadable.samples dd {
    margin: 0 0 10px; }

.table-downloadable-products .product-name {
  margin-right: 15px; }

.field.downloads .price-container {
  display: inline;
  white-space: nowrap; }
  .field.downloads .price-container:before {
    content: ' + ';
    display: inline; }

.field.downloads .price-excluding-tax {
  display: inline; }
  .field.downloads .price-excluding-tax:before {
    content: " (" attr(data-label) ": "; }
  .field.downloads .price-excluding-tax:after {
    content: ')'; }

.field.downloads .price-including-tax {
  font-size: 1.4rem;
  display: inline;
  font-weight: 600; }

.field.downloads .sample.link {
  float: right; }

.page-product-downloadable .product-add-form {
  clear: both;
  margin-bottom: 25px; }

.page-product-downloadable .product-options-bottom .field.qty {
  display: none !important; }

@media only screen and (min-width: 1024px) {
  .page-product-downloadable .product-options-wrapper {
    float: left;
    width: 55%; }
  .page-product-downloadable .product-options-bottom {
    float: right;
    width: 40%; }
    .page-product-downloadable .product-options-bottom .field.qty + .actions {
      padding-top: 0; } }

.cart-summary .block.giftcard .action.check {
  line-height: 1.2rem;
  padding: 5px 8px;
  font-size: 1.1rem;
  margin-top: 20px; }

.page-product-giftcard .giftcard-amount {
  margin-bottom: 0;
  text-align: left; }
  .page-product-giftcard .giftcard-amount .field:last-child {
    padding-bottom: 20px; }

.page-product-giftcard .product-options-wrapper .field:first-of-type {
  margin-top: 20px; }

.giftcard-account .please-wait {
  display: none; }

.giftcard-account .form-giftcard-redeem .giftcard.info {
  margin-bottom: 30px; }

.opc-wrapper .form-giftcard-account {
  max-width: 500px; }
  .opc-wrapper .form-giftcard-account .field {
    margin: 0 0 20px; }

.opc-wrapper .giftcard-account-info {
  margin: 0 0 20px; }
  .opc-wrapper .giftcard-account-info .giftcard-account-number {
    margin: 0 0 10px; }

.gift-message .field {
  margin-bottom: 20px; }
  .gift-message .field .label {
    color: #666;
    font-weight: 400; }

.gift-options .actions-toolbar .action-cancel {
  display: none; }

.gift-options-title {
  margin: 0 0 20px; }

.gift-options-content .fieldset {
  margin: 0 0 20px; }

.gift-summary .actions-toolbar > .secondary {
  float: none; }
  .gift-summary .actions-toolbar > .secondary .action {
    margin: 10px 20px 0 0; }

.action-gift {
  display: inline-block;
  text-decoration: none;
  margin-bottom: 10px;
  margin-right: 10px;
  display: inline-block;
  text-decoration: none; }
  .action-gift:hover, .action-gift:active, .action-gift:focus {
    text-decoration: none; }
  .action-gift:last-child {
    margin-right: 0; }
  .action-gift:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 32px;
    line-height: 16px;
    color: inherit;
    content: "";
    font-family: "Material Icons";
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center; }
  .action-gift:hover:after {
    color: inherit; }
  .action-gift:active:after {
    color: inherit; }
  .action-gift.active:after, .action-gift._active:after {
    content: ""; }

.cart.table-wrapper .gift-content {
  box-sizing: border-box;
  clear: left;
  display: none;
  float: left;
  margin: 10px 0 20px;
  text-align: left;
  width: 100%; }
  .cart.table-wrapper .gift-content._active {
    display: block; }

.gift-item-block {
  margin: 0; }
  .gift-item-block._active .title:after {
    content: ""; }
  .gift-item-block .title {
    font-weight: 700;
    border-radius: 3px;
    background-image: none;
    background: #e0e6ee;
    border: 0;
    color: #118b2d;
    cursor: pointer;
    display: inline-block;
    font-family: "Roboto", Helvetica, sans-serif;
    font-weight: 400;
    margin: 20px 0 0;
    padding: 7px 15px;
    font-size: 1.4rem;
    line-height: 1.6rem;
    box-sizing: border-box;
    vertical-align: middle;
    display: inline-block;
    text-decoration: none; }
    .gift-item-block .title:after {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 32px;
      line-height: 16px;
      color: inherit;
      content: "";
      font-family: "Material Icons";
      margin: 0;
      vertical-align: top;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; }
    .gift-item-block .title:hover:after {
      color: inherit; }
    .gift-item-block .title:active:after {
      color: inherit; }
    .gift-item-block .title:focus, .gift-item-block .title:active {
      background: #d0d9e5;
      border: 0;
      color: #118b2d; }
    .gift-item-block .title:hover {
      background: #d0d9e5;
      border: 0;
      color: #555; }
    .gift-item-block .title.disabled, .gift-item-block .title[disabled],
    fieldset[disabled] .gift-item-block .title {
      opacity: 0.5;
      cursor: default;
      pointer-events: none; }
    .gift-item-block .title:active {
      box-shadow: inset 0 2px 1px rgba(0, 0, 0, 0.12); }
  .gift-item-block .content {
    border-bottom: 1px solid #c1c1c1;
    padding: 10px 10px 20px;
    position: relative; }

.order-details-items .order-gift-message .item-options dt,
.order-details-items .block-order-details-gift-message .item-options dt {
  font-weight: 400;
  margin: 10px 0; }

.order-details-items .order-gift-message dt:after,
.order-details-items .block-order-details-gift-message dt:after {
  content: ''; }

.order-details-items .order-gift-message .item-message,
.order-details-items .block-order-details-gift-message .item-message {
  clear: left; }

.order-details-items .order-items .action.show {
  display: inline-block;
  text-decoration: none;
  padding-right: 20px;
  position: relative; }
  .order-details-items .order-items .action.show:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 22px;
    line-height: inherit;
    color: #a2a2a2;
    content: "";
    font-family: "Material Icons";
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center; }
  .order-details-items .order-items .action.show:after {
    position: absolute;
    right: 0;
    top: -4px; }
  .order-details-items .order-items .action.show.expanded:after {
    content: ""; }

.block-order-details-gift-message {
  border-top: 1px solid #E0E6EE;
  padding-top: 10px; }

@media only screen and (max-width: 1023px) {
  .gift-options .actions-toolbar .action-update {
    font-size: 2rem;
    padding: 15px;
    width: 100%; }
  .gift-item-block {
    border-top: 1px solid #c1c1c1;
    border-bottom: 0; }
  .cart.table-wrapper .gift-content {
    margin-right: -10px; } }

@media only screen and (max-width: 767px) {
  .cart.table-wrapper .gift-content {
    border-bottom: 1px solid #E0E6EE;
    margin-bottom: 20px; } }

@media only screen and (min-width: 1024px) {
  .gift-message .field {
    margin-bottom: 20px; }
  .gift-options {
    position: relative;
    z-index: 1; }
    .gift-options .actions-toolbar {
      clear: both;
      float: right;
      position: static; }
      .gift-options .actions-toolbar .secondary {
        float: right; }
        .gift-options .actions-toolbar .secondary .action {
          float: right;
          margin-left: 20px; }
        .gift-options .actions-toolbar .secondary .action-cancel {
          display: block;
          float: left;
          margin-top: 6px; }
      .gift-options .actions-toolbar:nth-child(3):before {
        border-left: 1px solid #c1c1c1;
        bottom: 5rem;
        content: '';
        display: block;
        left: 50%;
        overflow: hidden;
        position: absolute;
        top: 0;
        width: 0; }
  .gift-options-title {
    font-weight: 300;
    font-size: 1.8rem; }
  .cart.table-wrapper .action-gift {
    float: left; }
  .order-options .gift-wrapping,
  .table-order-review .gift-wrapping {
    max-width: 50%; } }

.form-giftregistry-share .actions-toolbar:not(:last-child),
.form-giftregistry-edit .actions-toolbar:not(:last-child) {
  margin-bottom: 40px; }

.form-giftregistry-share .fieldset .nested .field:not(.choice) .control,
.form-giftregistry-edit .fieldset .nested .field:not(.choice) .control {
  width: 100%; }

.table-giftregistry-items .product-image {
  float: left;
  margin-right: 10px; }

.cart-summary .form.giftregistry .fieldset {
  margin: 25px 0 5px; }
  .cart-summary .form.giftregistry .fieldset .field {
    margin: 0 0 10px; }
    .cart-summary .form.giftregistry .fieldset .field .control {
      float: none;
      width: auto; }
  .cart-summary .form.giftregistry .fieldset .action.primary {
    margin-bottom: 0; }

.form-giftregistry-search {
  margin-bottom: 60px; }
  .form-giftregistry-search .fields-specific-options .field:nth-last-of-type(1) {
    margin-bottom: 0; }

.block-giftregistry-results .toolbar-giftregistry-results {
  text-align: center; }

.block-giftregistry .actions-toolbar {
  margin: 17px 0 0; }

.block-giftregistry-shared .item-options .item-value {
  margin: 0 0 25px; }

.block-giftregistry-shared-items .item {
  border-bottom: 1px solid #E0E6EE; }

.block-giftregistry-shared-items .col {
  padding: 20px 8px 10px; }
  .block-giftregistry-shared-items .col .price-box {
    display: inline-block; }
  .block-giftregistry-shared-items .col .price-wrapper {
    font-size: 1.8rem;
    display: block;
    font-weight: 700;
    white-space: nowrap; }

.block-giftregistry-shared-items .product .item-options {
  margin-bottom: 0; }

.block-giftregistry-shared-items .product-item-photo {
  display: block;
  max-width: 65px;
  padding: 10px 0; }

.block-giftregistry-shared-items .product-item-name {
  display: block;
  margin: 0 0 5px; }

@media only screen and (max-width: 767px) {
  .table-giftregistry-items .field.choice,
  .table-giftregistry-items .field.qty {
    display: inline-block; }
  .form-giftregistry-search .fieldset {
    margin-bottom: 15px; } }

@media only screen and (min-width: 1024px) {
  .form-giftregistry-search .fieldset {
    margin-bottom: 29px; }
    .form-giftregistry-search .fieldset > .fields-specific-options > .field {
      margin: 0 0 20px;
      box-sizing: border-box; }
      .form-giftregistry-search .fieldset > .fields-specific-options > .field > .label {
        margin: 0; }
      .form-giftregistry-search .fieldset > .fields-specific-options > .field:before, .form-giftregistry-search .fieldset > .fields-specific-options > .field:after {
        content: '';
        display: table; }
      .form-giftregistry-search .fieldset > .fields-specific-options > .field:after {
        clear: both; }
      .form-giftregistry-search .fieldset > .fields-specific-options > .field.choice:before, .form-giftregistry-search .fieldset > .fields-specific-options > .field.no-label:before {
        padding: 6px 15px 0 0;
        width: 25.8%;
        box-sizing: border-box;
        content: ' ';
        float: left;
        height: 1px; }
      .form-giftregistry-search .fieldset > .fields-specific-options > .field .description {
        padding: 6px 15px 0 0;
        text-align: right;
        width: 25.8%;
        box-sizing: border-box;
        float: left; }
      .form-giftregistry-search .fieldset > .fields-specific-options > .field:not(.choice) > .label {
        padding: 6px 15px 0 0;
        text-align: right;
        width: 25.8%;
        box-sizing: border-box;
        float: left; }
      .form-giftregistry-search .fieldset > .fields-specific-options > .field:not(.choice) > .control {
        width: 74.2%;
        float: left; }
  .block-giftregistry-shared-items .product-item-photo {
    display: table-cell;
    max-width: 100%;
    padding: 0 20px 0 0;
    vertical-align: top;
    width: 1%; }
  .block-giftregistry-shared-items .product-item-details {
    display: table-cell;
    vertical-align: top;
    width: 99%;
    word-break: normal; }
  .block-giftregistry-shared-items .col.product {
    width: 48%; }
  .block-giftregistry-shared-items .col:not(.product) {
    text-align: center; }
  .block-giftregistry-shared-items .col.price {
    padding-top: 17px; }
  .block-giftregistry-shared-items .input-text.qty {
    margin-top: -4px; } }

.gift-wrapping .label {
  margin: 0 0 5px;
  display: inline-block; }

.gift-wrapping .field {
  margin-bottom: 10px; }

.gift-wrapping-info {
  font-weight: 300;
  font-size: 18px;
  margin: 20px 0; }

.gift-wrapping-list {
  list-style: none;
  margin: 20px 0 10px;
  padding: 0; }
  .gift-wrapping-list .no-image {
    background: #f2f2f2;
    display: inline-block;
    text-decoration: none; }
    .gift-wrapping-list .no-image > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .gift-wrapping-list .no-image:after {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 38px;
      line-height: 4rem;
      color: #494949;
      content: "";
      font-family: "Material Icons";
      vertical-align: middle;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; }
    .gift-wrapping-list .no-image:after {
      display: block; }

.gift-wrapping-preview,
.gift-wrapping-item > span {
  cursor: pointer;
  display: block;
  overflow: hidden;
  position: relative;
  z-index: 1; }

.gift-wrapping-item {
  float: left;
  margin: 0 10px 10px 0; }
  .gift-wrapping-item:last-child {
    margin-right: 0; }
  .gift-wrapping-item._active span:before {
    border: 3px solid #333;
    box-sizing: border-box;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 2; }
  .gift-wrapping-item > span {
    height: 4rem;
    width: 4rem; }

.gift-wrapping-title {
  margin: 10px 0; }
  .gift-wrapping-title .action-remove {
    margin-left: 5px; }

.gift-options-content .fieldset > .field {
  margin: 0 0 5px; }
  .gift-options-content .fieldset > .field:before {
    display: none; }

.gift-wrapping-summary .gift-wrapping-title {
  border: 0;
  display: inline-block;
  padding: 0; }

.opc-wrapper .data.table .gift-wrapping,
.table-order-items .gift-wrapping,
.table-order-review .gift-wrapping {
  margin: 20px 0; }
  .opc-wrapper .data.table .gift-wrapping .title,
  .table-order-items .gift-wrapping .title,
  .table-order-review .gift-wrapping .title {
    float: none; }
  .opc-wrapper .data.table .gift-wrapping .content,
  .table-order-items .gift-wrapping .content,
  .table-order-review .gift-wrapping .content {
    margin: 0; }
    .opc-wrapper .data.table .gift-wrapping .content > img,
    .table-order-items .gift-wrapping .content > img,
    .table-order-review .gift-wrapping .content > img {
      float: left;
      margin: 0 10px 0 0;
      max-height: 50px;
      max-width: 50px; }

.gift-summary .price-box,
.gift-options .price-box {
  margin-left: 22px; }

.gift-summary .regular-price:before,
.gift-options .regular-price:before {
  content: attr(data-label) ": "; }

.gift-summary .regular-price .price,
.gift-options .regular-price .price {
  font-weight: 700; }

.order-options .item {
  padding-bottom: 20px; }

.order-options .product {
  margin-bottom: 10px; }

.order-options .product-image-container {
  float: left;
  padding-right: 10px; }

.order-options .gift-wrapping {
  margin-bottom: 10px; }

@media only screen and (max-width: 1023px) {
  .gift-wrapping {
    border-bottom: 1px solid #c1c1c1;
    margin-bottom: 20px;
    padding-bottom: 10px; }
  .gift-wrapping-list {
    width: 100%; }
  .gift-wrapping-preview img {
    width: 100%; }
  .item-actions .actions-toolbar .gift-options .gift-wrapping,
  .item-actions .actions-toolbar .gift-options .gift-message {
    display: block; } }

@media only screen and (min-width: 1024px) {
  .gift-options-cart-item .gift-wrapping,
  .cart-gift-item .gift-wrapping {
    box-sizing: border-box;
    float: left;
    padding-right: 20px;
    width: 50%; }
    .gift-options-cart-item .gift-wrapping + .gift-message,
    .cart-gift-item .gift-wrapping + .gift-message {
      border-left: 1px solid #c1c1c1;
      box-sizing: border-box;
      float: left;
      padding-left: 4.5rem;
      width: 50%; } }

.table.grouped > thead > tr > th,
.table.grouped > thead > tr > td,
.table.grouped > tbody > tr > th,
.table.grouped > tbody > tr > td,
.table.grouped > tfoot > tr > th,
.table.grouped > tfoot > tr > td {
  border-top: 1px solid #E0E6EE; }

.table.grouped > caption + thead > tr:first-child > th,
.table.grouped > caption + thead > tr:first-child > td,
.table.grouped > colgroup + thead > tr:first-child > th,
.table.grouped > colgroup + thead > tr:first-child > td,
.table.grouped > thead:first-child > tr:first-child > th,
.table.grouped > thead:first-child > tr:first-child > td {
  border-top: 0; }

.table.grouped > tbody + tbody {
  border-top: 1px solid #E0E6EE; }

.table.grouped > thead > tr > th {
  border-bottom: 0; }

.table.grouped .product-item-name {
  font-weight: 400; }

.table.grouped .price-box .price {
  font-size: 14px; }

.table.grouped .stock.unavailable {
  font-weight: normal;
  margin: 0;
  text-transform: none;
  white-space: nowrap; }

.table.grouped .row-tier-price td {
  border-top: 0; }

.table.grouped .row-tier-price .prices-tier {
  margin: 0 -10px 10px; }

.form-add-invitations .action.add {
  display: inline-block;
  text-decoration: none;
  line-height: normal;
  padding: 2px 0;
  width: auto; }
  .form-add-invitations .action.add > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
  .form-add-invitations .action.add:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 31px;
    line-height: 22px;
    color: inherit;
    content: "";
    font-family: "Material Icons";
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center; }
  .form-add-invitations .action.add:hover:before {
    color: inherit; }
  .form-add-invitations .action.add:active:before {
    color: inherit; }

@media only screen and (max-width: 1023px) {
  .form-add-invitations .additional,
  .form-add-invitations .field.text {
    margin-top: 20px; } }

@media only screen and (min-width: 1024px) {
  .form-add-invitations .additional,
  .form-add-invitations .field.text {
    margin-top: 29px; }
  .table-invitations .col {
    width: 50%; } }

.filter-title strong {
  position: unset; }
  .filter-title strong:before {
    display: none; }

.filter-current .items {
  display: flex;
  flex-wrap: wrap; }

.filter-current .item {
  padding-left: unset;
  z-index: unset; }
  .filter-current .item a {
    display: flex;
    border: 1px solid #474747;
    border-radius: 15px;
    padding: 0.19rem 0.25rem 0.25rem 0.35rem;
    align-items: center;
    position: relative;
    z-index: 1;
    background: #fff;
    box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.75); }
    .filter-current .item a:before {
      margin-left: 1rem;
      order: 1; }
    .filter-current .item a .remove-label {
      padding: 0 30px 0 10px; }

.filter-current .action.remove {
  margin-right: 0.5rem;
  left: unset;
  position: unset;
  top: unset; }
  .filter-current .action.remove:hover:before {
    color: #fff; }
  .filter-current .action.remove:before {
    content: '\E5CD';
    position: absolute;
    right: 4px;
    top: 3px;
    font-size: 12px;
    line-height: 12px;
    margin: 0;
    text-align: center;
    background-color: #474747;
    border-radius: 50%;
    padding: 0.25rem;
    color: white;
    font-weight: 900; }

.filter-actions {
  display: none; }

.filter-label {
  display: none; }

.filter-options-item {
  background: #ededed;
  border: none;
  padding-bottom: unset; }
  .filter-options-item.active .filter-options-content {
    display: block; }

.filter-options[data-value="stormberg_size"] {
  background: red; }

.filter-options-title {
  align-items: center;
  font-size: 16px;
  padding: 10px;
  text-transform: unset;
  background: grey;
  color: #ffffff;
  border: 1px solid grey;
  border-bottom: 0;
  display: flex;
  text-decoration: none; }
  .filter-options-title:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 24px;
    line-height: inherit;
    color: #a2a2a2;
    content: "";
    font-family: "Material Icons";
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center; }
  .filter-options-title:after {
    position: unset;
    right: unset;
    top: unset;
    margin-left: auto;
    line-height: 1;
    color: #ffffff; }
  .filter-options-title:hover {
    color: #f2f2f2; }
  .active > .filter-options-title:after {
    content: ""; }

.filter-options-content {
  display: none; }
  .filter-options-content a {
    margin-left: unset;
    margin-right: unset;
    padding-left: 10px; }
  .filter-options-content .am_shopby_slider_container {
    margin: 1.5rem 2rem 0 1rem; }
    .filter-options-content .am_shopby_slider_container .ui-slider-horizontal {
      height: 4px;
      background: #0072a0; }
      .filter-options-content .am_shopby_slider_container .ui-slider-horizontal .ui-slider-handle {
        background: #0072a0;
        height: 20px;
        width: 20px;
        margin-top: -8px; }
    .filter-options-content .am_shopby_slider_container .am_shopby_slider_display {
      margin: .5rem 0 0 0;
      text-align: center; }
  .filter-options-content .swatch-option.color,
  .filter-options-content .swatch-option.image {
    width: 100%;
    max-width: 100%;
    height: 100%;
    min-height: 75px;
    max-height: 100%;
    background-size: cover !important;
    align-items: flex-end;
    border-radius: 0;
    padding: 0;
    margin: 0 2px;
    width: calc(100% - 4px); }
    .filter-options-content .swatch-option.color .label,
    .filter-options-content .swatch-option.image .label {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center; }

@media only screen and (max-width: 1023px) {
  body.filter-active .page-header {
    display: unset; }
  body.filter-active .page-wrapper {
    margin-top: unset;
    visibility: unset; }
  body.filter-active .filter-title strong {
    font-weight: bold; }
  .filter-title strong {
    height: 40px;
    background-color: #ffffff;
    border: 1px solid #474747;
    border-bottom: 2px solid;
    border-radius: 4px; }
  .filter.active .filter-options-item {
    border: 0;
    margin-bottom: 0; }
  .filter.active .filter-options-title {
    padding: 12px 10px;
    border-bottom: 1px solid #fff;
    background: grey; }
  .filter.active .filter-options-content {
    border-top: 1px solid #0072a0;
    border-bottom: 1px solid #0072a0;
    background: rgba(6, 57, 80, 0.2); }
  .filter.active .filter-title {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #8a8a8a;
    width: 85%;
    background-color: #fff;
    height: 50px;
    z-index: 15; }
    .filter.active .filter-title strong {
      position: static;
      width: 100%;
      left: unset;
      right: unset;
      top: unset;
      display: flex;
      text-decoration: none; }
      .filter.active .filter-title strong:after {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 16px;
        line-height: inherit;
        color: #47e96c;
        content: "";
        font-family: "Material Icons";
        vertical-align: middle;
        display: inline-block;
        font-weight: normal;
        overflow: hidden;
        speak: none;
        text-align: center; }
      .filter.active .filter-title strong:hover:after {
        color: #47e96c; }
      .filter.active .filter-title strong:active:after {
        color: #47e96c; }
      .filter.active .filter-title strong:after {
        margin: 0px 0 0 auto;
        font-size: 28px;
        line-height: 28px; }
      .filter.active .filter-title strong:before {
        display: none; }
  .filter.active .filter-subtitle {
    display: none; }
  .filter.active .filter-options {
    background: #f7f7f7;
    width: 85%;
    padding: 0;
    box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.5); }
    .filter.active .filter-options-title {
      padding: 10px;
      color: #ffffff;
      border: none; }
      .filter.active .filter-options-title:after {
        color: #ffffff; }
  .filter .filter-current {
    border: none;
    margin: unset; }
    .filter .filter-current .items {
      display: unset;
      padding: 1rem 0; }
    .filter .filter-current-subtitle {
      display: none;
      text-decoration: none; }
      .filter .filter-current-subtitle:before {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 13px;
        line-height: inherit;
        color: #a2a2a2;
        content: "";
        font-family: "Material Icons";
        vertical-align: middle;
        display: inline-block;
        font-weight: normal;
        overflow: hidden;
        speak: none;
        text-align: center; }
    .filter .filter-current.active .items {
      display: flex; }
  .filter-no-options .filter-title {
    display: none; } }

@media only screen and (max-width: 1023px) and (min-width: 1024px) {
  -content .item {
    margin: 5px 0;
    padding: 0 3px; }
  -subtitle {
    display: unset;
    position: static; } }

@media only screen and (max-width: 1023px) {
  .page-layout-1column .filter-options-item {
    margin: 5px; }
  .page-layout-1column .filter-options-title {
    padding: 10px; }
  .page-layout-1column .filter-options-content {
    left: 0; } }

/**
*
* DESKTOP STORMBERG SIDEBAR
*
*/
@media only screen and (min-width: 1200px) {
  .page-layout-2columns-left .column.main {
    width: 75%;
    float: right;
    order: 2; }
  .page-layout-2columns-left .sidebar-main {
    width: 25%;
    float: left;
    order: 1; }
  .filter-options-content {
    padding: 1rem 0; }
    .filter-options-content .swatch-option-link-layered {
      width: 20%;
      padding: 0; }
    .filter-options-content .swatch-option {
      box-shadow: inset 0 0 0 1px #474747;
      margin: 0 2px;
      padding: 0; }
      .filter-options-content .swatch-option.text {
        min-height: 50px;
        align-items: center; }
      .filter-options-content .swatch-option.selected .label {
        margin: 2px; }
      .filter-options-content .swatch-option .label {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 12px;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 1px;
        text-align: center;
        padding: 0;
        display: flex;
        justify-content: center; }
        .filter-options-content .swatch-option .label span {
          background: #fff;
          padding: 0 4px;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis; }
      .filter-options-content .swatch-option .swatch-option.color, .filter-options-content .swatch-option .swatch-option.image {
        width: 100%;
        max-width: 100%;
        height: 100%;
        min-height: 75px;
        max-height: 100%;
        background-size: cover !important;
        align-items: flex-end;
        border-radius: 0;
        padding: 0;
        margin: 0; }
        .filter-options-content .swatch-option .swatch-option.color .label, .filter-options-content .swatch-option .swatch-option.image .label {
          width: 100%;
          background: #ffffff;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: center; } }

.map-popup-wrapper.popup .action.close {
  top: 10px; }

.map-popup {
  background: #fff;
  border: 1px solid #aeaeae;
  padding: 22px;
  width: 100%;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
  transition: opacity 0.3s linear;
  left: 0;
  margin-top: 10px;
  position: absolute;
  top: 0;
  z-index: 1001;
  display: none;
  opacity: 0;
  box-sizing: border-box; }
  .map-popup .popup-header {
    margin: 0 0 25px;
    padding-right: 22px; }
    .map-popup .popup-header .title {
      font-weight: 300;
      line-height: 1.1;
      font-size: 1.8rem;
      margin-top: 1.5rem;
      margin-bottom: 1rem; }
  .map-popup .popup-content {
    margin: 10px 0 0; }
  .map-popup .popup-actions .action.close {
    position: absolute;
    display: inline-block;
    text-decoration: none;
    background-image: none;
    background: none;
    -moz-box-sizing: content-box;
    border: 0;
    box-shadow: none;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-decoration: none;
    text-shadow: none;
    font-weight: 400;
    right: 10px;
    top: 10px; }
    .map-popup .popup-actions .action.close > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .map-popup .popup-actions .action.close:before {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 22px;
      line-height: 22px;
      color: inherit;
      content: "";
      font-family: "Material Icons";
      margin: 0;
      vertical-align: top;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; }
    .map-popup .popup-actions .action.close:hover:before {
      color: inherit; }
    .map-popup .popup-actions .action.close:active:before {
      color: inherit; }
    .map-popup .popup-actions .action.close:focus, .map-popup .popup-actions .action.close:active {
      background: none;
      border: none; }
    .map-popup .popup-actions .action.close:hover {
      background: none;
      border: none; }
    .map-popup .popup-actions .action.close.disabled, .map-popup .popup-actions .action.close[disabled],
    fieldset[disabled] .map-popup .popup-actions .action.close {
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.5; }
  .map-popup.active {
    opacity: 1; }
  .map-popup .map-info-price {
    margin-bottom: 15px; }
    .map-popup .map-info-price .price-box {
      margin: 0 0 20px; }
      .map-popup .map-info-price .price-box .label:after {
        content: ': '; }
  .map-popup .regular-price .price-label {
    display: none; }

.map-old-price:not(:last-child),
.product-info-price .map-show-info:not(:last-child) {
  margin-right: 10px; }

.map-old-price {
  text-decoration: none; }
  .map-old-price .price-wrapper {
    text-decoration: line-through; }

.map-form-addtocart {
  display: inline-block;
  text-align: center; }
  .map-form-addtocart img {
    display: block;
    margin: 0 auto; }

button.map-show-info, .cart.table-wrapper .actions-toolbar > .map-show-info.action, .map-show-info.action-gift {
  background-image: none;
  background: none;
  -moz-box-sizing: content-box;
  border: 0;
  box-shadow: none;
  line-height: inherit;
  margin: 0;
  padding: 0;
  text-decoration: none;
  text-shadow: none;
  font-weight: 400;
  line-height: 1.42857;
  margin: 0;
  padding: 0;
  color: #118b2d;
  text-decoration: none;
  background: none;
  border: 0;
  display: inline; }
  button.map-show-info:focus, .cart.table-wrapper .actions-toolbar > .map-show-info.action:focus, .map-show-info.action-gift:focus, button.map-show-info:active, .cart.table-wrapper .actions-toolbar > .map-show-info.action:active, .map-show-info.action-gift:active {
    background: none;
    border: none; }
  button.map-show-info:hover, .cart.table-wrapper .actions-toolbar > .map-show-info.action:hover, .map-show-info.action-gift:hover {
    background: none;
    border: none; }
  button.map-show-info.disabled, .cart.table-wrapper .actions-toolbar > .map-show-info.disabled.action, .map-show-info.disabled.action-gift, button.map-show-info[disabled], .cart.table-wrapper .actions-toolbar > .map-show-info.action[disabled], .map-show-info.action-gift[disabled],
  fieldset[disabled] button.map-show-info,
  fieldset[disabled] .cart.table-wrapper .actions-toolbar > .map-show-info.action,
  .cart.table-wrapper fieldset[disabled] .actions-toolbar > .map-show-info.action,
  fieldset[disabled] .map-show-info.action-gift {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5; }
  button.map-show-info:visited, .cart.table-wrapper .actions-toolbar > .map-show-info.action:visited, .map-show-info.action-gift:visited {
    color: #118b2d;
    text-decoration: none; }
  button.map-show-info:hover, .cart.table-wrapper .actions-toolbar > .map-show-info.action:hover, .map-show-info.action-gift:hover {
    color: #0e7426;
    text-decoration: underline; }
  button.map-show-info:active, .cart.table-wrapper .actions-toolbar > .map-show-info.action:active, .map-show-info.action-gift:active {
    color: #2196f3;
    text-decoration: underline; }
  button.map-show-info:hover, .cart.table-wrapper .actions-toolbar > .map-show-info.action:hover, .map-show-info.action-gift:hover {
    color: #0e7426; }
  button.map-show-info:hover, .cart.table-wrapper .actions-toolbar > .map-show-info.action:hover, .map-show-info.action-gift:hover, button.map-show-info:active, .cart.table-wrapper .actions-toolbar > .map-show-info.action:active, .map-show-info.action-gift:active, button.map-show-info:focus, .cart.table-wrapper .actions-toolbar > .map-show-info.action:focus, .map-show-info.action-gift:focus {
    background: none;
    border: 0; }
  button.map-show-info.disabled, .cart.table-wrapper .actions-toolbar > .map-show-info.disabled.action, .map-show-info.disabled.action-gift, button.map-show-info[disabled], .cart.table-wrapper .actions-toolbar > .map-show-info.action[disabled], .map-show-info.action-gift[disabled],
  fieldset[disabled] button.map-show-info,
  fieldset[disabled] .cart.table-wrapper .actions-toolbar > .map-show-info.action,
  .cart.table-wrapper fieldset[disabled] .actions-toolbar > .map-show-info.action,
  fieldset[disabled] .map-show-info.action-gift {
    color: #118b2d;
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
    text-decoration: underline; }

@media only screen and (max-width: 1023px) {
  .map-popup {
    max-width: 100%; }
  .map-old-price,
  .map-show-info {
    display: inline-block; }
    .map-old-price:not(:last-child),
    .map-show-info:not(:last-child) {
      margin-bottom: 10px; } }

.wishlist.split.button {
  display: inline-block;
  position: relative;
  vertical-align: middle; }
  .wishlist.split.button:before, .wishlist.split.button:after {
    content: '';
    display: table; }
  .wishlist.split.button:after {
    clear: both; }
  .wishlist.split.button .action.split {
    float: left;
    margin: 0; }
  .wishlist.split.button .action.toggle {
    float: right;
    margin: 0; }
  .wishlist.split.button button.action.split, .wishlist.split.button .cart.table-wrapper .actions-toolbar > .action.split, .cart.table-wrapper .wishlist.split.button .actions-toolbar > .action.split, .wishlist.split.button .action.split.action-gift {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0; }
  .wishlist.split.button button + .action.toggle, .wishlist.split.button .cart.table-wrapper .actions-toolbar > .action + .action.toggle, .cart.table-wrapper .wishlist.split.button .actions-toolbar > .action + .action.toggle, .wishlist.split.button .action-gift + .action.toggle {
    border-bottom-left-radius: 0;
    border-left: 0;
    border-top-left-radius: 0; }
  .wishlist.split.button .action.toggle {
    display: inline-block;
    text-decoration: none; }
    .wishlist.split.button .action.toggle > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .wishlist.split.button .action.toggle:after {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 22px;
      line-height: 22px;
      color: #5e5e5e;
      content: "";
      font-family: "Material Icons";
      margin: 0;
      vertical-align: top;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; }
    .wishlist.split.button .action.toggle:hover:after {
      color: #6b6b6b; }
    .wishlist.split.button .action.toggle:active:after {
      color: inherit; }
    .wishlist.split.button .action.toggle.active {
      display: inline-block;
      text-decoration: none; }
      .wishlist.split.button .action.toggle.active > span {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px; }
      .wishlist.split.button .action.toggle.active:after {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 22px;
        line-height: 22px;
        color: #5e5e5e;
        content: "";
        font-family: "Material Icons";
        margin: 0;
        vertical-align: top;
        display: inline-block;
        font-weight: normal;
        overflow: hidden;
        speak: none;
        text-align: center; }
      .wishlist.split.button .action.toggle.active:hover:after {
        color: #6b6b6b; }
      .wishlist.split.button .action.toggle.active:active:after {
        color: inherit; }
  .wishlist.split.button .items {
    margin: 0;
    padding: 0;
    list-style: none none;
    background: #fff;
    border: 1px solid #bbb;
    margin-top: 4px;
    min-width: 200px;
    z-index: 100;
    box-sizing: border-box;
    display: none;
    position: absolute;
    top: 100%;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15); }
    .wishlist.split.button .items li {
      margin: 0;
      padding: 5px 5px 5px 23px; }
      .wishlist.split.button .items li:hover {
        background: #e8e8e8;
        cursor: pointer; }
    .wishlist.split.button .items:before, .wishlist.split.button .items:after {
      border-bottom-style: solid;
      content: '';
      display: block;
      height: 0;
      position: absolute;
      width: 0; }
    .wishlist.split.button .items:before {
      border: 6px solid;
      border-color: transparent transparent #fff transparent;
      z-index: 99; }
    .wishlist.split.button .items:after {
      border: 7px solid;
      border-color: transparent transparent #bbb transparent;
      z-index: 98; }
    .wishlist.split.button .items:before {
      left: 10px;
      top: -12px; }
    .wishlist.split.button .items:after {
      left: 9px;
      top: -14px; }
  .wishlist.split.button.active {
    overflow: visible; }
    .wishlist.split.button.active .items {
      display: block; }
  .wishlist.split.button .items {
    text-align: left; }
    .wishlist.split.button .items .item:last-child:hover {
      background: #e8e8e8; }
  .table-comparison .wishlist.split.button > .action.split:before,
  .product-items .wishlist.split.button > .action.split:before {
    content: ""; }
  .table-comparison .wishlist.split.button > .action:active, .table-comparison .wishlist.split.button > .action:focus, .table-comparison .wishlist.split.button > .action:hover,
  .product-items .wishlist.split.button > .action:active,
  .product-items .wishlist.split.button > .action:focus,
  .product-items .wishlist.split.button > .action:hover {
    color: #6b6b6b; }
  .table-comparison .wishlist.split.button > .action.toggle:before,
  .product-items .wishlist.split.button > .action.toggle:before {
    border-left: 1px solid #ababab;
    content: '';
    float: left;
    height: 15px;
    margin-top: 3px; }
  .product-info-main .wishlist.split.button .action.split, .product-info-main .wishlist.split.button .action.toggle,
  .block-bundle-summary .wishlist.split.button .action.split,
  .block-bundle-summary .wishlist.split.button .action.toggle,
  .product-add-form .wishlist.split.button .action.split,
  .product-add-form .wishlist.split.button .action.toggle {
    line-height: 1.2rem;
    padding: 5px 8px;
    font-size: 1.1rem; }
  .product-info-main .wishlist.split.button .action.toggle,
  .block-bundle-summary .wishlist.split.button .action.toggle,
  .product-add-form .wishlist.split.button .action.toggle {
    padding: 0; }
  .cart.table-wrapper .wishlist.split.button {
    margin-bottom: 10px; }
    .cart.table-wrapper .wishlist.split.button > .action.toggle {
      padding: 4px 5px; }

.wishlist.window.popup {
  box-sizing: border-box;
  background: #fff;
  border: 1px solid #aeaeae;
  padding: 22px;
  width: 100%;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
  transition: opacity 0.3s linear;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1001;
  display: none;
  opacity: 0; }
  .wishlist.window.popup .popup-header {
    margin: 0 0 25px;
    padding-right: 22px; }
    .wishlist.window.popup .popup-header .title {
      font-weight: 300;
      line-height: 1.1;
      font-size: 1.8rem;
      margin-top: 1.5rem;
      margin-bottom: 1rem; }
  .wishlist.window.popup .popup-actions .action.close {
    position: absolute;
    display: inline-block;
    text-decoration: none;
    background-image: none;
    background: none;
    -moz-box-sizing: content-box;
    border: 0;
    box-shadow: none;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-decoration: none;
    text-shadow: none;
    font-weight: 400;
    right: 10px;
    top: 10px; }
    .wishlist.window.popup .popup-actions .action.close > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .wishlist.window.popup .popup-actions .action.close:before {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 22px;
      line-height: 22px;
      color: inherit;
      content: "";
      font-family: "Material Icons";
      margin: 0;
      vertical-align: top;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; }
    .wishlist.window.popup .popup-actions .action.close:hover:before {
      color: inherit; }
    .wishlist.window.popup .popup-actions .action.close:active:before {
      color: inherit; }
    .wishlist.window.popup .popup-actions .action.close:focus, .wishlist.window.popup .popup-actions .action.close:active {
      background: none;
      border: none; }
    .wishlist.window.popup .popup-actions .action.close:hover {
      background: none;
      border: none; }
    .wishlist.window.popup .popup-actions .action.close.disabled, .wishlist.window.popup .popup-actions .action.close[disabled],
    fieldset[disabled] .wishlist.window.popup .popup-actions .action.close {
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.5; }
  .wishlist.window.popup.active {
    opacity: 1; }
  .wishlist.window.popup.active {
    display: block; }

.wishlist.overlay {
  transition: opacity 0.15s linear;
  background: #000;
  z-index: 1000;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0; }
  .wishlist.overlay.active {
    opacity: 0.5;
    filter: alpha(opacity=50); }
  .wishlist.overlay.active {
    display: block; }

.block-wishlist-management {
  position: relative; }
  .block-wishlist-management .wishlist-select .wishlist-name-label {
    font-weight: 600; }
  .block-wishlist-management .wishlist-title {
    margin-bottom: 10px; }
    .block-wishlist-management .wishlist-title strong {
      font-weight: 300;
      margin-right: 10px; }
  .block-wishlist-management .wishlist-notice {
    color: #47e96c;
    display: inline-block;
    margin-right: 20px; }
  .block-wishlist-management .wishlist-toolbar-select, .block-wishlist-management .wishlist-toolbar-actions {
    display: inline-block; }
  .block-wishlist-management .wishlist-toolbar-select {
    margin-right: 20px; }
  .block-wishlist-management .wishlist-dropdown.move {
    margin-right: 20px; }

.block-wishlist-search-form .form-wishlist-search {
  margin-bottom: 60px;
  max-width: 500px; }

.block-wishlist-search-results .toolbar-wishlist-results {
  text-align: center; }

.block-wishlist-info-items .block-title {
  margin-bottom: 20px;
  font-size: 2.2rem; }
  .block-wishlist-info-items .block-title > strong {
    font-weight: 300; }

.block-wishlist-info-items .product-item {
  width: 100%; }

.block-wishlist-info-items .product-item-photo {
  margin-left: 20px; }

.block-wishlist-info-items .product-item-info {
  width: auto; }
  .block-wishlist-info-items .product-item-info:hover .split {
    visibility: visible; }

.block-wishlist-info-items .price-wrapper {
  display: block;
  margin: 0 0 10px; }

.block-wishlist-info-items .split {
  clear: both;
  padding: 10px 0; }

.block-wishlist-info-items .action.towishlist {
  padding: 10px 0; }

.block-wishlist-info-items .input-text.qty {
  margin-right: 10px; }

@media only screen and (max-width: 1023px) {
  .block-wishlist-management {
    clear: both;
    margin-top: -21px; }
    .block-wishlist-management .wishlist-select {
      margin: 0 -15px 20px; }
      .block-wishlist-management .wishlist-select-items {
        border-bottom: 1px solid #E0E6EE;
        background: #f5f5f5;
        display: none;
        padding: 15px 0; }
        .block-wishlist-management .wishlist-select-items.active {
          display: block; }
        .block-wishlist-management .wishlist-select-items .item {
          margin: 3px 0 0; }
          .block-wishlist-management .wishlist-select-items .item:first-child {
            margin-top: 0; }
          .block-wishlist-management .wishlist-select-items .item.current {
            display: none; }
          .block-wishlist-management .wishlist-select-items .item a {
            display: block;
            padding: 5px 18px;
            text-decoration: none; }
            .block-wishlist-management .wishlist-select-items .item a:hover {
              background: #e8e8e8; }
    .block-wishlist-management .wishlist-title strong {
      font-size: 2.6rem; }
    .block-wishlist-management .wishlist-info {
      margin-bottom: 15px; }
    .block-wishlist-management .wishlist-toolbar-select, .block-wishlist-management .wishlist-toolbar-actions {
      margin-bottom: 15px; }
  .products-grid.wishlist .product-item-checkbox {
    left: 20px;
    position: absolute;
    top: 20px; }
  .page-multiple-wishlist .products-grid.wishlist .product-item-photo {
    margin-left: 25px; }
  .page-multiple-wishlist .products-grid.wishlist .product-item-name, .page-multiple-wishlist .products-grid.wishlist .product-item-description, .page-multiple-wishlist
  .products-grid.wishlist .product-item .price-box, .page-multiple-wishlist .products-grid.wishlist .product-item-tooltip {
    margin-left: 115px; }
  .products-grid.wishlist .wishlist-dropdown {
    display: none; } }

@media only screen and (min-width: 1024px) {
  .wishlist.window.popup {
    bottom: auto;
    top: 20%;
    left: 50%;
    margin-left: -212px;
    width: 380px;
    right: auto; }
    .wishlist.window.popup .field {
      margin: 0 0 20px; }
      .wishlist.window.popup .field:not(.choice) > .label {
        box-sizing: content-box;
        float: none;
        width: auto;
        text-align: left;
        padding: 0; }
      .wishlist.window.popup .field:not(.choice) > .control {
        float: none;
        width: auto; }
      .wishlist.window.popup .field > .label {
        margin: 0 0 5px;
        display: inline-block; }
      .wishlist.window.popup .field.choice:before, .wishlist.window.popup .field.no-label:before {
        display: none; }
  .block-wishlist-management {
    margin-bottom: 20px; }
    .block-wishlist-management .wishlist-select {
      border-bottom: 1px solid #999;
      display: table;
      margin-bottom: 15px;
      width: 100%; }
      .block-wishlist-management .wishlist-select .wishlist-name {
        display: table-cell;
        margin-right: 10px;
        padding: 5px 10px 10px;
        vertical-align: top;
        white-space: nowrap;
        width: 5%; }
      .block-wishlist-management .wishlist-select-items {
        display: table-cell;
        padding-right: 160px;
        vertical-align: top; }
        .block-wishlist-management .wishlist-select-items .item {
          display: inline-block;
          margin-right: 10px;
          padding: 5px 10px 10px; }
          .block-wishlist-management .wishlist-select-items .item:last-child {
            margin-right: 0; }
        .block-wishlist-management .wishlist-select-items .current {
          border-bottom: 3px solid #2196f3;
          font-weight: 600; }
      .block-wishlist-management .wishlist-select .wishlist-name-current {
        display: none; }
    .block-wishlist-management .wishlist-add.item {
      position: absolute;
      right: 0;
      top: 0; }
    .block-wishlist-management .wishlist-title strong {
      font-size: 4rem; }
    .block-wishlist-management .wishlist-info {
      float: left; }
    .block-wishlist-management .wishlist-toolbar {
      float: right; }
  .block-wishlist-info-items .product-item-photo {
    margin-left: 0; }
  .products-grid.wishlist .product-item-checkbox {
    float: left; }
    .products-grid.wishlist .product-item-checkbox + .product-item-name {
      margin-left: 25px; } }

.multicheckout .title {
  margin-bottom: 30px; }
  .multicheckout .title strong {
    font-weight: 400; }

.multicheckout .table-wrapper {
  margin-bottom: 0; }
  .multicheckout .table-wrapper .action.delete {
    display: inline-block; }
  .multicheckout .table-wrapper .col .qty {
    display: inline-block; }
  .multicheckout .table-wrapper .col.item .action.edit {
    font-weight: 400;
    margin-left: 10px; }

.multicheckout:not(.address) .table-wrapper .product-item-name {
  margin: 0; }

.multicheckout > .actions-toolbar {
  margin-top: 40px; }

.multicheckout .actions-toolbar > .secondary {
  display: block; }
  .multicheckout .actions-toolbar > .secondary .action {
    margin-bottom: 25px; }
    .multicheckout .actions-toolbar > .secondary .action.back {
      display: block;
      margin-left: 0; }

.multicheckout .actions-toolbar > .primary {
  margin-right: 10px; }

.multicheckout .item-options {
  margin: 10px 0 0; }

.multicheckout .block .methods-shipping .item-content .fieldset > .field:before {
  display: none; }

.multicheckout .block .methods-shipping .item-content .fieldset > .field .control {
  display: inline-block; }

.multicheckout .block-title,
.multicheckout .block-content .title {
  border-bottom: 1px solid #E0E6EE;
  padding-bottom: 10px; }
  .multicheckout .block-title strong,
  .multicheckout .block-content .title strong {
    font-weight: 400; }
    .multicheckout .block-title strong span,
    .multicheckout .block-content .title strong span {
      color: #90f1a6; }

.multicheckout .block-content .title {
  border-bottom: none;
  padding-bottom: 0; }

.multicheckout.order-review .block-title > strong {
  font-size: 2.4rem; }

.multicheckout.order-review .block-shipping .block-content:not(:last-child) {
  margin-bottom: 40px; }

.multicheckout .box-title span {
  margin-right: 10px; }

.multicheckout .box-title > .action {
  margin: 0; }

.multicheckout .box-shipping-method .price {
  font-weight: 700; }

.multicheckout .box-billing-method .fieldset {
  margin: 0; }
  .multicheckout .box-billing-method .fieldset .legend.box-title {
    margin: 0 0 5px; }

.multicheckout .checkout-review .grand.totals {
  font-size: 2.1rem;
  margin-bottom: 40px; }
  .multicheckout .checkout-review .grand.totals .mark {
    font-weight: 400; }

[class^='multishipping-'] .logo {
  margin-left: 0; }

.multishipping-checkout-success .nav-sections {
  display: block; }

@media only screen and (max-width: 767px) {
  .multicheckout .data.table .address:before {
    margin-bottom: 5px; }
  .multicheckout .product-item-name,
  .multicheckout .price-including-tax,
  .multicheckout .price-excluding-tax {
    display: inline-block; }
  .multicheckout .block-content .box:not(:last-child) {
    margin-bottom: 40px; }
  .multicheckout .block-content .box:last-child {
    margin-bottom: 0; }
  .multicheckout.order-review .box-items .data.table thead {
    display: block; }
    .multicheckout.order-review .box-items .data.table thead tr {
      display: block; }
    .multicheckout.order-review .box-items .data.table thead .col.item {
      display: block;
      padding: 0; }
  .multicheckout .actions-toolbar .action {
    margin-bottom: 25px; }
  .multicheckout .actions-toolbar > .primary {
    margin-bottom: 25px;
    margin-right: 0; } }

@media only screen and (min-width: 768px) {
  .multicheckout .actions-toolbar .secondary {
    float: none;
    margin-top: 11px;
    text-align: right; }
    .multicheckout .actions-toolbar .secondary .action {
      margin-left: 10px; }
      .multicheckout .actions-toolbar .secondary .action.back {
        display: block;
        float: left; }
  .multicheckout .item-options {
    margin: 20px 0 0; }
  .multicheckout .block-content .box {
    margin-bottom: 0; }
  .multicheckout .block-shipping .box {
    float: left;
    width: 25%; }
  .multicheckout .block-shipping .box-shipping-method {
    padding-left: 25px;
    padding-right: 25px;
    width: 50%; }
    .multicheckout .block-shipping .box-shipping-method .fieldset .field:before {
      display: none; }
  .multicheckout .block-billing .box-billing-address {
    float: left;
    width: 25%; }
  .multicheckout .block-billing .box-billing-method {
    float: left;
    padding-left: 25px;
    width: 50%; }
  .multicheckout.form.address .table-wrapper .applicable {
    margin: 7px 0 0; }
  .multicheckout.order-review .box-items {
    clear: left;
    float: none;
    padding-top: 40px;
    width: auto; }
  .multicheckout.order-review .col.item {
    width: 75%; }
  .multicheckout .methods-payment .item-content > .fieldset {
    width: auto; }
    .multicheckout .methods-payment .item-content > .fieldset .field.cvv {
      display: inline-block;
      width: auto; }
  .multicheckout .methods-payment .fieldset > .field:not(.choice) > .label {
    float: none;
    margin-bottom: 8px;
    text-align: left;
    width: auto; }
  .multicheckout .methods-payment .fieldset > .field:not(.choice):not(.cvv) .control {
    width: 100%; } }

@media only screen and (max-width: 1023px) {
  .multishipping-checkout-success .nav-toggle {
    display: block; }
  .multishipping-checkout-success .logo {
    margin-left: 40px; } }

.block.newsletter {
  margin-bottom: 40px; }
  .block.newsletter .form.subscribe {
    display: table;
    width: 100%; }
  .block.newsletter .fieldset {
    display: table-cell;
    margin: 0;
    padding: 0;
    vertical-align: top; }
  .block.newsletter .field {
    margin: 0; }
    .block.newsletter .field .control {
      display: inline-block;
      text-decoration: none;
      display: block; }
      .block.newsletter .field .control:before {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 35px;
        line-height: 33px;
        color: #c2c2c2;
        content: "";
        font-family: "Material Icons";
        vertical-align: middle;
        display: inline-block;
        font-weight: normal;
        overflow: hidden;
        speak: none;
        text-align: center; }
      .block.newsletter .field .control:before {
        position: absolute; }
  .block.newsletter input {
    padding-left: 35px; }
  .block.newsletter .title {
    display: none; }
  .block.newsletter .actions {
    display: table-cell;
    vertical-align: top;
    width: 1%; }
  .block.newsletter .action.subscribe {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    margin-left: -1px; }

@media only screen and (min-width: 1024px) {
  .block.newsletter {
    width: 32%; }
    .block.newsletter .field {
      margin-right: 5px; }
      .block.newsletter .field .control {
        width: 100%; }
    .block.newsletter .action.subscribe {
      border-radius: 3px; } }

.paypal.after:before {
  content: "- " attr(data-label) " -";
  display: block;
  margin: 5px 5px 10px;
  text-transform: uppercase; }

.paypal.before:before {
  content: "- " attr(data-label) " -";
  display: block;
  margin: 10px 5px;
  text-align: center;
  text-transform: uppercase; }

.paypal.acceptance {
  display: block;
  margin: 0 0 20px; }
  .paypal.acceptance img {
    max-width: 100%; }

.box-tocart .paypal img,
.block-minicart .paypal img {
  display: block;
  margin: 0 auto; }

.paypal-review .paypa-review-title > strong {
  font-weight: 300;
  line-height: 1.1;
  font-size: 1.8rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  display: inline-block; }

.paypal-review .items-qty .item {
  white-space: nowrap; }

.paypal-review .items-qty .title:after {
  content: ': '; }

.paypal-review .paypal-review-title > strong {
  font-weight: 300;
  line-height: 1.1;
  font-size: 1.8rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  display: inline-block; }

.paypal-review .actions-toolbar {
  margin-top: 10px; }

.paypal-review .item-options dt {
  display: inline-block; }
  .paypal-review .item-options dt:after {
    content: ': '; }

.paypal-review .item-options dd {
  margin: 0; }

.paypal-review-discount {
  border-bottom: 1px solid #E0E6EE;
  margin: 20px 0; }
  .paypal-review-discount .block {
    margin-bottom: 0; }
    .paypal-review-discount .block .fieldset {
      padding: 10px 0 20px; }

@media only screen and (min-width: 1024px) {
  .paypal-review .paypal-review-title {
    border-bottom: 1px solid #E0E6EE; }
  .paypal-review .block-content .box-order-shipping-address,
  .paypal-review .block-content .box-order-shipping-method,
  .paypal-review .block-content .box-order-shipping-method + .box-order-billing-address {
    box-sizing: border-box;
    float: left;
    width: 33%; }
  .paypal-review .block-content .box-order-shipping-address {
    padding: 0 5%;
    width: 34%; }
  .paypal-review .col.subtotal,
  .paypal-review .mark,
  .paypal-review .amount {
    text-align: right; } }

.fotorama-video-container:after {
  background: url(../Magento_ProductVideo/img/gallery-sprite.png) bottom right;
  bottom: 0;
  content: '';
  height: 100px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 12px;
  width: 100px; }

.fotorama-video-container .magnify-lens {
  display: none !important; }

.fotorama-video-container.video-unplayed:hover img {
  opacity: 0.6; }

.fotorama-video-container.video-unplayed:hover:after {
  transform: scale(1.25); }

.video-thumb-icon:after {
  background: url(../Magento_ProductVideo/img/gallery-sprite.png) bottom left;
  bottom: 0;
  content: '';
  height: 40px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 10px;
  width: 49px; }

.product-video {
  bottom: 0;
  height: 75%;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%; }
  .product-video iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 9999; }

.fotorama__stage__shaft:focus .fotorama__stage__frame.fotorama__active:after {
  bottom: 0;
  content: '';
  height: 100px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 12px;
  width: 100px; }

.fotorama__product-video--loading:after {
  visibility: hidden; }

@media only screen and (min-device-width: 320px) and (max-device-width: 780px) and (orientation: landscape) {
  .product-video {
    height: 100%;
    width: 81%; } }

.rating-summary {
  overflow: hidden;
  white-space: nowrap; }
  .rating-summary .rating-result {
    width: 68px;
    display: inline-block;
    position: relative;
    vertical-align: middle; }
    .rating-summary .rating-result:before {
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
      -webkit-font-smoothing: antialiased;
      color: #F0F0F0;
      font-family: "Material Icons";
      font-size: 16px;
      height: 16px;
      letter-spacing: -3px;
      line-height: 16px;
      content: "";
      display: block;
      font-style: normal;
      font-weight: normal;
      speak: none; }
    .rating-summary .rating-result > span {
      display: block;
      overflow: hidden; }
      .rating-summary .rating-result > span:before {
        position: relative;
        z-index: 2;
        -webkit-font-smoothing: antialiased;
        color: #FFF305;
        font-family: "Material Icons";
        font-size: 16px;
        height: 16px;
        letter-spacing: -3px;
        line-height: 16px;
        content: "";
        display: block;
        font-style: normal;
        font-weight: normal;
        speak: none; }
      .rating-summary .rating-result > span span {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px; }
  .rating-summary .rating-result {
    margin-left: -5px; }

.product-reviews-summary .rating-summary .label,
.table-reviews .rating-summary .label {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.review-control-vote {
  overflow: hidden; }
  .review-control-vote:before {
    color: #F0F0F0;
    font-family: "Material Icons";
    font-size: 16px;
    height: 16px;
    letter-spacing: -3px;
    line-height: 16px;
    font-style: normal;
    font-weight: normal;
    speak: none;
    vertical-align: top;
    -webkit-font-smoothing: antialiased;
    content: "";
    display: block;
    position: absolute;
    z-index: 1; }
  .review-control-vote input[type="radio"] {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
    .review-control-vote input[type="radio"]:focus + label:before, .review-control-vote input[type="radio"]:checked + label:before {
      opacity: 1; }
  .review-control-vote label {
    cursor: pointer;
    display: block;
    position: absolute; }
    .review-control-vote label span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .review-control-vote label:before {
      color: #FFF305;
      font-family: "Material Icons";
      font-size: 16px;
      height: 16px;
      letter-spacing: -3px;
      line-height: 16px;
      font-style: normal;
      font-weight: normal;
      speak: none;
      vertical-align: top;
      -webkit-font-smoothing: antialiased;
      opacity: 0; }
    .review-control-vote label:hover:before {
      opacity: 1; }
    .review-control-vote label:hover ~ label:before {
      opacity: 0; }
  .review-control-vote .rating-5 {
    z-index: 2; }
    .review-control-vote .rating-5:before {
      content: ""; }
  .review-control-vote .rating-4 {
    z-index: 3; }
    .review-control-vote .rating-4:before {
      content: ""; }
  .review-control-vote .rating-3 {
    z-index: 4; }
    .review-control-vote .rating-3:before {
      content: ""; }
  .review-control-vote .rating-2 {
    z-index: 5; }
    .review-control-vote .rating-2:before {
      content: ""; }
  .review-control-vote .rating-1 {
    z-index: 6; }
    .review-control-vote .rating-1:before {
      content: ""; }

.block.add.review {
  margin-bottom: 40px; }
  .block.add.review > .title {
    font-weight: 300;
    line-height: 1.1;
    font-size: 2.6rem;
    margin-top: 2.5rem;
    margin-bottom: 2rem; }
  .block.add.review .legend {
    font-weight: 300;
    line-height: 1.1;
    font-size: 1.8rem;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    margin: 0 0 20px; }
  .block.add.review .field.rating {
    display: table-row; }
    .block.add.review .field.rating .label {
      display: table-cell;
      padding: 5px 0;
      vertical-align: middle; }
    .block.add.review .field.rating .control {
      display: table-cell;
      vertical-align: top; }

.block.reviews.list > .title {
  font-weight: 300;
  line-height: 1.1;
  font-size: 2.6rem;
  margin-top: 2.5rem;
  margin-bottom: 2rem; }

.block.reviews.list .review.item {
  margin-bottom: 40px; }

.block.reviews.list .review.title {
  font-weight: 300;
  line-height: 1.1;
  font-size: 1.8rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem; }

.data.table.reviews .rating-summary {
  margin-top: -4px; }

.customer-review.view {
  margin-bottom: 20px; }
  .customer-review.view .review.title,
  .customer-review.view .ratings-summary,
  .customer-review.view .product-details {
    margin-bottom: 20px; }

.product-reviews-summary {
  display: table; }
  .products.wrapper.list .product-reviews-summary {
    margin: 0 auto; }
  .product-reviews-summary.empty {
    margin-left: 0; }
  .product-reviews-summary .rating-summary {
    display: table-cell;
    left: -5px;
    position: relative;
    text-align: left;
    vertical-align: middle; }
  .product-reviews-summary .reviews-actions {
    display: table-cell;
    line-height: 16px;
    vertical-align: middle; }
    .product-reviews-summary .reviews-actions a {
      margin-right: 5px; }

@media only screen and (min-width: 1024px) {
  .products.wrapper.list .product-reviews-summary {
    margin: 0; } }

.customer-review .product-details {
  margin-bottom: 40px; }

.customer-review .product-media {
  float: left;
  margin-right: 3%;
  max-width: 285px;
  width: 30%; }

.customer-review .review-details .customer-review-rating {
  margin-bottom: 20px; }
  .customer-review .review-details .customer-review-rating .item {
    margin-bottom: 10px; }
    .customer-review .review-details .customer-review-rating .item:last-child {
      margin-bottom: 0; }

.customer-review .review-details .review-title {
  font-weight: 300;
  line-height: 1.1;
  font-size: 1.8rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  font-weight: 600;
  margin-bottom: 20px; }

.customer-review .review-details .review-content {
  margin-bottom: 20px; }

.customer-review .product-reviews-summary .rating-summary,
.customer-review .product-reviews-summary .reviews-actions {
  display: block; }

.review-field-ratings .nested {
  display: table; }

.review-field-ratings .review-field-rating {
  display: table-row; }
  .review-field-ratings .review-field-rating .label,
  .review-field-ratings .review-field-rating .control {
    display: table-cell;
    padding-bottom: 10px;
    vertical-align: top; }
  .review-field-ratings .review-field-rating .label {
    padding-right: 10px;
    padding-top: 6px; }
  .review-field-ratings .review-field-rating .control {
    padding-top: 6px; }

.review-toolbar {
  border-top: 1px solid #c9c9c9;
  margin: 0 0 20px;
  text-align: center; }
  .review-toolbar:first-child {
    display: none; }
  .review-toolbar .pages {
    border-bottom: 1px solid #c9c9c9;
    display: block;
    padding: 20px 0;
    text-align: center; }
  .review-toolbar .limiter {
    display: none; }

.review-add .block-title {
  display: none; }

.review-form .action.submit.primary {
  background: #e0e6ee;
  border: 0;
  color: #118b2d; }
  .review-form .action.submit.primary:focus, .review-form .action.submit.primary:active {
    background: #d0d9e5;
    border: 0;
    color: #118b2d; }
  .review-form .action.submit.primary:hover {
    background: #d0d9e5;
    border: 0;
    color: #555; }

.fieldset .review-legend.legend {
  margin-bottom: 20px; }
  .fieldset .review-legend.legend span {
    display: inline-block;
    white-space: nowrap;
    width: 0;
    vertical-align: top; }
  .fieldset .review-legend.legend strong {
    display: inline-block;
    padding-left: 11rem; }

.review-field-rating .control {
  margin-bottom: 48px;
  margin-top: 10px; }

.review-list {
  margin-bottom: 30px; }
  .review-list .block-title strong {
    font-weight: 300;
    line-height: 1.1;
    font-size: 2.6rem;
    margin-top: 2.5rem;
    margin-bottom: 2rem; }

.review-item {
  border-bottom: 1px solid #c9c9c9;
  margin: 0;
  padding: 20px 0; }
  .review-item:after {
    clear: both;
    content: '';
    display: table; }
  .review-item:last-child {
    border-width: 0; }

.review-ratings {
  display: table;
  margin-bottom: 10px;
  max-width: 100%; }

.review-author {
  display: inline; }

.review-title {
  font-weight: 300;
  line-height: 1.1;
  font-size: 1.8rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  margin: 0 0 20px; }

.review-content {
  margin-bottom: 20px; }

@media only screen and (min-width: 1024px) {
  .review-form {
    max-width: 500px; }
  .review-ratings {
    float: left;
    margin-bottom: 0;
    max-width: 240px; }
  .review-ratings ~ .review-content,
  .review-ratings ~ .review-details {
    margin-left: 280px; }
  .review-toolbar {
    margin: 0 0 30px; }
    .review-toolbar .pages {
      padding: 30px 0; }
  .fieldset .review-legend.legend {
    margin-bottom: 30px; }
  .review-item {
    padding: 30px 0; }
  .review-title {
    margin: 0 0 30px; } }

.block-reward-info .reward-rates:not(:last-child),
.block-reward-info .reward-limit:not(:last-child),
.block-reward-info .reward-expiring:not(:last-child) {
  margin-bottom: 25px; }

.block-reward-info .reward-rates .title,
.block-reward-info .reward-limit .title,
.block-reward-info .reward-expiring .title {
  display: inline-block;
  margin-bottom: 10px; }

@media only screen and (max-width: 1023px) {
  .reward-settings + .actions-toolbar {
    margin-top: 10px; } }

@media only screen and (min-width: 1024px) {
  .reward-settings + .actions-toolbar {
    margin-top: -32px; } }

.form-create-return .field .control > span,
.form-create-return .field address {
  display: inline-block;
  padding-top: 6px; }

.form-create-return .additional:first-child {
  margin-top: 0; }

.form-create-return .additional .field:nth-last-child(2) {
  margin-bottom: 0; }

.form-create-return .additional .field:last-child {
  margin-top: 20px; }

.form-create-return .field.comment {
  margin-top: 55px; }

.block-returns-comments .returns-comments dt,
.block-returns-comments .returns-comments dd {
  margin: 0; }

.block-returns-comments .returns-comments dt {
  font-weight: 400; }

.block-returns-comments .returns-comments dd {
  margin-bottom: 15px; }
  .block-returns-comments .returns-comments dd:last-child {
    margin-bottom: 0; }

.block-returns-comments .fieldset {
  margin-bottom: 20px; }

.magento-rma-returns-returns .page-title-wrapper .page-title,
.magento-rma-guest-returns .page-title-wrapper .page-title,
.magento-rma-returns-view .page-title-wrapper .page-title {
  margin-right: 25px; }

@media only screen and (max-width: 1023px) {
  .account .column.main .returns-details-items .block-returns-tracking .block-title > .action.track {
    display: block;
    float: none;
    margin: 10px 0 0; } }

@media only screen and (min-width: 1024px) {
  .form-create-return .additional .field:last-child {
    margin-top: 29px; }
  .magento-rma-guest-returns .column.main .block:not(.widget) .block-content .box {
    margin-bottom: 20px; }
  .block-returns-tracking .block-title .action {
    margin: 0 0 0 30px; }
  .block-returns-tracking .block-title .actions-track {
    float: right;
    margin-top: 12px; } }

.order-links {
  border-bottom: 1px solid #E0E6EE;
  margin-bottom: 10px; }
  .order-links .item {
    display: inline-block;
    margin-right: 20px; }

.order-actions-toolbar .action {
  margin: 0 20px 0 0; }

.order-details-items {
  border-bottom: 1px solid #E0E6EE;
  margin-bottom: 20px;
  padding-bottom: 10px; }
  .order-details-items .order-title > strong {
    font-weight: 300;
    line-height: 1.1;
    font-size: 1.8rem;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    display: inline-block; }
  .order-details-items .items-qty .item {
    white-space: nowrap; }
  .order-details-items .items-qty .title:after {
    content: ': '; }
  .order-details-items .table-order-items .product-item-name {
    margin-top: 0; }
  .order-details-items .table-order-items tbody + tbody {
    border-top: 1px solid #E0E6EE; }
  .order-details-items .item-options dt {
    margin: 0; }
  .order-details-items .item-options dd {
    margin: 0 0 15px; }
  .order-details-items .item-options.links dt {
    display: inline-block; }
    .order-details-items .item-options.links dt:after {
      content: ': '; }
  .order-details-items .item-options.links dd {
    margin: 0; }

.order-pager-wrapper .toolbar-amount {
  padding-top: 3px; }

.order-pager-wrapper .pages-items .item:first-child, .order-pager-wrapper .pages-items .item:last-child {
  margin-right: 0; }

.order-actions-toolbar {
  margin-bottom: 25px; }
  .order-actions-toolbar .action {
    margin-right: 30px; }
    .order-actions-toolbar .action.print {
      margin: 0; }

.page-title-wrapper .order-date {
  margin: 0 0 20px; }

.account .page-title-wrapper .page-title,
[class^='sales-guest-'] .page-title-wrapper .page-title,
.sales-guest-view .page-title-wrapper .page-title {
  margin-right: 25px; }

.form-orders-search .field.email, .form-orders-search .field.zip {
  margin-bottom: 0; }

.block-reorder .product-item .checkbox {
  position: relative;
  top: 2px;
  vertical-align: baseline; }

.block-reorder .product-item .field.item {
  display: inline-block; }

.block-reorder .product-item-name {
  display: inline-block; }

.block-reorder .actions-toolbar {
  margin: 17px 0;
  max-width: 178px; }

.block-order-details-comments {
  margin: 0 0 40px; }
  .block-order-details-comments .comment-date {
    font-weight: 600; }
  .block-order-details-comments .comment-content {
    line-height: 1.6;
    margin: 0 0 20px; }

.block-order-details-view .box-content .payment-method .title {
  font-weight: 400; }

.block-order-details-view .box-content .payment-method .content {
  margin: 0; }
  .block-order-details-view .box-content .payment-method .content > strong {
    font-weight: 400; }
    .block-order-details-view .box-content .payment-method .content > strong:after {
      content: ': '; }

.order-tracking {
  border-bottom: 1px solid #E0E6EE;
  margin: 0;
  padding: 20px 0; }
  .order-tracking .tracking-title {
    display: inline-block; }
  .order-tracking .tracking-content {
    display: inline-block;
    margin: 0 0 0 5px; }

@media only screen and (max-width: 767px) {
  .account .order-details-items .table-order-items .product-item-name,
  .account .order-details-items .table-order-items .price-including-tax,
  .account .order-details-items .table-order-items .price-excluding-tax,
  .account .order-details-items .table-order-items .items-qty {
    display: inline-block;
    vertical-align: top; }
  .account .toolbar .pages {
    float: right; }
  .account .toolbar .limiter {
    clear: both; }
  .order-details-items thead {
    display: block; }
    .order-details-items thead tr,
    .order-details-items thead td {
      display: block; }
  .table-wrapper .table.table-order-items tfoot td.order-pager-wrapper {
    width: 100%; } }

@media only screen and (max-width: 1023px) {
  .order-pager-wrapper .toolbar-amount {
    left: inherit;
    position: relative;
    text-align: center;
    top: inherit; }
  .order-pager-wrapper .pages {
    text-align: center; }
  .order-pager-wrapper .action.previous,
  .order-pager-wrapper .action.next {
    margin: 0; } }

@media only screen and (min-width: 1024px) {
  .table-order-items .subtotal,
  .table-order-items .amount {
    text-align: right; }
  .table-order-items.creditmemo .col.qty, .table-order-items.creditmemo .col.discount, .table-order-items.creditmemo .col.subtotal {
    text-align: center; }
  .table-order-items.creditmemo .col.total {
    text-align: right; }
  .order-pager-wrapper .order-pager-wrapper-top {
    padding-left: 0;
    padding-right: 0; }
  .order-pager-wrapper .pages {
    float: right; }
  .order-actions-toolbar .action.print {
    display: block;
    float: right; }
  .page-title-wrapper .order-date {
    margin-top: -20px; }
  [class^='sales-guest-'] .column.main .block:not(.widget) .block-content .box,
  .sales-guest-view .column.main .block:not(.widget) .block-content .box {
    margin-bottom: 20px; } }

.opc-wrapper .form-discount {
  max-width: 500px; }

.action.mailto.friend {
  margin-left: -7px;
  display: inline-block;
  text-decoration: none; }
  .action.mailto.friend:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 28px;
    line-height: 28px;
    color: #a2a2a2;
    content: "";
    font-family: "Material Icons";
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center; }
  .action.mailto.friend span {
    display: inline-block;
    line-height: 28px;
    vertical-align: top; }

.clearfix:after {
  content: '';
  visibility: hidden;
  display: block;
  height: 0;
  clear: both; }

.swatch-attribute-label {
  font-weight: bold;
  position: relative; }
  .swatch-attribute-label.required {
    padding-right: 10px; }
  .swatch-attribute-label[data-required="1"]::after {
    content: '*';
    color: red;
    position: absolute;
    right: -11px;
    top: -2px;
    font-weight: bold;
    font-size: 1em; }

.swatch-attribute-selected-option {
  color: #646464;
  padding-left: 17px; }

.swatch-attribute-options {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px; }

.swatch-option {
  padding: 1px 2px;
  min-width: 30px;
  max-width: 90px;
  height: 20px;
  margin: 0 10px 5px 0;
  text-align: center;
  cursor: pointer;
  position: relative;
  border: 1px solid #dadada;
  overflow: hidden;
  text-overflow: ellipsis; }
  .swatch-option.text {
    box-sizing: content-box;
    background: #f9f9f9;
    border: 1px solid #f0f0f0;
    color: #7f7f7f;
    font-size: 12px;
    font-weight: 700;
    line-height: 20px;
    padding: 4px 4px;
    min-width: 22px;
    margin-right: 7px;
    border-radius: 2px; }
  .swatch-option.selected {
    border: 3px solid #118b2d;
    color: #333; }
  .swatch-option.text.selected {
    background-color: #fff; }
  .swatch-option:not(.disabled):hover {
    border: 1px solid #f0f0f0;
    color: #333; }
  .swatch-option.color, .swatch-option.image {
    width: 24px;
    height: 24px;
    width: 24px;
    min-width: 24px;
    max-width: 24px;
    height: 24px;
    max-height: 24px;
    min-height: 24px;
    border: 2px solid #dadada;
    border-radius: 50%; }
    .swatch-option.color.selected, .swatch-option.image.selected {
      border: 3px solid #118b2d;
      color: #333; }
    .swatch-option.color:not(.disabled):hover, .swatch-option.image:not(.disabled):hover {
      outline: 0;
      border: 2px solid #dadada;
      opacity: 0.8; }
  .swatch-option.disabled {
    cursor: default; }
    .swatch-option.disabled::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: linear-gradient(to left top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 42%, white 43%, white 46%, #ff5216 47%, #ff5216 53%, white 54%, white 57%, rgba(255, 255, 255, 0) 58%, rgba(255, 255, 255, 0) 100%); }

.swatch-attribute.manufacturer .swatch-option, .swatch-attribute.size .swatch-option {
  background: #ffffff;
  color: #949494; }
  .swatch-attribute.manufacturer .swatch-option.selected, .swatch-attribute.size .swatch-option.selected {
    color: black;
    background: #f3f3f3;
    border: 1px solid #f0f0f0; }

.swatch-option-tooltip {
  display: none;
  z-index: 999;
  max-width: 140px;
  max-height: 100%;
  min-height: 20px;
  min-width: 20px;
  position: absolute;
  padding: 5px;
  color: #949494;
  text-align: center; }

.swatch-option-tooltip .corner,
.swatch-option-tooltip-layered .corner {
  left: 40%;
  position: absolute;
  bottom: -1rem;
  height: 8px; }
  .swatch-option-tooltip .corner::before,
  .swatch-option-tooltip-layered .corner::before {
    content: '';
    position: relative;
    top: 2px;
    left: 2px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 8.5px 0 8.5px;
    border-color: #e0e0e0 transparent transparent transparent;
    font-size: 1px; }
  .swatch-option-tooltip .corner::after,
  .swatch-option-tooltip-layered .corner::after {
    content: '';
    position: relative;
    top: 1px;
    left: -14px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 7px 7.5px 0 7.5px;
    border-color: #fff transparent transparent transparent;
    font-size: 1px; }

.swatch-option-tooltip .image,
.swatch-option-tooltip-layered .image {
  display: block;
  position: relative;
  bottom: -0.75rem;
  height: 64px;
  width: 64px;
  border-radius: 50%;
  border: 5px solid #f0f0f0;
  margin: 0 auto; }

.swatch-option-tooltip .title {
  display: block;
  position: relative;
  bottom: -19px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  padding: 0.5rem 1rem;
  overflow: hidden;
  max-width: 140px;
  min-height: 20px;
  max-height: 200px;
  color: #282828;
  text-align: center; }

.swatch-opt {
  margin: 20px 0; }

.swatch-more {
  display: inline-block;
  margin: 2px 0;
  text-decoration: none !important;
  position: static;
  z-index: 1; }

.swatch-option-link-layered {
  position: relative;
  margin: 0 !important;
  padding: 0 !important; }

.swatch-option-tooltip-layered {
  width: 140px;
  position: absolute;
  background: #fff;
  color: #949494;
  border: 1px solid #adadad;
  display: none;
  z-index: 999;
  left: -47px; }

.swatch-visual-tooltip-layered {
  height: 160px;
  top: -170px; }

.swatch-textual-tooltip-layered {
  height: 30px;
  top: -40px; }

.swatch-option-link-layered:hover > .swatch-option-tooltip-layered {
  display: block; }

.swatch-attribute-options .swatch-option-tooltip-layered .title {
  width: 100%;
  height: 20px;
  position: absolute;
  bottom: -5px;
  left: 0;
  color: #282828;
  text-align: center;
  margin-bottom: 10px; }

.swatch-option-disabled:after {
  content: '';
  position: absolute;
  width: 42px;
  height: 2px;
  background: red;
  transform: rotate(-30deg);
  z-index: 995;
  left: -4px;
  top: 10px; }

.swatch-option-disabled {
  outline: none !important;
  cursor: default;
  border: 0; }

.swatch-opt-listing {
  margin-bottom: 10px; }

.swatch-option-loading {
  content: url(../Magento_Swatches/images/loader-2.gif); }

.swatch-input {
  left: -1000px;
  position: absolute;
  visibility: hidden; }

* {
  font-family: "Raleway"; }

body {
  background-color: #fff; }

.nav-sections-item-content .navigation > ul > li:first-child > a span {
  font-size: 0; }

.ie9 .page-wrapper {
  background-color: #fff;
  min-height: 0; }

[role="slider"][class*="lower"] [class*="tooltip"] {
  left: 0; }

[role="slider"][class*="upper"] [class*="tooltip"] {
  left: auto;
  right: 0; }

.page-header {
  background-color: #ffffff;
  border-bottom: 1px solid #E0E6EE; }
  .page-header .panel.wrapper {
    background-color: #ffffff;
    color: #626262; }

.header.panel > .header.links {
  display: none;
  margin: 0;
  padding: 0;
  list-style: none none;
  float: right;
  font-size: 0;
  margin-right: 20px; }
  .header.panel > .header.links > li {
    display: inline-block;
    vertical-align: top; }
  .header.panel > .header.links > li {
    font-size: 11px;
    margin: 0 0 0 15px; }
    .header.panel > .header.links > li > a {
      color: #626262;
      text-decoration: none; }
      .header.panel > .header.links > li > a:visited {
        color: #626262;
        text-decoration: none; }
      .header.panel > .header.links > li > a:hover {
        color: #626262;
        text-decoration: underline; }
      .header.panel > .header.links > li > a:active {
        color: #626262;
        text-decoration: underline; }

.header.content {
  position: relative;
  display: grid;
  display: -ms-grid;
  grid-template-columns: 2rem 1fr auto 1fr 2rem;
  -ms-grid-columns: 2rem 1fr auto 1fr 2rem;
  grid-template-rows: 1fr 1fr;
  -ms-grid-rows: 1fr 1fr;
  align-items: center;
  padding: 5px 10px;
  width: 100%; }

.logo {
  display: block;
  grid-column: 3;
  -ms-grid-column: 3;
  margin: 0;
  position: relative;
  z-index: 5;
  top: 16px; }
  .logo img {
    display: block;
    max-width: 100%;
    margin: 5px auto; }
    @media only screen and (max-width: 1023px) {
      .logo img {
        margin-top: 0.75rem; } }
  .page-print .logo {
    display: inline-block;
    float: none; }

.page-main {
  margin-bottom: 3rem;
  padding: 0;
  position: relative; }
  .page-main > .page-title-wrapper .page-title + .action {
    margin-top: 30px; }

@media only screen and (max-width: 1199px) {
  body:not(.cms-index-index):not(.trollweb-wpcloud-page-view) .page-main {
    padding: 0.5rem; } }

.action.skip:focus {
  background: #f0f0f0;
  padding: 10px;
  box-sizing: border-box;
  left: 0;
  position: absolute;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 15; }

.message.global p {
  margin: 0; }

.message.global.noscript, .message.global.cookie {
  margin: 0 0 10px;
  padding: 10px 20px;
  display: block;
  line-height: 1.2em;
  font-size: 1.3rem;
  background: #fdf0d5;
  color: #6f4400;
  margin: 0; }
  .message.global.noscript a, .message.global.cookie a {
    color: #118b2d; }
    .message.global.noscript a:hover, .message.global.cookie a:hover {
      color: #0e7426; }
    .message.global.noscript a:active, .message.global.cookie a:active {
      color: #0e7426; }

.message.global.cookie {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  z-index: 3; }
  .message.global.cookie .actions {
    margin-top: 10px; }

.message.global.demo {
  margin: 0 0 10px;
  padding: 10px 20px;
  display: block;
  line-height: 1.2em;
  font-size: 1.3rem;
  background: #fdf0d5;
  color: #6f4400;
  margin-bottom: 0;
  text-align: center; }
  .message.global.demo a {
    color: #118b2d; }
    .message.global.demo a:hover {
      color: #0e7426; }
    .message.global.demo a:active {
      color: #0e7426; }

.page-footer {
  margin-top: 25px; }

.footer.content {
  background: #e8e8e8;
  border-top: 1px solid #cecece;
  padding-top: 20px; }
  @media only screen and (max-width: 1023px) {
    .footer.content .links {
      display: flex;
      flex-direction: column; } }
  .footer.content .links > li {
    background: #e8e8e8;
    font-size: 14px;
    border-top: 1px solid #cecece;
    margin: -15px;
    padding: 0 15px; }
    @media only screen and (max-width: 1023px) {
      .footer.content .links > li {
        margin: 0; } }
  .footer.content .links a,
  .footer.content .links strong {
    display: block;
    padding: 10px 0; }
  .footer.content .links strong {
    color: #90f1a6;
    font-weight: normal; }
  .footer.content .links a {
    color: #f9f9f9;
    text-decoration: none; }
    .footer.content .links a:visited {
      color: #f9f9f9;
      text-decoration: none; }
    .footer.content .links a:hover {
      color: lightgray;
      text-decoration: underline; }
    .footer.content .links a:active {
      color: lightgray;
      text-decoration: underline; }

.copyright {
  background-color: #fff;
  color: #1a1a1a;
  display: block;
  padding: 10px;
  text-align: center; }

.page-header .switcher .options,
.page-footer .switcher .options {
  display: inline-block;
  position: relative; }
  .page-header .switcher .options:before, .page-header .switcher .options:after,
  .page-footer .switcher .options:before,
  .page-footer .switcher .options:after {
    content: '';
    display: table; }
  .page-header .switcher .options:after,
  .page-footer .switcher .options:after {
    clear: both; }
  .page-header .switcher .options .action.toggle,
  .page-footer .switcher .options .action.toggle {
    padding: 0;
    cursor: pointer;
    display: inline-block;
    text-decoration: none; }
    .page-header .switcher .options .action.toggle > span,
    .page-footer .switcher .options .action.toggle > span {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; }
    .page-header .switcher .options .action.toggle:after,
    .page-footer .switcher .options .action.toggle:after {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 10px;
      line-height: 22px;
      color: inherit;
      content: "";
      font-family: "Material Icons";
      margin: 0;
      vertical-align: top;
      display: inline-block;
      font-weight: normal;
      overflow: hidden;
      speak: none;
      text-align: center; }
    .page-header .switcher .options .action.toggle:hover:after,
    .page-footer .switcher .options .action.toggle:hover:after {
      color: inherit; }
    .page-header .switcher .options .action.toggle:active:after,
    .page-footer .switcher .options .action.toggle:active:after {
      color: inherit; }
    .page-header .switcher .options .action.toggle.active,
    .page-footer .switcher .options .action.toggle.active {
      display: inline-block;
      text-decoration: none; }
      .page-header .switcher .options .action.toggle.active > span,
      .page-footer .switcher .options .action.toggle.active > span {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px; }
      .page-header .switcher .options .action.toggle.active:after,
      .page-footer .switcher .options .action.toggle.active:after {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 10px;
        line-height: 22px;
        color: inherit;
        content: "";
        font-family: "Material Icons";
        margin: 0;
        vertical-align: top;
        display: inline-block;
        font-weight: normal;
        overflow: hidden;
        speak: none;
        text-align: center; }
      .page-header .switcher .options .action.toggle.active:hover:after,
      .page-footer .switcher .options .action.toggle.active:hover:after {
        color: inherit; }
      .page-header .switcher .options .action.toggle.active:active:after,
      .page-footer .switcher .options .action.toggle.active:active:after {
        color: inherit; }
  .page-header .switcher .options ul.dropdown,
  .page-footer .switcher .options ul.dropdown {
    margin: 0;
    padding: 0;
    list-style: none none;
    background: #fff;
    border: 1px solid #bbb;
    margin-top: 4px;
    min-width: 160px;
    z-index: 100;
    box-sizing: border-box;
    display: none;
    position: absolute;
    top: 100%;
    right: -8px;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15); }
    .page-header .switcher .options ul.dropdown li,
    .page-footer .switcher .options ul.dropdown li {
      margin: 0;
      padding: 0; }
      .page-header .switcher .options ul.dropdown li:hover,
      .page-footer .switcher .options ul.dropdown li:hover {
        background: #e8e8e8;
        cursor: pointer; }
    .page-header .switcher .options ul.dropdown:before, .page-header .switcher .options ul.dropdown:after,
    .page-footer .switcher .options ul.dropdown:before,
    .page-footer .switcher .options ul.dropdown:after {
      border-bottom-style: solid;
      content: '';
      display: block;
      height: 0;
      position: absolute;
      width: 0; }
    .page-header .switcher .options ul.dropdown:before,
    .page-footer .switcher .options ul.dropdown:before {
      border: 6px solid;
      border-color: transparent transparent #fff transparent;
      z-index: 99; }
    .page-header .switcher .options ul.dropdown:after,
    .page-footer .switcher .options ul.dropdown:after {
      border: 7px solid;
      border-color: transparent transparent #bbb transparent;
      z-index: 98; }
    .page-header .switcher .options ul.dropdown:before,
    .page-footer .switcher .options ul.dropdown:before {
      right: 10px;
      top: -12px; }
    .page-header .switcher .options ul.dropdown:after,
    .page-footer .switcher .options ul.dropdown:after {
      right: 9px;
      top: -14px; }
  .page-header .switcher .options.active,
  .page-footer .switcher .options.active {
    overflow: visible; }
    .page-header .switcher .options.active ul.dropdown,
    .page-footer .switcher .options.active ul.dropdown {
      display: block; }
  .page-header .switcher .options ul.dropdown a,
  .page-footer .switcher .options ul.dropdown a {
    color: #333;
    text-decoration: none;
    display: block;
    padding: 8px; }
    .page-header .switcher .options ul.dropdown a:visited,
    .page-footer .switcher .options ul.dropdown a:visited {
      color: #333;
      text-decoration: none; }
    .page-header .switcher .options ul.dropdown a:hover,
    .page-footer .switcher .options ul.dropdown a:hover {
      color: #333;
      text-decoration: none; }
    .page-header .switcher .options ul.dropdown a:active,
    .page-footer .switcher .options ul.dropdown a:active {
      color: #333;
      text-decoration: none; }

.page-header .switcher strong,
.page-footer .switcher strong {
  font-weight: 400; }

.sidebar .widget.block:not(:last-child),
.sidebar .widget:not(:last-child) {
  margin-bottom: 40px; }

.widget {
  clear: both; }

.page-header .widget.block,
.page-footer .widget.block {
  margin: 20px 0; }

.ui-datepicker td {
  padding: 0; }

.ui-tooltip {
  background: #fff;
  border: 1px solid #bbb;
  padding: 10px; }

@media only screen and (max-width: 1199px) {
  .customer-name,
  .customer-welcome + .authorization-link {
    display: none; }
  .nav-sections .switcher-trigger strong:after {
    font-size: 14px;
    right: -25px;
    top: -2px; }
  .navigation .parent .level-top:after {
    font-size: 14px;
    right: 10px;
    top: 7px; }
  .page-footer .footer.content .switcher-store {
    background: #e8e8e8;
    font-size: 14px;
    margin: -15px; }
    .page-footer .footer.content .switcher-store .switcher-options {
      display: block; }
      .page-footer .footer.content .switcher-store .switcher-options ul.dropdown {
        background: none;
        border: 0;
        box-shadow: none;
        margin: 0;
        position: relative; }
        .page-footer .footer.content .switcher-store .switcher-options ul.dropdown:before, .page-footer .footer.content .switcher-store .switcher-options ul.dropdown:after {
          display: none; }
      .page-footer .footer.content .switcher-store .switcher-options .switcher-trigger,
      .page-footer .footer.content .switcher-store .switcher-options .switcher-option {
        border-top: 1px solid #cecece;
        display: block;
        padding: 10px 1rem; }
      .page-footer .footer.content .switcher-store .switcher-options .switcher-trigger strong {
        padding: 10px 0; }
      .page-footer .footer.content .switcher-store .switcher-options .switcher-option a {
        padding: 0; } }

.account-link {
  display: none;
  margin-left: 1rem; }
  .account-link a {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: inherit; }
    .account-link a:hover {
      text-decoration: none; }
      .account-link a:hover .account-text {
        text-decoration: underline; }
  .account-link .account-icon {
    line-height: 10px; }
    .account-link .account-icon:before {
      font-family: 'Material Icons';
      font-weight: normal;
      font-style: normal;
      font-size: 24px;
      /* Preferred icon size */
      display: inline-block;
      line-height: 1;
      text-transform: none;
      letter-spacing: normal;
      word-wrap: normal;
      white-space: nowrap;
      direction: ltr;
      /* Support for all WebKit browsers. */
      -webkit-font-smoothing: antialiased;
      /* Support for Safari and Chrome. */
      text-rendering: optimizeLegibility;
      /* Support for Firefox. */
      -moz-osx-font-smoothing: grayscale;
      /* Support for IE. */
      font-feature-settings: 'liga';
      content: "";
      font-size: 26px; }
  .account-link .account-text {
    font-size: 10px;
    font-weight: 500; }

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .header.content {
    grid-template-columns: 3rem 5rem 1fr 2fr 2fr 3rem;
    -ms-grid-columns: 3rem 5rem 1fr 2fr 2fr 3rem;
    grid-template-rows: 1fr 1fr;
    -ms-grid-rows: 1fr 1fr; }
    .header.content .nav-toggle {
      grid-column: 1;
      -ms-grid-column: 1;
      grid-row: 1 / span 2;
      -ms-grid-row: 1;
      -ms-grid-row-span: 2; }
      .header.content .nav-toggle, .header.content .nav-toggle:before {
        display: flex;
        display: -ms-flexbox;
        align-items: center; }
    .header.content .logo {
      display: -ms-flexbox;
      align-items: center;
      grid-column: 2;
      -ms-grid-column: 2;
      grid-row: 1 / span 2;
      -ms-grid-row: 1;
      -ms-grid-row-span: 2;
      margin: 0 auto; }
    .header.content .block.block-search {
      grid-column: 4;
      -ms-grid-column: 4;
      grid-row: 1 / span 2;
      -ms-grid-row: 1;
      -ms-grid-row-span: 2;
      width: auto;
      padding: 0 1rem; } }

@media only screen and (min-width: 1200px) {
  html,
  body {
    height: 100%; }
  .ie9 body {
    background-color: #1C1C1C; }
  .account-link {
    display: block; }
  .navigation ul {
    padding: 0 8px;
    display: flex; }
  .page-header {
    border: none; }
    .page-header .panel.wrapper {
      border-bottom: 1px solid #e0e0e0;
      background-color: #ffffff; }
    .page-header .header.panel {
      padding: 8px 0; }
    .page-header .switcher {
      float: right;
      margin-left: 15px;
      margin-right: -6px;
      order: 1; }
  .page-main > .page-title-wrapper .page-title {
    display: inline-block; }
  .page-main > .page-title-wrapper .page-title + .action {
    float: right;
    margin-top: 20px; }
  .customer-welcome {
    display: inline-block;
    position: relative; }
    .customer-welcome:before, .customer-welcome:after {
      content: '';
      display: table; }
    .customer-welcome:after {
      clear: both; }
    .customer-welcome .action.switch {
      padding: 0;
      cursor: pointer;
      display: inline-block;
      text-decoration: none; }
      .customer-welcome .action.switch > span {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px; }
      .customer-welcome .action.switch:after {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 10px;
        line-height: 22px;
        color: inherit;
        content: "";
        font-family: "Material Icons";
        margin: 0;
        vertical-align: top;
        display: inline-block;
        font-weight: normal;
        overflow: hidden;
        speak: none;
        text-align: center; }
      .customer-welcome .action.switch:hover:after {
        color: inherit; }
      .customer-welcome .action.switch:active:after {
        color: inherit; }
      .customer-welcome .action.switch.active {
        display: inline-block;
        text-decoration: none; }
        .customer-welcome .action.switch.active > span {
          border: 0;
          clip: rect(0, 0, 0, 0);
          height: 1px;
          margin: -1px;
          overflow: hidden;
          padding: 0;
          position: absolute;
          width: 1px; }
        .customer-welcome .action.switch.active:after {
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          font-size: 10px;
          line-height: 22px;
          color: inherit;
          content: "";
          font-family: "Material Icons";
          margin: 0;
          vertical-align: top;
          display: inline-block;
          font-weight: normal;
          overflow: hidden;
          speak: none;
          text-align: center; }
        .customer-welcome .action.switch.active:hover:after {
          color: inherit; }
        .customer-welcome .action.switch.active:active:after {
          color: inherit; }
    .customer-welcome ul {
      margin: 0;
      padding: 0;
      list-style: none none;
      background: #fff;
      border: 1px solid #bbb;
      margin-top: 4px;
      min-width: 100%;
      z-index: 101;
      box-sizing: border-box;
      display: none;
      position: absolute;
      top: 100%;
      right: -8px;
      box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15); }
      .customer-welcome ul li {
        margin: 0;
        padding: 0; }
        .customer-welcome ul li:hover {
          background: #e8e8e8;
          cursor: pointer; }
      .customer-welcome ul:before, .customer-welcome ul:after {
        border-bottom-style: solid;
        content: '';
        display: block;
        height: 0;
        position: absolute;
        width: 0; }
      .customer-welcome ul:before {
        border: 6px solid;
        border-color: transparent transparent #fff transparent;
        z-index: 99; }
      .customer-welcome ul:after {
        border: 7px solid;
        border-color: transparent transparent #bbb transparent;
        z-index: 98; }
      .customer-welcome ul:before {
        right: 10px;
        top: -12px; }
      .customer-welcome ul:after {
        right: 9px;
        top: -14px; }
    .customer-welcome.active {
      overflow: visible; }
      .customer-welcome.active ul {
        display: block; }
    .customer-welcome li a {
      color: #333;
      text-decoration: none;
      display: block;
      line-height: 1.4;
      padding: 8px; }
      .customer-welcome li a:visited {
        color: #333;
        text-decoration: none; }
      .customer-welcome li a:hover {
        color: #333;
        text-decoration: none; }
      .customer-welcome li a:active {
        color: #333;
        text-decoration: none; }
    .customer-welcome .customer-name {
      cursor: pointer; }
    .customer-welcome .customer-menu {
      display: none; }
    .customer-welcome .action.switch {
      background-image: none;
      background: none;
      -moz-box-sizing: content-box;
      border: 0;
      box-shadow: none;
      line-height: inherit;
      margin: 0;
      padding: 0;
      text-decoration: none;
      text-shadow: none;
      font-weight: 400;
      color: #fff; }
      .customer-welcome .action.switch:focus, .customer-welcome .action.switch:active {
        background: none;
        border: none; }
      .customer-welcome .action.switch:hover {
        background: none;
        border: none; }
      .customer-welcome .action.switch.disabled, .customer-welcome .action.switch[disabled],
      fieldset[disabled] .customer-welcome .action.switch {
        cursor: not-allowed;
        pointer-events: none;
        opacity: 0.5; }
    .customer-welcome .header.links {
      min-width: 175px; }
    .customer-welcome.active .action.switch:before {
      content: ""; }
    .customer-welcome.active .customer-menu {
      display: block; }
    .customer-welcome .greet {
      display: none; }
  .header.panel > .header.links {
    margin: 0;
    padding: 0;
    list-style: none none;
    float: right;
    display: none; }
    .header.panel > .header.links > li {
      display: inline-block;
      vertical-align: top; }
    .header.panel > .header.links > li {
      margin: 0 15px 0 0; }
      .header.panel > .header.links > li.welcome a {
        color: #fff;
        padding-left: 5px; }
    .header.panel > .header.links > .authorization-link:after {
      content: attr(data-label); }
    .header.panel > .header.links > .customer-welcome + .authorization-link {
      display: none; }
  .header.content {
    grid-template-columns: 1fr 2fr auto 3fr 1fr;
    -ms-grid-columns: 1fr 2fr auto 3fr 1fr;
    grid-template-rows: 1fr;
    -ms-grid-rows: 1fr 1fr;
    align-items: center;
    padding: 0;
    min-height: 75px; }
    .header.content .logo {
      display: block;
      grid-column: 1;
      -ms-grid-column: 1;
      grid-row: 1 / span 2;
      -ms-grid-row: 1;
      -ms-grid-row-span: 2;
      margin: 0; }
      .header.content .logo img {
        max-height: inherit; }
    .header.content .block.block-search {
      grid-column: 3;
      -ms-grid-column: 3;
      grid-row: 1 / span 2;
      -ms-grid-row: 1;
      -ms-grid-row-span: 2;
      min-width: 30vw; }
  .page-wrapper {
    display: flex;
    flex-direction: column;
    margin: 0;
    min-height: 100%;
    position: relative;
    transition: margin 0.3s ease-out 0s; }
    .page-wrapper > .breadcrumbs,
    .page-wrapper > .top-container,
    .page-wrapper > .widget {
      box-sizing: border-box;
      width: 100%; }
    .ie10 .page-wrapper,
    .ie11 .page-wrapper {
      height: 100%; }
  .page-footer {
    background: #e8e8e8;
    margin-top: auto;
    padding-bottom: 25px; }
    .page-footer .switcher .options ul.dropdown {
      bottom: -10px;
      left: 100%;
      margin: 0 0 0 20px;
      top: auto; }
      .page-footer .switcher .options ul.dropdown:before, .page-footer .switcher .options ul.dropdown:after {
        bottom: 13px;
        left: auto;
        right: 100%;
        top: auto; }
      .page-footer .switcher .options ul.dropdown:before {
        border-color: transparent #fff transparent transparent; }
      .page-footer .switcher .options ul.dropdown:after {
        border-color: transparent #bbb transparent transparent;
        margin: 0 0 -1px -1px; }
  .footer.content {
    border-top: none; }
    .footer.content ul {
      padding-right: 50px; }
    .footer.content .switcher.store {
      display: block;
      margin: 0; }
    .footer.content .links {
      display: inline-block;
      padding-right: 50px;
      vertical-align: top; }
      .footer.content .links li {
        background: transparent;
        border: none;
        font-size: 14px;
        margin: 0 0 8px;
        padding: 0; }
      .footer.content .links a,
      .footer.content .links strong {
        display: inline; } }

@media only screen and (min-width: 1200px) {
  .header.content {
    min-height: 75px;
    height: 75px; }
    .header.content .logo {
      margin: 0; }
      body.checkout-klarna-index .header.content .logo {
        order: 1;
        margin: 0 auto; }
      .header.content .logo img {
        max-height: inherit;
        max-width: 15rem;
        margin: 5px auto; }
        body.checkout-klarna-index .header.content .logo img {
          max-width: 8rem; }
  .logo {
    display: block;
    grid-column: 3;
    -ms-grid-column: 3;
    position: relative;
    z-index: 5;
    top: 19px; } }

@media only screen and (min-width: 1921px) {
  .page-header {
    width: 100vw;
    margin: 0 auto; }
  .block-search {
    width: 570px;
    margin-left: 100px;
    min-width: 10vw !important; }
    .block-search .form.minisearch {
      width: 570px; }
    .block-search .action.search {
      right: -8px; }
  .search-autocomplete {
    max-width: 570px; }
  .minicart-wrapper {
    min-width: 45px; }
  a.logo {
    min-width: 100px; } }

.block-collapsible-nav .content {
  background: #f5f5f5;
  padding: 15px 0;
  border-radius: 5px; }

.block-collapsible-nav .item {
  margin: 3px 0 0; }
  .block-collapsible-nav .item:first-child {
    margin-top: 0; }
  .block-collapsible-nav .item a,
  .block-collapsible-nav .item > strong {
    border-left: 3px solid transparent;
    color: #575757;
    display: block;
    padding: 5px 18px 5px 15px; }
  .block-collapsible-nav .item a {
    text-decoration: none; }
    .block-collapsible-nav .item a:hover {
      background: #e8e8e8; }
  .block-collapsible-nav .item.current a,
  .block-collapsible-nav .item.current > strong {
    border-color: #118b2d;
    color: #000;
    font-weight: 600; }
  .block-collapsible-nav .item.current a {
    border-color: #2196f3; }

@media only screen and (max-width: 1023px) {
  .block-collapsible-nav {
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    right: auto;
    bottom: auto;
    width: 100%;
    background: #ffffff; }
    .block-collapsible-nav .content {
      border-bottom: 1px solid #E0E6EE;
      display: none; }
      .block-collapsible-nav .content.active {
        display: block; } }

.my-credit-cards .status {
  font-style: italic; }

.my-credit-cards .action.delete {
  background-image: none;
  background: none;
  -moz-box-sizing: content-box;
  border: 0;
  box-shadow: none;
  line-height: inherit;
  margin: 0;
  padding: 0;
  text-decoration: none;
  text-shadow: none;
  font-weight: 400;
  line-height: 1.42857;
  margin: 0;
  padding: 0;
  color: #118b2d;
  text-decoration: none;
  background: none;
  border: 0;
  display: inline; }
  .my-credit-cards .action.delete:focus, .my-credit-cards .action.delete:active {
    background: none;
    border: none; }
  .my-credit-cards .action.delete:hover {
    background: none;
    border: none; }
  .my-credit-cards .action.delete.disabled, .my-credit-cards .action.delete[disabled],
  fieldset[disabled] .my-credit-cards .action.delete {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5; }
  .my-credit-cards .action.delete:visited {
    color: #118b2d;
    text-decoration: none; }
  .my-credit-cards .action.delete:hover {
    color: #0e7426;
    text-decoration: underline; }
  .my-credit-cards .action.delete:active {
    color: #2196f3;
    text-decoration: underline; }
  .my-credit-cards .action.delete:hover {
    color: #0e7426; }
  .my-credit-cards .action.delete:hover, .my-credit-cards .action.delete:active, .my-credit-cards .action.delete:focus {
    background: none;
    border: 0; }
  .my-credit-cards .action.delete.disabled, .my-credit-cards .action.delete[disabled],
  fieldset[disabled] .my-credit-cards .action.delete {
    color: #118b2d;
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
    text-decoration: underline; }

.my-credit-cards-popup .modal-footer {
  text-align: right; }

.my-credit-cards-popup .action.secondary {
  background-image: none;
  background: none;
  -moz-box-sizing: content-box;
  border: 0;
  box-shadow: none;
  line-height: inherit;
  margin: 0;
  padding: 0;
  text-decoration: none;
  text-shadow: none;
  font-weight: 400;
  line-height: 1.42857;
  margin: 0;
  padding: 0;
  color: #118b2d;
  text-decoration: none;
  background: none;
  border: 0;
  display: inline;
  margin-right: 20px; }
  .my-credit-cards-popup .action.secondary:focus, .my-credit-cards-popup .action.secondary:active {
    background: none;
    border: none; }
  .my-credit-cards-popup .action.secondary:hover {
    background: none;
    border: none; }
  .my-credit-cards-popup .action.secondary.disabled, .my-credit-cards-popup .action.secondary[disabled],
  fieldset[disabled] .my-credit-cards-popup .action.secondary {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5; }
  .my-credit-cards-popup .action.secondary:visited {
    color: #118b2d;
    text-decoration: none; }
  .my-credit-cards-popup .action.secondary:hover {
    color: #0e7426;
    text-decoration: underline; }
  .my-credit-cards-popup .action.secondary:active {
    color: #2196f3;
    text-decoration: underline; }
  .my-credit-cards-popup .action.secondary:hover {
    color: #0e7426; }
  .my-credit-cards-popup .action.secondary:hover, .my-credit-cards-popup .action.secondary:active, .my-credit-cards-popup .action.secondary:focus {
    background: none;
    border: 0; }
  .my-credit-cards-popup .action.secondary.disabled, .my-credit-cards-popup .action.secondary[disabled],
  fieldset[disabled] .my-credit-cards-popup .action.secondary {
    color: #118b2d;
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
    text-decoration: underline; }

@media only screen and (max-width: 1023px) {
  .my-credit-cards .table:not(.totals):not(.cart):not(.table-comparison) .status {
    display: none; }
    .my-credit-cards .table:not(.totals):not(.cart):not(.table-comparison) .status:before {
      display: none; }
    .my-credit-cards .table:not(.totals):not(.cart):not(.table-comparison) .status._default {
      display: block; }
  .my-credit-cards .card-type img {
    vertical-align: middle; } }

@media only screen and (min-width: 1024px) {
  .my-credit-cards .card-type img {
    display: block; } }

.minilist .weee {
  display: table-row;
  font-size: 1.1rem; }
  .minilist .weee:before {
    display: none; }

.price-container.weee:before {
  display: none; }

.product-items .action.towishlist:before,
.products.list.items .action.towishlist:before,
.table-comparison .action.towishlist:before {
  content: ""; }

.account .table-wrapper .data.table.wishlist > thead > tr > th,
.account .table-wrapper .data.table.wishlist > thead > tr > td,
.account .table-wrapper .data.table.wishlist > tbody > tr > th,
.account .table-wrapper .data.table.wishlist > tbody > tr > td,
.account .table-wrapper .data.table.wishlist > tfoot > tr > th,
.account .table-wrapper .data.table.wishlist > tfoot > tr > td {
  border-top: 1px solid #E0E6EE; }

.account .table-wrapper .data.table.wishlist > caption + thead > tr:first-child > th,
.account .table-wrapper .data.table.wishlist > caption + thead > tr:first-child > td,
.account .table-wrapper .data.table.wishlist > colgroup + thead > tr:first-child > th,
.account .table-wrapper .data.table.wishlist > colgroup + thead > tr:first-child > td,
.account .table-wrapper .data.table.wishlist > thead:first-child > tr:first-child > th,
.account .table-wrapper .data.table.wishlist > thead:first-child > tr:first-child > td {
  border-top: 0; }

.account .table-wrapper .data.table.wishlist > tbody + tbody {
  border-top: 1px solid #E0E6EE; }

.account .table-wrapper .data.table.wishlist thead > tr > th {
  border-bottom: 0; }

.account .table-wrapper .data.table.wishlist tbody > tr:last-child > td {
  border-bottom: 1px solid #E0E6EE; }

.account .table-wrapper .data.table.wishlist .product.name {
  display: inline-block;
  margin-bottom: 10px; }

.account .table-wrapper .data.table.wishlist .box-tocart {
  margin: 10px 0; }
  .account .table-wrapper .data.table.wishlist .box-tocart .qty {
    vertical-align: middle; }

.account .table-wrapper .data.table.wishlist .col.item {
  width: 50%; }

.account .table-wrapper .data.table.wishlist .col.photo {
  max-width: 150px; }

.account .table-wrapper .data.table.wishlist .col.selector {
  max-width: 15px; }

.account .table-wrapper .data.table.wishlist textarea {
  margin: 10px 0; }

.account .table-wrapper .data.table.wishlist .input-text.qty {
  margin-bottom: 10px; }

.account .table-wrapper .data.table.wishlist .action.primary {
  vertical-align: top; }

.account .table-wrapper .data.table.wishlist .price {
  font-weight: 700; }

.block-wishlist .product-item-name {
  margin-right: 25px; }

.products-grid.wishlist .product-item-photo {
  display: block;
  margin-bottom: 10px; }

.products-grid.wishlist .product-item-name {
  margin-top: 0; }

.products-grid.wishlist .product-item .price-box {
  margin: 0; }

.products-grid.wishlist .product-item-comment {
  display: block;
  height: 42px;
  margin: 10px 0; }

.products-grid.wishlist .product-item-actions > * {
  margin-right: 15px; }
  .products-grid.wishlist .product-item-actions > *:last-child {
    margin-right: 0; }

.products-grid.wishlist .product-item .box-tocart input.qty {
  height: 32px; }

@media only screen and (max-width: 767px) {
  .products-grid.wishlist .product-item {
    border-bottom: 1px solid #999; }
    .products-grid.wishlist .product-item:first-child {
      border-top: 1px solid #999; } }

@media only screen and (max-width: 1023px) {
  .products-grid.wishlist {
    margin-bottom: 30px;
    margin-right: -10px; }
    .products-grid.wishlist .product-item {
      padding: 20px 10px 20px 20px;
      position: relative; }
      .products-grid.wishlist .product-item-photo {
        float: left;
        margin-right: 20px; }
      .products-grid.wishlist .product-item-name {
        font-size: 1.6rem; }
      .products-grid.wishlist .product-item-actions {
        display: block; }
        .products-grid.wishlist .product-item-actions .action {
          margin-right: 15px; }
          .products-grid.wishlist .product-item-actions .action:last-child {
            margin-right: 0; }
          .products-grid.wishlist .product-item-actions .action.edit {
            float: left; }
          .products-grid.wishlist .product-item-actions .action.delete {
            float: right; }
          .products-grid.wishlist .product-item-actions .action.edit, .products-grid.wishlist .product-item-actions .action.delete {
            margin-top: 7px; }
      .products-grid.wishlist .product-item-name, .products-grid.wishlist .product-item-description,
      .products-grid.wishlist .product-item .price-box, .products-grid.wishlist .product-item-tooltip {
        margin-left: 95px; }
      .products-grid.wishlist .product-item .box-tocart {
        float: left;
        margin-right: 20px; }
        .products-grid.wishlist .product-item .box-tocart .stock {
          margin-top: 7px; }
      .products-grid.wishlist .product-item .giftregisty-dropdown,
      .products-grid.wishlist .product-item .field.qty {
        display: none; }
    .products-grid.wishlist .product-image-container {
      max-width: 80px; }
  .wishlist-index-index .product-item {
    width: 100%; }
    .wishlist-index-index .product-item-info {
      width: auto; } }

@media only screen and (min-width: 768px) {
  .wishlist-index-index .products-grid .product-item {
    margin-bottom: 20px; }
  .wishlist-index-index .products-grid .product-item-actions {
    margin: 0; } }

@media only screen and (min-width: 1024px) {
  .products-grid.wishlist .product-item-tooltip {
    display: inline-block; }
  .products-grid.wishlist .product-item-actions {
    margin: 10px 0 0; }
  .products-grid.wishlist .product-item .fieldset .field.qty {
    margin-bottom: 10px;
    padding-right: 10px; }
    .products-grid.wishlist .product-item .fieldset .field.qty .label {
      width: auto; }
  .products-grid.wishlist .product-item .box-tocart .actions-primary {
    margin: 0; }
  .products-grid.wishlist .product-item .box-tocart .stock {
    margin: 20px 0 0; }
  .wishlist-index-index .product-item-info {
    width: 240px; } }

@media only screen and (min-width: 1200px) {
  .wishlist-index-index .products-grid .product-items {
    margin: 0; }
  .wishlist-index-index .products-grid .product-item {
    margin-left: calc((100% - 4 * 24.439%) / 3);
    padding: 0;
    width: 24.439%; }
    .wishlist-index-index .products-grid .product-item:nth-child(4n + 1) {
      margin-left: 0; } }

@font-face {
  font-family: "pagebuilder-font";
  font-style: normal;
  font-weight: normal;
  src: url("../Magento_PageBuilder/fonts/pagebuilder-icons/pagebuilder-icons.eot");
  src: url("../Magento_PageBuilder/fonts/pagebuilder-icons/pagebuilder-icons.eot?#iefix") format("embedded-opentype"), url("../Magento_PageBuilder/fonts/pagebuilder-icons/pagebuilder-icons.woff") format("woff"), url("../Magento_PageBuilder/fonts/pagebuilder-icons/pagebuilder-icons.ttf") format("truetype"); }

.pagebuilder-icon, .slick-prev:before, .slick-prev:after,
.slick-next:before,
.slick-next:after {
  font-family: "pagebuilder-font";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  speak: none; }

[class^='pagebuilder-icon-']:before,
[class*=' pagebuilder-icon-']:before {
  display: inline-block;
  font-family: "pagebuilder-font";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  speak: none;
  text-decoration: inherit;
  text-rendering: auto;
  text-transform: none;
  vertical-align: middle; }

.pagebuilder-icon-down:before {
  content: ""; }

.pagebuilder-icon-next:before {
  content: ""; }

.pagebuilder-icon-prev:before {
  content: ""; }

.pagebuilder-icon-up:before {
  content: ""; }

.product-full-width-section {
  padding: 0.5em 0; }
  .product-full-width-section .block.review-add {
    margin-top: 2.7rem; }

.page-layout-product-full-width .block.related {
  margin-top: 2.7rem; }

.page-main-details .product-section-title {
  border-bottom: 1px solid #c6c6c6;
  margin-bottom: 15px;
  padding-bottom: 12px; }

.additional-attributes-wrapper .additional-attributes {
  border: none;
  width: auto; }
  .additional-attributes-wrapper .additional-attributes > tbody > tr > th {
    border: none;
    padding: 5.5px 30px 10px 0; }
  .additional-attributes-wrapper .additional-attributes > tbody > tr > td {
    border: none;
    padding: 5.5px 5px 10px; }

.cms-index-index.page-layout-cms-full-width .nav-sections {
  margin-bottom: 0; }

.slick-slider {
  box-sizing: border-box;
  display: block;
  position: relative;
  touch-action: pan-y;
  user-select: none; }

.slick-list {
  display: block;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-track {
  display: flex;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: 0; }
  .slick-track:before, .slick-track:after {
    content: '';
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  min-height: 1px; }
  [dir='rtl'] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    border: 1px solid transparent;
    display: block;
    height: auto; }
  .slick-slide > div {
    height: 100%; }
  .slick-slide .product-item {
    height: inherit; }
    .slick-slide .product-item .product-item-info {
      height: inherit; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0); }

.slick-arrow.slick-hidden {
  display: none; }

.slick-prev {
  left: 2rem; }
  .slick-prev:before {
    content: "";
    padding-right: 5px; }

.slick-next {
  right: 2rem; }
  .slick-next:before {
    content: "";
    padding-left: 5px; }

.slick-prev,
.slick-next {
  background: rgba(252, 252, 252, 0.6);
  border: none;
  border-radius: 0;
  color: transparent;
  cursor: pointer;
  display: block;
  font-size: 0;
  height: 60px;
  line-height: 0;
  outline: none;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: 60px;
  z-index: 101; }
  .slick-prev.slick-disabled,
  .slick-next.slick-disabled {
    opacity: 0.2; }
  .slick-prev:hover,
  .slick-next:hover {
    background: #fcfcfc;
    border: none; }
    .slick-prev:hover.slick-disabled,
    .slick-next:hover.slick-disabled {
      opacity: 0.2; }
    .slick-prev:hover:before,
    .slick-next:hover:before {
      color: #494949; }
  .slick-prev:before, .slick-prev:after,
  .slick-next:before,
  .slick-next:after {
    color: #555;
    font-size: 38px;
    opacity: 1; }
  .slick-prev:active, .slick-prev:focus, .slick-prev:not(.primary),
  .slick-next:active,
  .slick-next:focus,
  .slick-next:not(.primary) {
    background: rgba(252, 252, 252, 0.7);
    border: none;
    box-shadow: none; }
    .slick-prev:active:active, .slick-prev:focus:active, .slick-prev:not(.primary):active,
    .slick-next:active:active,
    .slick-next:focus:active,
    .slick-next:not(.primary):active {
      box-shadow: none; }

.slick-dots {
  font-size: 1.4rem;
  font-size: 14px;
  letter-spacing: normal;
  line-height: normal;
  display: block;
  list-style: none;
  margin: 0;
  padding: 15px 0;
  text-align: center;
  width: 100%; }
  .slick-dots li {
    cursor: pointer;
    display: inline-block;
    height: 14px;
    margin: 0 5px;
    padding: 0;
    position: relative;
    vertical-align: middle;
    width: 14px; }
    .slick-dots li button, .slick-dots li .cart.table-wrapper .actions-toolbar > .action, .cart.table-wrapper .slick-dots li .actions-toolbar > .action, .slick-dots li .action-gift {
      background: #fff;
      border: 1px solid #ccc;
      border-radius: 10px;
      box-shadow: none;
      cursor: pointer;
      display: block;
      height: 14px;
      padding: 0;
      text-indent: -99999px;
      transition: 0.3s;
      width: 14px; }
      .slick-dots li button:hover, .slick-dots li .cart.table-wrapper .actions-toolbar > .action:hover, .cart.table-wrapper .slick-dots li .actions-toolbar > .action:hover, .slick-dots li .action-gift:hover {
        border: 1px solid #b3b3b3; }
      .slick-dots li button:active, .slick-dots li .cart.table-wrapper .actions-toolbar > .action:active, .cart.table-wrapper .slick-dots li .actions-toolbar > .action:active, .slick-dots li .action-gift:active, .slick-dots li button:focus, .slick-dots li .cart.table-wrapper .actions-toolbar > .action:focus, .cart.table-wrapper .slick-dots li .actions-toolbar > .action:focus, .slick-dots li .action-gift:focus, .slick-dots li button:not(.primary), .slick-dots li .cart.table-wrapper .actions-toolbar > .action:not(.primary), .cart.table-wrapper .slick-dots li .actions-toolbar > .action:not(.primary), .slick-dots li .action-gift:not(.primary) {
        box-shadow: none; }
    .slick-dots li.slick-active button, .slick-dots li.slick-active .cart.table-wrapper .actions-toolbar > .action, .cart.table-wrapper .slick-dots li.slick-active .actions-toolbar > .action, .slick-dots li.slick-active .action-gift {
      background: #1c1918; }

.pagebuilder-slider.slick-initialized {
  min-height: auto !important; }
  .pagebuilder-slider.slick-initialized.slick-slider .pagebuilder-slide-wrapper {
    background-size: cover !important;
    width: 100%;
    height: 0 !important;
    padding-top: 27.58%;
    min-height: auto !important; }

[data-content-type='banner'] > [data-element='link'],
[data-content-type='banner'] > [data-element='empty_link'] {
  color: inherit;
  text-decoration: inherit; }
  [data-content-type='banner'] > [data-element='link']:hover,
  [data-content-type='banner'] > [data-element='empty_link']:hover {
    color: inherit;
    text-decoration: inherit; }

.pagebuilder-banner-wrapper {
  background-clip: padding-box;
  border-radius: inherit;
  overflow-wrap: break-word;
  box-sizing: border-box;
  word-wrap: break-word; }
  .pagebuilder-banner-wrapper .pagebuilder-overlay {
    box-sizing: border-box;
    padding: 30px;
    transition: background-color 500ms ease; }
    .pagebuilder-banner-wrapper .pagebuilder-overlay.pagebuilder-poster-overlay {
      align-items: center;
      display: flex;
      justify-content: center; }
    .pagebuilder-banner-wrapper .pagebuilder-overlay:not(.pagebuilder-poster-overlay) {
      max-width: 540px; }
  .pagebuilder-banner-wrapper [data-element='content'] {
    min-height: 50px; }
  .pagebuilder-banner-wrapper .pagebuilder-banner-button {
    margin: 20px 0 0 0;
    max-width: 100%;
    text-align: inherit;
    transition: opacity 500ms ease;
    word-break: break-word; }

@media only screen and (max-width: 1022px) {
  .pagebuilder-banner-wrapper {
    background-attachment: scroll !important; }
    .pagebuilder-banner-wrapper .pagebuilder-overlay:not(.pagebuilder-poster-overlay) {
      max-width: none; } }

.pagebuilder-banner-wrapper .pagebuilder-poster-content {
  width: 100%; }

[data-appearance='collage-centered'] .pagebuilder-banner-wrapper .pagebuilder-overlay {
  margin-left: auto;
  margin-right: auto; }

[data-appearance='collage-left'] .pagebuilder-banner-wrapper .pagebuilder-overlay {
  margin-right: auto; }

[data-appearance='collage-right'] .pagebuilder-banner-wrapper .pagebuilder-overlay {
  margin-left: auto; }

[data-content-type$='block'] .block p:last-child {
  margin-bottom: 1rem;
  margin-top: 0; }

[data-content-type='buttons'] {
  max-width: 100%; }

[data-content-type='button-item'] {
  max-width: 100%; }
  [data-content-type='button-item'] [data-element='link'],
  [data-content-type='button-item'] [data-element='empty_link'] {
    max-width: 100%;
    word-wrap: break-word; }
  [data-content-type='button-item'] [data-element='empty_link'] {
    cursor: default; }

a.pagebuilder-button-link,
button.pagebuilder-button-link,
.cart.table-wrapper .actions-toolbar > .pagebuilder-button-link.action,
.pagebuilder-button-link.action-gift,
div.pagebuilder-button-link {
  line-height: 1.42857;
  margin: 0 10px 10px 0;
  padding: 14px 17px;
  color: #118b2d;
  text-decoration: none;
  background: none;
  border: 0;
  display: inline;
  font-weight: 600;
  box-shadow: none;
  box-sizing: border-box;
  display: inline-block; }
  a.pagebuilder-button-link:visited,
  button.pagebuilder-button-link:visited,
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-link.action:visited,
  .pagebuilder-button-link.action-gift:visited,
  div.pagebuilder-button-link:visited {
    color: #118b2d;
    text-decoration: none; }
  a.pagebuilder-button-link:hover,
  button.pagebuilder-button-link:hover,
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-link.action:hover,
  .pagebuilder-button-link.action-gift:hover,
  div.pagebuilder-button-link:hover {
    color: #0e7426;
    text-decoration: underline; }
  a.pagebuilder-button-link:active,
  button.pagebuilder-button-link:active,
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-link.action:active,
  .pagebuilder-button-link.action-gift:active,
  div.pagebuilder-button-link:active {
    color: #2196f3;
    text-decoration: underline; }
  a.pagebuilder-button-link:hover,
  button.pagebuilder-button-link:hover,
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-link.action:hover,
  .pagebuilder-button-link.action-gift:hover,
  div.pagebuilder-button-link:hover {
    color: #0e7426; }
  a.pagebuilder-button-link:hover, a.pagebuilder-button-link:active, a.pagebuilder-button-link:focus,
  button.pagebuilder-button-link:hover,
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-link.action:hover,
  .pagebuilder-button-link.action-gift:hover,
  button.pagebuilder-button-link:active,
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-link.action:active,
  .pagebuilder-button-link.action-gift:active,
  button.pagebuilder-button-link:focus,
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-link.action:focus,
  .pagebuilder-button-link.action-gift:focus,
  div.pagebuilder-button-link:hover,
  div.pagebuilder-button-link:active,
  div.pagebuilder-button-link:focus {
    background: none;
    border: 0; }
  a.pagebuilder-button-link.disabled, a.pagebuilder-button-link[disabled],
  fieldset[disabled] a.pagebuilder-button-link,
  button.pagebuilder-button-link.disabled,
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-link.disabled.action,
  .pagebuilder-button-link.disabled.action-gift,
  button.pagebuilder-button-link[disabled],
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-link.action[disabled],
  .pagebuilder-button-link.action-gift[disabled],
  fieldset[disabled] button.pagebuilder-button-link,
  fieldset[disabled] .cart.table-wrapper .actions-toolbar > .pagebuilder-button-link.action,
  .cart.table-wrapper fieldset[disabled] .actions-toolbar > .pagebuilder-button-link.action,
  fieldset[disabled] .pagebuilder-button-link.action-gift,
  div.pagebuilder-button-link.disabled,
  div.pagebuilder-button-link[disabled],
  fieldset[disabled]
  div.pagebuilder-button-link {
    color: #118b2d;
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
    text-decoration: underline; }

a.pagebuilder-button-primary,
button.pagebuilder-button-primary,
.cart.table-wrapper .actions-toolbar > .pagebuilder-button-primary.action,
.pagebuilder-button-primary.action-gift,
div.pagebuilder-button-primary {
  display: inline-block;
  text-decoration: none;
  background-image: none;
  background: #118b2d;
  border: 0px solid #e5e5e5;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-family: "Roboto", Helvetica, sans-serif;
  font-weight: 400;
  padding: 7px 15px;
  font-size: 1.4rem;
  box-sizing: border-box;
  vertical-align: middle;
  border-radius: 0;
  box-shadow: none; }
  a.pagebuilder-button-primary:hover, a.pagebuilder-button-primary:active, a.pagebuilder-button-primary:focus,
  button.pagebuilder-button-primary:hover,
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-primary.action:hover,
  .pagebuilder-button-primary.action-gift:hover,
  button.pagebuilder-button-primary:active,
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-primary.action:active,
  .pagebuilder-button-primary.action-gift:active,
  button.pagebuilder-button-primary:focus,
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-primary.action:focus,
  .pagebuilder-button-primary.action-gift:focus,
  div.pagebuilder-button-primary:hover,
  div.pagebuilder-button-primary:active,
  div.pagebuilder-button-primary:focus {
    text-decoration: none; }
  a.pagebuilder-button-primary:focus, a.pagebuilder-button-primary:active,
  button.pagebuilder-button-primary:focus,
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-primary.action:focus,
  .pagebuilder-button-primary.action-gift:focus,
  button.pagebuilder-button-primary:active,
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-primary.action:active,
  .pagebuilder-button-primary.action-gift:active,
  div.pagebuilder-button-primary:focus,
  div.pagebuilder-button-primary:active {
    background: #0d6721;
    border: 0px solid #e5e5e5;
    color: #fff; }
  a.pagebuilder-button-primary:hover,
  button.pagebuilder-button-primary:hover,
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-primary.action:hover,
  .pagebuilder-button-primary.action-gift:hover,
  div.pagebuilder-button-primary:hover {
    background: #0d6721;
    border: 0px solid #e5e5e5;
    color: #fff; }
  a.pagebuilder-button-primary.disabled, a.pagebuilder-button-primary[disabled],
  fieldset[disabled] a.pagebuilder-button-primary,
  button.pagebuilder-button-primary.disabled,
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-primary.disabled.action,
  .pagebuilder-button-primary.disabled.action-gift,
  button.pagebuilder-button-primary[disabled],
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-primary.action[disabled],
  .pagebuilder-button-primary.action-gift[disabled],
  fieldset[disabled] button.pagebuilder-button-primary,
  fieldset[disabled] .cart.table-wrapper .actions-toolbar > .pagebuilder-button-primary.action,
  .cart.table-wrapper fieldset[disabled] .actions-toolbar > .pagebuilder-button-primary.action,
  fieldset[disabled] .pagebuilder-button-primary.action-gift,
  div.pagebuilder-button-primary.disabled,
  div.pagebuilder-button-primary[disabled],
  fieldset[disabled]
  div.pagebuilder-button-primary {
    opacity: 0.5;
    cursor: default;
    pointer-events: none; }

a.pagebuilder-button-secondary,
button.pagebuilder-button-secondary,
.cart.table-wrapper .actions-toolbar > .pagebuilder-button-secondary.action,
.pagebuilder-button-secondary.action-gift,
div.pagebuilder-button-secondary {
  display: inline-block;
  text-decoration: none;
  background-image: none;
  background: #e0e6ee;
  border: 0;
  color: #118b2d;
  cursor: pointer;
  display: inline-block;
  font-family: "Roboto", Helvetica, sans-serif;
  font-weight: 400;
  margin: 0 10px 10px 0;
  padding: 7px 15px;
  font-size: 1.4rem;
  line-height: 1.6rem;
  box-sizing: border-box;
  vertical-align: middle;
  line-height: 2.2rem;
  padding: 14px 17px;
  font-size: 1.8rem;
  border-radius: 0;
  box-shadow: none; }
  a.pagebuilder-button-secondary:hover, a.pagebuilder-button-secondary:active, a.pagebuilder-button-secondary:focus,
  button.pagebuilder-button-secondary:hover,
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-secondary.action:hover,
  .pagebuilder-button-secondary.action-gift:hover,
  button.pagebuilder-button-secondary:active,
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-secondary.action:active,
  .pagebuilder-button-secondary.action-gift:active,
  button.pagebuilder-button-secondary:focus,
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-secondary.action:focus,
  .pagebuilder-button-secondary.action-gift:focus,
  div.pagebuilder-button-secondary:hover,
  div.pagebuilder-button-secondary:active,
  div.pagebuilder-button-secondary:focus {
    text-decoration: none; }
  a.pagebuilder-button-secondary:focus, a.pagebuilder-button-secondary:active,
  button.pagebuilder-button-secondary:focus,
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-secondary.action:focus,
  .pagebuilder-button-secondary.action-gift:focus,
  button.pagebuilder-button-secondary:active,
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-secondary.action:active,
  .pagebuilder-button-secondary.action-gift:active,
  div.pagebuilder-button-secondary:focus,
  div.pagebuilder-button-secondary:active {
    background: #d0d9e5;
    border: 0;
    color: #118b2d; }
  a.pagebuilder-button-secondary:hover,
  button.pagebuilder-button-secondary:hover,
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-secondary.action:hover,
  .pagebuilder-button-secondary.action-gift:hover,
  div.pagebuilder-button-secondary:hover {
    background: #d0d9e5;
    border: 0;
    color: #555; }
  a.pagebuilder-button-secondary.disabled, a.pagebuilder-button-secondary[disabled],
  fieldset[disabled] a.pagebuilder-button-secondary,
  button.pagebuilder-button-secondary.disabled,
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-secondary.disabled.action,
  .pagebuilder-button-secondary.disabled.action-gift,
  button.pagebuilder-button-secondary[disabled],
  .cart.table-wrapper .actions-toolbar > .pagebuilder-button-secondary.action[disabled],
  .pagebuilder-button-secondary.action-gift[disabled],
  fieldset[disabled] button.pagebuilder-button-secondary,
  fieldset[disabled] .cart.table-wrapper .actions-toolbar > .pagebuilder-button-secondary.action,
  .cart.table-wrapper fieldset[disabled] .actions-toolbar > .pagebuilder-button-secondary.action,
  fieldset[disabled] .pagebuilder-button-secondary.action-gift,
  div.pagebuilder-button-secondary.disabled,
  div.pagebuilder-button-secondary[disabled],
  fieldset[disabled]
  div.pagebuilder-button-secondary {
    opacity: 0.5;
    cursor: default;
    pointer-events: none; }

.pagebuilder-column {
  box-sizing: border-box; }

@media only screen and (max-width: 1022px) {
  .pagebuilder-column {
    background-attachment: scroll !important;
    flex-basis: 100%; } }

@media only screen and (max-width: 1022px) {
  .pagebuilder-column-group {
    flex-wrap: wrap; } }

[data-content-type='heading'] {
  word-wrap: break-word; }

[data-content-type='html'] {
  word-wrap: break-word; }

figure[data-content-type='image'] {
  box-sizing: border-box; }
  figure[data-content-type='image'] > [data-element='link'],
  figure[data-content-type='image'] > [data-element='link'] img {
    border-radius: inherit; }
  figure[data-content-type='image'] figcaption {
    word-wrap: break-word; }

[data-content-type='map'] {
  box-sizing: border-box;
  height: 300px; }

.pagebuilder-column .widget-product-grid .product-item {
  max-width: 100%;
  min-width: 50px; }
  .pagebuilder-column .widget-product-grid .product-item .price-box {
    word-wrap: break-word; }
    .pagebuilder-column .widget-product-grid .product-item .price-box .price {
      white-space: normal; }
  .pagebuilder-column .widget-product-grid .product-item .product-item-actions .actions-primary {
    display: inline-block;
    max-width: 100%; }
  .pagebuilder-column .widget-product-grid .product-item .product-item-actions .actions-secondary {
    width: auto; }
  .pagebuilder-column .widget-product-grid .product-item .product-item-actions .tocart {
    max-width: 100%;
    white-space: normal; }

.pagebuilder-column [data-content-type='products'] .block.widget .products-grid .widget-product-grid.product-items .product-item {
  width: 200px; }

[data-content-type='products'][data-appearance='carousel'] .slick-initialized li.product-item {
  display: block; }
  [data-content-type='products'][data-appearance='carousel'] .slick-initialized li.product-item:first-child {
    visibility: visible; }

[data-content-type='products'][data-appearance='carousel'] .product-item-info {
  width: auto; }

[data-content-type='products'][data-appearance='carousel'] li.product-item {
  display: none; }
  [data-content-type='products'][data-appearance='carousel'] li.product-item:first-child {
    display: block;
    visibility: hidden; }

[data-content-type='products'][data-appearance='carousel'].center-mode .product-item {
  opacity: 0.5;
  transition: all 300ms ease; }
  [data-content-type='products'][data-appearance='carousel'].center-mode .product-item:hover {
    opacity: 1; }

[data-content-type='products'][data-appearance='carousel'].center-mode .slick-current .product-item {
  opacity: 1; }

[data-content-type='row'][data-appearance='contained'] {
  box-sizing: border-box;
  margin-left: auto !important;
  margin-right: auto !important;
  max-width: 100%; }
  [data-content-type='row'][data-appearance='contained'] [data-element='inner'] {
    box-sizing: border-box; }

@media only screen and (max-width: 1022px) {
  [data-content-type='row'][data-appearance='contained'] [data-element='inner'] {
    background-attachment: scroll !important; } }

[data-content-type='row'][data-appearance='full-bleed'] {
  box-sizing: border-box; }

@media only screen and (max-width: 1022px) {
  [data-content-type='row'][data-appearance='full-bleed'] {
    background-attachment: scroll !important; } }

[data-content-type='row'][data-appearance='full-width'] {
  box-sizing: border-box; }
  [data-content-type='row'][data-appearance='full-width'] > .row-full-width-inner {
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    max-width: 1440px;
    width: 100%; }

@media only screen and (max-width: 1022px) {
  [data-content-type='row'][data-appearance='full-width'] {
    background-attachment: scroll !important; } }

[data-content-type='slide'] {
  box-sizing: border-box;
  line-height: 20px;
  min-height: inherit;
  overflow: hidden; }
  [data-content-type='slide'] > [data-element='link'],
  [data-content-type='slide'] > [data-element='empty_link'] {
    color: inherit;
    min-height: inherit;
    text-decoration: inherit; }
    [data-content-type='slide'] > [data-element='link']:hover,
    [data-content-type='slide'] > [data-element='empty_link']:hover {
      color: inherit;
      text-decoration: inherit; }

.pagebuilder-slide-wrapper {
  border-radius: inherit;
  box-sizing: border-box;
  min-height: inherit;
  overflow-wrap: break-word;
  word-wrap: break-word; }
  .pagebuilder-slide-wrapper .pagebuilder-overlay {
    border-radius: inherit;
    box-sizing: border-box;
    padding: 30px;
    transition: background-color 500ms ease; }
    .pagebuilder-slide-wrapper .pagebuilder-overlay.pagebuilder-poster-overlay {
      align-items: center;
      display: flex;
      justify-content: center;
      min-height: inherit; }
    .pagebuilder-slide-wrapper .pagebuilder-overlay:not(.pagebuilder-poster-overlay) {
      max-width: 540px; }
  .pagebuilder-slide-wrapper [data-element='content'] {
    min-height: 50px; }
  .pagebuilder-slide-wrapper .pagebuilder-slide-button {
    margin: 20px 0 0 0;
    max-width: 100%;
    text-align: inherit;
    transition: opacity 500ms ease;
    word-break: break-word; }

@media only screen and (max-width: 1022px) {
  .pagebuilder-slide-wrapper .pagebuilder-overlay:not(.pagebuilder-poster-overlay) {
    max-width: none; } }

.pagebuilder-slide-wrapper .pagebuilder-poster-content {
  width: 100%; }

[data-appearance='collage-centered'] .pagebuilder-slide-wrapper .pagebuilder-overlay {
  margin-left: auto;
  margin-right: auto; }

[data-appearance='collage-left'] .pagebuilder-slide-wrapper .pagebuilder-overlay {
  margin-right: auto; }

[data-appearance='collage-right'] .pagebuilder-slide-wrapper .pagebuilder-overlay {
  margin-left: auto; }

div[data-content-type='slider'] {
  visibility: hidden; }
  div[data-content-type='slider'].slick-initialized {
    visibility: visible; }
  div[data-content-type='slider'] .slick-list,
  div[data-content-type='slider'] .slick-track,
  div[data-content-type='slider'] .slick-slide {
    min-height: inherit; }
    div[data-content-type='slider'] .slick-list > div,
    div[data-content-type='slider'] .slick-track > div,
    div[data-content-type='slider'] .slick-slide > div {
      line-height: 0;
      min-height: inherit;
      overflow: hidden;
      width: 100%; }

a.button {
  appearance: button;
  color: initial;
  padding: 10px;
  text-decoration: none; }

div[data-content-type='tabs'] .tabs-navigation {
  display: block;
  font-size: 0;
  margin: 0 0 -1px;
  padding: 0; }
  div[data-content-type='tabs'] .tabs-navigation li:first-child {
    margin-left: 0 !important; }
  div[data-content-type='tabs'] .tabs-navigation li.tab-header {
    background: #f6f6f6;
    border: 1px solid #ccc;
    border-bottom: 0;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    display: inline-block;
    margin: 0;
    max-width: 100%;
    overflow-wrap: break-word;
    position: relative;
    word-wrap: break-word;
    z-index: 1; }
    div[data-content-type='tabs'] .tabs-navigation li.tab-header:not(:first-child) {
      margin-left: -1px; }
    div[data-content-type='tabs'] .tabs-navigation li.tab-header a.tab-title {
      border-right: 0;
      color: #000;
      cursor: pointer !important;
      display: block;
      font-size: 14px;
      font-weight: 600;
      padding: 1.4rem 2rem;
      position: relative;
      transition: all 0.3s;
      vertical-align: middle;
      white-space: normal; }
      div[data-content-type='tabs'] .tabs-navigation li.tab-header a.tab-title span.tab-title {
        display: block; }
      div[data-content-type='tabs'] .tabs-navigation li.tab-header a.tab-title:hover {
        text-decoration: none; }
    div[data-content-type='tabs'] .tabs-navigation li.tab-header:last-child {
      border-right: 1px solid #ccc; }
    div[data-content-type='tabs'] .tabs-navigation li.tab-header.ui-state-active {
      background: #fff;
      z-index: 19; }
      div[data-content-type='tabs'] .tabs-navigation li.tab-header.ui-state-active a.tab-title {
        position: relative;
        transition: all 0.3s; }

div[data-content-type='tabs'] .tabs-content {
  border: 1px solid #ccc;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  z-index: 9; }
  div[data-content-type='tabs'] .tabs-content [data-content-type='tab-item'] {
    box-sizing: border-box;
    min-height: inherit; }
    div[data-content-type='tabs'] .tabs-content [data-content-type='tab-item']:not(:first-child) {
      display: none; }

div[data-content-type='tabs'].tab-align-left .tabs-content {
  border-top-left-radius: 0 !important; }

div[data-content-type='tabs'].tab-align-right .tabs-content {
  border-top-right-radius: 0 !important; }

@media only screen and (max-width: 1022px) {
  [data-content-type='tab-item'] {
    background-attachment: scroll !important; } }

div[data-content-type='text'] {
  word-wrap: break-word; }

div[data-content-type='video'] {
  font-size: 0; }
  div[data-content-type='video'] .pagebuilder-video-inner {
    box-sizing: border-box;
    display: inline-block;
    width: 100%; }
  div[data-content-type='video'] .pagebuilder-video-container {
    border-radius: inherit;
    overflow: hidden;
    padding-top: 56.25%;
    position: relative; }
  div[data-content-type='video'] iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; }

.cms-content-important {
  background-color: #f5f5f5;
  color: #333;
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 300;
  line-height: 1.1;
  margin: 0 0 35px -20px;
  padding: 20px; }

.pagebuilder-full-width {
  float: left;
  width: 100%; }

.pagebuilder-content-type {
  box-sizing: border-box;
  margin-bottom: 20px; }

.pagebuilder-accordion {
  margin: 0;
  padding: 0; }
  .pagebuilder-accordion > .item.title {
    box-sizing: border-box;
    float: none;
    width: 100%; }
    .pagebuilder-accordion > .item.title > .switch {
      display: block; }
  .pagebuilder-accordion > .item.content {
    box-sizing: border-box;
    display: block;
    float: none;
    margin: 0; }
    .pagebuilder-accordion > .item.content:before, .pagebuilder-accordion > .item.content:after {
      content: '';
      display: table; }
    .pagebuilder-accordion > .item.content:after {
      clear: both; }
    .pagebuilder-accordion > .item.content.active {
      display: block; }
  .pagebuilder-accordion > .item.title {
    margin: 0 0 5px; }
  .pagebuilder-accordion > .item.title > .switch {
    background: #f0f0f0;
    border-bottom: 1px solid #E0E6EE;
    border-left: 1px solid #E0E6EE;
    border-right: 1px solid #E0E6EE;
    border-top: 1px solid #E0E6EE;
    height: 40px;
    padding: 5px 20px 5px 20px;
    color: #47e96c;
    font-weight: 600;
    line-height: 40px;
    font-size: 1.8rem;
    color: #47e96c;
    text-decoration: none; }
    .pagebuilder-accordion > .item.title > .switch:visited {
      color: #47e96c;
      text-decoration: none; }
    .pagebuilder-accordion > .item.title > .switch:hover {
      color: #47e96c;
      text-decoration: none; }
    .pagebuilder-accordion > .item.title > .switch:active {
      color: #434343;
      text-decoration: none; }
  .pagebuilder-accordion > .item.title:not(.disabled) > .switch:focus,
  .pagebuilder-accordion > .item.title:not(.disabled) > .switch:hover {
    background: #fcfcfc; }
  .pagebuilder-accordion > .item.title:not(.disabled) > .switch:active,
  .pagebuilder-accordion > .item.title.active > .switch,
  .pagebuilder-accordion > .item.title.active > .switch:focus,
  .pagebuilder-accordion > .item.title.active > .switch:hover {
    background: white;
    padding-bottom: 5px; }
  .pagebuilder-accordion > .item.content {
    background: white;
    border: 1px solid #E0E6EE;
    margin: 0 0 5px;
    padding: 20px 20px 20px 20px; }

@media only screen and (min-width: 1024px) {
  .pagebuilder-mobile-only {
    display: none !important; } }

@media only screen and (max-width: 1022px) {
  .pagebuilder-mobile-hidden {
    display: none !important; } }

.sidebar .block-addbysku .fieldset {
  margin: 0; }
  .sidebar .block-addbysku .fieldset .fields {
    position: relative; }
    .sidebar .block-addbysku .fieldset .fields .field {
      display: inline-block;
      margin-bottom: 10px;
      vertical-align: top; }
      .sidebar .block-addbysku .fieldset .fields .field.sku {
        margin-right: -85px;
        padding-right: 90px;
        width: 100%; }
      .sidebar .block-addbysku .fieldset .fields .field.qty .qty + .mage-error {
        width: 80px; }
    .sidebar .block-addbysku .fieldset .fields .actions-toolbar {
      position: absolute;
      right: 0;
      top: 6px; }

.sidebar .block-addbysku .form-addbysku .actions-toolbar {
  margin-bottom: 20px; }
  .sidebar .block-addbysku .form-addbysku .actions-toolbar .primary {
    text-align: left; }
    .sidebar .block-addbysku .form-addbysku .actions-toolbar .primary .action {
      width: auto; }
  .sidebar .block-addbysku .form-addbysku .actions-toolbar .secondary {
    float: none;
    text-align: left; }

.sidebar .block-addbysku .action.add {
  display: inline-block;
  text-decoration: none;
  line-height: normal;
  padding: 2px 0;
  width: auto; }
  .sidebar .block-addbysku .action.add > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
  .sidebar .block-addbysku .action.add:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 31px;
    line-height: 22px;
    color: inherit;
    content: "";
    font-family: "Material Icons";
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center; }
  .sidebar .block-addbysku .action.add:hover:before {
    color: inherit; }
  .sidebar .block-addbysku .action.add:active:before {
    color: inherit; }

.sidebar .block-addbysku .action.links {
  display: inline-block;
  margin: 20px 0 0; }

.sidebar .block-addbysku .action.reset {
  display: block;
  margin: 10px 0; }

.sidebar .block-addbysku .action.remove {
  display: inline-block;
  text-decoration: none;
  background-image: none;
  background: none;
  -moz-box-sizing: content-box;
  border: 0;
  box-shadow: none;
  line-height: inherit;
  margin: 0;
  padding: 0;
  text-decoration: none;
  text-shadow: none;
  font-weight: 400; }
  .sidebar .block-addbysku .action.remove > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
  .sidebar .block-addbysku .action.remove:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 28px;
    line-height: 22px;
    color: inherit;
    content: "";
    font-family: "Material Icons";
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center; }
  .sidebar .block-addbysku .action.remove:hover:before {
    color: inherit; }
  .sidebar .block-addbysku .action.remove:active:before {
    color: inherit; }
  .sidebar .block-addbysku .action.remove:focus, .sidebar .block-addbysku .action.remove:active {
    background: none;
    border: none; }
  .sidebar .block-addbysku .action.remove:hover {
    background: none;
    border: none; }
  .sidebar .block-addbysku .action.remove.disabled, .sidebar .block-addbysku .action.remove[disabled],
  fieldset[disabled] .sidebar .block-addbysku .action.remove {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5; }

.block-banners .banner-item,
.block-banners-inline .banner-item {
  display: block; }

.block-banners .banner-item-content,
.block-banners-inline .banner-item-content {
  margin-bottom: 20px; }
  .block-banners .banner-item-content img,
  .block-banners-inline .banner-item-content img {
    display: block;
    margin: 0 auto; }

.block-product-link.widget,
.block-category-link.widget {
  display: block;
  margin-bottom: 20px; }

.block-product-link-inline.widget {
  margin: 0; }

.block.widget .product-item-info {
  width: auto; }

.block.widget .pager {
  padding: 0; }
  .block.widget .pager .toolbar-amount {
    float: none;
    font-size: 1.2rem; }
  .block.widget .pager .pages-item-previous {
    padding-left: 0; }
  .block.widget .pager .pages-item-next {
    position: relative; }
  .block.widget .pager .items {
    white-space: nowrap; }

.block.widget .products.wrapper.grid.products-grid {
  margin: 30px 0; }
  .block.widget .products.wrapper.grid.products-grid .product.photo.product-item-photo {
    height: 220px;
    display: flex;
    justify-content: center; }
  .block.widget .products.wrapper.grid.products-grid a.product-item-link, .block.widget .products.wrapper.grid.products-grid .product-item-details .product-item-inner {
    display: flex;
    justify-content: center;
    text-align: center; }

@media only screen and (min-width: 768px) {
  .block.widget .products-grid .product-item,
  .page-layout-1column .block.widget .products-grid .product-item,
  .page-layout-3columns .block.widget .products-grid .product-item {
    width: 33.33333%; } }

@media only screen and (min-width: 1024px) {
  .block.widget .products-grid .product-item {
    width: 33.33333%; }
    .sidebar .block.widget .products-grid .product-item {
      margin-left: 0;
      width: 100%; }
      .sidebar .block.widget .products-grid .product-item .actions-secondary {
        display: block;
        padding: 10px 0; }
  .page-layout-1column .block.widget .products-grid .product-item {
    width: 25%; }
  .page-layout-3columns .block.widget .products-grid .product-item {
    width: 50%; } }

@media only screen and (min-width: 1200px) {
  .block.widget .products-grid .product-item {
    width: 25%; }
  .page-layout-1column .block.widget .products-grid .product-item {
    width: 16.66667%; }
  .page-layout-3columns .block.widget .products-grid .product-item {
    width: 25%; }
  .block.widget .products-grid .product-items {
    margin: 0; }
  .block.widget .products-grid .product-item:nth-child(4n + 1) {
    margin-left: 0; } }

@media only screen and (max-width: 1023px) {
  .block.widget .products-grid .widget-product-grid {
    margin-left: 0; } }

@media only screen and (max-width: 1199px) {
  .catalog-product-view .block.widget .products.wrapper.grid.products-grid {
    margin-left: -5px; } }

@media only screen and (max-width: 1439px) {
  .block.widget .products.wrapper.grid.products-grid {
    margin: 30px 0;
    padding: 0 15px; }
    .block.widget .products.wrapper.grid.products-grid .product.photo.product-item-photo {
      height: 150px; } }

@media only screen and (min-width: 1024px) {
  .sidebar .block.widget .pager .pages-item-next {
    padding: 0; }
    .sidebar .block.widget .pager .pages-item-next .action {
      margin: 0; } }

@media only screen and (min-width: 1200px) {
  .block.widget .products-grid .product-item {
    width: 24.1%; } }

.columns .block-event {
  position: relative;
  z-index: 2; }

.block-event .block-content {
  position: relative; }
  .block-event .block-content > .action {
    cursor: pointer;
    margin-top: -6px;
    position: absolute;
    top: 50%;
    z-index: 3; }
    .block-event .block-content > .action.backward, .block-event .block-content > .action.forward {
      opacity: 0.5;
      overflow: hidden;
      width: 20px;
      display: inline-block;
      text-decoration: none; }
      .block-event .block-content > .action.backward > span, .block-event .block-content > .action.forward > span {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px; }
      .block-event .block-content > .action.backward:before, .block-event .block-content > .action.forward:before {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 55px;
        line-height: 30px;
        color: #a2a2a2;
        content: "";
        font-family: "Material Icons";
        vertical-align: middle;
        display: inline-block;
        font-weight: normal;
        overflow: hidden;
        speak: none;
        text-align: center; }
      .block-event .block-content > .action.backward:before, .block-event .block-content > .action.forward:before {
        margin-left: -15px; }
    .block-event .block-content > .action.backward {
      left: 0; }
    .block-event .block-content > .action.forward {
      right: 0; }
      .block-event .block-content > .action.forward:before {
        content: ""; }
      .block-event .block-content > .action.forward:before {
        margin-left: -20px; }
    .block-event .block-content > .action.inactive {
      cursor: default;
      display: none; }
    .block-event .block-content > .action:hover {
      opacity: 1; }

.block-event .slider-panel {
  overflow: hidden;
  position: relative;
  z-index: 2; }
  .block-event .slider-panel .slider {
    white-space: nowrap; }
    .block-event .slider-panel .slider .item {
      background: #f0f0f0;
      display: inline-block;
      text-align: center;
      vertical-align: top;
      white-space: normal;
      width: 16.35%; }
      .sidebar-main .block-event .slider-panel .slider .item,
      .sidebar-additional .block-event .slider-panel .slider .item {
        width: 100%; }
      .block-event .slider-panel .slider .item img {
        max-width: 100%; }
      .block-event .slider-panel .slider .item .category-name {
        display: inline-block;
        font-size: 1.8rem;
        padding: 15px 5px 0;
        word-break: break-all; }
      .block-event .slider-panel .slider .item .ticker {
        margin: 0;
        padding: 0;
        list-style: none none; }
        .block-event .slider-panel .slider .item .ticker li {
          display: none;
          margin: 0 5px; }
        .block-event .slider-panel .slider .item .ticker .value {
          font-size: 2.6rem;
          font-weight: 300; }
        .block-event .slider-panel .slider .item .ticker .label {
          display: block;
          font-size: 1.3rem; }
      .block-event .slider-panel .slider .item .dates {
        font-weight: 300; }
        .block-event .slider-panel .slider .item .dates .start,
        .block-event .slider-panel .slider .item .dates .end,
        .block-event .slider-panel .slider .item .dates .date {
          display: block; }
        .block-event .slider-panel .slider .item .dates .start:after {
          font-size: 1.8rem;
          content: '\2013';
          display: block; }
        .block-event .slider-panel .slider .item .dates .date {
          font-size: 1.8rem;
          font-weight: 300; }
        .block-event .slider-panel .slider .item .dates .time {
          font-size: 1.3rem; }
      .block-event .slider-panel .slider .item .box-event .box-content {
        padding: 5px 0 10px; }
      .block-event .slider-panel .slider .item .box-event .box-title {
        display: block;
        font-weight: 300;
        margin: 10px 0 0; }
      .block-event .slider-panel .slider .item .action.show {
        display: block;
        padding: 0; }
        .block-event .slider-panel .slider .item .action.show .category.name {
          display: inline-block;
          padding: 30px 10px 0; }
        .block-event .slider-panel .slider .item .action.show img {
          display: block; }
      .block-event .slider-panel .slider .item .action.event {
        background-image: none;
        background: #1979c3;
        border: 1px solid #1979c3;
        color: #fff;
        cursor: pointer;
        display: inline-block;
        font-family: "Roboto", Helvetica, sans-serif;
        font-weight: 400;
        padding: 7px 15px;
        font-size: 1.4rem;
        box-sizing: border-box;
        vertical-align: middle;
        margin-top: 15px; }
        .block-event .slider-panel .slider .item .action.event:focus, .block-event .slider-panel .slider .item .action.event:active {
          background: #006bb4;
          border: 1px solid #006bb4;
          color: #fff; }
        .block-event .slider-panel .slider .item .action.event:hover {
          background: #006bb4;
          border: 1px solid #006bb4;
          color: #fff; }
        .block-event .slider-panel .slider .item .action.event.disabled, .block-event .slider-panel .slider .item .action.event[disabled],
        fieldset[disabled] .block-event .slider-panel .slider .item .action.event {
          opacity: 0.5;
          cursor: default;
          pointer-events: none; }
        .block-event .slider-panel .slider .item .action.event:hover {
          text-decoration: none; }

.block-static-block.widget,
.block-cms-link.widget {
  margin-bottom: 20px; }
  .links .block-static-block.widget, .links
  .block-cms-link.widget {
    margin-bottom: 0; }

.block-cms-link-inline.widget {
  margin: 0; }

.block-wishlist-search .form-wishlist-search {
  margin: 20px 0 0; }

.block-cms-hierarchy-link.widget {
  display: block;
  margin-bottom: 20px; }

.block-cms-hierarchy-link-inline.widget {
  margin: 0; }

/* apply a natural box layout model to all elements, but allowing components to change */
html {
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit; }

hr {
  display: block;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-left: auto;
  margin-right: auto;
  border-style: inset;
  border-width: 1px; }

.product.data.items > .item.title > .switch {
  box-sizing: content-box; }

html button, html .cart.table-wrapper .actions-toolbar > .action, .cart.table-wrapper html .actions-toolbar > .action, html .action-gift,
html select,
html .cart.table-wrapper .actions-toolbar > .action,
html .cart.table-wrapper .action-gift,
html [type="reset"],
html [type="submit"],
html [type="button"], body button, body .cart.table-wrapper .actions-toolbar > .action, .cart.table-wrapper body .actions-toolbar > .action, body .action-gift,
body select,
body .cart.table-wrapper .actions-toolbar > .action,
body .cart.table-wrapper .action-gift,
body [type="reset"],
body [type="submit"],
body [type="button"] {
  appearance: none !important; }

/*
*
* Reset the static block widget so the breadcrumbs does not look off
*
*/
.top-container .block-static-block.widget {
  margin-bottom: 0;
  margin-left: -1rem;
  margin-right: -1rem; }

input[type="radio"]:checked {
  background: #118b2d; }

input[type="radio"] {
  background: #ffffff;
  border: 2px solid #3b3f49;
  width: 1rem;
  height: 1rem;
  border-radius: 50%; }

.footer-logo .nav-toggle {
  display: none !important; }

.footer-logo .logo {
  display: block;
  margin: 2rem 0; }

/**
*
* Newsletter block/componenet
*
*/
.block .field.newsletter div.control:before {
  line-height: 44px; }

#newsletter {
  height: 44px; }

/*
 see Snowdog/blank/styles/mixins/_media-queries.scss
 screen range for tablets/halfscreen
*/
@media only screen and (min-width: 1024px) and (max-width: 1440px) {
  .page-wrapper .breadcrumbs {
    padding-left: 1rem;
    padding-right: 1rem; } }

@media only screen and (max-width: 1023px) {
  /*
     *
     *  Patch for mobile modal slide error
     *  @see _modals.scss
     *
     */
  body .custom-slide {
    left: 0 !important; }
  #opc-sidebar .minicart-items {
    background: white; }
  #opc-sidebar .product-item > .product {
    display: flex;
    flex-wrap: wrap;
    padding: 1rem; }
    #opc-sidebar .product-item > .product .product-item-details {
      padding-left: 0; } }

.modal-popup._show, .modal-slide._show {
  background: rgba(0, 0, 0, 0.2); }

.polarcore__full-width-top-container .widget.block-static-block {
  padding: 0;
  margin: 0;
  margin-bottom: 0; }

.polarcore__full-width-top-container img {
  width: 100%; }

.polarcore-header-links {
  grid-column: 5;
  -ms-grid-column: 5;
  -ms-grid-row: 2;
  display: grid;
  display: -ms-grid;
  grid-template-columns: 1fr;
  -ms-grid-columns: 1fr; }
  .polarcore-header-links .wishlist-link,
  .polarcore-header-links .account-link {
    display: none; }
  .polarcore-header-links .desktop-price-widget {
    display: none;
    grid-column: 2;
    order: 2;
    -ms-grid-column: 2;
    line-height: 1.5em; }
    .polarcore-header-links .desktop-price-widget .price-subtotal .label {
      display: none !important; }
    .polarcore-header-links .desktop-price-widget .price-subtotal, .polarcore-header-links .desktop-price-widget .price-subtotal * {
      font-size: 95%; }

.prescription-link a,
.bandagist-picker button,
.bandagist-picker .cart.table-wrapper .actions-toolbar > .action,
.cart.table-wrapper .bandagist-picker .actions-toolbar > .action,
.bandagist-picker .action-gift {
  display: inline-block;
  line-height: 1.1;
  font-weight: 600;
  background: none;
  box-shadow: none;
  border-radius: 0;
  border-bottom: 1px solid;
  padding: 0 0 0.3rem 0;
  color: #118b2d; }
  .prescription-link a, .prescription-link a:focus, .prescription-link a:active, .prescription-link a:visited,
  .bandagist-picker button,
  .bandagist-picker .cart.table-wrapper .actions-toolbar > .action,
  .cart.table-wrapper .bandagist-picker .actions-toolbar > .action,
  .bandagist-picker .action-gift,
  .bandagist-picker button:focus,
  .bandagist-picker .cart.table-wrapper .actions-toolbar > .action:focus,
  .cart.table-wrapper .bandagist-picker .actions-toolbar > .action:focus,
  .bandagist-picker .action-gift:focus,
  .bandagist-picker button:active,
  .bandagist-picker .cart.table-wrapper .actions-toolbar > .action:active,
  .cart.table-wrapper .bandagist-picker .actions-toolbar > .action:active,
  .bandagist-picker .action-gift:active,
  .bandagist-picker button:visited,
  .bandagist-picker .cart.table-wrapper .actions-toolbar > .action:visited,
  .cart.table-wrapper .bandagist-picker .actions-toolbar > .action:visited,
  .bandagist-picker .action-gift:visited {
    text-decoration: none;
    color: #0e7426; }

.prescription-link,
.bandagist-text {
  display: none; }

.prescription-link {
  grid-column: 2;
  -ms-grid-column: 2;
  text-align: center; }

.bandagist-picker {
  grid-column: 4;
  -ms-grid-column: 4;
  text-align: center; }

.prescription-link,
.bandagist-picker {
  -ms-grid-row: 2; }

.bandagist-text {
  order: -1;
  grid-column: 1 / span 5;
  -ms-grid-column: 1;
  -ms-grid-column-span: 5;
  -ms-grid-row: 1;
  width: 100%;
  margin: 0 auto;
  padding: 0.5rem 1rem;
  line-height: 1;
  font-weight: 700;
  text-align: center;
  background-color: #dedede;
  border: 10px solid white;
  border-top: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 -5px 5px -5px rgba(0, 0, 0, 0.3); }

span.bandagist-picker-address-target {
  display: block;
  font-size: 12px;
  margin-top: 5px; }

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .prescription-link {
    grid-column: 3;
    -ms-grid-column: 3; }
  .bandagist-text,
  .bandagist-picker {
    grid-column: 5;
    -ms-grid-column: 5; }
  .prescription-link,
  .bandagist-picker {
    grid-row: 2;
    -ms-grid-row: 2;
    margin-bottom: 1rem; }
  .bandagist-text {
    padding: 0 1rem;
    grid-row: 1;
    -ms-grid-row: 1; }
  .polarcore-header-links {
    grid-column: 6;
    -ms-grid-column: 6;
    grid-row: 1 / span 2;
    -ms-grid-row: 1;
    -ms-grid-row-span: 2; } }

@media only screen and (min-width: 768px) {
  .header.content .prescription-link {
    margin-left: auto;
    margin-right: auto; }
  .header.content .bandagist-text {
    background: none;
    box-shadow: none;
    border: none; } }

@media only screen and (min-width: 1200px) {
  .prescription-link {
    grid-column: 2;
    -ms-grid-column: 2;
    -ms-grid-row: 2; }
  .bandagist-text,
  .bandagist-picker {
    grid-column: 4;
    -ms-grid-column: 4; }
  .bandagist-text {
    -ms-grid-row: 1; }
  .bandagist-picker {
    -ms-grid-row: 2; }
  .prescription-link,
  .bandagist-picker {
    margin-bottom: 1.5rem; }
  .polarcore-header-links {
    grid-column: 5;
    grid-row: 1 / span 2;
    -ms-grid-column: 5;
    -ms-grid-row: 1;
    -ms-grid-row-span: 2;
    grid-template-columns: 3rem 2fr;
    -ms-grid-columns: 3rem 2fr; }
    .polarcore-header-links .desktop-price-widget {
      display: block;
      cursor: pointer; } }

@media only screen and (max-width: 1199px) {
  .block-search .form.minisearch {
    display: flex; }
  .block-search .field.search {
    width: 100%;
    height: 38px; }
  .block-search .actions button, .block-search .actions .cart.table-wrapper .actions-toolbar > .action, .cart.table-wrapper .block-search .actions .actions-toolbar > .action, .block-search .actions .action-gift {
    background: transparent;
    border: 1px solid #c2c2c2;
    border-left: 0;
    opacity: 1; }
    .block-search .actions button span, .block-search .actions .cart.table-wrapper .actions-toolbar > .action span, .cart.table-wrapper .block-search .actions .actions-toolbar > .action span, .block-search .actions .action-gift span {
      display: none; } }

@media only screen and (max-width: 767px) {
  .header.content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    .header.content .bandagist-text {
      order: 0;
      width: 100%;
      background: #00904a;
      color: #fff; }
      .header.content .bandagist-text span:empty {
        display: none !important; }
    .header.content .block-search {
      order: 6;
      width: 100%;
      margin-left: 1.75rem; }
    .header.content .nav-toggle {
      order: 2;
      min-width: 33.3%; }
    .header.content .logo {
      order: 3; }
    .header.content .bandagist-picker {
      order: 4; }
    .header.content .polarcore-header-links {
      order: 5;
      min-width: 25px; }
    .header.content .block-search .form.minisearch {
      width: 100%; }
    .header.content .block-search .field.search {
      width: inherit; } }

@media only screen and (max-width: 767px) {
  .logo {
    top: 0; }
    .logo img {
      max-width: 40%;
      margin: 4px; }
  .header.content .logo {
    width: 100%;
    max-width: 30%; } }

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .logo {
    top: 0; } }

.rating-summary .rating-label {
  padding-right: 0.5rem; }

.rating-summary .rating-result {
  min-width: 72px;
  margin-left: 0 !important; }

.polarcore .products {
  margin: 0.5rem -0.5rem; }

.polarcore a.product-item-link {
  white-space: normal;
  width: 100%;
  text-overflow: unset; }

.polarcore .product-item .special-price .price,
.polarcore .product-item .minimal-price .price {
  color: #dc2b2b; }

.polarcore .products-grid .product-item {
  position: relative;
  padding: 2%;
  width: calc(50% - 10px);
  border: none;
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.2);
  margin: 5px; }
  .polarcore .products-grid .product-item-info {
    padding: 1rem;
    border-radius: 0.2rem;
    margin: 0 0.5rem 0.5rem 0; }
    .polarcore .products-grid .product-item-info .regular-price {
      text-decoration: line-through; }
    .polarcore .products-grid .product-item-info .special-price {
      color: #dc2b2b; }
    .polarcore .products-grid .product-item-info .product-item-details .product-item-inner {
      width: 100%; }
  .polarcore .products-grid .product-item .price {
    font-size: 1.5em;
    font-weight: 700; }
    .polarcore .products-grid .product-item .price-box {
      position: relative;
      width: 100%;
      justify-content: unset;
      margin-top: 1rem;
      text-align: left; }
    .polarcore .products-grid .product-item .price .old-price {
      top: -1.5rem;
      right: 0;
      z-index: 1;
      text-decoration: line-through; }
      .polarcore .products-grid .product-item .price .old-price {
        margin-left: 1rem;
        color: #474747;
        font-size: 18px; }
  .polarcore .products-grid .product-item-details {
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: center;
    padding-top: 0.5rem; }
    .products-list .polarcore .products-grid .product-item-details {
      text-align: left; }
    .polarcore .products-grid .product-item-details__top {
      display: flex;
      flex-wrap: wrap;
      width: 100%; }
    .polarcore .products-grid .product-item-details span.new-price-label {
      font-size: 12px;
      height: fit-content;
      margin-top: 10px; }
  .polarcore .products-grid .product-item-actions {
    width: 100%; }
    .polarcore .products-grid .product-item-actions .actions-primary {
      width: 100%; }
      .polarcore .products-grid .product-item-actions .actions-primary button.action.tocart, .polarcore .products-grid .product-item-actions .actions-primary .cart.table-wrapper .actions-toolbar > .action.tocart, .cart.table-wrapper .polarcore .products-grid .product-item-actions .actions-primary .actions-toolbar > .action.tocart, .polarcore .products-grid .product-item-actions .actions-primary .action.tocart.action-gift {
        width: 100%; }
  .polarcore .products-grid .product-item-name {
    width: 100%;
    height: 5rem;
    margin: 0;
    padding-right: 0.5rem;
    font-size: 18px;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    line-clamp: 3;
    box-orient: vertical; }
  .polarcore .products-grid .product-item-actions {
    display: flex;
    justify-content: center;
    align-items: center; }
    .polarcore .products-grid .product-item-actions .actions-secondary {
      display: none; }
    .polarcore .products-grid .product-item-actions .products-list .actions-primary + .actions-secondary {
      width: auto;
      margin-left: 1rem;
      margin-right: auto; }
  .polarcore .products-grid .product-item .button-link {
    width: 70%;
    margin: 1rem auto 0 auto; }
    .polarcore .products-grid .product-item .button-link .action.tocart.primary {
      width: 100%;
      background: #00904a none;
      border: #00904a;
      color: #ffffff;
      cursor: pointer;
      display: inline-block;
      margin: 0;
      font-size: 14px;
      line-height: 16px;
      box-sizing: border-box;
      vertical-align: middle;
      padding: 0.7rem;
      text-transform: uppercase;
      font-weight: 400;
      border-radius: 3px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.35); }
      .polarcore .products-grid .product-item .button-link .action.tocart.primary:before {
        display: none; }
      .polarcore .products-grid .product-item .button-link .action.tocart.primary span {
        position: relative;
        width: auto;
        font-size: 15px;
        text-transform: none; }

.polarcore .products-grid .product-items {
  display: flex;
  flex-wrap: wrap;
  padding: 0; }

.polarcore .toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0;
  width: 100%; }
  .polarcore .toolbar:before, .polarcore .toolbar:after {
    display: none; }
  .polarcore .toolbar .sorter {
    position: unset;
    width: auto;
    border-radius: 3px;
    background-color: #e8e8e8;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.45); }
    .polarcore .toolbar .sorter-options {
      width: 100%;
      padding: 0 0.5rem;
      border: none;
      box-shadow: none;
      background-color: transparent;
      font-family: "Raleway";
      font-size: 16px;
      height: 35px; }
    .polarcore .toolbar .sorter .sorter-label {
      display: none; }

.polarcore .products-grid.products-crosssell .product-item {
  padding: 1rem;
  width: 100%;
  min-width: auto; }
  @media only screen and (min-width: 480px) {
    .polarcore .products-grid.products-crosssell .product-item {
      width: calc(100% / 2); } }
  @media only screen and (min-width: 768px) {
    .polarcore .products-grid.products-crosssell .product-item {
      width: calc(100% / 3); } }
  @media only screen and (min-width: 1024px) {
    .polarcore .products-grid.products-crosssell .product-item {
      width: calc(100% / 4); } }
  @media only screen and (min-width: 1200px) {
    .polarcore .products-grid.products-crosssell .product-item {
      width: calc(100% / 5); } }

@media only screen and (min-width: 1200px) {
  .polarcore .products-grid .product-item {
    padding: 1rem;
    width: calc(33.3333% - 10px);
    margin-left: 5px;
    margin-right: 5px; } }

@media only screen and (min-width: 1440px) {
  .polarcore .products-grid .product-item {
    width: calc(25% - 10px);
    margin-left: 5px;
    margin-right: 5px; } }

@media only screen and (max-width: 767px) {
  .polarcore .products-grid .product-item {
    margin-left: 5px;
    margin-right: 5px; }
    .polarcore .products-grid .product-item-info {
      margin: 0;
      height: 350px; }
      .polarcore .products-grid .product-item-info .product-image-container .product-image-wrapper {
        height: 150px; }
    .polarcore .products-grid .product-item .price {
      font-size: 1.5rem; }
    .polarcore .products-grid .product-item-actions {
      width: 90%; }
    .polarcore .products-grid .product-item .button-link {
      width: 70%; } }

.product-item-actions .actions-primary .stock.unavailable {
  width: 100%;
  text-align: center;
  background-color: #f3f3f3;
  color: #9e9e9e;
  display: block;
  height: 32px;
  line-height: 33px;
  text-decoration: none;
  text-align: center;
  max-width: 100%;
  pointer-events: none; }

.polarcore .products-grid .product-item-details span.new-price-label {
  font-size: 12px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-top: 10px; }

.product-item .special-price {
  display: inline-block; }

.block-static-block .products .product-item-info .price-box .price-wrapper .special-price {
  margin-left: 10px; }

.block-static-block .products .product-item-info .price-box .price-wrapper .regular-price {
  font-weight: 400; }

.toolbar select.sorter-options {
  background-color: #f6f6f6;
  box-shadow: none;
  border-radius: 0px;
  padding: 0 2rem;
  margin: 0;
  border-bottom: 3px solid #b5b5b5;
  -webkit-appearance: menulist !important;
  font-size: 14px;
  font-weight: 400;
  color: #000;
  width: 150px;
  height: 40px; }

.toolbar .action.sorter-action {
  display: none; }

/**
*
* Mobile
*
*/
@media only screen and (max-width: 1023px) {
  .filter-title strong {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px; }
  .toolbar select.sorter-options {
    height: 40px;
    width: calc(100% - 3rem);
    text-align: center;
    font-size: 14px;
    background-color: #ffffff;
    border: 1px solid #474747;
    border-bottom: 2px solid;
    border-radius: 4px; } }

/*
*
* Common Product View Extensions
*
*/
.product-info-main ._keyfocus *:focus, .product-info-main input:not([disabled]):focus,
.product-info-main textarea:not([disabled]):focus, .product-info-main select:not([disabled]):focus {
  box-shadow: none; }

.product-info-main select {
  height: 40px; }

.product-info-main .product-reviews-summary {
  padding: 1rem 0; }
  .product-info-main .product-reviews-summary.empty {
    display: none; }

.product-info-main .product-info-price {
  padding: 1rem 0; }
  .product-info-main .product-info-price .special-price {
    font-weight: 500;
    margin-right: 1rem;
    color: #DC2B2B; }

.product-info-main .bundle-actions {
  margin-top: 1.5rem; }

.product-info-main .page-title-wrapper .page-title {
  font-size: 26px;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 0; }

.product-info-main .product-info-stock-sku {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  /* remove stock, request from Cecilie */ }
  .product-info-main .product-info-stock-sku .stock {
    display: none; }
  .product-info-main .product-info-stock-sku .stock.available span {
    color: #49ba83;
    font-weight: 500;
    font-size: 12px;
    display: flex;
    align-items: center;
    text-transform: none; }
    .product-info-main .product-info-stock-sku .stock.available span:before {
      content: "";
      font-family: "Material Icons";
      vertical-align: top;
      margin-right: 0.25rem;
      font-size: 16px; }
  .product-info-main .product-info-stock-sku .banda_manufacturer {
    display: flex;
    margin-left: auto; }
    .product-info-main .product-info-stock-sku .banda_manufacturer .label {
      margin-right: 3px; }

.product-info-main product.attribute.sku {
  float: right; }

.product-info-main .prices-tier {
  display: none; }

.product-info-main .price-box {
  display: flex; }

span.old-price.sly-old-price span.price:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  background: #434343;
  left: 0;
  top: 54%; }

span.old-price.sly-old-price span.price {
  position: relative; }

span.old-price.sly-old-price {
  display: block !important;
  margin-top: 0; }

.old-price,
.old.price {
  text-decoration: none; }

.product-add-form button.sold-out-button, .product-add-form .cart.table-wrapper .actions-toolbar > .sold-out-button.action, .cart.table-wrapper .product-add-form .actions-toolbar > .sold-out-button.action, .product-add-form .sold-out-button.action-gift {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 37px;
  background-color: #e8e8e8;
  font-size: 14px;
  color: #333333;
  text-align: center;
  width: 100%;
  margin: 1rem auto 1rem auto;
  border-radius: 3px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.35); }

.product-info-main .box-tocart,
.product-options-bottom .box-tocart {
  width: 100%; }
  .product-info-main .box-tocart .input-text.qty,
  .product-options-bottom .box-tocart .input-text.qty {
    border-radius: 0; }
  .product-info-main .box-tocart .form,
  .product-options-bottom .box-tocart .form {
    position: relative; }
  .product-info-main .box-tocart .control,
  .product-options-bottom .box-tocart .control {
    display: flex;
    padding-right: 1rem;
    width: 100% !important;
    float: none !important; }
    .product-info-main .box-tocart .control button, .product-info-main .box-tocart .control .cart.table-wrapper .actions-toolbar > .action, .cart.table-wrapper .product-info-main .box-tocart .control .actions-toolbar > .action, .product-info-main .box-tocart .control .action-gift, .product-info-main .box-tocart .control input,
    .product-options-bottom .box-tocart .control button,
    .product-options-bottom .box-tocart .control .cart.table-wrapper .actions-toolbar > .action,
    .cart.table-wrapper .product-options-bottom .box-tocart .control .actions-toolbar > .action,
    .product-options-bottom .box-tocart .control .action-gift,
    .product-options-bottom .box-tocart .control input {
      background: white;
      font-size: 19px !important;
      border-radius: 0;
      border: 1px solid #c8c8c8;
      border-color: #c8c8c8 !important;
      height: 48px !important;
      width: 48px !important;
      display: flex;
      justify-content: center;
      align-items: center; }
    .product-info-main .box-tocart .control input,
    .product-options-bottom .box-tocart .control input {
      border-left: 0 !important;
      border-right: 0 !important; }
  .product-info-main .box-tocart .input-text.qty,
  .product-options-bottom .box-tocart .input-text.qty {
    border-radius: 0;
    font-size: 12px; }
  .product-info-main .box-tocart .fieldset,
  .product-info-main .box-tocart .actions,
  .product-options-bottom .box-tocart .fieldset,
  .product-options-bottom .box-tocart .actions {
    display: flex;
    width: 100%;
    align-items: flex-end; }
  .product-info-main .box-tocart .product-info-price,
  .product-options-bottom .box-tocart .product-info-price {
    margin-bottom: 2rem; }
  .product-info-main .box-tocart .actions .action.primary.tocart,
  .product-options-bottom .box-tocart .actions .action.primary.tocart {
    width: 100%;
    height: 48px; }
  .product-info-main .box-tocart .field.qty,
  .product-options-bottom .box-tocart .field.qty {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: auto;
    position: relative;
    margin: 0; }
    .product-info-main .box-tocart .field.qty .label,
    .product-options-bottom .box-tocart .field.qty .label {
      display: none; }

.product-social-links .action.towishlist span {
  display: flex;
  justify-content: center;
  align-items: center; }

.product-social-links .action.towishlist span:before {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  content: "";
  font-size: 1em !important;
  transform: scale(1.5);
  padding-right: 0.5rem; }

.actions .action.primary.tocart span {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px; }

.actions .action.primary.tocart span:before {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  content: "";
  font-size: 1em !important;
  transform: scale(1.75);
  padding-right: 0.5rem;
  /* should be hidden some other way probably */
  display: none !important; }

.product.info.detailed .data.item.title {
  margin: 0;
  margin-top: 1rem;
  border: 1px solid #c8c8c8; }
  .product.info.detailed .data.item.title .switch {
    position: relative;
    background: #fff;
    color: #1a1a1a;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 600; }
  .product.info.detailed .data.item.title .switch:after {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    font-feature-settings: 'liga';
    content: "";
    position: absolute;
    right: 1rem;
    top: 1rem;
    font-size: 32px !important;
    line-height: 0.6 !important;
    bottom: auto;
    left: auto;
    z-index: 10; }
  .product.info.detailed .data.item.title.active {
    border-bottom: 0; }
    .product.info.detailed .data.item.title.active .switch:after {
      font-family: 'Material Icons';
      font-weight: normal;
      font-style: normal;
      font-size: 24px;
      /* Preferred icon size */
      display: inline-block;
      line-height: 1;
      text-transform: none;
      letter-spacing: normal;
      word-wrap: normal;
      white-space: nowrap;
      direction: ltr;
      /* Support for all WebKit browsers. */
      -webkit-font-smoothing: antialiased;
      /* Support for Safari and Chrome. */
      text-rendering: optimizeLegibility;
      /* Support for Firefox. */
      -moz-osx-font-smoothing: grayscale;
      /* Support for IE. */
      font-feature-settings: 'liga';
      content: ""; }

.product.info.detailed .data.item.content {
  border: 1px solid #c8c8c8;
  border-top: 0; }
  .product.info.detailed .data.item.content #show-more-description {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 37px;
    background-color: #e8e8e8;
    font-size: 14px;
    color: #333333;
    text-align: center;
    width: 60%;
    margin: 1rem auto 1rem auto;
    border-radius: 3px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.35); }
  .product.info.detailed .data.item.content .inner.show-more {
    max-height: 80px;
    overflow: hidden;
    position: relative; }
    .product.info.detailed .data.item.content .inner.show-more:before {
      content: '';
      position: absolute;
      bottom: 0px;
      left: 0;
      right: 0;
      margin: 0;
      height: 50px;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0), white); }

.product.info.detailed .review-add,
.product.info.detailed #product-review-container {
  padding: 1rem;
  width: 100%; }

/* picture gallery background request from Cecilie */
.mz-expand .mz-expand-stage {
  background: #000; }

.review-container .review-add .review-form-actions button.submit, .review-container .review-add .review-form-actions .cart.table-wrapper .actions-toolbar > .submit.action, .cart.table-wrapper .review-container .review-add .review-form-actions .actions-toolbar > .submit.action, .review-container .review-add .review-form-actions .submit.action-gift {
  border: 1px solid #118b2d;
  background: #fff;
  color: #118b2d;
  transition: all 200ms ease;
  box-shadow: none; }
  .review-container .review-add .review-form-actions button.submit:hover, .review-container .review-add .review-form-actions .cart.table-wrapper .actions-toolbar > .submit.action:hover, .cart.table-wrapper .review-container .review-add .review-form-actions .actions-toolbar > .submit.action:hover, .review-container .review-add .review-form-actions .submit.action-gift:hover {
    background: #118b2d;
    color: #fff;
    border: 1px solid #118b2d; }

/*
*
* Mobile only
*
*/
@media only screen and (max-width: 1023px) {
  .product-info-main {
    padding-left: 1rem;
    padding-right: 1rem; }
  .product.info.detailed #reviews {
    flex-direction: column; }
  .actions .action.primary.tocart span {
    font-size: 1.5rem; } }

/*
*
* Common Footer
*
*/
.footer.content {
  display: flex;
  flex-wrap: wrap; }
  .footer.content > * {
    flex-grow: 1;
    padding: 1rem 2rem; }
    .footer.content > * .block-static-block {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: center; }
      .footer.content > * .block-static-block > div {
        width: 100%; }
      .footer.content > * .block-static-block .footer-social li {
        padding-bottom: 1rem; }
      .footer.content > * .block-static-block .footer-social a img {
        width: 20px;
        vertical-align: middle;
        margin-right: 1rem; }
      .footer.content > * .block-static-block .footer-social a:before {
        display: none; }
    .footer.content > * h2 {
      width: 100%;
      color: #ffffff;
      padding-bottom: 2rem;
      font-size: 14px; }
    .footer.content > * li {
      padding-bottom: 0.5rem;
      font-size: 12px; }
    .footer.content > * a {
      font-weight: 300;
      color: #f0f0f0; }
      .footer.content > * a.footer-store-link {
        color: #1979c3;
        text-decoration: underline; }
      .footer.content > * a:before {
        font-family: 'Material Icons';
        font-weight: normal;
        font-style: normal;
        font-size: 24px;
        /* Preferred icon size */
        display: inline-block;
        line-height: 1;
        text-transform: none;
        letter-spacing: normal;
        word-wrap: normal;
        white-space: nowrap;
        direction: ltr;
        /* Support for all WebKit browsers. */
        -webkit-font-smoothing: antialiased;
        /* Support for Safari and Chrome. */
        text-rendering: optimizeLegibility;
        /* Support for Firefox. */
        -moz-osx-font-smoothing: grayscale;
        /* Support for IE. */
        font-feature-settings: 'liga';
        content: "";
        vertical-align: middle;
        font-size: 20px;
        margin-left: -5px; }
  .footer.content .full-width {
    width: 100%; }
  .footer.content .customer-service .block-static-block ul {
    width: 50%; }

.page-footer {
  color: #f0f0f0; }
  .page-footer .copyright {
    padding: 20px;
    border-top: 1px solid #666666; }

/*
*
* Footer Mobile up
*
*/
@media only screen and (min-width: 1024px) {
  .page-footer .editorial {
    border-left: 1px solid #666666;
    border-right: 1px solid #666666; } }

.wishlist-link {
  margin-left: 1rem; }
  .wishlist-link .wishlist-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: inherit; }
  .wishlist-link .wishlist-icon {
    line-height: 10px; }
    .wishlist-link .wishlist-icon:before {
      font-family: 'Material Icons';
      font-weight: normal;
      font-style: normal;
      font-size: 24px;
      /* Preferred icon size */
      display: inline-block;
      line-height: 1;
      text-transform: none;
      letter-spacing: normal;
      word-wrap: normal;
      white-space: nowrap;
      direction: ltr;
      /* Support for all WebKit browsers. */
      -webkit-font-smoothing: antialiased;
      /* Support for Safari and Chrome. */
      text-rendering: optimizeLegibility;
      /* Support for Firefox. */
      -moz-osx-font-smoothing: grayscale;
      /* Support for IE. */
      font-feature-settings: 'liga';
      content: "";
      font-size: 26px; }
  .wishlist-link .wishlist-text {
    font-size: 10px;
    font-weight: 500; }

.products-grid.wishlist .product-item .price-box {
  justify-content: center; }

.sidebar .block-wishlist .product-items {
  padding: 0; }
  .sidebar .block-wishlist .product-items .product-item {
    width: 100%; }
    .sidebar .block-wishlist .product-items .product-item .price-box {
      text-align: right; }
    .sidebar .block-wishlist .product-items .product-item-name {
      text-align: left; }

.sidebar .block-wishlist .actions-primary .action.primary.tocart {
  margin-left: auto; }

.polarcore-header-links {
  display: flex; }

.wishlist-link {
  display: none;
  margin-left: 1rem; }
  .wishlist-link a:hover {
    text-decoration: none; }
    .wishlist-link a:hover .wishlist-text {
      text-decoration: underline; }
  .wishlist-link .wishlist-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: inherit; }
  .wishlist-link .wishlist-icon {
    line-height: 10px; }
    .wishlist-link .wishlist-icon:before {
      font-family: 'Material Icons';
      font-weight: normal;
      font-style: normal;
      font-size: 24px;
      /* Preferred icon size */
      display: inline-block;
      line-height: 1;
      text-transform: none;
      letter-spacing: normal;
      word-wrap: normal;
      white-space: nowrap;
      direction: ltr;
      /* Support for all WebKit browsers. */
      -webkit-font-smoothing: antialiased;
      /* Support for Safari and Chrome. */
      text-rendering: optimizeLegibility;
      /* Support for Firefox. */
      -moz-osx-font-smoothing: grayscale;
      /* Support for IE. */
      font-feature-settings: 'liga';
      content: "";
      font-size: 26px; }
  .wishlist-link .wishlist-text {
    font-size: 10px;
    font-weight: 500; }
  @media only screen and (min-width: 1024px) {
    .wishlist-link {
      display: block; } }

.page-main h1,
.page-main h2,
.page-main h3 {
  margin-top: 1.5rem;
  margin-bottom: 0.5rem; }

body.customer-account-login .login-container .fieldset:after {
  display: none; }

body.customer-account-login .block.block-customer-login {
  margin-bottom: 1rem; }

body.customer-account-login .actions-toolbar .primary {
  margin-bottom: 1rem; }

body.account {
  padding: 0; }
  body.account .page-header .header-menu .widget.block.block-static-block .menu-points ul li.first {
    background: #fafafa; }
    body.account .page-header .header-menu .widget.block.block-static-block .menu-points ul li.first a {
      font-weight: initial; }
  body.account .block-collapsible-nav .item.current a {
    border-color: #f96e33; }
  body.account.sales-order-view .column.main .page-title {
    margin-top: 0;
    margin-bottom: 1.5rem; }
    body.account.sales-order-view .column.main .page-title-wrapper .order-date {
      margin-bottom: 0; }
  body.account.sales-order-view .column.main .table-wrapper {
    margin: 0;
    border: none; }
  body.account.sales-order-view .column.main .order-status {
    position: absolute;
    right: 0; }
  body.account.sales-order-view .column.main .order-links strong {
    font-size: 1.4rem; }
  body.account.sales-order-view .column.main .order-title strong {
    display: none; }
  body.account.sales-order-view .column.main .order-details-items {
    border-bottom: none;
    margin: 0;
    padding: 0; }
    body.account.sales-order-view .column.main .order-details-items .actions-toolbar {
      display: none; }
    body.account.sales-order-view .column.main .order-details-items strong {
      font-size: 1.2rem;
      font-weight: 600; }
    body.account.sales-order-view .column.main .order-details-items div[ng-app="pharmaSuite"] #ps-pharma-suite {
      background: transparent; }
      body.account.sales-order-view .column.main .order-details-items div[ng-app="pharmaSuite"] #ps-pharma-suite .ps-cart {
        padding: 1rem 0rem; }
        body.account.sales-order-view .column.main .order-details-items div[ng-app="pharmaSuite"] #ps-pharma-suite .ps-cart #desktopCart .product-item .qty-info,
        body.account.sales-order-view .column.main .order-details-items div[ng-app="pharmaSuite"] #ps-pharma-suite .ps-cart #desktopCart .product-item .discount-cell,
        body.account.sales-order-view .column.main .order-details-items div[ng-app="pharmaSuite"] #ps-pharma-suite .ps-cart #desktopCart .product-item .price-info {
          text-align: center; }
      body.account.sales-order-view .column.main .order-details-items div[ng-app="pharmaSuite"] #ps-pharma-suite .ps-article-unauthorized {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.5rem;
        width: 100%;
        padding-bottom: 4rem;
        border-bottom: 1px solid #e0e6ee; }
        body.account.sales-order-view .column.main .order-details-items div[ng-app="pharmaSuite"] #ps-pharma-suite .ps-article-unauthorized .ps-login-container .btn {
          background: #f96e33;
          border: #b62d00;
          color: #ffffff;
          cursor: pointer;
          margin: 0;
          padding: 7px 15px;
          width: auto;
          outline: none;
          font-size: 14px;
          line-height: 16px;
          box-sizing: border-box;
          vertical-align: middle;
          padding: 1rem;
          text-transform: uppercase;
          font-weight: 400;
          border-radius: 3px;
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.35); }
        body.account.sales-order-view .column.main .order-details-items div[ng-app="pharmaSuite"] #ps-pharma-suite .ps-article-unauthorized span.ng-scope {
          font-weight: 900; }
        body.account.sales-order-view .column.main .order-details-items div[ng-app="pharmaSuite"] #ps-pharma-suite .ps-article-unauthorized .clearfix {
          display: none; }
    body.account.sales-order-view .column.main .order-details-items div[ng-app="pharmaSuite"] .ps-article-price-label {
      display: none; }
    body.account.sales-order-view .column.main .order-details-items #my-orders-table tbody tr .col {
      padding: 1.5rem 0.5rem; }
    body.account.sales-order-view .column.main .order-details-items #my-orders-table tbody.prescription .col .item-options {
      display: none; }
    body.account.sales-order-view .column.main .order-details-items #my-orders-table tbody.prescription .col.sku {
      visibility: hidden; }
    body.account.sales-order-view .column.main .order-details-items #my-orders-table tbody tr[id^="order-item-row"] td.col.price span, body.account.sales-order-view .column.main .order-details-items #my-orders-table tbody tr[id^="order-item-row"] td.col.qty span, body.account.sales-order-view .column.main .order-details-items #my-orders-table tbody tr[id^="order-item-row"] td.col.subtotal span {
      font-size: 1rem;
      font-weight: 600; }
    body.account.sales-order-view .column.main .order-details-items #my-orders-table tfoot tr.subtotal {
      border-top: 1px solid #e2e7ee;
      margin-top: 0.5rem;
      padding-top: 0.5rem; }
    body.account.sales-order-view .column.main .order-details-items #my-orders-table tfoot tr th, body.account.sales-order-view .column.main .order-details-items #my-orders-table tfoot tr td {
      font-size: 1.2rem; }
    body.account.sales-order-view .column.main .order-details-items #my-orders-table tfoot tr th {
      padding: 5px 10px 0 0; }
    body.account.sales-order-view .column.main .order-details-items #my-orders-table tfoot tr td {
      padding: 5px 0 0 10px; }
    body.account.sales-order-view .column.main .order-details-items #my-orders-table tfoot tr:first-of-type th, body.account.sales-order-view .column.main .order-details-items #my-orders-table tfoot tr:first-of-type td {
      padding-top: 1reM; }
    body.account.sales-order-view .column.main .order-details-items #my-orders-table tfoot tr:last-of-type th, body.account.sales-order-view .column.main .order-details-items #my-orders-table tfoot tr:last-of-type td {
      padding-bottom: 0; }
  body.account.sales-order-view .column.main .block-order-details-view .block-title {
    border-color: #e4e4e4; }
    body.account.sales-order-view .column.main .block-order-details-view .block-title strong {
      font-size: 19px;
      font-weight: 700; }
  body.account.sales-order-view .column.main .block-order-details-view .block-content {
    display: flex;
    flex-wrap: wrap; }
    body.account.sales-order-view .column.main .block-order-details-view .block-content .box {
      width: 25%; }
    body.account.sales-order-view .column.main .block-order-details-view .block-content address {
      line-height: 1.5; }
    body.account.sales-order-view .column.main .block-order-details-view .block-content .box-order-shipping-method {
      order: 3; }
    body.account.sales-order-view .column.main .block-order-details-view .block-content .box-order-shipping-address {
      order: 1; }
    body.account.sales-order-view .column.main .block-order-details-view .block-content .box-order-billing-method {
      word-break: break-all;
      order: 4; }
      body.account.sales-order-view .column.main .block-order-details-view .block-content .box-order-billing-method img {
        max-width: 100px; }
      body.account.sales-order-view .column.main .block-order-details-view .block-content .box-order-billing-method th, body.account.sales-order-view .column.main .block-order-details-view .block-content .box-order-billing-method td {
        padding: 5px 0 0 0; }
    body.account.sales-order-view .column.main .block-order-details-view .block-content .box-order-billing-address {
      order: 2; }

@media only screen and (max-width: 1023px) {
  body.account .page.messages {
    margin: 0; }
  body.account .block-collapsible-nav .title {
    border-top: 0; }
  body.account.sales-order-view .column.main .page-title-wrapper {
    margin-top: 4rem; }
    body.account.sales-order-view .column.main .page-title-wrapper .page-title {
      margin-bottom: 0.5rem; }
    body.account.sales-order-view .column.main .page-title-wrapper .order-date {
      margin: 0.5rem 0; }
    body.account.sales-order-view .column.main .page-title-wrapper .order-status {
      position: initial; }
  body.account.sales-order-view .column.main .order-details-items div[ng-app="pharmaSuite"] #ps-pharma-suite {
    background: transparent; }
    body.account.sales-order-view .column.main .order-details-items div[ng-app="pharmaSuite"] #ps-pharma-suite .ps-article-unauthorized {
      justify-content: space-between;
      flex-direction: column;
      padding-bottom: 2rem; }
      body.account.sales-order-view .column.main .order-details-items div[ng-app="pharmaSuite"] #ps-pharma-suite .ps-article-unauthorized span.ng-scope {
        font-weight: 900;
        width: 94%;
        display: block;
        text-align: center;
        margin: 0.5rem 0; }
  body.account.sales-order-view .column.main .order-details-items #my-orders-table tbody tr[id^="order-item-row"] td.col.price, body.account.sales-order-view .column.main .order-details-items #my-orders-table tbody tr[id^="order-item-row"] td.col.qty, body.account.sales-order-view .column.main .order-details-items #my-orders-table tbody tr[id^="order-item-row"] td.col.subtotal {
    display: flex;
    justify-content: space-between;
    padding: 5px 0 0 0; }
    body.account.sales-order-view .column.main .order-details-items #my-orders-table tbody tr[id^="order-item-row"] td.col.price span, body.account.sales-order-view .column.main .order-details-items #my-orders-table tbody tr[id^="order-item-row"] td.col.qty span, body.account.sales-order-view .column.main .order-details-items #my-orders-table tbody tr[id^="order-item-row"] td.col.subtotal span {
      font-size: 1rem;
      font-weight: 600; }
  body.account.sales-order-view .column.main .order-details-items #my-orders-table tbody tr[id^="order-item-row"] td.col.subtotal {
    padding-bottom: 1rem; }
  body.account.sales-order-view .column.main .order-details-items #my-orders-table tfoot tr.subtotal {
    border-top: 1px solid #e2e7ee;
    margin-top: 0.5rem;
    padding-top: 0.5rem; }
  body.account.sales-order-view .column.main .order-details-items #my-orders-table tfoot tr th, body.account.sales-order-view .column.main .order-details-items #my-orders-table tfoot tr td {
    font-size: 1.2rem; }
  body.account.sales-order-view .column.main .order-details-items #my-orders-table tfoot tr th {
    padding: 5px 10px 0 0; }
  body.account.sales-order-view .column.main .order-details-items #my-orders-table tfoot tr td {
    padding: 5px 0 0 10px; }
  body.account.sales-order-view .column.main .block-order-details-view .block-content {
    display: flex;
    flex-wrap: wrap; }
    body.account.sales-order-view .column.main .block-order-details-view .block-content .box {
      width: 50%; }
    body.account.sales-order-view .column.main .block-order-details-view .block-content .box-order-shipping-method {
      order: 3; }
    body.account.sales-order-view .column.main .block-order-details-view .block-content .box-order-shipping-address {
      order: 1; }
    body.account.sales-order-view .column.main .block-order-details-view .block-content .box-order-billing-method {
      order: 4; }
      body.account.sales-order-view .column.main .block-order-details-view .block-content .box-order-billing-method th, body.account.sales-order-view .column.main .block-order-details-view .block-content .box-order-billing-method td {
        padding: 5px 0 0 0; }
    body.account.sales-order-view .column.main .block-order-details-view .block-content .box-order-billing-address {
      order: 2; } }

body #ajaxcart, body #ajaxcart-loading {
  z-index: 9999; }

body #ajaxcart .inner {
  max-width: 550px; }

body #ajaxcart .success {
  background: #229633;
  margin-top: -1px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  font-size: 1.3rem;
  margin-bottom: 1rem; }
  body #ajaxcart .success .icon {
    display: none; }

body #ajaxcart .button-close-top {
  right: 0.25rem;
  top: 0.25rem;
  color: #fff; }

body #ajaxcart .product {
  text-align: left !important; }
  body #ajaxcart .product-name {
    padding: 0 0 1rem 0;
    font-size: 18px;
    font-weight: 500; }
  body #ajaxcart .product .price-box {
    padding-bottom: 1rem;
    display: flex; }
    body #ajaxcart .product .price-box .price-including-tax {
      margin-right: 0.5rem; }
  body #ajaxcart .product-image {
    float: left;
    max-width: 150px;
    min-width: 150px;
    margin: 0 1rem; }

body #ajaxcart .actions {
  display: flex;
  justify-content: center;
  padding: 1rem 0;
  background: #1f1f1f05;
  border-top: 1px dashed #eaeaea;
  width: 100%; }
  body #ajaxcart .actions a.button {
    width: 40%;
    justify-content: center;
    text-align: center; }
  body #ajaxcart .actions #button-checkout {
    display: none; }
  body #ajaxcart .actions #button-close {
    background: #dedede;
    box-shadow: none;
    border: none;
    border-bottom: 2px solid #c5c5c5;
    font-weight: 500;
    color: #575757;
    display: flex;
    align-items: center;
    line-height: initial; }
    body #ajaxcart .actions #button-close:hover, body #ajaxcart .actions #button-close:focus {
      border-bottom: 2px solid #b8b8b8;
      background: #d1d1d1; }
  body #ajaxcart .actions #button-cart {
    background-color: #00904a;
    border-color: #00904a;
    border-bottom: 2px solid #00904a;
    box-shadow: none;
    border-radius: 4px;
    line-height: initial;
    color: #fff; }
    body #ajaxcart .actions #button-cart:hover, body #ajaxcart .actions #button-cart:focus {
      border-bottom: 2px solid #00773d;
      background: #00773d; }

body #ajaxcart .cartamount {
  width: 90%;
  display: flex;
  flex-direction: column;
  margin: 2rem auto;
  padding: 0 0.5rem;
  justify-content: center;
  align-items: center; }
  body #ajaxcart .cartamount progress[value] {
    appearance: none;
    border: none;
    height: 12px;
    color: royalblue;
    position: relative;
    margin: 0.5rem 0 0;
    width: 100%;
    border-radius: 6px; }
  body #ajaxcart .cartamount progress[value]::-webkit-progress-bar {
    background-color: #ebebeb;
    border-radius: 6px; }
  body #ajaxcart .cartamount .amount-percent::-webkit-progress-value {
    background-image: -webkit-linear-gradient(left, #40c353, #40c353);
    border-radius: 6px; }
  body #ajaxcart .cartamount .remaining-amount .value {
    font-weight: 600; }

body #ajaxcart #ajax-slots-wrapper {
  display: flex;
  width: 80%;
  margin: auto; }
  body #ajaxcart #ajax-slots-wrapper .block.related {
    width: 100%;
    padding: 0 1rem;
    margin: 0 auto; }

@media only screen and (max-width: 1023px) {
  body #ajaxcart .actions {
    flex-direction: row; }
    body #ajaxcart .actions a.button {
      width: fit-content;
      margin: 0 0.25rem; }
  body #ajaxcart .cartamount {
    margin: 0rem auto 1rem auto;
    padding-top: 0.75rem; } }

.breadcrumbs .items {
  margin: 1rem 0; }
  .breadcrumbs .items .item.home a,
  .breadcrumbs .items .item a {
    color: #118b2d; }
  .breadcrumbs .items .item a,
  .breadcrumbs .items .item strong {
    font-size: 15px; }
  .breadcrumbs .items .item:after {
    font-size: 20px;
    line-height: 20px; }

@media only screen and (min-width: 1024px) {
  .breadcrumbs {
    margin-bottom: 0.5rem; } }

.checkout-cart-index .cart.table-wrapper .col.price, .checkout-cart-index .cart.table-wrapper .col.qty, .checkout-cart-index .cart.table-wrapper .col.subtotal {
  text-align: center; }

.checkout-cart-index .cart.table-wrapper .item .col.item {
  padding: 20px 8px 10px; }

.checkout-cart-index .cart.table-wrapper .item-actions td {
  text-align: right; }

.checkout-cart-index .cart.table-wrapper .product-item-photo {
  display: table-cell;
  max-width: 100%;
  padding-right: 20px;
  position: static;
  vertical-align: top;
  width: 1%; }

.checkout-cart-index .cart.table-wrapper .product-item-name {
  display: block;
  margin-top: 0;
  font-size: 1.2rem;
  font-weight: 600; }

.checkout-cart-index .cart.table-wrapper .product-item-details {
  display: table-cell;
  vertical-align: top;
  white-space: normal;
  width: 99%; }

.checkout-cart-index .cart.table-wrapper .actions-toolbar {
  padding-top: 0;
  padding-right: 0; }

.checkout-cart-index .cart.table-wrapper .item-actions .actions-toolbar .action-towishlist,
.checkout-cart-index .cart.table-wrapper .item-actions .actions-toolbar .action-edit {
  display: none; }

.checkout-cart-index .cart.table-wrapper .item-actions .actions-toolbar .action-delete {
  margin: 0;
  border-radius: 3px;
  background: #ececec;
  border-bottom: 2px solid #cccccc;
  color: #585858;
  float: right; }

.checkout-cart-index .cart-summary .summary.title {
  background: transparent;
  padding-left: 0;
  margin-top: 0;
  font-weight: 600; }

.checkout-cart-index .cart-summary #block-shipping {
  display: none; }

.checkout-cart-index .cart-summary #cart-totals {
  padding-top: 0; }
  .checkout-cart-index .cart-summary #cart-totals .totals th,
  .checkout-cart-index .cart-summary #cart-totals .totals td {
    padding: 0.75rem 0; }

.checkout-cart-index .cart-discount {
  display: none; }

.opc-block-summary .table-totals .totals-tax-summary .amount .price,
.cart-totals .totals-tax-summary .amount .price {
  padding-right: 0; }
  .opc-block-summary .table-totals .totals-tax-summary .amount .price:after,
  .cart-totals .totals-tax-summary .amount .price:after {
    font-size: 1em;
    position: absolute;
    left: -2.5rem;
    top: 0.2rem; }

.footer.content {
  background-color: #00904a;
  display: none; }
  .footer.content > * li {
    font-size: 15px;
    color: var(--neutrals-50, #fff);
    list-style-type: none; }
  .footer.content > * a {
    font-weight: 400;
    font-size: 14px;
    color: var(--neutrals-50, #fff); }

.block.newsletter .field .control:before {
  content: none; }

.page-footer {
  padding: 15px 15px 25px 15px;
  color: #fff;
  background-color: #00904a; }
  .page-footer .page-footer-column {
    width: 25%;
    max-width: 25%; }
  .page-footer .page-footer-content {
    max-width: 1280px;
    margin: auto;
    display: flex; }
  .page-footer ul > li, .page-footer footer.page-footer ol > li {
    list-style-type: none;
    color: var(--neutrals-50, #fff); }

.copyright {
  background-color: #007F41;
  color: var(--neutrals-50, #FFF);
  display: block;
  padding: 10px;
  text-align: center; }

.block.newsletter .form.subscribe > .field, .block.newsletter .form.subscribe > .actions {
  float: left; }

.block.newsletter {
  max-width: 100%; }
  .block.newsletter input[type=email] {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid var(--neutrals-300, #b8c0ca);
    background: var(--neutrals-50, #fff);
    width: 270px;
    color: #000; }
  .block.newsletter input[type=checkbox] {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid var(--neutrals-300, #b8c0ca);
    background: var(--neutrals-50, #fff);
    color: #000;
    margin: 0; }
  .block.newsletter .form.subscribe {
    display: table;
    width: 100%; }
  .block.newsletter label.indented-checkbox-text {
    margin-left: 3em;
    display: block;
    position: relative;
    margin-top: -1.4em;
    line-height: 1.4em; }
  .block.newsletter .actions {
    display: table-cell;
    vertical-align: top;
    width: 100%;
    margin-top: 5px; }
    .block.newsletter .actions button.subscribe, .block.newsletter .actions .cart.table-wrapper .actions-toolbar > .subscribe.action, .cart.table-wrapper .block.newsletter .actions .actions-toolbar > .subscribe.action, .block.newsletter .actions .subscribe.action-gift {
      border: none;
      display: flex;
      padding: 20px 28px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      border-radius: 12px;
      background: var(--primary-600, #f96e33);
      margin-top: inherit;
      float: left;
      width: 270px;
      height: 48px;
      margin-bottom: 10px;
      box-shadow: none; }

.terms-condition-checkbox {
  display: inline-flex;
  align-items: center; }

.block.newsletter label.indented-checkbox-text {
  margin-left: 5px;
  display: block;
  position: relative;
  margin-top: auto;
  line-height: normal; }

.block.newsletter {
  width: 100%; }

.page-footer-column.footer-links li a:visited, .page-footer-column.footer-links ul > li a, .page-footer-column.footer-links a:visited, .alink:visited, .page-footer-column.footer-links a {
  color: var(--neutrals-50, #fff);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  list-style-type: none; }

.footer-links ul li {
  list-style-type: none;
  margin-bottom: 1rem; }

footer.page-footer ul > li, footer.page-footer ol > li, footer.page-footer ul > li a {
  color: var(--neutrals-50, #fff);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  list-style-type: none; }

footer.page-footer ul {
  padding-left: 0; }

.social-media-wrapper {
  display: flex;
  align-items: center;
  /* Align items vertically */ }
  .social-media-wrapper .social-media {
    list-style: none;
    padding: 0;
    margin: 15px 0 0 0;
    display: flex;
    align-items: center; }
    .social-media-wrapper .social-media li {
      margin-right: 10px; }
      .social-media-wrapper .social-media li a {
        width: 40px;
        height: 40px;
        background-color: #fff;
        border-radius: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px; }
        .social-media-wrapper .social-media li a img {
          width: 40px;
          height: 40px; }

.footer-content {
  padding: 0rem 0.5rem;
  /* mobile */ }
  .footer-content .widget.block-static-block h2 {
    font-weight: 400;
    font-family: "Oswald";
    font-size: 20px; }
  .footer-content .widget.block-static-block a {
    font-size: 15px;
    letter-spacing: 0.02rem; }
    .footer-content .widget.block-static-block a:before {
      display: none; }
  .footer-content .widget.block-static-block ul li {
    padding: 0.3rem 0rem; }
  .footer-content .widget.block-static-block ul.social-media li {
    display: inline; }
    .footer-content .widget.block-static-block ul.social-media li img {
      max-width: 60px;
      margin-right: 2rem; }
  .footer-content .widget.block-static-block .trustpilot-widget {
    width: 160px; }
  @media only screen and (max-width: 1023px) {
    .footer-content .page-footer li {
      font-size: 14px !important; }
    .footer-content .page-footer a {
      font-size: 14px !important; }
    .footer-content .p-full {
      display: flex;
      flex-direction: column;
      flex-flow: column; }
      .footer-content .p-full > div {
        margin-left: 0;
        max-width: 100%;
        flex-basis: auto; }
    .footer-content .footer-grid ul.social-media {
      text-align: center; }
      .footer-content .footer-grid ul.social-media li img {
        max-width: 45px !important;
        margin-right: 1.5rem !important; } }

.page-bottom {
  background: var(--primary-50, #f3f5f9); }
  .page-bottom .usp-bar-wrapper {
    max-width: 1280px;
    flex-shrink: 0;
    margin: 0 auto;
    width: 100%;
    padding: 6px 0;
    display: inline-block;
    justify-content: flex-start;
    align-items: center;
    text-align: left; }
    .page-bottom .usp-bar-wrapper .usp-bar-content {
      display: flex;
      justify-content: flex-start;
      padding: 0 8px; }
      .page-bottom .usp-bar-wrapper .usp-bar-content .usp-bar-row {
        display: flex; }
        .page-bottom .usp-bar-wrapper .usp-bar-content .usp-bar-row .usp-bar-item {
          margin-left: 0;
          margin-right: 16px; }

@media screen and (max-width: 980px) {
  .page-footer .page-footer-content {
    flex-wrap: wrap; }
  .page-footer .page-footer-column {
    width: auto;
    max-width: 50%;
    flex: 0 0 50%; }
  .terms-condition-checkbox {
    float: inline-start; }
  .page-footer {
    margin-top: 0; } }

@media screen and (max-width: 600px) {
  .page-footer .page-footer-column {
    width: auto;
    max-width: 100%;
    flex: 0 0 100%; } }

body {
  /* overwrites the weird padding from pharmasuite.css */
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  /* hide tena stuff on banda */ }
  body .p-full {
    width: 100%; }
  body p.half-width {
    width: 50%; }
  body .tena-only {
    display: none; }
  body .cookie-status-message {
    display: none; }

/* Mine Resepter */
.resepter-headline, .back-to-frontpage {
  display: block;
  width: auto; }

.back-to-frontpage {
  font-size: 16px;
  margin-top: 1rem;
  margin-bottom: 1.5rem; }

/* Bandagist + org. nr., bottom of page */
.trollweb-pharmacy-picker-footer {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  text-align: center;
  background: #00904a;
  color: #fff; }
  @media only screen and (max-width: 767px) {
    .trollweb-pharmacy-picker-footer {
      border-top: 0.8px solid #43434394; } }
  .trollweb-pharmacy-picker-footer-contents {
    padding: 0.75rem 4rem;
    display: inline-block; }
    .trollweb-pharmacy-picker-footer-contents .pharmacy-name {
      font-weight: 700;
      font-size: 18px; }
      .trollweb-pharmacy-picker-footer-contents .pharmacy-name-chosen {
        font-weight: 500;
        font-size: 18px; }
    .trollweb-pharmacy-picker-footer-contents .pharmacy-org-number {
      font-weight: 700;
      font-size: 18px; }
      .trollweb-pharmacy-picker-footer-contents .pharmacy-org-number-chosen {
        font-weight: 500;
        font-size: 18px; }

/* breadcrumbs */
.columns .breadcrumbs ul li strong {
  font-size: 12px !important; }

.columns .breadcrumbs ul li a {
  font-size: 12px !important; }

.columns .breadcrumbs ul li:after {
  font-size: 15px !important;
  line-height: 15px !important; }

.wpcloud-banner2.wpcloud-banner2--small.banda-small {
  border: none; }

a.wpcloud-banner2__button.button {
  line-height: 2em;
  background: none;
  border-top: 1px solid #118b2d;
  width: auto; }
  a.wpcloud-banner2__button.button, a.wpcloud-banner2__button.button:visited, a.wpcloud-banner2__button.button:hover {
    color: #0e7426;
    text-decoration: none; }

.cms-home .quick-links .wpb_wrapper {
  display: grid; }
  .cms-home .quick-links .wpb_wrapper .wpcloud-card {
    padding: 5vw 0;
    border-bottom: 1px solid #118b2d; }
    .cms-home .quick-links .wpb_wrapper .wpcloud-card .mdl-card {
      padding: 0; }
      .cms-home .quick-links .wpb_wrapper .wpcloud-card .mdl-card a {
        display: grid;
        grid-template-columns: 2fr 1fr;
        align-items: center; }
        .cms-home .quick-links .wpb_wrapper .wpcloud-card .mdl-card a .wpcloud-image {
          grid-column: 2;
          grid-row: 1 / span 2;
          padding: 0;
          width: 100%;
          height: 100%;
          min-height: 15vw;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center; }
        .cms-home .quick-links .wpb_wrapper .wpcloud-card .mdl-card a .mdl-card__actions {
          position: relative;
          grid-row: 2; }
          .cms-home .quick-links .wpb_wrapper .wpcloud-card .mdl-card a .mdl-card__actions button, .cms-home .quick-links .wpb_wrapper .wpcloud-card .mdl-card a .mdl-card__actions .cart.table-wrapper .actions-toolbar > .action, .cart.table-wrapper .cms-home .quick-links .wpb_wrapper .wpcloud-card .mdl-card a .mdl-card__actions .actions-toolbar > .action, .cms-home .quick-links .wpb_wrapper .wpcloud-card .mdl-card a .mdl-card__actions .action-gift {
            background-color: #118b2d;
            color: white;
            border-radius: 5rem;
            padding: 0.8rem;
            font-family: inherit;
            font-weight: 500;
            font-size: 1.2em;
            min-width: 50%; }
            .cms-home .quick-links .wpb_wrapper .wpcloud-card .mdl-card a .mdl-card__actions button:hover, .cms-home .quick-links .wpb_wrapper .wpcloud-card .mdl-card a .mdl-card__actions .cart.table-wrapper .actions-toolbar > .action:hover, .cart.table-wrapper .cms-home .quick-links .wpb_wrapper .wpcloud-card .mdl-card a .mdl-card__actions .actions-toolbar > .action:hover, .cms-home .quick-links .wpb_wrapper .wpcloud-card .mdl-card a .mdl-card__actions .action-gift:hover {
              background-color: #047021; }
        .cms-home .quick-links .wpb_wrapper .wpcloud-card .mdl-card a .content {
          padding: 0;
          color: #434343;
          line-height: 1.5;
          font-size: 1.2em; }
        .cms-home .quick-links .wpb_wrapper .wpcloud-card .mdl-card a:hover {
          text-decoration: none; }

@media only screen and (max-width: 767px) {
  .cms-home .quick-links .wpb_wrapper .wpcloud-card:nth-of-type(even) .mdl-card a {
    grid-template-columns: 1fr 2fr;
    text-align: right; }
    .cms-home .quick-links .wpb_wrapper .wpcloud-card:nth-of-type(even) .mdl-card a .wpcloud-image {
      grid-column: 1; }
  .cms-home .quick-links .wpb_wrapper:last-of-type {
    border-bottom: none; } }

@media only screen and (min-width: 768px) and (max-width: 1200px) {
  .cms-home .quick-links .wpb_wrapper {
    grid-template-columns: 1fr 1fr; }
    .cms-home .quick-links .wpb_wrapper .mdl-card a {
      height: 100%; }
      .cms-home .quick-links .wpb_wrapper .mdl-card a .content {
        align-self: flex-start; }
      .cms-home .quick-links .wpb_wrapper .mdl-card a .mdl-card__actions {
        align-self: flex-end; }
    .cms-home .quick-links .wpb_wrapper:nth-of-type(odd) {
      padding-right: 1rem; }
    .cms-home .quick-links .wpb_wrapper:nth-of-type(even) {
      padding-left: 1rem; } }

@media only screen and (max-width: 1199px) {
  .show-only-desktop {
    display: none !important; } }

@media only screen and (min-width: 1200px) {
  .hide-only-desktop {
    display: none !important; } }

.smile-es-range-slider {
  width: 95%;
  margin: 30px auto auto;
  text-align: center; }
  .smile-es-range-slider [data-role="from-label"] {
    float: left;
    margin-top: -25px; }
  .smile-es-range-slider [data-role="to-label"] {
    float: right;
    margin-top: -25px; }
  .smile-es-range-slider .ui-slider-handle {
    background: #118b2d; }

/* General Label Styling */
.tw-productlabels .tw-productlabels__label {
  margin: 0rem 0.5rem 0.6rem 0rem;
  opacity: 0.85; }
  .tw-productlabels .tw-productlabels__label .tw-productlabels__text {
    padding: 0.6rem 1.5rem; }
  .tw-productlabels .tw-productlabels__label--discount .tw-productlabels__text {
    background-color: #d60606; }
  .tw-productlabels .tw-productlabels__label--new .tw-productlabels__text {
    background-color: #000;
    color: #fff; }

/* Remove on Product Page */
.product.media .tw-productlabels {
  display: none; }

.columns .column.main {
  padding: 0 1rem; }

@media only screen and (min-width: 1024px) {
  .page-layout-2columns-left .column.main {
    padding-right: 1rem; }
  .page-layout-2columns-left .sidebar-additional {
    width: 25%; } }

.modal-popup {
  z-index: 10000;
  overflow-y: visible; }
  .modal-popup .modal-inner-wrap {
    max-width: 1000px;
    max-height: 90%; }
    .modal-popup .modal-inner-wrap .modal-title {
      border-bottom: none; }
    .modal-popup .modal-inner-wrap .modal-content {
      overflow-y: auto;
      padding-bottom: 14px; }
      .modal-popup .modal-inner-wrap .modal-content ul li {
        width: 50%;
        display: inline-block; }
      .modal-popup .modal-inner-wrap .modal-content .pharmacy-picker-button-group {
        margin-bottom: 1.5rem; }
        .modal-popup .modal-inner-wrap .modal-content .pharmacy-picker-button-group .pharmacy-picker-cancel {
          margin-right: 0.5rem; }

/* normal pharmacy picker */
#pharmacy-list {
  /* Remove default list styling */
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: grid;
  display: -ms-flexbox;
  -ms-flex-wrap: wrap;
  grid-gap: 0.5rem;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(auto-fit, minmax(1rem, 1fr)); }
  #pharmacy-list li {
    width: stretch;
    margin: 0; }
    #pharmacy-list li span,
    #pharmacy-list li a {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      margin-top: -1px;
      /* Prevent double borders */
      background-color: #f6f6f6;
      /* Grey background color */
      padding: 12px;
      /* Add some padding */
      padding-bottom: 0px;
      text-decoration: none;
      /* Remove default text underline */
      font-size: 18px;
      /* Increase the font-size */
      color: black;
      /* Add a black text color */
      cursor: pointer; }
      #pharmacy-list li span:hover:not(.header),
      #pharmacy-list li a:hover:not(.header) {
        background-color: #eee;
        /* Add a hover effect to all links, except for headers */ }

/* Search bar */
.pharmacy-search {
  position: relative; }
  .pharmacy-search i {
    position: absolute;
    top: 0.7rem;
    left: 0.5rem;
    color: #bbb;
    font-size: 28px; }

@media only screen and (max-width: 479px) {
  #pharmacy-list {
    grid-template-columns: 1fr;
    -ms-grid-columns: 1fr; } }

/* MOBILE */
@media only screen and (max-width: 1023px) {
  .modal-popup .modal-inner-wrap {
    width: 100%; }
    .modal-popup .modal-inner-wrap .modal-header,
    .modal-popup .modal-inner-wrap .modal-content {
      padding-left: 24px;
      padding-right: 24px; } }

#myInput {
  width: 99%;
  font-size: 18px;
  padding: 12px 20px 12px 40px;
  border: 1px solid #ddd;
  margin-bottom: 5px;
  height: 45px; }
  #myInput:before {
    content: "search";
    display: inline-block;
    font-family: "Material Icons";
    width: 20px;
    height: 20px; }

.trollweb-pharmacypicker-index-index .column.main .pharmacy-picker {
  margin: 1.5rem 0 0 0; }
  .trollweb-pharmacypicker-index-index .column.main .pharmacy-picker .description-text {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 0.75rem; }
  .trollweb-pharmacypicker-index-index .column.main .pharmacy-picker ul {
    list-style: none;
    margin-top: 1rem; }

/* cart */
.cart #ps-pharma-suite .cart-list .product-item td:first-of-type {
  width: 57%; }

.cart #ps-pharma-suite .cart-list .product-item .thumb {
  display: inline-block;
  margin-right: 0.5rem; }
  .cart #ps-pharma-suite .cart-list .product-item .thumb img {
    max-width: 120px;
    width: 120px; }

.cart #ps-pharma-suite .cart-list .product-item .info {
  display: inline-block;
  vertical-align: top; }
  .cart #ps-pharma-suite .cart-list .product-item .info h4 {
    margin-top: 0.65rem;
    display: table-caption;
    width: 240px; }

.cart #ps-pharma-suite .cart-list .product-item .qty-info {
  text-align: center; }

.cart #ps-pharma-suite .cart-list .product-item .price-info {
  text-align: center; }
  .cart #ps-pharma-suite .cart-list .product-item .price-info .remove-item {
    display: none; }

.cart #ps-pharma-suite .cart-list .product-item .ps-cart-remove-button {
  text-align: center; }

.cart #ps-pharma-suite .ps-cart-container-mobile {
  display: none; }

.cart #ps-pharma-suite .center-cell {
  text-align: center;
  padding-top: 1.2rem;
  font-weight: 600;
  font-size: 14px; }

/* Booking Manual */
.ps-booking h1 {
  font-size: 30pt !important;
  font-weight: 700;
  line-height: 45pt;
  margin-bottom: 0rem !important;
  color: #474445;
  padding-left: 2rem;
  margin-top: 1rem !important; }

.ps-booking #medicineList thead th:nth-child(2) {
  display: none; }

.ps-booking #medicineList tbody tr td:nth-child(2) {
  display: none; }

.ps-booking #medicineList tbody tr td .btn.btn-secondary {
  width: 100% !important; }

.ps-booking form[name="bookingForm"] .bb {
  margin-top: 1rem; }
  .ps-booking form[name="bookingForm"] .bb legend {
    font-weight: 700;
    padding-bottom: 0.25rem !important; }
  .ps-booking form[name="bookingForm"] .bb .form-horizontal {
    margin-bottom: 1rem; }
    .ps-booking form[name="bookingForm"] .bb .form-horizontal label[for="medicine"] {
      text-align: left !important;
      padding-left: 0 !important;
      padding-top: 24px !important;
      font-size: 13px; }
      .ps-booking form[name="bookingForm"] .bb .form-horizontal label[for="medicine"] span:after {
        content: ":";
        display: inline-block; }
    .ps-booking form[name="bookingForm"] .bb .form-horizontal .form-group {
      padding: 0.5rem 0;
      border-bottom: none !important; }
  .ps-booking form[name="bookingForm"] .bb .btn {
    width: 105px !important;
    font-weight: 700 !important; }
  .ps-booking form[name="bookingForm"] .bb .h4 {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 0.5rem; }
  .ps-booking form[name="bookingForm"] .bb .field-group .pb-2 {
    display: block;
    margin-bottom: 0.5rem; }
  .ps-booking form[name="bookingForm"] .bb .row.ps-bottom-padding {
    margin-top: 1rem; }
  .ps-booking form[name="bookingForm"] .bb #psBookingBtnSendOrder {
    width: 475px !important;
    padding: 0.75rem 0;
    font-weight: 700 !important; }

/* Desktop */
@media only screen and (min-width: 1024px) {
  /* Login */
  .trollweb-pharmacypicker-index-index .pharmacy-picker {
    width: 79%;
    float: right; } }

/* Tablet */
@media only screen and (min-width: 1024px) and (max-width: 1200px) {
  .ps-booking .container-fluid form[name="bookingForm"] .bb .form-group label {
    text-align: left !important;
    padding-left: 0 !important; } }

@media only screen and (max-width: 1023px) {
  .ps-booking .container-fluid {
    padding-left: 0 !important;
    padding-right: 0 !important; }
    .ps-booking .container-fluid h1 {
      font-size: 26px !important;
      line-height: 32px; }
  .ps-booking form[name="bookingForm"] .bb .form-group label[for="medicine"] {
    padding-top: 0 !important; }
  .ps-booking form[name="bookingForm"] .bb .form-group label {
    padding-left: 0 !important; }
  .ps-booking form[name="bookingForm"] .bb #psBookingBtnSendOrder {
    width: 280px !important;
    font-size: 13px; } }

.trollweb-pharmacystorefront-prescriptions-index {
  padding: 0; }
  .trollweb-pharmacystorefront-prescriptions-index .modal-popup .modal-inner-wrap {
    max-width: 950px; }
  .trollweb-pharmacystorefront-prescriptions-index .modal-popup .modal-header {
    border: none; }
  .trollweb-pharmacystorefront-prescriptions-index .modal-popup .modal-content {
    box-shadow: none;
    border: none;
    outline: none;
    padding: 0 48px 48px 48px; }
    .trollweb-pharmacystorefront-prescriptions-index .modal-popup .modal-content ul {
      list-style: none;
      padding: 0; }
  .trollweb-pharmacystorefront-prescriptions-index #ps-pharma-suite {
    position: relative; }
    .trollweb-pharmacystorefront-prescriptions-index #ps-pharma-suite > * a {
      color: #1979c3 !important; }
    .trollweb-pharmacystorefront-prescriptions-index #ps-pharma-suite .container {
      padding-top: 0; }
    .trollweb-pharmacystorefront-prescriptions-index #ps-pharma-suite .overlay-panel-body {
      overflow: initial; }
      .trollweb-pharmacystorefront-prescriptions-index #ps-pharma-suite .overlay-panel-body .ps-prescription-content {
        padding-top: 1.25rem; }
    .trollweb-pharmacystorefront-prescriptions-index #ps-pharma-suite .ps-prescription-content-page .ps-consumable-header .ps-search {
      padding: 20px 30px; }
    .trollweb-pharmacystorefront-prescriptions-index #ps-pharma-suite .ps-prescription-content-page .ps-consumable-header .select-style {
      min-width: 235px !important; }
    .trollweb-pharmacystorefront-prescriptions-index #ps-pharma-suite .ps-main-content {
      background-color: transparent; }
    .trollweb-pharmacystorefront-prescriptions-index #ps-pharma-suite .ps-sort-form {
      position: absolute;
      top: -6rem;
      right: 1.25rem; }
    .trollweb-pharmacystorefront-prescriptions-index #ps-pharma-suite .ps-prescription-list-container {
      padding-top: 0; }
    .trollweb-pharmacystorefront-prescriptions-index #ps-pharma-suite .ps-prescription-list-content-content {
      background-color: transparent;
      border: none;
      padding: 0; }
    .trollweb-pharmacystorefront-prescriptions-index #ps-pharma-suite .btn-primary {
      background: #f96e33 !important;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.35);
      border: none !important; }
    .trollweb-pharmacystorefront-prescriptions-index #ps-pharma-suite .ps-prescription-name {
      color: #000; }
      .trollweb-pharmacystorefront-prescriptions-index #ps-pharma-suite .ps-prescription-name:hover {
        color: #000; }
    .trollweb-pharmacystorefront-prescriptions-index #ps-pharma-suite .ps-back-to-prescriptions {
      text-decoration: none;
      margin: 0 0 1rem 0;
      background: transparent; }
      .trollweb-pharmacystorefront-prescriptions-index #ps-pharma-suite .ps-back-to-prescriptions:before {
        left: 30px; }
      .trollweb-pharmacystorefront-prescriptions-index #ps-pharma-suite .ps-back-to-prescriptions:hover {
        text-decoration: none; }
        .trollweb-pharmacystorefront-prescriptions-index #ps-pharma-suite .ps-back-to-prescriptions:hover:before {
          left: 25px; }
    .trollweb-pharmacystorefront-prescriptions-index #ps-pharma-suite .ps-back-to-prescriptions .text,
    .trollweb-pharmacystorefront-prescriptions-index #ps-pharma-suite .ps-back-to-prescriptions .text > span,
    .trollweb-pharmacystorefront-prescriptions-index #ps-pharma-suite .prescription-list-new a.color-black.active .ps-prescription-title .text {
      color: #1979c3;
      font-family: "Raleway";
      font-weight: 600; }
    .trollweb-pharmacystorefront-prescriptions-index #ps-pharma-suite .ps-prescription-list-content-article-box button.choose, .trollweb-pharmacystorefront-prescriptions-index #ps-pharma-suite .ps-prescription-list-content-article-box .cart.table-wrapper .actions-toolbar > .choose.action, .cart.table-wrapper .trollweb-pharmacystorefront-prescriptions-index #ps-pharma-suite .ps-prescription-list-content-article-box .actions-toolbar > .choose.action, .trollweb-pharmacystorefront-prescriptions-index #ps-pharma-suite .ps-prescription-list-content-article-box .choose.action-gift {
      width: 187px; }
    .trollweb-pharmacystorefront-prescriptions-index #ps-pharma-suite .prescription-list-content-article-name {
      overflow-wrap: break-word;
      white-space: pre-wrap; }
    .trollweb-pharmacystorefront-prescriptions-index #ps-pharma-suite .ps-prescription-name {
      word-break: break-word; }

/* MOBILE */
@media only screen and (max-width: 1023px) {
  #ps-pharma-suite .ps-sort-form {
    position: unset !important; }
  #ps-pharma-suite .ps-prescription-list-container .ps-prescription-list-content-article-header {
    height: 150px; }
  .cart #ps-pharma-suite .product-item td:first-of-type {
    max-width: 110px; } }

.block.related {
  display: inline-block; }
  .block.related .block-title.title {
    margin-bottom: 1rem;
    text-align: center; }
    .block.related .block-title.title #block-related-heading {
      font-size: 26px;
      font-weight: 600; }
  .block.related .products-related {
    margin: 0; }
  .block.related .block-actions {
    display: none; }
  .block.related .product-item .product-item-info {
    max-height: 280px; }
  .block.related .product-item .product-image-container {
    height: 12rem; }
    .block.related .product-item .product-image-container .product-image-wrapper {
      height: inherit; }
  .block.related .product-item-details {
    flex: 1; }
    .block.related .product-item-details .price-box {
      margin-bottom: 0.7rem; }
      .block.related .product-item-details .price-box .old-price .price {
        margin-left: 0; }
    .block.related .product-item-details .field.related {
      display: none; }
    .block.related .product-item-details .product-item-actions {
      display: none !important; }

/* Desktop */
@media only screen and (min-width: 1024px) {
  .block.related {
    width: 48%;
    float: left;
    clear: both;
    margin-right: 4%;
    margin-bottom: 0; }
    .block.related .block-title.title #block-related-heading {
      font-size: 24px; }
    .block.related .products-grid .product-item {
      width: 30%; }
      .block.related .products-grid .product-item:nth-child(3) {
        margin-right: 0; } }

/* Mobile */
@media only screen and (max-width: 1023px) {
  .block.related {
    margin-top: 3rem;
    width: 100%; }
    .block.related .block-title.title #block-related-heading {
      font-size: 22px; }
    .block.related .block-content.content .block-actions .action.select {
      margin: 0.75rem auto; }
  .products-related .products .product-item {
    width: 48%; } }

/* Variables */
.review-container {
  display: block;
  clear: both;
  float: left; }
  .review-container #product-review-container .block-title {
    text-align: center; }
    .review-container #product-review-container .block-title strong {
      font-size: 24px;
      font-weight: 600; }
  .review-container #product-review-container .review-item .review-image {
    display: inline-block;
    float: left;
    margin-top: -0.9rem;
    margin-right: 1.5rem; }
  .review-container #product-review-container .review-item .review-ratings {
    float: right; }
    .review-container #product-review-container .review-item .review-ratings .rating-summary {
      overflow: unset; }
      .review-container #product-review-container .review-item .review-ratings .rating-summary .rating-result {
        width: 128px; }
        .review-container #product-review-container .review-item .review-ratings .rating-summary .rating-result:before {
          height: 26px;
          font-size: 26px;
          letter-spacing: 0; }
        .review-container #product-review-container .review-item .review-ratings .rating-summary .rating-result span:before {
          font-size: 26px;
          height: 26px;
          letter-spacing: 0; }

/* Desktop */
@media only screen and (min-width: 1024px) {
  .review-container {
    width: 48%;
    margin-top: 1rem; }
  .review-image i {
    font-size: 5rem; } }

@media only screen and (max-width: 1023px) {
  .review-container {
    width: 100%;
    margin-top: 0.5rem; }
  .review-image i {
    font-size: 4rem;
    margin-top: 0.5rem; } }

.block.upsell .title {
  margin-bottom: 1rem;
  text-align: center; }
  .block.upsell .title #block-upsell-heading {
    font-weight: 600; }

.block.upsell .products-upsell {
  margin: 0; }
  .block.upsell .products-upsell .product-item .product-item-info {
    max-height: 280px; }
  .block.upsell .products-upsell .product-item .product-image-container {
    height: 12rem; }
    .block.upsell .products-upsell .product-item .product-image-container .product-image-wrapper {
      height: inherit; }

/* Desktop */
@media only screen and (min-width: 1024px) {
  .block.upsell {
    width: 48%;
    float: left;
    margin-bottom: 0; }
    .block.upsell .title #block-upsell-heading {
      font-size: 24px; }
    .block.upsell .products-upsell .product-items .product-item {
      width: 31.33%;
      padding-bottom: 1.32rem; } }

/* Mobile */
@media only screen and (max-width: 1023px) {
  .block.upsell {
    margin-bottom: 20px; }
    .block.upsell .title #block-upsell-heading {
      font-size: 22px; }
    .block.upsell .products-upsell .product-items .product-item {
      width: 48%; } }

/* Top USP */
.usp-top-bar {
  width: 100%; }
  .usp-top-bar .widget.block.block-static-block {
    margin: 0; }
    .usp-top-bar .widget.block.block-static-block ul {
      width: 100%;
      display: grid;
      display: -ms-grid;
      justify-content: space-between;
      align-items: center;
      margin: 0 auto;
      padding: 0;
      max-width: 1440px; }
      .usp-top-bar .widget.block.block-static-block ul li {
        grid-row: 1;
        grid-column: 1;
        opacity: 0;
        list-style: none;
        margin: 0;
        white-space: nowrap; }
        .usp-top-bar .widget.block.block-static-block ul li i {
          margin-right: 0.5rem;
          vertical-align: middle;
          line-height: 1.5em;
          color: #118b2d; }
        .usp-top-bar .widget.block.block-static-block ul li:nth-of-type(1) {
          animation: fade 20s 5s infinite; }
        .usp-top-bar .widget.block.block-static-block ul li:nth-of-type(2) {
          animation: fade2 20s 5s infinite; }
        .usp-top-bar .widget.block.block-static-block ul li:nth-of-type(3) {
          animation: fade3 20s 5s infinite; }
        .usp-top-bar .widget.block.block-static-block ul li:nth-of-type(4) {
          animation: fade4 20s 5s infinite; }
        .usp-top-bar .widget.block.block-static-block ul li:nth-of-type(5) {
          animation: fade5 20s 5s infinite; }

@keyframes fade {
  0% {
    opacity: 1; }
  20% {
    opacity: 0; }
  40% {
    opacity: 0; }
  60% {
    opacity: 0; }
  80% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@keyframes fade2 {
  0% {
    opacity: 0; }
  20% {
    opacity: 1; }
  40% {
    opacity: 0; }
  60% {
    opacity: 0; }
  80% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@keyframes fade3 {
  0% {
    opacity: 0; }
  20% {
    opacity: 0; }
  40% {
    opacity: 1; }
  60% {
    opacity: 0; }
  80% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@keyframes fade4 {
  0% {
    opacity: 0; }
  20% {
    opacity: 0; }
  40% {
    opacity: 0; }
  60% {
    opacity: 1; }
  80% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@keyframes fade5 {
  0% {
    opacity: 0; }
  20% {
    opacity: 0; }
  40% {
    opacity: 0; }
  60% {
    opacity: 0; }
  80% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@media only screen and (min-width: 1200px) {
  .usp-top-bar .widget.block.block-static-block ul {
    display: flex;
    display: -ms-flexbox; }
    .usp-top-bar .widget.block.block-static-block ul li {
      visibility: visible;
      opacity: 1;
      grid-column: unset; }
      .usp-top-bar .widget.block.block-static-block ul li:nth-of-type(1), .usp-top-bar .widget.block.block-static-block ul li:nth-of-type(2), .usp-top-bar .widget.block.block-static-block ul li:nth-of-type(3), .usp-top-bar .widget.block.block-static-block ul li:nth-of-type(4), .usp-top-bar .widget.block.block-static-block ul li:nth-of-type(5) {
        animation: none; } }

@media only screen and (max-width: 1199px) {
  .usp-top-bar .widget.block.block-static-block ul {
    grid-template-columns: 100%;
    -ms-grid-columns: 100%;
    justify-items: center; } }

.field-recaptcha {
  float: right;
  margin-bottom: 15px; }
  .field-recaptcha > div > .field {
    display: none; }

body.customer-account-login .field-recaptcha {
  margin-right: 6px; }

body.catalog-product-view form.review-form {
  display: flex;
  flex-direction: column; }
  body.catalog-product-view form.review-form .review-fieldset {
    order: 1; }
  body.catalog-product-view form.review-form .field-recaptcha {
    order: 2; }
    body.catalog-product-view form.review-form .field-recaptcha .g-recaptcha {
      float: right; }
  body.catalog-product-view form.review-form .review-form-actions {
    order: 3; }

/*Typography*/
[data-content-type="row"] h1,
[data-content-type="row"] h2,
[data-content-type="row"] h3 {
  font-family: "Raleway";
  font-weight: 700; }

/*Lists*/
.banda-list ul {
  list-style-type: none; }

/*Buttons*/
.banda-green-button .pagebuilder-button-primary {
  background: #118b2d;
  border: 0; }
  .banda-green-button .pagebuilder-button-primary:hover {
    background: #00812b;
    border: 0; }

.banda-orange-button .pagebuilder-button-primary {
  background: #f96e33;
  border: 0; }
  .banda-orange-button .pagebuilder-button-primary:hover {
    background: #f8520b;
    border: 0; }

.banda-blog-button {
  width: 100% !important; }
  .banda-blog-button .pagebuilder-button-primary {
    display: flex;
    justify-content: center;
    flex-direction: column;
    background: #118b2d;
    border: 0;
    width: inherit;
    height: 30px; }
    .banda-blog-button .pagebuilder-button-primary:hover {
      background: #00812b;
      border: 0; }
    .banda-blog-button .pagebuilder-button-primary span {
      font-weight: 400;
      font-size: 14px; }

.banda-banner-button {
  width: 100%;
  padding: 0 1.5rem; }
  .banda-banner-button .pagebuilder-button-primary {
    color: #118b2d;
    text-decoration: none;
    line-height: 2rem;
    background: none;
    border: 0;
    border-top: 1px solid #118b2d;
    font-size: 20px;
    font-weight: 300;
    padding: 3rem 1.5rem;
    pointer-events: all;
    justify-content: center;
    display: inline-flex;
    width: 100%; }
    .banda-banner-button .pagebuilder-button-primary:hover {
      background: none;
      text-decoration: none;
      border: 0;
      border-top: 1px solid #118b2d;
      color: #118b2d;
      cursor: pointer; }
    .banda-banner-button .pagebuilder-button-primary a:hover {
      text-decoration: none;
      color: #118b2d; }

/*Column*/
.banda-blog-column {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1); }

@media only screen and (max-width: 1023px) {
  .banda-mobile-button .pagebuilder-button-primary {
    color: white;
    border-radius: 5rem;
    padding: 0.8rem 1.5rem;
    font-family: inherit;
    font-weight: 500;
    font-size: 1.2em;
    min-width: 50%; } }

#html-body .partner-logo {
  max-width: 75%;
  padding-left: 5px; }

#html-body.cms-bestill-reseptvarer .pagebuilder-column .pagebuilder-button-primary {
  border-radius: 50px; }

#html-body .contact-us-form .fieldset {
  margin-bottom: 15px; }
  #html-body .contact-us-form .fieldset .control input {
    background-color: transparent;
    font-size: 1em;
    box-shadow: none;
    font-weight: 400;
    line-height: 1em;
    border: 1px solid #eee; }
  #html-body .contact-us-form .fieldset .control textarea {
    background-color: transparent;
    font-size: 1em;
    box-shadow: none;
    font-weight: 400;
    line-height: 1em;
    border: 1px solid #eee; }

#html-body .contact-us-form .actions-toolbar .primary {
  display: inline-block; }
  #html-body .contact-us-form .actions-toolbar .primary button, #html-body .contact-us-form .actions-toolbar .primary .cart.table-wrapper .actions-toolbar > .action, .cart.table-wrapper #html-body .contact-us-form .actions-toolbar .primary .actions-toolbar > .action, #html-body .contact-us-form .actions-toolbar .primary .action-gift {
    border-radius: 6px;
    padding: 7px 15px; }

#html-body [data-content-type='text'][data-element='main'] ul li {
  margin-bottom: 0px; }

#html-body [data-content-type='row'][data-appearance='contained'] .reseptvarer-partner .pagebuilder-column-group .pagebuilder-column figure {
  margin: 0px 0px 8px 8px; }

#html-body .pagebuilder-column .widget-product-grid .product-item .product-item-actions .actions-secondary {
  display: none; }

#html-body.cms-ofte-stilte-sporsmal .pagebuilder-column {
  width: calc(50% - 16px);
  margin: 8px; }
  #html-body.cms-ofte-stilte-sporsmal .pagebuilder-column h1 {
    color: #118b2d; }
  #html-body.cms-ofte-stilte-sporsmal .pagebuilder-column h2 {
    color: white;
    background-color: #118b2d;
    padding: 12px; }

#html-body [data-content-type='faq'] {
  list-style-type: none;
  position: relative;
  width: 100%;
  padding: 0; }

#html-body [data-content-type='faq-item'] {
  overflow: hidden;
  padding: 1.25rem 1.25rem 1.25rem 4rem;
  border-bottom: 1px solid #d6dbe1;
  margin: 0; }
  #html-body [data-content-type='faq-item'] [data-role='collapsible'] {
    font-weight: 700;
    line-height: 1.1;
    margin: 0;
    font-size: 16px;
    cursor: pointer; }
    #html-body [data-content-type='faq-item'] [data-role='collapsible']::before {
      font-family: 'pagebuilder-font';
      margin-right: 5px;
      font-size: 12px;
      left: 15px;
      line-height: 1.8;
      position: absolute; }
  #html-body [data-content-type='faq-item'] [data-role='collapsible'][aria-expanded='true']::before {
    content: '\f104'; }
  #html-body [data-content-type='faq-item'] [data-role='collapsible'][aria-expanded='false']::before {
    content: '\f101'; }

#html-body [data-content-type='faq-item'] [data-role='content'] [data-element='answer'] {
  padding-left: 0;
  margin-top: 1.5rem; }

@media only screen and (min-width: 768px) and (max-width: 1198px) {
  #html-body .pagebuilder-column.mobile-half-width {
    display: grid;
    grid-template-columns: 2fr 1fr;
    align-items: center;
    padding: 2vw 0;
    border-bottom: 1px solid #118b2d; }
    #html-body .pagebuilder-column.mobile-half-width figure {
      grid-column: 2;
      grid-row: 1 / span 2;
      padding: 0;
      width: 100%;
      height: 100%;
      min-height: 15vw;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center; }
    #html-body .pagebuilder-column.mobile-half-width .content {
      padding: 0;
      color: #434343;
      line-height: 1.5;
      font-size: 1.2em;
      align-self: self-start; }
    #html-body .pagebuilder-column.mobile-half-width .pagebuilder-button-primary {
      border-radius: 5rem;
      padding: 0.5rem 3rem;
      font-family: inherit;
      font-weight: 500;
      font-size: 1.2em;
      min-width: 50%; }
  #html-body .pagebuilder-column [data-content-type='products'] .block.widget .products-grid .widget-product-grid.product-items .product-item {
    width: calc(33.33% - 20px) !important;
    margin: 10px !important; } }

@media only screen and (max-width: 1198px) {
  #html-body .pagebuilder-column {
    display: unset;
    flex-basis: unset; } }

@media only screen and (max-width: 1022px) {
  #html-body .pagebuilder-column.mobile-half-width {
    display: grid;
    grid-template-columns: 6fr 1fr;
    align-items: center;
    padding: 4vw 0;
    border-bottom: 1px solid #118b2d;
    width: 100%; }
    #html-body .pagebuilder-column.mobile-half-width:nth-of-type(even) {
      grid-template-columns: 1fr 6fr;
      text-align: right; }
      #html-body .pagebuilder-column.mobile-half-width:nth-of-type(even) figure {
        grid-column: 1; }
    #html-body .pagebuilder-column.mobile-half-width figure {
      grid-column: 2;
      grid-row: 1 / span 2;
      padding: 0;
      width: 100%;
      height: 100%;
      min-height: 15vw;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center; }
    #html-body .pagebuilder-column.mobile-half-width .content {
      padding: 0;
      color: #434343;
      line-height: 1.5;
      font-size: 1.2em;
      align-self: self-start; }
    #html-body .pagebuilder-column.mobile-half-width .pagebuilder-button-primary {
      border-radius: 5rem;
      padding: 0.5rem 3rem;
      font-family: inherit;
      font-weight: 500;
      font-size: 1.2em;
      min-width: 50%; }
  #html-body .pagebuilder-column {
    display: flex;
    flex-direction: column; }
    #html-body .pagebuilder-column [data-content-type='products'] .block.widget .products-grid .widget-product-grid.product-items .product-item {
      width: calc(50% - 16px);
      margin: 8px; } }

@media only screen and (min-width: 1200px) {
  #html-body .pagebuilder-column [data-content-type='products'] .block.widget .products-grid .widget-product-grid.product-items .product-item {
    width: calc(25% - 20px);
    padding: 1rem;
    margin: 10px; }
  #html-body [data-content-type='row'][data-appearance='contained'] .kontakt-oss-form .pagebuilder-column-group .pagebuilder-column [data-content-type='html'] {
    margin-left: 25%; } }

@media only screen and (min-width: 1024px) {
  #html-body .pagebuilder-column-group .pagebuilder-column {
    position: relative; }
    #html-body .pagebuilder-column-group .pagebuilder-column .banda-banner-button {
      position: absolute;
      top: 80%; }
  #html-body .contact-us-form .fieldset .label {
    width: 100%;
    text-align: unset;
    margin-bottom: 3px; }
  #html-body .contact-us-form .fieldset .control {
    width: 100%; }
    #html-body .contact-us-form .fieldset .control input {
      background-color: transparent;
      font-size: 1em;
      box-shadow: none;
      font-weight: 400;
      line-height: 1em;
      height: 40px;
      padding: 0 20px;
      border: 1px solid #eee; }
    #html-body .contact-us-form .fieldset .control textarea {
      background-color: transparent;
      font-size: 1em;
      box-shadow: none;
      font-weight: 400;
      line-height: 1em;
      padding: 20px 20px;
      border: 1px solid #eee; }
  #html-body .contact-us-form .actions-toolbar {
    margin-left: 0px; }
    #html-body .contact-us-form .actions-toolbar .primary button, #html-body .contact-us-form .actions-toolbar .primary .cart.table-wrapper .actions-toolbar > .action, .cart.table-wrapper #html-body .contact-us-form .actions-toolbar .primary .actions-toolbar > .action, #html-body .contact-us-form .actions-toolbar .primary .action-gift {
      border-radius: 6px; } }

@media only screen and (max-width: 1024px) {
  #html-body .image-fullwidth img {
    width: 100% !important; }
  #html-body.cms-bestill-reseptvarer .reseptvarer .pagebuilder-column {
    width: 100% !important;
    min-height: 0px !important;
    padding: 12px; }
    #html-body.cms-bestill-reseptvarer .reseptvarer .pagebuilder-column h3 {
      margin-top: 0px !important; }
    #html-body.cms-bestill-reseptvarer .reseptvarer .pagebuilder-column [data-content-type='text'] {
      margin-top: 0px !important; } }

@media only screen and (max-width: 479px) {
  #html-body .pagebuilder-column-group .pagebuilder-column {
    width: calc(100% - 16px); }
  #html-body .partner-logo-column .pagebuilder-column-group .pagebuilder-column {
    margin: 8px;
    width: calc(50% - 16px); }
  #html-body .reseptvarer-partner .pagebuilder-column-group .pagebuilder-column {
    width: 50%; }
  #html-body .reseptvarer-mobile .pagebuilder-column-group .pagebuilder-column [data-content-type='text'] p {
    padding-top: 12px;
    padding-bottom: 2px; }
  #html-body .reseptvarer-mobile .pagebuilder-column-group .pagebuilder-column .pagebuilder-button-primary {
    padding: 10px 22px;
    border-radius: 50px; }
  #html-body [data-content-type='row'][data-appearance='contained'] .reseptvarer-partner .pagebuilder-column-group {
    margin-left: 0px; } }

@media only screen and (min-width: 480px) and (max-width: 1023px) {
  #html-body .livskvalitet-tablet .pagebuilder-column-group .pagebuilder-column {
    margin: 8px;
    width: calc(33% - 16px); }
  #html-body .reseptvarer-mobile .pagebuilder-column-group .pagebuilder-column {
    width: 100%; }
    #html-body .reseptvarer-mobile .pagebuilder-column-group .pagebuilder-column [data-content-type='text'] p {
      padding-top: 12px;
      padding-bottom: 2px; }
    #html-body .reseptvarer-mobile .pagebuilder-column-group .pagebuilder-column .pagebuilder-button-primary {
      padding: 10px 36px !important;
      border-radius: 50px !important; }
  #html-body [data-content-type='row'][data-appearance='contained'] .reseptvarer-partner .pagebuilder-column-group {
    margin-left: 0px; } }

@media only screen and (min-width: 767px) {
  #html-body .pagebuilder-column-group .pagebuilder-column h1 {
    font-size: 40px; } }

@media only screen and (min-width: 1024px) {
  .contact-index-index .page-title-wrapper {
    padding: 0 8px;
    margin-left: 25.8%;
    margin-top: 15px; }
    .contact-index-index .page-title-wrapper .page-title {
      font-weight: 700; }
  .contact-index-index .page.messages {
    margin: 8px 16px 8px 26.5%; }
  .contact-index-index .columns {
    margin: 0 8px 0 25.8%; } }

.columns .main .app-container {
  width: 100% !important; }

.columns .mapboxgl-ctrl-geocoder.mapboxgl-ctrl,
.columns .location-button-container,
.columns .list-card,
.columns .store-list-item-button {
  border-radius: 0; }

.columns .app-content .location-button-container {
  background-color: #118b2d;
  text-transform: uppercase; }
  .columns .app-content .location-button-container:hover {
    background-color: #00812b; }

.columns .app-content ul .store-list-item-name {
  color: #1a1a1a; }

.columns .app-content ul .list-card button, .columns .app-content ul .list-card .cart.table-wrapper .actions-toolbar > .action, .cart.table-wrapper .columns .app-content ul .list-card .actions-toolbar > .action, .columns .app-content ul .list-card .action-gift {
  display: none; }

.columns .app-content ul .store-list-item-details {
  font-size: 1.5rem; }

.columns .app-content .store-list-item-button {
  color: #1a1a1a;
  border: 1px solid #1a1a1a; }

.store-locator-container #store-locator .store-search-input {
  border: 1px solid #e0e0e0;
  background-color: #fff; }
  .store-locator-container #store-locator .store-search-input:focus {
    border-color: #005d30;
    background-color: white; }

.store-locator-container #store-locator #store-count {
  color: #00904a;
  background-color: #fff; }

.store-locator-container #store-locator .store-info-icon {
  color: #00904a; }

.store-locator-container #store-locator .store-name {
  color: #00904a; }

.store-locator-container #store-locator .store:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15); }

.store-locator-container #store-locator .store .directions-button, .store-locator-container #store-locator .store .show-more-info {
  background-color: #00904a;
  color: #fff; }
  .store-locator-container #store-locator .store .directions-button:hover, .store-locator-container #store-locator .store .directions-button:focus, .store-locator-container #store-locator .store .show-more-info:hover, .store-locator-container #store-locator .store .show-more-info:focus {
    background-color: #00703c; }
  .store-locator-container #store-locator .store .directions-button:active, .store-locator-container #store-locator .store .show-more-info:active {
    background-color: #005f30; }

.store-locator-container #store-locator .store .distance-to-store {
  color: #00904a;
  background-color: #fff; }

.store-locator-container #store-locator #storeList::-webkit-scrollbar-track {
  background: #e0e0e0; }

.store-locator-container #store-locator #storeList::-webkit-scrollbar-thumb {
  background-color: #00904a; }

.store-locator-container #store-locator .search-suggestions {
  border: 1px solid #e0e0e0;
  background-color: #fff; }

.store-locator-container #store-locator .search-suggestions .suggestion {
  border-bottom: 1px solid #e0e0e0;
  color: #00904a; }

.store-locator-container #store-locator .search-suggestions .suggestion:hover {
  background-color: #00703c;
  color: #fff; }

.store-locator-container #store-locator .get-my-location-button, .store-locator-container #store-locator .show-all-stores {
  background-color: #00904a; }
  .store-locator-container #store-locator .get-my-location-button :hover, .store-locator-container #store-locator .show-all-stores :hover {
    background-color: #00703c; }

#map-selector .mapboxgl-popup-content {
  display: flex;
  align-items: baseline; }
  #map-selector .mapboxgl-popup-content .mapboxgl-popup-close-button {
    position: relative;
    top: -10px;
    right: -10px;
    cursor: pointer; }

.select-bandagist-modal .modal-header h1 {
  min-height: 0;
  padding: 0; }

.select-bandagist-modal .modal-header h2 {
  margin-top: 0;
  margin-bottom: 5px; }

.page-main .pharmacy-picker-description-text {
  margin-bottom: 2.5rem; }

.header-store-list, .pharmacy-picker form {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  gap: 6px; }
  @media (min-width: 768px) {
    .header-store-list, .pharmacy-picker form {
      grid-template-columns: 25% 75%;
      grid-template-rows: 50px 40px 40px 24px; }
      .header-store-list > :nth-child(2), .pharmacy-picker form > :nth-child(2) {
        grid-column: 2;
        grid-row: 1 / span 4; } }
  .header-store-list #map-selector, .pharmacy-picker form #map-selector {
    height: 300px;
    width: 100%;
    border: 1px solid #ccc;
    order: 1; }
    @media (min-width: 768px) {
      .header-store-list #map-selector, .pharmacy-picker form #map-selector {
        height: 560px; } }
  .header-store-list #show-all-stores, .header-store-list #get-current-location, .pharmacy-picker form #show-all-stores, .pharmacy-picker form #get-current-location {
    margin-bottom: 6px;
    background-color: #00904a;
    color: #fff;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase; }
  .header-store-list .mapbox-store-counter, .pharmacy-picker form .mapbox-store-counter {
    text-align: center; }
  .header-store-list #pharmacy-list, .pharmacy-picker form #pharmacy-list {
    display: block;
    max-height: 400px;
    min-height: 400px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #ccc #f0f0f0; }
    @media (max-width: 768px) {
      .header-store-list #pharmacy-list, .pharmacy-picker form #pharmacy-list {
        max-height: 200px;
        min-height: 200px; } }
    .header-store-list #pharmacy-list::-webkit-scrollbar, .pharmacy-picker form #pharmacy-list::-webkit-scrollbar {
      width: 8px; }
    .header-store-list #pharmacy-list::-webkit-scrollbar-track, .pharmacy-picker form #pharmacy-list::-webkit-scrollbar-track {
      background: #f0f0f0; }
    .header-store-list #pharmacy-list::-webkit-scrollbar-thumb, .pharmacy-picker form #pharmacy-list::-webkit-scrollbar-thumb {
      background-color: #ccc;
      border-radius: 4px; }
    .header-store-list #pharmacy-list li, .pharmacy-picker form #pharmacy-list li {
      list-style: none;
      margin: 5px 0;
      padding: 0;
      border-bottom: 1px solid #eee;
      cursor: pointer;
      background-color: #f6f6f6; }
      .header-store-list #pharmacy-list li span, .pharmacy-picker form #pharmacy-list li span {
        font-size: 14px; }
      .header-store-list #pharmacy-list li:hover, .pharmacy-picker form #pharmacy-list li:hover {
        background-color: #e9e9e9; }
        .header-store-list #pharmacy-list li:hover span, .pharmacy-picker form #pharmacy-list li:hover span {
          background-color: #e9e9e9; }
      .header-store-list #pharmacy-list li.selected, .pharmacy-picker form #pharmacy-list li.selected {
        background-color: #f0f0f0;
        border-left: 1px solid #dcdcdc;
        font-weight: bold; }
        .header-store-list #pharmacy-list li.selected span, .pharmacy-picker form #pharmacy-list li.selected span {
          background-color: #f0f0f0; }
      .header-store-list #pharmacy-list li .store-address, .pharmacy-picker form #pharmacy-list li .store-address {
        padding-left: 12px;
        font-size: 13px; }
      .header-store-list #pharmacy-list li .store-distance, .pharmacy-picker form #pharmacy-list li .store-distance {
        padding-left: 12px;
        font-size: 11px;
        padding-bottom: 9px; }
  .header-store-list .pharmacy-search, .pharmacy-picker form .pharmacy-search {
    position: relative; }
    .header-store-list .pharmacy-search #myInput, .pharmacy-picker form .pharmacy-search #myInput {
      font-size: 14px; }
    .header-store-list .pharmacy-search #search-suggestions, .pharmacy-picker form .pharmacy-search #search-suggestions {
      width: 100%;
      background: #fff;
      border: 1px solid #ccc;
      border-top: none;
      overflow-y: auto;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      margin: 0; }
      .header-store-list .pharmacy-search #search-suggestions li, .pharmacy-picker form .pharmacy-search #search-suggestions li {
        width: 100%;
        cursor: pointer; }
        .header-store-list .pharmacy-search #search-suggestions li:hover, .pharmacy-picker form .pharmacy-search #search-suggestions li:hover {
          background-color: #e9e9e9; }

body[class*="catalog"] .p2-category-top .page-title {
  font-size: 35px;
  color: #000;
  margin-bottom: 1rem;
  font-weight: 500;
  padding-left: 1rem;
  margin-top: 4rem; }

body[class*="catalog"] .p2-category-top .category-description {
  color: #1a1a1a;
  font-size: 16px;
  border-bottom: unset; }

span.old-price.sly-old-price span.price:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  background: #434343;
  left: 0;
  top: 54%; }

span.old-price.sly-old-price span.price {
  position: relative; }

span.old-price.sly-old-price {
  display: block !important;
  margin-top: 0; }

span.old-price span.price:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  background: #434343;
  left: 0;
  top: 54%; }

span.old-price span.price {
  position: relative; }

.product-info-main .special-price,
.product-options-bottom .special-price {
  margin: 0; }

.products-grid .old-price,
.products-grid .old.price, .widget-product-carousel .old-price,
.widget-product-carousel .old.price {
  float: left;
  margin-right: 1.25rem; }
  .products-grid .old-price .price,
  .products-grid .old.price .price, .widget-product-carousel .old-price .price,
  .widget-product-carousel .old.price .price {
    font-weight: 400 !important; }

.products-grid .product-image-container, .widget-product-carousel .product-image-container {
  width: 100%; }

.products-grid .product-image-wrapper, .widget-product-carousel .product-image-wrapper {
  height: 220px;
  width: 100%; }

.products-grid .product-image-photo, .widget-product-carousel .product-image-photo {
  height: 100%;
  width: 100%;
  max-width: unset;
  position: relative;
  left: unset;
  right: unset;
  top: unset;
  object-fit: contain;
  object-position: top; }
  .block.crosssell .products-grid .product-image-photo, .block.crosssell .widget-product-carousel .product-image-photo {
    width: auto;
    height: 10rem; }

.product-info-main .price-box .price-wrapper .price,
.product-options-bottom .price-box .price-wrapper .price {
  font-size: 32px;
  font-weight: unset; }

.product-info-main .special-price,
.product-options-bottom .special-price {
  display: inline-block; }
  .product-info-main .special-price .price-container .price,
  .product-options-bottom .special-price .price-container .price {
    font-size: 32px; }

.product-info-main .box-tocart .action.tocart,
.product-options-bottom .box-tocart .action.tocart {
  max-height: unset; }

@media only screen and (max-width: 1023px) {
  body[class*="catalog"] .p2-category-top .page-title {
    margin-top: 1rem; } }

.page-products .columns {
  padding-top: 0; }

.toolbar {
  margin-bottom: 0; }
  .toolbar-products {
    display: block;
    margin-top: 1.5rem; }
    .toolbar-products:last-of-type .field.limiter {
      float: none; }
    .toolbar-products .toolbar-amount {
      margin: 0;
      font-size: 20px;
      font-weight: 500; }
      .toolbar-products .toolbar-amount .toolbar-number {
        font-size: 20px;
        font-weight: 700;
        padding: 0 0.2rem;
        vertical-align: text-bottom; }

.sidebar-main .toolbar-sorter.sorter {
  display: flex;
  float: none;
  align-content: center;
  height: 52px;
  outline: 0.5rem solid #ffffff;
  border: 1px solid #bfbfbf;
  border-radius: 5px;
  padding: 0.55rem 1rem;
  box-shadow: none;
  background: none;
  justify-content: center;
  flex-direction: row-reverse; }

.toolbar-sorter.sorter .sorter-label {
  display: none; }

.sidebar-main .toolbar-sorter.sorter .sorter-options {
  margin: 0;
  width: auto;
  border: none;
  height: auto;
  padding: 0;
  font-family: inherit; }

.toolbar-sorter.sorter .sorter-options:focus {
  box-shadow: none;
  border: none;
  outline: none; }

.sidebar-main .toolbar-sorter.sorter .sorter-action {
  display: flex;
  align-items: center; }

@media only screen and (min-width: 1024px) {
  .modes {
    display: none; } }

.widget-product-grid {
  padding-left: 0; }

@media only screen and (min-width: 1024px) {
  .block.widget .products-grid .product-item {
    width: 33%;
    padding: 1rem; } }

@media only screen and (min-width: 1200px) {
  .block.widget .products-grid .product-item {
    width: 24.1%;
    padding: 1rem; } }

@media only screen and (min-width: 1024px) {
  .sidebar .block.widget .pager .item:not(.pages-item-next):not(.pages-item-previous) {
    display: none; } }

.block-static-block .block-products-list .products-grid .product-item-info img {
  height: 100%; }

.opc-block-summary .items-in-cart > .title {
  display: none !important; }

.rating-summary .rating-result {
  margin-top: -4px; }

.availability.only.configurable-variation-qty {
  display: none; }

.product-reviews-summary {
  display: flex;
  margin: 0 auto 0 0;
  margin-top: .5rem; }
  .products.wrapper.list .product-reviews-summary {
    margin: 0 auto 0 0; }
  .product-reviews-summary .reviews-actions {
    display: flex;
    width: 70%;
    flex-direction: row; }
    .product-reviews-summary .reviews-actions a {
      display: flex; }
      .product-reviews-summary .reviews-actions a.view {
        margin-right: .5rem; }
      .product-reviews-summary .reviews-actions a.add {
        display: none; }

@media only screen and (min-width: 1024px) {
  .product-reviews-summary {
    align-items: center; }
    .product-reviews-summary .reviews-actions a {
      width: 40%;
      white-space: nowrap; }
    .product-reviews-summary .reviews-actions a.add {
      display: none; } }

.review-field-ratings .review-field-rating .label:before,
.review-field-ratings .review-field-rating .control:before {
  height: 26px;
  font-size: 26px; }

.review-field-ratings .review-field-rating .label label:before,
.review-field-ratings .review-field-rating .control label:before {
  height: 26px;
  font-size: 26px; }

.review-field-ratings .review-field-rating .label {
  display: none; }

.review-field-ratings .review-field-rating .control {
  padding-top: 2px; }

.review legend.label {
  white-space: nowrap; }

.fieldset .review-legend.legend span {
  float: left; }

.fieldset .review-legend.legend strong {
  margin-left: 8px; }

.review-list .block-title strong {
  color: inherit;
  font-family: inherit;
  font-style: inherit;
  font-weight: 500;
  line-height: 1.1;
  font-size: 20px;
  margin-top: 7px;
  margin-bottom: 7px; }

.review-item {
  padding: 20px 0 0px 0; }

.review-ratings {
  margin: -2px 0px 4px 0; }

.review-title {
  font-weight: 300;
  line-height: 1.1;
  font-size: 1.8rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  margin: 0 0.5rem 0 0;
  font-size: 16px;
  font-weight: 900; }

.review-content {
  margin-top: 10px; }

@media only screen and (min-width: 768px) {
  .review-form fieldset {
    margin: 0 0 15px; }
    .review-form fieldset legend.label {
      white-space: nowrap; }
  .review-ratings {
    float: right;
    margin-top: -.15rem; }
  .review-ratings ~ .review-content,
  .review-ratings ~ .review-details {
    margin-left: 0;
    width: 100%;
    float: left;
    position: relative;
    padding-right: 1.5rem;
    margin-top: 0; }
    .review-ratings ~ .review-content:before,
    .review-ratings ~ .review-details:before {
      display: none;
      margin-right: 1rem;
      position: absolute;
      left: -2rem;
      top: -.6rem; }
  .fieldset .review-legend.legend {
    margin-bottom: 25px; }
  .review-item {
    padding: 30px 0 0 0; }
  .review-title {
    margin: 0rem 1rem .5rem 0; } }

.swatch-option.text, .swatch-attribute.size .swatch-option {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  border: none;
  min-width: 20px;
  max-width: fit-content;
  color: #3d3d3d;
  background: #f9f9f9;
  font-size: 15px;
  font-weight: 500;
  margin: 12px 12px 0 0; }
  @media (max-width: 600px) {
    .swatch-option.text, .swatch-attribute.size .swatch-option {
      font-size: 12px;
      min-width: 14px; } }
  .swatch-option.text:hover, .swatch-attribute.size .swatch-option:hover {
    background-color: #00954d;
    color: white;
    cursor: pointer;
    box-shadow: none;
    border: none; }
  .swatch-option.text.selected, .swatch-attribute.size .swatch-option.selected {
    background-color: #00904a;
    color: white;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
    border: none; }

.swatch-attribute {
  margin-top: 8px; }
  .swatch-attribute .size .swatch-option.selected {
    background-color: #00904a;
    color: white;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
    border: none; }

/* Gridlex */
[class~="p-grid"],
[class*="p-grid-"],
[class*="p-grid_"] {
  box-sizing: border-box;
  display: flex;
  flex-flow: row wrap;
  margin: 0 -0.5rem; }

[class~="p-col"],
[class*="p-col-"],
[class*="p-col_"] {
  box-sizing: border-box;
  padding: 0 0.5rem 1rem;
  max-width: 100%; }

[class~="p-col"],
[class*="p-col_"] {
  flex: 1 1 0%; }

[class*="p-col-"] {
  flex: none; }

[class~="p-grid"][class~="p-col"],
[class~="p-grid"][class*="p-col-"],
[class~="p-grid"][class*="p-col_"],
[class*="p-grid-"][class~="p-col"],
[class*="p-grid-"][class*="p-col-"],
[class*="p-grid-"][class*="p-col_"],
[class*="p-grid_"][class~="p-col"],
[class*="p-grid_"][class*="p-col-"],
[class*="p-grid_"][class*="p-col_"] {
  margin: 0;
  padding: 0; }

/************************
    HELPERS SUFFIXES
*************************/
[class*="p-grid-"][class*="-noGutter"] {
  margin: 0; }
  [class*="p-grid-"][class*="-noGutter"] > [class~="p-col"],
  [class*="p-grid-"][class*="-noGutter"] > [class*="p-col-"] {
    padding: 0; }

[class*="p-grid-"][class*="-noWrap"] {
  flex-wrap: nowrap; }

[class*="p-grid-"][class*="-center"] {
  justify-content: center; }

[class*="p-grid-"][class*="-right"] {
  justify-content: flex-end;
  align-self: flex-end;
  margin-left: auto; }

[class*="p-grid-"][class*="-top"] {
  align-items: flex-start; }

[class*="p-grid-"][class*="-middle"] {
  align-items: center; }

[class*="p-grid-"][class*="-bottom"] {
  align-items: flex-end; }

[class*="p-grid-"][class*="-reverse"] {
  flex-direction: row-reverse; }

[class*="p-grid-"][class*="-column"] {
  flex-direction: column; }
  [class*="p-grid-"][class*="-column"] > [class*="p-col-"] {
    flex-basis: auto; }

[class*="p-grid-"][class*="-spaceBetween"] {
  justify-content: space-between; }

[class*="p-grid-"][class*="-spaceAround"] {
  justify-content: space-around; }

[class*="p-grid-"][class*="-equalHeight"] > [class~="p-col"],
[class*="p-grid-"][class*="-equalHeight"] > [class*="p-col-"],
[class*="p-grid-"][class*="-equalHeight"] > [class*="p-col_"] {
  align-self: stretch; }
  [class*="p-grid-"][class*="-equalHeight"] > [class~="p-col"] > *,
  [class*="p-grid-"][class*="-equalHeight"] > [class*="p-col-"] > *,
  [class*="p-grid-"][class*="-equalHeight"] > [class*="p-col_"] > * {
    height: 100%; }

[class*="p-grid-"][class*="-noBottom"] > [class~="p-col"],
[class*="p-grid-"][class*="-noBottom"] > [class*="p-col-"],
[class*="p-grid-"][class*="-noBottom"] > [class*="p-col_"] {
  padding-bottom: 0; }

[class*="p-col-"][class*="-top"] {
  align-self: flex-start; }

[class*="p-col-"][class*="-middle"] {
  align-self: center; }

[class*="p-col-"][class*="-bottom"] {
  align-self: flex-end; }

[class*="p-col-"][class*="-first"] {
  order: -1; }

[class*="p-col-"][class*="-last"] {
  order: 1; }

/************************
    GRID BY NUMBER
*************************/
[class*="p-grid-1"] > [class~="p-col"],
[class*="p-grid-1"] > [class*="p-col-"],
[class*="p-grid-1"] > [class*="p-col_"] {
  flex-basis: 100%;
  max-width: 100%; }

[class*="p-grid-2"] > [class~="p-col"],
[class*="p-grid-2"] > [class*="p-col-"],
[class*="p-grid-2"] > [class*="p-col_"] {
  flex-basis: 50%;
  max-width: 50%; }

[class*="p-grid-3"] > [class~="p-col"],
[class*="p-grid-3"] > [class*="p-col-"],
[class*="p-grid-3"] > [class*="p-col_"] {
  flex-basis: 33.33333%;
  max-width: 33.33333%; }

[class*="p-grid-4"] > [class~="p-col"],
[class*="p-grid-4"] > [class*="p-col-"],
[class*="p-grid-4"] > [class*="p-col_"] {
  flex-basis: 25%;
  max-width: 25%; }

[class*="p-grid-5"] > [class~="p-col"],
[class*="p-grid-5"] > [class*="p-col-"],
[class*="p-grid-5"] > [class*="p-col_"] {
  flex-basis: 20%;
  max-width: 20%; }

[class*="p-grid-6"] > [class~="p-col"],
[class*="p-grid-6"] > [class*="p-col-"],
[class*="p-grid-6"] > [class*="p-col_"] {
  flex-basis: 16.66667%;
  max-width: 16.66667%; }

[class*="p-grid-7"] > [class~="p-col"],
[class*="p-grid-7"] > [class*="p-col-"],
[class*="p-grid-7"] > [class*="p-col_"] {
  flex-basis: 14.28571%;
  max-width: 14.28571%; }

[class*="p-grid-8"] > [class~="p-col"],
[class*="p-grid-8"] > [class*="p-col-"],
[class*="p-grid-8"] > [class*="p-col_"] {
  flex-basis: 12.5%;
  max-width: 12.5%; }

[class*="p-grid-9"] > [class~="p-col"],
[class*="p-grid-9"] > [class*="p-col-"],
[class*="p-grid-9"] > [class*="p-col_"] {
  flex-basis: 11.11111%;
  max-width: 11.11111%; }

[class*="p-grid-10"] > [class~="p-col"],
[class*="p-grid-10"] > [class*="p-col-"],
[class*="p-grid-10"] > [class*="p-col_"] {
  flex-basis: 10%;
  max-width: 10%; }

[class*="p-grid-11"] > [class~="p-col"],
[class*="p-grid-11"] > [class*="p-col-"],
[class*="p-grid-11"] > [class*="p-col_"] {
  flex-basis: 9.09091%;
  max-width: 9.09091%; }

[class*="p-grid-12"] > [class~="p-col"],
[class*="p-grid-12"] > [class*="p-col-"],
[class*="p-grid-12"] > [class*="p-col_"] {
  flex-basis: 8.33333%;
  max-width: 8.33333%; }

@media (max-width: 1200px) {
  [class*="_lg-1"] > [class~="p-col"],
  [class*="_lg-1"] > [class*="p-col-"],
  [class*="_lg-1"] > [class*="p-col_"] {
    flex-basis: 100%;
    max-width: 100%; }
  [class*="_lg-2"] > [class~="p-col"],
  [class*="_lg-2"] > [class*="p-col-"],
  [class*="_lg-2"] > [class*="p-col_"] {
    flex-basis: 50%;
    max-width: 50%; }
  [class*="_lg-3"] > [class~="p-col"],
  [class*="_lg-3"] > [class*="p-col-"],
  [class*="_lg-3"] > [class*="p-col_"] {
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  [class*="_lg-4"] > [class~="p-col"],
  [class*="_lg-4"] > [class*="p-col-"],
  [class*="_lg-4"] > [class*="p-col_"] {
    flex-basis: 25%;
    max-width: 25%; }
  [class*="_lg-5"] > [class~="p-col"],
  [class*="_lg-5"] > [class*="p-col-"],
  [class*="_lg-5"] > [class*="p-col_"] {
    flex-basis: 20%;
    max-width: 20%; }
  [class*="_lg-6"] > [class~="p-col"],
  [class*="_lg-6"] > [class*="p-col-"],
  [class*="_lg-6"] > [class*="p-col_"] {
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  [class*="_lg-7"] > [class~="p-col"],
  [class*="_lg-7"] > [class*="p-col-"],
  [class*="_lg-7"] > [class*="p-col_"] {
    flex-basis: 14.28571%;
    max-width: 14.28571%; }
  [class*="_lg-8"] > [class~="p-col"],
  [class*="_lg-8"] > [class*="p-col-"],
  [class*="_lg-8"] > [class*="p-col_"] {
    flex-basis: 12.5%;
    max-width: 12.5%; }
  [class*="_lg-9"] > [class~="p-col"],
  [class*="_lg-9"] > [class*="p-col-"],
  [class*="_lg-9"] > [class*="p-col_"] {
    flex-basis: 11.11111%;
    max-width: 11.11111%; }
  [class*="_lg-10"] > [class~="p-col"],
  [class*="_lg-10"] > [class*="p-col-"],
  [class*="_lg-10"] > [class*="p-col_"] {
    flex-basis: 10%;
    max-width: 10%; }
  [class*="_lg-11"] > [class~="p-col"],
  [class*="_lg-11"] > [class*="p-col-"],
  [class*="_lg-11"] > [class*="p-col_"] {
    flex-basis: 9.09091%;
    max-width: 9.09091%; }
  [class*="_lg-12"] > [class~="p-col"],
  [class*="_lg-12"] > [class*="p-col-"],
  [class*="_lg-12"] > [class*="p-col_"] {
    flex-basis: 8.33333%;
    max-width: 8.33333%; } }

@media (max-width: 1024px) {
  [class*="_md-1"] > [class~="p-col"],
  [class*="_md-1"] > [class*="p-col-"],
  [class*="_md-1"] > [class*="p-col_"] {
    flex-basis: 100%;
    max-width: 100%; }
  [class*="_md-2"] > [class~="p-col"],
  [class*="_md-2"] > [class*="p-col-"],
  [class*="_md-2"] > [class*="p-col_"] {
    flex-basis: 50%;
    max-width: 50%; }
  [class*="_md-3"] > [class~="p-col"],
  [class*="_md-3"] > [class*="p-col-"],
  [class*="_md-3"] > [class*="p-col_"] {
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  [class*="_md-4"] > [class~="p-col"],
  [class*="_md-4"] > [class*="p-col-"],
  [class*="_md-4"] > [class*="p-col_"] {
    flex-basis: 25%;
    max-width: 25%; }
  [class*="_md-5"] > [class~="p-col"],
  [class*="_md-5"] > [class*="p-col-"],
  [class*="_md-5"] > [class*="p-col_"] {
    flex-basis: 20%;
    max-width: 20%; }
  [class*="_md-6"] > [class~="p-col"],
  [class*="_md-6"] > [class*="p-col-"],
  [class*="_md-6"] > [class*="p-col_"] {
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  [class*="_md-7"] > [class~="p-col"],
  [class*="_md-7"] > [class*="p-col-"],
  [class*="_md-7"] > [class*="p-col_"] {
    flex-basis: 14.28571%;
    max-width: 14.28571%; }
  [class*="_md-8"] > [class~="p-col"],
  [class*="_md-8"] > [class*="p-col-"],
  [class*="_md-8"] > [class*="p-col_"] {
    flex-basis: 12.5%;
    max-width: 12.5%; }
  [class*="_md-9"] > [class~="p-col"],
  [class*="_md-9"] > [class*="p-col-"],
  [class*="_md-9"] > [class*="p-col_"] {
    flex-basis: 11.11111%;
    max-width: 11.11111%; }
  [class*="_md-10"] > [class~="p-col"],
  [class*="_md-10"] > [class*="p-col-"],
  [class*="_md-10"] > [class*="p-col_"] {
    flex-basis: 10%;
    max-width: 10%; }
  [class*="_md-11"] > [class~="p-col"],
  [class*="_md-11"] > [class*="p-col-"],
  [class*="_md-11"] > [class*="p-col_"] {
    flex-basis: 9.09091%;
    max-width: 9.09091%; }
  [class*="_md-12"] > [class~="p-col"],
  [class*="_md-12"] > [class*="p-col-"],
  [class*="_md-12"] > [class*="p-col_"] {
    flex-basis: 8.33333%;
    max-width: 8.33333%; } }

@media (max-width: 768px) {
  [class*="_sm-1"] > [class~="p-col"],
  [class*="_sm-1"] > [class*="p-col-"],
  [class*="_sm-1"] > [class*="p-col_"] {
    flex-basis: 100%;
    max-width: 100%; }
  [class*="_sm-2"] > [class~="p-col"],
  [class*="_sm-2"] > [class*="p-col-"],
  [class*="_sm-2"] > [class*="p-col_"] {
    flex-basis: 50%;
    max-width: 50%; }
  [class*="_sm-3"] > [class~="p-col"],
  [class*="_sm-3"] > [class*="p-col-"],
  [class*="_sm-3"] > [class*="p-col_"] {
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  [class*="_sm-4"] > [class~="p-col"],
  [class*="_sm-4"] > [class*="p-col-"],
  [class*="_sm-4"] > [class*="p-col_"] {
    flex-basis: 25%;
    max-width: 25%; }
  [class*="_sm-5"] > [class~="p-col"],
  [class*="_sm-5"] > [class*="p-col-"],
  [class*="_sm-5"] > [class*="p-col_"] {
    flex-basis: 20%;
    max-width: 20%; }
  [class*="_sm-6"] > [class~="p-col"],
  [class*="_sm-6"] > [class*="p-col-"],
  [class*="_sm-6"] > [class*="p-col_"] {
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  [class*="_sm-7"] > [class~="p-col"],
  [class*="_sm-7"] > [class*="p-col-"],
  [class*="_sm-7"] > [class*="p-col_"] {
    flex-basis: 14.28571%;
    max-width: 14.28571%; }
  [class*="_sm-8"] > [class~="p-col"],
  [class*="_sm-8"] > [class*="p-col-"],
  [class*="_sm-8"] > [class*="p-col_"] {
    flex-basis: 12.5%;
    max-width: 12.5%; }
  [class*="_sm-9"] > [class~="p-col"],
  [class*="_sm-9"] > [class*="p-col-"],
  [class*="_sm-9"] > [class*="p-col_"] {
    flex-basis: 11.11111%;
    max-width: 11.11111%; }
  [class*="_sm-10"] > [class~="p-col"],
  [class*="_sm-10"] > [class*="p-col-"],
  [class*="_sm-10"] > [class*="p-col_"] {
    flex-basis: 10%;
    max-width: 10%; }
  [class*="_sm-11"] > [class~="p-col"],
  [class*="_sm-11"] > [class*="p-col-"],
  [class*="_sm-11"] > [class*="p-col_"] {
    flex-basis: 9.09091%;
    max-width: 9.09091%; }
  [class*="_sm-12"] > [class~="p-col"],
  [class*="_sm-12"] > [class*="p-col-"],
  [class*="_sm-12"] > [class*="p-col_"] {
    flex-basis: 8.33333%;
    max-width: 8.33333%; } }

@media (max-width: 480px) {
  [class*="_xs-1"] > [class~="p-col"],
  [class*="_xs-1"] > [class*="p-col-"],
  [class*="_xs-1"] > [class*="p-col_"] {
    flex-basis: 100%;
    max-width: 100%; }
  [class*="_xs-2"] > [class~="p-col"],
  [class*="_xs-2"] > [class*="p-col-"],
  [class*="_xs-2"] > [class*="p-col_"] {
    flex-basis: 50%;
    max-width: 50%; }
  [class*="_xs-3"] > [class~="p-col"],
  [class*="_xs-3"] > [class*="p-col-"],
  [class*="_xs-3"] > [class*="p-col_"] {
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  [class*="_xs-4"] > [class~="p-col"],
  [class*="_xs-4"] > [class*="p-col-"],
  [class*="_xs-4"] > [class*="p-col_"] {
    flex-basis: 25%;
    max-width: 25%; }
  [class*="_xs-5"] > [class~="p-col"],
  [class*="_xs-5"] > [class*="p-col-"],
  [class*="_xs-5"] > [class*="p-col_"] {
    flex-basis: 20%;
    max-width: 20%; }
  [class*="_xs-6"] > [class~="p-col"],
  [class*="_xs-6"] > [class*="p-col-"],
  [class*="_xs-6"] > [class*="p-col_"] {
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  [class*="_xs-7"] > [class~="p-col"],
  [class*="_xs-7"] > [class*="p-col-"],
  [class*="_xs-7"] > [class*="p-col_"] {
    flex-basis: 14.28571%;
    max-width: 14.28571%; }
  [class*="_xs-8"] > [class~="p-col"],
  [class*="_xs-8"] > [class*="p-col-"],
  [class*="_xs-8"] > [class*="p-col_"] {
    flex-basis: 12.5%;
    max-width: 12.5%; }
  [class*="_xs-9"] > [class~="p-col"],
  [class*="_xs-9"] > [class*="p-col-"],
  [class*="_xs-9"] > [class*="p-col_"] {
    flex-basis: 11.11111%;
    max-width: 11.11111%; }
  [class*="_xs-10"] > [class~="p-col"],
  [class*="_xs-10"] > [class*="p-col-"],
  [class*="_xs-10"] > [class*="p-col_"] {
    flex-basis: 10%;
    max-width: 10%; }
  [class*="_xs-11"] > [class~="p-col"],
  [class*="_xs-11"] > [class*="p-col-"],
  [class*="_xs-11"] > [class*="p-col_"] {
    flex-basis: 9.09091%;
    max-width: 9.09091%; }
  [class*="_xs-12"] > [class~="p-col"],
  [class*="_xs-12"] > [class*="p-col-"],
  [class*="_xs-12"] > [class*="p-col_"] {
    flex-basis: 8.33333%;
    max-width: 8.33333%; } }

/************************
    COLS SIZES
*************************/
[class~="p-grid"] > [class*="p-col-1"],
[class*="p-grid-"] > [class*="p-col-1"],
[class*="p-grid_"] > [class*="p-col-1"] {
  flex-basis: 8.33333%;
  max-width: 8.33333%; }

[class~="p-grid"] > [class*="p-col-2"],
[class*="p-grid-"] > [class*="p-col-2"],
[class*="p-grid_"] > [class*="p-col-2"] {
  flex-basis: 16.66667%;
  max-width: 16.66667%; }

[class~="p-grid"] > [class*="p-col-3"],
[class*="p-grid-"] > [class*="p-col-3"],
[class*="p-grid_"] > [class*="p-col-3"] {
  flex-basis: 25%;
  max-width: 25%; }

[class~="p-grid"] > [class*="p-col-4"],
[class*="p-grid-"] > [class*="p-col-4"],
[class*="p-grid_"] > [class*="p-col-4"] {
  flex-basis: 33.33333%;
  max-width: 33.33333%; }

[class~="p-grid"] > [class*="p-col-5"],
[class*="p-grid-"] > [class*="p-col-5"],
[class*="p-grid_"] > [class*="p-col-5"] {
  flex-basis: 41.66667%;
  max-width: 41.66667%; }

[class~="p-grid"] > [class*="p-col-6"],
[class*="p-grid-"] > [class*="p-col-6"],
[class*="p-grid_"] > [class*="p-col-6"] {
  flex-basis: 50%;
  max-width: 50%; }

[class~="p-grid"] > [class*="p-col-7"],
[class*="p-grid-"] > [class*="p-col-7"],
[class*="p-grid_"] > [class*="p-col-7"] {
  flex-basis: 58.33333%;
  max-width: 58.33333%; }

[class~="p-grid"] > [class*="p-col-8"],
[class*="p-grid-"] > [class*="p-col-8"],
[class*="p-grid_"] > [class*="p-col-8"] {
  flex-basis: 66.66667%;
  max-width: 66.66667%; }

[class~="p-grid"] > [class*="p-col-9"],
[class*="p-grid-"] > [class*="p-col-9"],
[class*="p-grid_"] > [class*="p-col-9"] {
  flex-basis: 75%;
  max-width: 75%; }

[class~="p-grid"] > [class*="p-col-10"],
[class*="p-grid-"] > [class*="p-col-10"],
[class*="p-grid_"] > [class*="p-col-10"] {
  flex-basis: 83.33333%;
  max-width: 83.33333%; }

[class~="p-grid"] > [class*="p-col-11"],
[class*="p-grid-"] > [class*="p-col-11"],
[class*="p-grid_"] > [class*="p-col-11"] {
  flex-basis: 91.66667%;
  max-width: 91.66667%; }

[class~="p-grid"] > [class*="p-col-12"],
[class*="p-grid-"] > [class*="p-col-12"],
[class*="p-grid_"] > [class*="p-col-12"] {
  flex-basis: 100%;
  max-width: 100%; }

[class~="p-grid"] > [data-push-left*="off-0"],
[class*="p-grid-"] > [data-push-left*="off-0"],
[class*="p-grid_"] > [data-push-left*="off-0"] {
  margin-left: 0; }

[class~="p-grid"] > [data-push-left*="off-1"],
[class*="p-grid-"] > [data-push-left*="off-1"],
[class*="p-grid_"] > [data-push-left*="off-1"] {
  margin-left: 8.33333%; }

[class~="p-grid"] > [data-push-left*="off-2"],
[class*="p-grid-"] > [data-push-left*="off-2"],
[class*="p-grid_"] > [data-push-left*="off-2"] {
  margin-left: 16.66667%; }

[class~="p-grid"] > [data-push-left*="off-3"],
[class*="p-grid-"] > [data-push-left*="off-3"],
[class*="p-grid_"] > [data-push-left*="off-3"] {
  margin-left: 25%; }

[class~="p-grid"] > [data-push-left*="off-4"],
[class*="p-grid-"] > [data-push-left*="off-4"],
[class*="p-grid_"] > [data-push-left*="off-4"] {
  margin-left: 33.33333%; }

[class~="p-grid"] > [data-push-left*="off-5"],
[class*="p-grid-"] > [data-push-left*="off-5"],
[class*="p-grid_"] > [data-push-left*="off-5"] {
  margin-left: 41.66667%; }

[class~="p-grid"] > [data-push-left*="off-6"],
[class*="p-grid-"] > [data-push-left*="off-6"],
[class*="p-grid_"] > [data-push-left*="off-6"] {
  margin-left: 50%; }

[class~="p-grid"] > [data-push-left*="off-7"],
[class*="p-grid-"] > [data-push-left*="off-7"],
[class*="p-grid_"] > [data-push-left*="off-7"] {
  margin-left: 58.33333%; }

[class~="p-grid"] > [data-push-left*="off-8"],
[class*="p-grid-"] > [data-push-left*="off-8"],
[class*="p-grid_"] > [data-push-left*="off-8"] {
  margin-left: 66.66667%; }

[class~="p-grid"] > [data-push-left*="off-9"],
[class*="p-grid-"] > [data-push-left*="off-9"],
[class*="p-grid_"] > [data-push-left*="off-9"] {
  margin-left: 75%; }

[class~="p-grid"] > [data-push-left*="off-10"],
[class*="p-grid-"] > [data-push-left*="off-10"],
[class*="p-grid_"] > [data-push-left*="off-10"] {
  margin-left: 83.33333%; }

[class~="p-grid"] > [data-push-left*="off-11"],
[class*="p-grid-"] > [data-push-left*="off-11"],
[class*="p-grid_"] > [data-push-left*="off-11"] {
  margin-left: 91.66667%; }

[class~="p-grid"] > [data-push-right*="off-0"],
[class*="p-grid-"] > [data-push-right*="off-0"],
[class*="p-grid_"] > [data-push-right*="off-0"] {
  margin-right: 0; }

[class~="p-grid"] > [data-push-right*="off-1"],
[class*="p-grid-"] > [data-push-right*="off-1"],
[class*="p-grid_"] > [data-push-right*="off-1"] {
  margin-right: 8.33333%; }

[class~="p-grid"] > [data-push-right*="off-2"],
[class*="p-grid-"] > [data-push-right*="off-2"],
[class*="p-grid_"] > [data-push-right*="off-2"] {
  margin-right: 16.66667%; }

[class~="p-grid"] > [data-push-right*="off-3"],
[class*="p-grid-"] > [data-push-right*="off-3"],
[class*="p-grid_"] > [data-push-right*="off-3"] {
  margin-right: 25%; }

[class~="p-grid"] > [data-push-right*="off-4"],
[class*="p-grid-"] > [data-push-right*="off-4"],
[class*="p-grid_"] > [data-push-right*="off-4"] {
  margin-right: 33.33333%; }

[class~="p-grid"] > [data-push-right*="off-5"],
[class*="p-grid-"] > [data-push-right*="off-5"],
[class*="p-grid_"] > [data-push-right*="off-5"] {
  margin-right: 41.66667%; }

[class~="p-grid"] > [data-push-right*="off-6"],
[class*="p-grid-"] > [data-push-right*="off-6"],
[class*="p-grid_"] > [data-push-right*="off-6"] {
  margin-right: 50%; }

[class~="p-grid"] > [data-push-right*="off-7"],
[class*="p-grid-"] > [data-push-right*="off-7"],
[class*="p-grid_"] > [data-push-right*="off-7"] {
  margin-right: 58.33333%; }

[class~="p-grid"] > [data-push-right*="off-8"],
[class*="p-grid-"] > [data-push-right*="off-8"],
[class*="p-grid_"] > [data-push-right*="off-8"] {
  margin-right: 66.66667%; }

[class~="p-grid"] > [data-push-right*="off-9"],
[class*="p-grid-"] > [data-push-right*="off-9"],
[class*="p-grid_"] > [data-push-right*="off-9"] {
  margin-right: 75%; }

[class~="p-grid"] > [data-push-right*="off-10"],
[class*="p-grid-"] > [data-push-right*="off-10"],
[class*="p-grid_"] > [data-push-right*="off-10"] {
  margin-right: 83.33333%; }

[class~="p-grid"] > [data-push-right*="off-11"],
[class*="p-grid-"] > [data-push-right*="off-11"],
[class*="p-grid_"] > [data-push-right*="off-11"] {
  margin-right: 91.66667%; }

@media (max-width: 1200px) {
  [class~="p-grid"] > [class*="_lg-1"],
  [class*="p-grid-"] > [class*="_lg-1"],
  [class*="p-grid_"] > [class*="_lg-1"] {
    flex-basis: 8.33333%;
    max-width: 8.33333%; }
  [class~="p-grid"] > [class*="_lg-2"],
  [class*="p-grid-"] > [class*="_lg-2"],
  [class*="p-grid_"] > [class*="_lg-2"] {
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  [class~="p-grid"] > [class*="_lg-3"],
  [class*="p-grid-"] > [class*="_lg-3"],
  [class*="p-grid_"] > [class*="_lg-3"] {
    flex-basis: 25%;
    max-width: 25%; }
  [class~="p-grid"] > [class*="_lg-4"],
  [class*="p-grid-"] > [class*="_lg-4"],
  [class*="p-grid_"] > [class*="_lg-4"] {
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  [class~="p-grid"] > [class*="_lg-5"],
  [class*="p-grid-"] > [class*="_lg-5"],
  [class*="p-grid_"] > [class*="_lg-5"] {
    flex-basis: 41.66667%;
    max-width: 41.66667%; }
  [class~="p-grid"] > [class*="_lg-6"],
  [class*="p-grid-"] > [class*="_lg-6"],
  [class*="p-grid_"] > [class*="_lg-6"] {
    flex-basis: 50%;
    max-width: 50%; }
  [class~="p-grid"] > [class*="_lg-7"],
  [class*="p-grid-"] > [class*="_lg-7"],
  [class*="p-grid_"] > [class*="_lg-7"] {
    flex-basis: 58.33333%;
    max-width: 58.33333%; }
  [class~="p-grid"] > [class*="_lg-8"],
  [class*="p-grid-"] > [class*="_lg-8"],
  [class*="p-grid_"] > [class*="_lg-8"] {
    flex-basis: 66.66667%;
    max-width: 66.66667%; }
  [class~="p-grid"] > [class*="_lg-9"],
  [class*="p-grid-"] > [class*="_lg-9"],
  [class*="p-grid_"] > [class*="_lg-9"] {
    flex-basis: 75%;
    max-width: 75%; }
  [class~="p-grid"] > [class*="_lg-10"],
  [class*="p-grid-"] > [class*="_lg-10"],
  [class*="p-grid_"] > [class*="_lg-10"] {
    flex-basis: 83.33333%;
    max-width: 83.33333%; }
  [class~="p-grid"] > [class*="_lg-11"],
  [class*="p-grid-"] > [class*="_lg-11"],
  [class*="p-grid_"] > [class*="_lg-11"] {
    flex-basis: 91.66667%;
    max-width: 91.66667%; }
  [class~="p-grid"] > [class*="_lg-12"],
  [class*="p-grid-"] > [class*="_lg-12"],
  [class*="p-grid_"] > [class*="_lg-12"] {
    flex-basis: 100%;
    max-width: 100%; }
  [class~="p-grid"] > [data-push-left*="_lg-0"],
  [class*="p-grid-"] > [data-push-left*="_lg-0"],
  [class*="p-grid_"] > [data-push-left*="_lg-0"] {
    margin-left: 0; }
  [class~="p-grid"] > [data-push-left*="_lg-1"],
  [class*="p-grid-"] > [data-push-left*="_lg-1"],
  [class*="p-grid_"] > [data-push-left*="_lg-1"] {
    margin-left: 8.33333%; }
  [class~="p-grid"] > [data-push-left*="_lg-2"],
  [class*="p-grid-"] > [data-push-left*="_lg-2"],
  [class*="p-grid_"] > [data-push-left*="_lg-2"] {
    margin-left: 16.66667%; }
  [class~="p-grid"] > [data-push-left*="_lg-3"],
  [class*="p-grid-"] > [data-push-left*="_lg-3"],
  [class*="p-grid_"] > [data-push-left*="_lg-3"] {
    margin-left: 25%; }
  [class~="p-grid"] > [data-push-left*="_lg-4"],
  [class*="p-grid-"] > [data-push-left*="_lg-4"],
  [class*="p-grid_"] > [data-push-left*="_lg-4"] {
    margin-left: 33.33333%; }
  [class~="p-grid"] > [data-push-left*="_lg-5"],
  [class*="p-grid-"] > [data-push-left*="_lg-5"],
  [class*="p-grid_"] > [data-push-left*="_lg-5"] {
    margin-left: 41.66667%; }
  [class~="p-grid"] > [data-push-left*="_lg-6"],
  [class*="p-grid-"] > [data-push-left*="_lg-6"],
  [class*="p-grid_"] > [data-push-left*="_lg-6"] {
    margin-left: 50%; }
  [class~="p-grid"] > [data-push-left*="_lg-7"],
  [class*="p-grid-"] > [data-push-left*="_lg-7"],
  [class*="p-grid_"] > [data-push-left*="_lg-7"] {
    margin-left: 58.33333%; }
  [class~="p-grid"] > [data-push-left*="_lg-8"],
  [class*="p-grid-"] > [data-push-left*="_lg-8"],
  [class*="p-grid_"] > [data-push-left*="_lg-8"] {
    margin-left: 66.66667%; }
  [class~="p-grid"] > [data-push-left*="_lg-9"],
  [class*="p-grid-"] > [data-push-left*="_lg-9"],
  [class*="p-grid_"] > [data-push-left*="_lg-9"] {
    margin-left: 75%; }
  [class~="p-grid"] > [data-push-left*="_lg-10"],
  [class*="p-grid-"] > [data-push-left*="_lg-10"],
  [class*="p-grid_"] > [data-push-left*="_lg-10"] {
    margin-left: 83.33333%; }
  [class~="p-grid"] > [data-push-left*="_lg-11"],
  [class*="p-grid-"] > [data-push-left*="_lg-11"],
  [class*="p-grid_"] > [data-push-left*="_lg-11"] {
    margin-left: 91.66667%; }
  [class~="p-grid"] > [data-push-right*="_lg-0"],
  [class*="p-grid-"] > [data-push-right*="_lg-0"],
  [class*="p-grid_"] > [data-push-right*="_lg-0"] {
    margin-right: 0; }
  [class~="p-grid"] > [data-push-right*="_lg-1"],
  [class*="p-grid-"] > [data-push-right*="_lg-1"],
  [class*="p-grid_"] > [data-push-right*="_lg-1"] {
    margin-right: 8.33333%; }
  [class~="p-grid"] > [data-push-right*="_lg-2"],
  [class*="p-grid-"] > [data-push-right*="_lg-2"],
  [class*="p-grid_"] > [data-push-right*="_lg-2"] {
    margin-right: 16.66667%; }
  [class~="p-grid"] > [data-push-right*="_lg-3"],
  [class*="p-grid-"] > [data-push-right*="_lg-3"],
  [class*="p-grid_"] > [data-push-right*="_lg-3"] {
    margin-right: 25%; }
  [class~="p-grid"] > [data-push-right*="_lg-4"],
  [class*="p-grid-"] > [data-push-right*="_lg-4"],
  [class*="p-grid_"] > [data-push-right*="_lg-4"] {
    margin-right: 33.33333%; }
  [class~="p-grid"] > [data-push-right*="_lg-5"],
  [class*="p-grid-"] > [data-push-right*="_lg-5"],
  [class*="p-grid_"] > [data-push-right*="_lg-5"] {
    margin-right: 41.66667%; }
  [class~="p-grid"] > [data-push-right*="_lg-6"],
  [class*="p-grid-"] > [data-push-right*="_lg-6"],
  [class*="p-grid_"] > [data-push-right*="_lg-6"] {
    margin-right: 50%; }
  [class~="p-grid"] > [data-push-right*="_lg-7"],
  [class*="p-grid-"] > [data-push-right*="_lg-7"],
  [class*="p-grid_"] > [data-push-right*="_lg-7"] {
    margin-right: 58.33333%; }
  [class~="p-grid"] > [data-push-right*="_lg-8"],
  [class*="p-grid-"] > [data-push-right*="_lg-8"],
  [class*="p-grid_"] > [data-push-right*="_lg-8"] {
    margin-right: 66.66667%; }
  [class~="p-grid"] > [data-push-right*="_lg-9"],
  [class*="p-grid-"] > [data-push-right*="_lg-9"],
  [class*="p-grid_"] > [data-push-right*="_lg-9"] {
    margin-right: 75%; }
  [class~="p-grid"] > [data-push-right*="_lg-10"],
  [class*="p-grid-"] > [data-push-right*="_lg-10"],
  [class*="p-grid_"] > [data-push-right*="_lg-10"] {
    margin-right: 83.33333%; }
  [class~="p-grid"] > [data-push-right*="_lg-11"],
  [class*="p-grid-"] > [data-push-right*="_lg-11"],
  [class*="p-grid_"] > [data-push-right*="_lg-11"] {
    margin-right: 91.66667%; }
  [class~="p-grid"] [class*="_lg-first"],
  [class*="p-grid-"] [class*="_lg-first"],
  [class*="p-grid_"] [class*="_lg-first"] {
    order: -1; }
  [class~="p-grid"] [class*="_lg-last"],
  [class*="p-grid-"] [class*="_lg-last"],
  [class*="p-grid_"] [class*="_lg-last"] {
    order: 1; } }

@media (max-width: 1024px) {
  [class~="p-grid"] > [class*="_md-1"],
  [class*="p-grid-"] > [class*="_md-1"],
  [class*="p-grid_"] > [class*="_md-1"] {
    flex-basis: 8.33333%;
    max-width: 8.33333%; }
  [class~="p-grid"] > [class*="_md-2"],
  [class*="p-grid-"] > [class*="_md-2"],
  [class*="p-grid_"] > [class*="_md-2"] {
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  [class~="p-grid"] > [class*="_md-3"],
  [class*="p-grid-"] > [class*="_md-3"],
  [class*="p-grid_"] > [class*="_md-3"] {
    flex-basis: 25%;
    max-width: 25%; }
  [class~="p-grid"] > [class*="_md-4"],
  [class*="p-grid-"] > [class*="_md-4"],
  [class*="p-grid_"] > [class*="_md-4"] {
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  [class~="p-grid"] > [class*="_md-5"],
  [class*="p-grid-"] > [class*="_md-5"],
  [class*="p-grid_"] > [class*="_md-5"] {
    flex-basis: 41.66667%;
    max-width: 41.66667%; }
  [class~="p-grid"] > [class*="_md-6"],
  [class*="p-grid-"] > [class*="_md-6"],
  [class*="p-grid_"] > [class*="_md-6"] {
    flex-basis: 50%;
    max-width: 50%; }
  [class~="p-grid"] > [class*="_md-7"],
  [class*="p-grid-"] > [class*="_md-7"],
  [class*="p-grid_"] > [class*="_md-7"] {
    flex-basis: 58.33333%;
    max-width: 58.33333%; }
  [class~="p-grid"] > [class*="_md-8"],
  [class*="p-grid-"] > [class*="_md-8"],
  [class*="p-grid_"] > [class*="_md-8"] {
    flex-basis: 66.66667%;
    max-width: 66.66667%; }
  [class~="p-grid"] > [class*="_md-9"],
  [class*="p-grid-"] > [class*="_md-9"],
  [class*="p-grid_"] > [class*="_md-9"] {
    flex-basis: 75%;
    max-width: 75%; }
  [class~="p-grid"] > [class*="_md-10"],
  [class*="p-grid-"] > [class*="_md-10"],
  [class*="p-grid_"] > [class*="_md-10"] {
    flex-basis: 83.33333%;
    max-width: 83.33333%; }
  [class~="p-grid"] > [class*="_md-11"],
  [class*="p-grid-"] > [class*="_md-11"],
  [class*="p-grid_"] > [class*="_md-11"] {
    flex-basis: 91.66667%;
    max-width: 91.66667%; }
  [class~="p-grid"] > [class*="_md-12"],
  [class*="p-grid-"] > [class*="_md-12"],
  [class*="p-grid_"] > [class*="_md-12"] {
    flex-basis: 100%;
    max-width: 100%; }
  [class~="p-grid"] > [data-push-left*="_md-0"],
  [class*="p-grid-"] > [data-push-left*="_md-0"],
  [class*="p-grid_"] > [data-push-left*="_md-0"] {
    margin-left: 0; }
  [class~="p-grid"] > [data-push-left*="_md-1"],
  [class*="p-grid-"] > [data-push-left*="_md-1"],
  [class*="p-grid_"] > [data-push-left*="_md-1"] {
    margin-left: 8.33333%; }
  [class~="p-grid"] > [data-push-left*="_md-2"],
  [class*="p-grid-"] > [data-push-left*="_md-2"],
  [class*="p-grid_"] > [data-push-left*="_md-2"] {
    margin-left: 16.66667%; }
  [class~="p-grid"] > [data-push-left*="_md-3"],
  [class*="p-grid-"] > [data-push-left*="_md-3"],
  [class*="p-grid_"] > [data-push-left*="_md-3"] {
    margin-left: 25%; }
  [class~="p-grid"] > [data-push-left*="_md-4"],
  [class*="p-grid-"] > [data-push-left*="_md-4"],
  [class*="p-grid_"] > [data-push-left*="_md-4"] {
    margin-left: 33.33333%; }
  [class~="p-grid"] > [data-push-left*="_md-5"],
  [class*="p-grid-"] > [data-push-left*="_md-5"],
  [class*="p-grid_"] > [data-push-left*="_md-5"] {
    margin-left: 41.66667%; }
  [class~="p-grid"] > [data-push-left*="_md-6"],
  [class*="p-grid-"] > [data-push-left*="_md-6"],
  [class*="p-grid_"] > [data-push-left*="_md-6"] {
    margin-left: 50%; }
  [class~="p-grid"] > [data-push-left*="_md-7"],
  [class*="p-grid-"] > [data-push-left*="_md-7"],
  [class*="p-grid_"] > [data-push-left*="_md-7"] {
    margin-left: 58.33333%; }
  [class~="p-grid"] > [data-push-left*="_md-8"],
  [class*="p-grid-"] > [data-push-left*="_md-8"],
  [class*="p-grid_"] > [data-push-left*="_md-8"] {
    margin-left: 66.66667%; }
  [class~="p-grid"] > [data-push-left*="_md-9"],
  [class*="p-grid-"] > [data-push-left*="_md-9"],
  [class*="p-grid_"] > [data-push-left*="_md-9"] {
    margin-left: 75%; }
  [class~="p-grid"] > [data-push-left*="_md-10"],
  [class*="p-grid-"] > [data-push-left*="_md-10"],
  [class*="p-grid_"] > [data-push-left*="_md-10"] {
    margin-left: 83.33333%; }
  [class~="p-grid"] > [data-push-left*="_md-11"],
  [class*="p-grid-"] > [data-push-left*="_md-11"],
  [class*="p-grid_"] > [data-push-left*="_md-11"] {
    margin-left: 91.66667%; }
  [class~="p-grid"] > [data-push-right*="_md-0"],
  [class*="p-grid-"] > [data-push-right*="_md-0"],
  [class*="p-grid_"] > [data-push-right*="_md-0"] {
    margin-right: 0; }
  [class~="p-grid"] > [data-push-right*="_md-1"],
  [class*="p-grid-"] > [data-push-right*="_md-1"],
  [class*="p-grid_"] > [data-push-right*="_md-1"] {
    margin-right: 8.33333%; }
  [class~="p-grid"] > [data-push-right*="_md-2"],
  [class*="p-grid-"] > [data-push-right*="_md-2"],
  [class*="p-grid_"] > [data-push-right*="_md-2"] {
    margin-right: 16.66667%; }
  [class~="p-grid"] > [data-push-right*="_md-3"],
  [class*="p-grid-"] > [data-push-right*="_md-3"],
  [class*="p-grid_"] > [data-push-right*="_md-3"] {
    margin-right: 25%; }
  [class~="p-grid"] > [data-push-right*="_md-4"],
  [class*="p-grid-"] > [data-push-right*="_md-4"],
  [class*="p-grid_"] > [data-push-right*="_md-4"] {
    margin-right: 33.33333%; }
  [class~="p-grid"] > [data-push-right*="_md-5"],
  [class*="p-grid-"] > [data-push-right*="_md-5"],
  [class*="p-grid_"] > [data-push-right*="_md-5"] {
    margin-right: 41.66667%; }
  [class~="p-grid"] > [data-push-right*="_md-6"],
  [class*="p-grid-"] > [data-push-right*="_md-6"],
  [class*="p-grid_"] > [data-push-right*="_md-6"] {
    margin-right: 50%; }
  [class~="p-grid"] > [data-push-right*="_md-7"],
  [class*="p-grid-"] > [data-push-right*="_md-7"],
  [class*="p-grid_"] > [data-push-right*="_md-7"] {
    margin-right: 58.33333%; }
  [class~="p-grid"] > [data-push-right*="_md-8"],
  [class*="p-grid-"] > [data-push-right*="_md-8"],
  [class*="p-grid_"] > [data-push-right*="_md-8"] {
    margin-right: 66.66667%; }
  [class~="p-grid"] > [data-push-right*="_md-9"],
  [class*="p-grid-"] > [data-push-right*="_md-9"],
  [class*="p-grid_"] > [data-push-right*="_md-9"] {
    margin-right: 75%; }
  [class~="p-grid"] > [data-push-right*="_md-10"],
  [class*="p-grid-"] > [data-push-right*="_md-10"],
  [class*="p-grid_"] > [data-push-right*="_md-10"] {
    margin-right: 83.33333%; }
  [class~="p-grid"] > [data-push-right*="_md-11"],
  [class*="p-grid-"] > [data-push-right*="_md-11"],
  [class*="p-grid_"] > [data-push-right*="_md-11"] {
    margin-right: 91.66667%; }
  [class~="p-grid"] [class*="_md-first"],
  [class*="p-grid-"] [class*="_md-first"],
  [class*="p-grid_"] [class*="_md-first"] {
    order: -1; }
  [class~="p-grid"] [class*="_md-last"],
  [class*="p-grid-"] [class*="_md-last"],
  [class*="p-grid_"] [class*="_md-last"] {
    order: 1; } }

@media (max-width: 768px) {
  [class~="p-grid"] > [class*="_sm-1"],
  [class*="p-grid-"] > [class*="_sm-1"],
  [class*="p-grid_"] > [class*="_sm-1"] {
    flex-basis: 8.33333%;
    max-width: 8.33333%; }
  [class~="p-grid"] > [class*="_sm-2"],
  [class*="p-grid-"] > [class*="_sm-2"],
  [class*="p-grid_"] > [class*="_sm-2"] {
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  [class~="p-grid"] > [class*="_sm-3"],
  [class*="p-grid-"] > [class*="_sm-3"],
  [class*="p-grid_"] > [class*="_sm-3"] {
    flex-basis: 25%;
    max-width: 25%; }
  [class~="p-grid"] > [class*="_sm-4"],
  [class*="p-grid-"] > [class*="_sm-4"],
  [class*="p-grid_"] > [class*="_sm-4"] {
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  [class~="p-grid"] > [class*="_sm-5"],
  [class*="p-grid-"] > [class*="_sm-5"],
  [class*="p-grid_"] > [class*="_sm-5"] {
    flex-basis: 41.66667%;
    max-width: 41.66667%; }
  [class~="p-grid"] > [class*="_sm-6"],
  [class*="p-grid-"] > [class*="_sm-6"],
  [class*="p-grid_"] > [class*="_sm-6"] {
    flex-basis: 50%;
    max-width: 50%; }
  [class~="p-grid"] > [class*="_sm-7"],
  [class*="p-grid-"] > [class*="_sm-7"],
  [class*="p-grid_"] > [class*="_sm-7"] {
    flex-basis: 58.33333%;
    max-width: 58.33333%; }
  [class~="p-grid"] > [class*="_sm-8"],
  [class*="p-grid-"] > [class*="_sm-8"],
  [class*="p-grid_"] > [class*="_sm-8"] {
    flex-basis: 66.66667%;
    max-width: 66.66667%; }
  [class~="p-grid"] > [class*="_sm-9"],
  [class*="p-grid-"] > [class*="_sm-9"],
  [class*="p-grid_"] > [class*="_sm-9"] {
    flex-basis: 75%;
    max-width: 75%; }
  [class~="p-grid"] > [class*="_sm-10"],
  [class*="p-grid-"] > [class*="_sm-10"],
  [class*="p-grid_"] > [class*="_sm-10"] {
    flex-basis: 83.33333%;
    max-width: 83.33333%; }
  [class~="p-grid"] > [class*="_sm-11"],
  [class*="p-grid-"] > [class*="_sm-11"],
  [class*="p-grid_"] > [class*="_sm-11"] {
    flex-basis: 91.66667%;
    max-width: 91.66667%; }
  [class~="p-grid"] > [class*="_sm-12"],
  [class*="p-grid-"] > [class*="_sm-12"],
  [class*="p-grid_"] > [class*="_sm-12"] {
    flex-basis: 100%;
    max-width: 100%; }
  [class~="p-grid"] > [data-push-left*="_sm-0"],
  [class*="p-grid-"] > [data-push-left*="_sm-0"],
  [class*="p-grid_"] > [data-push-left*="_sm-0"] {
    margin-left: 0; }
  [class~="p-grid"] > [data-push-left*="_sm-1"],
  [class*="p-grid-"] > [data-push-left*="_sm-1"],
  [class*="p-grid_"] > [data-push-left*="_sm-1"] {
    margin-left: 8.33333%; }
  [class~="p-grid"] > [data-push-left*="_sm-2"],
  [class*="p-grid-"] > [data-push-left*="_sm-2"],
  [class*="p-grid_"] > [data-push-left*="_sm-2"] {
    margin-left: 16.66667%; }
  [class~="p-grid"] > [data-push-left*="_sm-3"],
  [class*="p-grid-"] > [data-push-left*="_sm-3"],
  [class*="p-grid_"] > [data-push-left*="_sm-3"] {
    margin-left: 25%; }
  [class~="p-grid"] > [data-push-left*="_sm-4"],
  [class*="p-grid-"] > [data-push-left*="_sm-4"],
  [class*="p-grid_"] > [data-push-left*="_sm-4"] {
    margin-left: 33.33333%; }
  [class~="p-grid"] > [data-push-left*="_sm-5"],
  [class*="p-grid-"] > [data-push-left*="_sm-5"],
  [class*="p-grid_"] > [data-push-left*="_sm-5"] {
    margin-left: 41.66667%; }
  [class~="p-grid"] > [data-push-left*="_sm-6"],
  [class*="p-grid-"] > [data-push-left*="_sm-6"],
  [class*="p-grid_"] > [data-push-left*="_sm-6"] {
    margin-left: 50%; }
  [class~="p-grid"] > [data-push-left*="_sm-7"],
  [class*="p-grid-"] > [data-push-left*="_sm-7"],
  [class*="p-grid_"] > [data-push-left*="_sm-7"] {
    margin-left: 58.33333%; }
  [class~="p-grid"] > [data-push-left*="_sm-8"],
  [class*="p-grid-"] > [data-push-left*="_sm-8"],
  [class*="p-grid_"] > [data-push-left*="_sm-8"] {
    margin-left: 66.66667%; }
  [class~="p-grid"] > [data-push-left*="_sm-9"],
  [class*="p-grid-"] > [data-push-left*="_sm-9"],
  [class*="p-grid_"] > [data-push-left*="_sm-9"] {
    margin-left: 75%; }
  [class~="p-grid"] > [data-push-left*="_sm-10"],
  [class*="p-grid-"] > [data-push-left*="_sm-10"],
  [class*="p-grid_"] > [data-push-left*="_sm-10"] {
    margin-left: 83.33333%; }
  [class~="p-grid"] > [data-push-left*="_sm-11"],
  [class*="p-grid-"] > [data-push-left*="_sm-11"],
  [class*="p-grid_"] > [data-push-left*="_sm-11"] {
    margin-left: 91.66667%; }
  [class~="p-grid"] > [data-push-right*="_sm-0"],
  [class*="p-grid-"] > [data-push-right*="_sm-0"],
  [class*="p-grid_"] > [data-push-right*="_sm-0"] {
    margin-right: 0; }
  [class~="p-grid"] > [data-push-right*="_sm-1"],
  [class*="p-grid-"] > [data-push-right*="_sm-1"],
  [class*="p-grid_"] > [data-push-right*="_sm-1"] {
    margin-right: 8.33333%; }
  [class~="p-grid"] > [data-push-right*="_sm-2"],
  [class*="p-grid-"] > [data-push-right*="_sm-2"],
  [class*="p-grid_"] > [data-push-right*="_sm-2"] {
    margin-right: 16.66667%; }
  [class~="p-grid"] > [data-push-right*="_sm-3"],
  [class*="p-grid-"] > [data-push-right*="_sm-3"],
  [class*="p-grid_"] > [data-push-right*="_sm-3"] {
    margin-right: 25%; }
  [class~="p-grid"] > [data-push-right*="_sm-4"],
  [class*="p-grid-"] > [data-push-right*="_sm-4"],
  [class*="p-grid_"] > [data-push-right*="_sm-4"] {
    margin-right: 33.33333%; }
  [class~="p-grid"] > [data-push-right*="_sm-5"],
  [class*="p-grid-"] > [data-push-right*="_sm-5"],
  [class*="p-grid_"] > [data-push-right*="_sm-5"] {
    margin-right: 41.66667%; }
  [class~="p-grid"] > [data-push-right*="_sm-6"],
  [class*="p-grid-"] > [data-push-right*="_sm-6"],
  [class*="p-grid_"] > [data-push-right*="_sm-6"] {
    margin-right: 50%; }
  [class~="p-grid"] > [data-push-right*="_sm-7"],
  [class*="p-grid-"] > [data-push-right*="_sm-7"],
  [class*="p-grid_"] > [data-push-right*="_sm-7"] {
    margin-right: 58.33333%; }
  [class~="p-grid"] > [data-push-right*="_sm-8"],
  [class*="p-grid-"] > [data-push-right*="_sm-8"],
  [class*="p-grid_"] > [data-push-right*="_sm-8"] {
    margin-right: 66.66667%; }
  [class~="p-grid"] > [data-push-right*="_sm-9"],
  [class*="p-grid-"] > [data-push-right*="_sm-9"],
  [class*="p-grid_"] > [data-push-right*="_sm-9"] {
    margin-right: 75%; }
  [class~="p-grid"] > [data-push-right*="_sm-10"],
  [class*="p-grid-"] > [data-push-right*="_sm-10"],
  [class*="p-grid_"] > [data-push-right*="_sm-10"] {
    margin-right: 83.33333%; }
  [class~="p-grid"] > [data-push-right*="_sm-11"],
  [class*="p-grid-"] > [data-push-right*="_sm-11"],
  [class*="p-grid_"] > [data-push-right*="_sm-11"] {
    margin-right: 91.66667%; }
  [class~="p-grid"] [class*="_sm-first"],
  [class*="p-grid-"] [class*="_sm-first"],
  [class*="p-grid_"] [class*="_sm-first"] {
    order: -1; }
  [class~="p-grid"] [class*="_sm-last"],
  [class*="p-grid-"] [class*="_sm-last"],
  [class*="p-grid_"] [class*="_sm-last"] {
    order: 1; } }

@media (max-width: 480px) {
  [class~="p-grid"] > [class*="_xs-1"],
  [class*="p-grid-"] > [class*="_xs-1"],
  [class*="p-grid_"] > [class*="_xs-1"] {
    flex-basis: 8.33333%;
    max-width: 8.33333%; }
  [class~="p-grid"] > [class*="_xs-2"],
  [class*="p-grid-"] > [class*="_xs-2"],
  [class*="p-grid_"] > [class*="_xs-2"] {
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  [class~="p-grid"] > [class*="_xs-3"],
  [class*="p-grid-"] > [class*="_xs-3"],
  [class*="p-grid_"] > [class*="_xs-3"] {
    flex-basis: 25%;
    max-width: 25%; }
  [class~="p-grid"] > [class*="_xs-4"],
  [class*="p-grid-"] > [class*="_xs-4"],
  [class*="p-grid_"] > [class*="_xs-4"] {
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  [class~="p-grid"] > [class*="_xs-5"],
  [class*="p-grid-"] > [class*="_xs-5"],
  [class*="p-grid_"] > [class*="_xs-5"] {
    flex-basis: 41.66667%;
    max-width: 41.66667%; }
  [class~="p-grid"] > [class*="_xs-6"],
  [class*="p-grid-"] > [class*="_xs-6"],
  [class*="p-grid_"] > [class*="_xs-6"] {
    flex-basis: 50%;
    max-width: 50%; }
  [class~="p-grid"] > [class*="_xs-7"],
  [class*="p-grid-"] > [class*="_xs-7"],
  [class*="p-grid_"] > [class*="_xs-7"] {
    flex-basis: 58.33333%;
    max-width: 58.33333%; }
  [class~="p-grid"] > [class*="_xs-8"],
  [class*="p-grid-"] > [class*="_xs-8"],
  [class*="p-grid_"] > [class*="_xs-8"] {
    flex-basis: 66.66667%;
    max-width: 66.66667%; }
  [class~="p-grid"] > [class*="_xs-9"],
  [class*="p-grid-"] > [class*="_xs-9"],
  [class*="p-grid_"] > [class*="_xs-9"] {
    flex-basis: 75%;
    max-width: 75%; }
  [class~="p-grid"] > [class*="_xs-10"],
  [class*="p-grid-"] > [class*="_xs-10"],
  [class*="p-grid_"] > [class*="_xs-10"] {
    flex-basis: 83.33333%;
    max-width: 83.33333%; }
  [class~="p-grid"] > [class*="_xs-11"],
  [class*="p-grid-"] > [class*="_xs-11"],
  [class*="p-grid_"] > [class*="_xs-11"] {
    flex-basis: 91.66667%;
    max-width: 91.66667%; }
  [class~="p-grid"] > [class*="_xs-12"],
  [class*="p-grid-"] > [class*="_xs-12"],
  [class*="p-grid_"] > [class*="_xs-12"] {
    flex-basis: 100%;
    max-width: 100%; }
  [class~="p-grid"] > [data-push-left*="_xs-0"],
  [class*="p-grid-"] > [data-push-left*="_xs-0"],
  [class*="p-grid_"] > [data-push-left*="_xs-0"] {
    margin-left: 0; }
  [class~="p-grid"] > [data-push-left*="_xs-1"],
  [class*="p-grid-"] > [data-push-left*="_xs-1"],
  [class*="p-grid_"] > [data-push-left*="_xs-1"] {
    margin-left: 8.33333%; }
  [class~="p-grid"] > [data-push-left*="_xs-2"],
  [class*="p-grid-"] > [data-push-left*="_xs-2"],
  [class*="p-grid_"] > [data-push-left*="_xs-2"] {
    margin-left: 16.66667%; }
  [class~="p-grid"] > [data-push-left*="_xs-3"],
  [class*="p-grid-"] > [data-push-left*="_xs-3"],
  [class*="p-grid_"] > [data-push-left*="_xs-3"] {
    margin-left: 25%; }
  [class~="p-grid"] > [data-push-left*="_xs-4"],
  [class*="p-grid-"] > [data-push-left*="_xs-4"],
  [class*="p-grid_"] > [data-push-left*="_xs-4"] {
    margin-left: 33.33333%; }
  [class~="p-grid"] > [data-push-left*="_xs-5"],
  [class*="p-grid-"] > [data-push-left*="_xs-5"],
  [class*="p-grid_"] > [data-push-left*="_xs-5"] {
    margin-left: 41.66667%; }
  [class~="p-grid"] > [data-push-left*="_xs-6"],
  [class*="p-grid-"] > [data-push-left*="_xs-6"],
  [class*="p-grid_"] > [data-push-left*="_xs-6"] {
    margin-left: 50%; }
  [class~="p-grid"] > [data-push-left*="_xs-7"],
  [class*="p-grid-"] > [data-push-left*="_xs-7"],
  [class*="p-grid_"] > [data-push-left*="_xs-7"] {
    margin-left: 58.33333%; }
  [class~="p-grid"] > [data-push-left*="_xs-8"],
  [class*="p-grid-"] > [data-push-left*="_xs-8"],
  [class*="p-grid_"] > [data-push-left*="_xs-8"] {
    margin-left: 66.66667%; }
  [class~="p-grid"] > [data-push-left*="_xs-9"],
  [class*="p-grid-"] > [data-push-left*="_xs-9"],
  [class*="p-grid_"] > [data-push-left*="_xs-9"] {
    margin-left: 75%; }
  [class~="p-grid"] > [data-push-left*="_xs-10"],
  [class*="p-grid-"] > [data-push-left*="_xs-10"],
  [class*="p-grid_"] > [data-push-left*="_xs-10"] {
    margin-left: 83.33333%; }
  [class~="p-grid"] > [data-push-left*="_xs-11"],
  [class*="p-grid-"] > [data-push-left*="_xs-11"],
  [class*="p-grid_"] > [data-push-left*="_xs-11"] {
    margin-left: 91.66667%; }
  [class~="p-grid"] > [data-push-right*="_xs-0"],
  [class*="p-grid-"] > [data-push-right*="_xs-0"],
  [class*="p-grid_"] > [data-push-right*="_xs-0"] {
    margin-right: 0; }
  [class~="p-grid"] > [data-push-right*="_xs-1"],
  [class*="p-grid-"] > [data-push-right*="_xs-1"],
  [class*="p-grid_"] > [data-push-right*="_xs-1"] {
    margin-right: 8.33333%; }
  [class~="p-grid"] > [data-push-right*="_xs-2"],
  [class*="p-grid-"] > [data-push-right*="_xs-2"],
  [class*="p-grid_"] > [data-push-right*="_xs-2"] {
    margin-right: 16.66667%; }
  [class~="p-grid"] > [data-push-right*="_xs-3"],
  [class*="p-grid-"] > [data-push-right*="_xs-3"],
  [class*="p-grid_"] > [data-push-right*="_xs-3"] {
    margin-right: 25%; }
  [class~="p-grid"] > [data-push-right*="_xs-4"],
  [class*="p-grid-"] > [data-push-right*="_xs-4"],
  [class*="p-grid_"] > [data-push-right*="_xs-4"] {
    margin-right: 33.33333%; }
  [class~="p-grid"] > [data-push-right*="_xs-5"],
  [class*="p-grid-"] > [data-push-right*="_xs-5"],
  [class*="p-grid_"] > [data-push-right*="_xs-5"] {
    margin-right: 41.66667%; }
  [class~="p-grid"] > [data-push-right*="_xs-6"],
  [class*="p-grid-"] > [data-push-right*="_xs-6"],
  [class*="p-grid_"] > [data-push-right*="_xs-6"] {
    margin-right: 50%; }
  [class~="p-grid"] > [data-push-right*="_xs-7"],
  [class*="p-grid-"] > [data-push-right*="_xs-7"],
  [class*="p-grid_"] > [data-push-right*="_xs-7"] {
    margin-right: 58.33333%; }
  [class~="p-grid"] > [data-push-right*="_xs-8"],
  [class*="p-grid-"] > [data-push-right*="_xs-8"],
  [class*="p-grid_"] > [data-push-right*="_xs-8"] {
    margin-right: 66.66667%; }
  [class~="p-grid"] > [data-push-right*="_xs-9"],
  [class*="p-grid-"] > [data-push-right*="_xs-9"],
  [class*="p-grid_"] > [data-push-right*="_xs-9"] {
    margin-right: 75%; }
  [class~="p-grid"] > [data-push-right*="_xs-10"],
  [class*="p-grid-"] > [data-push-right*="_xs-10"],
  [class*="p-grid_"] > [data-push-right*="_xs-10"] {
    margin-right: 83.33333%; }
  [class~="p-grid"] > [data-push-right*="_xs-11"],
  [class*="p-grid-"] > [data-push-right*="_xs-11"],
  [class*="p-grid_"] > [data-push-right*="_xs-11"] {
    margin-right: 91.66667%; }
  [class~="p-grid"] [class*="_xs-first"],
  [class*="p-grid-"] [class*="_xs-first"],
  [class*="p-grid_"] [class*="_xs-first"] {
    order: -1; }
  [class~="p-grid"] [class*="_xs-last"],
  [class*="p-grid-"] [class*="_xs-last"],
  [class*="p-grid_"] [class*="_xs-last"] {
    order: 1; } }

/************************
    HIDING COLS
*************************/
@media (max-width: 1200px) {
  [class*="lg-hidden"] {
    display: none; } }

@media (max-width: 1024px) {
  [class*="md-hidden"] {
    display: none; } }

@media (max-width: 768px) {
  [class*="sm-hidden"] {
    display: none; } }

@media (max-width: 480px) {
  [class*="xs-hidden"] {
    display: none; } }
