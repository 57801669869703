body{
    #ajaxcart {
        &, &-loading {
            z-index: 9999;
        }
        .inner{
            max-width: 550px;
        }
        .success {
            background: #229633;
            margin-top: -1px;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 1rem;
            font-size: 1.3rem;
            margin-bottom: 1rem;
            .icon {
                display: none;
            }
        }
        .button-close-top {
            right: 0.25rem;
            top: 0.25rem;
            color: #fff;
        }
        .product {
            text-align: left !important;
            &-name {
                padding: 0 0 1rem 0;
                font-size: 18px;
                font-weight: 500;
            }
            .price-box {
                padding-bottom: 1rem;
                display: flex;
                .price-including-tax{
                    margin-right: 0.5rem;
                }
            }
            &-image{
                float: left;
                max-width: 150px;
                min-width: 150px;
                margin: 0 1rem;
            }
        }
        .actions {
            display: flex;
            justify-content: center;
            padding: 1rem 0;
            background: #1f1f1f05;
            border-top: 1px dashed #eaeaea;
            width: 100%;
            a.button {
                width: 40%;
                justify-content: center;
                text-align: center;
            }
            #button-checkout {
                display: none;
            }
            #button-close{
                background: #dedede;
                box-shadow: none;
                border: none;
                border-bottom: 2px solid #c5c5c5;
                font-weight: 500;
                color: #575757;
                display: flex;
                align-items: center;
                line-height: initial;
                &:hover,
                &:focus {
                    border-bottom: 2px solid darken(#c5c5c5, 5%);
                    background: darken(#dedede, 5%);
                }
            }
            #button-cart {
                background-color: #00904a;
                border-color: #00904a;
                border-bottom: 2px solid #00904a;
                box-shadow: none;
                border-radius: 4px;
                line-height: initial;
                color: #fff;
                &:hover,
                &:focus {
                    border-bottom: 2px solid darken(#00904a, 5%);
                    background: darken(#00904a, 5%);
                }
            }
        }
        .cartamount {
            width: 90%;
            display: flex;
            flex-direction: column;
            margin: 2rem auto;
            padding: 0 0.5rem;
            justify-content: center;
            align-items: center;

            progress[value] {
                appearance: none;
                border: none;
                height: 12px;
                color: royalblue;
                position: relative;
                margin: 0.5rem 0 0;
                width: 100%;
                border-radius: 6px;
            }

            progress[value]::-webkit-progress-bar {
                background-color: #ebebeb;
                border-radius: 6px;
            }

            .amount-percent::-webkit-progress-value {
                background-image: -webkit-linear-gradient(left, #40c353, #40c353);
                border-radius: 6px;
            }

            .remaining-amount .value {
                font-weight: 600;
            }
        }
        #ajax-slots-wrapper {
            display: flex;
            width: 80%;
            margin: auto;
            .block.related {
                width: 100%;
                padding: 0 1rem;
                margin: 0 auto;
            }
        }
    }
    @include max-screen($screen__m){
        #ajaxcart {
            .actions {
                flex-direction: row;
                a.button {
                    width: fit-content;
                    margin: 0 0.25rem;
                }
            }
            .cartamount {
                margin: 0rem auto 1rem auto;
                padding-top: 0.75rem;
            }
        }
    }
}
