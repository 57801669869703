/*
*
* Common Product View Extensions
*
*/

.product-info-main {

    ._keyfocus *:focus, input:not([disabled]):focus,
    textarea:not([disabled]):focus, select:not([disabled]):focus {
        box-shadow: none;
    }

    select {
        height: 40px;
    }

    .product-reviews-summary {
        padding: 1rem 0;
        &.empty{
            display: none;
        }
    }

    .product-info-price{
        padding: 1rem 0;
        .special-price{
            font-weight: 500;
            margin-right: 1rem;
            color: #DC2B2B;
        }
    }

    .bundle-actions {
        margin-top: 1.5rem;
    }
    .page-title-wrapper{
        .page-title{
            font-size: 26px;
            font-weight: 600;
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    .product-info-stock-sku{
        display: flex;
        align-items: center;
        flex-direction: row-reverse;

        /* remove stock, request from Cecilie */
        .stock{
            display: none;
        }
        .stock.available{
            span {
                color: #49ba83;
                font-weight: 500;
                font-size: 12px;
                display: flex;
                align-items: center;
                text-transform: none;
                &:before {
                    content: $icon-checkmark;
                    font-family: "Material Icons";
                    vertical-align: top;
                    margin-right: 0.25rem;
                    font-size: 16px;
                }
            }
        }

        .banda_manufacturer {
            display: flex;
            margin-left: auto;
            .label {
                margin-right: 3px;
            }
        }
    }
    product.attribute.sku{
        float: right;
    }
    .prices-tier {
        display: none;
    }
}
.product-info-main .price-box {  
    display: flex;
}
span.old-price.sly-old-price span.price:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background: #434343;
    left: 0;
    top: 54%;
}

span.old-price.sly-old-price span.price {
    position: relative;
}
span.old-price.sly-old-price {
    display: block !important;
    margin-top:0;
}

.old-price, .old.price {
   text-decoration: none;
}



.product-add-form button.sold-out-button{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 37px;
    background-color: $banda-showmore-button__background-color;
    font-size: 14px;
    color: $banda-headline__color;
    text-align: center;
    width: 100%;
    margin: 1rem auto 1rem auto;
    border-radius: 3px;
    //box-shadow: 0px 3px 0px 0px darken($banda-showmore-button__background-color, 18%);
    box-shadow: $banda__box-shadow;
}

.product-info-main .box-tocart,
.product-options-bottom .box-tocart {
    width: 100%;
    .input-text.qty {
        border-radius: $polarcore-product-input-text-qty__border-radius;
    }

    .form{
        position: relative;
    }
    .control {
        display: flex;
        padding-right: 1rem;
        width: 100% !important;
        float: none !important;
        button, input {
            background: white;
            font-size: 19px !important;
            border-radius: 0;
            border: 1px solid $banda-border__color;
            border-color: $banda-border__color !important;
            height: 48px !important;
            width: 48px !important;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        input {
            border-left: 0 !important;
            border-right: 0 !important;
        }
    }

    .input-text.qty {
        border-radius: $polarcore-product-input-text-qty__border-radius;
        font-size: 12px;
    }

    .fieldset,
    .actions {
        display: flex;
        width: 100%;
        align-items: flex-end;
    }

    .product-info-price {
        margin-bottom: 2rem;
    }

    .actions .action.primary.tocart {
        width: 100%;
        height: 48px;
    }


    .field.qty {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: auto;
		position: relative;
        margin: 0;
        .label {
            display: none;
        }

    }

}

.product-social-links {

    .action.towishlist {

        span {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        span:before {
            @include material-icon($icon-wishlist);
            font-size: 1em !important;
            transform: scale(1.5);
            padding-right: 0.5rem;
        }

    }

}

.actions .action.primary.tocart {

    span {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
    }

   span:before {
        @include material-icon($icon-cart);
        font-size: 1em !important;
        transform: scale(1.75);
        padding-right: 0.5rem;
        /* should be hidden some other way probably */
        display: none !important;
    }

}




.product.info.detailed {

    .data.item.title {
        margin: 0;
        margin-top: 1rem;
        border: 1px solid $banda-border__color;

        .switch {
            position: relative;
            background: $product-tab__background-color;
            color: $banda-font__color;
            text-transform: uppercase;
            font-size: 15px;
            font-weight: 600;
        }

        .switch:after {
            @include material-icon($icon-add);
            position: absolute;
            right: 1rem;
            top: 1rem;
            font-size: 32px !important;
            line-height: 0.6 !important;
            bottom: auto;
            left: auto;
            z-index: 10;
        }

        &.active {
            border-bottom: 0;
            .switch:after {
                @include material-icon($icon-minus);
            }
        }

    }

    .data.item.content {
        border: 1px solid $banda-border__color;
        border-top: 0;
        #show-more-description{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 37px;
            background-color: $banda-showmore-button__background-color;
            font-size: 14px;
            color: $banda-headline__color;
            text-align: center;
            width: 60%;
            margin: 1rem auto 1rem auto;
            border-radius: 3px;
            //box-shadow: 0px 3px 0px 0px darken($banda-showmore-button__background-color, 18%);
            box-shadow: $banda__box-shadow;
        }
        .inner {
            &.show-more {
                max-height: 80px;
                overflow: hidden;
                position: relative;
                &:before {
                    content: '';
                    position: absolute;
                    bottom: 0px;
                    left: 0;
                    right: 0;
                    margin: 0;
                    height: 50px;
                    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), white);
                }
            }
        }
    }

    .review-add,
    #product-review-container {
        padding: 1rem;
        width: 100%;
    }

}

/* picture gallery background request from Cecilie */
.mz-expand{
    .mz-expand-stage{
        background: #000;
    }
}

.review-container{
    .review-add{
        .review-form-actions{
            button.submit{
                border: 1px solid $banda-green;
                background: #fff;
                color: $banda-green;
                transition: all 200ms ease;
                box-shadow: none;
                &:hover{
                    background: $banda-green;
                    color: #fff;
                    border: 1px solid $banda-green;
                }
            }
        }
    }
}

/*
*
* Mobile only
*
*/

@include max-screen($screen__m) {
    .product-info-main{
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .product.info.detailed {
        #reviews {
            flex-direction: column;
        }
    }

    .actions .action.primary.tocart {
        span {
            font-size: 1.5rem;
        }
    }
}
