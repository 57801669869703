
.widget-product-grid{
    padding-left: 0;
}
//    Desktop
@include min-screen($screen__m){
    .block.widget .products-grid .product-item {
        width: 33%;
        padding: 1rem;
    }
}
@include min-screen($screen__l){
    .block.widget .products-grid .product-item{
        width: 24.1%;
        padding: 1rem;
    }
}
@include min-screen($screen__m) {
    .sidebar .block.widget .pager {
        .item:not(.pages-item-next):not(.pages-item-previous) {
            display: none;
        }
    }
}
.block-static-block{
    .block-products-list{
        .products-grid{
            .product-item-info img{
                height:100%
            }
        }
    }
}
