.checkout-cart-index  {

    .cart {
        &.table-wrapper {

            .col {
                &.price, &.qty, &.subtotal {
                    text-align: center;
                }
            }
            .item {
                .col {
                    &.item {
                        padding: 20px 8px $indent__s;
                    }
                }

                &-actions {
                    td {
                        text-align: right;
                    }
                }
            }

            .product {
                &-item-photo {
                    display: table-cell;
                    max-width: 100%;
                    padding-right: $indent__base;
                    position: static;
                    vertical-align: top;
                    width: 1%;
                }

				&-item-name {
					display: block;
					margin-top: 0;
					font-size: 1.2rem;
					font-weight: 600;
				}

                &-item-details {
                    display: table-cell;
                    vertical-align: top;
                    white-space: normal;
                    width: 99%;
                }
            }
            .actions-toolbar{
                padding-top: 0;
                padding-right: 0;
            }

            .item-actions {
                .actions-toolbar {
                    .action-towishlist,
					.action-edit {
						display: none;
					}
                    .action-delete {
                        margin: 0;
						border-radius: 3px;
						background: #ececec;
						border-bottom: 2px solid #cccccc;
						color: #585858;
						float: right;
                    }
                }
            }
        }
    }

    .cart-summary {
        .summary.title {
            background: transparent;
            padding-left: 0;
            margin-top: 0;
			font-weight: 600;
        }
        #block-shipping {
            display: none;
        }
        #cart-totals{
            padding-top: 0;
            .totals{
                th,
                td{
                    padding: 0.75rem 0;
                }
            }
        }
    }
    .cart-discount {
        display: none;
    }
}
.opc-block-summary .table-totals .totals-tax-summary,
.cart-totals .totals-tax-summary {
    .amount {
        .price {
            padding-right: 0;
            &:after {
                font-size: 1em;
                position: absolute;
                left: - 2.5rem;
                top: 0.2rem;
            }
        }
    }
}
