//
//  Product -- Full Width
//  _____________________________________________

.product-full-width-section {
    padding: 0.5em 0;

    .block.review-add {
        margin-top: 2.7rem;
    }
}

.page-layout-product-full-width {
    .block.related {
        margin-top: 2.7rem;
    }
}

.page-main-details .product-section-title {
    border-bottom: 1px solid $color-gray-middle2;
    margin-bottom: 15px;
    padding-bottom: 12px;
}

.additional-attributes-wrapper .additional-attributes {
    border: none;
    width: auto;

    > tbody > tr {
        > th {
            border: none;
            padding: 5.5px 30px 10px 0;
        }
        > td {
            border: none;
            padding: 5.5px 5px 10px;
        }
    }
}

//
//  Page -- Full Width
//  _____________________________________________

.cms-index-index.page-layout-cms-full-width .nav-sections {
    margin-bottom: 0;
}
