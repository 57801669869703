//  Layout
$layout-column__sidebar-width: 2.5;
$_breadcrumbs-container-margin: 0;

// General
$font-family__base: "Roboto", Helvetica, sans-serif;

// Colors
$color__primary: $banda-green;
$link__color: $color__primary;
$link__hover__color: darken($link__color,5%);
$polarcore-primary__color: $color__primary;
$primary__color: $color__primary;

//  Navigation
$navigation__level0-item-line-height: 1;
$navigation__level0-item-font-size: 1em;

$product-name-link__color: #2C313E !default;
$product-name-link__color__active: #2C313E !default;
$product-name-link__color__hover: #2C313E !default;
$product-name-link__color__visited: #2C313E !default;

$_button-color: #606675;
$border-color__base: #E0E6EE;
$button__background: #e0e6ee;
$button__border: 0;
$button__border-radius: 0;
// $button__hover__color: $button__color;
$button__hover__background: darken($button__background, 5%);
$button__active__background: darken($button__background, 5%);

$rating-icon__color: #F0F0F0;
$rating-icon__active__color: #FFF305;

/**
*
* Layout
*
*/

$layout-indent__width: 1rem;
$layout__max-width: 1440px;

/**
*
* PolarCore Tabs mode
*
*/

$polarcore-tabs__mode: 'default';
