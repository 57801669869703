// Login page
body.customer-account-login {
    .login-container {
        .fieldset:after {
            display: none;
        }
    }
    .block.block-customer-login {
        margin-bottom: 1rem;
    }
    .actions-toolbar {
        .primary {
            margin-bottom: 1rem;
        }
    }
}
body.account {
    padding: 0;
    // general
    .page-header .header-menu .widget.block.block-static-block .menu-points ul li.first {
        background: #fafafa;
        a {
            font-weight: initial;
        }
    }
    .block-collapsible-nav {
        .item.current a {
            border-color: #f96e33;
        }
    }
    // customer index

    // order page
    &.sales-order-view {
        .column.main {
            .page-title {
                margin-top: 0;
                margin-bottom: 1.5rem;
                &-wrapper {
                    .order-date {
                        margin-bottom: 0;
                    }
                }
            }
            .table-wrapper {
                margin: 0;
                border: none;
            }
            .order {
                &-status {
                    position: absolute;
                    right: 0;
                }
                &-links {
                    strong {
                        font-size: 1.4rem;
                    }
                }
                &-title{
                    strong{
                        display: none;
                    }
                }
                &-details-items {
                    border-bottom: none;
                    margin: 0;
                    padding: 0;
                    .actions-toolbar {
                        display: none;
                    }
                    strong {
                        font-size: 1.2rem;
                        font-weight: 600;
                    }
                    // Pharmasuite Online
                    div[ng-app="pharmaSuite"] {
                        #ps-pharma-suite {
                            background: transparent;
                            .ps-cart{
                                padding: 1rem 0rem;
                                #desktopCart{
                                    .product-item{
                                        .qty-info,
                                        .discount-cell,
                                        .price-info{
                                            text-align: center;
                                        }
                                    }
                                }
                            }
                            .ps-article-unauthorized {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                margin-bottom: 0.5rem;
                                width: 100%;
                                padding-bottom: 4rem;
                                border-bottom: 1px solid #e0e6ee;
                                .ps-login-container {
                                    .btn {
                                        background: #f96e33;
                                        border: #b62d00;
                                        color: #ffffff;
                                        cursor: pointer;
                                        margin: 0;
                                        padding: 7px 15px;
                                        width: auto;
                                        outline: none;
                                        font-size: 14px;
                                        line-height: 16px;
                                        box-sizing: border-box;
                                        vertical-align: middle;
                                        padding: 1rem;
                                        text-transform: uppercase;
                                        font-weight: 400;
                                        border-radius: 3px;
                                        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.35);
                                    }
                                }
                                span.ng-scope {
                                    font-weight: 900;
                                }
                                .clearfix {
                                    display: none;
                                }
                            }
                        }
                        .ps-article-price-label {
                            display: none;
                        }
                    }
                    #my-orders-table {
                        tbody {
                            tr{
                                .col{
                                    padding: 1.5rem 0.5rem;
                                }
                            }
                            &.prescription{
                                .col{
                                    .item-options{
                                        display: none;
                                    }
                                    &.sku{
                                        visibility: hidden;
                                    }
                                }
                            }
                            tr[id^="order-item-row"] {
                                td.col {
                                    &.price,
                                    &.qty,
                                    &.subtotal {
                                        span {
                                            font-size: 1rem;
                                            font-weight: 600;
                                        }
                                    }
                                }
                            }
                        }
                        tfoot {
                            tr {
                                &.subtotal {
                                    border-top: 1px solid #e2e7ee;
                                    margin-top: 0.5rem;
                                    padding-top: 0.5rem;
                                }
                                th, td {
                                    font-size: 1.2rem;
                                }
                                th {
                                    padding: 5px 10px 0 0;
                                }
                                td {
                                    padding: 5px 0 0 10px;
                                }
                                &:first-of-type {
                                    th, td {
                                        padding-top: 1reM;
                                    }
                                }
                                &:last-of-type {
                                    th, td {
                                        padding-bottom: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            // order details
            .block-order-details-view {
                .block-title {
                    border-color: #e4e4e4;
                    strong {
                        font-size: 19px;
                        font-weight: 700;
                    }
                }
                .block-content {
                    display: flex;
                    flex-wrap: wrap;
                    .box {
                        width: 25%;
                    }
                    address {
                        line-height: 1.5;
                    }
                    .box-order {
                        &-shipping {
                            &-method {
                                order: 3;
                            }
                            &-address {
                                order: 1;
                            }
                        }
                        &-billing {
                            &-method {
                                word-break: break-all;
                                order: 4;
                                img {
                                    max-width: 100px;
                                }
                                th, td {
                                    padding: 5px 0 0 0;
                                }
                            }
                            &-address {
                                order: 2;
                            }
                        }
                    }
                }
            }
        }
    }
}
@include max-screen($screen__m) {
    body.account {
        // general
        .page.messages {
            margin: 0;
        }
        .block-collapsible-nav .title {
            border-top: 0;
        }
        // order page
        &.sales-order-view {
            .column.main {
                .page-title-wrapper {
                    margin-top: 4rem;
                    .page-title {
                        margin-bottom: 0.5rem;
                    }
                    .order-date {
                        margin: 0.5rem 0;
                    }
                    .order-status {
                        position: initial;
                    }
                }
                .order {
                    &-details-items {
                        // Pharmasuite Online
                        div[ng-app="pharmaSuite"] {
                            #ps-pharma-suite {
                                background: transparent;
                                .ps-article-unauthorized {
                                    justify-content: space-between;
                                    flex-direction: column;
                                    padding-bottom: 2rem;
                                    span.ng-scope {
                                        font-weight: 900;
                                        width: 94%;
                                        display: block;
                                        text-align: center;
                                        margin: 0.5rem 0;
                                    }
                                }
                            }
                        }
                        #my-orders-table {
                            tbody {
                                tr[id^="order-item-row"] {
                                    td.col {
                                        &.price,
                                        &.qty,
                                        &.subtotal {
                                            display: flex;
                                            justify-content: space-between;
                                            padding: 5px 0 0 0;
                                            span {
                                                font-size: 1rem;
                                                font-weight: 600;
                                            }
                                        }
                                        &.subtotal {
                                            padding-bottom: 1rem;
                                        }
                                    }
                                }
                            }
                            tfoot {
                                tr {
                                    &.subtotal {
                                        border-top: 1px solid #e2e7ee;
                                        margin-top: 0.5rem;
                                        padding-top: 0.5rem;
                                    }
                                    th, td {
                                        font-size: 1.2rem;
                                    }
                                    th {
                                        padding: 5px 10px 0 0;
                                    }
                                    td {
                                        padding: 5px 0 0 10px;
                                    }
                                }
                            }
                        }
                    }
                }
                // order details
                .block-order-details-view {
                    .block-content {
                        display: flex;
                        flex-wrap: wrap;
                        .box {
                            width: 50%
                        }
                        .box-order {
                            &-shipping {
                                &-method {
                                    order: 3;
                                }
                                &-address {
                                    order: 1;
                                }
                            }
                            &-billing {
                                &-method {
                                    order: 4;
                                    th, td {
                                        padding: 5px 0 0 0;
                                    }
                                }
                                &-address {
                                    order: 2;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
