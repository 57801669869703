//
//  Common
//  _____________________________________________

* {
    font-family: "Raleway";
}

body {
    background-color: $page__background-color;
}
.nav-sections-item-content .navigation > ul > li:first-child > a span{
    font-size: 0;
}
.page-wrapper {
    .ie9 & {
        background-color: $page__background-color;
        min-height: 0;
    }
}

[role="slider"][class*="lower"] [class*="tooltip"]{
    left: 0;
}
[role="slider"][class*="upper"] [class*="tooltip"] {
    left: auto;
    right: 0;
}

//
//  Header
//  ---------------------------------------------

.page-header {
    background-color: $header__background-color;
    border-bottom: 1px solid $border-color__base;

    .panel.wrapper {
        background-color: $header-panel__background-color;
        color: $header-panel__text-color;
    }
}

.header.panel {
    > .header.links {
        display: none;
        @include lib-list-inline();
        float: right;
        font-size: 0;
        margin-right: $indent__base;

        > li {
            font-size: $font-size__top-line;
            margin: 0 0 0 15px;

            > a {
                @include lib-link(
                    $_link-color: $header-panel__text-color,
                    $_link-text-decoration: none,
                    $_link-color-visited: $header-panel__text-color,
                    $_link-text-decoration-visited: none,
                    $_link-color-hover: $header-panel__text-color,
                    $_link-text-decoration-hover: underline,
                    $_link-color-active: $header-panel__text-color,
                    $_link-text-decoration-active: underline
                );
            }
        }
    }
}


.header {
    &.content {
        position: relative;
        display: grid;
        display: -ms-grid;
        grid-template-columns: 2rem 1fr auto 1fr 2rem;
        -ms-grid-columns: 2rem 1fr auto 1fr 2rem;
        grid-template-rows: 1fr 1fr;
        -ms-grid-rows: 1fr 1fr;
        align-items: center;
        padding: $indent__xs $indent__s;
        width: 100%;
    }
}

.logo {
    display: block;
    grid-column: 3;
    -ms-grid-column: 3;
    margin: 0;
    position: relative;
    z-index: 5;
    top: 16px;

    img {
        display: block;    
     
        max-width: 100%;
        margin: 5px auto;

        @include max-screen($screen__m) {
            margin-top: 0.75rem;
        }
    }

    .page-print & {
        display: inline-block;
        float: none;
    }
}

.page-main {
    margin-bottom: 3rem;
    padding: 0;
    position: relative;
    > .page-title-wrapper {
        .page-title + .action {
            margin-top: $indent__l;
        }
    }
}

@include max-screen($screen__l) {
    body:not(.cms-index-index, .trollweb-wpcloud-page-view) .page-main {
        padding: 0.5rem;
    }
}

.action.skip {
    &:not(:focus) {
        @extend .abs-visually-hidden;
    }

    &:focus {
        background: $color-gray94;
        padding: $indent__s;
        box-sizing: border-box;
        left: 0;
        position: absolute;
        text-align: center;
        top: 0;
        width: 100%;
        z-index: 15;
    }
}

//
//  Global notice
//  ---------------------------------------------

.message.global {
    p {
        margin: 0;
    }

    &.noscript,
    &.cookie {
        @include lib-message($_message-type: global-note);
        margin: 0;
    }

    &.cookie {
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        z-index: 3;

        .actions {
            margin-top: $indent__s;
        }
    }

    &.demo {
        @include lib-message($_message-type: global-caution);
        margin-bottom: 0;
        text-align: center;
    }
}

//
//  Footer
//  ---------------------------------------------

.page-footer {
    margin-top: $indent__m;
}

.footer {
    &.content {
        background: $banda-footer__background-color;
        border-top: 1px solid $footer-links-separator-border-color;
        padding-top: $indent__base;

        ul {
            @extend .abs-reset-list;
        }

        .links {

            @include max-screen($screen__m) {
                display:flex;
                flex-direction: column;
            }

            > li {
                background: $banda-footer__background-color;
                font-size: $font-size__base;
                border-top: 1px solid $footer-links-separator-border-color;
                margin: 0 -$layout__width-xs-indent;
                padding: 0 $layout__width-xs-indent;
                @include max-screen($screen__m) {
                    margin: 0;
                }
            }


            a,
            strong {
                display: block;
                padding: $indent__s 0;
            }

            strong {
                color: $footer-links-color-current;
                font-weight: normal;
            }

            a {
                @include lib-link(
                    $_link-color: $footer-links-color,
                    $_link-text-decoration: none,
                    $_link-color-visited: $footer-links-color,
                    $_link-text-decoration-visited: none,
                    $_link-color-hover: $footer-links-color-hover,
                    $_link-text-decoration-hover: underline,
                    $_link-color-active: $footer-links-color-hover,
                    $_link-text-decoration-active: underline
                );
            }

        }
    }
}

.copyright {
    background-color: #fff;
    color: $banda-font__color;
    display: block;
    padding: $indent__s;
    text-align: center;
}

.page-header,
.page-footer {
    .switcher {
        .options {
            @include lib-dropdown(
                $_dropdown-actions-padding: 0,
                $_dropdown-list-item-padding: 0,
                $_dropdown-toggle-icon-content: $icon-down,
                $_dropdown-toggle-active-icon-content: $icon-up,
                $_icon-font-text-hide: true,
                $_icon-font-size: 10px,
                $_icon-font-line-height: 22px,
                $_dropdown-list-min-width: 160px,
                $_dropdown-list-position-right: -8px,
                $_dropdown-list-pointer-position: right
            );

            ul.dropdown {
                a {
                    @include lib-link(
                        $_link-color: $color-gray20,
                        $_link-text-decoration: none,
                        $_link-color-visited: $color-gray20,
                        $_link-text-decoration-visited: none,
                        $_link-color-hover: $color-gray20,
                        $_link-text-decoration-hover: none,
                        $_link-color-active: $color-gray20,
                        $_link-text-decoration-active: none
                    );
                    display: block;
                    padding: 8px;
                }
            }
        }

        strong {
            font-weight: $font-weight__regular;
        }

        .label {
            @extend .abs-visually-hidden;
        }
    }
}

//
//  Widgets
//  ---------------------------------------------

.sidebar {
    .widget.block:not(:last-child),
    .widget:not(:last-child) {
        margin-bottom: $indent__xl;
    }
}

.widget {
    clear: both;

    .block-title {
        @extend .abs-block-title;
    }
}

.page-header,
.page-footer {
    .widget.block {
        margin: $indent__base 0;
    }
}

.no-display {
    @extend .abs-no-display;
}

//
//  Calendar
//  ---------------------------------------------

.ui-datepicker td {
    padding: 0;
}

.ui-tooltip {
    background: $tooltip__background;
    border: $tooltip__border-width solid $tooltip__border-color;
    padding: 10px;
}

//
//  Navigation
//  _____________________________________________

@include max-screen($screen__l) {
    .customer-name,
    .customer-welcome + .authorization-link {
        display: none;
    }

    .nav-sections .switcher-trigger strong:after {
        font-size: $font-size__base;
        right: -25px;
        top: -2px;
    }

    .navigation .parent .level-top:after {
        font-size: $font-size__base;
        right: 10px;
        top: 7px;
    }

    .page-footer .footer.content {
        .switcher-store {
            background: $banda-footer__background-color;
            font-size: $font-size__base;
            margin: 0 -$layout__width-xs-indent;

            .switcher-options {
                display: block;

                ul.dropdown {
                    background: none;
                    border: 0;
                    box-shadow: none;
                    margin: 0;
                    position: relative;

                    &:before,
                    &:after {
                        display: none;
                    }
                }

                .switcher-trigger,
                .switcher-option {
                    border-top: 1px solid $footer-links-separator-border-color;
                    display: block;
                    padding: $indent__s $layout-indent__width;
                }

                .switcher-trigger strong {
                    padding: $indent__s 0;
                }

                .switcher-option a {
                    padding: 0;
                }
            }
        }
    }
}

.account-link {
    display: none;
    margin-left: 1rem;

    a {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: inherit;
        &:hover {
            text-decoration: none;
            .account-text {
               text-decoration: underline;
            }
        }
    }

    .account-icon {
        line-height: 10px;
        &:before {
            @include material-icon($icon-account-round);
            font-size: 26px;
        }
   }

   .account-text {
       font-size: 10px;
       font-weight: 500;
   }
}

//
//  Tablet
//  _____________________________________________

@include screen($screen__s, $screen__l - 1px) {
    .header {
        &.content {
            grid-template-columns: 3rem 5rem 1fr 2fr 2fr 3rem;
            -ms-grid-columns: 3rem 5rem 1fr 2fr 2fr 3rem;
            grid-template-rows: 1fr 1fr;
            -ms-grid-rows: 1fr 1fr;
            .nav-toggle {
                grid-column: 1;
                -ms-grid-column: 1;
                grid-row: 1 / span 2;
                -ms-grid-row: 1;
                -ms-grid-row-span: 2;
                &,
                &:before {
                    display: flex;
                    display: -ms-flexbox;
                    align-items: center;
                }
            }
            .logo {
                display: -ms-flexbox;
                align-items: center;
                grid-column: 2;
                -ms-grid-column: 2;
                grid-row: 1 / span 2;
                -ms-grid-row: 1;
                -ms-grid-row-span: 2;
                margin: 0 auto;
            }
            .block.block-search {
                grid-column: 4;
                -ms-grid-column: 4;
                grid-row: 1 / span 2;
                -ms-grid-row: 1;
                -ms-grid-row-span: 2;
                width: auto;
                padding: 0 1rem;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__l) {
    html,
    body {
        height: 100%; // Stretch screen area for sticky footer
    }

    body {
        .ie9 & {
            background-color: $copyright__background-color;
        }
    }

    .account-link {
        display: block;
    }

    .navigation ul {
        padding: 0 8px;
        display: flex;
    }

    .page-header {
        border: none;
        .panel.wrapper {
            border-bottom: 1px solid $header-panel__border-color;
            background-color: $header-panel__background-color;
        }

        .header.panel {
            padding: 8px 0;
        }

        .switcher {
            float: right;
            margin-left: 15px;
            margin-right: -6px;
            order: 1;
        }
    }

    .page-main {
        > .page-title-wrapper {
            .page-title {
                display: inline-block;
            }

            .page-title + .action {
                float: right;
                margin-top: $indent__base;
            }
        }
    }

    .customer-welcome {
        @include lib-dropdown(
        $_toggle-selector: '.action.switch',
        $_options-selector: 'ul',
        $_dropdown-actions-padding: 0,
        $_dropdown-list-item-padding: 0,
        $_dropdown-toggle-icon-content: $icon-down,
        $_dropdown-toggle-active-icon-content: $icon-up,
        $_icon-font-text-hide: true,
        $_icon-font-size: 10px,
        $_icon-font-line-height: 22px,
        $_dropdown-list-pointer-position: right,
        $_dropdown-list-position-right: -8px,
        $_dropdown-list-z-index: $customer-welcome__z-index
        );

        li {
            // @extend .switcher li;
            a {
                @include lib-link(
                $_link-color: $color-gray20,
                $_link-text-decoration: none,
                $_link-color-visited: $color-gray20,
                $_link-text-decoration-visited: none,
                $_link-color-hover: $color-gray20,
                $_link-text-decoration-hover: none,
                $_link-color-active: $color-gray20,
                $_link-text-decoration-active: none
                );
                display: block;
                line-height: 1.4;
                padding: 8px;
            }
        }

        .customer-name {
            cursor: pointer;
        }

        .customer-menu {
            display: none;
        }

        .action.switch {
            @include lib-button-reset();
            color: $color-white;
        }

        .header.links {
            min-width: 175px;
        }

        &.active {
            .action.switch {
                @include lib-icon-font-symbol(
                $_icon-font-content: $icon-up,
                $_icon-font-position: before
                );
            }

            .customer-menu {
                display: block;
            }
        }

        .greet {
            display: none;
        }
    }

    .header {
        &.panel {
            > .header.links {
                @include lib-list-inline();
                float: right;
                display: none;

                > li {
                    margin: 0 15px 0 0;

                    &.welcome {
                        a {
                            color: $color-white;
                            padding-left: $indent__xs;
                        }
                    }
                }

                > .authorization-link {
                    &:after {
                        content: attr(data-label);
                    }
                }

                > .customer-welcome + .authorization-link {
                    display: none;
                }
            }
        }

        &.content {
            grid-template-columns: 1fr 2fr auto 3fr 1fr;
            -ms-grid-columns: 1fr 2fr auto 3fr 1fr;
            grid-template-rows: 1fr;
            -ms-grid-rows: 1fr 1fr;
            align-items: center;
            padding: $polarcore-header-content-desktop__padding; // indent__l 0
            min-height: 75px;
            .logo {
                display: block;
                grid-column: 1;
                -ms-grid-column: 1;
                grid-row: 1 / span 2;
                -ms-grid-row: 1;
                -ms-grid-row-span: 2;
                margin: 0;
                img {
                    max-height: inherit;
                }
            }
            .block.block-search {
                grid-column: 3;
                -ms-grid-column: 3;
                grid-row: 1 / span 2;
                -ms-grid-row: 1;
                -ms-grid-row-span: 2;
                min-width: 30vw;
            }
        }
    }

    .page-wrapper {
        display: flex;
        flex-direction: column;
        margin: 0;
        min-height: 100%; // Stretch content area for sticky footer
        position: relative;
        transition: margin 0.3s ease-out 0s;

        > .breadcrumbs,
        > .top-container,
        > .widget {
            box-sizing: border-box;
            width: 100%;
        }

        .ie10 &,
        .ie11 & {
            height: 100%;
        }
    }

    .page-footer {
        background: $banda-footer__background-color;
        margin-top: auto;
        padding-bottom: $indent__m;

        .switcher {
            .options {
                ul.dropdown {
                    bottom: -$indent__s;
                    left: 100%;
                    margin: 0 0 0 $indent__base;
                    top: auto;

                    &:before,
                    &:after {
                        bottom: $indent__base - 7px;
                        left: auto;
                        right: 100%;
                        top: auto;
                    }

                    &:before {
                        border-color: transparent $dropdown-list__background transparent transparent;
                    }

                    &:after {
                        border-color: transparent $dropdown-list-pointer__border transparent transparent;
                        margin: 0 0 -1px -1px;
                    }
                }
            }
        }
    }

    .footer {
        &.content {
            border-top: none;

            ul {
                padding-right: 50px;
            }

            .switcher.store {
                display: block;
                margin: 0;
            }

            .links {
                display: inline-block;
                padding-right: 50px;
                vertical-align: top;

                li {
                    background: transparent;
                    border: none;
                    font-size: 14px;
                    margin: 0 0 8px;
                    padding: 0;
                }

                a,
                strong {
                    display: inline;
                }
            }
        }
    }
}


// Desktop
@include min-screen($screen__l) {
    .header {
        &.content {
            min-height: 75px;
            height: 75px;
            .logo {
                body.checkout-klarna-index & {
                    order: 1;
                    margin: 0 auto;
                }
                margin: 0;
                img {
                    max-height: inherit;
                    max-width: 15rem;
                    body.checkout-klarna-index & {
                        max-width: 8rem;
                    }
                    margin: 5px auto;
                }
            }
        }
    }

    .logo {
        display: block;
        grid-column: 3;
        -ms-grid-column: 3;      
        position: relative;
        z-index: 5;
        top: 19px;
    }
}

@include min-screen($screen__xxl + 1) {
    .page-header {
        width: 100vw;
        margin: 0 auto;
    }

    .block-search {
        width: 570px;
        margin-left: 100px;
        min-width: 10vw !important;
        .form.minisearch {
            width: 570px;
        }
        .action.search {
            right: -8px;
        }
    }

    .search-autocomplete {
        max-width: 570px;
    }

    .minicart-wrapper {
        min-width: 45px;
    }

    a.logo {
        min-width: 100px;
    }
}
