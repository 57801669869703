//
//  Variables
//  _____________________________________________

$autocomplete__background-color  : $color-white !default;
$autocomplete__border            : 1px solid $form-element-input__border-color !default;
$autocomplete-item__border       : 1px solid $color-gray90 !default;
$autocomplete-item__hover__color : $color-gray91 !default;
$autocomplete-item-amount__color : $color-gray60 !default;

.block-search {
    margin-bottom: 0;

    .block {
        &-title {
            display: none;
        }
    }

    .block-content {
        margin-bottom: 0;
    }

    .label {
        @include lib-icon-font(
            $_icon-font-content      : $icon-search,
            $_icon-font-size         : 35px,
            $_icon-font-line-height  : 33px,
            $_icon-font-color        : $minicart-icons-color,
            $_icon-font-color-hover  : $minicart-icons-color-hover,
            $_icon-font-color-active : $minicart-icons-color-hover,
            $_icon-font-text-hide    : true
        );
        display: inline-block;
        float: right;

        &.active {
            + .control {
                input {
                    position: static;
                }
            }
        }
    }

    .action.search {
        &:before {
           -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            font-size: 28px;
            line-height: 22px;
            color: #b5b5b5;
            content: "";
            font-family: "Material Icons";
            vertical-align: top;
            display: inline-block;
            font-weight: normal;
            overflow: hidden;
            speak: none;
            text-align: center;
            margin-right: -15px;
        }
    }

    .control {
        clear: both;
        margin: 0 (-$layout__width-xs-indent) -1px;
        padding: 0 $layout__width-xs-indent;
        height: 38px;
    }

    input {
        margin: 0;
        position: absolute;
        height: inherit;
    }

    .nested {
        display: none;
    }
}

.search-autocomplete {
    @extend .abs-add-box-sizing;
    display: none;
    margin-top: -15px;
    overflow: hidden;
    position: absolute;
    z-index: 3;
    background: $banda-white;
    border: 1px solid $banda-border_color;
    border-top: 2px solid $banda-green;
    padding: 1rem;
    box-shadow: 5px 5px 15px 0px rgba(0,0,0,0.14);

    .autocomplete-list-title {
        text-transform: uppercase;
        font-size: 10px;
        color: rgba(0,0,0,0.3);
        font-weight: 800;
        margin-bottom: 1rem;
    }

    dd {
        display: flex;
        width: 100%;

        &:hover {
            cursor: pointer;
        }

        .product-item {
            .price {
                font-size: 14px;
            }
        }

        .price-box,
        .price-final_price {
            margin-top: -0.5rem !important;
            margin-bottom: 0;
        }

        .category-mini-crumb,
        .qs-option-name {
            cursor: pointer;
        }

        .product-image-box {
            margin-right: 1rem;
        }

        .qs-option-name {
            margin-right: 1rem;
        }
    }

    ul {
        @include lib-list-reset-styles();

        li {
            @include lib-css(border-top, $autocomplete-item__border);
            cursor: pointer;
            margin: 0;
            padding: $indent__xs $indent__xl $indent__xs $indent__s;
            position: relative;
            text-align: left;
            white-space: normal;

            &:not(:empty) {
                border-top: 0;
                @include lib-css(border, $autocomplete__border);
                @include lib-css(background, $autocomplete__background-color);
            }

            &:first-child {
                border-top: none;
            }

            &:hover,
            &.selected {
                @include lib-css(background, $autocomplete-item__hover__color);
            }

            .amount {
                @include lib-css(color, $autocomplete-item-amount__color);
                position: absolute;
                right: 7px;
                top: $indent__xs;
            }
        }
    }
}

.form.search.advanced {
    .fields.range {
        .field {
            &:first-child {
                position: relative;

                .control {
                    padding-right: 25px;

                    &:after {
                        content: ' \2013 ';
                        display: inline-block;
                        position: absolute;
                        right: 0;
                        text-align: center;
                        top: 6px;
                        width: 25px;
                    }
                }
            }

            &:last-child {
                position: relative;

                div.mage-error[generated] {
                    left: 0;
                    position: absolute;
                    top: 32px;
                }
            }

            &.with-addon {
                .control {
                    padding-right: 45px;
                }
            }
        }
    }

    .group.price {
        .addon {
            .addafter {
                background: none;
                border: 0;
                padding-top: 6px;
                position: absolute;
                right: 0;
                top: 0;
            }
        }
    }
}

.search.summary {
    margin-bottom: $indent__s;
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .block-search {
        .block-content {
            margin-bottom: 0;
        }

        .field.search label {
            display: none;
        }

        .control {
            padding: 0;
        }

        .control input.input-text {
            position: relative;
            border-right: 0;
        }
    }
}

@include max-screen($screen__s) {
    .block-search {
        margin-top: $indent__s;
    }

    .field.search label {
        display: none;
    }

    .control input.input-text {
        position: relative;
        width: 100%;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .block-search {
        float: right;
        padding-left: 15px;
        position: relative;
        width: 250px;
        z-index: 4;

        .label {
            @extend .abs-visually-hidden-desktop;
        }

        .control {
            border-top: 0;
            margin: 0;
            padding: 0 0 25px;
        }

        .nested {
            display: block;
            padding-top: 5px;
            position: absolute;
        }

        input {
            @include lib-input-placeholder($form-element-input-placeholder__color);
            margin: 0;
            padding-right: 35px;
            position: static;
        }

        .action.search {
            display: inline-block;
            @include lib-button-icon(
                $_icon-font-content   : $icon-search,
                $_icon-font-text-hide : true,
                $_icon-font-color     : $header-icons-color
            );
            @include lib-button-reset();
            padding: $indent__xs 0;
            position: absolute;
            right: 10px;
            top: 3px;
            z-index: 1;

            &:focus {
                &:before {
                    @include lib-css(color, $color-gray20);
                }
            }
        }
    }

    .search-autocomplete {
        margin-top: 0;
    }
}
