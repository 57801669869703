@include max-screen($screen__m) {
    /*
     *
     *  Patch for mobile modal slide error
     *  @see _modals.scss
     *
     */
    body .custom-slide {
        left: 0 !important;
    }
    
    #opc-sidebar {
        .minicart-items {
            background: white;
        }
        .product-item > .product {
            display: flex;
            flex-wrap: wrap;
            padding: 1rem;
            .product-item-details {
                padding-left: 0;   
            }
        }
    }
}

.modal-popup._show, .modal-slide._show {
    background: rgba(0, 0, 0, 0.2)
}

