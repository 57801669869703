.field-recaptcha {
    float: right;
    margin-bottom: 15px;

    &>div {
        &>.field {
            display: none;
        }
    }
}

body.customer-account-login {
    .field-recaptcha {
        margin-right: 6px;
    }
}

body.catalog-product-view {
    form.review-form {
        display: flex;
        flex-direction: column;

        .review-fieldset {
            order: 1;
        }

        .field-recaptcha {
            order: 2;

            .g-recaptcha {
                float:right;
            }
        }

        .review-form-actions {
            order:3
        }
    }
}
