/*Typography*/

[data-content-type="row"] {
    h1,
    h2,
    h3 {
        font-family: "Raleway";
        font-weight: 700;
    }
}

/*Lists*/
.banda-list {
    ul {
        list-style-type: none;
    }
}

/*Buttons*/
.banda-green-button {
    .pagebuilder-button-primary {
        background: $banda-green;
        border: 0;

        &:hover {
           background: $banda-green__hover-color;
            border: 0;
        }
    }
}

.banda-orange-button {
    .pagebuilder-button-primary {
        background: $banda-button__background-color;
        border: 0;

        &:hover {
            background: $banda-orange__hover-color;
            border: 0;
        }
    }
}

.banda-blog-button {
    width: 100% !important;
    .pagebuilder-button-primary {
        display: flex;
        justify-content: center;
        flex-direction: column;
        background: $banda-green;
        border: 0;
        width: inherit;
        height: 30px;

        &:hover {
            background: $banda-green__hover-color;
            border: 0;
        }

        span {
            font-weight: 400;
            font-size: 14px;
        }
    }
}

.banda-banner-button {
    width: 100%;
    padding: 0 1.5rem;
    .pagebuilder-button-primary {
        color: $banda-green;
        text-decoration: none;
        line-height: 2rem;
        background: none;
        border: 0;
        border-top: 1px solid $banda-green;
        font-size: 20px;
        font-weight: 300;
        padding: 3rem 1.5rem;
        pointer-events: all;
        justify-content: center;
        display: inline-flex;
        width: 100%;

        &:hover {
            background: none;
            text-decoration: none;
            border: 0;
            border-top: 1px solid $banda-green;
            color: $banda-green;
            cursor: pointer;
        }

        a:hover {
            text-decoration: none;
            color: $banda-green;
        }
    }
}

/*Column*/

.banda-blog-column {
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
}

@include max-screen($screen__m) {
    .banda-mobile-button {
        .pagebuilder-button-primary {
            color: white;
            border-radius: 5rem;
            padding: 0.8rem 1.5rem;
            font-family: inherit;
            font-weight: 500;
            font-size: 1.2em;
            min-width: 50%;
        }
    }
}


#html-body {
    .partner-logo {
        max-width: 75%;
        padding-left: 5px;
    }
    &.cms-bestill-reseptvarer {
        .pagebuilder-column {
            .pagebuilder-button-primary {
                border-radius: 50px;
            }
        }
    }
    .contact-us-form {
        .fieldset {
            margin-bottom: 15px;
            .control {
                input {
                    background-color: transparent;
                    font-size: 1em;
                    box-shadow: none;
                    font-weight: 400;
                    line-height: 1em;
                    border: 1px solid #eee;

                }
                textarea {
                    background-color: transparent;
                    font-size: 1em;
                    box-shadow: none;
                    font-weight: 400;
                    line-height: 1em;
                    border: 1px solid #eee;
                }

            }
        }
        .actions-toolbar {
            .primary {
                display: inline-block;
                button {
                    border-radius: 6px;
                    padding: 7px 15px;
                }
            }

        }

    }

    [data-content-type='text'][data-element='main'] {
        ul {
            li {
                margin-bottom: 0px;
            }
        }
    }
    [data-content-type='row'][data-appearance='contained'] {
        .reseptvarer-partner {
            .pagebuilder-column-group {
                .pagebuilder-column {
                    figure {
                        margin: 0px 0px 8px 8px;
                    }
                }
            }
        }
    }
    .pagebuilder-column {
        .widget-product-grid {
            .product-item {
                .product-item-actions {
                    .actions-secondary {
                        display: none;
                    }
                }
            }
        }
    }
    &.cms-ofte-stilte-sporsmal {
        .pagebuilder-column {
            width: calc(50% - 16px);
            margin: 8px;
            h1 {
                color: $color__primary;
            }
            h2 {
                color: white;
                background-color: $color__primary;
                padding: 12px;
            }
        }
    }
    [data-content-type='faq'] {
        list-style-type: none;
        position: relative;
        width: 100%;
        padding: 0;
    }
    [data-content-type='faq-item'] {
        overflow: hidden;
        padding: 1.25rem 1.25rem 1.25rem 4rem;
        border-bottom: 1px solid #d6dbe1;
        margin: 0;
        [data-role='collapsible'] {
            font-weight: 700;
            line-height: 1.1;
            margin: 0;
            font-size: 16px;
            cursor: pointer;
            &::before {
                font-family: 'pagebuilder-font';
                margin-right: 5px;
                font-size: 12px;
                left: 15px;
                line-height: 1.8;
                position: absolute;
            }
        }
        [data-role='collapsible'][aria-expanded='true'] {
            &::before {
                content: '\f104';
            }
        }
        [data-role='collapsible'][aria-expanded='false'] {
            &::before {
                content: '\f101';
            }
        }
    }
    [data-content-type='faq-item'] {
        [data-role='content'] {
            [data-element='answer'] {
                padding-left: 0;
                margin-top: 1.5rem;
            }
        }
    }

    // tablet
    @media only screen and (min-width: 768px) and (max-width: 1198px) {
        .pagebuilder-column.mobile-half-width {
            display: grid;
            grid-template-columns: 2fr 1fr;
            align-items: center;
            padding: 2vw 0;
            border-bottom: 1px solid #118b2d;
            figure {
                grid-column: 2;
                grid-row: 1 / span 2;
                padding: 0;
                width: 100%;
                height: 100%;
                min-height: 15vw;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
            }
            .content {
                padding: 0;
                color: #434343;
                line-height: 1.5;
                font-size: 1.2em;
                align-self: self-start;
            }
            .pagebuilder-button-primary {
                border-radius: 5rem;
                padding: 0.5rem 3rem;
                font-family: inherit;
                font-weight: 500;
                font-size: 1.2em;
                min-width: 50%;
            }
        }

        .pagebuilder-column {
            [data-content-type='products'] {
                .block.widget {
                    .products-grid {
                        .widget-product-grid.product-items {
                            .product-item {
                                width: calc(33.33% - 20px) !important;
                                margin: 10px !important;
                            }
                        }
                    }
                }
            }
        }
    }
    @include max-screen($screen__l - 1px) {
        .pagebuilder-column {
            display: unset;
            flex-basis: unset;
        }
    }
    // mobile
    @include max-screen($screen__m - 1px) {
        .pagebuilder-column.mobile-half-width {
            display: grid;
            grid-template-columns: 6fr 1fr;
            align-items: center;
            padding: 4vw 0;
            border-bottom: 1px solid #118b2d;
            width: 100%;
            &:nth-of-type(even) {
                grid-template-columns: 1fr 6fr;
                text-align: right;
                figure {
                    grid-column: 1;
                }
            }
            figure {
                grid-column: 2;
                grid-row: 1 / span 2;
                padding: 0;
                width: 100%;
                height: 100%;
                min-height: 15vw;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
            }
            .content {
                padding: 0;
                color: #434343;
                line-height: 1.5;
                font-size: 1.2em;
                align-self: self-start;
            }
            .pagebuilder-button-primary {
                border-radius: 5rem;
                padding: 0.5rem 3rem;
                font-family: inherit;
                font-weight: 500;
                font-size: 1.2em;
                min-width: 50%;
            }
        }

        .pagebuilder-column {
            display: flex;
            flex-direction: column;
            [data-content-type='products'] {
                .block.widget {
                    .products-grid {
                        .widget-product-grid.product-items {
                            .product-item {
                                width: calc(50% - 16px);
                                margin: 8px;
                            }
                        }
                    }
                }
            }
        }
    }
    @media only screen and (min-width: 1200px) {
        .pagebuilder-column {
            [data-content-type='products'] {
                .block.widget {
                    .products-grid {
                        .widget-product-grid.product-items {
                            .product-item {
                                width: calc(25% - 20px);
                                padding: 1rem;
                                margin: 10px;
                            }
                        }
                    }
                }
            }
        }
        [data-content-type='row'][data-appearance='contained'] {
            .kontakt-oss-form {
                .pagebuilder-column-group {
                    .pagebuilder-column {
                        [data-content-type='html'] {
                            margin-left: 25%;
                        }
                    }
                }
            }
        }
    }
    @media only screen and (min-width: 1024px) {
        .pagebuilder-column-group {
            .pagebuilder-column {
                position: relative;
                .banda-banner-button {
                    position: absolute;
                    top: 80%;
                }
            }
        }
        .contact-us-form {
            .fieldset {
                .label {
                    width: 100%;
                    text-align: unset;
                    margin-bottom: 3px;
                }
                .control {
                    width: 100%;
                    input {
                        background-color: transparent;
                        font-size: 1em;
                        box-shadow: none;
                        font-weight: 400;
                        line-height: 1em;
                        height: 40px;
                        padding: 0 20px;
                        border: 1px solid #eee;

                    }
                    textarea {
                        background-color: transparent;
                        font-size: 1em;
                        box-shadow: none;
                        font-weight: 400;
                        line-height: 1em;
                        padding: 20px 20px;
                        border: 1px solid #eee;
                    }

                }
            }
            .actions-toolbar {
                margin-left: 0px;
                .primary {
                    button {
                        border-radius: 6px;
                    }
                }

            }

        }
    }
    @media only screen and (max-width: 1024px) {
        .image-fullwidth {
            img {
                width: 100% !important;
            }
        }

        // overriding pagebuilder styling (!important)
        &.cms-bestill-reseptvarer {
            .reseptvarer {
                .pagebuilder-column {
                    width: 100% !important;
                    min-height: 0px !important;
                    padding: 12px;
                    h3 {
                        margin-top: 0px !important;
                    }
                    [data-content-type='text'] {
                        margin-top: 0px !important;
                    }
                }
            }
        }
    }
    @media only screen and (max-width: 479px) {
        .pagebuilder-column-group {
            .pagebuilder-column {
                width: calc(100% - 16px);
            }
        }
        .partner-logo-column {
            .pagebuilder-column-group {
                .pagebuilder-column {
                    margin: 8px;
                    width: calc(50% - 16px);
                }
            }
        }
        .reseptvarer-partner {
            .pagebuilder-column-group {
                .pagebuilder-column {
                    width: 50%;
                }
            }
        }
        .reseptvarer-mobile {
            .pagebuilder-column-group {
                .pagebuilder-column {
                    [data-content-type='text'] {
                        p {
                            padding-top: 12px;
                            padding-bottom: 2px;
                        }
                    }
                    .pagebuilder-button-primary {
                        padding: 10px 22px;
                        border-radius: 50px;
                    }
                }
            }
        }

        [data-content-type='row'][data-appearance='contained'] {
            .reseptvarer-partner {
                .pagebuilder-column-group {
                    margin-left: 0px;
                }
            }
        }
    }
    @media only screen and (min-width: 480px) and (max-width: 1023px) {
        .livskvalitet-tablet {
            .pagebuilder-column-group {
                .pagebuilder-column {
                    margin: 8px;
                    width: calc(33% - 16px);
                }
            }
        }
        .reseptvarer-mobile {
            .pagebuilder-column-group {
                .pagebuilder-column {
                    width: 100%;
                    [data-content-type='text'] {
                        p {
                            padding-top: 12px;
                            padding-bottom: 2px;
                        }
                    }
                    .pagebuilder-button-primary {
                        padding: 10px 36px !important;
                        border-radius: 50px !important;
                    }
                }
            }
        }

        [data-content-type='row'][data-appearance='contained'] {
            .reseptvarer-partner {
                .pagebuilder-column-group {
                    margin-left: 0px;
                }
            }
        }
    }
    @media only screen and (min-width: 767px) {
        .pagebuilder-column-group {
            .pagebuilder-column {
                h1 {
                    font-size: 40px;
                }
            }
        }
    }
}
@media only screen and (min-width: 1024px) {
    .contact-index-index {
        .page-title-wrapper {
            padding: 0 8px;
            margin-left: 25.8%;
            margin-top: 15px;
            .page-title {
                font-weight: 700;

            }
        }
        .page.messages {
            margin: 8px 16px 8px 26.5%;
        }
        .columns {
            margin: 0 8px 0 25.8%;
        }
    }

}
