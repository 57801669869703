//  Common

.page-products {
    .columns {
        padding-top: 0;
    }
}

.toolbar {
    margin-bottom: 0;
    &-products {
        display: block;
        margin-top: 1.5rem;
        &:last-of-type {
            .field.limiter {
                float: none;
            }

        }
        .toolbar-amount {
            margin: 0;
            font-size: 20px;
            font-weight: 500;
            .toolbar-number{
                font-size: 20px;
                font-weight: 700;
                padding: 0 0.2rem;
                vertical-align: text-bottom;
            }
        }
    }
}

.toolbar-sorter.sorter {
    .sidebar-main & {
        display: flex;
        float: none;
        align-content: center;
        height: 52px;
        outline: 0.5rem solid #ffffff;
        border: 1px solid #bfbfbf;
        border-radius: 5px;
        padding: 0.55rem 1rem;
        box-shadow: none;
        background: none;
        justify-content: center;
        flex-direction: row-reverse;
    }
    .sorter-label {
        display: none;
    }
    .sorter {
        &-options {
            .sidebar-main & {
                margin: 0;
                width: auto;
                border: none;
                height: auto;
                padding: 0;
                font-family: inherit;
            }
            &:focus {
                box-shadow: none;
                border: none;
                outline: none;
            }
        }
        &-action {
            .sidebar-main & {
                display: flex;
                align-items: center;
            }
        }
    }
}

//  Tablet + Desktop
@include min-screen($screen__m) {
    .modes {
        display: none;
    }
}
