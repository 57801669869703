.breadcrumbs {
    .items {
        margin: 1rem 0;
        .item {
            &.home a,
            a {
                color: $primary__color;
            }
            a,
            strong {
                font-size: 15px;
            }
            &:after {
                font-size: 20px;
                line-height: 20px;
            }
        }
    }
}
@include min-screen($screen__m) {
    .breadcrumbs {
        margin-bottom: 0.5rem;
    }
}
