.block-product-link,
.block-category-link {
    &.widget {
        display: block;
        margin-bottom: $indent__base;
    }
}

.block-product-link-inline {
    &.widget {
        margin: 0;
    }
}

.block.widget {
    .product-item-info {
        width: auto;
    }

    .pager {
        padding: 0;

        .toolbar-amount {
            float: none;
            @include lib-font-size(12);
        }

        .pages-item-previous {
            padding-left: 0;
        }

        .pages-item-next {
            position: relative;
        }

        .items {
            white-space: nowrap;
        }
    }

    .products.wrapper.grid.products-grid {
        margin: 30px 0;

        .product.photo.product-item-photo {
            height: 220px;
            display: flex;
            justify-content: center;
        }

        a.product-item-link, .product-item-details .product-item-inner {
            display: flex;
            justify-content: center;
            text-align: center;
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include min-screen($screen__s) {
    .block.widget .products-grid .product-item,
    .page-layout-1column .block.widget .products-grid .product-item,
    .page-layout-3columns .block.widget .products-grid .product-item {
        width: (100% / 3);
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .block.widget .products-grid .product-item {
        width: (100% / 3);

        .sidebar & {
            margin-left: 0;
            width: 100%;

            .actions-secondary {
                display: block;
                padding: 10px 0;
            }
        }
    }

    .page-layout-1column .block.widget .products-grid .product-item {
        width: (100% / 4);
    }

    .page-layout-3columns .block.widget .products-grid .product-item {
        width: (100% / 2);
    }
}

@include min-screen($screen__l) {
    .block.widget .products-grid .product-item {
        width: (100% / 4);
    }

    .page-layout-1column .block.widget .products-grid .product-item {
        width: (100% / 6);
    }

    .page-layout-3columns .block.widget .products-grid .product-item {
        width: (100% / 4);
    }

    .block.widget .products-grid .product-items {
        margin: 0;
    }

    .block.widget .products-grid .product-item {

        &:nth-child(4n + 1) {
            margin-left: 0;
        }
    }
}
//Mobile & tablet
@include max-screen($screen__m) {
    .block.widget .products-grid {
        .widget-product-grid {
            margin-left: 0;
        }
    }
}

@include max-screen($screen__l) {
    .catalog-product-view .block.widget {
        .products.wrapper.grid.products-grid {
            margin-left: -5px;
        }
    }
}

@include max-screen($screen__xl) {
    .block.widget {
        .products.wrapper.grid.products-grid {
            margin: 30px 0;
            padding: 0 15px;

            .product.photo.product-item-photo {
                height: 150px;
            }
        }
    }
}

@include min-screen($screen__m) {
    .sidebar .block.widget .pager {
        .item:not(.pages-item-next):not(.pages-item-previous) {
            @extend .abs-no-display-desktop;
        }

        .pages-item-next {
            padding: 0;

            .action {
                margin: 0;
            }
        }
    }
}


@media only screen and (min-width: 1200px) {
    .block.widget .products-grid .product-item {
        width: 24.1%;
    }
}
