//
//  Variables
//  _____________________________________________

$checkout-wrapper__margin             : $indent__base !default;
$checkout-wrapper__columns            : 8 !default;

$checkout-step-title__border          : $border-width__base solid $color-gray80 !default;
$checkout-step-title__font-size       : 26px !default;
$checkout-step-title__font-weight     : $font-weight__light !default;
$checkout-step-title__padding         : $indent__s !default;

$checkout-step-title-mobile__font-size: 18px !default;

//
//  Common
//  _____________________________________________

.totals-tax-summary{
    th{
        &:before{
            content: "Herav";
            display: inline-block;
            margin-right: 0.2rem;
        }
    }
    th, td {
        border-top: none;
    }
}
.checkout-klarna-index {
    overflow-x: hidden;
    .nav-sections,
    .nav-toggle {
        display: none;
    }
	.page-header {
		box-shadow: 0 1px 10px rgba(0,0,0,.1);
		z-index: 1;
	}
    .header.content{
        display: -ms-flexbox;
        grid-template-columns: 1fr 1fr 1fr;
        -ms-grid-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr;
        -ms-grid-rows: 1fr;
        .logo {
            grid-column: 2;
            -ms-grid-column: 2;
            grid-row: 1;
            -ms-grid-row: 1;
        }
        .nav-toggle{
            display: none;
        }
    }
    .discount-code {
        display: none;
    }
	.page-main {
		background-color: #f9f9f9;
		margin: 0;
		max-width: none;
		padding: 1rem 0;
        .columns {
            max-width: 1440px;
            margin: 0 auto;
        }
	}
    .page-title{
        text-align: center;
        font-weight: 700;
		margin: 1vw auto 2vw auto;
    	width: 100%;
    }
	.step-title,
	.title {
		margin: 0;
		font-family: inherit;
		font-size: 2em;
		font-weight: 600;
        border: none;
	}
    .trollweb-pharmacy-picker-footer {
        max-width: $layout__max-width;
        margin-bottom: 2rem;
        background: transparent;
        &-contents {
            padding: 0rem 4rem;
            margin-bottom: 1rem;
        }
    }
    #checkout {
        display: grid;
        display: -ms-grid;
        grid-gap: 1rem;
        grid-template-columns: 1fr;
        -ms-grid-columns: 1fr;
        -ms-grid-rows: auto 1rem auto 1rem auto;
        .opc-estimated-wrapper {
            display: none;
        }
        .messages {
            width: 100%;
        }
		#opc-shipping_method {
            grid-row: 1;
            -ms-grid-row: 1;
        }
        .opc-block-summary {
            display: none;
        }
		.opc-wrapper {
            grid-row: 2;
            -ms-grid-row: 3;
            .opc {
                padding: 0;
                list-style: none;
                .cui__main-header h1 {
                    color: #ffffff !important;
                }
            }
        }
		.opc-wrapper,
		#opc-shipping_method,
		.opc-block-summary {
			background-color: #ffffff;
			border-radius: 0.5rem;
			box-shadow: 0 1px 5px rgba(0,0,0,0.05);
			padding: 1rem 2rem;
		}
		.table-checkout-shipping-method {
            width: 100%;
			tbody {
				width: 100%;
				display: flex;
				flex-direction: column;
				tr.row {
                    cursor: row;
					border: 1px solid #dcdcdc;
					border-radius: 0.5rem;
					padding: 1rem;
					margin: 0.5rem 0;
					display: grid;
                    display: -ms-flexbox;
                    align-items: center;
					grid-template-columns: 2rem auto auto auto;
					* {
						padding: 0;
						border: none;
					}
					td.col-carrier {
						font-weight: 600;
						font-size: 1.1em;
						text-align: right;
					}
					td[data-bind*="method_title"] {
						text-align: center;
                        margin: 0 auto;
					}
				}
			}
		}
    }
}

.payment-option.giftcardaccount {
    display: none;
}

/* Success page */
.checkout-success{
    p{
        text-align: center;
        font-size: 18px;
    }
    p:first-of-type{
        font-size: 22px;
        font-weight: 500;
    }
    .ng-scope{
        max-width: 700px;
        margin: 0.5rem auto;
        .product-item.ng-scope{
            .qty-info{
                text-align: center;
            }
        }
    }
    .ps-article-unauthorized{
        padding: 1.25rem;
    }
    .actions-toolbar{
        max-width: 705px;
        margin: 1rem auto;
    }
}
.checkout-klarna-success{
    .page-title{
        text-align: center;
        font-weight: 500;
    }
    #registration{
        max-width: 700px;
        margin: 0 auto;
        p{
            font-size: 16px;
        }
        .action.primary{
            margin-top: 0.5rem;
        }
    }
}
.continue-shopping-link {
    width: auto;
	a {
        font-size: 1.2em;
		color: $color__primary;
        display: flex;
        align-items: center;
        &:hover,
        &:focus {
            text-decoration: none;
        }
        &:before {
            @include material-icon($icon-arrow-left);
            font-size: 1.2em;
            color: $color__primary;
            margin-right: 1rem;
            vertical-align: middle;
        }
	}
}
.authentication-link {
    grid-column: 3;
    -ms-grid-column: 3;
    order: 3;
	button.action {
		border: 1px solid #{$color__primary};
		color: $color__primary;
        font-size: 1.2em;
		padding: 0.5em 2rem;
		border-radius: 5rem;
		white-space: nowrap;
		&:hover {
			text-decoration: none;
		}
	}
	.authentication-wrapper {
		width: 100%;
		float: none;
		margin: 0 1rem 0 auto;
        text-align: right;
	}
	.authentication-dropdown {
		button.action {
			background-color: $color__primary;
			color: #ffffff;
		}
        .modal-inner-wrap {
            background-color: #ffffff;
        }
		&._show {
			min-width: 70vw;
			max-width: 500px;
			width: auto;
			right: unset;
			left: 50vw !important;
			top: calc(5rem + 2vw);
			bottom: unset;
			position: fixed;
			transform: translateX(-50%);
			& + .dropdown-overlay {
				position: fixed;
				top: 0;
				left: 0;
				width: 100vw;
				height: 100vh;
				background-color: rgba(0,0,0,0.2);
			}
		}
	}
}
/* Desktop/Tablet */
@include min-screen($screen__m){
    .checkout-klarna-index {
        #checkout{
            max-width: 1024px;
            margin: 0 auto;
            .messages {
                grid-column: 1;
                -ms-grid-column: 1;
            }
            #opc-shipping_method {
                grid-column: 1;
                -ms-grid-column: 1;
                grid-row: 1;
                -ms-grid-row: 1;
            }
            .opc-wrapper {
                grid-column: 1;
                -ms-grid-column: 1;
                grid-row: 2 / span 6;
                -ms-grid-row: 2;
                -ms-grid-row-span: 6;
                width: 100%;
            }
        }
    }
}

/*Mobile */
@include max-screen($screen__m){
    .block-authentication {
        .block-customer-login {
            .block-title {
                display: flex;
                justify-content: flex-start;
            }
        }
    }
}

/* Small Mobile */
@include max-screen($screen__s){
    .checkout-klarna-index{
        .authentication-link {
            button.action {
                border: none;
                padding: 0;
            }
        }
        .columns {
            .column.main {
                padding: 0;
            }
        }
        #checkout {
            .opc-wrapper,
            #opc-shipping_method,
            .opc-block-summary {
                padding: 1rem;
            }
            .table-checkout-shipping-method {
                tbody {
                    tr.row {
                        td[data-bind*="method_title"] {
                            grid-row: 2;
                            grid-column: 1 / span 4;
                            text-align: center;
                        }
                        td[data-bind*="carrier_title"] {
                            grid-column: 3 / span 2;
                        }
                    }
                }
            }
            .step-title, .title {
                margin: 0;
            }
        }
    }
}
