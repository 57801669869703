$primary-color: #00904a;
$light-background-color: #fff;
$text-color: #00904a;
$border-color: #e0e0e0;
$hover-background-color: #00703c;
$active-background-color: #005f30;
$hover-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);

// Pharmacy select
$header-store-list-bg: #007bff;
$header-store-list-border: #ccc;
$header-store-list-hover-bg: darken($header-store-list-bg, 5%);
$pharmacy-list-item-hover-bg: #e9e9e9;
$pharmacy-list-item-selected-bg: #f0f0f0;
$pharmacy-list-item-border: #dcdcdc;

.store-locator-container #store-locator {
    .store-search-input {
        border: 1px solid $border-color;
        background-color: $light-background-color;

        &:focus {
            border-color: darken($primary-color, 10%);
            background-color: lighten($light-background-color, 5%);
        }
    }

    #store-count {
        color: $primary-color;
        background-color: $light-background-color;
    }

    .store-info-icon {
        color: $primary-color;
    }

    .store-name {
        color: $primary-color;
    }

    .store {
        &:hover {
            box-shadow: $hover-box-shadow;
        }

        .directions-button, .show-more-info {
            background-color: $primary-color;
            color: $light-background-color;

            &:hover, &:focus {
                background-color: $hover-background-color;
            }

            &:active {
                background-color: $active-background-color;
            }
        }

        .distance-to-store {
            color: $text-color;
            background-color: $light-background-color;
        }
    }

    #storeList {
        &::-webkit-scrollbar-track {
            background: $border-color;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $primary-color;
        }
    }

    .search-suggestions {
        border: 1px solid $border-color;
        background-color: $light-background-color;
    }

    .search-suggestions .suggestion {
        border-bottom: 1px solid $border-color;
        color: $text-color;
    }

    .search-suggestions .suggestion:hover {
        background-color: $hover-background-color;
        color: $light-background-color;
    }

    .get-my-location-button, .show-all-stores {
        background-color: $primary-color;

        :hover {
            background-color: $hover-background-color;
        }
    }
}

#map-selector {
    .mapboxgl-popup-content {
        display: flex;
        align-items: baseline;

        .mapboxgl-popup-close-button {
            position: relative;
            top: -10px; // Adjust as needed for visual alignment
            right: -10px; // Adjust as needed for visual alignment
            cursor: pointer; // To indicate this is clickable
        }
    }
}

.select-bandagist-modal {
    .modal-header {
        h1 {
            min-height: 0;
            padding: 0;
        }

        h2 {
            margin-top: 0;
            margin-bottom: 5px;
        }
    }
}

.page-main .pharmacy-picker-description-text {
    margin-bottom: 2.5rem;
}

.header-store-list, .pharmacy-picker form {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    gap: 6px;

    @media (min-width: 768px) {
        grid-template-columns: 25% 75%;
        grid-template-rows: 50px 40px 40px 24px;

        & > :nth-child(2) {
            grid-column: 2;
            grid-row: 1 / span 4;
        }
    }

    #map-selector {
        height: 300px;
        width: 100%;
        border: 1px solid $header-store-list-border;
        order: 1;

        @media (min-width: 768px) {
            height: 560px;
        }
    }

    #show-all-stores, #get-current-location {
        margin-bottom: 6px;
        background-color: #00904a;
        color: #fff;
        border: none;
        border-radius: 2px;
        cursor: pointer;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
    }

    .mapbox-store-counter {
        text-align: center;
    }

    #pharmacy-list {
        display: block;
        max-height: 400px;
        min-height: 400px;
        overflow-y: auto;
        scrollbar-width: thin;
        scrollbar-color: $header-store-list-border $pharmacy-list-item-selected-bg;

        @media (max-width: 768px) {
            max-height: 200px;
            min-height: 200px;
        }

        &::-webkit-scrollbar {
            width: 8px;
        }
        &::-webkit-scrollbar-track {
            background: $pharmacy-list-item-selected-bg;
        }
        &::-webkit-scrollbar-thumb {
            background-color: $header-store-list-border;
            border-radius: 4px;
        }

        li {
            list-style: none;
            margin: 5px 0;
            padding: 0;

            border-bottom: 1px solid #eee;
            cursor: pointer;
            background-color: #f6f6f6;

            span {
                font-size: 14px;
            }

            &:hover {
                background-color: $pharmacy-list-item-hover-bg;
                span {
                    background-color: $pharmacy-list-item-hover-bg;
                }
            }

            &.selected {
                background-color: $pharmacy-list-item-selected-bg;
                border-left: 1px solid $pharmacy-list-item-border;
                font-weight: bold;
                span {
                    background-color: $pharmacy-list-item-selected-bg;
                }
            }
            .store-address {
                padding-left: 12px;
                font-size: 13px;
            }
            .store-distance {
                padding-left: 12px;
                font-size: 11px;
                padding-bottom: 9px;
            }
        }
    }
    .pharmacy-search {
        position: relative;

        #myInput {
            font-size: 14px;
        }

        #search-suggestions {
            width: 100%;
            background: #fff;
            border: 1px solid $header-store-list-border;
            border-top: none;
            overflow-y: auto;
            box-shadow: 0 4px 6px rgba(0,0,0,0.1);
            margin: 0;

            li {
                width: 100%;
                cursor: pointer;

                &:hover {
                    background-color: $pharmacy-list-item-hover-bg;
                }
            }
        }
    }
}
